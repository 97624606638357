<template>
  <div class="boolean-radio-input d-flex flex-wrap gap-2">
    <label
      class="d-flex align-items-center gap-1 pr-1 cursor-pointer"
      v-for="(item, index) in selectOptions"
      style="line-height: 1.2em"
      :key="index"
    >
      <input
        class="form-check-input m-0"
        type="checkbox"
        :id="col.name + '_true'"
        :name="col.name + '--' + index + '--' + _.uid"
        :value="item.value"
        :checked="isChecked(item.value)"
        @change="() => change(item.value)"
      />
      {{ selectionLabels?.[item.value] || item.text || item.label }}
    </label>
  </div>
</template>
<script lang="ts">
import { PropType } from 'vue'
import { SelectOptionItem } from '../../../common/$models/ModelDef'

/**
 * # MultiselectCheckboxesInput.vue
 * - MULTISELECT を チェックボックスで表現するためのInput
 */
export default {
  name: 'MultiselectCheckboxesInput',
  props: {
    record: {},
    modelValue: {
      type: Array as PropType<boolean[]>,
      default: () => [],
    },
    col: {},
    colInputSelection: {},
    selectionLabels: {
      type: Object as PropType<Record<string, any>>,
      default: () => ({}),
    },
  },
  computed: {
    options() {
      return this.colInputSelection
    },
    selectOptions(): SelectOptionItem[] {
      if (Array.isArray(this.options) && ['string', 'number'].includes(typeof this.options[0])) {
        return this.options.map((value) => ({
          value,
          label: this.col?.customLabel ? this.col.customLabel(value) : value,
        }))
      }
      return this.options
    },
  },
  methods: {
    isChecked(optionValue) {
      return this.modelValue?.includes(optionValue)
    },
    change(optionValue) {
      const newValue = [...(this.modelValue || [])]
      if (this.isChecked(optionValue)) {
        newValue.splice(newValue.indexOf(optionValue), 1)
      } else {
        newValue.push(optionValue)
      }
      this.$emit('update:modelValue', this.sortByColInputSelection(newValue))
    },
    sortByColInputSelection(arr) {
      return this.selectOptions.map((option) => option.value).filter((value) => arr.includes(value))
    },
  },
}
</script>
