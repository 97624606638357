import { ColumnDefByColName } from '../$models/ModelDef'
import { cleanupDataForSave } from './cleanupDataForSave'
import { ModelFactory } from '../$models'

const columnsThatShouldBeNullifiedIfTheValueIsEmptyString = [
  'NUMBER',
  'DECIMAL',
  'FLOAT',
  'DOUBLE',
  'BIGINTEGER',
  'DATETIME',
  'ITME',
  'DATEONLY',
  'RELATIONSHIP_MANY_TO_ONE',
]

export class TransformDataBeforeSave {
  data: { [key: string]: any }
  columns: ColumnDefByColName
  model: ModelFactory

  constructor(data, model: ModelFactory) {
    this.data = data
    this.model = model
    this.columns = model.columns
  }

  run(): any {
    Object.keys(this.data || {}).map(key => {
      const columnType = this.columns[key]?.type
      // M2O Relationship で Object として Nested 保存しようとしている場合には、再帰的に変換する
      if(columnType === 'RELATIONSHIP_MANY_TO_ONE') {
        if (typeof this.data[key] === 'object') {
          this.data[key] = new TransformDataBeforeSave(this.data[key], $core.$models[this.columns[key].relationshipManyToOne.collectionName]).run()
        }
      }
      // O2M Relationship で Object として Nested 保存しようとしている場合には、再帰的に変換する
      if (columnType === 'RELATIONSHIP_ONE_TO_MANY' && typeof this.data[key] === 'object' && Array.isArray(this.data[key]) && this.data[key]?.length > 0) {
        this.data[key] = this.data[key].map(d => {
          if(typeof d === 'object') {
            return new TransformDataBeforeSave(d, $core.$models[this.columns[key].relationshipOneToMany.collectionName]).run()
          }
          return d
        })
      }
      if (this.data[key] === '') {
        if (columnsThatShouldBeNullifiedIfTheValueIsEmptyString.includes(columnType)) {
          this.data[key] = null
        }
      }
      // Unique 制約がかかっていて、値がfalsyならnullでないと保存できない (unique空文字禁止)
      if (this.columns[key]?.unique === true && !this.data[key]) {
        this.data[key] = null
      }
      if (columnType === 'BOOLEAN') {
        this.data[key] = !!this.data[key]
      }
    })
    return cleanupDataForSave(this.data, this.model)
  }
}
