import { singletonInstanceSummoner } from './singletonInstanceSummoner'
import { makeItReactive } from '../front/$frameworkUtils/$frameworkUtils'

/**
 * # $core.$uiState
 * `$core.$uiState` 画面 (Vueコンポーネント) 全体を通じたUIの状態 (Reactive) を保持するサービス
 *
 * Vueコンポーネント側で、`$core.$uiState[propName]` を参照 もしくは watch することで、画面表示を切り替えたり、アクションを実行するhookとすることが可能。 (オブジェクト全体がVue Reactive になっている)
 *
 * ```ts
 * // Vueコンポーネント内で、画面上でデータ更新があった際に メソッドを発火する例
 * export default {
 *   ...,
 *   watch: {
 *     // ユーザがデータを更新 or 削除したら、fetchData() を発火
 *     '$core.$uiState.userLatestStoreMethodActionTime'() {
 *       this.fetchData()
 *     },
 *   }
 * }
 * ```
 *
 * ```vue
 * // Vueコンポーネント内で、現在表示中のアプリケーション定義名を表示
 * <template>
 *   <div>{{ $core.$uiState.appName }}</div>
 * </template>
 * ```
 *
 * @coreDocPath $core/20.ui/204.uiState
 */
export class UiState {
  /**
   * 一覧画面で表示する件数を保持
   * @hidden
   */
  hitsPerPage: number = 50
  /**
   * ユーザがデータを更新 or 削除した際の最新のタイムスタンプ (ミリ秒)
   */
  userLatestStoreMethodActionTime: number | null = null
  userLatestStoreMethodActionModelName: string | null = null
  /**
   * @hidden
   */
  latestModelDefinitionLoadedTime: number | null = null
  /**
   * UI action 実行中の状態を保持, like "dragging-chart-items"
   */
  motion: string = ''
  /**
   * @hidden
   * CORE Front app 初期化済の際に、resolved になる Promise を保持。
   * レイアウトコンポーネント側で
   * await $core.$uiState.initLoadedPromise
   * とすることで、Frontの準備完了までawaitできる。
   */
  initLoadedPromise: Promise<any>
  /**
   * @hidden
   */
  __initLoadedResolveFunc: Function
  /**
   * HTML \<body\> タグに付与される css class(es) を配列で保持
   *
   * ```ts
   * // body に class を付与
   * $core.$uiState.bodyClasses.push('my-added-class')
   * ```
   */
  bodyClasses: string[] = []
  /**
   * モバイルサイズのみ, sidebarを開く/閉じるの制御
   */
  sidebarActive: boolean = false

  appRenderedAt: number = Date.now()

  constructor() {
    this.initLoadedPromise = new Promise(resolve => {
      this.__initLoadedResolveFunc = () => {
        resolve(true)
      }
    })
  }

  /**
   * body class を返す
   * CORE/src/front/AppInitializer/CoreAppMain.vue にてwatchして制御している
   */
  get wrapperClasses() {
    return [
      this.wrapperClassesByUserRoles,
      this.bodyClasses.join(' '),
      this.appName ? `core-child-app core-app-name--${this.appName}` : 'core-app--admin',
    ].join(' ')
  }

  /**
   * 現在のアプリケーション定義名称を取得
   */
  get appName(): string {
    return $core.$appDefinitionLoader?.appDefinition?.name
  }

  private get wrapperClassesByUserRoles() {
    return (
      ($core.$embAuth.user?.isAdmin ? 'core-user-admin-role ' : '') +
      ($core.$embAuth.user?.coreRoles || [])
        .map(roleName => `core-user-role--${roleName}`)
        .join(' ')
    )
  }

  /**
   * @hidden
   */
  static get instance(): UiState {
    return singletonInstanceSummoner('UiState', UiState)
  }
}

export const $uiState = makeItReactive<UiState>(UiState.instance)
