/**
 * FrontConfigurationsForComposableFunctionsManager
 */
import { singletonInstanceSummoner } from '../../../common/singletonInstanceSummoner'
import { ModelServerSideAppHooks } from '../models/serverSideAppHooksModel'
import { ColumnDefByColName } from '../../../common/$models/ModelDef'

/**
 * serverSideAppHooks (サーバサイドカスタムHooks) にて、UI上から設定可能な挙動の定義
 * 例: コメント追加時に通知を生成する, データ保存時に特定の関数を実行する 等
 */
export type FrontConfigurationsForComposableFunction = {
  key: string
  label: string
  selectable: (record: ModelServerSideAppHooks) => boolean
  /**
   * サービス毎の挙動のオプションを指定できる、ネストしたカラム定義
   * 下記で指定された内容を、サーバサイドのサービスが受け取って、挙動のオプションとして利用する
   */
  argumentsConfigColumns?: ColumnDefByColName
}

/**
 * サーバサイドのサービス設定を実施するための、Frontのための設定を管理する
 */
class FrontConfigurationsForComposableFunctionsManager {
  configs: { [key: string]: FrontConfigurationsForComposableFunction } = {}

  static get instance() {
    return singletonInstanceSummoner(
      'FrontConfigurationsForComposableFunctionsManager',
      FrontConfigurationsForComposableFunctionsManager,
    )
  }

  register(frontConfig: FrontConfigurationsForComposableFunction) {
    this.configs[frontConfig.key] = frontConfig
  }

  getSelectableConfigs(childRecord, recordRoot) {
    return Object.values(this.configs).filter((config) => config.selectable(recordRoot))
  }
}

export const $frontConfigurationsForComposableFunctionsManager =
  FrontConfigurationsForComposableFunctionsManager.instance
