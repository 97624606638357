<template>
  <div v-if="isValidState" style="max-width: 100%">
    <slot/>
  </div>
</template>
<script lang="ts">
import { ComposableDataListService } from '../ComposableDataListService'
import { registerComposableComponentSettings } from '../../../ComposableComponents'
import {
  modelNameSelectColumnDefWithClearVirtualModelNameColumnOnEditCallback,
  virtualModelNameSelectColumnDefWithUpdateModelNameColumnOnEditCallback,
} from '../../../../common/$models/modelsSelectionsHelperFunctions'
import { generateComposableDataListDefaultComponentStructure } from '../generateComposableDataListDefaultComponentStructure'
import { computed } from 'vue'
import { codeInputCommonAttrs } from '../../../../common/$models'

registerComposableComponentSettings('ComposableDataList', {
  label: 'ComposableDataList',
  hasDefaultSlot: true,
  description: `ComposableDataList は、データリストを表示するためのコンポーネントです。`,
  images: [
    {
      img: '/images/cc_images/ComposableDataList1.png',
    },
  ],
  configColumns: {
    modelName: {
      ...modelNameSelectColumnDefWithClearVirtualModelNameColumnOnEditCallback('virtualModelName'),
    },
    virtualModelName: {
      ...virtualModelNameSelectColumnDefWithUpdateModelNameColumnOnEditCallback('modelName'),
    },
    disableFindDataOnLoad: {
      label: 'Load時にデータを取得しない',
      type: 'BOOLEAN',
    },
    disableFindDataOnQueryChange: {
      label: '検索条件変更時にデータを自動再検索しない',
      type: 'BOOLEAN',
    },
    disableRefreshDataOnDataEdited: {
      label: 'データ追加・変更時に自動再検索しない',
      type: 'BOOLEAN',
    },
    defaultSort: {
      label: 'デフォルト表示順',
      type: 'MULTISELECT',
      inputHelpText: '複数ソートを指定することが可能です。',
      selections: (record, currentValue, initialValue, recordRoot, callerVueInstance) => {
        const modelName = record?.modelName
        if (!modelName) {
          return []
        }
        return $core.$models[modelName].colNames.reduce((acc, colName) => {
          const labelBase = `${$core.$models[modelName].columns[colName].label || ''}[${colName}]`
          acc.push({
            label: `${labelBase} 昇順`,
            value: colName,
          })
          acc.push({
            label: `${labelBase} 降順`,
            value: `-${colName}`,
          })
          return acc
        }, [])
      },
    },
    defaultLimit: {
      label: 'デフォルト表示件数',
      type: 'NUMBER',
      inputHelpText: '上限なしに設定するには -1 を指定します。',
    },
    filteringCondition: {
      label: '固定の絞り込み条件',
      type: 'TEXT',
      inputComponent: {
        template: '<FilterResultDisplayContainer v-if="modelName" :emit-value-as-object="true" :collectionName="modelName"/>',
        computed: {
          modelName() {
            return this.$parent.record?.modelName
          },
        },
      },
      editCallback: ({ row, newValue, oldValue }) => {
        if (newValue !== oldValue && newValue) {
          row.__func__filter = `return ${JSON.stringify(newValue, null, 2)}`
        }
        return row
      },
      enableIf: (row) => !row.useFilteringConditionFunction,
    },
    useFilteringConditionFunction: {
      hideLabel: true,
      label: '関数でフィルター条件を定義する',
      type: 'BOOLEAN',
    },
    __func__filter: {
      enableIf: (row) => !!row.useFilteringConditionFunction,
      label: '固定のフィルター条件 (関数)',
      type: 'TEXT',
      inputAttrs: {
        ...codeInputCommonAttrs,
      },
      afterComponent: `<div class="small">変数 <code>thisInstance</code> が利用可能です。 <pre class="bg-light p-1">// 設定例:
return {
  name: {
    _contains: 'somestring',
  }
}</pre></div>`,
    },
  },
  defaultProps: {
    modelName: 'selectOptionsMaster',
    isShowModernFilter: false,
  },
  // TODO: 個別概念を切り出し...
  children: async (setting) => {
    return generateComposableDataListDefaultComponentStructure({})
  },
})

export default {
  name: 'ComposableDataList',
  props: {
    modelName: {
      type: String,
      required: true,
    },
    virtualModelName: {
      type: String,
    },
    disableFindDataOnLoad: {
      type: Boolean,
    },
    disableFindDataOnQueryChange: {
      type: Boolean,
    },
    disableRefreshDataOnDataEdited: {
      type: Boolean,
      default: false,
    },
    defaultLimit: {
      type: Number,
      default: 100,
    },
    defaultSort: {
      type: Array,
      default: null,
    },
    fixedFilter: {
      type: Object,
    },
    fields: {
      type: Array,
      default: null,
    },
  },
  provide() {
    return {
      ComposableDataListServiceInstance: computed(() => this.ComposableDataListServiceInstance),
      $dls: this.ComposableDataListServiceInstance,
    }
  },
  data() {
    return {
      ComposableDataListServiceInstance: new ComposableDataListService(),
    }
  },
  computed: {
    isValidState() {
      return this.ComposableDataListServiceInstance?.isValidState
    },
    hasValidFixedFilterUIAppliedResolveFunction() {
      if (this.$attrs.useFilteringConditionFunction) {
        return !!this.$attrs.__func__filter
      }
      return Object.keys(this.$attrs.filteringCondition || {}).length > 0
    },
    fixedFilterUIAppliedResolveFunction() {
      return async () => {
        /**
         * filteringCondition
         * useFilteringConditionFunction
         * __func__filter
         */
        try {
          if (this.$attrs.useFilteringConditionFunction) {
            return $core.$utils.executeStringDefinedFunction({
              functionString: this.$attrs.__func__filter,
              functionArgValues: {
                thisInstance: this,
                ComposableDataListServiceInstance: this.ComposableDataListServiceInstance,
              },
            })
          }
          return this.$attrs.filteringCondition || {}
        } catch (error) {
          console.error('error:', error)
        }
      }
    },
  },
  async created() {
    this.ComposableDataListServiceInstance.modelName = this.modelName
    this.ComposableDataListServiceInstance.virtualModelName = this.virtualModelName
    this.ComposableDataListServiceInstance.disableFindDataOnLoad = !!this.disableFindDataOnLoad
    this.ComposableDataListServiceInstance.disableFindDataOnQueryChange =
      !!this.disableFindDataOnQueryChange
    this.ComposableDataListServiceInstance.disableRefreshDataOnDataEdited =
      !!this.disableRefreshDataOnDataEdited
    this.ComposableDataListServiceInstance.query.limit = this.defaultLimit
    this.ComposableDataListServiceInstance.query.applyFilter('CCDataListFixedFilter', this.fixedFilter)
    if(this.hasValidFixedFilterUIAppliedResolveFunction) {
      this.ComposableDataListServiceInstance.query.applyFilter('CCDataListFixedFilterUIApplied', await this.fixedFilterUIAppliedResolveFunction())
    }
    if (this.fields) {
      this.ComposableDataListServiceInstance.query.fields = this.fields
    }
    if (this.defaultSort?.length > 0) {
      this.ComposableDataListServiceInstance.query.sort = this.defaultSort
    } else {
      // Model定義で指定されたデフォルトソートを設定
      const modelDefinedSort =
        this.ComposableDataListServiceInstance.virtualModel?.defaultSort ||
        this.ComposableDataListServiceInstance.model?.defaultSort
      if (modelDefinedSort) {
        this.ComposableDataListServiceInstance.query.sort = modelDefinedSort
      }
    }
    // Valid state なら
    if (
      !this.ComposableDataListServiceInstance.disableFindDataOnLoad &&
      this.ComposableDataListServiceInstance.isValidState
    ) {
      setTimeout(() => {
        this.ComposableDataListServiceInstance.find()
      }, 50)
    }
  },
  beforeUnmount() {
    this.ComposableDataListServiceInstance = null
  },
}
</script>
