<template>
  <component
    v-if="hasResolved && refItem && refItem.isDeleting !== true && refItem._invisible !== true"
    v-bind="bindProps"
    :is="refItem.component"
    class="composable-component-item-renderer"
  >
    <template v-for="(child, index) in children" :key="child.id">
      <ComposableComponentItemRenderer
        v-if="child && child.isDeleting !== true && child._invisible !== true"
        :pagePath="`${currentPath}.children`"
        :childPath="index"
        :composableComponentItem="child"
      />
    </template>
  </component>
</template>

<script lang="ts">
import { ComposableComponentInstanceItem } from '../../../../ComposableComponents/ComposableComponentInstanceItem'
import { PropType } from 'vue'
import { composableComponentItemBeforeRenderResolver } from './composableComponentItemBeforeRenderResolver'

export default {
  inject: ['componentsById'],
  props: {
    composableComponentItem: {
      type: Object as PropType<ComposableComponentInstanceItem>,
      required: true,
    },
    pagePath: {
      type: String,
      default: '',
    },
    childPath: {
      default: '',
    },
  },
  data() {
    return {
      errors: {},
      additionalProps: {},
      hasResolved: false,
    }
  },
  async mounted() {
    // ココで、渡す props の dynamic 解決を実施することが可能だワン
    this.additionalProps = await composableComponentItemBeforeRenderResolver(
      this,
      this.composableComponentItem,
    )

    this.$nextTick(() => {
      this.hasResolved = true
    })
  },
  computed: {
    refItem() {
      return this.componentsById?.[this.composableComponentItem.id]
    },
    currentPath() {
      return `${this.pagePath}.${this.childPath}`
    },
    children() {
      return this.refItem.children?.filter((c) => !!c) || []
    },
    bindProps() {
      return {
        ...(this.refItem.configuredProps || {}),
        ...(this.additionalProps || {}),
        class: [
          this.$attrs.class,
          this.refItem.styleProps?.classes || '',
          this.additionalProps?.class || '',
        ],
        style: [
          this.$attrs.style,
          this.refItem.styleProps?.styles || '',
          this.additionalProps?.style || '',
        ],
        'data-cc-item-id': this.refItem.id,
        'data-cc-item-uid': this._.uid,
        'data-cc-item-component': this.refItem.component,
        'data-cc-current-path': this.currentPath,
      }
    },
  },
}
</script>
