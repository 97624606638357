<template>
  <div class="input-array-of-object p-1" v-if="initialized">
    <div class="card mb-1">
      <div class="card-body">
        <div v-for="(row, rowIndex) in this.v.blocks" :key="row.tempKey" :data-row="rowIndex">
          <OneRowOfConditionalExpressionBuilder
            :modelValue="row"
            @update:modelValue="change"
            :row="row"
            :rowIndex="rowIndex"
            :builderLabels="builderLabels"
            :propSelections="propSelections"
            :recordRoot="recordRoot"
            :ModelFormService="ModelFormService"
            class="row"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
// dependent on "../modelInput"
import OneRowOfConditionalExpressionBuilder from './OneRowOfConditionalExpressionBuilder.vue'
import { LogicalOperator } from './validateWithColDef'

export default {
  name: 'OneBlockOfConditionalExpressionBuilder',
  emits: ['update:model-value'],
  components: { OneRowOfConditionalExpressionBuilder },
  props: {
    // value: { required: true },
    col: { required: true },
    record: { required: true },
    commonAttrs: { required: false },
    recordRoot: {},
    block: {},
    blockIndex: {},
    builderLabels: { required: false, default: null },
    propSelections: { required: false, default: null },
    readOnlyMode: { required: false, default: null },
    ModelFormService: {},
  },
  data() {
    return {
      initialized: false,
      v: {
        blocks: [
          {
            propName: '',
            relationalOperator: '',
            threshold: '',
            logicalOperator: '',
            returnValue: '',
          },
        ],
      },
      errors: [],
    }
  },
  computed: {
    rowLength() {
      return !this.$parent.v.expressions ? 0 : this.$parent.v.expressions.length
    },
    minLength() {
      return 1
    },
    isMinLength() {
      return this.minLength >= this.rowLength
    },
    value() {
      return (
        this.$parent.v.expressions[this.blockIndex] ||
        this.$parent.value.expressions[this.blockIndex]
      )
    },
    enableControl() {
      return this.commonAttrs.enableControl !== false && this.readOnlyMode !== true
    },
  },
  mounted() {
    const v = this.value || {
      blocks: [
        {
          propName: '',
          relationalOperator: '',
          threshold: '',
          logicalOperator: '',
          returnValue: '',
        },
      ],
    }
    for (let value of v.blocks) {
      value.tempKey = Math.random()
    }
    Object.assign(this.v, { ...v })
    this.$nextTick(() => {
      const addRowNumbers = this.minLength - this.rowLength
      if (addRowNumbers > 0) {
        if (addRowNumbers) {
          this.addRow(addRowNumbers)
        }
      }
      this.initialized = true
    })
  },
  methods: {
    change(rowIndex, { value, error }, subColumn, keyName) {
      this.v.blocks[rowIndex][keyName] = value
      this.errors[rowIndex] = error
      if (keyName === 'logicalOperator' && value) {
        if (value === LogicalOperator['ならば']) {
          // それ以降の条件削除
          this.v.blocks.splice(rowIndex + 1)
        } else {
          // 条件変更or追加
          if (this.v.blocks.length == rowIndex + 1) {
            // 追加
            this.v.blocks.push({
              propName: '',
              relationalOperator: '',
              threshold: '',
              logicalOperator: '',
              returnValue: '',
            })
            //this.$set(this.v.blocks, rowIndex + 1, )
          } else {
            // 変更
            for (let i = rowIndex + 1; i < this.v.length; i++) {
              if (this.v.blocks?.[i]?.logicalOperator !== LogicalOperator['ならば']) {
                this.v.blocks[i].logicalOperator = value
              }
            }
          }
        }
      }
      this._changeCallback()
    },

    addRow(rows = 1, insertPosition = null) {},
    _changeCallback() {
      this.$emit('update:model-value', this.blockIndex, {
        value: this.v,
        error: this.errors.length ? this.errors.join(',') : '',
      })
    },
  },
}
</script>
