export class CanceledError extends Error {
  constructor(message: string = "Promise has been canceled") {
    super(message);
    this.name = "CanceledError";
  }
}

/**
 * # CancellablePromise
 * - Promiseをキャンセル可能なクラス
 * - 使い方は、Promise と同じ感覚
 *   - Promiseをキャンセルすると catch で CanceledError が返る
 * - キャンセルは、cancel() を call することで実行
 *
 *
 * ```ts
 * // 使用例: cancellable.cancel() を call することで、Promiseをキャンセル (cancel error を返す) できる
 * const cancellable = new CancellablePromise<number>((resolve, reject) => {
 *   setTimeout(() => {
 *     resolve(42)
 *   }, 1000)
 * })
 *
 * cancellable.then(value => console.log(value)).catch(error => {
 *   if (error instanceof CanceledError) {
 *     console.log("Promise was canceled")
 *   } else {
 *     console.error(error)
 *     throw error
 *   }
 * });
 *
 * // キャンセルを実行
 * cancellable.cancel();
 * ```
 */
export class CancellablePromise<T> {
  private promise: Promise<T>;
  public hasCanceled: boolean = false;

  constructor(executor: (resolve: (value: T | PromiseLike<T>) => void, reject: (reason?: any) => void, CancellablePromise: CancellablePromise<T>) => void) {
    this.promise = new Promise<T>((resolve, reject) => {
      executor(
        (value) => {
          if (this.hasCanceled) {
            reject(new CanceledError());
          } else {
            resolve(value);
          }
        },
        (reason) => {
          if (this.hasCanceled) {
            reject(new CanceledError());
          } else {
            reject(reason);
          }
        },
        this
      );
    });
  }

  cancel() {
    this.hasCanceled = true;
  }

  then(onfulfilled?: ((value: T) => T | PromiseLike<T>) | null, onrejected?: ((reason: any) => PromiseLike<never>) | null): Promise<T> {
    return this.promise.then(onfulfilled, onrejected);
  }

  catch(onrejected?: ((reason: any) => PromiseLike<never>) | null): Promise<T> {
    return this.promise.catch(onrejected);
  }

  async toPromise(): Promise<T> {
    return this.promise;
  }
}
