<template>
  <div>
    <div v-if="initialized === false">Not found modelName: {{ modelName }}</div>
    <div v-else-if="initialized" class="card">
      <app-hookable-component
        class="card-header"
        :resolve-hook-name="`CORE.component.model-index.${modelName}.header`"
      >
        {{ (virtualModel || model || {}).tableLabel }} 一覧
        <router-link
          :to="{ name: `${modelName}-new` }"
          class="btn btn-outline-primary ml-2 component-model-index-page--add-new-button"
        >
          <ficon type="plus" />
          新規追加
        </router-link>
        <router-link :to="{ name: `${modelName}-bulkUpsert` }" class="btn btn-outline-success ml-2"
          >一括更新 (作成 & 編集)</router-link
        >
      </app-hookable-component>
      <app-hookable-component
        class="card-body"
        :resolve-hook-name="`CORE.component.model-index.${modelName}`"
      >
        <ModelIndexDirectus :modelName="modelName" :virtualModelName="virtualModelName" />
      </app-hookable-component>
      <app-hookable-component
        :resolve-hook-name="`CORE.component.model-index.${modelName}.after`"
      />
    </div>
  </div>
</template>

<script lang="ts">
import ModelIndexDirectus from './ModelIndexDirectus.vue'

export default {
  components: {
    ModelIndexDirectus,
  },
  props: {
    passedVirtualModelName: {},
  },
  data() {
    return {
      initialized: null,
    }
  },
  computed: {
    modelName() {
      return this.$route.params.fallback_model
    },
    model() {
      return $core.$models[this.modelName]
    },
    virtualModelName() {
      return this.$route.query.virtualModel || this.passedVirtualModelName
    },
    virtualModel() {
      return $core.$virtualModels[this.virtualModelName] || null
    },
    virtualModelColumns() {
      return this.virtualModel ? this.virtualModel.colNames : null
    },
    routerLinkOption() {
      return {
        params: { fallback_model: this.modelName },
        query: this.virtualModelName ? { virtualModel: this.virtualModelName } : {},
      }
    },
  },
  created() {
    this.$nextTick(async () => {
      this.initialized = !!$core.$models[this.modelName]
    })
  },
  methods: {
    filterFunction(row) {
      if (!this.virtualModel) {
        return true
      }
      for (let [key, value] of Object.entries(this.virtualModel.dataFilters)) {
        if (row[key] != value) {
          return false
        }
      }
      return true
    },
  },
}
</script>
