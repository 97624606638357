import { ModelDirectusRevisions } from './directus_revisions'
import { singletonInstanceSummoner } from '../../common/singletonInstanceSummoner'

/**
 * Revisions のデータを元に、データを復元する
 */
export class RestoreRevisionService {
  // alias of run
  get restore() {
    return this.run
  }

  async runWithRevisionIds(revisionIds: number[]) {
    const revisionRecords = await $core.$models.directus_revisions.findByIds<
      ModelDirectusRevisions
    >(revisionIds)
    return this.run(revisionRecords)
  }

  async run(revisionRecords: ModelDirectusRevisions[]) {
    // 現在 そのIDのデータが存在していようがいまいが、保存する
    const revisionRecordsByModelName: {
      [modelName: string]: ModelDirectusRevisions[]
    } = revisionRecords.reduce((acc, revisionRecord) => {
      if (!acc[revisionRecord.collection]) {
        acc[revisionRecord.collection] = []
      }
      acc[revisionRecord.collection].push(revisionRecord)
      return acc
    }, {})
    console.log({ revisionRecordsByModelName })
    const modelNames = Object.keys(revisionRecordsByModelName)
    $core.$loading.start('データを復元しています', 'overlay')
    for (let i = 0; modelNames.length > i; i++) {
      const modelName = modelNames[i]
      const model = $core.$models[modelName]
      const modelLabel = model.tableLabel || modelName
      const primaryKeyName = model.primaryKeyColName
      // Reactive を分離
      const data = revisionRecordsByModelName[modelName].map(revisionRecord =>
        Object.assign({}, revisionRecord.data, {[primaryKeyName]: revisionRecord.item}),
      )
      try {
        await $core.$storeMethods.bulkUpsert({
          modelName,
          data,
          quiet: true,
        })
        $core.$toast.successToast(`"${modelLabel}" ${data.length}件 を復元しました`)
      } catch (e) {
        console.error(e)
        $core.$toast.errorToast(`"${modelLabel}" ${data.length}件 の復元に失敗しました`)
      }
    }
    $core.$loading.finish()
  }

  static get instance(): RestoreRevisionService {
    return singletonInstanceSummoner('RestoreRevisionService', RestoreRevisionService)
  }
}

export const $restoreRevisionService = RestoreRevisionService.instance
