<template>
  <div style="position: relative">
    <div class="d-flex align-items-center">
      <span class="column-label mr-2">{{ label }}</span>
      <b-form-select
        class="flex-1"
        :modelValue="operator"
        @update:modelValue="updateOperator"
        :options="options"
        size="sm"
      ></b-form-select>
      <a role="button" @click="toggleSetting">
        <Ficon class="ml-2" type="ellipsis-h" />
      </a>
    </div>
    <div class="mt-1" v-if="operator !== 'isnull' && operator !== 'isnotnull'">
      <div class="d-flex" v-if="operator === 'between'">
        <b-form-input
          type="number"
          size="sm"
          ref="filterItemFormInput"
          :modelValue="!!modelValue ? modelValue[0] : null"
          @update:modelValue="(_val) => changeModelValue(0, _val)"
        />
        <span class="ml-1 mr-1">~</span>
        <b-form-input
          type="number"
          size="sm"
          :modelValue="!!modelValue ? modelValue[1] : null"
          @update:modelValue="(_val) => changeModelValue(1, _val)"
        />
      </div>
      <div v-else>
        <b-form-input
          type="number"
          size="sm"
          ref="filterItemFormInput"
          :modelValue="modelValue"
          @update:modelValue="change"
        />
      </div>
    </div>
    <FilterItemManager :visible="isVisibleSetting" @onDismiss="onDismissDropDown" />
  </div>
</template>
<script lang="ts">
import { inject } from 'vue'
import { FilterItemService } from '../../../FilterItemService'
import { FILTER_OPERATOR_FOR_NUMBER } from '../../../FilterRuleService'
import FilterItemManager from '../FilterItemManager.vue'

export default {
  props: {
    modelValue: {
      type: String,
      required: true,
    },
  },
  components: {
    FilterItemManager,
  },
  setup() {
    return {
      filterItemService: inject<FilterItemService<any>>('filterItemService'),
    }
  },
  data() {
    return {
      operator: null,
      isVisibleSetting: false,
    }
  },
  computed: {
    label() {
      return this.filterItemService.colDef.label || this.filterItemService.colDef.name
    },
    options() {
      // { value: xxxx'', text: 'xxxxx' }のような形で返す
      return Object.keys(FILTER_OPERATOR_FOR_NUMBER).map((operator) => {
        return {
          value: operator,
          text: FILTER_OPERATOR_FOR_NUMBER[operator],
        }
      })
    },
  },
  watch: {
    'filterItemService.isFocus'(val) {
      if (val && this.$refs.filterItemFormInput) {
        this.$refs.filterItemFormInput.focus()
      }
    },
  },
  created() {
    this.operator = this.options.length > 0 ? this.options[0].value : null
    setTimeout(() => {
      this.$refs.filterItemFormInput.focus()
    }, 100)
  },
  methods: {
    toggleSetting() {
      this.isVisibleSetting = !this.isVisibleSetting
    },
    change(value) {
      this.$emit('update:modelValue', {
        value,
        operator: this.operator,
      })
    },
    updateOperator(value) {
      this.operator = value
      if (this.operator == 'between') {
        this.$emit('update:modelValue', {
          value: Array.isArray(this.modelValue) ? this.modelValue : [this.modelValue, null],
          operator: this.operator,
        })
      } else if (this.operator === 'isnull' || this.operator === 'isnotnull') {
        this.$emit('update:modelValue', {
          value: null,
          operator: this.operator,
        })
      } else {
        this.$emit('update:modelValue', {
          value: Array.isArray(this.modelValue) ? this.modelValue[0] : this.modelValue,
          operator: this.operator,
        })
      }
    },
    changeModelValue(index, value) {
      const newValue = [...this.modelValue]
      newValue[index] = value
      this.$emit('update:modelValue', {
        value: newValue,
        operator: this.operator,
      })
    },
    onDismissDropDown() {
      this.$emit('onDismissDropdown')
    },
  },
}
</script>
