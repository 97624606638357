import { singletonInstanceSummoner } from '../singletonInstanceSummoner'

type HookFunctionOld<T = any> = (currentValue, callerContext?, args?) => any | Promise<any>
export type HookFunction<T = any> = ((args?: T) => T | Promise<T>) | HookFunctionOld

export type AppHookRegisteredItem<T> = {
  func: HookFunction<T>
  identifier: string
  priority: number
}

interface stringValuedObject {
  [keyName: string]: string | stringValuedObject
}

type EmitOptions = {
  /**
   * 特定のhookのみ実行
   */
  identifier: string
}

const isProdEnv = process.env.NODE_ENV === 'production'

const defaultHookPriority = 1000

/**
 * # $core.$appHook
 *
 * CORE 側の挙動を上書きしたり、メイン処理ライフサイクルの前後に処理を追加したりするためのサービス。
 * CORE 側から発行されるイベントに対して処理を追加することができます。
 * - 一例:
 *   - ログイン完了後に、特定の処理を実行する関数を登録
 *   - 特定モデルの一覧表示を別のVueコンポーネントで上書きする
 *   - データ保存前に、データを整形する関数を登録
 * - 挙動イメージは、JavascriptのeventHandlerです。Promiseできる + 複数functionが登録できるeventEmitterとして挙動します。
 *
 * @coreDocPath $core/20.ui/210.appHook
 */
export class AppHook {
  constructor() {
    this._hooks = {}
  }

  public readonly _hooks: { [hookName: string]: AppHookRegisteredItem<any>[] }

  private getHook(hookName: string, identifier: string): AppHookRegisteredItem<any> | undefined {
    return this._hooks[hookName]?.find(hook => {
      return hook.identifier === identifier
    })
  }

  private getHooks(hookName: string): AppHookRegisteredItem<any>[] {
    if (!this._hooks[hookName]) {
      this._hooks[hookName] = []
    }
    return this._hooks[hookName].sort((a, b) => {
      return b.priority - a.priority
    })
  }

  private _log(msg, level = '') {
    if (!isProdEnv) {
      console.log(`%c[$appHook] ${msg}`, 'color: #8ebff0')
    }
  }

  removeHook(hookName: string, identifier: string = 'default'): void {
    this._hooks[hookName] = this._hooks[hookName].filter(hook => hook.identifier !== identifier)
  }

  removeHookAll(hookName: string) {
    this._hooks[hookName] = []
  }

  hasHook(hookName: string): boolean {
    return !!this._hooks[hookName] && this._hooks[hookName].length > 0
  }

  /**
   * Hook関数を登録
   * identifier が同じものがあれば上書き
   * @param hookName - hook名, 例: '$CORE.appDefinitionLoaded'
   * @param func - hook関数を指定
   * @param identifier - hook関数の識別子を指定, 重複する場合は上書きされる
   * @param priority - 優先度を設定, 数字が大きいほど 優先度が高い
   */
  on<T>(hookName: string, func: HookFunction<T>, identifier: string = null, priority = defaultHookPriority) {
    if (identifier) {
      const prevFunc = this.getHook(hookName, identifier)
      if (prevFunc) {
        this._log(`Override hook: ${hookName}`)
        this.removeHook(hookName, identifier)
      }
    }
    this.getHooks(hookName).push({ identifier, func, priority })
  }

  /**
   * # Hookを発火する
   *
   * - 複数のhookが登録されている場合、 `emit()` の実行では、引数に与えられた値をチェインする形で、登録されたhook関数を順番に実行します。
   * - そのため、引数を "加工したい" 場合に適しています。
   * - 加工する必要がなく、登録された各関数の実行結果を無視したい場合は、 `emitAllParallel()` を使用してください。
   */
  async emit<T>(hookName: string, hookArgs?: T, options?: EmitOptions): Promise<T | undefined> {
    const hooks: AppHookRegisteredItem<T>[] = this.getHooks(hookName)
    this._log(`emitted: ${hookName}`)

    if (!hooks || hooks.length === 0) {
      const phase = hookName.split('').pop()
      if (phase === 'main') {
        this._log(`No hooks for ${hookName}`)
      }
      return hookArgs
    }

    if (options?.identifier) {
      this._log(`executing by specified identifier: ${options?.identifier} in ${hookName}`)
      const hook = this.getHook(hookName, options.identifier)
      return hook?.func(hookArgs)
    }

    /**
     * with hook Args
     * execute hook functions synchronously
     * Suggestion: use identifier for sorting?
     */
    if (hookArgs) {
      return hooks.reduce(async (transformedHookArgs, hook) => {
        try {
          this._log(`executing: ${hook.identifier} in ${hookName}`)
          const ret = await hook.func(await transformedHookArgs, hookArgs)
          // 値が返されなければ、引数をそのまま返す
          return ret === undefined ? transformedHookArgs : ret
        } catch (e) {
          console.error(`[plugin/app-hooks] error in ${hookName}(${hook.identifier})`, e)
          throw e
        }
      }, Promise.resolve(hookArgs))
    }

    /**
     * no hook Args
     * execute hook functions "a"synchronously
     */
    try {
      await Promise.all(hooks.map(hook => hook.func(null)))
    } catch (e) {
      throw e
    }
    return hookArgs
  }

  /**
   * # Hookを発火する (並列実行)
   *
   * - 複数のhookが登録されている場合、 `emitAllParallel()` の実行では、登録されたhook関数を並列に実行します。
   * - そのため、引数を "加工したい" 場合には、 `emit()` を使用してください。
   */
  async emitAllParallel(hookName: string, hookArgs?: any): Promise<any[]> {
    const hooks: AppHookRegisteredItem<any>[] = this.getHooks(hookName)
    this._log(`emitAllParallel: ${hookName}`)
    if (!hooks || hooks.length === 0) {
      return []
    }
    // 並列実行で結果を配列で返す
    return Promise.all(
      hooks.map(hook => {
        try {
          this._log(`executing: ${hook.identifier} in ${hookName}`)
          return hook.func(hookArgs)
        } catch (e) {
          console.error(`[$appHook] error in ${hookName}(${hook.identifier})`, e)
          return null
        }
      }),
    )
  }

  async emitBasicHookFlow<T>(hookKeys: BasicHookKeys, hookArgs?: T) {
    try {
      const retBeforeHook = await this.emit(hookKeys.Before, hookArgs)
      const retMainHook = await this.emit(hookKeys.Main, retBeforeHook)
      const retAfterHook = await this.emit(hookKeys.After, retMainHook)
      return this.emit(hookKeys.Success, retAfterHook)
    } catch (e) {
      console.error(`[plugin/app-hooks] error in emitBasicHookFlow (${hookKeys.Base})`, e)
      await this.emit(hookKeys.Error, e)
    }
  }

  /**
   * 真のシングルトンを達成するためのworkaround
   * Ref: https://gist.github.com/dmnsgn/4a6ad76de1b5928f13f68f406c70bb09#gistcomment-3470400
   * @hidden
   */
  static get instance(): AppHook {
    return singletonInstanceSummoner(AppHook.name, AppHook)
  }

  /**
   * 後方互換: 登録されたHookを実行する
   * @param hookName - 実行するHookの名前: '$embCommerce.cart.updated.after'
   * @param currentValue - 現在の値, 処理によってはこの値が変更する対象となる (hook実行後の返り値)
   * @param callerContext
   * @param additionalArgs
   * @deprecated
   */
  async callHooks(
    hookName: string,
    currentValue: any = null,
    callerContext: any = null,
    additionalArgs: any = {},
  ): Promise<any> {
    if (!this.hasHook(hookName)) {
      return currentValue
    }
    const hooks = this.getHooks(hookName)
    this._log(
      `(callHooks, @deprecated) emitted: ${hookName}, ${hooks.length} functions`,
      'warn',
    )
    let result = currentValue
    for (let i = 0; i < hooks.length; i++) {
      const h = hooks[i]
      const func = h.func
      this._log(`(callHooks, @deprecated) executing: ${h.identifier} in ${hookName}`, 'warn')
      result = func(result, callerContext, additionalArgs)
      if (result && typeof result.then === 'function') {
        result = await result
      }
    }
    return result
  }

  /**
   * hookを登録しておく, このときに、priority 順で処理順番を制御できる。
   * functionName で重複制御, 再登録されることはない
   * @param hookName
   * @param functionName
   * @param func
   * @param priority
   * @deprecated
   */
  registerHook(
    hookName: string,
    functionName: string = 'd',
    func: HookFunction,
    priority = defaultHookPriority,
  ): void {
    if (!this._hooks[hookName]) {
      this._hooks[hookName] = []
    }
    if (this._hooks[hookName].find(h => h.identifier === functionName)) {
      this._log(`Overriding func: "${functionName}" of hook "${hookName}"`)
      this.removeHook(hookName, functionName)
    }
    this._hooks[hookName].push({
      func,
      identifier: functionName,
      priority,
    })
  }

  /**
   * 関数を実行するときに、 before hook, after hook をそれぞれCallする
   * @param mainFunc - メイン処理関数
   * @param hookBaseName
   * @param callerContext
   * @param currentValue
   * @param additionalArgs
   * @deprecated
   */
  async executeWithBeforeAfterHooks({
    mainFunc,
    hookBaseName,
    callerContext,
    currentValue = null,
    additionalArgs = null,
  }: {
    mainFunc: (currentValue, callerContext, additionalArgs) => any
    hookBaseName: string
    callerContext: any
    currentValue?: any
    additionalArgs?: any
  }) {
    if (!hookBaseName) {
      throw new Error(`[$appHook] Please set hookBaseName arg correctly`)
    }
    const hooks = genHookSet(hookBaseName)
    try {
      currentValue = await this.callHooks(hooks.BEFORE, currentValue, callerContext, additionalArgs)
      currentValue = await mainFunc(currentValue, callerContext, additionalArgs)
      currentValue = await this.callHooks(hooks.AFTER, currentValue, callerContext, additionalArgs)
      return currentValue
    } catch (e) {
      if (this.hasHook(hooks.ERROR)) {
        console.error(hooks.ERROR, e)
        return await this.callHooks(hooks.ERROR, e, callerContext, additionalArgs)
      } else {
        throw e
      }
    }
  }

  /**
   * Hook自体が、executableであるものをkickする。
   * hookBaseName.before => hookBaseName.do => hookBaseName.after (hookBaseName.error)
   * の4つのhookがそれぞれcallされる可能性がある
   * @param hookBaseName
   * @param callerContext
   * @param currentValue
   * @param additionalArgs
   * @deprecated
   */
  async doExecutableHook({
    hookBaseName,
    callerContext = null,
    currentValue = null,
    additionalArgs = null,
  }: {
    hookBaseName: string
    callerContext?: any
    currentValue?: any
    additionalArgs?: any
  }) {
    if (hookBaseName.endsWith('.do')) {
      hookBaseName = hookBaseName.replace(/\.do$/, '')
    }
    const execHookName = `${hookBaseName}.do`
    if (!this._hooks[execHookName]) {
      window.alert(
        `[$appHook] hookBaseName: ${hookBaseName} を実装してください。 $appHook.registerHook('${execHookName}', 'do', func: HookFunction, priority = 0)`,
      )
      return false
    }
    const mainFunc = () => this.callHooks(execHookName, currentValue, callerContext, additionalArgs)
    return this.executeWithBeforeAfterHooks({
      mainFunc,
      hookBaseName,
      callerContext,
      additionalArgs,
    })
  }

  /**
   * 登録されたHookを正規表現で探す
   * @param regExp
   * @deprecated
   */
  async findRegisteredHooksByHookNameRegularExpression(regExp) {
    return Object.keys(this._hooks).reduce((res, r) => {
      if (regExp.test(r)) {
        res[r] = this._hooks[r]
      }
      return res
    }, {})
  }
}

export const genHookSet = basePath => {
  return {
    baseName: basePath,
    BEFORE: `${basePath}.before`,
    AFTER: `${basePath}.after`,
    ERROR: `${basePath}.onerror`,
  }
}

export const genExecutableHookSet = basePath => {
  return {
    DO: `${basePath}.do`,
    ...genHookSet(basePath),
  }
}

type BasicHookKeys = ReturnType<typeof _generateBasicHookKeys>
const _generateBasicHookKeys = (base: string) => {
  return {
    Base: base,
    Before: `${base}.before`,
    Main: `${base}.main`,
    After: `${base}.after`,
    Error: `${base}.error`,
    Success: `${base}.success`,
  }
}
export const $appHook = AppHook.instance

export const generateBasicHookKeys = <U, T>(
  base: U extends string ? U : never,
  keys: T extends ReadonlyArray<string> ? T : never,
): {
  [key in typeof keys[number]]: BasicHookKeys
} => {
  const ret = {} as { [key in typeof keys[number]]: BasicHookKeys }
  keys.forEach(key => {
    key as typeof keys[number]
    const hookKey = `${base}.${key}`
    ret[key as typeof keys[number]] = _generateBasicHookKeys(hookKey)
  })
  return ret
}
