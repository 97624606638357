<template>
  <vue-datepicker v-bind="attrs" :model-value="modelValue" @update:model-value="(_v) => change(_v)">
    <template v-if="$slots['input-icon']" #input-icon>
      <slot name="input-icon" />
    </template>
  </vue-datepicker>
</template>

<style>
:root {
  --dp-font-size: 0.96em;
  --dp-font-family: monospace;
}
.dp__action_button {
  white-space: nowrap;
  font-size: 0.86em;
}
</style>

<script lang="ts">
import { VueDatePickerProps } from '@vuepic/vue-datepicker'
import { PropType } from 'vue'

export default {
  name: 'Datepicker',
  props: {
    /**
     * 保存する値のフォーマット
     * - 注: ISO8601 形式である点注意, displayFormat と異なる
     */
    format: { default: 'YYYY-MM-DD' },
    /**
     * 表示するフォーマット
     * - 注: Unicode token である点注意, format と異なる
     */
    displayFormat: { default: 'yyyy-MM-dd' },
    modelValue: { default: '' },
    datepickerProps: {
      default: () => ({}),
      type: Object as PropType<VueDatePickerProps>,
    },
    emptyValue: { default: null },
    changeFunction: { default: null },
    commonAttrs: {
      type: Object as PropType<Record<string, unknown>>,
      required: false,
      default: () => ({}),
    },
  },
  computed: {
    defaultVueDatepickerProps(): VueDatePickerProps {
      return {
        locale: 'ja',
        enableTimePicker: false,
        format: this.displayFormat,
        autoApply: true,
        highlight: [new Date()],
        selectText: '選択',
        cancelText: 'キャンセル',
      }
    },
    attrs() {
      return {
        ...this.defaultVueDatepickerProps,
        ...this.$attrs,
        ...this.datepickerProps,
        ...this.commonAttrs,
      }
    },
  },
  methods: {
    change(value) {
      if (this.changeFunction) {
        this.changeFunction(value)
        return
      }
      // 値のFormatに変換してEmit
      const newValue = value ? $core.$dayjs(value).format(this.format) : this.emptyValue
      if (this.modelValue === newValue) {
        return
      }
      this.$emit('update:modelValue', newValue)
    },
  },
}
</script>
