<template>
  <span
    v-if="isEnabled && badgeCount !== null"
    class="badge rounded-pill"
    :class="`bg-${badgeVariant}`"
    >{{ badgeCount }}</span
  >
</template>
<script lang="ts">
import { PropType } from 'vue'
import { AppMenuItem, getAmountBadgeCount } from './appMenuDefinitionParser'

export default {
  name: 'AppMenuRecordCount',
  props: {
    menuItem: {
      required: true,
      type: Object as PropType<AppMenuItem>,
    },
  },
  data() {
    return {
      badgeCount: null,
    }
  },
  computed: {
    isEnabled(): boolean {
      return !!(this.menuItem.amountBadgeVisible && this.menuItem.amountBadgeTargetModel)
    },
    badgeVariant() {
      if (this.badgeCount === 0) {
        return this.menuItem.amountBadgeStyleWhenZero || 'secondary'
      }
      return this.menuItem.amountBadgeStyle || 'danger'
    },
  },
  async mounted() {
    await this.refreshBadgeCount()
    // 自動で レコード保存後にカウントを更新する
    $core.$appHook.on(`${this.menuItem.amountBadgeTargetModel}.afterSave`, () => {
      setTimeout(() => {
        this.refreshBadgeCount()
      }, 1000)
    }, `AppMenuRecordCount-${this.menuItem.amountBadgeTargetModel}-${this._.uid}`)
  },
  methods: {
    async refreshBadgeCount() {
      if (this.menuItem.amountBadgeVisible) {
        this.badgeCount = await getAmountBadgeCount(
          this.menuItem.amountBadgeTargetModel,
          this.menuItem.amountBadgeFilterCondition,
        )
      }
    },
  }
}
</script>
