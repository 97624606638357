<template>
  <div
    class="d-flex core-layout--page-header bg-white py-2 border-bottom px-3 justify-content-between"
  >
    <h6 v-if="title" class="mb-0 bold">{{ title }}</h6>
    <slot />
  </div>
</template>
<script lang="ts">
import { registerComposableComponentSettings } from '../../../../plugins/ComposableComponents'

const name = 'PageHeader'
registerComposableComponentSettings(name, {
  hasDefaultSlot: true,
  label: 'ページヘッダー',
  configColumns: {
    title: {
      label: 'タイトル',
      type: 'STRING',
      defaultValue: '',
    },
  },
})
export default {
  name,
  props: {
    title: {
      type: String,
      default: '',
    },
  },
}
</script>
