import { ModelDef, SelectOptionItemObject } from '../../types'
import { codeInputCommonAttrs } from '../../common/$models'
import { displayModelNameWithLabel } from '../../common/utils'
import { addModelDoc } from '../../plugins/HelpDoc/coreDocsHelperFunctions'

const modelName = 'dataExportSettings'

addModelDoc(
  modelName,
  'データエクスポート設定では、Excel・CSV出力をするための、データ元 および 出力フォーマットを自由に作成可能です。定義後は、 <router-link to="/exportData">データエクスポート実行</router-link> 画面より利用可能です。<br/>必要に応じて、Javascript関数による変換処理・カスタマイズが可能であるため、多彩なケースに対応可能です。',
)
const _nestedColSelectionsByModelNameCache = {}

/**
 * nested col selections by modelName
 */
export const colSelectionsByModelName = (
  modelName: string,
  getNested = false,
): { [colName: string]: string } => {
  if (!$core.$models[modelName]?.colNames?.length) {
    return {}
  }
  const cacheKey = `${modelName}_${getNested ? 'nested' : 'flat'}`
  if (!_nestedColSelectionsByModelNameCache[cacheKey]) {
    _nestedColSelectionsByModelNameCache[cacheKey] = $core.$models[modelName].colNames.reduce(
      (res, colName) => {
        const colDef = $core.$models[modelName]?.columns?.[colName]
        // ここでは、選択肢としてはリレーション先のカラム名 "も" 表示する必要があるので...
        if (
          colDef &&
          getNested &&
          ['REFERENCE', 'RELATIONSHIP_MANY_TO_ONE'].indexOf(colDef.type) >= 0
        ) {
          // リレーショナブルであれば... リレーション先のカラム名すべてを選択肢に入れる, ドット連結で
          const relationalModelName =
            colDef.type === 'REFERENCE'
              ? colDef.referenceOfStore.storeName
              : colDef.relationshipManyToOne.collectionName
          const relatedModel = $core.$models[relationalModelName]
          relatedModel.colNames.map((colName2) => {
            const relatedModelColDef = relatedModel.columns[colName2]
            res[`${colName}.${colName2}`] =
              `${colName}.${colName2}` +
              (relatedModelColDef.label
                ? ` (${colDef.label ? `${colDef.label}.` : ''}${relatedModelColDef.label})`
                : '')
          })
        } else {
          res[colName] = colName + (colDef.label ? ` (${colDef.label})` : '')
        }
        return res
      },
      {},
    )
  }
  return _nestedColSelectionsByModelNameCache[cacheKey]
}

/**
 * カラム定義用, カラム選択の選択肢を生成 by modelName
 * getNested を true にすると、リレーショナブルなカラムの場合、リレーション先のカラムも選択肢に入れる
 * @param modelName
 * @param getNested
 */
export const colSelectionsByModelNameAsSelectOptions = (
  modelName: string,
  getNested = false,
): SelectOptionItemObject[] => {
  return Object.keys(colSelectionsByModelName(modelName, getNested)).map((key) => ({
    value: key,
    label: colSelectionsByModelName(modelName, getNested)[key],
  }))
}

export const nestedColSelectionsByModelName = (
  modelName: string,
): { [colName: string]: string } => {
  return colSelectionsByModelName(modelName, true)
}

export const colBehaviorTypeAndLabel = {
  direct: 'ダイレクト',
  func: '関数制御',
  // passThrough: 'パススルー',
}

export type ModelDataExportSettings = {
  name: string
  targetModelName: string
  desc: string
  // pathThroughOriginalProps: boolean
  useBeforeFormatFunction: boolean
  beforeFormatFunction: string
  useAfterFormatFunction: boolean
  afterFormatFunction: string
  beforeEachRowConvertFunction: string
  useDataFetchFunction: boolean
  dataFetchFunction: string
  fields: {
    behavior: string
    dataCol?: string
    tCol?: string
    tFunc?: string
    execPriority?: string
  }[]
}

const model: ModelDef = {
  tableName: modelName,
  tableLabel: 'データエクスポート設定',
  bulkControllable: true,
  tableComment: '',
  primaryKeyColType: 'UUID',
  defaultSort: {
    key: 'createdAt',
    order: 'desc',
  },
  formColumnGroups: {
    // basics: {
    //   type: 'tab',
    //   groupKey: 'tab1',
    //   label: '基本設定',
    // },
    fields: {
      type: 'tab',
      groupKey: 'tab1',
      label: '出力フィールド',
    },
    advanced: {
      type: 'tab',
      groupKey: 'tab1',
      label: '高度な設定',
    },
  },
  columns: {
    name: {
      label: 'エクスポート設定名',
      type: 'STRING',
      unique: true,
      validate: {
        notEmpty: true,
      },
      inputAttrs: { wrapperClass: 'col-4' },
      afterComponent:
        '<span class="small text-muted" v-if="$parent.value">/exportData/{{$parent.value}} <br/>で個別画面としてアクセス可能です。</span>',
    },
    targetModelName: {
      type: 'SELECT',
      label: 'データ 元モデル',
      editable: false,
      editableOnCreate: true,
      strictSelections: true,
      validate: {
        notEmpty: true,
      },
      selections: () => {
        return Object.keys($core.$models)
      },
      customLabel(val) {
        return displayModelNameWithLabel(val)
      },
      inputAttrs: { wrapperClass: 'col-4' },
    },
    desc: {
      label: '説明',
      type: 'TEXT',
      inputAttrs: { wrapperClass: 'col-4' },
    },
    useBeforeFormatFunction: {
      label: '変換処理前関数(全件対象)を利用する',
      type: 'BOOLEAN',
      visibleOnIndex: false,
      groupKey: 'advanced',
    },
    beforeFormatFunction: {
      label: '変換処理前関数(全件対象)',
      type: 'TEXT',
      comment:
        'async ({originalDataRows, instance}): Promise<any[]> => { (...) } のコンテキストで実行されます。originalDataRowsのプロパティを設定、書き換えください。',
      inputAttrs: {
        ...codeInputCommonAttrs,
        placeholder: 'originalDataRows = originalDataRows.filter(...)',
      },
      enableIf: { useBeforeFormatFunction: true },
      visibleOnIndex: false,
      groupKey: 'advanced',
    },
    useAfterFormatFunction: {
      label: '変換処理後関数(全件対象)を利用する',
      type: 'BOOLEAN',
      visibleOnIndex: false,
      groupKey: 'advanced',
    },
    afterFormatFunction: {
      label: '変換処理後関数(全件対象)',
      type: 'TEXT',
      comment:
        'async ({formattedDataRows, instance, originalDataRows}): Promise<any[]> => { (...) } のコンテキストで実行されます。formattedDataRows の配列を書き換えてください。また、 instance.downloadAsExcel = false とすることで、デフォルトのダウンロード挙動をストップすることが可能です。',
      inputAttrs: codeInputCommonAttrs,
      enableIf: { useAfterFormatFunction: true },
      visibleOnIndex: false,
      groupKey: 'advanced',
    },
    hideFrom: {
      label: 'エクスポート選択肢で非表示',
      type: 'BOOLEAN',
      groupKey: 'advanced',
    },
    useDataFetchFunction: {
      label: 'データフェッチ関数を利用する',
      type: 'BOOLEAN',
      visibleOnIndex: false,
      groupKey: 'advanced',
    },
    dataFetchFunction: {
      label: 'データフェッチ関数',
      type: 'TEXT',
      comment:
        'async ({filter, dataExporterInstance}): Promise＜any[]＞ => { (...) } のコンテキストで実行されます。エクスポート対象となるデータをreturnしてください。',
      afterComponent: `<div class="small" style="line-height: 1.6em">データ取得関数を入力します。例: <code class="bg-light p-1">return await dataExporterInstance.model.find({filter, fields: ['id','column1', 'column2'], limit: 1000})</code></div>`,
      inputAttrs: {
        ...codeInputCommonAttrs,
      },
      enableIf: { useDataFetchFunction: true },
      visibleOnIndex: false,
      groupKey: 'advanced',
    },
    fields: {
      groupKey: 'fields',
      hideLabel: true,
      enableIf: (row) => !!row.targetModelName && !row.pathThroughOriginalProps,
      label: '出力データ列毎の変換処理設定',
      type: 'ARRAY_OF_OBJECT',
      visibleOnIndex: false,
      inputAttrs: {
        wrapperClass: 'col-12',
      },
      minValueLength: 1,
      columns: {
        dataCol: {
          type: 'STRING',
          label: '出力データ列名',
          validate: { notEmpty: true },
          inputAttrs: { wrapperClass: 'col-2' },
        },
        behavior: {
          label: '出力挙動',
          type: 'SELECT',
          strictSelections: true,
          selections: () => Object.keys(colBehaviorTypeAndLabel),
          customLabel: (val) => colBehaviorTypeAndLabel[val],
          default: 'direct',
          validate: { notEmpty: true },
          inputAttrs: { wrapperClass: 'col-2' },
        },
        tCol: {
          type: 'STRING',
          label: 'カラム名',
          dynamicSelections: true,
          selections(record, currentValue, initialValue, recordRoot) {
            return Object.keys(nestedColSelectionsByModelName(recordRoot.targetModelName))
          },
          customLabel(val, callerVueInstance, recordRoot) {
            return nestedColSelectionsByModelName(recordRoot.targetModelName)[val] || ''
          },
          inputAttrs: { wrapperClass: 'col-3' },
          enableIf: (row, parentRecord) => row.behavior === 'direct',
        },
        // execPriority: {
        //   type: 'NUMBER',
        //   label: '処理順',
        //   inputAttrs: {wrapperClass: 'col-1', placeholder: '0'},
        //   inputHelpText: '優先度 昇順',
        //   default: '',
        // },
        tFunc: {
          enableIf: (row, parentRecord) => row.behavior === 'func',
          label: '変換処理関数(オプション)',
          type: 'TEXT',
          comment:
            'async ({row, sourceDataRow, allSourceDataRows}): Promise<row> => { (...); return row } のコンテキストで実行されます。rowのプロパティを設定、書き換えください。',
          inputAttrs: {
            ...codeInputCommonAttrs,
          },
          default: '',
        },
      },
      validate: {
        notEmpty: true,
      },
    },
  },
  modelType: 'admin',
}

export const dataExportSettings = model

// action to import data
