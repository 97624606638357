type AnyObject = { [key: string]: any }

export function deepObjectDiff(obj1: AnyObject, obj2: AnyObject): AnyObject {
  const diff: AnyObject = {}

  for (const key in obj1) {
    if (!(key in obj2)) {
      diff[key] = obj1[key]
    } else if (
      typeof obj1[key] === 'object' &&
      typeof obj2[key] === 'object' &&
      !Array.isArray(obj1[key]) &&
      !Array.isArray(obj2[key])
    ) {
      const nestedDiff = deepObjectDiff(obj1[key], obj2[key])
      if (Object.keys(nestedDiff).length > 0) {
        diff[key] = nestedDiff
      }
    } else if (obj1[key] !== obj2[key]) {
      diff[key] = obj1[key]
    }
  }

  for (const key in obj2) {
    if (!(key in obj1)) {
      diff[key] = undefined
    }
  }

  return diff
}

export function deepObjectDiffWithoutProxy(obj1: AnyObject, obj2: AnyObject): AnyObject {
  const diff: AnyObject = {}

  for (const key in obj1) {
    if (!(key in obj2)) {
      diff[key] = obj1[key]
    } else if (Array.isArray(obj1[key]) && Array.isArray(obj2[key])) {
      const arrayDiff = deepArrayDiff(obj1[key], obj2[key])
      if (arrayDiff.length > 0) {
        diff[key] = arrayDiff
      }
    } else if (
      typeof obj1[key] === 'object' &&
      typeof obj2[key] === 'object' &&
      !Array.isArray(obj1[key]) &&
      !Array.isArray(obj2[key])
    ) {
      const nestedDiff = deepObjectDiff(obj1[key], obj2[key])
      if (Object.keys(nestedDiff).length > 0) {
        diff[key] = nestedDiff
      }
    } else if (obj1[key] !== obj2[key]) {
      diff[key] = obj1[key]
    }
  }

  for (const key in obj2) {
    if (!(key in obj1)) {
      diff[key] = undefined
    }
  }

  return diff
}

function deepArrayDiff(arr1: any[], arr2: any[]): any[] {
  const diff = []
  for (let i = 0; i < Math.max(arr1.length, arr2.length); i++) {
    if (typeof arr1[i] === 'object' && typeof arr2[i] === 'object') {
      const nestedDiff = deepObjectDiff(arr1[i], arr2[i])
      if (Object.keys(nestedDiff).length > 0) {
        diff.push(nestedDiff)
      }
    } else if (arr1[i] !== arr2[i]) {
      diff.push(arr1[i])
    }
  }
  return diff
}
