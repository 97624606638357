/**
 * Javascript による .focus() でうまくstyle が当たらない問題に対応するための関数
 * - focus / blur 時の css class toggle を実現する
 * - $core.$utils.programmaticFocusWithToggleClass(htmlElem)
 *
 * ## Usage
 *
 * ```ts
 * // Vue component などで
 * <script lang="ts">
 * export default {
 *   mounted() {
 *     // .focus() しつつ css class `.programmatic-focused` を付与 (blur 時に解除
 *     $core.$utils.programmaticFocusWithToggleClass(this.$refs.theFocusedButton.$el, {
 *       cssClassName: 'programmatic-focused' // optional, default は "programmatic-focused"
 *     })
 *   }
 * }
 * </script>
 * <style lang="scss">
 * .the-focused-button {
 *   &.programmatic-focused {
 *     outline: 4px solid rgba(255,255,255,0.3)
 *   }
 * }
 * </style>
 * ```
 *
 **/
export const programmaticFocusWithToggleClass = (
  elem: HTMLElement, {
    cssClassName = 'programmatic-focused',
  }: {
    cssClassName: string
  } = {
    cssClassName: 'programmatic-focused',
  }) => {
  if (!elem) {
    return
  }
  elem.addEventListener('blur', () => {
    elem.classList.remove(cssClassName)
  })
  elem.focus()
  elem.classList.add(cssClassName)
}

