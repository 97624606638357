import { ColumnDef, ModelDef } from '../../types'
import { addModelDoc } from '../../plugins/HelpDoc/coreDocsHelperFunctions'

const modelName = 'textTemplates'
addModelDoc(
  modelName,
  'テキストテンプレートは、メール送信時のテンプレート (通知文面の設定等) やその他システム内で利用される文面のテンプレートを定義します。',
)

export const customAttributeColumnDefinition: ColumnDef = {
  label: '追加の属性',
  type: 'ARRAY_OF_OBJECT',
  inputAttrs: {
    wrapperClass: 'col-12',
  },
  columns: {
    key: {
      label: 'データ項目名',
      type: 'STRING',
      inputAttrs: {
        wrapperClass: 'col-4',
      },
    },
    value: {
      label: '値',
      type: 'STRING',
      inputAttrs: {
        wrapperClass: 'col-7',
      },
    },
  },
}

export type ModelTextTemplate = {
  id: string
  key: string
  category: string
  name: string
  title: string
  body: string
  custom: {
    key: string
    value: string
  }[]
}

const model: ModelDef = {
  tableName: modelName,
  tableLabel: 'テキストテンプレート',
  bulkControllable: true,
  tableComment: '',
  primaryKeyColType: 'UUID',
  defaultSort: {
    key: 'createdAt',
    order: 'asc',
  },
  columns: {
    key: {
      type: 'STRING',
      unique: true,
      facet: true,
      label: 'ユニークキー',
      validate: { notEmpty: true },
      searchable: true,
      editable: false,
      editableOnCreate: true,
    },
    // type: {
    //   type: 'STRING',
    //   selections: () => ['', '通知メッセージ']
    // },
    category: {
      type: 'STRING',
      selectionsWithColNameFacet: 'category',
      label: 'カテゴリ',
      facet: { showOnSearch: true },
    },
    name: {
      type: 'STRING',
      label: '管理用名称',
      searchable: true,
    },
    title: {
      type: 'STRING',
      label: 'タイトル',
      inputAttrs: { wrapperClass: 'col-12' },
      validate: { notEmpty: true },
      inputHelpText: 'Handlebars の記法が利用できます https://handlebarsjs.com/guide/',
    },
    body: {
      type: 'TEXT',
      label: 'メッセージ',
      inputAttrs: {
        wrapperClass: 'col-12',
        rows: 20,
        style: 'line-height: 1.2em;',
      },
      validate: { notEmpty: true },
      inputHelpText: 'Handlebars の記法が利用できます https://handlebarsjs.com/guide/',
    },
    custom: customAttributeColumnDefinition,
  },
  modelType: 'admin',
}

export const textTemplates = model
