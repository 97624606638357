// @ts-ignore
import exportData from './exportData.vue'
import { $models, $modelsLoader } from '../../common/$models'
import { dataExportSettings } from './ModelDataExportSettings'

export const useDataExportSettings = () => {
  if (!$models.dataExportSettings) {
    $modelsLoader.loadModel(dataExportSettings)
  }
  $core.$router.addRoutes([
    {
      path: '/exportData/:exportSettingName?',
      component: exportData,
    },
  ])
}
