<template>
  <div :class="classes">
    <ficon type="book" style="font-size: 1.4em; margin-right: 2px; margin-left: 0" />
    <span>
      <slot />
<!--      <a style="font-size: 0.9em" v-if="docKey" :href="docUrl" target="_blank">
        {{ linkText || '詳細' }}<ficon type="external-link-square-alt" />
      </a>-->
    </span>
  </div>
</template>
<script lang="ts">
const name = 'HelpDoc'
const helpDocBaseUrl = 'https://docs.core-fw.com/#/a/v4-docs/findByKey'

export default {
  name,
  props: {
    docKey: { type: String, required: false },
    linkText: { type: String, required: false },
    inline: { type: Boolean, required: false, default: false },
  },
  computed: {
    docUrl() {
      return `${helpDocBaseUrl}?key=${this.docKey}`
    },
    classes() {
      return [
        'core-admin-role-only',
        this.inline
          ? 'd-inline-block _text-primary alert alert-primary-light p-1 m-0 small help-doc-inline'
          : 'alert alert-primary-light w-100 px-2 py-2 m-0 small help-doc d-flex _align-items-center',
      ]
    },
  },
}
</script>

<style lang="scss">
.help-doc {
  border: none !important;
  border-left: 2px solid var(--bs-primary) !important;
  border-top-left-radius: 3px !important;
  border-bottom-left-radius: 3px !important;
}
</style>
