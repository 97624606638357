import { ColumnDef, ColumnTypes, ComponentResolver } from '../../../common/$models/ModelDef'
import { defineAsyncComponent } from 'vue'

/**
 *
 */
const componentMap = {
  [ColumnTypes.File]: { component: 'input-file-selector' },
  [ColumnTypes.Boolean]: { component: 'BooleanCheckboxInput' },
  [ColumnTypes.RichText]: { component: 'rich-text-editor' },
  [ColumnTypes.Reference]: { component: 'ReferenceMultiSelect' },
  [ColumnTypes.RelationshipManyToOne]: { component: 'ReferenceMultiSelect' },
  [ColumnTypes.RelationshipOneToMany]: { component: 'relationship-one-to-many' }, // ExcelEditor に変更?
  [ColumnTypes.Select]: { component: 'SingleSelectInput' },
  [ColumnTypes.MultiSelect]: {
    component: 'InputMultiselectMultipleValue',
  },
  [ColumnTypes.Datetime]: { component: 'DatetimePicker' },
  [ColumnTypes.DateOnly]: { component: 'Datepicker' },
  [ColumnTypes.Time]: {
    component: 'TimePicker',
    addProps: {
      format: 'HH:mm:00',
      displayFormat: 'HH:mm',
      color: 'var(--bs-primary)',
    },
  },
  [ColumnTypes.Number]: { component: 'CInputNumber' },
  [ColumnTypes.Float]: { component: 'CInputNumber' },
  [ColumnTypes.Double]: { component: 'CInputNumber' },
  [ColumnTypes.Decimal]: { component: 'CInputNumber' },
  [ColumnTypes.BigInteger]: { component: 'CInputNumber' },
  [ColumnTypes.Text]: { component: 'b-form-textarea' },
  [ColumnTypes.JSON]: { component: 'code-editor' },
  [ColumnTypes.String]: { component: 'CInputString' },
  [ColumnTypes.ArrayOfObject]: { component: 'array-of-object' },
  [ColumnTypes.ConditionalExpression]: { component: 'conditional-expression' },
  [ColumnTypes.FileUpload]: { component: 'inputImageUploader' },
}

/**
 * ColumDef から、input用のコンポーネントを判定する関数
 */
export const judgeModelInputComponentTypeByColDef = (
  colDef: ColumnDef,
): { component: ComponentResolver; addProps?: Record<string, any> } => {
  if (colDef.inputComponent) {
    return {
      component: colDef.inputComponent,
      addProps: {},
    }
  }
  if (colDef.inputAttrs?.codeEditor === true) {
    return {
      component: 'code-editor',
      addProps: {},
    }
  }
  // radio
  if (colDef.type === ColumnTypes.Boolean && colDef.inputAttrs?.radio === true) {
    return {
      component: 'BooleanRadioInput',
      addProps: {},
    }
  } else if (colDef.inputAttrs?.radio === true) {
    return {
      component: defineAsyncComponent(
        () => import('../ModelColumnInputs/front/components/RadioSelectInput.vue'),
      ),
      addProps: {},
    }
  }
  // type: stringであり、selectionが定義されている場合
  if (colDef.type === ColumnTypes.String && colDef.selections) {
    return {
      component: 'SingleSelectInput',
      addProps: {},
    }
  }
  if (componentMap[colDef.type]) {
    return componentMap[colDef.type]
  }
  return {
    component: 'CInputString',
    addProps: {},
  }
}
