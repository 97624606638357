import { ComposableComponentInstanceItem } from '../ComposableComponents/ComposableComponentInstanceItem'
import { ComposableComponentSettings } from '../ComposableComponents/ComposableComponentSettings'

export type ComposableComponentAttrs = {
  styles: string | Record<string, any>
  classes: string | string[] | Record<string, any>
}

export type ComposableComponentData = {
  id?: string
  key: string
  name?: string
  urlPath?: string
  description?: string
  type?: string
  typeSub?: string
  modelName?: string
  virtualModelName?: string
  targetColumn?: string
  children?: ComposableComponentInstanceItem[]
  pageStyle?: ComposableComponentAttrs['styles']
}

// ComposableComponentSettings to ComposableComponentInstanceItem
export const convertComposableComponentSettingsToComposableComponentInstanceItem = async (
  setting: ComposableComponentSettings,
): Promise<ComposableComponentInstanceItem> => {
  setting = $core.$utils.deepmerge({}, setting)
  const composableComponentInstanceItem: ComposableComponentInstanceItem = {
    id: $core.$utils.generateRandomString(),
    label: setting.label || '',
    component: setting.componentDef as string,
    configuredProps: setting.defaultProps || {},
    children:
      typeof setting.children === 'function'
        ? await setting.children(setting)
        : setting.children || [],
  }
  return composableComponentInstanceItem
}

/**
 * ById で 参照しやすくした形式, データをLoadしたあとに、この形式に変換する
 * ComposableComponentRenderer.vue で参照される際にはこの形式に変換できている前提
 */
export type ComposableComponentDataWithByComponentIdRefs = ComposableComponentData & {
  componentById: Record<string, ComposableComponentInstanceItem>
}

/**
 * ComposableComponentInstanceItem の 構造体から、指定した名前の親を探す
 * @param item
 * @param parentComponentName
 */
export const findNearestParentComposableComponentInstanceItemByName = (
  item: ComposableComponentInstanceItem,
  parentComponentName: string,
): ComposableComponentInstanceItem => {
  if (!item.parent) {
    return null
  }
  if (item.parent.component === parentComponentName) {
    return item.parent
  }
  return findNearestParentComposableComponentInstanceItemByName(item.parent, parentComponentName)
}

export const convertComposableComponentDataToComposableComponentDataWithByComponentIdRefs = (
  ComposableComponentData: ComposableComponentData,
): ComposableComponentDataWithByComponentIdRefs => {
  const componentById: Record<string, ComposableComponentInstanceItem> = {}
  const convert = (
    composableComponentInstanceItem: ComposableComponentInstanceItem,
    parent: ComposableComponentInstanceItem | null,
  ) => {
    if (!composableComponentInstanceItem) {
      return // do nothing
    }
    if (!composableComponentInstanceItem?.id) {
      composableComponentInstanceItem.id = $core.$utils.generateRandomString()
    }
    componentById[composableComponentInstanceItem.id] = composableComponentInstanceItem
    composableComponentInstanceItem.children =
      composableComponentInstanceItem.children?.map((child) => {
        if (!child) {
          return
        }
        // set non numerable property
        try {
          Object.defineProperty(child, 'parent', {
            enumerable: false,
            get(): any {
              return composableComponentInstanceItem
            },
            configurable: true, // can re-define
          })
        } catch (e) {
          console.error(e)
        }

        return convert(child, composableComponentInstanceItem)
      }) || []
    return composableComponentInstanceItem
  }
  if (!ComposableComponentData.children) {
    ComposableComponentData.children = []
  }
  ComposableComponentData.children.map((child) => {
    convert(child, null) // no parent
  })
  return {
    ...ComposableComponentData,
    componentById,
  }
}

/**
 * ById で 参照しやすくした形式を、元の形式に戻す
 * @param ccData
 */
export const revertComposableComponentDataWithByComponentIdRefsToComposableComponentData = (
  ccData: ComposableComponentDataWithByComponentIdRefs,
): ComposableComponentData => {
  const revert = (ccItem: ComposableComponentInstanceItem) => {
    if (ccItem.isDeleting) {
      return null
    }
    if (!ccItem.children?.length) {
      return ccItem
    }
    ccItem.children = ccItem.children.reduce((acc, child) => {
      const c = ccData.componentById[child.id]
      if (c.isDeleting) {
        return acc
      }
      const revertChild = revert(c)
      if (!revertChild) {
        return acc
      }
      return [...acc, revertChild]
    }, [])
    return ccItem
  }
  ccData.children = ccData.children
    ?.map((child) => {
      return revert(child)
    })
    .filter((c) => !!c)
  delete ccData.componentById
  return ccData
}

export const ComposableComponent = {}
