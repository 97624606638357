import { ColumnDef } from './ModelDef'
import { displayModelNameWithLabel } from '../utils'

/**
 * モデル名を選択するカラム定義
 * @coreDocPath
 */
export const modelNameSelectColumnDef: ColumnDef = {
  label: 'モデル定義',
  type: 'STRING',
  strictSelections: true,
  dynamicSelections: true,
  validate: { notEmpty: true },
  async selections(record: any) {
    return $core.$modelsLoader.modelNames
  },
  customLabel: (val, callerVueInstance, recordRoot) => {
    return displayModelNameWithLabel(val)
  },
}

/**
 * モデル名を選択するカラム定義
 * with 選択したModel名が virtualModelName と矛盾がある場合には、virtualModelName をクリアする
 * @param virtualModelNameColumnName
 */
export const modelNameSelectColumnDefWithClearVirtualModelNameColumnOnEditCallback = (
  virtualModelNameColumnName: string,
): ColumnDef => {
  return {
    ...modelNameSelectColumnDef,
    editCallback({ row, newValue, oldValue }) {
      if (newValue !== oldValue && row[virtualModelNameColumnName]) {
        const currentBaseModelNameOfVirtualModelNameSelection =
          $core.$virtualModels?.[row[virtualModelNameColumnName]]?.baseModel
        if (currentBaseModelNameOfVirtualModelNameSelection !== newValue) {
          row[virtualModelNameColumnName] = ''
        }
      }
      return row
    },
  }
}

/**
 * Virtualモデル名を選択するカラム定義
 * @coreDocPath
 */
export const virtualModelNameSelectColumnDef: ColumnDef = {
  label: 'Virtualモデル定義',
  type: 'STRING',
  strictSelections: true,
  async selections(record: any) {
    return Object.keys($core.$virtualModels)
  },
  customLabel: (val, callerVueInstance, recordRoot) => {
    return displayModelNameWithLabel(val, true)
  },
}

/**
 * VirtualModel 名を指定する選択肢にて、modelNameカラムを併せて更新できるように
 * @param modelNameColumnName
 */
export const virtualModelNameSelectColumnDefWithUpdateModelNameColumnOnEditCallback = (
  modelNameColumnName: string,
): ColumnDef => {
  return {
    ...virtualModelNameSelectColumnDef,
    editCallback({ row, newValue, oldValue }) {
      if (newValue && oldValue !== newValue) {
        const baseModel = $core.$virtualModels?.[newValue]?.baseModel
        if (baseModel && row[modelNameColumnName] !== baseModel) {
          row[modelNameColumnName] = baseModel
        }
      }
      return row
    },
  }
}
