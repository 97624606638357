<template>
  <ul style="margin-top: -8px" class="nav mb-2">
    <li
      class="nav-item menu-item-keyword-search"
      :class="$parent.searchString || isFocusing ? 'searching' : ''"
    >
      <div class="nav-link" style="background: transparent !important;">
        <div class="input-group input-group-sm">
          <span class="input-group-text">
            <ficon type="search" />
          </span>
          <input
            type="text"
            placeholder="...メニューを検索"
            v-model="$parent.searchString"
            class="form-control"
            ref="menuSearchInput"
            @keydown.enter="event => selectTopOne(event)"
            @focus="isFocusing = true"
            @blur="isFocusing = false"
          />
        </div>
      </div>
    </li>
    <li class="nav-item" v-for="(searchTargetMenu, index) in filteredLinks">
      <router-link
        class="nav-link"
        :class="index === selectCursor ? 'router-link-exact-active' : ''"
        active-class="nullclass"
        exact-active-class="nullclass"
        :to="searchTargetMenu.to"
        >{{ searchTargetMenu.label }}
      </router-link>
    </li>
  </ul>
</template>
<style lang="scss">
.menu-item-keyword-search {
  transition: opacity ease-in 200ms;
  opacity: 0.4;
  &:hover,
  &:focus,
  &:active,
  &.searching {
    opacity: 1;
  }
}
</style>
<script>
let hasShortcutEventRegistered = false
export default {
  props: {
    appMenus: {
      required: true,
    },
  },
  data() {
    return {
      isFocusing: false,
      selectCursor: 0,
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.registerShortcutKey()
    })
  },
  watch: {
    $route: function(to, from) {
      this.$parent.searchString = ''
    },
  },
  computed: {
    searchTargetMenu() {
      return this.appMenus.reduce((res, object) => {
        if (object.type === 'link') {
          res.push(object)
        }
        if (object.type === 'nested' && object.children?.length) {
          const childrenTypeLinkItems = object.children.filter(
            childrenTypeLinkItem => childrenTypeLinkItem.type === 'link',
          )
          res = res.concat(childrenTypeLinkItems)
        }
        return res
      }, [])
    },
    filteredLinks() {
      if (!this.$parent.searchString) {
        return []
      }
      this.selectCursor = 0 // reset
      return this.searchTargetMenu.filter(searchTargetMenu => {
        return new RegExp(this.$parent.searchString, 'i').test(
          JSON.stringify(Object.values(searchTargetMenu)),
        )
      })
    },
  },
  methods: {
    selectTopOne(event) {
      // 日本語入力中のEnterキー操作は無効にする
      if (event.keyCode !== 13 || this.filteredLinks.length === 0) {
        return
      }
      const to = (this.filteredLinks[this.selectCursor] || this.filteredLinks[0])?.to
      // 同じリンクであればキーワードをクリアして do nothing
      if (this.$route.path === to) {
        this.$parent.searchString = ''
        return
      }
      // 遷移 to top link
      this.$router.push(to)
    },
    /**
     * Ctrl + S キーで、inputにfocus当たるように
     */
    registerShortcutKey() {
      if (hasShortcutEventRegistered) {
        return
      }
      hasShortcutEventRegistered = true
      window.addEventListener('keydown', event => {
        // UIブロックしないように、setTimeoutでフレームずらし
        setTimeout(() => {
          // input に focus
          if (event.ctrlKey && event.keyCode === 83 && this.$refs?.menuSearchInput?.focus) {
            this.$refs.menuSearchInput.focus()
          }
          // 検索キーワードが入力されているときだけ、
          if (this.$parent.searchString && this.filteredLinks.length) {
            // 下カーソルでfocus移動
            if (event.keyCode === 40 && this.filteredLinks.length > this.selectCursor + 1) {
              this.selectCursor = this.selectCursor + 1
            }
            // 上カーソルでfocus移動
            if (event.keyCode === 38 && this.selectCursor > 0) {
              this.selectCursor = this.selectCursor - 1
            }
          }
        }, 3)
      })
    },
  },
}
</script>
