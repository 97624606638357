import { ColumnDefByColName } from './ModelDef'

/**
 * Create a new record with default values
 * @param columns
 */
export const createNewRecordWithColumnDefs = async (
  columns: ColumnDefByColName,
): Promise<Record<string, any>> => {
  const newRecord = {}
  for (const colName in columns) {
    const columnDef = columns[colName]
    const defaultValue = columnDef.defaultValue !== undefined ? columnDef.defaultValue : columnDef.default
    if (defaultValue !== undefined) {
      newRecord[colName] = typeof defaultValue === 'function' ? await defaultValue() : defaultValue
    }
  }
  return newRecord
}
