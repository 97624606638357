import Big from 'big.js'
import { ModelFactory } from '../$models'

const isEmptyObject = (obj) => Object.keys(obj).length === 0 && obj.constructor === Object

export const removeUndefinedValuedKey = (obj) => {
  const isKeyValuedObject = typeof obj === 'object' && obj !== null
  if (!isKeyValuedObject) {
    return obj
  }
  const newObj = {}
  try {
    const keys = Object.keys(obj)
    keys.forEach((key) => {
      const data = obj[key]
      if (data === undefined) {
        return // ignore
      }
      // 予期せぬ User 情報の Update を block
      if (['userCreated', 'userUpdated'].includes(key)) {
        return // ignore
      }
      /**
       * 予期せぬパスワードの更新を防ぐ
       */
      if (key === 'password') {
        // falsy or data が 同じ文字の連続かどうか？ with 正規表現
        if (!data || /^(.)\1+$/.test(data)) {
          return // ignore
        }
      }

      if (Array.isArray(data)) {
        // filter `empty object`, `null`, `undefined`
        const filtered = data.filter((d) => {
          return !isEmptyObject(d) && d != null
        })
        newObj[key] = filtered.map(removeUndefinedValuedKey)
      } else if (typeof obj[key] === 'object') {
        try {
          newObj[key] = removeUndefinedValuedKey(obj[key])
        } catch (e) {
          throw e
        }
      } else if (obj[key] !== undefined) {
        newObj[key] = obj[key]
      }
    })
  } catch (e) {
    console.log({ obj })
    throw e
  }
  return newObj
}

/**
 * 削除対象のproperty
 */
const prohibitPropertyList = ['_highlightResult']

export const cleanupDataForSave = (newData: Record<string, any>, model: ModelFactory) => {
  if (!newData) {
    return newData
  }
  Object.keys(newData || {}).forEach((col) => {
    const val = newData[col]
    // BOOLEAN 型は 着実に true/false に変換
    if (model.columns[col]?.type === 'BOOLEAN') {
      newData[col] = !!val
    }
    if (val instanceof Big) {
      newData[col] = Number(val)
    }
  })
  // primary key が falsy であることはないので
  const primaryKeyColName = model.primaryKeyColName
  if (!newData[primaryKeyColName]) {
    delete newData[primaryKeyColName]
  }
  return removeUndefinedValuedKey(newData)
}
