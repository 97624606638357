<template>
  <BButton :variant="variant" @click="deleteAction">
    <span>{{ label }}</span>
  </BButton>
</template>
<script lang="ts">
import { ColumnDefByColName } from '../../../../common/$models/ModelDef'
import { registerComposableComponentSettings } from '../../../ComposableComponents'
import { inject } from 'vue'
import { CCModelFormOneRecordService } from '../CCModelFormOneRecordService'
import { normalAndOutlinedVariantsKeys } from '../../../ComposableComponents/composableComponentHelpers/variants'

export const configColumns: ColumnDefByColName = {
  label: {
    type: 'STRING',
    label: 'ラベル',
  },
  variant: {
    label: 'スタイル',
    type: 'STRING',
    selections: () => normalAndOutlinedVariantsKeys,
  },
}
const name = 'ModelFormOneRecordDeleteButton'
registerComposableComponentSettings(name, {
  label: 'ModelFormOneRecordDeleteButton',
  hasDefaultSlot: true,
  category: 'フォーム',
  configColumns: configColumns,
  defaultProps: {
    label: '削除する',
    variant: 'danger',
  },
})

export default {
  name,
  props: {
    label: {
      type: String,
      required: true,
    },
    variant: {
      type: String,
      required: true,
    },
  },
  setup() {
    return {
      CCModelFormOneRecordServiceInstance: inject(
        'CCModelFormOneRecordServiceInstance',
      ) as CCModelFormOneRecordService,
    }
  },
  methods: {
    deleteAction() {
      this.CCModelFormOneRecordServiceInstance.deleteData()
    },
  },
}
</script>
