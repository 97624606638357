<template>
  <div
    class="hoverShow control-display-of-app-hook-detail"
    style="
      position: fixed;
      bottom: 0;
      left: 0;
      z-index: 9999999;
      background: white;
    "
    :style="s ? 'opacity: 1' : ''"
  >
    <label class="btn btn-danger btn-sm text-white">
      <input :checked="s" type="checkbox" class="mr-1" @change="toggleClass()" />
      {{ s ? 'Hide' : 'Show' }} $appHooks
    </label>
  </div>
</template>
<script>
const className = 'showAppHookableInfo'
export default {
  data() {
    return { s: false }
  },
  mounted() {
    this.bodyClassRef = document.querySelector('body').classList
    this.s = window.localStorage.getItem(className) === 'true'
    if (this.s) {
      this.bodyClassRef.add(className)
    }
    this.$nextTick(() => {
      setTimeout(() => {
        if (this.s) {
          this.bodyClassRef.add(className)
        }
      }, 1000)
    })
  },
  methods: {
    toggleClass() {
      this.s ? this.bodyClassRef.remove(className) : this.bodyClassRef.add(className)
      this.s = !this.s
      window.localStorage.setItem(className, this.s ? 'true' : 'false')
    },
  },
}
</script>
<style scoped lang="scss">
.hoverShow {
  opacity: 0;
  &:hover {
    opacity: 1;
  }
}
</style>
