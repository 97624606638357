import { ModelDef } from '../../common/$models/ModelDef'

export type ModelDirectusActivity = {
  id
  collection
  item
  action
  user
  timestamp
  ip
  user_agent
  comment
  origin
}

const actions = {
  create: '作成',
  update: '更新',
  delete: '削除',
  login: 'ログイン',
}

export const directus_activity: ModelDef = {
  tableName: 'directus_activity',
  tableLabel: '利用ログ',
  doNotSyncModel: true,
  creatable: true,
  updatable: true,
  deletable: true,
  timestamps: false,
  modelType: 'admin',
  defaultSort: { key: 'id', order: 'desc' },
  defaultFieldsParamExpression: ['*.*.*'],
  columns: {
    id: { type: 'NUMBER', label: 'ID' },
    collection: { type: 'STRING', label: 'モデル名' },
    item: { type: 'STRING', label: 'レコードID' },
    action: {
      type: 'STRING',
      label: 'アクション',
      selections: () => Object.keys(actions).map((key) => ({ value: key, label: actions[key] })),
    },
    user: {
      type: 'STRING',
      label: 'ユーザ',
      labelFormatter: (row) => `${row.user?.first_name} (${row.user?.email})`,
    },
    timestamp: { type: 'STRING', label: '日時' },
    ip: { type: 'STRING', label: 'ユーザIP' },
    user_agent: { type: 'STRING', label: 'ブラウザ UserAgent' },
    comment: { type: 'STRING', label: 'コメント' },
    origin: { type: 'STRING', label: 'リクエスト元URL' },
  },
}
