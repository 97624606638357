import { ModelDef } from '../$models/ModelDef'

export const ipAccessSettings: ModelDef = {
  tableName: 'ipAccessSettings',
  tableLabel: 'ipAccessSettings',
  defaultSort: { key: 'createdAt', order: 'desc' },
  modelType: 'admin',
  columns: {
    ip: {
      type: 'STRING',
      unique: true,
      validate: { notEmpty: true },
      // TODO: put validation with regex
    },
    isEnabled: {
      type: 'BOOLEAN',
    },
    roles: {
      type: 'MULTISELECT',
      strictSelections: true,
      async selections() {
        const roles = await $core.$models.directus_roles.find()
        return roles.map(r => r.name)
      },
    },
    note: {
      type: 'STRING',
    },
  },
}
