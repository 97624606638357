<template>
  <Datepicker
    :model-value="valueHourAndMin"
    :changeFunction="change"
    :format="format"
    :displayFormat="displayFormat"
    :datepickerProps="datepickerProps"
    :time-picker="true"
    :enable-time-picker="true"
    :autoApply="false"
    v-bind="commonAttrs"
  >
    <template #input-icon>
      <ficon type="clock" class="ml-2 mt-1" style="font-size: 135%" />
    </template>
  </Datepicker>
</template>

<script lang="ts">
import { VueDatePickerProps } from '@vuepic/vue-datepicker'
import { PropType } from 'vue'

export default {
  name: 'TimePicker',
  props: {
    format: { default: 'HH:mm:ss' },
    displayFormat: { default: 'HH:mm' },
    modelValue: { default: '' },
    datepickerProps: { default: () => ({} as PropType<VueDatePickerProps>) },
    emptyValue: { default: null },
    commonAttrs: {
      type: Object as PropType<Record<string, unknown>>,
      required: false,
      default: () => ({}),
    },
  },
  computed: {
    valueHourAndMin() {
      if (this.modelValue) {
        const [hours, minutes] = this.modelValue.split(':')
        return { hours, minutes }
      }
      return null
    },
  },
  methods: {
    change(updateValue) {
      this.$nextTick(() => {
        if (!updateValue) {
          this.$emit('update:modelValue', this.emptyValue)
          return
        }
        this.$emit(
          'update:modelValue',
          `${`0${updateValue.hours}`.slice(-2)}:${`0${updateValue.minutes}`.slice(-2)}:00`,
        )
      })
    },
  },
}
</script>
