import { ModelNameSpecifiedClassBase } from '../../ModelServices/ModelNameSpecifiedClassBase'
import { ColumnDef, ColumnDefByColName } from '../../../common/$models/ModelDef'
import { colTypeInputTypeMap, inputTypes } from '../../../front/ModelForm/ModelInput'
import { CCModelFormOneRecordService } from './CCModelFormOneRecordService'
import { CCModelFormColumnService } from './CCModelFormColumnService'

/**
 * CCModelFormService - Composable ModelForm の 大元を司るサービス
 *
 * ## 前提
 * - Composable ModelForm は複数レコード編集可能である (もちろん単一でも編集可能)
 *   - ※ なので、設計的には常に "複数レコード編集のパターン" で考えたほうが良い
 * - 配下に、 `<ModelFormOneRecord/>` (更にその配下に `<ComposableModelInput/>`), `<ComposableModelFormSubmitButton>` `<ComposableModelFormDeleteButton/>` などがある
 * - Classの階層構造 (依存関係)
 *   - CCModelFormService: records 全体の管理, ここで、複数レコードにまたがる editCallback や validation などを走らせる。 method としては、保存や削除を実行する submit, delete を持つ。また、レコードの初期状態を定義するために、場合によっては、DBから値を取得する (or initialRecords で渡される)
 *     - CCModelFormOneRecordService: `<ModelFormOneRecord/>` で利用される。 1レコードの管理, ここで、editCallback や validation などを走らせる。 method としては submit, delete を **持たない**。
 *       - ComposableModelInputService: `<ComposableModelInput/>` にて初期化。 1フィールドの管理, カラム定義を渡される。 必須 props は col-name="title" のみ。 columnDefOverride で上書きカラムをpropsとして受け取る (親の親 CCModelFormService までマージされない範囲での、局所的上書き)
 *
 * ## 役割
 * -
 *
 *
 */
export class CCModelFormService extends ModelNameSpecifiedClassBase {
  initialRecords: any[]
  onSubmitFunction
  onDeleteFunction
  readOnlyMode
  filterCondition
  isLoading = false
  useColumns: ColumnDefByColName | null = null
  oneRecordServices: CCModelFormOneRecordService[] = []

  // 編集フォーム用のIDを保持する
  recordId: string | null = null
  // 編集フォーム用のconditionを保持する
  recordFindCondition: string | null = null

  /**
   * ColumnDef を基にした、カラムの挙動を司るサービス by カラム名
   */
  columnServices: { [colName: string]: CCModelFormColumnService } = {}
  private _calcCache: { [key: string]: any } = {}
  constructor() {
    super()
  }

  async init() {
    this._initColumnServices()
    await this._prepareRecords()
  }

  /**
   * CCModelFormColumnService の初期化
   */
  _initColumnServices() {
    Object.keys(this.filteredColumns).forEach((colName) => {
      this.columnServices[colName] = new CCModelFormColumnService(
        this.filteredColumns[colName],
        this,
      )
    })
  }

  /**
   * レコードのセットアップ
   * レコードを必要なら取得しつつ、 new CCModelFormOneRecordService(record, this)
   */
  private async _prepareRecords() {
    this.isLoading = true
    try {
      const records = await this.__returnInitialRecords()
      if (!records?.length) {
        // recordがなければ自動的に新規作成モードにする
        this.oneRecordServices = [new CCModelFormOneRecordService({}, {}, this)]
      } else {
        this.oneRecordServices = records.map((record) => {
          return new CCModelFormOneRecordService(record, record, this)
        })
      }
    } catch (e) {
      console.error(e)
    } finally {
      this.isLoading = false
    }
  }

  private validateForm() {
    let validated = true
    this.oneRecordServices.forEach((oneRecordService) => {
      if (Object.values(oneRecordService.errorMessageByColName).length > 0) {
        validated = false
      }
    })
    return validated
  }

  removeOneRecordService(oneRecordService: CCModelFormOneRecordService) {
    const index = this.oneRecordServices.indexOf(oneRecordService)
    if (index > -1) {
      this.oneRecordServices.splice(index, 1)
    }
  }

  async saveDataAfterConfirm() {
    const validated = this.validateForm()
    if (!validated) {
      $core.$toast.error('入力内容に誤りがあります')
      return
    }
    // 保存処理
    await Promise.all(
      this.oneRecordServices.map(async (oneRecordService) => {
        await $core.$storeMethods.upsert({
          modelName: this.modelName,
          data: oneRecordService.record,
        })
      }),
    )
  }

  async saveData(confirmMessageWithHtml = '', isConfirmMessage) {
    // validationチェック
    // validationは`oneRecordService`の`errorMessageByColName`をチェックしていいかと
    // 確認モダールを出す
    if (!isConfirmMessage) {
      this.saveDataAfterConfirm()
      return
    }
    if (confirmMessageWithHtml === '') {
      confirmMessageWithHtml = '保存します。よろしいでしょうか？'
    }
    if (await $core.$toast.confirmDialog(confirmMessageWithHtml)) {
      this.saveDataAfterConfirm()
    }
  }

  private async __returnInitialRecords() {
    if (!this.initialRecords?.length && !this.filterCondition) {
      return []
    }
    if (this.initialRecords?.length) {
      return this.initialRecords
    }
    // this.recordIdが指定されている場合には、それを優先する
    // あと、recordFindConditionが指定されている場合には、それを優先する
    // 最後に、filterConditionを優先する
    let filter
    let limit = -1
    if (this.recordId) {
      filter = { id: { _eq: this.recordId } }
      limit = 1
    } else if (this.recordFindCondition) {
      filter = this.recordFindCondition
      limit = 1
    } else {
      filter = this.filterCondition
    }
    return this.model.find({
      filter: filter,
      limit: limit,
    })
  }

  /**
   * 利用するカラムの取得
   */
  get filteredColumns(): ColumnDefByColName {
    if (this._calcCache.filteredColumns) {
      return this._calcCache.filteredColumns
    }
    this._calcCache.filteredColumns =
      this.useColumns || this.virtualModel?.columnsMergedWithBaseModel || this.model.columns
    return this._calcCache.filteredColumns
  }

  // getModelAttr(attrName: string, fallbackValue: any = null) {
  //   return (
  //     this.virtualModel?.[attrName] || this.model?.[attrName] || this[attrName] || fallbackValue
  //   )
  // }

  // get keyColName() {
  //   return this.getModelAttr('keyColName', 'id')
  // }

  getColDef(colName: string, colDefOverride: Partial<ColumnDef> = null): ColumnDef {
    const col = this.virtualModel
      ? this.virtualModel?.columnsMergedWithBaseModel?.[colName]
      : this.model?.columns[colName]
    if (!colDefOverride) {
      return col
    }
    return Object.assign({}, col, colDefOverride)
  }

  // getColumnTypeName(colName: string, colDefOverride: ColumnDef) {
  //   return this.getColDef(colName, colDefOverride).type
  // }

  getColInputType(colName: string, colDefOverride: ColumnDef) {
    if (
      typeof this.getColDef(colName, colDefOverride).selections === 'function' ||
      this.getColDef(colName, colDefOverride).selectionsWithSelectOptionsMasterGroupName ||
      this.getColDef(colName, colDefOverride).selectionsWithColNameFacet
    ) {
      return inputTypes.MULTISELECT
    } else {
      return colTypeInputTypeMap[this.getColDef(colName, colDefOverride).type]
    }
  }

  // getIsMultipleSelect(colName: string, colDefOverride: ColumnDef) {
  //   return this.getColDef(colName, colDefOverride).type === 'MULTISELECT'
  // }

  getAdditionalClasses(colName: string, colDefOverride: ColumnDef) {
    return this.getColDef(colName, colDefOverride).type === 'NUMBER' &&
      this.getColInputType(colName, colDefOverride) !== inputTypes.MULTISELECT
      ? 'text-right'
      : ''
  }
}

// /**
//  * CCModelFormService に関連
//  * 指定されたモデルとかVirtualモデルとfilterConditionから取得したレコードを管理するクラス
//  */
// export class DataListDataItems {
//   _list: Record<string, any>[] = []
//   listLastUpdated: number = 0
//   /**
//    * 現在の検索条件内での total count
//    */
//   public filter_count: number = 0
//   constructor(public readonly $dls: CCModelFormService) {}
//
//   get itemLength(): number {
//     return this.list?.length || 0
//   }
//   get pageCount(): number {
//     return Math.ceil((this.filter_count || 0) / this.$dls.query.limit)
//   }
//   get list(): Record<string, any>[] {
//     return this._list
//   }
//   set list(v: Record<string, any>[]) {
//     this._list = v
//     this.listLastUpdated = Date.now()
//   }
// }
