<template>
  <div style="position: relative">
    <b-button size="sm" variant="outline-primary" @click="toggleModal">
      {{ this.filterObject.displayLabel }}
      <Ficon type="chevron-circle-down" />
    </b-button>
    <div
      class="dropdown-menu position-absolute p-2 border-0 shadow-lg"
      style="max-width: 94vw; top: 32px"
      :style="toggle ? 'display: block;' : 'display: none;'"
    >
      <input
        type="text"
        class="form-control"
        :value="columnSearchStr"
        @input="changeColumnSearchStr"
        placeholder="フィルター対象..."
      />
      <div class="pt-2 pb-2" style="overflow: visible">
        <el-cascader-panel
          v-if="Object.keys(filteredColumns).length > 0"
          v-model="value"
          style="background: white"
          :options="options"
          @change="handleChange"
        />
        <div class="pb-1" v-else>該当なし</div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { inject } from 'vue'
import { ElCascaderPanel } from 'element-plus'
import { FilterColumnSelection } from '../../../FilterControlsService'
import { ColumnTypes } from '../../../../../../common/$models/ModelDef'
import { FilterControlsService } from '../../../FilterControlsService'
import { getColLabels } from '../../../FilterItemService'
import { getChildrenOptions } from '../../../FilterControlsService'
export default {
  components: {
    ElCascaderPanel,
  },
  setup() {
    return {
      filterObject: inject('filterObject'),
      FilterControlsServiceInstance: inject(
        'FilterControlsServiceInstance',
      ) as FilterControlsService,
    }
  },
  data() {
    return {
      toggle: false,
      columnSearchStr: '',
      value: [],
    }
  },
  computed: {
    options(): FilterColumnSelection[] {
      if (!this.filteredColumns) return []
      return Object.keys(this.filteredColumns).map((key) => {
        const column = this.filteredColumns[key]
        return {
          value: column.name || column.key,
          label: column.label || column.name,
          children: getChildrenOptions(column),
        }
      })
    },
    columns() {
      return this.filterObject.FilterGroupService?.FilterControlsService?.columns
    },
    filteredColumns() {
      if (!this.columns) return {}
      if (this.columnSearchStr === '') return this.columns
      return Object.keys(this.columns).reduce((acc: any, key: string) => {
        const column = this.columns[key]
        const searchTarget = column.label || column.name
        if (searchTarget.includes(this.columnSearchStr)) {
          acc[key] = column
        }
        return acc
      }, {})
    },
  },
  methods: {
    handleChange(selectedColumnPath: string[] | string) {
      if (typeof selectedColumnPath === 'string') {
        selectedColumnPath = [selectedColumnPath]
      }
      this.selectColumn(selectedColumnPath)
      this.value = []
    },
    selectColumn(selectedColumnPath: string[]) {
      this.filterObject.selectColumn(selectedColumnPath)
      this.closeAppendModal()
    },
    closeAppendModal() {
      this.toggle = false
    },
    toggleModal() {
      this.toggle = !this.toggle
    },
    changeColumnSearchStr(event) {
      this.columnSearchStr = event.target.value
    },
  },
}
</script>
