import { App } from 'vue'
import { registerFlexComponentsContainer } from '../../AppAndPageLayouts/front/components/ComponentsContainer/FlexComponentsContainer.config'
import { registerComposableComponentSettings } from '../../ComposableComponents/registerComposableComponentSettings'
import ComposableComponentRenderer from '../../ComposableComponentBuilder/front/components/Renderer/ComposableComponentRenderer.vue'
import ComposableComponentItemRenderer from '../../ComposableComponentBuilder/front/components/Renderer/ComposableComponentItemRenderer.vue'
import ComposableComponentRendererById from '../../ComposableComponentBuilder/front/components/Renderer/ComposableComponentRendererById.vue'
import SimpleContainer from '../../AppAndPageLayouts/front/components/ComponentsContainer/SimpleContainer.vue'

export const registerComposableComponentBuilderComponents = (Vue: App) => {
  Vue.component('SimpleContainer', SimpleContainer)
  Vue.component('ComposableComponentRenderer', ComposableComponentRenderer)
  Vue.component('ComposableComponentRendererById', ComposableComponentRendererById)
  registerComposableComponentSettings('ComposableComponentRendererById', {
    label: 'Composable',
    category: 'container',
    configColumns: {
      pageId: {
        beforeComponent: '<p class="small">ID, 一意キーのどちらかを指定します。</p>',
        label: 'ID',
        type: 'RELATIONSHIP_MANY_TO_ONE',
        relationshipManyToOne: {
          collectionName: 'core_composable_component_definitions',
          labelFormatter: (item) => `${item.name} (key: ${item.key})`,
        },
      },
      itemKey: {
        type: 'STRING',
        label: '一意キー',
        editCallback({ row, newValue, oldValue }) {
          if (newValue !== oldValue) {
            row.pageId = null
          }
          return row
        },
      },
    },
  })
  Vue.component('ComposableComponentItemRenderer', ComposableComponentItemRenderer)
  Vue.component(
    'ComposableComponentsTreeStructureItem',
    $frameworkUtils.defineAsyncComponent(
      () => import('./components/BuilderControls/ComposableComponentsTreeStructureItem.vue'),
    ),
  )

  Vue.component(
    'ComposableComponentBuilder',
    $frameworkUtils.defineAsyncComponent(
      () => import('./components/ComposableComponentBuilder.vue'),
    ),
  )

  Vue.component(
    'ComposableComponentBuilderModelInput',
    $frameworkUtils.defineAsyncComponent(
      () => import('./components/ComposableComponentBuilderModelInput.vue'),
    ),
  )

  Vue.component(
    'ComposableComponentsTreeStructureViewerItem',
    $frameworkUtils.defineAsyncComponent(
      () => import('./components/BuilderControls/ComposableComponentsTreeStructureViewerItem.vue'),
    ),
  )
  Vue.component(
    'ComposableComponentsTreeStructureEditor',
    $frameworkUtils.defineAsyncComponent(
      () => import('./components/BuilderControls/ComposableComponentsTreeStructureEditor.vue'),
    ),
  )
  Vue.component(
    'ComposableComponentsTreeStructureViewer',
    $frameworkUtils.defineAsyncComponent(
      () => import('./components/BuilderControls/ComposableComponentsTreeStructureViewer.vue'),
    ),
  )

  registerFlexComponentsContainer(Vue)
}
