import {
  ColumnDef,
  ColumnDefByColName,
  ColumnType,
  SelectOptionItemObject,
} from '../../common/$models/ModelDef'

/**
 * 選択肢のために利用, ColumnDefByColName を SelectOptionItemObject[] に変換する
 * @param columns
 */
export const columnsIntoSelectableOptions = (
  columns: ColumnDefByColName,
): SelectOptionItemObject[] => {
  return Object.keys(columns).map((colName) => {
    return {
      label: columns[colName].label || colName,
      value: colName,
    }
  })
}

const nonFilterableColumnTypes: ColumnType[] = ['ARRAY_OF_OBJECT']
/**
 * Filterableかどうかの判定
 */
const isColumnFilterable = (col: ColumnDef): boolean => {
  return (
    col.visible !== false &&
    col.searchBehavior !== false &&
    !col.virtualColumnOf &&
    !nonFilterableColumnTypes.includes(col.type)
  )
}

/**
 * Filterable なカラムのみを抽出する from ColumnDefByColName
 * @param columns
 */
export const extractFilterablesFromColumns = (columns: ColumnDefByColName): ColumnDefByColName => {
  return Object.keys(columns).reduce((res, colName) => {
    if (isColumnFilterable(columns[colName])) {
      return {
        ...res,
        [colName]: columns[colName],
      }
    }
    return res
  }, {})
}

/**
 * VisibleOnIndex なカラムのみを抽出する from ColumnDefByColName
 * @param columns
 */
export const extractVisibleOnIndexFromColumns = (
  columns: ColumnDefByColName,
): ColumnDefByColName => {
  return Object.keys(columns).reduce((res, colName) => {
    if (columns[colName].visibleOnIndex !== false && columns[colName].visible !== false) {
      return {
        ...res,
        [colName]: columns[colName],
      }
    }
    return res
  }, {})
}
