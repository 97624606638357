import { Ref, reactive, ref } from 'vue'
import { FilterGroupService } from './FilterGroupService'
import { ColumnDef } from '../../../common/$models/ModelDef'

/**
 * @module FilterRuleService
 * @description
 * 個別的な`FilterRule`を管理するサービス
 */

// FilterOperatorForStringは、`と一致`、`と一致しない`、`を含む`、`を含まない`、`で始まる`、`で終わる`、`未入力`、`未入力ではない` directusのフィルターのオペレーターと同じ
export type FilterOperatorForString =
  | 'eq'
  | 'neq'
  | 'contains'
  | 'ncontains'
  | 'starts_with'
  | 'ends_with'
  | 'isnull'
  | 'isnotnull'
export const FILTER_OPERATOR_FOR_STRING: Record<FilterOperatorForString, string> = {
  contains: 'を含む',
  ncontains: 'を含まない',
  eq: 'と一致',
  neq: 'と一致しない',
  starts_with: 'で始まる',
  ends_with: 'で終わる',
  isnull: '未入力',
  isnotnull: '未入力ではない',
}

export type FilterOperatorForUuid = 'eq' | 'neq'
// | 'contains'
// | 'ncontains'
// | 'starts_with'
// | 'ends_with'
// | 'isnull' // 利用できない, Error: "uuid" field type does not contain the "_empty" filter operator が発生する
// | 'isnotnull' // 利用できない, Error: "uuid" field type does not contain the "_nempty" filter operator が発生する

export const FILTER_OPERATOR_FOR_UUID: Record<FilterOperatorForUuid, string> = {
  // contains: 'を含む',
  // ncontains: 'を含まない',
  eq: 'と一致',
  neq: 'と一致しない',
  // starts_with: 'で始まる',
  // ends_with: 'で終わる',
  // isnull: '未入力',
  // isnotnull: '未入力ではない',
}

// FilterOperatorForNumberは、`=`, `!=`, `>`, `>=`, `<`, `<=`, `未入力`, `未入力ではない` directusのフィルターのオペレーターと同じ
export type FilterOperatorForNumber =
  | 'eq'
  | 'neq'
  | 'gt'
  | 'gte'
  | 'lt'
  | 'lte'
  | 'between'
  | 'isnull'
  | 'isnotnull'
export const FILTER_OPERATOR_FOR_NUMBER: Record<FilterOperatorForNumber, string> = {
  eq: '=',
  neq: '!=',
  gt: '>',
  gte: '>=',
  lt: '<',
  lte: '<=',
  between: 'の間',
  isnull: '未入力',
  isnotnull: '未入力ではない',
}

export const TYPICAL_NUMBER_OPERATOR = ['eq', 'neq', 'gt', 'gte', 'lt', 'lte']

// FilterOperatorForDateは、`と一致`、`より前`、`より後`、`以前`、`以降`、`の期間内`、`今日と相対日付`、`未入力`、`未入力ではない`
export type FilterOperatorForDate =
  | 'eq'
  | 'lt'
  | 'gt'
  | 'lte'
  | 'gte'
  | 'between'
  | 'relative'
  | 'isnull'
  | 'isnotnull'
export const FILTER_OPERATOR_FOR_DATE: Record<FilterOperatorForDate, string> = {
  eq: 'と一致',
  lt: 'より前',
  gt: 'より後',
  lte: '以前',
  gte: '以降',
  between: 'の期間内',
  relative: '今日と相対日付',
  isnull: '未入力',
  isnotnull: '未入力ではない',
}

export type FilterOperatorForBoolean = 'eq' | 'neq' | 'isnull' | 'isnotnull'
export const FILTER_OPERATOR_FOR_BOOLEAN: Record<FilterOperatorForBoolean, string> = {
  eq: 'と一致',
  neq: 'と一致しない',
  isnull: '未入力',
  isnotnull: '未入力ではない',
}

export type DateValueType =
  | 'today'
  | 'yesterday'
  | 'tomorrow'
  | 'lastWeek'
  | 'nextWeek'
  | 'lastMonth'
  | 'nextMonth'
  | 'custom'

// typeがstringの場合のフィルターの型
export type FilterTypeString = {
  colName: string
  operator: FilterOperatorForString
  value: string
}
// typeがTEXTの場合のフィルターの型
export type FilterTypeText = FilterTypeString
// typeがRICHTEXTの場合のフィルターの型
export type FilterTypeRichText = FilterTypeString
// typeがNUMBERの場合のフィルターの型
export type FilterTypeNumber = {
  colName: string
  operator: FilterOperatorForNumber
  value: number | Array<number>
}
// typeがDATEONLYの場合のフィルターの型
export type FilterTypeDateOnly = {
  colName: string
  valueType: DateValueType
  operator: FilterOperatorForDate
  value: any
}
//typeがDATETIMEの場合のフィルターの型
export type FilterTypeDateTime = FilterTypeDateOnly
// typeがBOOLEANの場合のフィルターの型
export type FilterTypeBoolean = {
  colName: string
  operator: FilterOperatorForBoolean
  value: boolean
}

export type FilterOperatorForSelect = 'in' | 'nin' | 'isnull' | 'isnotnull'
export const FILTER_OPERATOR_FOR_SELECT: Record<FilterOperatorForSelect, string> = {
  in: 'を含む',
  nin: 'を含まない',
  isnull: '未入力',
  isnotnull: '未入力ではない',
}
export type FilterTypeSelect = {
  colName: string
  operator: FilterOperatorForSelect
  value: Array<any>
}

// typeがMULTISELECTの場合のフィルターの型
export type FilterTypeMultiSelect = FilterTypeSelect
// typeがFLOATの場合のフィルターの型
export type FilterTypeFloat = FilterTypeNumber
// typeがBIGINTEGERの場合のフィルターの型
export type FilterTypeBigInteger = FilterTypeNumber
// typeがDECIMALの場合のフィルターの型
export type FilterTypeDecimal = FilterTypeNumber
// typeがDOUBLEの場合のフィルターの型
export type FilterTypeDouble = FilterTypeNumber

export type FilterType =
  | FilterTypeString
  | FilterTypeText
  | FilterTypeRichText
  | FilterTypeNumber
  | FilterTypeDateOnly
  | FilterTypeDateTime
  | FilterTypeBoolean
  | FilterTypeSelect
  | FilterTypeMultiSelect
  | FilterTypeFloat
  | FilterTypeBigInteger
  | FilterTypeDecimal
  | FilterTypeDouble

export const ONE_DATE_TYPES = {
  today: '今日',
  yesterday: '昨日',
  tomorrow: '明日',
  one_week_ago: '一週間前',
  one_week_from_now: '一週間後',
  one_month_ago: '一ヶ月前',
  one_month_from_now: '一ヶ月後',
  custom_date: 'カスタム',
}

export const DATE_RELATION_TYPES = {
  this: 'この',
  past: '前の',
  next: '次の',
}

export const DATE_RELATION_PERIOD_TYPES = {
  day: '日',
  week: '週間',
  month: 'ヶ月',
  year: '年',
}

export class FilterRuleService<T extends FilterType | null> {
  public value: Ref<T> = reactive(null) as Ref<T>
  public isFirstObject: boolean = false
  public isSecondObject: boolean = false
  public objectType = 'rule'
  public colDef: ColumnDef
  FilterGroupService: FilterGroupService

  constructor(
    colDef: ColumnDef,
    isFirstObject: boolean = false,
    isSecondObject: boolean = false,
    filterGroupService: FilterGroupService,
  ) {
    this.colDef = colDef
    this.isFirstObject = isFirstObject
    this.isSecondObject = isSecondObject
    this.FilterGroupService = filterGroupService
    // operatorは`and`をデフォルトにする
    this.value = ref({
      colName: this.colDef.name,
      operator: 'and',
      value: '',
    }) as Ref<T>
  }

  updateOperator(operator) {
    this.FilterGroupService.filterLogic = operator
  }

  selectColumn(column: ColumnDef) {
    this.colDef = column
  }
}
