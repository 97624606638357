import { ColumnDef, SelectOptionItem } from '../../../common/$models/ModelDef'
import { CCModelFormService } from './CCModelFormService'
import { CCModelFormOneRecordService } from './CCModelFormOneRecordService'
import { fetchSelectionsWithColDef } from '../../../front/ModelForm'

/**
 * # CCModelFormColumnService - ModelForm における、カラム定義 および カラムの挙動 (選択肢の生成や、表示/非表示の切り替えなど) を司るサービス
 *
 * ## 役割
 * - カラム定義の参照
 * - 選択肢の生成 (レコードの状態に応じて の場合もある)
 * - 表示/非表示の切り替え (レコードの他のカラムのの状態に応じて)
 * - Validation message の 表示 (Validationの実施は行わない。CCModelFormOneRecordService が実施する)
 * - カラムの表示名の提供
 * - 入力された値を `CCModelFormOneRecordService` に渡す
 *
 * ## 責任範囲外 (このClassで実施しないこと)
 * - Validation の 実施
 */
export class CCModelFormColumnService {
  colDef: ColumnDef
  CCModelFormServiceInstance: CCModelFormService
  constructor(colDef: ColumnDef, CCModelFormServiceInstance: CCModelFormService) {
    this.colDef = colDef
    this.CCModelFormServiceInstance = CCModelFormServiceInstance
  }
  overrideColumnDef(colDefOverrides: Partial<ColumnDef>) {
    this.colDef = { ...this.colDef, ...colDefOverrides }
  }

  /**
   * 本カラムが、選択肢を持つ入力であるかどうかを判定する
   */
  get isSelectInput(): boolean {
    return !!(
      typeof this.colDef.selections === 'function' ||
      this.colDef.selectionsWithSelectOptionsMasterGroupName
    )
  }
  get colName(): string {
    return this.colDef.name
  }

  /**
   * 選択肢の生成 with CCModelFormOneRecordService
   *
   * ## 処理
   * - 1. isSelectInput で、選択肢を生成するかどうかを判定
   */
  async generateSelections(
    oneRecordService: CCModelFormOneRecordService, // root record state も含めた、全レコードの状態を保持している
    currentValue: any,
    callerVueInstance: any,
  ): Promise<SelectOptionItem[]> {
    //
    // const record = oneRecordService.record
    if (this.isSelectInput === false) {
      return // do nothing
    }
    const list = await fetchSelectionsWithColDef({
      modelName: this.CCModelFormServiceInstance.modelName,
      colDef: this.colDef,
      record: oneRecordService.record,
      value: currentValue,
      initialValue: oneRecordService.initialRecordState[this.colName],
      recordRoot: oneRecordService.recordRoot,
      callerVueInstance,
    })
    return list.filter((v) => v !== undefined)
    // list構造が {value, label}[] であれば、、、
    // const isLabeledList =
    //   Object.keys(list[0] || {}).length === 2 && list[0].value !== undefined && list[0].label
    // // let selectionLabels = []
    // if (isLabeledList) {
    //   // const valueList = []
    //   // const labelMap = list.reduce((res, r) => {
    //   //   res[r.value] = r.label
    //   //   valueList.push(r.value)
    //   //   return res
    //   // }, {})
    //   // selectionLabels = labelMap
    //   // list = valueList
    // }
    // TODO: 後で解消する
    // if (this.addedSelections) {
    //   list = list.concat(this.addedSelections)
    // }
    // 選択肢が1つだけの場合、自動選択する if autoSelectIfSelectionIsOnlyOne === true
    // if (
    //   autoSelectIfSelectionIsOnlyOne &&
    //   this.isNewRecord &&
    //   list.length === 1 &&
    //   !this.v &&
    //   list[0]
    // ) {
    //   this.$nextTick(async () => {
    //     this.v = list[0]
    //     this.change() // kick validation & $emit event for parent component
    //   })
    // }
    // return list.filter(v => v !== undefined)

    // this.colInputSelection = list.filter(v => v !== undefined)
    // this.multiSelectOptions = this.colInputSelection
    // this.change()
    // return list
  }
}
