export const csvParserLite = (csvString: string): Record<string, string>[] => {
  // BOMを除去
  const csvStringWithoutBom = csvString.replace(/^\ufeff/, '');

  // 行を分割し、空行を除外
  const rows = csvStringWithoutBom.split(/\r?\n/).filter(row => row.trim().length > 0);

  if (rows.length === 0) {
    return [];
  }

  // ヘッダ行の処理
  const headerRow = parseRow(rows[0]);

  // データ行の処理
  return rows.slice(1).map(rowString => {
    const values = parseRow(rowString);
    return headerRow.reduce((record, header, index) => {
      record[header] = index < values.length ? values[index] : '';
      return record;
    }, {} as Record<string, string>);
  });
};

// 行をパースする補助関数
const parseRow = (row: string): string[] => {
  const result: string[] = [];
  let inQuotes = false;
  let currentValue = '';

  for (let i = 0; i < row.length; i++) {
    const char = row[i];
    if (char === '"') {
      if (inQuotes && row[i + 1] === '"') {
        // エスケープされた二重引用符
        currentValue += '"';
        i++;
      } else {
        // 引用符の開始または終了
        inQuotes = !inQuotes;
      }
    } else if (char === ',' && !inQuotes) {
      // フィールドの区切り
      result.push(currentValue.trim());
      currentValue = '';
    } else {
      currentValue += char;
    }
  }

  // 最後のフィールドを追加
  result.push(currentValue.trim());

  return result;
};
