<template>
  <table
    :class="dataTableClass || 'table table-sm b-table table-striped table-hover overflow-ellipsis'"
  >
    <thead>
      <tr
        v-html="
          `${bulkControllable ? `<th></th>` : ''}${
            virtualModel && virtualModel.listItemActionHtml ? `<th></th>` : ''
          }<th>${tableColumns.map(t => t.label).join('</th><th>')}</th>`
        "
      ></tr>
    </thead>
    <tbody v-if="!bulkControllable">
      <tr
        v-for="(item, index) in items"
        :key="index"
        style="cursor: pointer; font-size: 0.94em"
        v-single-click="event => itemClick(item.id, event)"
        v-html="`<td>${tableColumns.map(t => item[t.key]).join('</td><td>')}</td>`"
      ></tr>
    </tbody>
    <tbody v-else>
      <tr
        v-for="(item, index) in items"
        :key="index"
        style="cursor: pointer; font-size: 0.94em"
        v-single-click="event => itemClick(item.id, event)"
      >
        <th class="ignoreItemClick">
          <input
            :checked="bulkControlSelectedItems[item.id]"
            class="ignoreItemClick"
            type="checkbox"
            v-single-click="event => clickCheckboxInput(item.id, [...items], index, event)"
          />
        </th>
        <td
          v-if="virtualModel && virtualModel.listItemActionHtml"
          v-html="virtualModel.listItemActionHtml(item)"
          class="no-ellipsis"
        ></td>
        <td v-for="t in tableColumns" :key="t.key" v-html="item[t.key]"></td>
      </tr>
    </tbody>
  </table>
</template>

<script lang="ts">
export default {
  props: {
    items: { required: true },
    tableColumns: { required: true },
    dataTableClass: { required: false },
    virtualModel: { required: false },
    clickCheckboxInput: { required: false },
    bulkControlSelectedItems: { required: false },
    itemClick: { required: false },
    bulkControllable: { required: false },
  },
}
</script>
