/**
 * 初期化 on init
 */
export const initComposableComponentBuilderFront = () => {
  // 新規作成ページをOverride
  $core.$appHook.on(
    '$CORE.admin.resolveComponent.model.core_composable_component_definitions.pages.new',
    () => 'ComposableComponentBuilder',
    'c',
  )
  // Router add
  $core.$router.addRoute({
    path: '/pb/:urlPath/:restPart(.*)?',
    component: () => import('./components/Renderer/ComposableComponentRendererRouterPage.vue'),
    props: true,
  })
}
