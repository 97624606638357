<template>
  <div v-if="calcedComponent">
    <label>{{ columnLabel }}</label>
    <component
      :is="calcedComponent"
      :modelValue="!!filterObject.value ? filterObject.value.value : null"
      @update:modelValue="updateValue"
      @update:operator="updateRuleOperator"
      :disable-setting="true"
    />
  </div>
</template>
<script lang="ts">
import { registerComposableComponentSettings } from '../../../ComposableComponents'
import { computed, inject } from 'vue'
import { getComposableDataListFromSettingColumnInstance } from '../patrials/dataListComposableColumnNameSelectionSettingColumnDef'
import { columnsIntoSelectableOptions } from '../../../ModelServices/modelFactoryUtils'
import { FilterControlsService } from '../FilterControlsService'

registerComposableComponentSettings('ComposableDataListColumnSearchInput', {
  label: 'ComposableDataListColumnSearchInput',
  hasDefaultSlot: true,
  configColumns: {
    columnName: {
      type: 'STRING',
      label: 'カラム名',
      dynamicSelections: true,
      selections(
        record: any,
        currentValue: any,
        initialValue: any,
        recordRoot: any,
        callerVueInstance: any,
      ): Promise<any[]> | any[] {
        const parent = getComposableDataListFromSettingColumnInstance(
          callerVueInstance,
          'ComposableDataList',
        )
        if (!parent) {
          return []
        }
        const virtualModelName = parent?.configuredProps?.virtualModelName
        const modelName = parent?.configuredProps?.modelName
        const columns =
          $core.$virtualModels[virtualModelName]?.columns || $core.$models[modelName]?.columns
        if (!columns) {
          return []
        }
        return columnsIntoSelectableOptions(columns)
      },
    },
  },
  defaultProps: {
    columnName: '',
  },
})

export default {
  name: 'ComposableDataListColumnSearchInput',
  props: {
    columnName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {}
  },
  setup() {
    return {
      FilterControlsServiceInstance: inject(
        'FilterControlsServiceInstance',
      ) as FilterControlsService,
    }
  },
  provide() {
    return {
      filterObject: computed(() => {
        return this.filterObject
      }),
    }
  },
  watch: {
    columnName(val) {
      this.FilterControlsServiceInstance.filterGroupService.appendFilterRuleWithColName([val])
    },
  },
  created() {
    if (this.columnName) {
      this.FilterControlsServiceInstance.filterGroupService.appendFilterRuleWithColName(
        [this.columnName],
      )
    }
  },
  computed: {
    filterObject() {
      return this.FilterControlsServiceInstance.filterGroupService.getFilterRuleWithColName(
        [this.columnName],
      )
    },
    calcedComponent() {
      if (!this.filterObject) {
        return null
      }
      return this.filterObject.component
    },
    columnLabel() {
      if (!this.filterObject || !this.filterObject.colDef) {
        return ''
      }
      return this.filterObject.colDef.label || this.filterObject.colDef.name
    },
  },
  methods: {
    updateOperator(val) {
      this.operator = val
      this.filterObject.updateOperator(val)
    },
    updateValue(value) {
      this.filterObject.updateValue(value)
    },
    updateRuleOperator(operator) {
      this.filterObject.updateItemOperator(operator)
    },
  },
}
</script>
