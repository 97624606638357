<template>
  <div
    class="dropdown-menu position-absolute p-2 border-0 shadow-lg"
    style="min-width: 320px; max-width: 94vw; top: 8px; left: 99%"
    :style="visible ? 'display: block;' : 'display: none;'"
  >
    <div
      @click="removeFilter"
      role="button"
      class="d-flex align-items-center filter-manager-button"
    >
      <Ficon type="trash" class="mr-1" /> Filterを削除
    </div>
    <div
      @click="duplicateFilter"
      role="button"
      class="d-flex align-items-center filter-manager-button"
    >
      <Ficon type="copy" class="mr-1" /> 複製する
    </div>
  </div>
</template>
<script lang="ts">
import { inject } from 'vue'
import { FilterItemService } from '../../FilterItemService'

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  setup() {
    return {
      filterObject: inject('filterObject'),
    }
  },
  methods: {
    removeFilter() {
      this.filterObject.removeFilter()
      this.$emit('onDismiss')
    },
    duplicateFilter() {
      this.filterObject.duplicateFilter()
      this.$emit('onDismiss')
    },
  },
}
</script>
