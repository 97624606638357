import { ModelDef } from '../../../common/$models/ModelDef'
import { ModelJunctionUsersAndOrgsAndPositions } from './junctionUsersAndOrgsAndPositions'

const tableName = 'organizations'

export type ModelOrganizations = {
  code: string
  parent: string | ModelOrganizations | null
  name: string
  category: string | null
  sort: number
  users?: ModelJunctionUsersAndOrgsAndPositions[]
}

export const organizations: ModelDef = {
  tableName,
  tableLabel: '組織',
  tableComment: '',
  defaultSort: 'sort',
  primaryKeyColType: 'STRING',
  modelType: 'admin',
  columns: {
    // 組織コード
    code: {
      label: '組織コード',
      type: 'STRING',
      unique: true,
      validate: { notEmpty: true },
      // defaultValue: () => $core.$utils.generateRandomString().toUpperCase(),
      inputAttrs: { wrapperClass: 'w-auto' },
      editable: false,
      editableOnCreate: true,
      primaryKey: true,
      inputHelpText: '後から変更することはできません',
    },
    // 親組織
    // 選択されない場合は最上位組織とする
    parent: {
      label: '親組織',
      type: 'RELATIONSHIP_MANY_TO_ONE',
      relationshipManyToOne: {
        collectionName: tableName,
        labelFormatter: (record) => {
          return `${record.name} (${record.code})`
        },
        allowEmptySelection: true,
      },
    },
    // 組織名
    name: {
      label: '組織名',
      type: 'STRING',
      validate: { notEmpty: true },
      unique: true,
      inputAttrs: { wrapperClass: 'col-4' },
    },

    category: {
      label: '区分',
      type: 'STRING',
      selectionsWithSelectOptionsMasterGroupName: '組織区分',
      createSelectOptionsMasterOnAddNewSelection: true,
    },

    sort: {
      label: '表示順 (昇順)',
      type: 'NUMBER',
      inputAttrs: { wrapperClass: 'col-2' },
      // visible: false,
    },
    users: {
      label: '所属ユーザー',
      type: 'RELATIONSHIP_ONE_TO_MANY',
      visible: false,
      inputAttrs: { wrapperClass: 'col-12' },
      relationshipOneToMany: {
        collectionName: 'junctionUsersAndOrgsAndPositions',
        fieldName: 'organization',
      },
    },
  },
}
