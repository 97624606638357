import ModelIndexDirectus from './ModelIndexDirectus.vue'
import ModelIndexPage from './ModelIndexPage.vue'
import IndexListTable from './IndexListTable.vue'
import DataListTable from './DataListTable.vue'
import ModelSearchSetSort from './ModelSearchSetSort.vue'

export const registerModelIndexComponents = (Vue) => {
  Vue.component('ModelIndexDirectus', ModelIndexDirectus)
  Vue.component('ModelIndexPage', ModelIndexPage)
  Vue.component('ModelIndexTabbed', () => import('./ModelIndexTabbed.vue'))
  Vue.component('IndexListTable', IndexListTable)
  Vue.component('DataListTable', DataListTable)
  Vue.component('ModelSearchSetSort', ModelSearchSetSort)
  Vue.component(
    'IconSelectInput',
    $frameworkUtils.defineAsyncComponent(() => import('../../front/Icons/IconSelectInput.vue')),
  )
}
