import { ColumnTypes } from './ModelDef'

const _defalutVals = {
  [ColumnTypes.Boolean]: false,
  [ColumnTypes.String]: '',
  [ColumnTypes.ConditionalExpression]: { expressions: [], defaultValue: '' },
  [ColumnTypes.ArrayOfObject]: [], // empty array
  [ColumnTypes.MultiSelect]: [], // empty array
}
/**
 * ColumnType に対応するデフォルト値を設定するためのfunction
 * 初期化時に利用する
 * @param colType
 */
export const initialValueByColumnTypes = (colType: string) => _defalutVals[colType] || undefined
