<template>
  <thead class="data-list-display-table-header">
    <tr>
      <slot>
        <th v-if="DataListDisplayServiceInstance.shouldBulkControlEnabled">
          <DataListDisplayCheckboxBulk />
        </th>
        <DataListDisplayTableHeaderColumnItem
          v-for="(column, colName, index) in DataListDisplayServiceInstance.columns"
          :key="colName + index"
          :column="column"
          :colName="colName"
          :disableSort="disableHeaderColSort"
        ></DataListDisplayTableHeaderColumnItem>
      </slot>
    </tr>
  </thead>
</template>
<script lang="ts">
import { inject } from 'vue'
import { DataListDisplayService } from '../DataListDisplayService'
import DataListDisplayTableHeaderColumnItem from './DataListDisplayTableHeaderColumnItem.vue'
import { registerComposableComponentSettings } from '../../../../../ComposableComponents'

const name = 'DataListDisplayTableHeader'
registerComposableComponentSettings(name, {
  hasDefaultSlot: true,
  configColumns: {
    disableHeaderColSort: {
      type: 'BOOLEAN',
      label: 'ヘッダーの列ソートを無効化',
    },
  },
})

export default {
  name,
  props: {
    disableHeaderColSort: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    DataListDisplayTableHeaderColumnItem,
  },
  setup() {
    return {
      DataListDisplayServiceInstance: inject<DataListDisplayService>(
        'DataListDisplayServiceInstance',
      ),
    }
  },
}
</script>
