import { ModelDef } from './ModelDef'

/**
 * src/server/AnotherDataSourcesHandler へ登録した dataSourceName をデータソース元とするためのModel
 * 2022.05 時点では、基本的に non-editable
 */
export const anotherDataSourceFrontModelDefFactory = (
  {
    modelDef,
    dataSourceName,
  }: {
    modelDef: ModelDef
    dataSourceName: string
  },
  readonly = true,
): ModelDef => {
  const find = async (query) => {
    return $core.$d.transport.post('/core/anotherDatasourceFind', {
      ...query,
      dataSourceName,
    })
  }
  // find 関数の 上書き
  modelDef.overrides = {
    find,
    findById: async (id) => {
      return (await find({ filter: { id: { _eq: id } } })).data?.[0] || null
    },
    ...(modelDef.overrides || {}),
  }
  modelDef.doNotSyncModel = true
  if (readonly) {
    modelDef.creatable = false
    modelDef.updatable = false
    modelDef.deletable = false
  }
  return modelDef
}
