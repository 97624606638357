<template>
  <HelpDoc v-if="content"><span v-html="content"></span></HelpDoc>
</template>
<script lang="ts">
const name = 'RemoteCOREHelpDoc'

/**
 * CORE FW Docs から fetch して、HelpDoc に表示するコンポーネント
 */
export default {
  name,
  props: {
    docKey: { type: String, required: false },
    linkText: { type: String, required: false },
    inline: { type: Boolean, required: false, default: false },
  },
  data() {
    return {
      content: '',
    }
  },
}
</script>
