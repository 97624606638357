<template>
  <div
    v-if="initialized"
    class="model-form-grouped-edit"
    :class="`model-form-grouped-edit--modelName-${modelName}`"
  >
    <app-hookable-component :resolve-hook-name="`${hookNameBase}.before`"/>
    <ModelForm
      :modelName="modelName"
      :virtualModelName="virtualModelName"
      v-model:record="datas[0]"
      :columns="groupedEditColumns"
      :disableSubmitAction="true"
      :groupedEditIsParent="true"
      :hide-footer="true"
      @update="
        ({ data, validationErrors }) => {
          updateParent({ data, validationErrors })
        }
      "
      :readOnlyMode="readOnlyMode"
      @on-submit-function="onSubmitFunction"
      @on-delete-function="onDeleteFunction"
      :submit-button-label="submitButtonLabel"
      ref="parentForm"
    />
    <div class="model-form-grouped-edit--records">
      <div
        v-for="(data, index) in datas"
        :key="index"
        :class="'childStyle' === 'table' ? 'table' : 'card mt-3 mb-4'"
      >
        <div v-if="mainModel && mainModel.groupedEditChildTitle" class="card-header">
          {{ mainModel.groupedEditChildTitle }} {{ index + 1 }}
        </div>
        <div class="card-body pb-0 position-relative">
          <ModelForm
            :modelName="modelName"
            :virtualModelName="virtualModelName"
            v-model:record="datas[index]"
            :columns="arrayEditColumns"
            :disableSubmitAction="true"
            :hide-footer="true"
            :groupedEditIndex="index"
            :readOnlyMode="readOnlyMode"
            @update="
              ({ data: dataChild, validationErrors: validationErrorsChild }) => {
                updateChild({ data: dataChild, validationErrors: validationErrorsChild, index })
              }
            "
            @on-submit-function="onSubmitFunction"
            @on-delete-function="onDeleteFunction"
            :submit-button-label="submitButtonLabel"
            ref="childForm"
          />
          <span
            v-if="datas.length > 1 && index !== 0 && !readOnlyMode"
            class="btn btn-sm btn-danger"
            style="position: absolute; right: -6px; top: -11px; font-size: 10px; padding: 2px 6px"
            v-single-click="() => deleteChildRecord({ data, index })"
          >
            <ficon type="trash"/>
            レコードを削除</span
          >
        </div>
      </div>
      <div
        v-if="!readOnlyMode"
        class="col-12 text-center mb-4 model-form-grouped-edit--add-new-row"
      >
        <span class="btn btn-outline-primary ml-2" v-single-click="addNewRow">
          <ficon type="plus"/>
          レコード追加</span
        >
      </div>
    </div>
    <div v-if="disableSubmitAction !== true" class="col-12 text-center">
      <p v-if="errorCount" class="text-danger">入力エラーを修正してください</p>
    </div>
    <app-hookable-component :resolve-hook-name="`${hookNameBase}.beforeSubmitActions`"/>
    <div
      class="mt-5 mb-3 model-form-grouped-edit--delete"
      v-if="
        !isNewRecord &&
        !readOnlyMode &&
        model.deletable !== false &&
        disableSubmitAction !== true &&
        datas.length === 1
      "
    >
      <deleteLink :id="id" :modelName="modelName" class="btn btn-danger"/>
    </div>
    <app-hookable-component
      v-if="disableSubmitAction !== true && !readOnlyMode"
      class="col-12 text-center py-3 d-block"
      style="position: sticky; bottom: 0; background-color: #fff; border-top: 1px solid #d5dfe6"
      :resolve-hook-name="`${hookNameBase}.submitPart`"
    >
      <slot name="beforeActionButton">
        <app-hookable-component :resolve-hook-name="`${hookNameBase}.beforeActionButton`"/>
      </slot>
      <b-button
        :disabled="!!errorCount"
        :variant="`${errorCount ? 'secondary' : 'success'}`"
        v-single-click="save"
      >
        {{ actionLabel }}
      </b-button>
    </app-hookable-component>
    <app-hookable-component :resolve-hook-name="`${hookNameBase}.after`"/>
    <div v-if="!$core.isProduction">
      <pre class="bg-dark p-2 text-white" style="max-height: 320px">{{datas}}</pre>
    </div>
  </div>
</template>

<script lang="ts">
import { generateUUIDV4 } from '../../common/utils'
import { PropType } from 'vue'

export default {
  name: 'ModelFormGroupedEdit',
  props: {
    modelName: { required: true },
    virtualModelName: { required: false, default: null },
    columns: { required: false, default: false },
    record: { default: () => Object },
    forceTreatAsNewRecord: { required: false, default: false },
    disableSubmitAction: { required: false, default: false },
    passedDefaultValues: { default: () => ({}) },
    readOnlyMode: { required: false, default: false },
    onSubmitFunction: {
      required: false,
      type: Function as PropType<(data) => Promise<any>>,
    },
    onDeleteFunction: {
      required: false,
      type: Function as PropType<(data) => Promise<any>>,
    },
    submitButtonLabel: {
      required: false,
      default: '保存',
    },
    modalId: { required: false, type: String },
  },
  data() {
    return {
      initialized: false,
      datas: [],
      validationErrorValues: [],
      validationErrors: {},
    }
  },
  computed: {
    id() {
      return this.record[this.model.primaryKeyColName]
    },
    errorCount() {
      return [
        ...Object.values(this.validationErrors),
        ...Object.values(this.validationErrorValues),
      ].filter((v) => !!v).length
    },
    isNewRecord() {
      return this.forceTreatAsNewRecord === true || !(this.record && !!this.id)
    },
    actionLabel() {
      return this.isNewRecord ? '新規作成' : '更新'
    },
    hookNameBase() {
      return `$CORE.admin.resolveComponent.model.${this.virtualModelName || this.modelName}.${
        this.isNewRecord ? 'create' : 'edit'
      }.modelFormGroupedEdit`
    },
    model() {
      return $core.$models[this.modelName]
    },
    mainModel() {
      return $core.$virtualModels[this.virtualModelName] || this.model
    },
    colNames(): string[] {
      return Object.keys(this.mainModel.columns)
    },
    groupedEditColumns() {
      return this.colNames.filter((cn) => !!this.mainModel.columns[cn].groupedEdit)
    },
    arrayEditColumns() {
      return this.colNames.filter((cn) => !this.mainModel.columns[cn].groupedEdit)
    },
    shouldExcludeNonUpdatedDataFromSaveKey() {
      return `ModelFormGroupedEdit.shouldExcludeNonUpdatedDataFromSave.modelName:${this.modelName}${this.virtualModelName ? `.virtualModelName:${this.virtualModelName}` : ''}`
    },
    /**
     * 保存時に更新されていないデータを除外するかどうか の設定
     *
     * Config vars を利用して設定する
     * ```ts
     * // 設定例:
     * $core.$configVars.set('ModelFormGroupedEdit.shouldExcludeNonUpdatedDataFromSave.modelName:ce_material_trans.virtualModelName:c_orders', true)
     * ```
     */
    shouldExcludeNonUpdatedDataFromSave(): boolean {
      return $core.$configVars.get(this.shouldExcludeNonUpdatedDataFromSaveKey, false)
    }
  },
  async created() {
    // Bind fields and default value when this is new record
    const data = Object.assign(
      {},
      this.isNewRecord ? await this.createNewRecord() : this.record,
      // this.data
    )
    // TODO: find records with key
    if (!this.forceTreatAsNewRecord && data.groupedKey) {
      this.datas = await this.model.find({
        filter: {
          groupedKey: { _eq: data.groupedKey },
        },
      })
    } else {
      this.datas = [data]
    }
    this.initialized = true
  },
  methods: {
    async save() {
      if (this.errorCount) {
        $core.$toast.errorToast('入力エラーを修正してください')
        return
      }
      if (
        (await $core.$toast.confirmDialog(`${this.actionLabel}します、よろしいですか？`, {
          okVariant: 'success',
        })) === false
      ) {
        return
      }
      $core.$loading.start('更新中です...', 'overlay')
      const data = this.generateSaveTargetData()
      try {
        const res = await $core.$storeMethods.bulkUpsert({
          collectionName: this.modelName,
          data,
          virtualModelName: this.virtualModelName,
        })
        this.$emit('successCallback', res.data)
        if (this.modalId) {
          $core.$modals.closeModal(this.modalId)
        }
      } catch (e) {
        $core.$toast.errorToast(`${this.actionLabel}に失敗しました。${JSON.stringify(e.message)}`)
        console.error(`[/front_nuxt/components/ModelForm.vue] Error: `, JSON.stringify(e))
        throw e
      } finally {
        $core.$loading.finish()
      }
    },
    /**
     * 保存対象のデータを生成する
     */
    generateSaveTargetData(): any[] {
      // Merge parent data with child data, with grouped key
      const groupedKey = this.datas[0].groupedKey || generateUUIDV4()
      // 親レコードを編集したら、すべての子レコードの共通フィールドが更新されるようにするために
      const overridesParentValues = this.groupedEditColumns.reduce(
        (res, colName) => ({ ...res, [colName]: this.datas[0][colName] }),
        {},
      )
      // shouldExcludeNonUpdatedDataFromSave の設定 かつ 共通フィールドが編集されているかどうかを判定する
      const hasParentFormChanged = this.$refs.parentForm?.hasDataChanged === true
      const data = this.datas.map((d, index) => {
        if (this.shouldExcludeNonUpdatedDataFromSave && !hasParentFormChanged) {
          const hasDataChanged = this.$refs.childForm?.[index]?.hasDataChanged === true
          if(!hasDataChanged) {
            // 更新されていないデータを 保存対象から除外する
            return null
          }
        }
        return Object.assign({}, d, overridesParentValues, { groupedKey })
      }).filter((d) => !!d)
      return data
    },
    async createNewRecord() {
      return Object.assign(await this.defaultValues(), this.record)
    },
    async addNewRow(additionalData = {}) {
      // 行を追加する場合には、親レコードからコピーする
      const parentData =
        this.groupedEditColumns.reduce((res, key) => ({ ...res, [key]: this.datas[0][key] }), {}) ||
        {}
      this.datas.push(
        Object.assign(
          {},
          await this.defaultValues(),
          parentData, // 親レコードからコピー
          additionalData,
        ),
      )
    },
    replaceAllRows(data) {
      this.datas = data
      // this.datas = data
    },
    async defaultValues() {
      return Object.assign(
        {},
        this.mainModel && this.mainModel.defaultValues
          ? (await this.mainModel.defaultValues()) || {}
          : {},
        this.passedDefaultValues || {},
      )
    },
    updateChild({ data, validationErrors, index }) {
      this.datas[index] = data
      this.validationErrorValues[index] = Object.values(validationErrors).join('')
    },
    updateParent({ data, validationErrors }) {
      this.datas[0] = data
      const parentData =
        this.groupedEditColumns.reduce((res, key) => ({ ...res, [key]: this.datas[0][key] }), {}) ||
        {}
      this.datas.map((d, index) => {
        this.datas[index] = Object.assign({}, d, parentData)
      })
      this.validationErrors = validationErrors
    },
    async deleteChildRecord({ data, index }) {
      let deleteResult = true
      if (data[this.model.primaryKeyColName]) {
        if ((await $core.$toast.confirmDialog('データを削除します、よろしいですか？')) !== true) {
          return false
        }
        deleteResult = await $core.$storeMethods.deleteDoc({
          collectionName: this.modelName,
          docId: data[this.model.primaryKeyColName],
        })
      }
      if (deleteResult) {
        this.datas.splice(index, 1)
        this.validationErrorValues = this.validationErrorValues.filter((v, i) => i !== index)
      }
    },
  },
}
</script>
