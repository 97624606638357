import { NavigationGuardNext } from 'vue-router'
/**
 * モデルフォームが変更されているかどうかをチェックする
 * モデルフォームが変更されている場合は、確認モーダルを表示する
 * モデルフォームが変更されていない場合は、そのまま次のページに遷移する
 * @param vuePageComponentInstance Vueページコンポーネントのインスタンス
 * @param next VueRouterのnext関数
 */
export const checkModelFormHasChanged = async (vuePageComponentInstance, next: NavigationGuardNext) => {
  const ModelForms =
    vuePageComponentInstance?.$refs?.ModelPage?.$refs?.AppHookableComponent?.$refs?.ModelForm
  if (!ModelForms || !ModelForms.length) {
    next()
    return
  }
  const ModelForm = ModelForms[0]
  if (
    $core.$configVars.get('confirmWhenExitWithoutSave', false) &&
    ModelForm.hasDataChanged === true &&
    ModelForm.hasSaved === false
  ) {
    const useConfirmDialog = $core.$configVars.get('useCoreConfirmModal', true)
    const msg = '変更が保存されていませんがよろしいですか？'
    if (useConfirmDialog ? await $core.$toast.confirmDialog(msg) : window.confirm(msg)) {
      next()
      return
    } else {
      next(false)
      return
    }
  } else {
    next()
    return
  }
}
