<template>
  <div class="data-list-display-table" style="width: 100%">
    <div class="table-header-nowrap table-body-nowrap table-responsive scroll_table_wrapper">
      <table class="mb-0 data-list-display-table--table" :class="tableStyleClasses">
        <slot>
          <DataListDisplayTableHeader
            v-if="hideHeader !== true"
            :disableHeaderColSort="disableHeaderColSort"
          />
          <DataListDisplayTableBody v-if="!isLoading"/>
        </slot>
      </table>
      <div
        v-show="isLoading"
        class="bg-white w-100"
      >
        <div class="p-2">
          <b-skeleton v-for="i in skeletonLowLength" :key="i" height="36px" class="mb-2" style="border-radius: 6px"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { registerComposableComponentSettings } from '../../../../../ComposableComponents'
import './DataListDisplayTable.scss'
import { inject } from 'vue'
import { ComposableDataListService } from '../../../ComposableDataListService'

const name = 'DataListDisplayTable'

const defaultTableStyleClass = 'data-list-display-table--style-default'

const tableStyles = {
  default: {
    label: 'デフォルト',
    class: defaultTableStyleClass,
  },
  cardLike: {
    label: 'カード型',
    class: 'data-list-display-table--style-card-like',
  },
}

// overflow-ellipsis sticky_thead

const classesByBooleanProps = {
  style__disableStickyHeader: {
    t: '',
    f: 'sticky_thead',
  },
  style__disableOverflowEllipsis: {
    t: '',
    f: 'overflow-ellipsis',
  },
}

registerComposableComponentSettings(name, {
  hasDefaultSlot: true,
  description: `データ一覧をテーブルとして表示させるコンポーネント`,
  images: [
    {
      img: '/images/cc_images/ComposableDataList1.png',
    },
  ],
  configColumns: {
    style__disableStickyHeader: {
      type: 'BOOLEAN',
      label: 'スクロール時にヘッダーを固定しない',
    },
    style__disableOverflowEllipsis: {
      type: 'BOOLEAN',
      label: 'テキストが長い場合でも省略しない',
    },
    hideHeader: {
      type: 'BOOLEAN',
      label: 'ヘッダーを非表示',
    },
    disableHeaderColSort: {
      type: 'BOOLEAN',
      label: 'ヘッダーのソートを利用しない',
      enableIf: (row) => !row.hideHeader,
    },
    tableStyle: {
      type: 'STRING',
      label: 'テーブル レイアウトタイプ',
      selections: () => Object.keys(tableStyles),
      customLabel: (value) => tableStyles[value].label,
    },
    tableClasses: {
      type: 'MULTISELECT',
      label: 'テーブルに追加するスタイル (クラス)',
      selections: () => {
        return [
          {
            label: 'セルの罫線有り (table-bordered)',
            value: 'table-bordered',
          },
          {
            label: '余白を小さく (table-sm)',
            value: 'table-sm',
          }
        ]
      },
    },
  },
  category: 'DataListDisplayTable',
})
export default {
  name,
  props: {
    tableStyle: {
      type: String,
      default: 'default',
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
    disableHeaderColSort: {
      type: Boolean,
      default: false,
    },
    style__disableStickyHeader: {
      type: Boolean,
      default: false,
    },
    style__disableOverflowEllipsis: {
      type: Boolean,
      default: false,
    },
    tableClasses: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    return {
      items: inject<any[]>('items'),
      ComposableDataListServiceInstance: inject('ComposableDataListServiceInstance') as ComposableDataListService,
    }
  },
  computed: {
    tableStyleClasses() {
      return [
        tableStyles?.[this.tableStyle]?.class || defaultTableStyleClass,
        this.classesByBooleanProps,
        this.tableClasses || [],
      ]
    },
    classesByBooleanProps() {
      return Object.keys(classesByBooleanProps).reduce((acc, key) => {
        if (!key.startsWith('style__')) {
          return acc
        }
        const value = this[key]
        acc.push(classesByBooleanProps[key]?.[value ? 't' : 'f'] || '')
        return acc
      }, [])
    },
    isLoading() {
      return this.ComposableDataListServiceInstance.isLoading
    },
    skeletonLowLength() {
      return this.ComposableDataListServiceInstance.items.length || 8
    }
  },
}
</script>
