import { $imgix } from '../$libs/$imgix'
import { $core } from '../$core'
import { files } from './ModelFiles'
import { $modelsLoader } from '../../common/$models'
import FileList from './FileList.vue'

/**
 * Enable file manager feature on CORE
 * Load this before app start
 */
export const enableFileManageFeature = () => {
  if (!$core.$models.files) {
    $modelsLoader.loadModel(files)
  }
  $core.$imgix = $imgix
  $core.VueClass.component(
    'inputFilePreview',
    $frameworkUtils.defineAsyncComponent(() => import('./inputFilePreview.vue')),
  )
  $core.VueClass.component(
    'InputFileSelector',
    $frameworkUtils.defineAsyncComponent(() => import('./InputFileSelector.vue')),
  )
  $core.$appHook.on('modelIndex.model:files.indexList', () => FileList)
  $core.VueClass.component(
    'inputImageUploader',
    $frameworkUtils.defineAsyncComponent(() => import('./InputImageUploader.vue')),
  )
}
