<template>
  <div class="row">
    <div class="col-12">
      <ul class="nav nav-tabs">
        <li
          v-for="(tab, index) in DisplayToggleGroupContainer.children"
          :key="index"
          class="nav-item cursor-pointer"
          @click="selectTab(index)"
        >
          <a
            class="nav-link"
            :class="{ active: DisplayToggleGroupContainer.displayingIndex === index }"
            >{{ tab.title }}</a
          >
        </li>
      </ul>

      <div class="tab-content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { registerComposableComponentSettings } from '../../../plugins/ComposableComponents'
import { ColumnDefByColName } from '../../../common/$models/ModelDef'

/**
 * タブグループの設定カラム
 */
export const composableComponentTabGroupConfigColumns: ColumnDefByColName = {
  defaultActiveIndex: {
    label: 'タブインデックス',
    type: 'NUMBER',
    defaultValue: 0,
  },
}
const name = 'TabGroup'
registerComposableComponentSettings(name, {
  label: 'タブグループ',
  category: 'Basic',
  hasDefaultSlot: true,
  configColumns: composableComponentTabGroupConfigColumns,
  // TODO: 個別概念を切り出し...
  children: [
    {
      label: 'タブグループアイテム',
      component: 'TabGroupItem',
      configuredProps: {
        title: 'テストタブ',
      },
      children: [
        {
          label: 'コンテンツ',
          component: 'TextContent',
          configuredProps: {
            t__content: 'サンプルコンテンツ１',
            t__tag: null,
            t__isHtml: null,
          },
          children: [],
          styleProps: {
            styles: null,
            classes: null,
          },
          advancedSettings: {
            useBeforeInitFunction: null,
          },
          isDeleting: false,
        },
      ],
      styleProps: {},
      advancedSettings: {},
    },
    {
      label: 'タブグループアイテム',
      component: 'TabGroupItem',
      configuredProps: {
        title: 'テストタブ',
      },
      children: [
        {
          label: 'コンテンツ',
          component: 'TextContent',
          configuredProps: {
            t__content: 'サンプルコンテンツ２',
            t__tag: null,
            t__isHtml: null,
          },
          children: [],
          styleProps: {
            styles: null,
            classes: null,
          },
          advancedSettings: {
            useBeforeInitFunction: null,
          },
          isDeleting: false,
        },
      ],
      styleProps: {},
      advancedSettings: {},
      isDeleting: false,
    },
    {
      label: 'タブグループアイテム',
      component: 'TabGroupItem',
      configuredProps: {
        title: 'テストタブ',
      },
      children: [
        {
          id: 'hdw4r1o2itjt',
          label: 'コンテンツ',
          component: 'TextContent',
          configuredProps: {
            t__content: 'サンプルコンテンツ３',
            t__tag: null,
            t__isHtml: null,
          },
          children: [],
          styleProps: {
            styles: null,
            classes: null,
          },
          advancedSettings: {
            useBeforeInitFunction: null,
          },
          isDeleting: false,
        },
      ],
      styleProps: {},
      advancedSettings: {},
      isDeleting: false,
    },
  ],
})

type TabGroupItem = {
  title: string
  uid: string
}

export class DisplayToggleGroupContainer {
  // Rendering された Item の uid を配列で保持
  children: TabGroupItem[] = []
  // 現在表示するべき Item の uid の index を保持 (childrenUids より)
  displayingIndex: number = 0

  get currentDisplayingUid() {
    return this.children[this.displayingIndex].uid
  }
}

export default {
  props: {
    defaultActiveIndex: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  provide() {
    return {
      DisplayToggleGroupContainerInstance: this.DisplayToggleGroupContainer,
    }
  },
  data() {
    return {
      DisplayToggleGroupContainer: new DisplayToggleGroupContainer(),
    }
  },
  created() {
    if (this.defaultActiveIndex != null) {
      this.DisplayToggleGroupContainer.displayingIndex = this.defaultActiveIndex
    }
  },
  methods: {
    selectTab(index) {
      this.DisplayToggleGroupContainer.displayingIndex = index
    },
  },
}
</script>
