<template>
  <CInputString
    type="text"
    class="form-control d-inline-block"
    placeholder="キーワードで絞り込み..."
    v-model="ComposableDataListServiceInstance.query.search"
  />
</template>
<script lang="ts">
import { registerComposableComponentSettings } from '../../../../ComposableComponents'
import { inject } from 'vue'
import { ComposableDataListService } from '../../ComposableDataListService'

const name = 'DataListKeywordSearch'
registerComposableComponentSettings(name, {
  label: 'キーワード検索',
})
export default {
  name,
  setup() {
    return {
      ComposableDataListServiceInstance: inject<ComposableDataListService>(
        'ComposableDataListServiceInstance',
      ),
    }
  },
}
</script>
