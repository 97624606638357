import { ModelDef } from '../../common/$models/ModelDef'
import { codeInputCommonAttrs } from '../../common/$models'

export const directus_permissions: ModelDef = {
  tableName: 'directus_permissions',
  tableLabel: 'Permissions',
  tableComment: '',
  modelType: 'admin',
  doNotSyncModel: true,
  timestamps: false,
  columns: {
    id: {
      type: 'NUMBER',
    }, // int(10) unsigned	NO	PRI	NULL	auto_increment
    role: {
      type: 'RELATIONSHIP_MANY_TO_ONE',
      relationshipManyToOne: {
        collectionName: 'directus_roles',
        labelFormatter: (row: any) => {
          return `${row.name} (${row.id})`
        },
      },
    }, // char(36)	YES	MUL	NULL
    collection: {
      type: 'STRING',
    }, // varchar(64)	NO	MUL	NULL
    action: {
      type: 'STRING',
    }, // varchar(10)	NO		NULL
    permissions: {
      type: 'TEXT',
      inputAttrs: {
        ...codeInputCommonAttrs,
      },
    },
    validation: {
      type: 'TEXT',
      inputAttrs: {
        ...codeInputCommonAttrs,
      },
    }, // json	YES		NULL
    presets: {
      type: 'TEXT',
      inputAttrs: {
        ...codeInputCommonAttrs,
      },
    }, // json	YES		NULL
    fields: {
      type: 'TEXT',
      inputAttrs: {
        ...codeInputCommonAttrs,
      },
    }, // text	YES		NULL
  },
}
