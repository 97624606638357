import { ModelDef } from '../../types'
import { codeInputCommonAttrs } from '../../common/$models/constants'
import { colBehaviorTypeAndLabel } from '../DataExportSettings/ModelDataExportSettings'
import { displayModelNameWithLabel } from '../../common/utils'
import { addModelDoc } from '../../plugins/HelpDoc/coreDocsHelperFunctions'

const modelName = 'dataImportSettings'

addModelDoc(
  modelName,
  'データインポート設定では、外部システムから出力されたCSVやExcelファイルを取り込む設定を作成可能です。定義後は、 <router-link to="/importDataFromExternal">データインポート実行</router-link> 画面より利用可能です。<br/>必要に応じて、Javascript関数による変換処理・カスタマイズが可能であるため、多彩なケースに対応可能です。',
)

const model: ModelDef = {
  tableName: modelName,
  tableLabel: 'データインポート設定',
  bulkControllable: true,
  tableComment: '',
  primaryKeyColType: 'UUID',
  defaultSort: {
    key: 'createdAt',
    order: 'desc',
  },
  formColumnGroups: {
    basics: {
      type: 'tab',
      groupKey: 'tab1',
      label: '基本設定',
    },
    fields: {
      type: 'tab',
      groupKey: 'tab1',
      label: '取込フィールド',
    },
    advanced: {
      type: 'tab',
      groupKey: 'tab1',
      label: '高度な設定',
    },
  },
  columns: {
    name: {
      label: 'インポート設定名',
      type: 'STRING',
      facet: true,
      unique: true,
      // editable: false,
      // editableOnCreate: true,
      searchable: true,
      // inputHelpText: '名称は後から変更することができませんのでご注意ください。',
      validate: {
        notEmpty: true,
      },
      inputAttrs: { wrapperClass: 'col-4' },
      afterComponent:
        '<span class="small text-muted" v-if="$parent.value">/importDataFromExternal/{{$parent.value}} <br/>で個別画面としてアクセス可能です。</span>',
    },
    targetModelName: {
      type: 'SELECT',
      label: 'インポート先モデル',
      // editable: false,
      // editableOnCreate: true,
      strictSelections: true,
      validate: {
        notEmpty: true,
      },
      selections: () => {
        return $core.$modelsLoader.modelNames
      },
      customLabel: (val, callerVueInstance, recordRoot) => {
        return displayModelNameWithLabel(val)
      },
      editCallback({ row, newValue, oldValue }) {
        if (newValue && !row.name) {
          row.name = displayModelNameWithLabel(newValue, false, true)
        }
        return row
      },
      inputAttrs: { wrapperClass: 'col-4' },
    },
    desc: {
      label: 'ロジックの補足説明',
      type: 'TEXT',
      inputAttrs: { wrapperClass: 'col-4' },
    },
    pathThroughOriginalProps: {
      // groupKey: 'fields',
      label: 'パススルー取込を実施',
      type: 'BOOLEAN',
      default: false,
      inputAttrs: { wrapperClass: 'col-12' },
      inputHelpText: '取込フィールドの設定に加えて、Excel列名(1列目)の項目名をそのまま取り込みます。 一覧表示の "Excelエクスポート" からダウンロードしたデータを変更して、そのまま取り込む際に便利です。',
    },
    useDateConversion: {
      groupKey: 'advanced',
      // TODO: 未実装 on ./importServiceWithDataImportSetting.ts
      label: '日付変換を利用する',
      type: 'BOOLEAN',
      default: true,
      inputAttrs: { wrapperClass: 'col-2' },
      visibleOnIndex: false,
      editCallback({ row, newValue, oldValue }) {
        if (newValue !== oldValue && newValue && !row.dateConversionFormat) {
          row.dateConversionFormat = 'yyyy-mm-dd'
        }
        return row
      },
    },
    dateConversionFormat: {
      groupKey: 'advanced',
      // TODO: 未実装 on ./importServiceWithDataImportSetting.ts
      label: '日付変換フォーマット',
      type: 'STRING',
      enableIf: { useDateConversion: true },
      default: 'yyyy-mm-dd',
      visibleOnIndex: false,
    },
    useBeforeFormatFunction: {
      groupKey: 'advanced',
      label: '変換処理前関数(全件対象)を利用する',
      type: 'BOOLEAN',
      visibleOnIndex: false,
      inputAttrs: { wrapperClass: 'col-12' },
    },
    beforeFormatFunction: {
      groupKey: 'advanced',
      label: '変換処理前関数(全件対象)',
      type: 'TEXT',
      afterComponent: '<div class="small text-muted">async ({<code>originalDataRows</code>, <code>instance</code>, <code>sourceData</code>, <code>sourceDataIndex</code>}): Promise => { (...); return originalDataRows } のコンテキストで実行されます。 変換処理前の 取込データを変更するには <code>originalDataRows</code> (配列) を書き換えてください。</div>',
      inputAttrs: {
        ...codeInputCommonAttrs,
        placeholder: 'originalDataRows = originalDataRows.filter(...)',
      },
      enableIf: { useBeforeFormatFunction: true },
      visibleOnIndex: false,
    },
    useAfterFormatFunction: {
      groupKey: 'advanced',
      label: '変換処理後関数(全件対象)を利用する',
      type: 'BOOLEAN',
      visibleOnIndex: false,
      inputAttrs: { wrapperClass: 'col-12' },
    },
    afterFormatFunction: {
      groupKey: 'advanced',
      label: '変換処理後関数(全件対象)',
      type: 'TEXT',
      // comment: 'async ({formattedDataRows}): Promise => { (...) } のコンテキストで実行されます。formattedDataRows のプロパティを設定、書き換えください。',
      afterComponent: '<div class="small text-muted">async ({<code>formattedDataRows</code>, <code>instance</code>, <code>originalDataRows</code>}): Promise => { (...); return formattedDataRows } のコンテキストで実行されます。 変換処理結果を変更するには <code>formattedDataRows</code> (配列) を書き換えてください。</div>',
      inputAttrs: codeInputCommonAttrs,
      enableIf: { useAfterFormatFunction: true },
      visibleOnIndex: false,
    },
    beforeEachRowConvertFunction: {
      groupKey: 'advanced',
      label: '1行単位での処理関数(変換前処理)',
      visibleOnIndex: false,
      afterComponent: '<div class="small text-muted">async ({<code>row</code>, <code>sourceDataRow</code>, <code>allSourceDataRows</code>, <code>sourceDataRowIndex</code>}): Promise  => { (...); return row } のコンテキストで実行されます。 <code>row</code> のプロパティを設定、書き換えください。</div>',
      type: 'TEXT',
      inputAttrs: codeInputCommonAttrs,
    },
    excludeFields: {
      groupKey: 'basics',
      enableIf: (row) => !!row.targetModelName,
      type: 'MULTISELECT',
      label: '取込対象外フィールド (保護フィールド)',
      visibleOnIndex: false,
      inputAttrs: { wrapperClass: 'col-12' },
      selections(record, currentValue, initialValue) {
        return [''].concat($core.$models[record.targetModelName].colNames)
      },
      inputHelpText:
        '指定したフィールドは取込時に更新されず、値が保護されます。各行の変換処理後に除外処理を実施します。',
    },
    fields: {
      groupKey: 'fields',
      enableIf: (row) => !!row.targetModelName,
      label: '取り込み列毎の変換処理設定',
      type: 'ARRAY_OF_OBJECT',
      visibleOnIndex: false,
      inputAttrs: {
        wrapperClass: 'col-12',
      },
      minValueLength: 1,
      columns: {
        behavior: {
          label: '取込挙動',
          type: 'SELECT',
          strictSelections: true,
          selections: () => Object.keys(colBehaviorTypeAndLabel),
          customLabel: (val) => colBehaviorTypeAndLabel[val],
          default: 'direct',
          validate: { notEmpty: true },
          inputAttrs: { wrapperClass: 'col-2' },
        },
        dataCol: {
          type: 'STRING',
          label: '取り込みデータ列名',
          validate: { notEmpty: true },
          inputAttrs: { wrapperClass: 'col-2' },
        },
        tCol: {
          type: 'STRING',
          label: '保存先フィールド名',
          // validate: { notEmpty: true },
          width: {
            md: 18
          },
          dynamicSelections: true,
          selections(record, currentValue, initialValue, recordRoot) {
            if (!$core.$models[recordRoot.targetModelName]) {
              return []
            }
            const selectedTColNames = (recordRoot.fields || []).map((f) => f.tCol)
            return [''].concat(
              $core.$models[recordRoot.targetModelName].colNames.filter(
                (colName) => selectedTColNames.indexOf(colName) === -1,
              ),
            )
          },
          customLabel: (val, callerVueInstance, recordRoot) => {
            if (!$core.$models[recordRoot.targetModelName]) {
              return ''
            }
            if($core.$models[recordRoot.targetModelName].colLabels[val]) {
              return `${$core.$models[recordRoot.targetModelName].colLabels[val]} (${val})`
            }
            return val
          },
          inputAttrs: { wrapperClass: 'col-2' },
          enableIf: (row, parentRecord) => {
            return row.behavior === 'direct'
          },
        },
        tFunc: {
          enableIf: (row, parentRecord) => row.behavior === 'func',
          label: '変換処理関数(オプション)',
          type: 'TEXT',
          comment:
            'async ({row, dataColName, value, sourceDataRow, allSourceDataRows, sourceDataRowIndex}): Promise<row> => { (...); return row } のコンテキストで実行されます。rowのプロパティを設定、書き換えください。',
          inputAttrs: {
            ...codeInputCommonAttrs,
          },
          default: '',
        },
        execPriority: {
          type: 'NUMBER',
          label: '処理順',
          inputAttrs: { wrapperClass: 'col-1', placeholder: '0' },
          inputHelpText: '優先度 昇順',
          default: '',
        },
      },
      validate: {
        notEmpty: true,
        doNotDuplicateDataColOrTargetColName: (value, col, modelName, record) => {
          if (!value) {
            return false
          }
          // dataCol 重複
          const dupDataCols = value.filter((x, i, self) => self.indexOf(x) !== self.lastIndexOf(x))
          // tCol 重複
          const dupTCols = value.filter((x, i, self) => self.indexOf(x) !== self.lastIndexOf(x))
          if (dupDataCols.length || dupTCols.length) {
            return (
              `${
                dupDataCols.length
                  ? `取り込みデータ列名 "${dupDataCols.join('", "')}" が重複して設定されています。`
                  : ''
              }` +
              `${
                dupTCols.length
                  ? `保存先フィールド名 "${dupTCols.join('", "')}" が重複して設定されています。`
                  : ''
              }`
            )
          }
          return false
        },
      },
    },
  },
  modelType: 'admin',
}

export const dataImportSettings = model

// action to import data
