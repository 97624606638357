import { singletonInstanceSummoner } from '../../../common/singletonInstanceSummoner'
import * as Sentry from '@sentry/vue'

/**
 * # $core.$errorReporter
 *
 * - SentryにFrontエラーを送信するサービス。 (Sentryのライブラリを利用)
 * - 各サービス, 関数内で try ... catch でエラーをキャッチして、ここに送信する。
 * - production環境の場合のみ、Sentryにエラーが送信されます。
 *
 * ## report
 * 利用例:
 * ```ts
 * try {
 *   // 何かの処理
 * } catch (e) {
 *   console.error(e)
 *   // Sentryにエラーを送信
 *   $core.$errorReporter.r(e, this)
 * }
 * ```
 */
class ErrorReporter {
  async report(e: any, callerInstance: any) {
    try {
      // NoNeedReportErrorであれば、何もしない
      if (e?.name === 'NoNeedReportError') {
        return
      }
      const callerInstanceName =
        callerInstance?.constructor?.name || callerInstance?.name || callerInstance?.__name || callerInstance?.$options?.name || callerInstance?.$options?._componentTag
      console.error(
        `ErrorReporter: ${callerInstanceName ? `in [${callerInstanceName}]` : ''} ${e.message}`,
        {
          e,
          callerInstance,
        },
      )
      const isVueError = !!callerInstance?.$options
      if ($core.isProduction) {
        Sentry.captureException(e, callerInstance)
      }
      // Adminのみエラー表示
      if ($core.$embAuth?.user?.isAdmin) {
        $core.$toast.errorToast(`[ErrorReporter] on ${callerInstanceName}${isVueError ? '(vue)' : ''}, ${e.message}`)
      }
    } catch (e) {
      // Loop を回避
      console.error(e)
    }
  }

  async r(e: any, callerInstance: any) {
    this.report(e, callerInstance)
  }

  static get instance(): ErrorReporter {
    return singletonInstanceSummoner('ErrorReporter', ErrorReporter)
  }
}

export const $errorReporter = ErrorReporter.instance
