import { ModelDef } from '../../../common/$models/ModelDef'
import { ModelDirectusUsers } from '../../../front/Users/directus_users'
import { ModelOrganizations } from './organizations'

export type ModelJunctionUsersAndOrgsAndPositions = {
  user: ModelDirectusUsers
  organization: ModelOrganizations
  position: string
}

export const junctionUsersAndOrgsAndPositions: ModelDef = {
  tableName: 'junctionUsersAndOrgsAndPositions',
  tableLabel: 'ユーザー所属組織',
  tableComment: '',
  primaryKeyColType: 'UUID',
  defaultSort: { key: 'createdAt', order: 'desc' },
  modelType: 'admin',
  columns: {
    // TODO: UI的にはユーザに対してまとめて組織と役職が登録できたほうが良い
    // 組織の管理者どうする
    // 優先する組織は一旦忘れる（設定するならユーザ側？）
    // ユーザ
    user: {
      label: 'ユーザー',
      type: 'RELATIONSHIP_MANY_TO_ONE',
      validate: { notEmpty: true },
      relationshipManyToOne: {
        collectionName: 'directus_users',
        labelFormatter: (record) => {
          return record.first_name ? `${record.first_name} (${record.email})` : record.email
        },
      },
    },
    // 組織
    organization: {
      label: '所属組織',
      type: 'RELATIONSHIP_MANY_TO_ONE',
      validate: { notEmpty: true },
      relationshipManyToOne: {
        collectionName: 'organizations',
        labelFormatter: (record) => {
          return `${record.name}(${record.code})`
        },
      },
    },
    // 役職
    position: {
      label: '役職',
      type: 'RELATIONSHIP_MANY_TO_ONE',
      // validate: {notEmpty: true},
      relationshipManyToOne: {
        collectionName: 'positions',
        labelFormatter: (record) => {
          return `${record.name}(${record.code})`
        },
      },
    },
  },
}
