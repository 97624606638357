<template>
  <div class="radio-group-wrapper">
    <label
      v-for="(option, index) in options"
      :key="index"
      class="radio-item"
      :class="{ selected: modelValue?.includes(option.value) }"
      role="div"
    >
      <div class="img">
        <img v-if="option.image" :src="optionImage(option.image)" :alt="option.label" />
      </div>
      <div>
        <div class="label">
          <input
            type="checkbox"
            :name="groupKey"
            :value="option.value"
            @change="selectOption(option)"
          />{{ option.label }}
        </div>
        <div v-if="option.description" class="description">{{ option.description }}</div>
      </div>
    </label>
  </div>
</template>
<script lang="ts">
import { RichSelectOption } from './type'
/**
 * 画像・説明文・値・Labelを持つラジオボタン
 */
export default {
  name: 'RichRadioSelect',
  props: {
    modelValue: {
      type: Array as () => [String, Number],
    },
    options: {
      type: Array as () => RichSelectOption[],
      required: true,
    },
  },
  data() {
    return {
      groupKey: Math.random().toString(32),
    }
  },
  methods: {
    selectOption(option) {
      const value = this.modelValue
      if (value) {
        if (value.includes(option.value)) {
          const index = value.indexOf(option.value)
          value.splice(index, 1)
        } else {
          value.push(option.value)
        }
        this.$emit('update:modelValue', value)
      } else {
        this.$emit('update:modelValue', [option.value])
      }
    },
    optionImage(imgPath) {
      // imgPathがプロトコルを含んでいたら
      if (imgPath.match(/^(http|https):\/\//)) {
        return imgPath
      } else {
        return $core.$imgix.minify(imgPath, { w: 150, h: 150 })
      }
    },
  },
}
</script>
