<template>
  <div class="modern-filter" style="position: relative">
    <div
      @click="toggleShow"
      class="d-flex align-items-center filter-item"
      :class="{
        active: filterLength,
      }"
      role="button"
    >
      <Ficon type="tasks"/>
      <span :class="filterLength ? `badge badge-pill bg-secondary` : ''">{{ filterLength }}件</span>のフィルター
      <span @click.prevent="removeModernFilter" class="trash-icon">
        <Ficon type="trash"/>
      </span>
    </div>
    <div
      class="dropdown-menu position-absolute p-2 border-0 shadow-lg"
      style="min-width: 650px; max-width: 94vw; top: 32px"
      :style="
        FilterControlsServiceInstance.value.filterGroupService.isFocus
          ? 'display: block;'
          : 'display: none;'
      "
    >
      <ModernFilterWrapper/>
    </div>
  </div>
</template>
<script lang="ts">
import { computed, inject } from 'vue'
import { FilterControlsService } from '../../FilterControlsService'
import ModernFilterWrapper from './ModernFilterComponents/ModernFilterWrapper.vue'

export default {
  components: { ModernFilterWrapper },
  data() {
    return {
      modalShow: false,
    }
  },
  watch: {
    FilterControlsServiceInstance: {
      handler(newValue, oldValue) {
        this.modalShow = !!newValue.value.filterGroupService?.isFocus
      },
      deep: true, // ネストされたプロパティも監視
    },
  },
  setup() {
    return {
      FilterControlsServiceInstance: computed(() =>
        inject<FilterControlsService>('FilterControlsServiceInstance'),
      ),
    }
  },
  created() {
    this.modalShow = this.FilterControlsServiceInstance.value.filterGroupService.isFocus
  },
  computed: {
    filterLength() {
      return Object.values(this.FilterControlsServiceInstance.value.filterGroupService.filterObjects)
        .length
    },
  },
  methods: {
    toggleShow() {
      if (this.FilterControlsServiceInstance.value.filterGroupService.isFocus) {
        this.FilterControlsServiceInstance.value.filterGroupService.isFocus = false
      } else {
        this.FilterControlsServiceInstance.value.focusModernFilter()
      }
    },
    removeModernFilter(event) {
      event.stopPropagation()
      this.FilterControlsServiceInstance.value.removeModernFilter()
    },
  },
}
</script>
