import { $virtualModels } from '../common/$virtualModels'
import { $models, $modelsLoader, ModelFactory, TModelObjects } from '../common/$models'
import { storeMethods } from '../common/storeMethods'

import { $toast } from './$toast/$toast'
import { singletonInstanceSummoner } from '../common/singletonInstanceSummoner'
import { Router } from 'vue-router'
import { $modals } from './Modal/$modals'
import { $embAuth } from './auth/$embAuth/$embAuth'
import { $uiState } from '../common/$uiState'
import { $lsCache, LsCache } from './$libs/$lsCache'
import { $loading } from './$loading'
import { ImageTransformer } from './$libs/$imgix'
import { $directusSdk, DirectusSdkType } from '../common/$directusSdk'
import { $errorReporter } from './$libs/$errorReporter/$errorReporter'
import { AppDefinitionLoaderService } from '../plugins/AppDefinitions/front/AppDefinitionLoaderService'
import { DataExportService } from './DataExportSettings/$dataExportService'
import { ExportAsExcel } from './ExcelExport/$exportAsExcel'
import { UserMetaDataService } from './UserMetaDataService/UserMetaDataService'
import { AxiosStatic } from 'axios'
import { CoreBaseClass } from '../common/$coreBaseClass'
import { $i18n } from './I18nService/$i18n'
import { App, ComponentPublicInstance } from 'vue'
import { $obCache, ObjectCacheService } from '../plugins/$obCache/$obCache'
import { ComposableComponentSettingsManager } from '../plugins/ComposableComponents/$composableComponentSettingsManager'
import { $frontErrors } from './FrontErrors/$frontErrors'
import { UserNotificationsService } from '../plugins/UserNotifications/front/$userNotificationsService'
import { $inlineCssController, InlineCssController } from './InlineCssController/$inlineCssController'

/**
 * Frontのための $core 内の各種サービスインスタンスを保持するSingleton Instance
 * window.$core.$models などとしてアクセスするための中核
 * 各種共通で利用するModuleをInject
 * ここに列挙しているものは "デフォルトの" Modulesであり、まずもって必ずどのプロジェクトでも利用するものを付与している。
 * 各プラグインで、 globalThis.$core にプロパティを拡張していく考え方。
 */
export class Core extends CoreBaseClass {
  $modelsLoader: typeof $modelsLoader = $modelsLoader
  $models: Record<string, ModelFactory> = $models
  $storeMethods = storeMethods
  /**
   * @hidden from docs
   */
  $directusSdk: DirectusSdkType = $directusSdk
  /**
   * Alias of $directusSdk
   * @hidden from docs
   */
  $d: DirectusSdkType = $directusSdk
  $axios: AxiosStatic
  $toast = $toast
  $loading = $loading
  $virtualModels = $virtualModels
  $modals = $modals
  $embAuth = $embAuth
  $uiState = $uiState
  $lsCache: LsCache = $lsCache
  $obCache: ObjectCacheService = $obCache
  $imgix?: ImageTransformer
  $errorReporter: typeof $errorReporter = $errorReporter
  $inlineCssController: InlineCssController = $inlineCssController
  /**
   * UserMetaData
   */
  $userMetaData: UserMetaDataService
  /**
   * @hidden from docs
   */
  $appDefinitionLoader: AppDefinitionLoaderService
  $dataExportService: DataExportService
  $exportAsExcel: ExportAsExcel
  $i18n = $i18n
  /**
   * 通常の管理画面を利用しない場合にtrueにする, default false
   * 独自画面を構築したい場合に、いくつかのデフォルト挙動をoffにする (signInへのリダイレクト挙動など)
   */
  isSdkMode: boolean = false
  VueClass: App
  $router: Router & Record<string, any> & { currentRoute: any }
  $vueRoot: ComponentPublicInstance
  $root: ComponentPublicInstance
  isProduction: boolean

  /**
   * Error types
   */
  $frontErrors = $frontErrors

  /**
   * Composable Components
   */
  $composableComponentSettingsManager: ComposableComponentSettingsManager

  $userNotificationsService: UserNotificationsService

  constructor() {
    super()
    // @ts-ignore
    this.$axios = this.$d.transport.axios
  }

  /**
   * @hidden from docs
   */
  static get instance(): Core {
    // return makeItReactive(singletonInstanceSummoner('CORE', Core))
    return singletonInstanceSummoner('CORE', Core)
  }

  /**
   * Serviceを注入する
   * window.$core.$serviceName で利用可能になる
   * TODO: これ全然使っていないが... どうしようか
   * @param serviceName
   * @param serviceInstance
   * @hidden from docs
   */
  injectService(serviceName: string, serviceInstance) {
    this[serviceName] = serviceInstance
  }
}

const coreInstance = Core.instance
// @ts-ignore
if (!globalThis.$core) {
  // @ts-ignore
  globalThis.$core = coreInstance as Core
}

// To avoid error(s) from some libs
if (!globalThis.global) {
  // @ts-ignore
  globalThis.global = globalThis
}
export const $core = coreInstance as Core
