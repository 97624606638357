export const inputTypes = Object.freeze({
  STRING: 'string',
  TEXT: 'textarea',
  RICHTEXT: 'richtext',
  DATEPICKER: 'datepicker',
  DATETIMEPICKER: 'datetimepicker',
  NUMBER: 'number',
  CHECKBOX: 'checkbox',
  BOOLEAN: 'checkbox',
  SELECT: 'select',
  MULTISELECT: 'multiselect',
  REFERENCE: 'reference',
  ARRAY_OF_OBJECT: 'arrayOfObject',
  FILE: 'file',
  RELATIONSHIP_MANY_TO_ONE: 'RELATIONSHIP_MANY_TO_ONE',
  RELATIONSHIP_ONE_TO_MANY: 'RELATIONSHIP_ONE_TO_MANY',
  CONDITIONAL_EXPRESSION: 'CONDITIONAL_EXPRESSION',
  TIME: 'TIME',
  JSON: 'JSON',
})

export const hasDecimalTypes = Object.freeze([
  'FLOAT',
  'DOUBLE',
  'DECIMAL',
])

export const colTypeInputTypeMap = Object.freeze({
  STRING: inputTypes.STRING,
  TEXT: inputTypes.TEXT,
  RICHTEXT: inputTypes.RICHTEXT,
  CITEXT: inputTypes.STRING,
  SELECT: inputTypes.SELECT,
  INTEGER: inputTypes.NUMBER,
  NUMBER: inputTypes.NUMBER,
  BIGINTEGER: inputTypes.NUMBER,
  FLOAT: inputTypes.NUMBER,
  REAL: inputTypes.STRING,
  DOUBLE: inputTypes.NUMBER,
  DECIMAL: inputTypes.NUMBER,
  DATE: inputTypes.DATETIMEPICKER,
  DATEONLY: inputTypes.DATEPICKER,
  DATETIME: inputTypes.DATETIMEPICKER,
  BOOLEAN: inputTypes.CHECKBOX,
  ENUM: inputTypes.SELECT,
  ARRAY: inputTypes.STRING,
  JSON: inputTypes.JSON,
  JSONB: inputTypes.STRING,
  BLOB: inputTypes.STRING,
  UUID: inputTypes.STRING,
  CIDR: inputTypes.STRING,
  INET: inputTypes.STRING,
  MACADDR: inputTypes.STRING,
  RANGE: inputTypes.STRING,
  GEOMETRY: inputTypes.STRING,
  REFERENCE: inputTypes.REFERENCE,
  ARRAY_OF_OBJECT: inputTypes.ARRAY_OF_OBJECT,
  FILE: inputTypes.FILE,
  MULTISELECT: inputTypes.MULTISELECT,
  RELATIONSHIP_MANY_TO_ONE: inputTypes.RELATIONSHIP_MANY_TO_ONE,
  RELATIONSHIP_ONE_TO_MANY: inputTypes.RELATIONSHIP_ONE_TO_MANY,
  CONDITIONAL_EXPRESSION: inputTypes.CONDITIONAL_EXPRESSION,
  TIME: inputTypes.TIME,
})
