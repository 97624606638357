import { ModelDef } from '../../../common/$models/ModelDef'
import {
  getExtensionCommentsModelNameByBaseModelName,
  recordCommentManyToOneRelationshipColName,
} from '../common/coreRecordCommentConstants'
import { IDLike, UUID } from '../../../types'
import { ModelDirectusUsers } from '../../../front/Users/directus_users'

export type ModelCoreRecordComments<T = Record<string, any>> = {
  id?: UUID
  title: string
  body: string
  commentedBy: string | ModelDirectusUsers
  threadId: string
  status: string
  replies: string
  attachmentFiles: string
  commentTargetModelName: string
  commentTargetVirtualModelName: string
  // attachments?: ModelCoreCommentAttachments[]
  [recordCommentManyToOneRelationshipColName]: T | IDLike
}
export type ModelCoreRecordCommentsSaveable = Partial<ModelCoreRecordComments>

/**
 * # RecordCommentsDisplayService Model定義のExtension (つまりModel拡張用Model) である、RecordComments のModel定義を生成する
 * @param model
 */
export const generateCoreRecordCommentsModelAndColumns = (model: ModelDef): ModelDef => {
  const tableName = getExtensionCommentsModelNameByBaseModelName(model.tableName)
  return {
    extensionBaseModelName: model.tableName,
    extensionType: 'recordComments',
    tableName,
    tableLabel: `${model.tableLabel} コメント`,
    tableComment: '',
    timestamps: true,
    primaryKeyColType: 'UUID',
    defaultSort: { key: 'createdAt', order: 'desc' },
    columns: {
      id: {
        label: 'コメントID',
        type: 'UUID',
        editable: false,
      },
      commentTargetModelName: {
        label: 'モデル名',
        type: 'STRING',
        editable: false,
      },
      commentTargetVirtualModelName: {
        label: 'Virtualモデル名',
        type: 'STRING',
        editable: false,
      },
      [recordCommentManyToOneRelationshipColName]: {
        label: '対象レコード',
        type: 'RELATIONSHIP_MANY_TO_ONE',
        relationshipManyToOne: {
          collectionName: model.tableName,
          labelFormatter: (row) => `${row.id}`,
        },
      },
      title: {
        label: 'タイトル',
        type: 'STRING',
        comment: '通常は、スレッドを立てるときにのみ利用する',
      },
      body: {
        label: '内容',
        type: 'TEXT',
        validate: { notEmpty: true },
      },
      commentedBy: {
        label: '作成者',
        type: 'RELATIONSHIP_MANY_TO_ONE',
        relationshipManyToOne: {
          collectionName: 'directus_users',
          labelFormatter: (row) => `${row.first_name} (${row.email})`,
        },
      },
      threadId: {
        label: 'スレッドID',
        type: 'STRING',
        comment:
          "スレッドID。同じスレッドIDのコメントは同じスレッドに属するとみなす。基本的にUUID or 'default' が格納される。",
      },
      status: {
        label: '状態',
        type: 'STRING',
        selections: () => ['unresolved', 'resolved'],
      },
      replies: {
        label: '返信先コメントID',
        type: 'TEXT',
      },
      attachmentFiles: {
        // カンマ区切りで、添付ファイルのpathsを格納する
        label: '添付ファイル',
        type: 'TEXT',
      },
    },
  }
}
