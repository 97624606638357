<template>
  <Datepicker
    v-bind="commonAttrs"
    :model-value="valueYearMonth"
    :datepickerProps="{
      format,
      monthPicker: true,
    }"
    :changeFunction="change"
  />
</template>

<script lang="ts">
import { PropType } from 'vue'
import { ColumnDef } from '../../../../../common/$models/ModelDef'

export default {
  name: 'YearMonthPicker',
  props: {
    modelValue: {
      required: false,
      default: null,
    },
    col: {
      type: Object as PropType<ColumnDef>,
    },
    format: { default: 'yyyy-MM' },
    valueFormatFunction: {
      default: null,
      type: Function as PropType<(value: { year: number; month: number }) => any>,
    },
    commonAttrs: {
      type: Object as PropType<Record<string, unknown>>,
      required: false,
      default: () => ({}),
    },
  },
  computed: {
    modelValueString() {
      if (!this.modelValue) {
        return ''
      }
      return `${this.modelValue}`
    },
    valueYearMonth() {
      if (!this.modelValueString || this.modelValueString === '') {
        return null
      }
      if (this.col.type === 'NUMBER') {
        return {
          year: this.modelValueString.slice(0, 4),
          month: Number(this.modelValueString.slice(4, 6)) - 1,
        }
      } else if (
        this.col.type === 'DATEONLY' ||
        this.col.type === 'DATETIME' ||
        this.col.type === 'STRING'
      ) {
        return {
          year: this.modelValueString.slice(0, 4),
          month: Number(this.modelValueString.slice(5, 7)) - 1,
        }
      }
      return {
        year: this.modelValueString.slice(0, 4),
        month: Number(this.modelValueString.slice(5, 7)) - 1,
      }
    },
    emptyValue() {
      if (this.col.type === 'NUMBER') {
        return null
      } else if (this.col.type === 'DATEONLY' || this.col.type === 'DATETIME') {
        return null
      }
      return ''
    }
  },
  methods: {
    change(updateValue: { year: number; month: number } | null) {
      this.$nextTick(() => {
        if (!updateValue) {
          this.$emit('update:modelValue', this.emptyValue)
          return
        }
        if (updateValue) {
          updateValue.month += 1
        }
        this.$emit('update:modelValue', this.formatEmittableValue(updateValue))
      })
    },
    formatEmittableValue({ year, month }: { year: number; month: number }) {
      // if (!year || !month) {
      //   return null
      // }
      if (this.valueFormatFunction) {
        return this.valueFormatFunction({ year, month })
      }
      const monthZeroFilled = `0${month}`.slice(-2)
      if (this.col.type === 'NUMBER') {
        return Number(`${year}${monthZeroFilled}`)
      } else if (this.col.type === 'DATEONLY' || this.col.type === 'DATETIME') {
        return `${year}-${monthZeroFilled}-01`
      }
      return `${year}-${monthZeroFilled}`
    },
  },
}
</script>
