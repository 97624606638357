import { RouteRecordRaw } from 'vue-router'
import PageOpenDAdmin from './pageOpenDAdmin.vue'

// Directus admin を iFrame で開く際の Routings
export const dAdminPageRoutes: RouteRecordRaw[] = [
  {
    path: '/da/d/:pagePath(.*)',
    component: PageOpenDAdmin,
  },
  // @ts-ignore
]
