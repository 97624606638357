import { embAuthHooks } from './$embAuth'
import { $appHook } from '../../../common/$appHook'
import { $directusSdk } from '../../../common/$directusSdk'
import { $errorReporter } from '../../$libs/$errorReporter/$errorReporter'

// register hooks
export const registerEmbAuthActionsWithDirectusAuth = () => {
  // signIn Error
  $appHook.registerHook(
    embAuthHooks.signIn.ERROR,
    'showErrorMessage',
    (currentValue, callerContext, args) => {
      globalThis.$core.$toast.errorToast(
        $core.$dict.get(
          'signInFailedDisplayMessage',
          'ログインできませんでした、メールアドレスまたはパスワードを確認してください。',
        ),
      )
      return true // Avoid throw error
    },
  )

  // SignIn 実行
  $appHook.registerHook(embAuthHooks.signIn.DO, 'do', (currentValue, callerContext, args) => {
    const { email, password } = args
    try {
      // @ts-ignore
      $directusSdk.auth.resetStorage()
      // @ts-ignore
      $directusSdk.auth._refreshPromise = undefined
    } catch (e) {
      console.error(e)
    }
    return $directusSdk.auth.login({ email, password })
  })

  // SignIn.AFTER
  const signedInCallbackDefault = userData => {
    globalThis.$core.$toast.successToast(
      $core.$dict.get('signInSucceededDisplayMessage', 'ログインしました。'),
    )
    if (globalThis.$core.$router) {
      // TODO: FIXME!
      const route = $core.$router.currentRoute
      const redirectTo =
        route.query?.redirectTo && route.query.redirectTo !== 'undefined'
          ? decodeURIComponent(route.query.redirectTo.toString())
          : '/'
      globalThis.$core.$router.push(redirectTo)
      // globalThis.$core.$router.push('/')
    }
  }
  $appHook.registerHook(embAuthHooks.signIn.AFTER, 'redirection', async user => {
    if (globalThis.$core && globalThis.$core.$toast) {
      return $core.$configVars.get(
        'embAuth.signedIn.successCallback',
        signedInCallbackDefault,
      )(user)
    }
  })

  // UserData取得
  $appHook.registerHook(
    embAuthHooks.getUserData.DO,
    'do',
    async (currentValue, callerContext, args) => {
      if (!$directusSdk.auth.token) {
        return null
      }
      try {
        const me = await $directusSdk.users.me.read({ fields: '*.*' })
        me.name = (me.last_name || '') + (me.first_name || '') || me.name
        if (!me.name) {
          me.name = me.email
        }
        return me
      } catch (e) {
        console.error(`[registerEmbAuthActionsWithDirectusAuth] embAuthHooks.getUserData.DO`, { e })
        return null
      }
    },
  )
  // UserData取得失敗 = ログアウトする
  $appHook.registerHook(
    embAuthHooks.getUserData.ERROR,
    'default',
    async (currentValue, callerContext, args) => {
      console.log(`[${embAuthHooks.getUserData.ERROR}]`, {
        currentValue,
        callerContext,
        args,
      })
      await callerContext.signOut()
    },
  )
  // Refresh
  $appHook.registerHook(
    embAuthHooks.refreshUserData.DO,
    'do',
    async (currentValue, callerContext, args) => {
      await globalThis.$core.$embAuth.getUserData()
      return currentValue
    },
  )

  // signOut
  $appHook.registerHook(embAuthHooks.signOut.DO, 'do', async () => {
    if ($directusSdk.auth.token) {
      try {
        if ($directusSdk.auth.mode === 'json') {
          try {
            await $directusSdk.auth.logout()
          } catch (e) {
            console.error(e)
          }
        }
        // @ts-ignore
        await $directusSdk.auth.resetStorage()

        // ログアウト時、ログイン画面にリダイレクト
        globalThis.$core.$router.push($core.$configVars.get('signInRedirectTo', '/signIn'))
        window.location.reload()
      } catch (e) {
        $errorReporter.r(e, this)
      }
    }
  })

  // TODO: なんかおかしい?
  // embAuthHooks.updateUserData.
  $appHook.registerHook(
    embAuthHooks.updateUserData.DO,
    'do',
    async (currentValue, callerContext, args) => {
      console.log({ currentValue, callerContext, args })
      const userData = args
      const userRaw = await $directusSdk.users.me.update(userData)
      return userRaw
    },
  )
}
