let loadPromise = null
// import * as XLSXType from '../../../libs/SheetjsPro/edit/types'
let XLSX = null
export const loadXlsxLib = async () => {
  if (XLSX) {
    return XLSX
  }
  if (!loadPromise) {
    loadPromise = import(/* webpackChunkName: 'xlsx' */ 'xlsx')
  }
  // @ts-ignore Pro版の場合 window.XLSX になる。
  XLSX = (await loadPromise).default || window.XLSX
  return XLSX
}
