import { ColumnDef } from '../../../../../common/$models/ModelDef'

/**
 * inputComponent に応じた Style を返す
 */
export const colItemWrapperBindByInputComponentTypes: {
  [key: string]: (params: {
    colName: string,
    colDef: ColumnDef,
    record: Record<string, any>,
    value: any,
  }) => Record<string, any> | null
} = {
  /**
   * ColorfulSelect
   * - colorLabelSelectionConditon (設定値) に応じたスタイルを返す
   */
  ColorfulSelect: ({
    colName,
    colDef,
    record,
    value,
  }) => {
    const colorLabelSelectionConditon = colDef.colorLabelSelectionConditon
    if (colorLabelSelectionConditon && colorLabelSelectionConditon.length) {
      const find = colorLabelSelectionConditon.find((condition) => {
        const { valueCondition, useRegExp } = condition
        if (useRegExp) {
          const regExp = new RegExp(valueCondition)
          return regExp.test(value)
        } else {
          return value === valueCondition
        }
      })
      if (find) {
        return {
          style: {
            backgroundColor: find.color,
            color: find.textColor,
            border: `1px solid ${find.borderColor}`,
            padding: `4px 10px`,
            'border-radius': `4px`,
            'font-size': '.9em',
          },
        }
      }
    }
    return null
  },
}
