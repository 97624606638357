<template>
  <div class="d-flex header-nav-menu gap-3 position-relative">
    <div class="header-nav-menu--app-logo-wrapper pl-2 bold hover-show-parent">
      <AdminRoleOnly class="hover-show-child position-absolute small" style="white-space: nowrap; z-index: 99; bottom: -14px; left: 8px;font-weight: normal">
        <a href="#" style="cursor: pointer;" @click.prevent="() => $core.$modals.openEditViewModal({
      modelName: 'appDefinitions',
      id: $core.$appDefinitionLoader.appDefinition?.id,
      successCallback: () => {
        $core.$utils.getWindowObject().location.reload()
      },
      })">アプリケーション定義を編集 <ficon type="external-link-alt"/></a>
      </AdminRoleOnly>
      <AppLogoAndName/>
    </div>
    <div class="header-nav-menu--app-menu-wrapper d-none d-lg-block">
      <AppDynamicMenu :showOtherAppLinks="false"/>
    </div>
  </div>
</template>
<script lang="ts">
import AppLogoAndName from './AppLogoAndName.vue'
import AppDynamicMenu from '../../AppMenu/AppDynamicMenu.vue'

export default {
  name: 'HeaderNavMenu',
  components: { AppDynamicMenu, AppLogoAndName },
  data() {
    return {
      initialized: true,
    }
  },
}
</script>
<style lang="scss">
.p-2 > .header-nav-menu > .header-nav-menu--app-menu-wrapper {
  margin: -0.5em;
  margin-bottom: -0.6em;
}
body {
  --header-nav-menu-active-color: var(--bs-link-color);
}
.header-nav-menu {
  .nav-item {
    .nav-link {
      padding-left: .8em;
      padding-right: .8em;
      &.router-link-active, &.router-link-exact-active, &:hover {
        color: var(--header-nav-menu-active-color);
        border-bottom: 3px solid var(--header-nav-menu-active-color);
        margin-bottom: -1px;
        //font-weight: bold;
      }
    }
    &.app-menu-item--type-heading {
      display: none !important;
      //.nav-item-heading {
      //  position: absolute;
      //  width: auto;
      //  left: 0;
      //  top: 0;
      //  white-space: pre-wrap;
      //  text-wrap: nowrap;
      //  font-size: 12px;
      //}
    }
  }
}
</style>

