<template>
  <div class="card m-0 h-100">
    <img v-if="thumbnailImage" :src="thumbnailImage" class="card-img-top"/>
    <div class="card-body">
      <template v-if="useCardBodyHTML">
        <component
          :is="{
            template: cardBodyHTMLContent,
            props: ['item'], computed: {row() { return this.item }}}" :item="item"/>
      </template>
      <template v-else>
        <h5 v-if="cardBodyTitle" class="card-title bold">{{ item[cardBodyTitle] }}</h5>
        <div v-if="cardBodyText" class="card-text" :style="{ fontSize: cardBodyTextSize }">
          {{ item[cardBodyText] }}
        </div>
      </template>
      <div class="mt-2">
        <template v-for="tag in cardTags" :key="tag.tagColName">
          <span
            v-for="(tagValue, index) in getTagValues(item[tag.tagColName])"
            :key="`${tag.tagColName}-${index}`"
            :class="['badge', 'me-2', 'mb-1', getBadgeClass(tag.tagVariant)]"
          > {{ tagValue }} </span>
        </template>
      </div>
    </div>
    <component
      v-if="showCardFooter"
      class="card-footer bg-white"
      :is="{template: `<div>${cardFooterContent}</div>`, props: ['item'], computed: {row() { return this.item }}}"
      :item="item"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'DataListDisplayCardItem',
  props: {
    item: {
      type: Object as PropType<Record<string, any>>,
      required: true,
    },
    cardThumbnail: String,
    cardBodyTitle: String,
    cardBodyText: String,
    cardBodyTextSize: {
      type: String,
      default: '0.9em',
    },
    cardTags: {
      type: Array as PropType<Array<{ tagColName: string; tagVariant: string }>>,
      default: () => [],
    },
    useCardBodyHTML: Boolean,
    cardBodyHTMLContent: String,
    showCardFooter: Boolean,
    cardFooterContent: String,
  },
  computed: {
    thumbnailImage() {
      return this.item[this.cardThumbnail] ? $core.$imgix.buildUrl(this.item[this.cardThumbnail]) : ''
    },
  },
  methods: {
    getBadgeClass(variant: string) {
      const baseVariant = variant.replace('outline-', '')
      return variant.startsWith('outline-')
        ? `border border-${baseVariant} text-${baseVariant}`
        : `bg-${baseVariant} text-white`
    },
    getTagValues(value: any): string[] {
      if (Array.isArray(value)) {
        return value.map(String)
      }
      return value ? [String(value)] : []
    },
  },
})
</script>
