import { ModelDef } from '../../common/$models/ModelDef'

export const directus_roles: ModelDef = {
  tableName: 'directus_roles',
  tableLabel: 'APIロール',
  doNotSyncModel: true,
  timestamps: false,
  // creatable: false,
  // updatable: false,
  deletable: false,
  modelType: 'admin',
  columns: {
    name: {
      label: 'APIロール名',
      type: 'STRING',
      unique: true,
    },
    enforce_tfa: {
      label: '2段階認証を強制',
      type: 'BOOLEAN',
    },
    admin_access: {
      label: '管理者アクセス',
      type: 'BOOLEAN',
    },
    description: {
      label: '説明',
      type: 'TEXT',
      inputAttrs: {
        wrapperClass: 'col-12',
      },
    },
  },
}
