import { singletonInstanceSummoner } from '../../common/singletonInstanceSummoner'
import { ComposableComponentSettings, composableComponentStyleColumnsDefault } from './ComposableComponentSettings'
import { ComponentPropsOptions } from 'vue'
import { vuePropsIntoColumnDefs } from './registerComposableComponentSettings'

/**
 * ComposableComponentSettings を保持する Singleton instance
 * 各コンポーネント定義から、このインスタンスに対して設定値を登録する, via configureComposableComponentSettings() 関数
 */
export class ComposableComponentSettingsManager {
  public settings: Record<string, ComposableComponentSettings> = {}

  static get instance(): ComposableComponentSettingsManager {
    return singletonInstanceSummoner(
      'ComposableComponentSettingsManager',
      ComposableComponentSettingsManager,
    )
  }

  get componentNames(): string[] {
    return Object.keys(this.settings)
  }

  getSetting(componentName: string): ComposableComponentSettings {
    return this.settings[componentName]
  }

  setSetting(componentName: string, settings: ComposableComponentSettings): void {
    this.settings[componentName] = settings
  }
  registerComponent(
    componentName: string,
    settings: ComposableComponentSettings,
    {
      props = null,
      defaultValues = null,
    }: {
      props?: ComponentPropsOptions
      defaultValues?: Record<string, any>
    } = {}
  ) {
    if (props) {
      settings.configColumns = Object.assign(
        vuePropsIntoColumnDefs(props, defaultValues),
        settings.configColumns,
      )
    }
    settings.styleColumns = Object.assign(
      composableComponentStyleColumnsDefault,
      settings.styleColumns || {},
    )
    if (!settings.componentDef) {
      settings.componentDef = componentName
    }
    this.setSetting(componentName, settings)
  }
}

export const $composableComponentSettingsManager = ComposableComponentSettingsManager.instance
