<template>
  <img :height="height" :width="width" :style="styleBind" :src="imgSrc" />
</template>

<script lang="ts">
import { registerComposableComponentSettings } from '../../../plugins/ComposableComponents'
import { ColumnDefByColName } from '../../../common/$models/ModelDef'

/**
 * 画像の設定カラム
 */
const composableComponentSimpleImage: ColumnDefByColName = {
  width: { type: 'STRING' },
  height: { type: 'STRING' },
  file: {
    type: 'FILE',
  },
  objectFit: {
    type: 'STRING',
    selections: () => ['fill', 'contain', 'cover', 'none', 'scale-down'],
  },
  // src: { type: 'STRING', editable: false },
}
registerComposableComponentSettings('SimpleImage', {
  label: '画像',
  configColumns: composableComponentSimpleImage,
  defaultProps: {},
  styleColumns: {},
})

/**
 * シンプルなテキストコンテンツ, TextNode と同じようなイメージで、各種 ComposableComponent が継承して使用することも可能
 */
export default {
  name: 'SimpleImage',
  category: 'Basic',
  props: {
    height: {},
    width: {},
    file: {},
    objectFit: {},
  },
  computed: {
    imgSrc() {
      return $core.$imgix.buildUrl(this.file, {})
    },
    styleBind() {
      return {
        objectFit: this.objectFit || '',
        maxWidth: '100%',
      }
    },
  },
}
</script>
