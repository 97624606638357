<template>
  <input
    v-bind="commonAttrs"
    class="form-control text-right"
    :value="formattedValue"
    @change="updateValueAndError"
    ref="theInput"
    @keypress="handleKeypress"
    @focus="isOnFocus = true"
    @blur="isOnFocus = false"
  />
</template>
<script lang="ts">
import { PropType } from 'vue'
import { ColumnDef } from '../../../common/$models/ModelDef'
import { hasDecimalTypes } from './inputTypes'

/**
 * カンマ区切りを解除, 全角数字 to 半角数字
 * @param value
 */
const formatDisplayValueToNumber = (value: string): number | '' => {
  if (value === '' || value === null || value === undefined) {
    return ''
  }
  const _v = Number(value.replace(/,/g, '').replace(/[０-９]/g, (s) => {
    return String.fromCharCode(s.charCodeAt(0) - 0xfee0)
  }))
  return isNaN(_v) ? '' : _v
}

/**
 * 数値入力用のコンポーネント
 * - カンマ区切り有無を設定可能
 */
export default {
  name: 'NumberInput',
  props: {
    commonAttrs: {
      type: Object as PropType<Record<string, unknown>>,
      required: true,
    },
    modelValue: {},
    isFormatWithComma: {
      type: Boolean,
      default: false,
    },
    col: {
      type: Object as PropType<ColumnDef>,
      required: true,
    }
  },
  data() {
    return {
      isOnFocus: false,
    }
  },
  computed: {
    formattedValue() {
      if (isNaN(this.modelValue) || this.modelValue === null || this.modelValue === undefined) {
        return ''
      }
      // 入力中は カンマ区切りを解除する
      if (this.isFormatWithComma && !this.isOnFocus) {
        return this.modelValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return this.modelValue
    },
    hasDecimal() {
      return hasDecimalTypes.includes(this.col.type)
    }
  },
  methods: {
    updateValueAndError(event) {
      // カンマ区切りを解除, 全角数字 to 半角数字
      const convertedValue = formatDisplayValueToNumber(event.target.value)
      this.$emit('update:model-value', convertedValue)
      if (convertedValue === '') {
        // 不正な値の場合は、空文字列にする
        this.$refs.theInput.value = ''
      }
    },
    handleKeypress(event) {
      // 数字 および マイナス 以外の入力を無効化する
      const regEx = this.hasDecimal ? /[-0-9,.]/ : /[-0-9,]/
      if (!regEx.test(event.key)) {
        event.preventDefault()
      }
    },
  },
}
</script>
