<template>
  <div style="position: relative">
    <div class="d-flex align-items-center">
      <span class="column-label mr-2">{{ label }}</span>
      <b-form-select
        class="flex-1"
        :modelValue="operator"
        @update:modelValue="updateOperator"
        :options="options"
        size="sm"
      ></b-form-select>
      <a role="button" @click="toggleSetting">
        <Ficon class="ml-2" type="ellipsis-h" />
      </a>
    </div>
    <div class="mt-1" v-if="operator !== 'isnull' && operator !== 'isnotnull'">
      <multiselect
        v-if="selections.length > 20"
        :allow-empty="true"
        :show-labels="true"
        select-label="選択"
        placeholder="選択"
        :model-value="formattedValue"
        :internal-search="false"
        :options="selections"
        :multiple="true"
        @open="searchChange"
        @update:modelValue="change"
        @search-change="searchChange"
      >
        <template v-slot:noOptions> --- </template>
        <template v-slot:noResult> --- </template>
      </multiselect>
      <b-form-checkbox-group v-else :model-value="formattedValue" @update:modelValue="change">
        <b-form-checkbox v-for="option in selections" :key="option" :value="selectionValue(option)">
          {{ selectionLabel(option) }}
        </b-form-checkbox>
      </b-form-checkbox-group>
    </div>
    <FilterItemManager :visible="isVisibleSetting" @onDismiss="onDismissDropDown" />
  </div>
</template>
<script lang="ts">
import { inject } from 'vue'
import { FilterItemService } from '../../../FilterItemService'
import { FILTER_OPERATOR_FOR_SELECT } from '../../../FilterRuleService'
import FilterItemManager from '../FilterItemManager.vue'
import { SelectOptionItemObject } from '../../../../../../common/$models/ModelDef'

export default {
  props: {
    modelValue: {
      type: Array,
      required: true,
    },
  },
  components: {
    FilterItemManager,
  },
  setup() {
    return {
      filterItemService: inject<FilterItemService<any>>('filterItemService'),
    }
  },
  data() {
    return {
      operator: null,
      isVisibleSetting: false,
      selections: [],
    }
  },
  computed: {
    label() {
      return this.filterItemService.colDef.label || this.filterItemService.colDef.name
    },
    options() {
      // { value: xxxx'', text: 'xxxxx' }のような形で返す
      return Object.keys(FILTER_OPERATOR_FOR_SELECT).map((operator) => {
        return {
          value: operator,
          text: FILTER_OPERATOR_FOR_SELECT[operator],
        }
      })
    },
    formattedValue() {
      const colName = this.filterItemService.colDef.name
      // { [colName]: xxx }の配列の場合
      if (this.modelValue.length > 0 && typeof this.modelValue[0] === 'object') {
        return this.modelValue.map((v) => Object.values(v[colName])[0])
      } else {
        return this.modelValue
      }
    },
  },
  created() {
    this.operator = this.options.length > 0 ? this.options[0].value : null
    this.setSelections()
  },
  methods: {
    toggleSetting() {
      this.isVisibleSetting = !this.isVisibleSetting
    },
    change(value) {
      // { _or: [ { coreRoles: {_contains: 'XXXX'} }, { coreRoles: {_contains: 'YYYY'} }, { coreRoles: {_contains: 'ZZZZ'} } ] }
      console.log({
        value,
        operator: this.operator,
      })
      if (this.filterItemService.colDef.type === 'MULTISELECT') {
        const colName = this.filterItemService.colDef.name
        let changedValue = []
        if (this.operator === 'in') {
          changedValue = value.map((v) => ({ [colName]: { _contains: v } }))
        } else {
          changedValue = value.map((v) => ({ [colName]: { _ncontains: v } }))
        }
        this.$emit('update:modelValue', {
          value: changedValue,
          operator: 'or',
        })
        return
      }
      this.$emit('update:modelValue', {
        value,
        operator: this.operator,
      })
    },
    selectionValue(item) {
      if (item.value) {
        return item.value
      } else {
        return item
      }
    },
    selectionLabel(item) {
      if (item.label) {
        return item.label
      } else {
        return item
      }
    },
    searchChange(query) {
      let list = this.multiSelectOptions

      if (query) {
        query = query.replaceAll('　', ' ').split(' ')
        for (const searchText of query) {
          const tarText = searchText.toLowerCase()
          list = list.filter((v) => `${v.label}`.toLowerCase().includes(tarText))
        }
      }
      this.multiSelectOptions = list
    },
    async setSelections() {
      const list = await this.filterItemService.getSelections()
      if (list) {
        this.selections = list
      }
    },
    updateOperator(value) {
      this.operator = value
      this.$emit('update:operator', this.operator)
    },
    onDismissDropDown() {
      this.$emit('onDismissDropdown')
    },
  },
}
</script>
