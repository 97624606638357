import { ModelDef } from '../../../common/$models/ModelDef'

export const positions: ModelDef = {
  tableName: 'positions',
  tableLabel: '役職',
  tableComment: '',
  defaultSort: { key: 'createdAt', order: 'desc' },
  // TODO: code を pKey にする
  modelType: 'admin',
  columns: {
    // 役職コード
    code: {
      label: '役職コード',
      type: 'STRING',
      unique: true,
      inputAttrs: {
        wrapperClass: 'w-auto',
      },
      validate: { notEmpty: true },
      editableOnCreate: true,
      editable: false,
      primaryKey: true,
      inputHelpText: '後から変更することはできません',
    },
    // 役職名
    name: {
      label: '役職名',
      type: 'STRING',
      validate: { notEmpty: true },
      unique: true,
      inputAttrs: { wrapperClass: 'col-4' },
    },
    sort: {
      label: '表示順',
      type: 'NUMBER',
      inputAttrs: { wrapperClass: 'col-2' },
    },
  },
}
