import { singletonInstanceSummoner } from '../../common/singletonInstanceSummoner'

interface CacheItem<T> {
  value: T
  expiry: number
}

/**
 * # $core.$obCache
 *
 * - TTL（Time to Live）とキャッシュキーを使用して、任意のデータをキャッシュするための汎用的なクラスです。このクラスを使用することで、APIから取得したデータや計算結果など、再利用可能な情報を一時的に格納し、パフォーマンスを向上させることができます。
 *
 * ## 利用例
 * ```ts
 * async function fetchDataFromAPI(): Promise<any> {
 *   // APIからデータを取得する実際の処理を記述します。
 * }
 *
 * async function getMasterData(key: string, ttlInSeconds?: number): Promise<any> {
 *   return await $core.$obCache.get(key, fetchDataFromAPI, ttlInSeconds);
 * }
 *
 * (async () => {
 *   const data = await getMasterData('your-cache-key', 300); // TTLを5分に設定
 *   console.log(data);
 * })();
 * ```
 *
 * @coreDocPath $core/30.utils/307.obCache
 */
export class ObjectCacheService<T = any> {
  private cache: Record<string, CacheItem<T>> = {}
  private pendingRequests: Record<string, Promise<T>> = {}

  /**
   * 指定されたキャッシュキーに対応するデータを取得します。キャッシュにデータが存在しないか、TTLが切れている場合は、fetchDataメソッドを使って新たなデータを取得し、キャッシュに格納します。
   *
   * @param key キャッシュキーを指定します。
   * @param fetchData キャッシュにデータが存在しないか、TTLが切れている場合に実行される関数です。データ取得処理を実装します。
   * @param ttlInSeconds キャッシュのTTL（秒）を指定します。省略した場合、キャッシュアイテムは期限切れになりません。
   */
  async get(key: string, fetchData: () => Promise<T>, ttlInSeconds?: number): Promise<T> {
    const cachedItem = this.cache[key]
    if (cachedItem && this.isNotExpired(cachedItem)) {
      return cachedItem.value
    }
    // 同一キーでまだfetchDataが進行中であれば、そのPromiseを待機する
    // fetchData進行中に次のgetが呼ばれてキャッシュから取得できず再度fetchDataが実行されてしまうことを回避する処置
    if (this.pendingRequests[key]) {
      return this.pendingRequests[key]
    }
    const requestPromise = fetchData()
    this.pendingRequests[key] = requestPromise
    const data = requestPromise.then((value) => {
      this.set(key, value, ttlInSeconds)
      return value
    }).catch((e) => {
      throw e
    }).finally(() => {
      if (this.pendingRequests[key]) {
        delete this.pendingRequests[key]
      }
    })
    return data
  }

  /**
   * 指定されたキャッシュキーとデータを格納します。オプションでTTL（秒）を指定できます。
   *
   * @param key キャッシュキーを指定します。
   * @param value キャッシュするデータを指定します。
   * @param ttlInSeconds キャッシュのTTL（秒）を指定します。省略した場合、キャッシュアイテムは期限切れになりません。
   */
  set(key: string, value: T, ttlInSeconds?: number): void {
    const expiry = ttlInSeconds ? Date.now() + ttlInSeconds * 1000 : undefined
    this.cache[key] = {
      value: value,
      expiry: expiry,
    }
  }

  /**
   * 指定されたキーの キャッシュを削除します
   * @param key
   */
  delete(key: string): void {
    delete this.cache[key]
  }

  /**
   * 正規表現でマッチするキーの キャッシュを一括削除します
   * @param keyRegExp
   */
  bulkDeleteWithKeyRegExp(keyRegExp: RegExp): void {
    for (const key of Object.keys(this.cache)) {
      if (keyRegExp.test(key)) {
        delete this.cache[key]
      }
    }
  }

  private isNotExpired(cacheItem: CacheItem<T>): boolean {
    return !cacheItem.expiry || Date.now() < cacheItem.expiry
  }

  static get instance(): ObjectCacheService {
    return singletonInstanceSummoner('ObjectCacheService', ObjectCacheService)
  }
}

export const $obCache = ObjectCacheService.instance
