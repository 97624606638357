<template>
  <div style="position: relative">
    <div class="d-flex align-items-center">
      <span class="column-label mr-2">{{ label }}</span>
      <b-form-select
        class="flex-1"
        v-model="operator"
        :modelValue="operator"
        @update:modelValue="updateOperator"
        :options="options"
        size="sm"
      ></b-form-select>
      <a role="button" @click="toggleSetting">
        <Ficon class="ml-2" type="ellipsis-h" />
      </a>
    </div>
    <div class="mt-1" v-if="operator !== 'isnull' && operator !== 'isnotnull'">
      <b-form-radio-group
        :modelValue="changedValue"
        :options="radioOptions"
        @update:modelValue="change"
      ></b-form-radio-group>
    </div>
    <FilterItemManager :visible="isVisibleSetting" @onDismiss="onDismissDropDown" />
  </div>
</template>
<script lang="ts">
import FilterItemManager from '../FilterItemManager.vue'
import { inject } from 'vue'
import { FilterItemService } from '../../../FilterItemService'
import { FILTER_OPERATOR_FOR_BOOLEAN } from '../../../FilterRuleService'

export default {
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    FilterItemManager,
  },
  setup() {
    return {
      filterItemService: inject<FilterItemService<any>>('filterItemService'),
    }
  },
  data() {
    return {
      operator: null,
      isVisibleSetting: false,
      radioOptions: [
        { text: 'TRUE', value: 1 },
        { text: 'FALSE', value: 0 },
      ],
    }
  },
  computed: {
    label() {
      return this.filterItemService.colDef.label || this.filterItemService.colDef.name
    },
    changedValue() {
      return this.modelValue ? (this.modelValue === 'true' ? 1 : 0) : null
    },
    options() {
      // { value: xxxx'', text: 'xxxxx' }のような形で返す
      return Object.keys(FILTER_OPERATOR_FOR_BOOLEAN).map((operator) => {
        return {
          value: operator,
          text: FILTER_OPERATOR_FOR_BOOLEAN[operator],
        }
      })
    },
  },
  created() {
    this.operator = this.options.length > 0 ? this.options[0].value : null
  },
  methods: {
    toggleSetting() {
      this.isVisibleSetting = !this.isVisibleSetting
    },
    change(value) {
      this.$nextTick(() => {
        this.$emit('update:modelValue', {
          value: value === 1 ? 'true' : 'false',
          operator: this.operator,
        })
      })
    },
    updateOperator(value) {
      this.operator = value
      this.$emit('update:operator', this.operator)
    },
    onDismissDropDown() {
      this.$emit('onDismissDropdown')
    },
  },
}
</script>
