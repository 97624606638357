import { ModelExtensionConfigBase } from '../../ModelExtensionModelsLoader/common'
import { StyleVariant } from '../../../types'

/**
 * RecordComments の テーブル名の接尾辞
 */
export const recordCommentExtensionModelNameSuffix = '__extension__record_comments'

/**
 * データ側のModel定義に付与される、RecordComments を O2M Relationship で参照する際のカラム名
 */
export const recordCommentOneToManyColName = 'ext__record_comments'
/**
 * RecordComments の テーブル 内で、元レコードの ID もしくは primaryKey 値を参照するカラム名 (M2O Relationship)
 */
export const recordCommentManyToOneRelationshipColName = 'target_record'
export const getExtensionCommentsModelNameByBaseModelName = (baseModelName: string): string => {
  return `${baseModelName}${recordCommentExtensionModelNameSuffix}`
}

export const recordCommentDisplayStyles = {
  default: {},
}

/**
 * RecordComments の Model定義に付与する 設定の型
 * ModelDef.extensions.recordComments へ設定する
 */
export type ModelExtensionRecordCommentsConfig = ModelExtensionConfigBase & {
  extensionType: 'recordComments'
  /**
   * true を指定すると レコードコメント機能が有効になる。
   */
  enabled?: boolean
  /**
   * true を指定すると レコードコメント関連Modelは生成されるが、コメント機能は表示されなくなる。 (default false)
   */
  hidden?: boolean
  /**
   * true に設定すると、スレッドでまとめる機能を有効化する (デフォルトでは無効)
   */
  useThread?: boolean
  /**
   * true に設定すると、元レコード => レコードコメント用のリレーションシップを生成しない (default false)
   */
  disableAddOneToManyRelationship?: boolean
  /**
   * 元レコード => レコードコメント用のリレーションシップを貼る際のカラム名を変更したい場合に指定する, Default は `ext__record_comments`
   */
  recordCommentOneToManyColName?: string | typeof recordCommentOneToManyColName
  /**
   * 表示スタイルの指定
   */
  displayType?: keyof typeof recordCommentDisplayStyles
  /**
   * レコードコメント拡張テーブルの名称を変更したい場合に指定する
   * Default は 自動生成で `${modelName}__extension__comments` となる
   */
  extensionModelName?: string
  /**
   * スレッドごとのステータスを定義する
   * Default: {resolved: { label: '解決済み', variant: 'success' }, unresolved: {label: '未解決', variant: 'danger'}}
   */
  threadStates?: {
    [key: string]: {
      label: string
      variant?: StyleVariant
      default?: boolean
    }
  }
}

/**
 * 設定のデフォルト値
 */
export const getModelExtensionRecordCommentsConfigDefault =
  (): Partial<ModelExtensionRecordCommentsConfig> => {
    return {
      extensionType: 'recordComments',
      enabled: false,
      hidden: false,
      disableAddOneToManyRelationship: false,
      useThread: false,
      displayType: 'default',
      threadStates: {
        unresolved: { label: '未解決', variant: 'danger', default: true },
        resolved: { label: '解決済み', variant: 'success' },
      },
    }
  }

/**
 * レコードコメントを送信する際の送信先の種類
 * Serverside の ComposableFunctions の 設定に関連
 */
export const recordCommentSenderTargets = {
  replied: {
    label: 'リプライされたユーザ・組織',
  },
  threadRelated: {
    label: 'スレッドに関連するユーザ・組織',
  },
  // all: {
  //   label: '全ユーザ・組織',
  // },
}

export type UserAndOrgIds = {
  userIds: string[]
  orgCodes: string[]
}

export type ThreadRelatedUserAndOrgIds = {
  [threadId: string]: UserAndOrgIds
}

export const recordCommentCoreNotificationCategory = 'コメント'
