<template>
  <label class="boolean-checkbox-input cursor-pointer hover-fade">
    <input
      v-bind="commonAttrs"
      type="checkbox"
      :checked="modelValue"
      :disabled="disabled"
      :value="true"
      @change="(event) => $emit('update:modelValue', !!event.target.checked)"
    />
    <slot />
  </label>
</template>
<script lang="ts">
import { PropType } from 'vue'

export default {
  props: {
    modelValue: { default: false },
    disabled: { default: false },
    commonAttrs: {
      type: Object as PropType<Record<string, unknown>>,
      required: false,
    },
  },
}
</script>
<style>
.boolean-checkbox-input {
  padding-top: .5em;
}
</style>

