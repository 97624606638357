<template>
  <div
    class="component-model-index-directus"
    :class="`${wrapperClass} component-model-index-directus-modelname-${modelName} component-model-index-directus-virtualModelName-${virtualModelName}`"
  >
    <div v-if="!initialized" class="p-4">
      <b-skeleton v-for="i in 20" :key="i" class="mt-2" height="40px" />
    </div>
    <div v-else-if="componentStructure.children[0].children.length">
      <ComposableComponentRenderer :builder-data="componentStructure" />
    </div>
  </div>
</template>

<script lang="ts">
import { VirtualModel } from '../../types'
import { ModelIndexDirectusProps } from './ModelIndexDirectus.config'
import { ComposableComponentData } from '../../plugins/ComposableComponentBuilder/ComposableComponentTypes'
import { generateComposableDataListDefaultComponentStructure } from '../../plugins/ComposableDataListComponents/front/generateComposableDataListDefaultComponentStructure'
import { ModelFactory } from '../../common/$models'
import { VirtualModelFactory } from '../../common/$virtualModels'

export default {
  name: 'ModelIndexDirectus',
  props: ModelIndexDirectusProps,
  async mounted() {
    await this.initOnce()
  },
  data() {
    return {
      initialized: false,
      isLoading: true,
      componentStructureChildren: [],
    }
  },
  computed: {
    componentStructure(): ComposableComponentData {
      return {
        key: '',
        children: [
          {
            id: 'ComposableDataList',
            component: 'ComposableDataList',
            configuredProps: {
              ...this.$props,
              fixedFilter: this.fixedFilter,
              defaultLimit: this.defaultLimit,
            },
            children: this.componentStructureChildren,
          },
        ],
      }
    },
    model(): ModelFactory {
      return $core.$models[this.modelName]
    },
    virtualModel(): VirtualModelFactory {
      return $core.$virtualModels[this.virtualModelName]
    },
    shouldEnableAddNewRecordButton(): boolean {
      return (
        this.enableAddNewRecordButton !== false &&
        this.model?.creatable !== false &&
        this.virtualModel?.creatable !== false
      )
    },
    // URLクエリフィルタ を変更して良いものとして捉えるかどうか のflag
    isUrlQueryFilterChangeable() {
      return !!this.urlQueryFilterChangeable
    },
    fixedFilter() {
      const virtualModel = this.virtualModel as VirtualModel
      let passedFilters = null
      if (this.filters && typeof this.filters === 'string') {
        try {
          passedFilters = this.filters ? $core.$utils.tryParseAsObject(this.filters) : null
        } catch (e) {
          console.error(e)
          console.warn(`Failed to parse filters ${this.filters}`)
        }
      } else if (typeof this.filters === 'object') {
        passedFilters = this.filters
      }
      return Object.assign(
        {},
        virtualModel && virtualModel.dataFilters ? virtualModel.dataFilters : {},
        passedFilters || {},
        this.isUrlQueryFilterChangeable ? {} : this.queryFilters || {},
      )
    },
  },
  methods: {
    async initOnce() {
      const componentStructureChildren = await generateComposableDataListDefaultComponentStructure(
        this,
        true,
        {
          itemClickFunction: this.itemClickEvent,
        },
      )
      this.componentStructureChildren = Object.freeze(componentStructureChildren)
      this.$nextTick(() => {
        this.initialized = true
      })
    },
  },
}
</script>
