<template>
  <div class="fallback-model-page fallback-model-page--edit-or-create fallback-model-page--create">
    <ModelCreatePage
      ref="ModelPage"
      :modelName="modelName"
      :virtualModelName="virtualModelName"
      :columns="virtualModelColumns"
      :key="hookName"
    />
  </div>
</template>

<script lang="ts">
import { checkModelFormHasChanged } from './checkModelFormHasChanged'

export default {
  computed: {
    virtualModelColumns() {
      return this.virtualModelName
        ? Object.keys($core.$virtualModels[this.virtualModelName]?.columns || {})
        : null
    },
    modelName() {
      return this.$route.params.fallback_model
    },
    virtualModelName() {
      return this.$route.query.virtualModel
    },
    hookName() {
      return `$CORE.admin.resolveComponent.model.${
        this.virtualModelName || this.modelName
      }.pages.new`
    },
  },
  async beforeRouteLeave(to, from, next) {
    checkModelFormHasChanged(this, next)
  },
}
</script>
