import { App, Directive } from 'vue'

const signleClickDirective: Directive = {
  beforeMount(el, binding) {
    const preventMs = Number(binding?.arg) || 1000
    let isClicked = false

    const originalHandler = binding.value
    // aタグの場合も おせっかいだが自動で preventDefaultを実行する
    const shouldPreventDefault = binding.modifiers?.prevent || el.tagName === 'A'
    const newHandler = (clickEvent: Event) => {
      if(shouldPreventDefault) {
        clickEvent.preventDefault()
      }
      if (!isClicked) {
        isClicked = true
        originalHandler(clickEvent)
        setTimeout(() => {
          isClicked = false
        }, preventMs)
      }
    }

    el.addEventListener('click', newHandler)
  },
}

/**
 * 多重クリックを避ける vue directive, @click の代わりに利用する
 * @example
 * <a v-single-click:300.prevent="() => moveToPaged(i)" href="#">ページ{{ i }}</a>
 * // 300ms の間隔を開けてのみ moveToPaged(i) が実行される
 * @param app
 */
export const installSingleClickDirective = (app: App) => {
  app.directive('single-click', signleClickDirective)
}