<template>
  <div
    class="input-array-of-object"
    v-if="initialized"
    ref="arrayOfObjectWrapper"
  >
    <div
      v-for="(row, rowIndex) in v || []"
      :key="row.tempKey"
      class="card mb-2 mt-1 input-array-of-object--item"
      :data-row="rowIndex"
    >
      <div class="card-body input-array-of-object--item--inner p-2" :class="wrapperClass">
        <div class="child-row-controls" v-if="enableControl">
          <div class="btn-group">
            <span class="btn text-success" :data-row="rowIndex" @click="addRow(1, rowIndex)">
              <ficon type="plus" :strokeWidth="4" class="mx-0" />
            </span>
            <span class="btn text-black" @click="moveUp(rowIndex)">
              <ficon type="arrow-up" :strokeWidth="3" class="mx-0" />
            </span>
            <span class="btn text-black" @click="moveDown(rowIndex)">
              <ficon type="arrow-down" :strokeWidth="3" class="mx-0" />
            </span>
            <span v-if="!isMinLength" class="btn text-danger" style="" @click="removeRow(rowIndex)">
              <ficon type="trash" :strokeWidth="4" class="mx-0" />
            </span>
          </div>
        </div>
        <oneRowOfArrayOfObjectInput
          :readOnlyMode="readOnlyMode || disableEdit"
          :ModelFormService="ModelFormService"
          :model-value="row"
          @update:one-row-array-of-object="change"
          :row="row"
          :col="col"
          :rowIndex="rowIndex as number"
          :recordRoot="recordRoot"
          :columnsForOneRow="columns"
          class="row"
          :modelName="modelName"
          :virtualModelName="virtualModelName"
        />
      </div>
    </div>
    <div v-if="enableControl" class="pt-2">
      <span
        v-if="!col.maxValueLength || rowLength < col.maxValueLength"
        class="btn btn-sm btn-outline-primary"
        @click="addRow(1)"
        >+ {{ col.label || col.name }} のデータを追加</span
      >
    </div>
  </div>
</template>

<script lang="ts">
// dependent on "../modelInput"
import { createNewRecordWithColumnDefs } from '../../../common/$models/createNewRecordWithColumnDefs'
import { ColumnDef, ColumnDefByColName } from '../../../common/$models/ModelDef'
import { PropType } from 'vue'

export default {
  props: {
    modelValue: { required: true },
    col: { required: true, type: Object as PropType<ColumnDef> },
    record: { required: true },
    commonAttrs: { required: false },
    recordRoot: {},
    readOnlyMode: { required: false, default: null },
    ModelFormService: {},
    modelName: {},
    virtualModelName: {},
    // @deprecated
    disableEdit: { type: Boolean, default: false },
    shouldDisabled: { type: Boolean, default: false },
  },
  data() {
    return {
      initialized: false,
      v: [],
      newRowValuesCached: null,
      error: {},
    }
  },
  watch: {
    modelValue: {
      handler(newValue) {
        // validate
        const isValid = Array.isArray(newValue)
        this.v = isValid ? newValue : []
      },
      immediate: true,
    },
  },
  computed: {
    columns(): ColumnDefByColName {
      if (this.col.type === 'RELATIONSHIP_ONE_TO_MANY') {
        const modelName = this.col.relationshipOneToMany?.collectionName
        return $core.$models[modelName].columns
      }
      return Object.keys(this.col.columns || {}).reduce((res, colName) => {
        return {
          ...res,
          [colName]: { ...this.col.columns[colName], name: colName },
        }
      }, {})
    },
    rowLength() {
      return this.v?.length || 0
    },
    minLength() {
      return this.col.minValueLength !== undefined ? this.col.minValueLength : 1
    },
    isMinLength() {
      return this.minLength >= this.rowLength
    },
    enableControl() {
      return (
        this.commonAttrs.enableControl !== false && this.readOnlyMode !== true && !this.disableEdit && !this.shouldDisabled
      )
    },
    wrapperClass() {
      return (
        $core.$utils.findParentVueComponentByComponentName(this, 'ModelForm')
          ?.modelFormStyleClass || ''
      )
    },
  },
  mounted() {
    const v = Array.isArray(this.modelValue) ? this.modelValue : []
    for (let value of v) {
      if (!value.tempKey) {
        value.tempKey = Math.random()
      }
    }
    this.v = v
    this.$nextTick(() => {
      const addRowNumbers = this.minLength - this.rowLength
      if (addRowNumbers > 0) {
        if (addRowNumbers) {
          this.addRow(addRowNumbers)
        }
      }
      this.initialized = true
    })
  },
  methods: {
    change(rowIndex, { value, error, modelInputVm }, subColumn, keyName) {
      const oldValue = this.v[rowIndex][keyName]
      this.v[rowIndex][keyName] = value
      // arrayObObjectのeditCallback暫定対応
      if (typeof subColumn.editCallback === 'function' && value !== oldValue) {
        // TODO: 右記じゃなくていいらしい... => this.v[rowIndex] = subColumn.editCallback({
        subColumn.editCallback({
          row: this.v[rowIndex],
          key: keyName,
          newValue: value,
          oldValue: oldValue,
          isNewRecord: this.isNewRecord,
          callerVueInstance: this,
        })
      }
      // this.setError(error, rowIndex, subColumn, keyName)
      this.$nextTick(() => {
        this._changeCallback()
      })
    },
    // TODO: Error 伝える
    // setError(error, rowIndex, subColumn, keyName) {
    //   console.log({ error, rowIndex, subColumn, keyName })
    //   debugger
    // },
    async addRow(rows = 1, insertPosition = null) {
      if (rows <= 0) {
        return // do nothing
      }
      let newRows = []
      const currentVal = this.v?.length && Array.isArray(this.v) ? [...this.v] : []
      if (insertPosition === null) {
        insertPosition = currentVal.length
      }
      if (!this.newRowValuesCached) {
        this.newRowValuesCached = await this.newRowValues()
      }
      for (let i = 0; i < rows; i++) {
        newRows.push(Object.assign({ tempKey: Math.random() }, this.newRowValuesCached))
      }
      currentVal.splice(insertPosition, 0, ...newRows)
      this.v = currentVal
      this.$nextTick(() => {
        this._changeCallback()
      })
    },
    async newRowValues() {
      return createNewRecordWithColumnDefs(this.columns)
    },
    removeRow(rowIndex) {
      const currentVal = [...this.v]
      currentVal.splice(rowIndex, 1)
      this.v = currentVal
      this._changeCallback()
    },
    _changeCallback() {
      this.$emit('update:value-and-error', { value: this.v })
    },
    moveUp(rowIndex) {
      if (rowIndex < 1) {
        return // do nothing
      }
      const arr = [...this.v]
      arr.splice(rowIndex - 1, 2, arr[rowIndex], arr[rowIndex - 1])
      this.v = arr
      this.scroll(rowIndex - 1, true)
      this.$nextTick(() => {
        this._changeCallback()
      })
    },
    moveDown(rowIndex) {
      if (rowIndex >= this.rowLength - 1) {
        return // do nothing
      }
      const arr = [...this.v]
      arr.splice(rowIndex, 2, arr[rowIndex + 1], arr[rowIndex])
      this.v = arr
      this.scroll(rowIndex + 1, false)
      this.$nextTick(() => {
        this._changeCallback()
      })
    },
    scroll(newRowIndex, isUp = true) {
      // $refs.arrayOfObjectWrapper を利用した selector に変更
      const element = this.$refs.arrayOfObjectWrapper.querySelector(`[data-row="${newRowIndex}"]`) as HTMLElement
      // 親要素 `.modal.show` もしくは window を取得
      const scrollElement = this.$refs.arrayOfObjectWrapper.closest('.modal.show') || window
      scrollElement.scrollBy({
        top: isUp ? -element.clientHeight : element.clientHeight,
        left: 0,
        behavior: 'smooth',
      })
    }
  },
}
</script>
