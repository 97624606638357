<template>
  <div class="input-array-of-object sortable-record-list" v-if="initialized">
    <div
      v-for="(row, rowIndex) in v"
      :key="row.tempKey"
      class="card mb-2 mt-1 input-array-of-object--item"
      :data-row="rowIndex"
    >
      <div class="card-body input-array-of-object--item-inner pt-2 pb-0" :class="wrapperClass">
        <div class="child-row-controls" v-if="enableControl">
          <div class="btn-group">
            <span class="btn text-success" :data-row="rowIndex" @click="addRow(1, rowIndex)">
              <ficon type="plus" :strokeWidth="4" class="mx-0"/>
            </span> <span class="btn text-black" @click="moveUp(rowIndex)">
            <ficon type="arrow-up" :strokeWidth="3" class="mx-0"/>
          </span> <span class="btn text-black" @click="moveDown(rowIndex)">
            <ficon type="arrow-down" :strokeWidth="3" class="mx-0"/>
          </span> <span v-if="!isMinLength" class="btn text-danger" style="" @click="removeRow(rowIndex)">
            <ficon type="trash" :strokeWidth="4" class="mx-0"/>
          </span>
          </div>
        </div>
        <ModelForm
          :modelName="relationModelName"
          :virtualModelName="relationVirtualModelName"
          :record="row"
          :ignoreColNames="ignoreColNames"
          :disableSubmitAction="true"
          :hide-footer="true"
          :readOnlyMode="readOnlyMode"
          @update="
              ({ data: dataChild, validationErrors: validationErrorsChild }) => {
                change(rowIndex, { value: dataChild, error: validationErrorsChild })
              }
            "
        />
      </div>
    </div>
    <div v-if="enableControl" class="pt-2">
      <span
        tabindex="0"
        v-if="!col.maxValueLength || rowLength < col.maxValueLength"
        class="btn btn-sm btn-outline-primary"
        @click.prevent="() => addRow(1)"
        @keydown.enter.prevent="() => addRow(1)"
      >+ {{ col.label || col.name }} のデータを追加</span
      >
    </div>
  </div>
</template>

<script lang="ts">
// dependent on "../modelInput"
import { ColumnDef, ColumnDefByColName, ModelDef } from '../../../common/$models/ModelDef'
import { PropType } from 'vue'
import { VirtualModelFactory } from '../../../common/$virtualModels'

/**
 * 1対多のリレーションを持つ入力コンポーネント
 * - リレーション先のモデルのレコードをリスト表示する
 * - リレーション先のモデルのレコードを追加、削除、移動 (並び替え) が可能
 */
export default {
  name: 'SortableRecordList',
  props: {
    modelValue: { required: true },
    col: { required: true, type: Object as PropType<ColumnDef> },
    record: { required: true },
    commonAttrs: { required: false },
    recordRoot: {},
    readOnlyMode: { required: false, default: null },
    ModelFormService: {},
    modelName: {},
    virtualModelName: {},
    disableEdit: { type: Boolean, default: false },
  },
  data() {
    return {
      initialized: false,
      v: [],
      error: {},
    }
  },
  watch: {
    modelValue: {
      handler() {
        this.v = [...(this.modelValue || [])]
      },
      immediate: true,
    },
  },
  computed: {
    columns(): ColumnDefByColName {
      const relationModel = this.col.relationshipOneToMany.collectionName
      const columns = $core.$models[relationModel].columns
      return Object.keys(columns || {}).reduce((res, colName) => {
        return {
          ...res,
          [colName]: {
            ...columns[colName],
            label: columns[colName].label || columns[colName].name,
          },
        }
      }, {})
    },
    orderableColumn() {
      return this.col.relationshipOneToMany.sortFieldName || null
    },
    /**
     * リレーション先のモデルのレコードの一覧表示時に 無視 (非表示に) するカラム名のリスト
     *
     * - 元 Model に対して リレーションしているフィールドは 表示しない
     * - orderableColumn は 表示しない
     */
    ignoreColNames() {
      return [
        this.col.relationshipOneToMany.fieldName,
        this.orderableColumn,
      ].filter((colName) => !!colName)
    },
    defaultSort() {
      if (this.orderableColumn) return [this.orderableColumn]
      return []
    },
    defaultValue() {
      const relationColumn = this.col.relationshipOneToMany.fieldName
      if (this.orderableColumn) {
        return {
          [relationColumn]: this.record.id,
          [this.orderableColumn]: this.rowLength + 1,
        }
      }
      return {
        [relationColumn]: this.record.id,
      }
    },
    rowLength() {
      return this.modelValue?.length || 0
    },
    minLength() {
      return this.col.minValueLength !== undefined ? this.col.minValueLength : 0
    },
    isMinLength() {
      return this.minLength >= this.rowLength
    },
    enableControl() {
      return (
        this.commonAttrs.enableControl !== false && this.readOnlyMode !== true && !this.disableEdit
      )
    },
    wrapperClass() {
      return (
        $core.$utils.findParentVueComponentByComponentName(this, 'ModelForm')
          ?.modelFormStyleClass || ''
      )
    },
    relationModelName(): string {
      return this.col.relationshipOneToMany.collectionName
    },
    relationVirtualModelName(): string {
      return this.col.relationshipOneToMany.virtualModelName
    },
    relationModel(): ModelDef {
      return $core.$models[this.relationModelName]
    },
    relationVirtualModel(): VirtualModelFactory {
      return $core.$virtualModels[this.relationVirtualModelName]
    },
    primaryKeyColNameOfRelationModel() {
      return this.relationModel.primaryKeyColName
    },
    modelValueContainRecordIds() {
      return this.modelValue?.length && Array.isArray(this.modelValue)
        ? this.modelValue.map((r) => {
          if (typeof r === 'object') {
            return r[this.primaryKeyColNameOfRelationModel]
          }
          return r
        })
        : []
    },
  },
  async mounted() {
    const records = await this.getRelationRecords()
    let v = records || this.modelValue || []
    // orderableColumnがある場合は、最初にソートする
    if (this.orderableColumn) {
      v.sort((a, b) => {
        return a[this.orderableColumn] - b[this.orderableColumn]
      })
      // そのうえで 振り直す
      this.reOrder(v)
      debugger
    }
    this.v = [...v]
    this.$nextTick(() => {
      const addRowNumbers = this.minLength - this.rowLength
      if (addRowNumbers > 0) {
        if (addRowNumbers) {
          this.addRow(addRowNumbers)
        }
      }
      this.initialized = true
    })
  },
  methods: {
    async getRelationRecords() {
      if (!this.modelValue?.length) {
        return []
      }
      const records = await this.relationModel.find({
        filter: {
          [this.primaryKeyColNameOfRelationModel]: {
            _in: this.modelValueContainRecordIds,
          },
        },
      })
      // orderableColumnがある場合は、orderableColumnの値でソートする
      if (this.orderableColumn) {
        records.sort((a, b) => {
          return a[this.orderableColumn] - b[this.orderableColumn]
        })
      }
      return records.length ? records : null
    },
    change(
      rowIndex,
      { value, error },
    ) {
      if (!this.v) {
        this.v = []
      }
      // TODO: Error に対する対応
      if (this.v) {
        this.v[rowIndex] = value
      } else {
        this.v = [value]
      }
      // TODO: Error に対する対応
      // console.log('error', error)
      this.$nextTick(() => {
        this._changeCallback()
      })
    },
    async addRow(rows = 1, insertPosition = null) {
      if (rows <= 0) {
        return // do nothing
      }
      let newRows = []
      const currentVal = this.v?.length && Array.isArray(this.v) ? [...this.v] : []
      if (insertPosition === null) {
        insertPosition = currentVal.length
      }
      const newRowValues = await this.newRowValues()
      for (let i = 0; i < rows; i++) {
        newRows.push(
          Object.assign({ tempKey: Math.random() }, newRowValues),
        )
      }
      currentVal.splice(insertPosition, 0, ...newRows)
      // orderableColumnがある場合は、orderableColumnの値を振り直す
      this.reOrder(currentVal)
      this.v = currentVal
      this.$nextTick(() => {
        this._changeCallback()
      })
    },
    async newRowValues() {
      const modelDefinedDefault = await ((this.relationVirtualModel || this.relationModel).createNew())
      return Object.assign({}, modelDefinedDefault || {}, this.defaultValue || {})
    },
    reOrder(currentVal) {
      if (this.orderableColumn) {
        const orderableColumn = this.orderableColumn
        currentVal.forEach((row, index) => {
          row[orderableColumn] = index + 1
          if (!row.tempKey) {
            row.tempKey = Math.random()
          }
        })
      }
    },
    removeRow(rowIndex) {
      const currentVal = [...this.v]
      currentVal.splice(rowIndex, 1)
      this.reOrder(currentVal)
      this.v = currentVal
      this._changeCallback()
    },
    _changeCallback() {
      // this.vでidがnullのものはidを消す
      const v = this.v.map(row => {
        if (!row[this.primaryKeyColNameOfRelationModel]) {
          delete row[this.primaryKeyColNameOfRelationModel]
        }
        return row
      })
      // TODO: emit error
      this.$emit('update:modelValue', { value: v })
    },
    moveUp(rowIndex) {
      if (rowIndex < 1) {
        return // do nothing
      }
      const arr = [...this.v]
      arr.splice(rowIndex - 1, 2, arr[rowIndex], arr[rowIndex - 1])
      // orderableColumnがある場合は、orderableColumnの値を入れ替える
      this.reOrder(arr)
      this.v = arr
      this.scroll(rowIndex - 1, true)
      this.$nextTick(() => {
        this._changeCallback()
      })
    },
    moveDown(rowIndex) {
      if (rowIndex >= this.rowLength - 1) {
        return // do nothing
      }
      const arr = [...this.v]
      arr.splice(rowIndex, 2, arr[rowIndex + 1], arr[rowIndex])
      // orderableColumnがある場合は、orderableColumnの値を入れ替える
      this.reOrder(arr)
      this.v = arr
      this.scroll(rowIndex + 1, false)
      this.$nextTick(() => {
        this._changeCallback()
      })
    },
    scroll(newRowIndex, isUp = true) {
      const scrollElement = this.getFrontModal()
      const element = document.querySelector(`[data-row="${newRowIndex}"]`) as HTMLElement
      scrollElement.scrollBy({
        top: isUp ? -element.clientHeight : element.clientHeight,
        left: 0,
        behavior: 'smooth',
      })
    },
    getFrontModal() {
      const modals = document.querySelectorAll('.modal.show')
      if (modals.length === 0) {
        return window
      }
      let max = null
      // @ts-ignore
      for (const modal of modals) {
        if (max === null) {
          max = modal
          continue
        }
        if (Number(max.parentElement.style.zIndex) < Number(modal.parentElement.style.zIndex)) {
          max = modal
        }
      }
      return max
    },
  },
}
</script>
