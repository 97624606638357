<template>
  <div class="radio-group-wrapper">
    <label
      v-for="(option, index) in options"
      :key="index"
      class="radio-item"
      :class="{ selected: option.value === modelValue }"
      role="div"
      :style="itemStyle"
    >
      <div
        class="img"
        :style="itemImageStyle"
      >
        <img v-if="option.image" :src="optionImage(option.image)" :alt="option.label" />
        <span v-else>Default</span>
      </div>
      <div>
        <div class="label">
          <input
            type="radio"
            :name="groupKey"
            :checked="option.value === modelValue"
            :value="option.value"
            @change="selectOption(option)"
          />{{ option.label }}
        </div>
        <div v-if="option.description" class="description">{{ option.description }}</div>
      </div>
    </label>
  </div>
</template>
<script lang="ts">
import { RichSelectOption } from './type'
/**
 * 画像・説明文・値・Labelを持つラジオボタン
 */
export default {
  name: 'RichRadioSelect',
  props: {
    modelValue: {
      type: [String, Number],
    },
    options: {
      type: Array as () => RichSelectOption[],
      required: true,
    },
    itemStyle: {
      type: Object,
      default: () => ({}),
    },
    itemImageStyle: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      groupKey: Math.random().toString(32),
    }
  },
  methods: {
    selectOption(option) {
      this.$emit('update:modelValue', option.value)
    },
    optionImage(imgPath) {
      // imgPathがプロトコルを含んでいたら
      if (imgPath.match(/^(http|https):\/\//)) {
        return imgPath
      } else {
        return $core.$imgix.minify(imgPath, { w: 150, h: 150 })
      }
    },
  },
}
</script>
