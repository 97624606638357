import { castValuesWithColumnType, ModelFactory } from '../../common/$models'
import { dateFormatter } from '../../common/utils'
import { $models } from '../../common/$models'
import { unflatten } from 'flat'
import { Knex } from 'knex'

export interface ImportType {
  key: string
  modelName: string
  virtualModelName: string
  fieldConvertDefinition: FieldConvertDefinition
  reformatExcelJson?: (excelRawRows: any[]) => any[]
}

interface FieldConvertDefinition {
  [keyName: string]: (originalRow, saveRecord) => any | string | false
}

const fixedExcludeFields = [
  'createdAt',
  'updatedAt',
  '作成日時',
  '更新日時',
  'objectID',
  undefined,
  null,
]

interface DataImportSettingRecord {
  name: string
  targetModelName: string
  desc: string
  pathThroughOriginalProps: boolean
  excludeFields: string[]
  useDateConversion: boolean
  dateConversionFormat: string
  useBeforeFormatFunction: boolean
  beforeFormatFunction: string
  useAfterFormatFunction: boolean
  afterFormatFunction: string
  useBeforeSaveFunction: boolean
  beforeSaveFunction: string
  useAfterSaveFunction: boolean
  afterSaveFunction: string
  beforeEachRowConvertFunction: string
  fields: {
    dataCol: string
    tCol: string
    behavior: string
    tFunc: string
    execPriority: number
  }[]
}

/**
 *
 */
export class ImportServiceWithDataImportSetting {
  public importTypes: { [key: string]: ImportType }
  public currentSaveTarget: { importType: ImportType | null; data: any[] }
  public dataImportSettingName: string
  public dataImportSettingRecord: DataImportSettingRecord
  public originalDataRows: any[]
  public formattedDataRows: any[]
  public options: {
    useUnflatten: boolean
  }
  public dataForFormattedDataFields: any
  public loadedModels: { [modelName: string]: any }
  public findFunction: any
  public transaction: Knex.Transaction | null
  public displayErrorMessages: string[] = []
  public displayWarningMessages: string[] = []

  constructor(
    dataImportSettingName,
    { useUnflatten = true } = {},
    dataImportSettingRecord: DataImportSettingRecord | any = null,
    $models = null,
  ) {
    this.importTypes = {}
    this.currentSaveTarget = { importType: null, data: [] }
    this.dataImportSettingName = dataImportSettingName
    if (dataImportSettingRecord) {
      this.dataImportSettingRecord = dataImportSettingRecord
    }
    this.options = { useUnflatten }
    this.dataForFormattedDataFields = {}
    this.findFunction = async (modelName, findParam) => {
      return globalThis.$core?.$models?.[modelName]?.find(findParam)
    }
  }

  async _initData(): Promise<void> {
    if (this.dataImportSettingRecord) {
      return
    }
    await this._setDataImportSettingRecord()
    if (!this.dataImportSettingRecord) {
      throw new Error(
        `[ImportServiceWithDataImportSetting] 設定名 "${this.dataImportSettingName}" のインポート設定が見つかりませんでした`,
      )
    }
    if (!this.targetModelDef) {
      throw new Error(
        `[ImportServiceWithDataImportSetting] this.modelName: "${this.modelName}" のモデル定義が見つかりませんでした`,
      )
    }
  }

  get modelName() {
    return this.dataImportSettingRecord ? this.dataImportSettingRecord.targetModelName : ''
  }

  get $models() {
    return this.loadedModels || $core.$models
  }

  get targetModelDef(): ModelFactory {
    return this.$models[this.modelName]
  }

  get colNames() {
    return this.targetModelDef?.colNames || []
  }

  get columns() {
    return this.targetModelDef?.columns || {}
  }

  /**
   * @param dataRows
   */
  async reformatDataWithImportSettings(dataRows: any[]) {
    // reset validation
    this.displayErrorMessages = []
    this.displayWarningMessages = []
    // 1. 設定を読み込んでおく
    await this._initData()
    // Convert flattered Nested object
    this.originalDataRows = this.options.useUnflatten ? dataRows.map(row => unflatten(row)) : dataRows
    this.dataForFormattedDataFields = {} // init
    // 2. 各行フォーマット
    // 2-1. useBeforeFormatFunction
    if (
      this.dataImportSettingRecord.useBeforeFormatFunction === true &&
      this.dataImportSettingRecord.beforeFormatFunction
    ) {
      try {
        this.originalDataRows = await $core.$utils.executeStringDefinedFunction({
          functionArgValues: {
            originalDataRows: this.originalDataRows,
            instance: this,
          },
          functionString: `${this.dataImportSettingRecord.beforeFormatFunction}; return originalDataRows`,
        })
      } catch (e) {
        $core.$errorReporter.r(e, this)
        console.error(`beforeFormatFunction の実行時にエラーが発生しました: ${e.message}`)
        $core.$toast.errorToast(`beforeFormatFunction の実行時にエラーが発生しました: ${e.message}`)
        throw e
      }
    }
    // 2-2. 各行 フォーマット
    const originalRowLength = this.originalDataRows.length
    const formattedRow: any[] = []
    let dataForFormattedDataFields = {}
    for (let i = 0; originalRowLength > i; i++) {
      const originalRow = this.originalDataRows[i]
      // パススルー取り込みの際に、Label行を排除するために設定している
      if (i === 0 && this.dataImportSettingRecord.pathThroughOriginalProps === true) {
        const rowKeys = Object.keys(originalRow)
        // label が2つ以上合致するなら
        const isLikeLabelRow =
          (this.columns[rowKeys[1]]?.label || '') === originalRow[rowKeys[1]] &&
          (this.columns[rowKeys[2]]?.label || '') === originalRow[rowKeys[2]]
        if (isLikeLabelRow) {
          continue
        }
      }
      const formatted = await this._convertRowToRecordWithFieldConvertDefinition(originalRow, i)
      formattedRow.push(formatted)
      // ヘッダ行をlabel付きで整形するためのオブジェクト
      dataForFormattedDataFields = Object.assign(dataForFormattedDataFields, formatted)
    }
    this.formattedDataRows = formattedRow
    this.dataForFormattedDataFields = dataForFormattedDataFields
    // 3. useAfterFormatFunction
    if (
      this.dataImportSettingRecord.useAfterFormatFunction === true &&
      this.dataImportSettingRecord.afterFormatFunction
    ) {
      try {
        this.formattedDataRows = await $core.$utils.executeStringDefinedFunction({
          functionArgValues: {
            formattedDataRows: this.formattedDataRows,
            instance: this,
            originalDataRows: this.originalDataRows,
          },
          functionString: `${this.dataImportSettingRecord.afterFormatFunction}; return formattedDataRows`,
          errorThrow: true,
        })
      } catch (e) {
        console.error(e)
        $core.$toast.errorToast(`afterFormatFunction の実行時にエラーが発生しました: ${e.message}`)
        throw e
      }
    }
    return this
  }

  /**
   * Original 1行をFormatted 1行へ変換
   * @param originalRow
   * @param index
   * @private
   */
  async _convertRowToRecordWithFieldConvertDefinition(
    originalRow: any,
    index: number,
  ): Promise<any> {
    let formattedRow = {} as any
    // id があれば付与
    if (originalRow.id) {
      formattedRow.id = originalRow.id
    }
    // パススルー取込
    if (this.dataImportSettingRecord.pathThroughOriginalProps) {
      formattedRow = this.colNames.reduce((res, colName) => {
        if (originalRow[colName] !== undefined) {
          res[colName] = originalRow[colName]
        }
        return res
      }, formattedRow)
    }
    // beforeRowConvertFunction
    if (this.dataImportSettingRecord.beforeEachRowConvertFunction) {
      try {
        formattedRow = await $core.$utils.executeStringDefinedFunction({
          functionString: `${this.dataImportSettingRecord.beforeEachRowConvertFunction}; return row`,
          functionArgValues: {
            row: formattedRow,
            sourceDataRow: originalRow,
            allSourceDataRows: this.originalDataRows,
            sourceDataRowIndex: index,
          },
        })
      } catch (e) {
        window.alert(
          `[ImportServiceWithDataImportSetting] beforeEachRowConvertFunction の実行に失敗しました。e.message: ${e.message}`,
        )
        throw e
      }
    }
    // import定義に従ってLoop
    for (let i = 0; this.dataImportSettingRecord.fields.length > i; i++) {
      const fieldConvDef = this.dataImportSettingRecord.fields[i]
      const targetModelColumnDef = this.columns[fieldConvDef.tCol]
      let value = this._valueFilter(originalRow[fieldConvDef.dataCol])
      // MULTISELECT or ARRAY_OB_OBJECT である場合には、 JSON.parse() をTryする
      if (
        targetModelColumnDef &&
        ['MULTISELECT', 'ARRAY_OB_OBJECT'].indexOf(targetModelColumnDef.type) >= 0 &&
        value
      ) {
        try {
          value = JSON.parse(value)
        } catch (e) {
          // try with comma sep
          if (
            targetModelColumnDef.type === 'MULTISELECT' &&
            typeof value === 'string' &&
            value.indexOf(',')
          ) {
            value = value.split(',')
          }
        }
      }
      if (fieldConvDef.behavior === 'func' && fieldConvDef.tFunc) {
        // A. functionパターン
        try {
          formattedRow = await $core.$utils.executeStringDefinedFunction({
            functionString: `${fieldConvDef.tFunc}; return row`,
            functionArgValues: {
              row: formattedRow,
              dataColName: fieldConvDef.tCol,
              value,
              sourceDataRow: originalRow,
              allSourceDataRows: this.originalDataRows,
              sourceDataRowIndex: index,
            },
          })
        } catch (e) {
          window.alert(
            `[ImportServiceWithDataImportSetting] tFunc の実行に失敗しました。e.message: ${
              e.message
            }, ${JSON.stringify({
              formattedRow,
              fieldConvDef,
              value,
            })}`,
          )
          throw e
        }
      } else if (fieldConvDef.behavior === 'passThrough') {
        // passThroughパターン
        formattedRow[fieldConvDef.dataCol] = value
      } else if (fieldConvDef.tCol) {
        // B. directパターン
        formattedRow[fieldConvDef.tCol] = value
      }
    }
    if (this.columns) {
      formattedRow = castValuesWithColumnType(formattedRow, this.columns)
    }
    // excludeFields
    fixedExcludeFields.map(exCol => {
      if (exCol) {
        delete formattedRow[exCol]
      }
    })
    if (
      this.dataImportSettingRecord.excludeFields &&
      this.dataImportSettingRecord.excludeFields.length
    ) {
      this.dataImportSettingRecord.excludeFields.map(exCol => {
        delete formattedRow[exCol]
      })
    }

    return formattedRow
  }

  /**
   * Date型を突っ込むと変になるので、Dateを YYYY-MM-DD へ変換 等
   * @private
   */
  _valueFilter(val) {
    if (val && typeof val.getMonth === 'function') {
      // Date型なので
      return dateFormatter('YYYY-MM-DD', val)
    }
    return val
  }

  async saveCurrentTargets() {
    if (!this.formattedDataRows || this.formattedDataRows.length === 0) {
      return
    }
    await this._executeBeforeSaveFunctionDefinedOnSettingIfEnabled()
    // 行数が多すぎるとErrorになるので、1000行ずつ保存する
    const rowsLength = this.formattedDataRows.length
    // @2024-08-06: configVarsにてsliceサイズを変更できるように変更
    const tmpSliceSize = $core.$configVars.get('dataImportSetting.sliceSize', 1000)
    const sliceSize = tmpSliceSize > 0 ? tmpSliceSize : 1000
    const loopCount = Math.ceil(rowsLength / sliceSize)
    for (let i = 0; loopCount > i; i++) {
      const start = i * sliceSize
      const end = Math.min(start + sliceSize, rowsLength)
      await this._execSave(this.formattedDataRows.slice(start, end))
    }
    console.log(`[ImportServiceWithDataImportSetting] Saved ${rowsLength} records.`)
    await this._executeAfterSaveFunctionDefinedOnSettingIfEnabled()
  }

  async _execSave(data) {
    await $core.$storeMethods.bulkUpsert({
      modelName: this.dataImportSettingRecord.targetModelName,
      data,
      quiet: true,
    })
  }

  /**
   * 保存処理前関数を実行する
   * @private
   */
  private async _executeBeforeSaveFunctionDefinedOnSettingIfEnabled() {
    if (
      this.dataImportSettingRecord.useBeforeSaveFunction === true &&
      this.dataImportSettingRecord.beforeSaveFunction
    ) {
      try {
        this.formattedDataRows = await $core.$utils.executeStringDefinedFunction({
          functionArgValues: { formattedDataRows: this.formattedDataRows },
          functionString: `${this.dataImportSettingRecord.beforeSaveFunction}; return formattedDataRows`,
        })
      } catch (e) {
        console.error(e)
        if (globalThis.alert) {
          globalThis.alert(
            `[ImportServiceWithDataImportSetting] beforeSaveFunction の実行に失敗しました。e.message: ${e.message}`,
          )
        }
        throw e
      }
    }
  }

  /**
   * 保存処理後関数を実行する
   * @private
   */
  private async _executeAfterSaveFunctionDefinedOnSettingIfEnabled() {
    if (
      this.dataImportSettingRecord.useAfterSaveFunction === true &&
      this.dataImportSettingRecord.afterSaveFunction
    ) {
      try {
        this.formattedDataRows = await $core.$utils.executeStringDefinedFunction({
          functionArgValues: { formattedDataRows: this.formattedDataRows },
          functionString: `${this.dataImportSettingRecord.afterSaveFunction}; return formattedDataRows`,
        })
      } catch (e) {
        console.error(e)
        if (globalThis.alert) {
          globalThis.alert(
            `[ImportServiceWithDataImportSetting] afterSaveFunction の実行に失敗しました。e.message: ${e.message}`,
          )
        }
        throw e
      }
    }
  }

  // TODO: パススルー取り込みにも対応する
  async _setDataImportSettingRecord() {
    // すでにsetされている場合は、fetchしない
    if (this.dataImportSettingRecord) {
      return
    }
    this.dataImportSettingRecord = (
      await this.findFunction('dataImportSettings', {
        filter: { name: { _eq: this.dataImportSettingName } },
      })
    )[0] as DataImportSettingRecord
  }

  /**
   * 表示用, データカラムのlabelで表示するために
   */
  get formattedDataFields(): { key: string; label?: string }[] {
    return Object.keys(this.dataForFormattedDataFields).map(colName => {
      const label = this.columns[colName]?.label
      return label ? { key: colName, label } : { key: colName }
    })
  }
}

export const valueFormatFixer = val => {
  if (val === undefined) {
    return ''
  }
  // ## 変換
  // - 全角英数 => 半角英数
  // - 半角カタカナ => 全角カタカナ
  // - 全角スペース => 半角スペース
  // - 全角ハイフン => 半角ハイフン
  // - 連続する半角スペース => 半角スペース
  return val.replace(/\s+/g, ' ')
}

const convertRowToRecordWithFieldConvertDefinition = async (
  row,
  fieldConvertDefinition: FieldConvertDefinition,
): Promise<{ [key: string]: any }> => {
  const fields = Object.keys(fieldConvertDefinition)
  let res: any = {}
  for (let i = 0; i < fields.length; i++) {
    if (res === false) {
      continue // filtered out
    }
    const key = fields[i]
    const conv = fieldConvertDefinition[key]
    const val = row[key]
    if (conv) {
      if (typeof conv === 'string') {
        res[conv] = valueFormatFixer(val)
      } else if (typeof conv === 'function') {
        res = await conv(row, res)
      }
    }
  }
  return res
}

//
// const importValidation = async (originalDataRows, instance) => {
//   // Validation 実施
//   const emails = originalDataRows.map(r => r['メールアドレス']).filter(d => !!d)
//   const 社員番号s = originalDataRows.map(r => r['社員番号']).filter(d => !!d)
//   if (!emails.length) {
//     instance.displayErrorMessages.push('メールアドレスの入力されている行が0件です。')
//     return []
//   }
//   console.log(emails, 社員番号s)
//   debugger
//   // 整形
//   const companies = await $core.$models.organizations.find({limit: -1})
//   const companyByName = companies.reduce((c, res) => ({...res, [c.name]: c}))
//   const orQuery = {
//     _or: [
//       { email: {_in: emails} },
//     ]
//   }
//   if (社員番号s.length) {
//     orQuery._or.push({ user_number: {_in: 社員番号s} })
//   }
//   // 重複可能性のある Users
//   const possibleUsers = await $core.$models.directus_users.find({
//     filter: orQuery,
//     limit: -1,
//   })
//   const usersByEmail = possibleUsers.reduce((c, res) => ({...res, [c.email]: c}))
//   // 企業コードの正規化
//   originalDataRows = originalDataRows.map(row => {
//     const comName = row['会社名']
//     const companyExactMatched = Object.keys(companyByName).find(c => c === comName)
//     const company = companyExactMatched || Object.keys(companyByName).find(c => c.indexOf(comName) >= 0)
//     if (company?.code) {
//       row.company = company?.code
//     }
//     return row
//   })
//   // 企業・社員番号が重複していて、メールアドレスが合致しない場合のみ, 除外する
//   originalDataRows = originalDataRows.map(row => {
//     const userDupButAnotherEmail = possibleUsers.find(u => {
//       return (
//         row.company && u.company == row.company &&
//         u.user_number == row['社員番号'] &&
//         u.email !== row['メールアドレス']
//       )
//     })
//     if(userDupButAnotherEmail) {
//       instance.displayErrorMessages.push(`異なるメールアドレスでの 会社名,社員番号 の重複がありましたので、取込対象から除外されました。(${row['会社名']}, ${row['社員番号']}, ${row['メールアドレス']})`)
//       return null
//     }
//     // id 付与
//     const user = usersByEmail[row['メールアドレス']]
//     if (user) {
//       row.id = user.id
//     }
//     return row
//   }).filter(d => !!d)
//   return originalDataRows
//
// }
