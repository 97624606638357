import { ModelFactory } from '../../common/$models'
import { VirtualModelFactory } from '../../common/$virtualModels'
import { ColumnDefByColName } from '../../common/$models/ModelDef'

/**
 * modelName, virtualModelName の指定を持つ Class base
 * model定義, virtualModel定義への参照などを持つ
 */
export class ModelNameSpecifiedClassBase {
  protected _modelName: string
  protected _virtualModelName: string

  set modelName(modelName: string) {
    this._modelName = modelName
  }

  get modelName() {
    return $core.$models[this._modelName] ? this._modelName : null
  }

  get model(): ModelFactory | undefined {
    return $core.$models[this.modelName]
  }

  set virtualModelName(virtualModelName: string) {
    if (!virtualModelName) {
      this._virtualModelName = null
      return // do nothing
    }
    const modelName = $core.$virtualModels[virtualModelName]?.baseModel || null
    if (!modelName) {
      console.error(
        `virtualModelName: ${virtualModelName} に対応する baseModel が見つかりませんでした`,
      )
      this._virtualModelName = null
      return
    }
    this._modelName = modelName
    this._virtualModelName = virtualModelName
  }

  get virtualModelName() {
    return $core.$virtualModels[this._virtualModelName] ? this._virtualModelName : null
  }

  get virtualModel(): VirtualModelFactory | undefined {
    return $core.$virtualModels[this._virtualModelName]
  }
  getModelProp(propName: string) {
    return this.virtualModel?.[propName] || this.model?.[propName]
  }
  get displayModelName(): string {
    return this.getModelProp('tableLabel') || this.modelName
  }

  get columns(): ColumnDefByColName {
    return this.virtualModel?.columnsMergedWithBaseModel || this.model?.columns
  }
  getPermission(propName: 'creatable' | 'updatable' | 'deletable' | 'bulkControllable'): boolean {
    if (this.virtualModel?.[propName] === false) {
      return false
    }
    return this.model[propName] !== false
  }
  get creatable(): boolean {
    return this.getPermission('creatable')
  }
  get updatable(): boolean {
    return this.getPermission('updatable')
  }
  get deletable(): boolean {
    return this.getPermission('deletable')
  }
}
