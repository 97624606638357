<template>
  <div id="container-scroller" class="container-scroller">
    <app-hookable-component v-if="initialized" resolve-hook-name="$CORE.component.layoutDefault.prepend"/>
    <app-hookable-component v-if="initialized" :resolve-hook-name="wrapperResolveHookName">
      <app-header
        v-if="enableHeader"
        class="d-print-none app-header"
        :class="{
          'shrink-sidebar': !sidebarDisplayStatus,
          'sidebar-disabled': !shouldSidebarEnabled,
          'sidebar-enabled': shouldSidebarEnabled,
        }"
        :sidebarEnabled="shouldSidebarEnabled"
      />
      <div v-if="initialized" class="page-body-wrapper" :class="shouldSidebarEnabled ? 'container-fluid page-body-wrapper--sidebar-enabled' : 'page-body-wrapper--sidebar-disabled'">
        <component
          :is="sidebarComponent"
          :initialSidebarDisplayStatus="sidebarDisplayStatus"
          class="d-print-none"
          :class="shouldSidebarEnabled ? '' : 'd-block d-lg-none'"
          @onToggleSidebar="toggleSidebar"
        />
        <div
          class="navbar-sidebar-active-dark-overlay"
          v-single-click="() => ($core.$uiState.sidebarActive = false)"
        ></div>
        <div
          id="main-content"
          class="main-panel position-relative"
          :class="[
            shouldSidebarEnabled ? 'main-content--sidebar-enabled' : 'main-content--sidebar-disabled',
            sidebarDisplayStatus ? '' : 'grow',
          ]"
        >
          <div :class="!noContentWrapper ? 'content-wrapper' : ''">
            <router-view></router-view>
          </div>
        </div>
      </div>
    </app-hookable-component>
    <div v-else style="position: fixed; width: 100%; height: 100%">
      <div style="position: fixed; top: 48%; width: 100%; text-align: center">
        <loading> ロード中...</loading>
      </div>
    </div>
    <OfflineDetectMessage />
    <ModalContainer />
    <ControlDisplayOfAppHookDetail v-if="shouldEnableAppHookControlDisplay" class="core-admin-role-only" />
    <app-hookable-component v-if="initialized" resolve-hook-name="$CORE.component.layoutDefault.append"/>
  </div>
</template>

<script lang="ts">
import Sidebar from './partials/AppSidebar.vue'
import AdminSidebar from './partials/AdminSidebar.vue'
import AppHeader from './partials/AppHeader.vue'
import ModalContainer from '../Modal/ModalContainer.vue'
import { authMixinRequireAuth } from '../auth/authVueMixins'
import { frontAppHookEmitFunctions } from '../frontAppHooks'
import OfflineDetectMessage from '../OfflineDetectMessage.vue'

export default {
  props: {
    enableHeader: { default: true },
    enableSidebar: { default: true },
    noContentWrapper: { type: Boolean, default: false },
    initialSidebarDisplayStatus: { type: Boolean, default: true },
  },
  mixins: [authMixinRequireAuth],
  components: {
    AppHeader,
    ModalContainer,
    OfflineDetectMessage,
  },
  data() {
    return {
      initialized: false,
      sidebarDisplayStatus: this.initialSidebarDisplayStatus,
    }
  },
  methods: {
    async init() {
      await frontAppHookEmitFunctions.beforeInit(this)
      $core.$uiState.__initLoadedResolveFunc()
      this.initialized = true
      this.$nextTick(() => {
        frontAppHookEmitFunctions.afterInit(this)
      })
    },
    toggleSidebar(sidebarDisplayStatus) {
      this.sidebarDisplayStatus = sidebarDisplayStatus
    },
  },
  computed: {
    sidebarComponent() {
      return $core.$appDefinitionLoader.appDefinition?.key ? Sidebar : AdminSidebar
    },
    shouldSidebarEnabled() {
      if (!this.enableSidebar) {
        return false
      }
      if ($core.$appDefinitionLoader.isAdminMode) {
        // Force layout
        return true
      }
      if (!$core.$configVars.get('layout.sidebarEnabled', true)) {
        return false
      }
      return true
    },
    wrapperResolveHookName() {
      return $core.$appDefinitionLoader.appDefinition?.key ? '$CORE.component.layoutDefault' : '$CORE.component.layoutDefaultAdmin'
    },
    shouldEnableAppHookControlDisplay() {
      return $core.$configVars.get('enableAppHookControlDisplay', !!$core?.$embAuth?.user?.isAdmin)
    },
  },
}
</script>
