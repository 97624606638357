import { $appHook } from '../common/$appHook'

export const frontAppHooks = {
  beforeInit: 'CORE.frontApp.beforeInit',
  afterInit: 'CORE.frontApp.afterInit',
  modelDefinitions: {
    beforeInitOnModelDefinitionLoaded: modelName =>
      `CORE.modelDefinitions.beforeInitOnModelDefinitionLoaded.${modelName}`,
    loaded: 'CORE.modelDefinitions.loaded',
  },
  appDefinitionLoaderService: {
    redirectToRightAppOrErrorPageWhenNoPrivilege: 'appDefinitionLoaderService.redirectToRightAppOrErrorPageWhenNoPrivilege',
  }
}

const _emitHook = (hookName: string, args: any) => $appHook.emit(hookName, args)

export const frontAppHookEmitFunctions = {
  beforeInit: async args => _emitHook(frontAppHooks.beforeInit, args),
  afterInit: async args => _emitHook(frontAppHooks.afterInit, args),
}
