import { $appHook } from '../../$appHook'
import { ModelDef } from '../../$models/ModelDef'
import { ModelFactory } from '../../$models'
import {
  extensionTypeOfModelExtensionCachedFindable,
  installModelExtensionCachedFindable,
} from './ModelExtensionCachedFindable'
import { ModelExtension } from '../../../plugins/ModelExtensionModelsLoader/common'

const installFunctionByExtensionType: {
  [key: string]: (model: ModelFactory, ex: ModelExtension) => void
} = {
  // @ts-ignore
  [extensionTypeOfModelExtensionCachedFindable]: (model, ex) => installModelExtensionCachedFindable(model, ex),
}

const installModelExtensions = ({
  modelDefinition,
  instance
}: {
  modelDefinition: ModelDef
  instance: ModelFactory
}) => {
  instance.extensions?.forEach((ex) => {
    if (!ex.enabled) return
    const installFunction = installFunctionByExtensionType[ex.extensionType]
    if (installFunction) {
      try {
        installFunction(instance, ex)
      } catch (e) {
        console.warn(`Failed to install ModelExtension "${ex.extensionType}" for Model "${instance.tableName}"`, e)
      }
    }
  })
  // return void
}

/**
 * ModelFactory の 初期化直後に ModelExtensions を読み込む挙動を設定
 */
export const prepareModelExtensionsInstallBehavior = () => {
  $appHook.on('$CORE.ModelFactory.init.after', installModelExtensions)
}
