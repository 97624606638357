<template>
  <i class="d-inline-block ficon" :class="iconClass"></i>
</template>
<script lang="ts">
import { allIcons, IconTypes } from './allIcons'
import { PropType } from 'vue'

export default {
  props: {
    type: {
      type: String as PropType<IconTypes>,
      default: 'default',
    },
  },
  computed: {
    iconClass() {
      return allIcons[this.type]
    },
  },
}
</script>
