import { defineAsyncComponent } from 'vue'

export const coreUserNotificationsRouterPath = '/notifications'

/**
 * ユーザ通知を利用
 */
export const useUserNotificationsFeature = async () => {
  $core.$userNotificationsService.enable()
  $core.$router.addRoute({
    path: coreUserNotificationsRouterPath,
    component: defineAsyncComponent(
      () =>
        //@ts-ignore
        import('./UserNotificationListPage.vue'),
    ),
  })
}
