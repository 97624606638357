<template>
  <FilterResultDisplayContainer
    :modelValue="modelValue"
    :collection-name="collectionName"
    :col="col"
    :record="record"
    :addFilterButtonText="addFilterButtonText"
    :emitValueAsObject="emitValueAsObject"
    :columns="columns"
    @update:modelValue="change"
  />
</template>
<script lang="ts">
import FilterResultDisplayContainer from '../../../plugins/ComposableDataListComponents/front/components/FilterResultDisplayContainer.vue'
import { ColumnDef } from '../../../common/$models/ModelDef'

export default {
  components: {
    FilterResultDisplayContainer,
  },
  props: {
    modelValue: {
      type: String,
      required: false,
    },
    collectionName: {
      type: String,
      required: false,
    },
    col: {
      type: Object as () => ColumnDef,
      required: false,
    },
    record: {
      type: Object,
      required: false,
    },
    addFilterButtonText: {
      type: String,
      default: 'フィルターを追加',
    },
    emitValueAsObject: {
      type: Boolean,
      default: false,
    },
    recordRoot: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      columns: {},
    }
  },
  watch: {
    'recordRoot.columns': {
      handler(newvalue) {
        this.updateColumns(newvalue)
      },
      immediate: true,
      deep: true,
    },
  },
  created() {
    this.updateColumns()
  },
  methods: {
    updateColumns(defaultColumns = null) {
      // TODO: ここで `columns` を取得する
      const columns = defaultColumns || this.recordRoot.columns
      if (columns.length > 0) {
        this.columns = columns.reduce((acc: any, column: any) => {
          acc[column.name || column.key] = column
          return acc
        }, {})
      }
    },
    change(value) {
      this.$emit('update:modelValue', value)
    },
  },
}
</script>
