<template>
  <div class="model-form-grouped-edit-loop-wrapper">
    <ModelFormGroupedEditLoopItem
      v-for="(oneRecordService, index) in CCModelFormServiceInstance.oneRecordServices"
      :key="index"
    >
      <!--      <slot> </slot>-->
      <ModelFormOneRecord :oneRecordService="oneRecordService">
        <slot />
      </ModelFormOneRecord>
    </ModelFormGroupedEditLoopItem>
  </div>
</template>
<script lang="ts">
import ModelFormGroupedEditLoopItem from './ModelFormGroupedEditLoopItem.vue'
import { CCModelFormService } from '../CCModelFormService'
import { inject } from 'vue'
import ModelFormOneRecord from '../../../../plugins/ComposableModelForm/front/components/ModelFormOneRecord.vue'
import { registerComposableComponentSettings } from '../../../ComposableComponents'
const name = 'ModelFormGroupedEditLoopWrapper'
registerComposableComponentSettings(name, {
  label: 'CCModelForm',
  hasDefaultSlot: true,
  category: 'フォーム',
  configColumns: {},
})
export default {
  components: {
    ModelFormOneRecord,
    ModelFormGroupedEditLoopItem,
  },
  setup() {
    return {
      CCModelFormServiceInstance: inject('CCModelFormServiceInstance') as CCModelFormService,
    }
  },
}
</script>
