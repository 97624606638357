import { $appHook } from '../../common/$appHook'
import { ModelUser_roles } from './user_roles'
import { frontAppHooks } from '../frontAppHooks'
import { registerFunctionLoggedInExecuteOnce } from '../../common/utils'

export const columnPermissionSelections = {
  ineditable: '編集不可',
  ifowner: '作成者なら編集可',
  invisible: '読取不可',
  function: '関数制御',
}

/**
 * ログイン時に、自身のrole (directus_users.coreRoles フィールドに保存されている値) に基づいて、
 * アプリケーションで設定された権限 (user_roles) を取得し、
 * Model定義 ($core.$models.modelName) にマージすることで、編集/参照 の 可/不可 をコントロールする
 */
class UserRoleManager {
  hasModelDefinitionsLoaded: Promise<void>
  _modelDefLoaded: Function

  constructor() {
    registerFunctionLoggedInExecuteOnce({
      appHookFunctionRegisterKey: 'UserRoleManager',
      onceLoadFunction: (userData) => this._setRolesAndPermissionsOnSignedIn(userData),
      useAwaitOnLoad: true,
    })

    this.hasModelDefinitionsLoaded = new Promise((resolve) => {
      this._modelDefLoaded = () => {
        resolve()
      }
    })
    $appHook.on(
      frontAppHooks.modelDefinitions.loaded,
      () => {
        // resolve promise
        this._modelDefLoaded()
      },
      'mergeLoadedUserRolesIntoCurrentModelDefinitions',
    )
  }

  // ログインしたら、自分のrolesに基づいて、modelsの権限をセットする
  // ここでは、ログインしたら実行する関数を登録している (のみ)
  async _setRolesAndPermissionsOnSignedIn(args) {
    const coreRoles = args?.coreRoles
    if (!coreRoles) {
      return args
    }
    // adminであればスキップ (全権限あり)
    if ($core.$embAuth.user?.isAdmin === true) {
      return args
    }
    // TODO: (要修正) 本来は、rolesがなくてadminでもない場合は全禁止すべきなのだが...
    if (coreRoles.length === 0) {
      return args
    }
    // 付与されているrolesをロード
    // user.rolesに基づいて, `user_roles` をロードする
    const userRoles = (await $core.$models.user_roles.find({
      filter: { key: { _in: coreRoles } },
    })) as ModelUser_roles[]
    // userRoles を $core.$models にマージする (権限関連)
    try {
      this.hasModelDefinitionsLoaded.then(async () => {
        await mergeLoadedUserRolesIntoCurrentModelDefinitions(userRoles)
      })
    } catch (e) {
      console.error(e)
    }
    return args
  }
}

/**
 * 設定された ModelUser_roles
 * @param userRoles
 */
const mergeLoadedUserRolesIntoCurrentModelDefinitions = async (userRoles: ModelUser_roles[]) => {
  for (let i = 0; userRoles.length > i; i++) {
    const userRole = userRoles[i]
    for (const modelPermission of userRole.permissions) {
      if (!$core.$models[modelPermission.modelName]) {
        continue
      }
      const model = $core.$models[modelPermission.modelName]
      model.creatable = !!modelPermission.creatable
      model.updatable = !!modelPermission.updatable
      model.deletable = !!modelPermission.deletable
      // console.log({model})
      console.log(`modelPermission.columnDefinitions`, modelPermission.columnDefinitions)
      // columnDefinitions に特に定義がされていない
      if (!modelPermission.columnDefinitions) {
        continue
      }
      // カラム毎の挙動を $models へ上書き
      for (const colPermission of modelPermission.columnDefinitions) {
        // console.log({colPermission})
        const colDef = model.columns[colPermission.colName]
        if (!colDef) {
          console.log(
            `Skipping ${modelPermission.modelName}.${colPermission.colName} cos not found.`,
          )
          continue
        }
        if (colPermission.permission === 'ineditable') {
          colDef.editable = false
        } else if (colPermission.permission === 'invisible') {
          colDef.visible = false
        } else if (
          colPermission.permission === 'ifowner' ||
          colPermission.permission === 'function'
        ) {
          // 関数制御 or 作成者なら編集可能パターン
          if (colPermission.permission === 'function' && !colPermission.editableFunction) {
            const msg = `[columnPermissionSelections] colPermission.editableFunction が設定されていません at ${modelPermission.modelName}.${colPermission.colName}`
            console.warn(msg)
            continue
          }
          /**
           * Editableを関数で制御
           * TODO: Function の取り扱いが意外とめんどくさいので、Helper化
           */
          const definedFunc = colDef.editable
          const body = `function( row, currentVueInstance ){ ${colPermission.editableFunction} }`
          const wrap = (s) => '{ return ' + body + ' };' //return the block having function expression
          const textDefinedFunction = new Function(wrap(body))
          const editableFunction = (row, currentVueInstance) => {
            if (colPermission.permission === 'ifowner') {
              // 作成者のみパターン
              return !row.id || row.userCreated === $core.$embAuth.user.id
            } else {
              return textDefinedFunction.call(null).call(null, row, currentVueInstance)
            }
          }
          colDef.editable = (row, currentVueInstance) => {
            /**
             * 既存で定義されている editable() 関数を判定, falseなら編集不可とする
             */
            if (definedFunc && typeof definedFunc === 'function') {
              const baseJudge = definedFunc(row, currentVueInstance)
              if (baseJudge === false) {
                return false
              }
            }
            return editableFunction(row, currentVueInstance)
          }
          console.log(`colPermission.editableFunction: `, colPermission.editableFunction)
        }
      }
    }

    // editableConditionとdeletableConditionのappHookを設定
    // registerRecordStateBasedEditAndDeletePermission(userRole)
  }
}

export const $userRoleManager = () => new UserRoleManager()
