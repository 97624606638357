<template>
  <DefaultLayout :enable-header="false" :enable-sidebar="false" />
</template>
<script lang="ts">
import DefaultLayout from './DefaultLayout.vue'

export default {
  components: {
    DefaultLayout,
  },
}
</script>