import { ModelDef } from '../../common/$models/ModelDef'

/**
 * 多様なコンポーネント定義を保存しておくための
 */
export const customComponentDefinitions: ModelDef = {
  tableName: 'customComponentDefinitions',
  tableLabel: 'カスタムコンポーネント定義',
  primaryKeyColType: 'UUID',
  modelType: 'admin',
  columns: {
    name: {
      type: 'STRING',
      label: '設定名',
    },
    type: {
      type: 'STRING',
    },
    definition: {
      type: 'ARRAY_OF_OBJECT',
      visible: false,
      defaultValue: () => new Object(),
    },
    metaData: {
      type: 'ARRAY_OF_OBJECT',
      visible: false,
    },
    metaDataAlt: {
      type: 'ARRAY_OF_OBJECT',
      visible: false,
    },
    metaString: {
      type: 'TEXT',
    },
    note: {
      type: 'TEXT',
    },
  },
}
