<template>
  <div class="flex-fill" style="position: relative">
    <div class="d-flex align-items-center">
      <b-form-select
        style="width: 120px"
        class="mr-1"
        :modelValue="operator"
        @update:modelValue="updateOperator"
        :options="options"
        size="sm"
      ></b-form-select>
      <multiselect
        v-if="operator !== 'isnull' && operator !== 'isnotnull' && selections.length > 20"
        :allow-empty="true"
        :show-labels="true"
        select-label="選択"
        placeholder="選択"
        :model-value="modelValue"
        :internal-search="false"
        :options="selections"
        :multiple="true"
        @open="searchChange"
        @update:modelValue="change"
        size="sm"
        @search-change="searchChange"
      >
        <template v-slot:noOptions> --- </template>
        <template v-slot:noResult> --- </template>
      </multiselect>
      <b-form-checkbox-group
        v-if="operator !== 'isnull' && operator !== 'isnotnull' && selections.length <= 20"
        :model-value="modelValue"
        @update:modelValue="change"
      >
        <b-form-checkbox v-for="option in selections" :key="option" :value="option">
          {{ option }}
        </b-form-checkbox>
      </b-form-checkbox-group>
      <a v-if="!disableSetting" class="ml-auto" role="button" @click="toggleSetting">
        <Ficon class="ml-2" type="ellipsis-h" />
      </a>
    </div>
    <FilterItemManagerForCustom
      @onDismiss="() => (isVisibleSetting = false)"
      :visible="isVisibleSetting"
    />
  </div>
</template>
<script lang="ts">
import { inject } from 'vue'
import { FilterItemService } from '../../../FilterItemService'
import { FILTER_OPERATOR_FOR_SELECT } from '../../../FilterRuleService'
import FilterItemManagerForCustom from '../FilterItemManagerForCustom.vue'

export default {
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    disableSetting: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    FilterItemManagerForCustom,
  },
  setup() {
    return {
      filterObject: inject('filterObject'),
    }
  },
  data() {
    return {
      operator: null,
      isVisibleSetting: false,
      selections: [],
    }
  },
  computed: {
    label() {
      return this.filterObject.colDef.label || this.filterObject.colDef.name
    },
    options() {
      // { value: xxxx'', text: 'xxxxx' }のような形で返す
      return Object.keys(FILTER_OPERATOR_FOR_SELECT).map((operator) => {
        return {
          value: operator,
          text: FILTER_OPERATOR_FOR_SELECT[operator],
        }
      })
    },
  },
  created() {
    this.operator = this.options.length > 0 ? this.options[0].value : null
    this.setSelections()
  },
  methods: {
    toggleSetting() {
      this.isVisibleSetting = !this.isVisibleSetting
    },
    change(value) {
      this.$emit('update:modelValue', {
        value,
        operator: this.operator,
      })
    },
    searchChange(query) {
      let list = this.multiSelectOptions

      if (query) {
        query = query.replaceAll('　', ' ').split(' ')
        for (const searchText of query) {
          const tarText = searchText.toLowerCase()
          list = list.filter((v) => `${v.label}`.toLowerCase().includes(tarText))
        }
      }
      this.multiSelectOptions = list
    },
    async setSelections() {
      const list = await this.filterObject.getSelections()
      if (list) {
        this.selections = list
      }
    },
    updateOperator(value) {
      this.operator = value
      this.$emit('update:operator', this.operator)
    },
  },
}
</script>
