export const allIcons = Object.freeze({
  'accessible-icon': 'lab la-accessible-icon',
  'american-sign-language-interpreting': 'las la-american-sign-language-interpreting',
  'assistive-listening-systems': 'las la-assistive-listening-systems',
  'audio-description': 'las la-audio-description',
  blind: 'las la-blind',
  braille: 'las la-braille',
  'closed-captioning': 'las la-closed-captioning',
  deaf: 'las la-deaf',
  'low-vision': 'las la-low-vision',
  'phone-volume': 'las la-phone-volume',
  'question-circle': 'las la-question-circle',
  'sign-language': 'las la-sign-language',
  tty: 'las la-tty',
  'universal-access': 'las la-universal-access',
  wheelchair: 'las la-wheelchair',
  bell: 'las la-bell',
  'bell-slash': 'las la-bell-slash',
  exclamation: 'las la-exclamation',
  'exclamation-circle': 'las la-exclamation-circle',
  'exclamation-triangle': 'las la-exclamation-triangle',
  radiation: 'las la-radiation',
  'radiation-alt': 'las la-radiation-alt',
  'skull-crossbones': 'las la-skull-crossbones',
  cat: 'las la-cat',
  crow: 'las la-crow',
  dog: 'las la-dog',
  dove: 'las la-dove',
  dragon: 'las la-dragon',
  feather: 'las la-feather',
  'feather-alt': 'las la-feather-alt',
  fish: 'las la-fish',
  frog: 'las la-frog',
  hippo: 'las la-hippo',
  horse: 'las la-horse',
  'horse-head': 'las la-horse-head',
  'kiwi-bird': 'las la-kiwi-bird',
  otter: 'las la-otter',
  paw: 'las la-paw',
  spider: 'las la-spider',
  'angle-double-down': 'las la-angle-double-down',
  'angle-double-left': 'las la-angle-double-left',
  'angle-double-right': 'las la-angle-double-right',
  'angle-double-up': 'las la-angle-double-up',
  'angle-down': 'las la-angle-down',
  'angle-left': 'las la-angle-left',
  'angle-right': 'las la-angle-right',
  'angle-up': 'las la-angle-up',
  'arrow-alt-circle-down': 'las la-arrow-alt-circle-down',
  'arrow-alt-circle-left': 'las la-arrow-alt-circle-left',
  'arrow-alt-circle-right': 'las la-arrow-alt-circle-right',
  'arrow-alt-circle-up': 'las la-arrow-alt-circle-up',
  'arrow-circle-down': 'las la-arrow-circle-down',
  'arrow-circle-left': 'las la-arrow-circle-left',
  'arrow-circle-right': 'las la-arrow-circle-right',
  'arrow-circle-up': 'las la-arrow-circle-up',
  'arrow-down': 'las la-arrow-down',
  'arrow-left': 'las la-arrow-left',
  'arrow-right': 'las la-arrow-right',
  'arrow-up': 'las la-arrow-up',
  'arrows-alt': 'las la-arrows-alt',
  'arrows-alt-h': 'las la-arrows-alt-h',
  'arrows-alt-v': 'las la-arrows-alt-v',
  'caret-down': 'las la-caret-down',
  'caret-left': 'las la-caret-left',
  'caret-right': 'las la-caret-right',
  'caret-square-down': 'las la-caret-square-down',
  'caret-square-left': 'las la-caret-square-left',
  'caret-square-right': 'las la-caret-square-right',
  'caret-square-up': 'las la-caret-square-up',
  'caret-up': 'las la-caret-up',
  'cart-arrow-down': 'las la-cart-arrow-down',
  'chart-line': 'las la-chart-line',
  'chevron-circle-down': 'las la-chevron-circle-down',
  'chevron-circle-left': 'las la-chevron-circle-left',
  'chevron-circle-right': 'las la-chevron-circle-right',
  'chevron-circle-up': 'las la-chevron-circle-up',
  'chevron-down': 'las la-chevron-down',
  'chevron-left': 'las la-chevron-left',
  'chevron-right': 'las la-chevron-right',
  'chevron-up': 'las la-chevron-up',
  'cloud-download-alt': 'las la-cloud-download-alt',
  'cloud-upload-alt': 'las la-cloud-upload-alt',
  'compress-arrows-alt': 'las la-compress-arrows-alt',
  download: 'las la-download',
  'exchange-alt': 'las la-exchange-alt',
  'expand-arrows-alt': 'las la-expand-arrows-alt',
  'external-link-alt': 'las la-external-link-alt',
  'external-link-square-alt': 'las la-external-link-square-alt',
  'hand-point-down': 'las la-hand-point-down',
  'hand-point-left': 'las la-hand-point-left',
  'hand-point-right': 'las la-hand-point-right',
  'hand-point-up': 'las la-hand-point-up',
  'hand-pointer': 'las la-hand-pointer',
  history: 'las la-history',
  'level-down-alt': 'las la-level-down-alt',
  'level-up-alt': 'las la-level-up-alt',
  'location-arrow': 'las la-location-arrow',
  'long-arrow-alt-down': 'las la-long-arrow-alt-down',
  'long-arrow-alt-left': 'las la-long-arrow-alt-left',
  'long-arrow-alt-right': 'las la-long-arrow-alt-right',
  'long-arrow-alt-up': 'las la-long-arrow-alt-up',
  'mouse-pointer': 'las la-mouse-pointer',
  play: 'las la-play',
  random: 'las la-random',
  recycle: 'las la-recycle',
  redo: 'las la-redo',
  'redo-alt': 'las la-redo-alt',
  reply: 'las la-reply',
  'reply-all': 'las la-reply-all',
  retweet: 'las la-retweet',
  share: 'las la-share',
  'share-square': 'las la-share-square',
  'sign-in-alt': 'las la-sign-in-alt',
  'sign-out-alt': 'las la-sign-out-alt',
  sort: 'las la-sort',
  'sort-alpha-down': 'las la-sort-alpha-down',
  'sort-alpha-down-alt': 'las la-sort-alpha-down-alt',
  'sort-alpha-up': 'las la-sort-alpha-up',
  'sort-alpha-up-alt': 'las la-sort-alpha-up-alt',
  'sort-amount-down': 'las la-sort-amount-down',
  'sort-amount-down-alt': 'las la-sort-amount-down-alt',
  'sort-amount-up': 'las la-sort-amount-up',
  'sort-amount-up-alt': 'las la-sort-amount-up-alt',
  'sort-down': 'las la-sort-down',
  'sort-numeric-down': 'las la-sort-numeric-down',
  'sort-numeric-down-alt': 'las la-sort-numeric-down-alt',
  'sort-numeric-up': 'las la-sort-numeric-up',
  'sort-numeric-up-alt': 'las la-sort-numeric-up-alt',
  'sort-up': 'las la-sort-up',
  sync: 'las la-sync',
  'sync-alt': 'las la-sync-alt',
  'text-height': 'las la-text-height',
  'text-width': 'las la-text-width',
  undo: 'las la-undo',
  'undo-alt': 'las la-undo-alt',
  upload: 'las la-upload',
  backward: 'las la-backward',
  'broadcast-tower': 'las la-broadcast-tower',
  circle: 'las la-circle',
  compress: 'las la-compress',
  eject: 'las la-eject',
  expand: 'las la-expand',
  'fast-backward': 'las la-fast-backward',
  'fast-forward': 'las la-fast-forward',
  'file-audio': 'las la-file-audio',
  'file-video': 'las la-file-video',
  film: 'las la-film',
  forward: 'las la-forward',
  headphones: 'las la-headphones',
  microphone: 'las la-microphone',
  'microphone-alt': 'las la-microphone-alt',
  'microphone-alt-slash': 'las la-microphone-alt-slash',
  'microphone-slash': 'las la-microphone-slash',
  music: 'las la-music',
  pause: 'las la-pause',
  'pause-circle': 'las la-pause-circle',
  'photo-video': 'las la-photo-video',
  'play-circle': 'las la-play-circle',
  podcast: 'las la-podcast',
  rss: 'las la-rss',
  'rss-square': 'las la-rss-square',
  'step-backward': 'las la-step-backward',
  'step-forward': 'las la-step-forward',
  stop: 'las la-stop',
  'stop-circle': 'las la-stop-circle',
  tv: 'las la-tv',
  video: 'las la-video',
  'volume-down': 'las la-volume-down',
  'volume-mute': 'las la-volume-mute',
  'volume-off': 'las la-volume-off',
  'volume-up': 'las la-volume-up',
  youtube: 'lab la-youtube',
  'air-freshener': 'las la-air-freshener',
  ambulance: 'las la-ambulance',
  bus: 'las la-bus',
  'bus-alt': 'las la-bus-alt',
  car: 'las la-car',
  'car-alt': 'las la-car-alt',
  'car-battery': 'las la-car-battery',
  'car-crash': 'las la-car-crash',
  'car-side': 'las la-car-side',
  'charging-station': 'las la-charging-station',
  'gas-pump': 'las la-gas-pump',
  motorcycle: 'las la-motorcycle',
  'oil-can': 'las la-oil-can',
  'shuttle-van': 'las la-shuttle-van',
  'tachometer-alt': 'las la-tachometer-alt',
  taxi: 'las la-taxi',
  truck: 'las la-truck',
  'truck-monster': 'las la-truck-monster',
  'truck-pickup': 'las la-truck-pickup',
  'apple-alt': 'las la-apple-alt',
  campground: 'las la-campground',
  'cloud-sun': 'las la-cloud-sun',
  'drumstick-bite': 'las la-drumstick-bite',
  'football-ball': 'las la-football-ball',
  hiking: 'las la-hiking',
  mountain: 'las la-mountain',
  tractor: 'las la-tractor',
  tree: 'las la-tree',
  wind: 'las la-wind',
  'wine-bottle': 'las la-wine-bottle',
  beer: 'las la-beer',
  blender: 'las la-blender',
  cocktail: 'las la-cocktail',
  coffee: 'las la-coffee',
  flask: 'las la-flask',
  'glass-cheers': 'las la-glass-cheers',
  'glass-martini': 'las la-glass-martini',
  'glass-martini-alt': 'las la-glass-martini-alt',
  'glass-whiskey': 'las la-glass-whiskey',
  'mug-hot': 'las la-mug-hot',
  'wine-glass': 'las la-wine-glass',
  'wine-glass-alt': 'las la-wine-glass-alt',
  '500px': 'lab la-500px',
  accusoft: 'lab la-accusoft',
  adn: 'lab la-adn',
  adobe: 'lab la-adobe',
  adversal: 'lab la-adversal',
  affiliatetheme: 'lab la-affiliatetheme',
  airbnb: 'lab la-airbnb',
  algolia: 'lab la-algolia',
  amazon: 'lab la-amazon',
  amilia: 'lab la-amilia',
  android: 'lab la-android',
  angellist: 'lab la-angellist',
  angrycreative: 'lab la-angrycreative',
  angular: 'lab la-angular',
  'app-store': 'lab la-app-store',
  'app-store-ios': 'lab la-app-store-ios',
  apper: 'lab la-apper',
  apple: 'lab la-apple',
  artstation: 'lab la-artstation',
  asymmetrik: 'lab la-asymmetrik',
  atlassian: 'lab la-atlassian',
  audible: 'lab la-audible',
  autoprefixer: 'lab la-autoprefixer',
  avianex: 'lab la-avianex',
  aviato: 'lab la-aviato',
  aws: 'lab la-aws',
  bandcamp: 'lab la-bandcamp',
  'battle-net': 'lab la-battle-net',
  behance: 'lab la-behance',
  'behance-square': 'lab la-behance-square',
  bimobject: 'lab la-bimobject',
  bitbucket: 'lab la-bitbucket',
  bity: 'lab la-bity',
  'black-tie': 'lab la-black-tie',
  blackberry: 'lab la-blackberry',
  blogger: 'lab la-blogger',
  'blogger-b': 'lab la-blogger-b',
  bootstrap: 'lab la-bootstrap',
  buffer: 'lab la-buffer',
  buromobelexperte: 'lab la-buromobelexperte',
  'buy-n-large': 'lab la-buy-n-large',
  buysellads: 'lab la-buysellads',
  'canadian-maple-leaf': 'lab la-canadian-maple-leaf',
  centercode: 'lab la-centercode',
  centos: 'lab la-centos',
  chrome: 'lab la-chrome',
  chromecast: 'lab la-chromecast',
  cloudscale: 'lab la-cloudscale',
  cloudsmith: 'lab la-cloudsmith',
  cloudversify: 'lab la-cloudversify',
  codepen: 'lab la-codepen',
  codiepie: 'lab la-codiepie',
  confluence: 'lab la-confluence',
  connectdevelop: 'lab la-connectdevelop',
  contao: 'lab la-contao',
  'cotton-bureau': 'lab la-cotton-bureau',
  cpanel: 'lab la-cpanel',
  'creative-commons': 'lab la-creative-commons',
  'creative-commons-by': 'lab la-creative-commons-by',
  'creative-commons-nc': 'lab la-creative-commons-nc',
  'creative-commons-nc-eu': 'lab la-creative-commons-nc-eu',
  'creative-commons-nc-jp': 'lab la-creative-commons-nc-jp',
  'creative-commons-nd': 'lab la-creative-commons-nd',
  'creative-commons-pd': 'lab la-creative-commons-pd',
  'creative-commons-pd-alt': 'lab la-creative-commons-pd-alt',
  'creative-commons-remix': 'lab la-creative-commons-remix',
  'creative-commons-sa': 'lab la-creative-commons-sa',
  'creative-commons-sampling': 'lab la-creative-commons-sampling',
  'creative-commons-sampling-plus': 'lab la-creative-commons-sampling-plus',
  'creative-commons-share': 'lab la-creative-commons-share',
  'creative-commons-zero': 'lab la-creative-commons-zero',
  css3: 'lab la-css3',
  'css3-alt': 'lab la-css3-alt',
  cuttlefish: 'lab la-cuttlefish',
  dashcube: 'lab la-dashcube',
  delicious: 'lab la-delicious',
  deploydog: 'lab la-deploydog',
  deskpro: 'lab la-deskpro',
  dev: 'lab la-dev',
  deviantart: 'lab la-deviantart',
  dhl: 'lab la-dhl',
  diaspora: 'lab la-diaspora',
  digg: 'lab la-digg',
  'digital-ocean': 'lab la-digital-ocean',
  discord: 'lab la-discord',
  discourse: 'lab la-discourse',
  dochub: 'lab la-dochub',
  docker: 'lab la-docker',
  draft2digital: 'lab la-draft2digital',
  dribbble: 'lab la-dribbble',
  'dribbble-square': 'lab la-dribbble-square',
  dropbox: 'lab la-dropbox',
  drupal: 'lab la-drupal',
  dyalog: 'lab la-dyalog',
  earlybirds: 'lab la-earlybirds',
  ebay: 'lab la-ebay',
  edge: 'lab la-edge',
  elementor: 'lab la-elementor',
  ello: 'lab la-ello',
  ember: 'lab la-ember',
  empire: 'lab la-empire',
  envira: 'lab la-envira',
  erlang: 'lab la-erlang',
  etsy: 'lab la-etsy',
  evernote: 'lab la-evernote',
  expeditedssl: 'lab la-expeditedssl',
  facebook: 'lab la-facebook',
  'facebook-f': 'lab la-facebook-f',
  'facebook-messenger': 'lab la-facebook-messenger',
  'facebook-square': 'lab la-facebook-square',
  fedex: 'lab la-fedex',
  fedora: 'lab la-fedora',
  figma: 'lab la-figma',
  firefox: 'lab la-firefox',
  'first-order': 'lab la-first-order',
  'first-order-alt': 'lab la-first-order-alt',
  firstdraft: 'lab la-firstdraft',
  flickr: 'lab la-flickr',
  flipboard: 'lab la-flipboard',
  fly: 'lab la-fly',
  'font-awesome': 'lab la-font-awesome',
  'font-awesome-alt': 'lab la-font-awesome-alt',
  'font-awesome-flag': 'lab la-font-awesome-flag',
  fonticons: 'lab la-fonticons',
  'fonticons-fi': 'lab la-fonticons-fi',
  'fort-awesome': 'lab la-fort-awesome',
  'fort-awesome-alt': 'lab la-fort-awesome-alt',
  forumbee: 'lab la-forumbee',
  foursquare: 'lab la-foursquare',
  'free-code-camp': 'lab la-free-code-camp',
  freebsd: 'lab la-freebsd',
  fulcrum: 'lab la-fulcrum',
  'get-pocket': 'lab la-get-pocket',
  git: 'lab la-git',
  'git-alt': 'lab la-git-alt',
  'git-square': 'lab la-git-square',
  github: 'lab la-github',
  'github-alt': 'lab la-github-alt',
  'github-square': 'lab la-github-square',
  gitkraken: 'lab la-gitkraken',
  gitlab: 'lab la-gitlab',
  gitter: 'lab la-gitter',
  glide: 'lab la-glide',
  'glide-g': 'lab la-glide-g',
  gofore: 'lab la-gofore',
  goodreads: 'lab la-goodreads',
  'goodreads-g': 'lab la-goodreads-g',
  google: 'lab la-google',
  'google-drive': 'lab la-google-drive',
  'google-play': 'lab la-google-play',
  'google-plus': 'lab la-google-plus',
  'google-plus-g': 'lab la-google-plus-g',
  'google-plus-square': 'lab la-google-plus-square',
  gratipay: 'lab la-gratipay',
  grav: 'lab la-grav',
  gripfire: 'lab la-gripfire',
  grunt: 'lab la-grunt',
  gulp: 'lab la-gulp',
  'hacker-news': 'lab la-hacker-news',
  'hacker-news-square': 'lab la-hacker-news-square',
  hackerrank: 'lab la-hackerrank',
  hips: 'lab la-hips',
  'hire-a-helper': 'lab la-hire-a-helper',
  hooli: 'lab la-hooli',
  hornbill: 'lab la-hornbill',
  hotjar: 'lab la-hotjar',
  houzz: 'lab la-houzz',
  html5: 'lab la-html5',
  hubspot: 'lab la-hubspot',
  imdb: 'lab la-imdb',
  instagram: 'lab la-instagram',
  intercom: 'lab la-intercom',
  'internet-explorer': 'lab la-internet-explorer',
  invision: 'lab la-invision',
  ioxhost: 'lab la-ioxhost',
  'itch-io': 'lab la-itch-io',
  itunes: 'lab la-itunes',
  'itunes-note': 'lab la-itunes-note',
  java: 'lab la-java',
  jenkins: 'lab la-jenkins',
  jira: 'lab la-jira',
  joget: 'lab la-joget',
  joomla: 'lab la-joomla',
  js: 'lab la-js',
  'js-square': 'lab la-js-square',
  jsfiddle: 'lab la-jsfiddle',
  kaggle: 'lab la-kaggle',
  keybase: 'lab la-keybase',
  keycdn: 'lab la-keycdn',
  kickstarter: 'lab la-kickstarter',
  'kickstarter-k': 'lab la-kickstarter-k',
  korvue: 'lab la-korvue',
  laravel: 'lab la-laravel',
  lastfm: 'lab la-lastfm',
  'lastfm-square': 'lab la-lastfm-square',
  leanpub: 'lab la-leanpub',
  less: 'lab la-less',
  line: 'lab la-line',
  linkedin: 'lab la-linkedin',
  'linkedin-in': 'lab la-linkedin-in',
  linode: 'lab la-linode',
  linux: 'lab la-linux',
  lyft: 'lab la-lyft',
  magento: 'lab la-magento',
  mailchimp: 'lab la-mailchimp',
  mandalorian: 'lab la-mandalorian',
  markdown: 'lab la-markdown',
  mastodon: 'lab la-mastodon',
  maxcdn: 'lab la-maxcdn',
  mdb: 'lab la-mdb',
  medapps: 'lab la-medapps',
  medium: 'lab la-medium',
  'medium-m': 'lab la-medium-m',
  medrt: 'lab la-medrt',
  meetup: 'lab la-meetup',
  megaport: 'lab la-megaport',
  mendeley: 'lab la-mendeley',
  microsoft: 'lab la-microsoft',
  mix: 'lab la-mix',
  mixcloud: 'lab la-mixcloud',
  mizuni: 'lab la-mizuni',
  modx: 'lab la-modx',
  monero: 'lab la-monero',
  neos: 'lab la-neos',
  nimblr: 'lab la-nimblr',
  node: 'lab la-node',
  'node-js': 'lab la-node-js',
  npm: 'lab la-npm',
  ns8: 'lab la-ns8',
  nutritionix: 'lab la-nutritionix',
  odnoklassniki: 'lab la-odnoklassniki',
  'odnoklassniki-square': 'lab la-odnoklassniki-square',
  opencart: 'lab la-opencart',
  openid: 'lab la-openid',
  opera: 'lab la-opera',
  'optin-monster': 'lab la-optin-monster',
  orcid: 'lab la-orcid',
  osi: 'lab la-osi',
  page4: 'lab la-page4',
  pagelines: 'lab la-pagelines',
  palfed: 'lab la-palfed',
  patreon: 'lab la-patreon',
  periscope: 'lab la-periscope',
  phabricator: 'lab la-phabricator',
  'phoenix-framework': 'lab la-phoenix-framework',
  'phoenix-squadron': 'lab la-phoenix-squadron',
  php: 'lab la-php',
  'pied-piper': 'lab la-pied-piper',
  'pied-piper-alt': 'lab la-pied-piper-alt',
  'pied-piper-hat': 'lab la-pied-piper-hat',
  'pied-piper-pp': 'lab la-pied-piper-pp',
  pinterest: 'lab la-pinterest',
  'pinterest-p': 'lab la-pinterest-p',
  'pinterest-square': 'lab la-pinterest-square',
  'product-hunt': 'lab la-product-hunt',
  pushed: 'lab la-pushed',
  python: 'lab la-python',
  qq: 'lab la-qq',
  quinscape: 'lab la-quinscape',
  quora: 'lab la-quora',
  'r-project': 'lab la-r-project',
  'raspberry-pi': 'lab la-raspberry-pi',
  ravelry: 'lab la-ravelry',
  react: 'lab la-react',
  reacteurope: 'lab la-reacteurope',
  readme: 'lab la-readme.md',
  rebel: 'lab la-rebel',
  'red-river': 'lab la-red-river',
  reddit: 'lab la-reddit',
  'reddit-alien': 'lab la-reddit-alien',
  'reddit-square': 'lab la-reddit-square',
  redhat: 'lab la-redhat',
  renren: 'lab la-renren',
  replyd: 'lab la-replyd',
  researchgate: 'lab la-researchgate',
  resolving: 'lab la-resolving',
  rev: 'lab la-rev',
  rocketchat: 'lab la-rocketchat',
  rockrms: 'lab la-rockrms',
  safari: 'lab la-safari',
  salesforce: 'lab la-salesforce',
  sass: 'lab la-sass',
  schlix: 'lab la-schlix',
  scribd: 'lab la-scribd',
  searchengin: 'lab la-searchengin',
  sellcast: 'lab la-sellcast',
  sellsy: 'lab la-sellsy',
  servicestack: 'lab la-servicestack',
  shirtsinbulk: 'lab la-shirtsinbulk',
  shopware: 'lab la-shopware',
  simplybuilt: 'lab la-simplybuilt',
  sistrix: 'lab la-sistrix',
  sith: 'lab la-sith',
  sketch: 'lab la-sketch',
  skyatlas: 'lab la-skyatlas',
  skype: 'lab la-skype',
  slack: 'lab la-slack',
  'slack-hash': 'lab la-slack-hash',
  slideshare: 'lab la-slideshare',
  snapchat: 'lab la-snapchat',
  'snapchat-ghost': 'lab la-snapchat-ghost',
  'snapchat-square': 'lab la-snapchat-square',
  sourcetree: 'lab la-sourcetree',
  speakap: 'lab la-speakap',
  'speaker-deck': 'lab la-speaker-deck',
  squarespace: 'lab la-squarespace',
  'stack-exchange': 'lab la-stack-exchange',
  'stack-overflow': 'lab la-stack-overflow',
  stackpath: 'lab la-stackpath',
  staylinked: 'lab la-staylinked',
  'sticker-mule': 'lab la-sticker-mule',
  strava: 'lab la-strava',
  studiovinari: 'lab la-studiovinari',
  stumbleupon: 'lab la-stumbleupon',
  'stumbleupon-circle': 'lab la-stumbleupon-circle',
  superpowers: 'lab la-superpowers',
  supple: 'lab la-supple',
  suse: 'lab la-suse',
  swift: 'lab la-swift',
  symfony: 'lab la-symfony',
  teamspeak: 'lab la-teamspeak',
  telegram: 'lab la-telegram',
  'telegram-plane': 'lab la-telegram-plane',
  'tencent-weibo': 'lab la-tencent-weibo',
  'the-red-yeti': 'lab la-the-red-yeti',
  themeco: 'lab la-themeco',
  themeisle: 'lab la-themeisle',
  'think-peaks': 'lab la-think-peaks',
  'trade-federation': 'lab la-trade-federation',
  trello: 'lab la-trello',
  tripadvisor: 'lab la-tripadvisor',
  tumblr: 'lab la-tumblr',
  'tumblr-square': 'lab la-tumblr-square',
  twitter: 'lab la-twitter',
  'twitter-square': 'lab la-twitter-square',
  typo3: 'lab la-typo3',
  uber: 'lab la-uber',
  ubuntu: 'lab la-ubuntu',
  uikit: 'lab la-uikit',
  umbraco: 'lab la-umbraco',
  uniregistry: 'lab la-uniregistry',
  untappd: 'lab la-untappd',
  ups: 'lab la-ups',
  usb: 'lab la-usb',
  usps: 'lab la-usps',
  ussunnah: 'lab la-ussunnah',
  vaadin: 'lab la-vaadin',
  viacoin: 'lab la-viacoin',
  viadeo: 'lab la-viadeo',
  'viadeo-square': 'lab la-viadeo-square',
  viber: 'lab la-viber',
  vimeo: 'lab la-vimeo',
  'vimeo-square': 'lab la-vimeo-square',
  'vimeo-v': 'lab la-vimeo-v',
  vine: 'lab la-vine',
  vk: 'lab la-vk',
  vnv: 'lab la-vnv',
  vuejs: 'lab la-vuejs',
  waze: 'lab la-waze',
  weebly: 'lab la-weebly',
  weibo: 'lab la-weibo',
  weixin: 'lab la-weixin',
  whatsapp: 'lab la-whatsapp',
  'whatsapp-square': 'lab la-whatsapp-square',
  whmcs: 'lab la-whmcs',
  'wikipedia-w': 'lab la-wikipedia-w',
  windows: 'lab la-windows',
  wix: 'lab la-wix',
  'wolf-pack-battalion': 'lab la-wolf-pack-battalion',
  wordpress: 'lab la-wordpress',
  'wordpress-simple': 'lab la-wordpress-simple',
  wpbeginner: 'lab la-wpbeginner',
  wpexplorer: 'lab la-wpexplorer',
  wpforms: 'lab la-wpforms',
  wpressr: 'lab la-wpressr',
  xing: 'lab la-xing',
  'xing-square': 'lab la-xing-square',
  'y-combinator': 'lab la-y-combinator',
  yahoo: 'lab la-yahoo',
  yammer: 'lab la-yammer',
  yandex: 'lab la-yandex',
  'yandex-international': 'lab la-yandex-international',
  yarn: 'lab la-yarn',
  yelp: 'lab la-yelp',
  yoast: 'lab la-yoast',
  'youtube-square': 'lab la-youtube-square',
  zhihu: 'lab la-zhihu',
  archway: 'las la-archway',
  building: 'las la-building',
  church: 'las la-church',
  city: 'las la-city',
  'clinic-medical': 'las la-clinic-medical',
  dungeon: 'las la-dungeon',
  gopuram: 'las la-gopuram',
  home: 'las la-home',
  hospital: 'las la-hospital',
  'hospital-alt': 'las la-hospital-alt',
  hotel: 'las la-hotel',
  'house-damage': 'las la-house-damage',
  igloo: 'las la-igloo',
  industry: 'las la-industry',
  kaaba: 'las la-kaaba',
  landmark: 'las la-landmark',
  monument: 'las la-monument',
  mosque: 'las la-mosque',
  'place-of-worship': 'las la-place-of-worship',
  school: 'las la-school',
  store: 'las la-store',
  'store-alt': 'las la-store-alt',
  synagogue: 'las la-synagogue',
  'torii-gate': 'las la-torii-gate',
  university: 'las la-university',
  vihara: 'las la-vihara',
  warehouse: 'las la-warehouse',
  'address-book': 'las la-address-book',
  'address-card': 'las la-address-card',
  archive: 'las la-archive',
  'balance-scale': 'las la-balance-scale',
  'balance-scale-left': 'las la-balance-scale-left',
  'balance-scale-right': 'las la-balance-scale-right',
  'birthday-cake': 'las la-birthday-cake',
  book: 'las la-book',
  briefcase: 'las la-briefcase',
  bullhorn: 'las la-bullhorn',
  bullseye: 'las la-bullseye',
  'business-time': 'las la-business-time',
  calculator: 'las la-calculator',
  calendar: 'las la-calendar',
  'calendar-alt': 'las la-calendar-alt',
  certificate: 'las la-certificate',
  'chart-area': 'las la-chart-area',
  'chart-bar': 'las la-chart-bar',
  'chart-pie': 'las la-chart-pie',
  clipboard: 'las la-clipboard',
  columns: 'las la-columns',
  compass: 'las la-compass',
  copy: 'las la-copy',
  copyright: 'las la-copyright',
  cut: 'las la-cut',
  edit: 'las la-edit',
  envelope: 'las la-envelope',
  'envelope-open': 'las la-envelope-open',
  'envelope-square': 'las la-envelope-square',
  eraser: 'las la-eraser',
  fax: 'las la-fax',
  file: 'las la-file',
  'file-alt': 'las la-file-alt',
  folder: 'las la-folder',
  'folder-minus': 'las la-folder-minus',
  'folder-open': 'las la-folder-open',
  'folder-plus': 'las la-folder-plus',
  glasses: 'las la-glasses',
  globe: 'las la-globe',
  highlighter: 'las la-highlighter',
  marker: 'las la-marker',
  paperclip: 'las la-paperclip',
  paste: 'las la-paste',
  pen: 'las la-pen',
  'pen-alt': 'las la-pen-alt',
  'pen-fancy': 'las la-pen-fancy',
  'pen-nib': 'las la-pen-nib',
  'pen-square': 'las la-pen-square',
  'pencil-alt': 'las la-pencil-alt',
  percent: 'las la-percent',
  phone: 'las la-phone',
  'phone-alt': 'las la-phone-alt',
  'phone-slash': 'las la-phone-slash',
  'phone-square': 'las la-phone-square',
  'phone-square-alt': 'las la-phone-square-alt',
  print: 'las la-print',
  'project-diagram': 'las la-project-diagram',
  registered: 'las la-registered',
  save: 'las la-save',
  sitemap: 'las la-sitemap',
  socks: 'las la-socks',
  'sticky-note': 'las la-sticky-note',
  stream: 'las la-stream',
  table: 'las la-table',
  tag: 'las la-tag',
  tags: 'las la-tags',
  tasks: 'las la-tasks',
  thumbtack: 'las la-thumbtack',
  trademark: 'las la-trademark',
  wallet: 'las la-wallet',
  binoculars: 'las la-binoculars',
  fire: 'las la-fire',
  'fire-alt': 'las la-fire-alt',
  'first-aid': 'las la-first-aid',
  map: 'las la-map',
  'map-marked': 'las la-map-marked',
  'map-marked-alt': 'las la-map-marked-alt',
  'map-signs': 'las la-map-signs',
  route: 'las la-route',
  'toilet-paper': 'las la-toilet-paper',
  'dollar-sign': 'las la-dollar-sign',
  donate: 'las la-donate',
  gift: 'las la-gift',
  'hand-holding-heart': 'las la-hand-holding-heart',
  'hand-holding-usd': 'las la-hand-holding-usd',
  'hands-helping': 'las la-hands-helping',
  handshake: 'las la-handshake',
  heart: 'las la-heart',
  leaf: 'las la-leaf',
  'parachute-box': 'las la-parachute-box',
  'piggy-bank': 'las la-piggy-bank',
  ribbon: 'las la-ribbon',
  seedling: 'las la-seedling',
  comment: 'las la-comment',
  'comment-alt': 'las la-comment-alt',
  'comment-dots': 'las la-comment-dots',
  'comment-medical': 'las la-comment-medical',
  'comment-slash': 'las la-comment-slash',
  comments: 'las la-comments',
  frown: 'las la-frown',
  icons: 'las la-icons',
  meh: 'las la-meh',
  poo: 'las la-poo',
  'quote-left': 'las la-quote-left',
  'quote-right': 'las la-quote-right',
  smile: 'las la-smile',
  sms: 'las la-sms',
  'video-slash': 'las la-video-slash',
  chess: 'las la-chess',
  'chess-bishop': 'las la-chess-bishop',
  'chess-board': 'las la-chess-board',
  'chess-king': 'las la-chess-king',
  'chess-knight': 'las la-chess-knight',
  'chess-pawn': 'las la-chess-pawn',
  'chess-queen': 'las la-chess-queen',
  'chess-rook': 'las la-chess-rook',
  'square-full': 'las la-square-full',
  baby: 'las la-baby',
  'baby-carriage': 'las la-baby-carriage',
  bath: 'las la-bath',
  biking: 'las la-biking',
  cookie: 'las la-cookie',
  'cookie-bite': 'las la-cookie-bite',
  gamepad: 'las la-gamepad',
  'ice-cream': 'las la-ice-cream',
  mitten: 'las la-mitten',
  robot: 'las la-robot',
  shapes: 'las la-shapes',
  snowman: 'las la-snowman',
  'graduation-cap': 'las la-graduation-cap',
  'hat-cowboy': 'las la-hat-cowboy',
  'hat-cowboy-side': 'las la-hat-cowboy-side',
  'hat-wizard': 'las la-hat-wizard',
  'shoe-prints': 'las la-shoe-prints',
  tshirt: 'las la-tshirt',
  'user-tie': 'las la-user-tie',
  barcode: 'las la-barcode',
  bug: 'las la-bug',
  code: 'las la-code',
  'code-branch': 'las la-code-branch',
  'file-code': 'las la-file-code',
  filter: 'las la-filter',
  'fire-extinguisher': 'las la-fire-extinguisher',
  keyboard: 'las la-keyboard',
  'laptop-code': 'las la-laptop-code',
  microchip: 'las la-microchip',
  qrcode: 'las la-qrcode',
  'shield-alt': 'las la-shield-alt',
  terminal: 'las la-terminal',
  'user-secret': 'las la-user-secret',
  'window-close': 'las la-window-close',
  'window-maximize': 'las la-window-maximize',
  'window-minimize': 'las la-window-minimize',
  'window-restore': 'las la-window-restore',
  at: 'las la-at',
  bluetooth: 'lab la-bluetooth',
  'bluetooth-b': 'lab la-bluetooth-b',
  chalkboard: 'las la-chalkboard',
  inbox: 'las la-inbox',
  language: 'las la-language',
  mobile: 'las la-mobile',
  'mobile-alt': 'las la-mobile-alt',
  'paper-plane': 'las la-paper-plane',
  voicemail: 'las la-voicemail',
  wifi: 'las la-wifi',
  database: 'las la-database',
  desktop: 'las la-desktop',
  ethernet: 'las la-ethernet',
  hdd: 'las la-hdd',
  laptop: 'las la-laptop',
  memory: 'las la-memory',
  mouse: 'las la-mouse',
  plug: 'las la-plug',
  'power-off': 'las la-power-off',
  satellite: 'las la-satellite',
  'satellite-dish': 'las la-satellite-dish',
  'sd-card': 'las la-sd-card',
  server: 'las la-server',
  'sim-card': 'las la-sim-card',
  tablet: 'las la-tablet',
  'tablet-alt': 'las la-tablet-alt',
  brush: 'las la-brush',
  'drafting-compass': 'las la-drafting-compass',
  dumpster: 'las la-dumpster',
  hammer: 'las la-hammer',
  'hard-hat': 'las la-hard-hat',
  'paint-roller': 'las la-paint-roller',
  'pencil-ruler': 'las la-pencil-ruler',
  ruler: 'las la-ruler',
  'ruler-combined': 'las la-ruler-combined',
  'ruler-horizontal': 'las la-ruler-horizontal',
  'ruler-vertical': 'las la-ruler-vertical',
  screwdriver: 'las la-screwdriver',
  toolbox: 'las la-toolbox',
  tools: 'las la-tools',
  wrench: 'las la-wrench',
  bitcoin: 'lab la-bitcoin',
  btc: 'lab la-btc',
  ethereum: 'lab la-ethereum',
  'euro-sign': 'las la-euro-sign',
  gg: 'lab la-gg',
  'gg-circle': 'lab la-gg-circle',
  hryvnia: 'las la-hryvnia',
  'lira-sign': 'las la-lira-sign',
  'money-bill': 'las la-money-bill',
  'money-bill-alt': 'las la-money-bill-alt',
  'money-bill-wave': 'las la-money-bill-wave',
  'money-bill-wave-alt': 'las la-money-bill-wave-alt',
  'money-check': 'las la-money-check',
  'money-check-alt': 'las la-money-check-alt',
  'pound-sign': 'las la-pound-sign',
  'ruble-sign': 'las la-ruble-sign',
  'rupee-sign': 'las la-rupee-sign',
  'shekel-sign': 'las la-shekel-sign',
  tenge: 'las la-tenge',
  'won-sign': 'las la-won-sign',
  'yen-sign': 'las la-yen-sign',
  'calendar-check': 'las la-calendar-check',
  'calendar-minus': 'las la-calendar-minus',
  'calendar-plus': 'las la-calendar-plus',
  'calendar-times': 'las la-calendar-times',
  clock: 'las la-clock',
  hourglass: 'las la-hourglass',
  'hourglass-end': 'las la-hourglass-end',
  'hourglass-half': 'las la-hourglass-half',
  'hourglass-start': 'las la-hourglass-start',
  stopwatch: 'las la-stopwatch',
  adjust: 'las la-adjust',
  'bezier-curve': 'las la-bezier-curve',
  clone: 'las la-clone',
  crop: 'las la-crop',
  'crop-alt': 'las la-crop-alt',
  crosshairs: 'las la-crosshairs',
  'draw-polygon': 'las la-draw-polygon',
  eye: 'las la-eye',
  'eye-dropper': 'las la-eye-dropper',
  'eye-slash': 'las la-eye-slash',
  fill: 'las la-fill',
  'fill-drip': 'las la-fill-drip',
  'layer-group': 'las la-layer-group',
  magic: 'las la-magic',
  'object-group': 'las la-object-group',
  'object-ungroup': 'las la-object-ungroup',
  'paint-brush': 'las la-paint-brush',
  palette: 'las la-palette',
  splotch: 'las la-splotch',
  'spray-can': 'las la-spray-can',
  stamp: 'las la-stamp',
  swatchbook: 'las la-swatchbook',
  tint: 'las la-tint',
  'tint-slash': 'las la-tint-slash',
  'vector-square': 'las la-vector-square',
  'align-center': 'las la-align-center',
  'align-justify': 'las la-align-justify',
  'align-left': 'las la-align-left',
  'align-right': 'las la-align-right',
  bold: 'las la-bold',
  'border-all': 'las la-border-all',
  'border-none': 'las la-border-none',
  'border-style': 'las la-border-style',
  font: 'las la-font',
  heading: 'las la-heading',
  'i-cursor': 'las la-i-cursor',
  indent: 'las la-indent',
  italic: 'las la-italic',
  link: 'las la-link',
  list: 'las la-list',
  'list-alt': 'las la-list-alt',
  'list-ol': 'las la-list-ol',
  'list-ul': 'las la-list-ul',
  outdent: 'las la-outdent',
  paragraph: 'las la-paragraph',
  'remove-format': 'las la-remove-format',
  'spell-check': 'las la-spell-check',
  strikethrough: 'las la-strikethrough',
  subscript: 'las la-subscript',
  superscript: 'las la-superscript',
  th: 'las la-th',
  'th-large': 'las la-th-large',
  'th-list': 'las la-th-list',
  trash: 'las la-trash',
  'trash-alt': 'las la-trash-alt',
  'trash-restore': 'las la-trash-restore',
  'trash-restore-alt': 'las la-trash-restore-alt',
  underline: 'las la-underline',
  unlink: 'las la-unlink',
  atom: 'las la-atom',
  award: 'las la-award',
  'book-open': 'las la-book-open',
  'book-reader': 'las la-book-reader',
  'chalkboard-teacher': 'las la-chalkboard-teacher',
  microscope: 'las la-microscope',
  'theater-masks': 'las la-theater-masks',
  'user-graduate': 'las la-user-graduate',
  angry: 'las la-angry',
  dizzy: 'las la-dizzy',
  flushed: 'las la-flushed',
  'frown-open': 'las la-frown-open',
  grimace: 'las la-grimace',
  grin: 'las la-grin',
  'grin-alt': 'las la-grin-alt',
  'grin-beam': 'las la-grin-beam',
  'grin-beam-sweat': 'las la-grin-beam-sweat',
  'grin-hearts': 'las la-grin-hearts',
  'grin-squint': 'las la-grin-squint',
  'grin-squint-tears': 'las la-grin-squint-tears',
  'grin-stars': 'las la-grin-stars',
  'grin-tears': 'las la-grin-tears',
  'grin-tongue': 'las la-grin-tongue',
  'grin-tongue-squint': 'las la-grin-tongue-squint',
  'grin-tongue-wink': 'las la-grin-tongue-wink',
  'grin-wink': 'las la-grin-wink',
  kiss: 'las la-kiss',
  'kiss-beam': 'las la-kiss-beam',
  'kiss-wink-heart': 'las la-kiss-wink-heart',
  laugh: 'las la-laugh',
  'laugh-beam': 'las la-laugh-beam',
  'laugh-squint': 'las la-laugh-squint',
  'laugh-wink': 'las la-laugh-wink',
  'meh-blank': 'las la-meh-blank',
  'meh-rolling-eyes': 'las la-meh-rolling-eyes',
  'sad-cry': 'las la-sad-cry',
  'sad-tear': 'las la-sad-tear',
  'smile-beam': 'las la-smile-beam',
  'smile-wink': 'las la-smile-wink',
  surprise: 'las la-surprise',
  tired: 'las la-tired',
  'battery-empty': 'las la-battery-empty',
  'battery-full': 'las la-battery-full',
  'battery-half': 'las la-battery-half',
  'battery-quarter': 'las la-battery-quarter',
  'battery-three-quarters': 'las la-battery-three-quarters',
  burn: 'las la-burn',
  lightbulb: 'las la-lightbulb',
  poop: 'las la-poop',
  'solar-panel': 'las la-solar-panel',
  sun: 'las la-sun',
  water: 'las la-water',
  'file-archive': 'las la-file-archive',
  'file-excel': 'las la-file-excel',
  'file-image': 'las la-file-image',
  'file-pdf': 'las la-file-pdf',
  'file-powerpoint': 'las la-file-powerpoint',
  'file-word': 'las la-file-word',
  'cash-register': 'las la-cash-register',
  coins: 'las la-coins',
  'comment-dollar': 'las la-comment-dollar',
  'comments-dollar': 'las la-comments-dollar',
  'credit-card': 'las la-credit-card',
  'file-invoice': 'las la-file-invoice',
  'file-invoice-dollar': 'las la-file-invoice-dollar',
  percentage: 'las la-percentage',
  receipt: 'las la-receipt',
  bicycle: 'las la-bicycle',
  heartbeat: 'las la-heartbeat',
  running: 'las la-running',
  skating: 'las la-skating',
  skiing: 'las la-skiing',
  'skiing-nordic': 'las la-skiing-nordic',
  snowboarding: 'las la-snowboarding',
  spa: 'las la-spa',
  swimmer: 'las la-swimmer',
  walking: 'las la-walking',
  bacon: 'las la-bacon',
  bone: 'las la-bone',
  'bread-slice': 'las la-bread-slice',
  'candy-cane': 'las la-candy-cane',
  carrot: 'las la-carrot',
  cheese: 'las la-cheese',
  'cloud-meatball': 'las la-cloud-meatball',
  egg: 'las la-egg',
  hamburger: 'las la-hamburger',
  hotdog: 'las la-hotdog',
  lemon: 'las la-lemon',
  'pepper-hot': 'las la-pepper-hot',
  'pizza-slice': 'las la-pizza-slice',
  stroopwafel: 'las la-stroopwafel',
  dice: 'las la-dice',
  'dice-d20': 'las la-dice-d20',
  'dice-d6': 'las la-dice-d6',
  'dice-five': 'las la-dice-five',
  'dice-four': 'las la-dice-four',
  'dice-one': 'las la-dice-one',
  'dice-six': 'las la-dice-six',
  'dice-three': 'las la-dice-three',
  'dice-two': 'las la-dice-two',
  ghost: 'las la-ghost',
  headset: 'las la-headset',
  playstation: 'lab la-playstation',
  'puzzle-piece': 'las la-puzzle-piece',
  steam: 'lab la-steam',
  'steam-square': 'lab la-steam-square',
  'steam-symbol': 'lab la-steam-symbol',
  twitch: 'lab la-twitch',
  xbox: 'lab la-xbox',
  genderless: 'las la-genderless',
  mars: 'las la-mars',
  'mars-double': 'las la-mars-double',
  'mars-stroke': 'las la-mars-stroke',
  'mars-stroke-h': 'las la-mars-stroke-h',
  'mars-stroke-v': 'las la-mars-stroke-v',
  mercury: 'las la-mercury',
  neuter: 'las la-neuter',
  transgender: 'las la-transgender',
  'transgender-alt': 'las la-transgender-alt',
  venus: 'las la-venus',
  'venus-double': 'las la-venus-double',
  'venus-mars': 'las la-venus-mars',
  'book-dead': 'las la-book-dead',
  broom: 'las la-broom',
  'cloud-moon': 'las la-cloud-moon',
  mask: 'las la-mask',
  allergies: 'las la-allergies',
  'fist-raised': 'las la-fist-raised',
  'hand-holding': 'las la-hand-holding',
  'hand-lizard': 'las la-hand-lizard',
  'hand-middle-finger': 'las la-hand-middle-finger',
  'hand-paper': 'las la-hand-paper',
  'hand-peace': 'las la-hand-peace',
  'hand-rock': 'las la-hand-rock',
  'hand-scissors': 'las la-hand-scissors',
  'hand-spock': 'las la-hand-spock',
  hands: 'las la-hands',
  'praying-hands': 'las la-praying-hands',
  'thumbs-down': 'las la-thumbs-down',
  'thumbs-up': 'las la-thumbs-up',
  'h-square': 'las la-h-square',
  medkit: 'las la-medkit',
  'plus-square': 'las la-plus-square',
  prescription: 'las la-prescription',
  stethoscope: 'las la-stethoscope',
  'user-md': 'las la-user-md',
  gifts: 'las la-gifts',
  'holly-berry': 'las la-holly-berry',
  sleigh: 'las la-sleigh',
  bed: 'las la-bed',
  'concierge-bell': 'las la-concierge-bell',
  'door-closed': 'las la-door-closed',
  'door-open': 'las la-door-open',
  dumbbell: 'las la-dumbbell',
  'hot-tub': 'las la-hot-tub',
  infinity: 'las la-infinity',
  key: 'las la-key',
  'luggage-cart': 'las la-luggage-cart',
  shower: 'las la-shower',
  smoking: 'las la-smoking',
  'smoking-ban': 'las la-smoking-ban',
  snowflake: 'las la-snowflake',
  suitcase: 'las la-suitcase',
  'suitcase-rolling': 'las la-suitcase-rolling',
  'swimming-pool': 'las la-swimming-pool',
  'umbrella-beach': 'las la-umbrella-beach',
  utensils: 'las la-utensils',
  chair: 'las la-chair',
  couch: 'las la-couch',
  fan: 'las la-fan',
  bolt: 'las la-bolt',
  camera: 'las la-camera',
  'camera-retro': 'las la-camera-retro',
  'id-badge': 'las la-id-badge',
  'id-card': 'las la-id-card',
  image: 'las la-image',
  images: 'las la-images',
  portrait: 'las la-portrait',
  'sliders-h': 'las la-sliders-h',
  ban: 'las la-ban',
  bars: 'las la-bars',
  blog: 'las la-blog',
  check: 'las la-check',
  'check-circle': 'las la-check-circle',
  'check-double': 'las la-check-double',
  'check-square': 'las la-check-square',
  cloud: 'las la-cloud',
  cog: 'las la-cog',
  cogs: 'las la-cogs',
  'dot-circle': 'las la-dot-circle',
  'ellipsis-h': 'las la-ellipsis-h',
  'ellipsis-v': 'las la-ellipsis-v',
  'file-download': 'las la-file-download',
  'file-export': 'las la-file-export',
  'file-import': 'las la-file-import',
  'file-upload': 'las la-file-upload',
  fingerprint: 'las la-fingerprint',
  flag: 'las la-flag',
  'flag-checkered': 'las la-flag-checkered',
  'grip-horizontal': 'las la-grip-horizontal',
  'grip-lines': 'las la-grip-lines',
  'grip-lines-vertical': 'las la-grip-lines-vertical',
  'grip-vertical': 'las la-grip-vertical',
  hashtag: 'las la-hashtag',
  info: 'las la-info',
  'info-circle': 'las la-info-circle',
  medal: 'las la-medal',
  minus: 'las la-minus',
  'minus-circle': 'las la-minus-circle',
  'minus-square': 'las la-minus-square',
  plus: 'las la-plus',
  'plus-circle': 'las la-plus-circle',
  question: 'las la-question',
  search: 'las la-search',
  'search-minus': 'las la-search-minus',
  'search-plus': 'las la-search-plus',
  'share-alt': 'las la-share-alt',
  'share-alt-square': 'las la-share-alt-square',
  signal: 'las la-signal',
  star: 'las la-star',
  'star-half': 'las la-star-half',
  times: 'las la-times',
  x: 'las la-times',
  'times-circle': 'las la-times-circle',
  'toggle-off': 'las la-toggle-off',
  'toggle-on': 'las la-toggle-on',
  trophy: 'las la-trophy',
  user: 'las la-user',
  'user-alt': 'las la-user-alt',
  'user-circle': 'las la-user-circle',
  box: 'las la-box',
  boxes: 'las la-boxes',
  'clipboard-check': 'las la-clipboard-check',
  'clipboard-list': 'las la-clipboard-list',
  dolly: 'las la-dolly',
  'dolly-flatbed': 'las la-dolly-flatbed',
  pallet: 'las la-pallet',
  'shipping-fast': 'las la-shipping-fast',
  anchor: 'las la-anchor',
  bomb: 'las la-bomb',
  bookmark: 'las la-bookmark',
  directions: 'las la-directions',
  'fighter-jet': 'las la-fighter-jet',
  gavel: 'las la-gavel',
  helicopter: 'las la-helicopter',
  'life-ring': 'las la-life-ring',
  magnet: 'las la-magnet',
  male: 'las la-male',
  'map-marker': 'las la-map-marker',
  'map-marker-alt': 'las la-map-marker-alt',
  'map-pin': 'las la-map-pin',
  newspaper: 'las la-newspaper',
  parking: 'las la-parking',
  plane: 'las la-plane',
  restroom: 'las la-restroom',
  road: 'las la-road',
  rocket: 'las la-rocket',
  ship: 'las la-ship',
  'shopping-bag': 'las la-shopping-bag',
  'shopping-basket': 'las la-shopping-basket',
  'shopping-cart': 'las la-shopping-cart',
  snowplow: 'las la-snowplow',
  'street-view': 'las la-street-view',
  subway: 'las la-subway',
  'ticket-alt': 'las la-ticket-alt',
  'traffic-light': 'las la-traffic-light',
  train: 'las la-train',
  tram: 'las la-tram',
  umbrella: 'las la-umbrella',
  'utensil-spoon': 'las la-utensil-spoon',
  dharmachakra: 'las la-dharmachakra',
  ad: 'las la-ad',
  'envelope-open-text': 'las la-envelope-open-text',
  'funnel-dollar': 'las la-funnel-dollar',
  'mail-bulk': 'las la-mail-bulk',
  poll: 'las la-poll',
  'poll-h': 'las la-poll-h',
  'search-dollar': 'las la-search-dollar',
  'search-location': 'las la-search-location',
  divide: 'las la-divide',
  equals: 'las la-equals',
  'greater-than': 'las la-greater-than',
  'greater-than-equal': 'las la-greater-than-equal',
  'less-than': 'las la-less-than',
  'less-than-equal': 'las la-less-than-equal',
  'not-equal': 'las la-not-equal',
  'square-root-alt': 'las la-square-root-alt',
  'wave-square': 'las la-wave-square',
  'band-aid': 'las la-band-aid',
  biohazard: 'las la-biohazard',
  bong: 'las la-bong',
  'book-medical': 'las la-book-medical',
  brain: 'las la-brain',
  'briefcase-medical': 'las la-briefcase-medical',
  cannabis: 'las la-cannabis',
  capsules: 'las la-capsules',
  crutch: 'las la-crutch',
  diagnoses: 'las la-diagnoses',
  dna: 'las la-dna',
  'file-medical': 'las la-file-medical',
  'file-medical-alt': 'las la-file-medical-alt',
  'file-prescription': 'las la-file-prescription',
  'hospital-symbol': 'las la-hospital-symbol',
  'id-card-alt': 'las la-id-card-alt',
  joint: 'las la-joint',
  'laptop-medical': 'las la-laptop-medical',
  'mortar-pestle': 'las la-mortar-pestle',
  'notes-medical': 'las la-notes-medical',
  pager: 'las la-pager',
  pills: 'las la-pills',
  'prescription-bottle': 'las la-prescription-bottle',
  'prescription-bottle-alt': 'las la-prescription-bottle-alt',
  procedures: 'las la-procedures',
  'star-of-life': 'las la-star-of-life',
  syringe: 'las la-syringe',
  tablets: 'las la-tablets',
  teeth: 'las la-teeth',
  'teeth-open': 'las la-teeth-open',
  thermometer: 'las la-thermometer',
  tooth: 'las la-tooth',
  'user-nurse': 'las la-user-nurse',
  vial: 'las la-vial',
  vials: 'las la-vials',
  weight: 'las la-weight',
  'x-ray': 'las la-x-ray',
  'box-open': 'las la-box-open',
  'people-carry': 'las la-people-carry',
  sign: 'las la-sign',
  tape: 'las la-tape',
  'truck-loading': 'las la-truck-loading',
  'truck-moving': 'las la-truck-moving',
  drum: 'las la-drum',
  'drum-steelpan': 'las la-drum-steelpan',
  guitar: 'las la-guitar',
  'headphones-alt': 'las la-headphones-alt',
  napster: 'lab la-napster',
  'record-vinyl': 'las la-record-vinyl',
  soundcloud: 'lab la-soundcloud',
  spotify: 'lab la-spotify',
  cube: 'las la-cube',
  cubes: 'las la-cubes',
  'digital-tachograph': 'las la-digital-tachograph',
  futbol: 'las la-futbol',
  gem: 'las la-gem',
  'heart-broken': 'las la-heart-broken',
  lock: 'las la-lock',
  'lock-open': 'las la-lock-open',
  moon: 'las la-moon',
  ring: 'las la-ring',
  scroll: 'las la-scroll',
  'space-shuttle': 'las la-space-shuttle',
  toilet: 'las la-toilet',
  unlock: 'las la-unlock',
  'unlock-alt': 'las la-unlock-alt',
  backspace: 'las la-backspace',
  'blender-phone': 'las la-blender-phone',
  crown: 'las la-crown',
  'dumpster-fire': 'las la-dumpster-fire',
  'file-csv': 'las la-file-csv',
  'network-wired': 'las la-network-wired',
  signature: 'las la-signature',
  skull: 'las la-skull',
  'vr-cardboard': 'las la-vr-cardboard',
  'weight-hanging': 'las la-weight-hanging',
  alipay: 'lab la-alipay',
  'amazon-pay': 'lab la-amazon-pay',
  'apple-pay': 'lab la-apple-pay',
  'cart-plus': 'las la-cart-plus',
  'cc-amazon-pay': 'lab la-cc-amazon-pay',
  'cc-amex': 'lab la-cc-amex',
  'cc-apple-pay': 'lab la-cc-apple-pay',
  'cc-diners-club': 'lab la-cc-diners-club',
  'cc-discover': 'lab la-cc-discover',
  'cc-jcb': 'lab la-cc-jcb',
  'cc-mastercard': 'lab la-cc-mastercard',
  'cc-paypal': 'lab la-cc-paypal',
  'cc-stripe': 'lab la-cc-stripe',
  'cc-visa': 'lab la-cc-visa',
  'google-wallet': 'lab la-google-wallet',
  paypal: 'lab la-paypal',
  stripe: 'lab la-stripe',
  'stripe-s': 'lab la-stripe-s',
  democrat: 'las la-democrat',
  'flag-usa': 'las la-flag-usa',
  'person-booth': 'las la-person-booth',
  republican: 'las la-republican',
  'vote-yea': 'las la-vote-yea',
  ankh: 'las la-ankh',
  bible: 'las la-bible',
  cross: 'las la-cross',
  hamsa: 'las la-hamsa',
  hanukiah: 'las la-hanukiah',
  haykal: 'las la-haykal',
  jedi: 'las la-jedi',
  'journal-whills': 'las la-journal-whills',
  khanda: 'las la-khanda',
  menorah: 'las la-menorah',
  om: 'las la-om',
  pastafarianism: 'las la-pastafarianism',
  peace: 'las la-peace',
  pray: 'las la-pray',
  quran: 'las la-quran',
  'star-and-crescent': 'las la-star-and-crescent',
  'star-of-david': 'las la-star-of-david',
  torah: 'las la-torah',
  'yin-yang': 'las la-yin-yang',
  'temperature-high': 'las la-temperature-high',
  'temperature-low': 'las la-temperature-low',
  'galactic-republic': 'lab la-galactic-republic',
  'galactic-senate': 'lab la-galactic-senate',
  'jedi-order': 'lab la-jedi-order',
  meteor: 'las la-meteor',
  'old-republic': 'lab la-old-republic',
  'user-astronaut': 'las la-user-astronaut',
  'file-contract': 'las la-file-contract',
  'file-signature': 'las la-file-signature',
  passport: 'las la-passport',
  'user-lock': 'las la-user-lock',
  'user-shield': 'las la-user-shield',
  square: 'las la-square',
  'user-friends': 'las la-user-friends',
  'user-plus': 'las la-user-plus',
  users: 'las la-users',
  asterisk: 'las la-asterisk',
  'circle-notch': 'las la-circle-notch',
  'compact-disc': 'las la-compact-disc',
  slash: 'las la-slash',
  spinner: 'las la-spinner',
  'baseball-ball': 'las la-baseball-ball',
  'basketball-ball': 'las la-basketball-ball',
  'bowling-ball': 'las la-bowling-ball',
  'golf-ball': 'las la-golf-ball',
  'hockey-puck': 'las la-hockey-puck',
  quidditch: 'las la-quidditch',
  'table-tennis': 'las la-table-tennis',
  'volleyball-ball': 'las la-volleyball-ball',
  'cloud-sun-rain': 'las la-cloud-sun-rain',
  rainbow: 'las la-rainbow',
  'calendar-day': 'las la-calendar-day',
  'calendar-week': 'las la-calendar-week',
  'star-half-alt': 'las la-star-half-alt',
  'thermometer-empty': 'las la-thermometer-empty',
  'thermometer-full': 'las la-thermometer-full',
  'thermometer-half': 'las la-thermometer-half',
  'thermometer-quarter': 'las la-thermometer-quarter',
  'thermometer-three-quarters': 'las la-thermometer-three-quarters',
  'user-alt-slash': 'las la-user-alt-slash',
  'user-slash': 'las la-user-slash',
  'acquisitions-incorporated': 'lab la-acquisitions-incorporated',
  'critical-role': 'lab la-critical-role',
  'd-and-d': 'lab la-d-and-d',
  'd-and-d-beyond': 'lab la-d-and-d-beyond',
  'fantasy-flight-games': 'lab la-fantasy-flight-games',
  'penny-arcade': 'lab la-penny-arcade',
  'wizards-of-the-coast': 'lab la-wizards-of-the-coast',
  atlas: 'las la-atlas',
  'globe-africa': 'las la-globe-africa',
  'globe-americas': 'las la-globe-americas',
  'globe-asia': 'las la-globe-asia',
  'globe-europe': 'las la-globe-europe',
  'plane-arrival': 'las la-plane-arrival',
  'plane-departure': 'las la-plane-departure',
  child: 'las la-child',
  female: 'las la-female',
  'user-check': 'las la-user-check',
  'user-clock': 'las la-user-clock',
  'user-cog': 'las la-user-cog',
  'user-edit': 'las la-user-edit',
  'user-injured': 'las la-user-injured',
  'user-minus': 'las la-user-minus',
  'user-ninja': 'las la-user-ninja',
  'user-tag': 'las la-user-tag',
  'user-times': 'las la-user-times',
  'users-cog': 'las la-users-cog',
  'cloud-moon-rain': 'las la-cloud-moon-rain',
  'cloud-rain': 'las la-cloud-rain',
  'cloud-showers-heavy': 'las la-cloud-showers-heavy',
  'poo-storm': 'las la-poo-storm',
  smog: 'las la-smog',
  icicles: 'las la-icicles',
})

export type IconTypes = keyof typeof allIcons
