import { ModelDataExportSettings } from './ModelDataExportSettings'
import { ColumnDef } from '../../common/$models/ModelDef'
import { executeStringDefinedFunction } from '../../common/utils'
import { FindQuery } from '../../common/$models'

/**
 * Execute data exporting by exportSettingRecord (and filter)
 */
export class DataExporter {
  exportSettingRecord: ModelDataExportSettings
  downloadAsExcel: boolean
  filter: any
  data: any[]
  results: any[]
  searchKeyword: string
  exportFormatType: 'xlsx' | 'csv'

  constructor(
    exportSettingRecord: ModelDataExportSettings,
    filter: any,
    downloadAsExcel = true,
    searchKeyword = '',
    exportFormatType: 'xlsx' | 'csv' = 'xlsx',
  ) {
    this.exportSettingRecord = exportSettingRecord
    this.downloadAsExcel = true
    this.filter = filter
    this.searchKeyword = searchKeyword
    this.exportFormatType = exportFormatType
  }

  static async runStatic(
    exportSettingRecord: ModelDataExportSettings,
    filter: any,
    downloadAsExcel = true,
  ) {
    await new DataExporter(exportSettingRecord, filter, downloadAsExcel).run()
  }

  async run() {
    // 0. fetchする
    await this._fetchData()
    // 1. 変換処理前関数
    await this._execBeforeFormatFunction()
    // 2. 各行をFormat by definition
    await this._formatData()
    // 3. 変換処理後関数を実行
    await this._execAfterFormatFunction()
    // 4. 結果を
    if (this.downloadAsExcel) {
      await $core.$exportAsExcel.fromArrayOfObject({
        data: this.results,
        filename: this.exportSettingRecord.name,
        exportFormatType: this.exportFormatType,
      })
    }
  }

  /**
   * beforeFormatFunction 定義に従って、変換前の整形を実施
   */
  async _execBeforeFormatFunction() {
    if (
      this.exportSettingRecord.useBeforeFormatFunction !== true ||
      !this.exportSettingRecord.beforeFormatFunction
    ) {
      return
    }
    await executeStringDefinedFunction({
      functionString: `${this.exportSettingRecord.beforeFormatFunction}; return originalDataRows`,
      functionArgValues: { originalDataRows: this.data, instance: this },
      errorMessagePrefix: '[DataExporter._execBeforeFormatFunction()] ',
      successCallback: formatted => {
        this.data = formatted
      },
    })
  }

  /**
   * データをエクスポート定義に従って整形
   */
  async _formatData() {
    const res = []
    for (let i = 0; this.data.length > i; i++) {
      res.push(await this.__formatRowByDefinition(this.data[i]))
    }
    this.results = res
  }

  /**
   * 1行をフォーマットする by export definition
   * @param record
   */
  async __formatRowByDefinition(record: any): Promise<Record<string, any>> {
    let res = {}
    const fields = this.exportSettingRecord.fields
    for (let ii = 0; fields.length > ii; ii++) {
      const field = fields[ii]
      if (['direct', 'passThrough'].indexOf(field.behavior) >= 0) {
        res[
          field.behavior === 'passThrough' ? field.tCol : field.dataCol
        ] = this.___fetchValueWithDotConnectedNestedKey(field.tCol, record)
      } else if (field.behavior === 'func') {
        await executeStringDefinedFunction({
          functionString: `${field.tFunc}; return row`,
          functionArgValues: {
            row: res,
            sourceDataRow: record,
            allSourceDataRows: this.data,
          },
          errorMessagePrefix: `[DataExporter field.tFunc() (field.tCol: ${field.tCol})] `,
          successCallback: value => {
            res = value
          },
        })
      }
    }
    return res
  }

  ___fetchValueWithDotConnectedNestedKey(dottedKey: string, record) {
    const nestedKeys = dottedKey.split('.')
    try {
      // リレーション先のデータを持ってこれていないときはエラーになるので空文字列を返却する
      return nestedKeys.reduce((res, key) => {
        return (res === null ? record : res)[key]
      }, null)
    } catch (e) {
      console.error(e)
      return ''
    }
  }

  async _execAfterFormatFunction() {
    if (
      this.exportSettingRecord.useAfterFormatFunction !== true ||
      !this.exportSettingRecord.afterFormatFunction
    ) {
      return
    }
    this.results = await executeStringDefinedFunction({
      functionString: `${this.exportSettingRecord.afterFormatFunction}; return formattedDataRows`,
      functionArgValues: {
        originalDataRows: this.data,
        formattedDataRows: this.results,
        instance: this,
      },
      errorMessagePrefix: `[DataExporter.afterFormatFunction] `,
      successCallback: value => {
        this.data = value
      },
    })
  }

  get model() {
    return $core.$models[this.exportSettingRecord.targetModelName]
  }

  /**
   * データを取得する, 全件取得
   */
  async _fetchData() {
    const query: FindQuery = { filter: this.filter || {}, fields: ['*.*'], limit: -1 }
    if (this.searchKeyword) {
      query.search = this.searchKeyword
    }
    // dataFetchFunctionが指定されていればそれでfetchする
    if(this.exportSettingRecord.useDataFetchFunction && this.exportSettingRecord.dataFetchFunction){
      this.data = await executeStringDefinedFunction({
        functionString: `${this.exportSettingRecord.dataFetchFunction}`,
        functionArgValues: {
          filter: this.filter,
          dataExporterInstance: this,
        },
        errorMessagePrefix: `[DataExporter.dataFetchFunction] `,
      })
    }else{
      this.data = await this.model.find(query)
    }
    // reference columns のデータを取得するために
    await this.__fetchReferencedData()
  }

  /**
   * reference columns のデータを取得するために
   * 1. type === 'REFERENCE' の column def を取得する
   * 2. this.data の中から、取得するべきデータの値を取得する
   * 3. reference data をfind
   * 4. this.data の配列へ戻す
   */
  async __fetchReferencedData() {
    // 1. type === 'REFERENCE' の column def を取得する
    const referenceColumns: ColumnDef[] = this.model.colNames.reduce((res, colName) => {
      if (this.model.columns[colName].type === 'REFERENCE') {
        res.push(this.model.columns[colName])
      }
      return res
    }, [])
    // 2. this.data の中から、取得するべきデータの値を取得して結合する
    for (let i = 0; referenceColumns.length > i; i++) {
      const refCol = referenceColumns[i]
      const refColName = refCol.name
      const ref = refCol.referenceOfStore
      const refModel = $core.$models[ref.storeName]
      if (!ref || !refModel) {
        continue
      }
      // 3-1. 検索対象Valuesを抽出
      const queryValues = this.data.reduce((res, d) => {
        const value = d[refColName]
        if (value && res.indexOf(value) === -1) {
          res.push(value)
        }
        return res
      }, [])
      if (!queryValues.length) {
        return
      }
      // 3-2. 抽出したValuesで検索
      const refRecords = await refModel.find({
        filter: {
          [ref.key]: {
            _in: queryValues,
          },
        },
        limit: -1,
      })
      const refRecordsByReferencedKey = refRecords.reduce((res, d) => {
        res[d[ref.key]] = d
        return res
      }, {})
      // 4. this.data へマージ
      this.data = this.data.map(d => {
        d[refColName] = refRecordsByReferencedKey[d[refColName]]
        return d
      })
    }
  }
}
