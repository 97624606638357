<template>
  <div style="position: relative">
    <div class="d-flex align-items-center">
      <b-form-select
        class="flex-1"
        :modelValue="operator"
        @update:modelValue="updateOperator"
        :options="options"
        size="sm"
      ></b-form-select>
      <a role="button" @click="toggleSetting">
        <Ficon class="ml-2" type="ellipsis-h" />
      </a>
    </div>
    <div v-if="operator !== 'isnull' && operator !== 'isnotnull'">
      <div class="mt-1" v-if="isShowOneDateType">
        <b-form-select
          :modelValue="oneDateType"
          @update:modelValue="updateOneDateType"
          :options="oneDateTypes"
          size="sm"
        ></b-form-select>
      </div>
      <div class="mt-1 d-flex align-items-center" v-if="isShowRelationType">
        <b-form-select
          class="mr-1"
          :modelValue="dateRelationType"
          @update:modelValue="updateDateRelationType"
          :options="dateRelationTypes"
          size="sm"
        ></b-form-select>
        <b-form-input
          v-if="dateRelationPeriodType !== 'day' && dateRelationType !== 'this'"
          type="number"
          size="sm"
          :modelValue="relationPeriod"
          @update:modelValue="updateRelationPeriod"
        />
        <b-form-select
          :modelValue="dateRelationPeriodType"
          @update:modelValue="updateDateRelationPeriodType"
          :options="dateRelationPeriodTypes"
          size="sm"
        ></b-form-select>
      </div>
      <div class="mt-1 filter-calendar" v-if="renderCalendar === false">
        <component
          :is="dateOrTimePicker"
          :enable-seconds="enableSeconds"
          v-if="renderCalendar === false"
          style="width: 100%"
          :key="filterItemService.colDef.name + '_datepicker'"
          :modelValue="modelValue"
          @update:modelValue="change"
          :inline="true"
          :disabled="oneDateType !== 'custom_date' || operator === 'relative'"
          :common-attrs="{
            range: isRange,
          }"
          :defaultTime="defaultTime"
        />
      </div>
    </div>
    <FilterItemManager :visible="isVisibleSetting" @onDismiss="onDismissDropDown" />
  </div>
</template>
<script lang="ts">
import { inject } from 'vue'
import { FilterItemService } from '../../../FilterItemService'
import {
  FILTER_OPERATOR_FOR_DATE,
  ONE_DATE_TYPES,
  DATE_RELATION_TYPES,
  DATE_RELATION_PERIOD_TYPES,
} from '../../../FilterRuleService'
import FilterItemManager from '../FilterItemManager.vue'
import { ColumnDef } from '../../../../../../common/$models/ModelDef'

export default {
  props: {
    modelValue: {
      type: [String, Array],
      required: true,
    },
    initialOperator: {
      type: String,
    },
  },
  components: {
    FilterItemManager,
  },
  setup() {
    return {
      filterItemService: inject<FilterItemService<any>>('filterItemService'),
    }
  },
  data() {
    return {
      operator: null,
      isVisibleSetting: false,
      oneDateType: null,
      renderCalendar: false,
      dateRelationType: null,
      dateRelationPeriodType: null,
      relationPeriod: 1,
    }
  },
  computed: {
    isRange() {
      return this.operator === 'between' || this.dateRelationPeriodType !== 'day'
    },
    defaultTime() {
      if (this.isRange) {
        return ['00:00:00', '23:59:59']
      } else {
        return '00:00:00'
      }
    },
    label() {
      return this.filterItemService.colDef.label || this.filterItemService.colDef.name
    },
    colType(): ColumnDef['type'] {
      return this.filterItemService.colDef.type
    },
    enableSeconds() {
      return this.colType !== 'DATEONLY'
    },
    isShowRelationType() {
      return this.operator === 'relative'
    },
    isShowOneDateType() {
      return (
        this.operator === 'eq' ||
        this.operator === 'lt' ||
        this.operator === 'gt' ||
        this.operator === 'lte' ||
        this.operator === 'gte'
      )
    },
    dateOrTimePicker() {
      if (this.colType === 'DATEONLY') {
        return 'datepicker'
      } else {
        return 'DatetimePicker'
      }
    },
    options() {
      // { value: xxxx'', text: 'xxxxx' }のような形で返す
      return Object.keys(FILTER_OPERATOR_FOR_DATE).map((operator) => {
        return {
          value: operator,
          text: FILTER_OPERATOR_FOR_DATE[operator],
        }
      })
    },
    oneDateTypes() {
      return Object.keys(ONE_DATE_TYPES).map((type) => {
        return {
          value: type,
          text: ONE_DATE_TYPES[type],
        }
      })
    },
    dateRelationTypes() {
      return Object.keys(DATE_RELATION_TYPES).map((type) => {
        return {
          value: type,
          text: DATE_RELATION_TYPES[type],
        }
      })
    },
    dateRelationPeriodTypes() {
      return Object.keys(DATE_RELATION_PERIOD_TYPES).map((type) => {
        return {
          value: type,
          text: DATE_RELATION_PERIOD_TYPES[type],
        }
      })
    },
  },
  created() {
    this.operator = this.initialOperator
      ? this.initialOperator
      : this.options.length > 0
      ? this.options[0].value
      : null
    this.oneDateType = 'custom_date'
    this.dateRelationType = 'this'
    this.dateRelationPeriodType = 'day'
  },
  methods: {
    toggleSetting() {
      this.isVisibleSetting = !this.isVisibleSetting
    },
    change(value) {
      if (value && value !== 'Invalid Date') {
        this.$emit('update:modelValue', {
          value: Array.isArray(value)
            ? [
                this.filterItemService.formatDate(value[0]),
                this.filterItemService.formatDate(value[1]),
              ]
            : this.filterItemService.formatDate(value),
          valueType: this.oneDateType,
          operator: this.operator,
        })
      }
    },
    updateOneDateType(value) {
      this.oneDateType = value
      if (value !== 'custom_date') {
        this.$emit('update:modelValue', {
          value: this.filterItemService.calcOneDateFromType(value),
          valueType: this.oneDateType,
          operator: this.operator,
        })
      }
    },
    updateOperator(operator) {
      this.operator = operator
      this.oneDateType = 'custom_date'
      this.renderCalendar = true
      if (this.operator === 'between') {
        this.$emit('update:modelValue', {
          value: ['', ''],
          valueType: this.oneDateType,
          operator,
        })
      } else if (this.operator === 'relative') {
        this.updatePropertyModelValue()
      } else if (this.operator === 'isnull' || operator === 'isnotnull') {
        this.$emit('update:modelValue', {
          value: '',
          valueType: this.oneDateType,
          operator,
        })
      } else {
        this.$emit('update:modelValue', {
          value: Array.isArray(this.modelValue) ? '' : this.modelValue,
          valueType: this.oneDateType,
          operator,
        })
      }
      setTimeout(() => {
        this.renderCalendar = false
      }, 1)
    },
    updateDateRelationType(newValue) {
      if (newValue !== 'this') {
        this.renderCalendar = true
      }
      this.updateProperty('dateRelationType', newValue)
      setTimeout(() => {
        this.renderCalendar = false
      }, 50)
    },
    updateRelationPeriod(newValue) {
      this.updateProperty('relationPeriod', newValue)
    },
    updateDateRelationPeriodType(newValue) {
      this.dateRelationPeriodType = newValue
      if (newValue === 'day') {
        this.relationPeriod = 1
      } else {
        this.renderCalendar = true
      }
      this.updateProperty('dateRelationPeriodType', newValue)
      setTimeout(() => {
        this.renderCalendar = false
      }, 50)
    },
    updateProperty(propertyName, newValue) {
      this[propertyName] = newValue
      this.updatePropertyModelValue()
    },
    updatePropertyModelValue() {
      this.$emit('update:modelValue', {
        value: this.filterItemService.formatDateFromRelation(
          this.dateRelationType,
          this.dateRelationPeriodType,
          this.relationPeriod,
        ),
        valueType: this.oneDateType,
        operator: this.operator,
      })
    },
    onDismissDropDown() {
      this.$emit('onDismissDropdown')
    },
  },
}
</script>
