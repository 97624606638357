import ComposableModelForm from './components/ComposableModelForm.vue'
import ModelFormGroupedEditLoopWrapper from '../../../plugins/ComposableModelForm/front/components/ModelFormGroupedEditLoopWrapper.vue'
import ComposableModelInput from './components/ComposableModelInput.vue'
import CCModelFormSaveButton from '../../../plugins/ComposableModelForm/front/components/CCModelFormSaveButton.vue'
import ModelFormOneRecordDeleteButton from '../../../plugins/ComposableModelForm/front/components/ModelFormOneRecordDeleteButton.vue'
import RichRadioSelect from '../../RichFormComponents/RichRadioSelect.vue'
import RichCheckboxes from '../../RichFormComponents/RichCheckboxes.vue'
import ColorfulSelect from '../../RichFormComponents/ColorfulSelect.vue'
import { defineAsyncComponent } from 'vue'
export const initComposableModelFormComponents = () => {
  $core.VueClass.component('ComposableModelForm', ComposableModelForm)
  $core.VueClass.component('ModelFormGroupedEditLoopWrapper', ModelFormGroupedEditLoopWrapper)
  $core.VueClass.component('ComposableModelInput', ComposableModelInput)
  $core.VueClass.component('CCModelFormSaveButton', CCModelFormSaveButton)
  $core.VueClass.component('ModelFormOneRecordDeleteButton', ModelFormOneRecordDeleteButton)
  $core.VueClass.component('RichRadioSelect', RichRadioSelect)
  $core.VueClass.component('RichCheckboxes', RichCheckboxes)
  $core.VueClass.component('ColorfulSelect', ColorfulSelect)
  $core.VueClass.component(
    'ModelSearchSelectInput',
    defineAsyncComponent(
      () => import('../ModelColumnInputs/front/components/ModelSearchSelectInput.vue'),
    ),
  )
}
