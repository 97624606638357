<template>
  <div class="modal-container">
    <b-modal
      v-for="(modal, modalId) in modals"
      v-bind="modal.modalProps"
      :key="modalId"
      :id="`modal-${modalId}`"
      :ref="`modal-${modalId}`"
      :showOnLoad="true"
      @hide="modal.modalProps.onClose"
      :dialog-class="[
        !!modal.componentType
          ? 'core-modal-model-component-element'
          : 'core-modal-model-component-function',
        ModalTypeMap[modal.componentType]
          ? `core-modal-type--${ModalTypeMap[modal.componentType]}`
          : '',
        modal.componentProps && modal.componentProps.modelName
          ? `core-modal-modelname--${modal.componentProps.modelName}`
          : '',
        modal.modalProps?.dialogClass || '',
      ]"
    >
      <component
        :is="modal.componentType"
        v-bind="modal.componentProps"
        v-if="!!modal.componentType"
        :modalId="modal.modalId"
        :ref="modal.componentType"
      />
      <component
        :is="modal.component"
        :ref="modal.component.name || `modal-content-${modalId}`"
        v-else
        v-bind="modal.componentProps"
        :modalId="modal.modalId"
      />
    </b-modal>
  </div>
</template>

<script lang="ts">
import ModelView from '../ModelView/fireModelViewPage.vue'
import ListView from '../ModelIndex/ModelIndexDirectus.vue'
import EditView from '../ModelForm/ModelEditPage.vue'
import CreateView from '../ModelForm/ModelCreatePage.vue'
import { ModalTypeMap } from './$modals'

export default {
  components: {
    ModelView,
    EditView,
    ListView,
    CreateView,
  },
  mounted() {
    // $core.$modals.$vm へ this を投入することで、classよりVueコンポーネントを参照可能にする
    $core.$modals.$vm = this
  },
  data() {
    return {
      visibleStateByModalId: {},
      showBool: true,
    }
  },
  computed: {
    modals() {
      return $core.$modals.modalsByModalId
    },
    ModalTypeMap() {
      return ModalTypeMap
    },
  },
  methods: {
    showTheModal(modalId) {
      this.visibleStateByModalId[modalId] = true
      this.$nextTick(() => {
        setTimeout(() => {
          const modal = this.$refs[modalId][0]
          if (modal) {
            this.visibleStateByModalId[modalId] = true
          }
        }, 500)
      })
    },
  },
}
</script>

<style lang="scss">
.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}

@media print {
  .modal-dialog {
    width: 100% !important;
    max-width: 100% !important;
    margin-top: 0 !important;
    .modal-header {
      display: none;
    }
  }
}
</style>
