<template>
  <ficon
    v-if="icon"
    class="app-menu-icon d-inline-block"
    :type="icon"
  />
</template>
<script>
export default {
  props: {
    icon: {},
  },
}
</script>
