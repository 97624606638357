import axios, { AxiosRequestConfig } from 'axios'

export const filenameSeparator = '--:--:--:--'

export const getOriginalFileNameFromFilePath = (fullFilePath) => {
  // `/`区切りでsplitして最後の要素を返す
  return fullFilePath.split('/').pop()
}

/**
 * ファイルパスを生成する関数
 */
export interface FilePathGenerateFunction {
  ({
    fileName,
    fileExtension,
    pathPrefix,
  }: {
    fileName: string
    fileExtension: string
    pathPrefix: string | null
  }): string
}

const getFileExtensionFromFileObject = (file: File) => {
  if (!file?.name) {
    throw new Error('Invalid file object')
  }
  return file.name.split('.').pop()
}

class FileManager {
  splitKey = '--:--:--:--'

  async upload(
    file,
    filePath: string = null,
    uploadAxiosOptions: Partial<AxiosRequestConfig> = null,
    filePathPrefix: string = null,
    filePathGenerateFunction: FilePathGenerateFunction = null,
  ): Promise<any> {
    // filePathが指定されていないか、またはfilePathPrefixとかfilePathGenerateFunctionが設定されている場合にはpathをGenerateする
    if (!filePath || filePathPrefix || filePathGenerateFunction) {
      filePath = this.generateDefaultFilePath(file, filePathPrefix, filePathGenerateFunction)
    }
    const fileType = file.type || file.name.substr(file.name.lastIndexOf('.')).replace('.', '')
    const { raw } = await this._getSignedUrlForPutObject(filePath, fileType)
    const uploadResult = await this._uploadFileWithSignedUrl(
      file,
      raw,
      fileType,
      uploadAxiosOptions,
    )
    // filePathとuploadResultawaitを返す
    return {
      filePath,
      uploadResult,
    }
  }

  getFileExtensionFromFileObject(file: File) {
    return getFileExtensionFromFileObject(file)
  }

  generateDefaultFilePath(
    file: File,
    filePathPrefix: string = null,
    filePathGenerateFunction: FilePathGenerateFunction = null,
  ) {
    // 1. ファイル名 及び 拡張子を取得
    const fileName = file.name
      .normalize('NFC')
      .substr(0, file.name.lastIndexOf('.'))
      .replace(/&/g, '＆')
      .replace(/\//g, '／')
    const fileExtension = this.getFileExtensionFromFileObject(file)

    // 2. pathPrefix を 決定
    filePathPrefix = filePathPrefix || 'u'

    // 3. pathを生成
    // 3-1. 関数の場合を優先にする
    if (filePathGenerateFunction) {
      return filePathGenerateFunction({
        fileName,
        fileExtension,
        pathPrefix: filePathPrefix,
      })
    }

    // 3-2. default
    return `${filePathPrefix}/${$core.$utils.generateStrongTimeStampString()}/${fileName}.${fileExtension}`
  }

  getFileNameAvailableAsPathString(file: File) {
    return file.name.substr(0, file.name.lastIndexOf('.')).replace(/&/g, '＆').replace(/\//g, '／')
  }

  async _getSignedUrlForPutObject(filePath: string, fileType: string): Promise<any> {
    return await $core.$directusSdk.transport.get(
      `/core/getSignedUrlForS3PutObject?filePath=${filePath}&ContentType=${fileType}`,
    )
  }

  async _uploadFileWithSignedUrl(
    file,
    signedUrl: string,
    fileType: string,
    uploadAxiosOptions: Partial<AxiosRequestConfig> = null,
  ): Promise<any> {
    return await axios.put(signedUrl, file, {
      ...(uploadAxiosOptions || {}),
      headers: { 'Content-Type': fileType },
    })
  }

  async bulkUploadFiles(
    files: File[],
    filePathPrefix: string = null,
    filePathGenerateFunction: FilePathGenerateFunction = null,
  ): Promise<{
    failedFileNames: string[]
    uploaded: { fileName: string; filePath: string }[]
  }> {
    const uploadingPromises = []
    const failedFileNames = []
    const uploaded = []
    for (const key in files) {
      const file = files[key]
      const fileName = file.name
      const filePath = this.generateDefaultFilePath(file, filePathPrefix, filePathGenerateFunction)
      // 失敗してもPromise all でコケないように...
      uploadingPromises.push(
        this.upload(file, filePath)
          .then(() => {
            uploaded.push({ fileName, filePath: filePath })
          })
          .catch((error) => {
            console.error(error)
            failedFileNames.push(fileName)
            return true
          }),
      )
    }
    await Promise.all(uploadingPromises)
    return {
      failedFileNames,
      uploaded,
    }
  }
}

export const $fileManager = new FileManager()
