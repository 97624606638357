<template>
  <div :class="wrapperClass">
    <DataListQueryFilterGroupToggleItem
      v-for="(g, index) in filterGroups"
      :key="index"
      class="cursor-pointer"
      :itemTag="itemTag"
      :itemClass="itemClass"
      :itemClassActive="itemClassActive"
      :itemChildTag="itemChildTag"
      :itemChildClass="itemChildClass"
      :itemChildClassActive="itemChildClassActive"
      :filterGroupItem="g"
      :filterGroupKey="filterGroupKey"
      :isActive="activeIndex === index"
      @click="() => (activeIndex = index)"
    />
  </div>
</template>
<script lang="ts">
import { registerComposableComponentSettings } from '../../../../ComposableComponents'
import { inject, PropType } from 'vue'
import { ComposableDataListService } from '../../ComposableDataListService'
import { ColumnDefByColName } from '../../../../../common/$models/ModelDef'
import DataListQueryFilterGroupToggleItem from './DataListQueryFilterGroupToggleItem.vue'
import {
  getComposableDataListFromSettingColumnInstance
} from '../../patrials/dataListComposableColumnNameSelectionSettingColumnDef'
import { codeInputCommonAttrs } from '../../../../../common/$models'

const name = 'DataListQueryFilterGroupToggle'

const groupingStyle = {
  tabs: {
    label: 'タブ',
    styles: {
      wrapperClass: 'nav nav-tabs',
      itemTag: 'span',
      itemClass: 'nav-item',
      itemClassActive: '',
      itemChildTag: 'span',
      itemChildClass: 'nav-link',
      itemChildClassActive: 'nav-link active',
    },
  },
  pills: {
    label: 'Pill',
    styles: {
      wrapperClass: 'nav nav-pills',
      itemTag: 'span',
      itemClass: 'nav-item',
      itemClassActive: '',
      itemChildTag: 'span',
      itemChildClass: 'nav-link',
      itemChildClassActive: 'nav-link active',
    },
  },
  // buttons: {
  //   label: 'Pill',
  // },
}

const customStylesCols: ColumnDefByColName = {
  wrapperClass: { type: 'STRING' },
  itemTag: { type: 'STRING' },
  itemClass: { type: 'STRING' },
  itemClassActive: { type: 'STRING' },
  itemChildTag: { type: 'STRING' },
  itemChildClass: { type: 'STRING' },
  itemChildClassActive: { type: 'STRING' },
}

const defaultFilterKeyName = 'tabToggleFilterGroup'

const configColumns: ColumnDefByColName = {
  filterGroups: {
    label: '検索条件切り替えグループ (タブ)',
    type: 'ARRAY_OF_OBJECT',
    columns: {
      label: {
        label: 'ラベル',
        type: 'STRING',
        inputHelpText: 'HTMLが利用可能です'
      },
      showCount: {
        label: '件数表示',
        type: 'BOOLEAN',
      },
      countClass: {
        label: '件数表示 class',
        type: 'STRING',
        inputHelpText: 'デフォルト値: badge rounded-pill bg-danger',
        enableIf: (row) => row.showCount,
      },
      countClassWhenZero: {
        label: '件数表示 class (0件の場合)',
        type: 'STRING',
        inputHelpText: 'デフォルト値: badge rounded-pill bg-light text-dark',
        enableIf: (row) => row.showCount,
      },
      initActive: {
        label: '読み込み時アクティブ',
        type: 'BOOLEAN',
      },
      filteringCondition: {
        label: 'フィルター条件',
        type: 'TEXT',
        inputComponent: {
          template: '<FilterResultDisplayContainer v-if="modelName" :emit-value-as-object="true" :collectionName="modelName"/>',
          computed: {
            modelName() {
              const composableDataList = getComposableDataListFromSettingColumnInstance(
                this,
                'ComposableDataList',
              )
              return composableDataList?.configuredProps?.modelName || ''
            }
          },
        },
        editCallback: ({ row, newValue, oldValue }) => {
          if (newValue !== oldValue && newValue) {
            row.__func__filter = `return ${JSON.stringify(newValue, null, 2)}`
          }
          return row
        },
        enableIf: (row) => !row.useFilteringConditionFunction,
      },
      useFilteringConditionFunction: {
        hideLabel: true,
        label: '関数でフィルター条件を定義する',
        type: 'BOOLEAN',
      },
      __func__filter: {
        enableIf: (row) => !!row.useFilteringConditionFunction,
        label: 'フィルター条件 (関数)',
        type: 'TEXT',
        inputAttrs: {
          ...codeInputCommonAttrs,
        },
        afterComponent: `<div class="small">変数 <code>thisInstance</code> が利用可能です。 <pre class="bg-light p-1">// 設定例:
return {
  name: {
    _contains: 'somestring',
  }
}</pre></div>`,
      },
    },
  },
  filterConditionKeyName: {
    label: '検索条件キー名',
    type: 'STRING',
    defaultValue: defaultFilterKeyName,
  },
  groupingStyle: {
    type: 'STRING',
    label: 'グループ化スタイル',
    selections: () => Object.keys(groupingStyle),
    customLabel: (val) => groupingStyle[val]?.label || val,
    editCallback({ row, newValue, oldValue }) {
      if (newValue !== oldValue) {
        Object.keys(customStylesCols).map((key) => {
          row[key] = groupingStyle[newValue]?.styles?.[key] || ''
        })
      }
      return row
    },
  },
  ...customStylesCols,
}

export type DataListQueryFilterGroupToggleFilterGroupItem = {
  label: string
  showCount: boolean
  initActive: boolean
  __func__filter?: string
}

registerComposableComponentSettings(name, {
  label: 'フィルターグループ',
  configColumns,
})

export default {
  name,
  components: {
    DataListQueryFilterGroupToggleItem,
  },
  props: {
    wrapperClass: {},
    itemTag: {},
    itemClass: {},
    itemClassActive: {},
    itemChildTag: {},
    itemChildClass: {},
    itemChildClassActive: {},
    filterGroups: {
      type: Array as PropType<DataListQueryFilterGroupToggleFilterGroupItem[]>,
      default: () => [],
    },
    filterConditionKeyName: {
      type: String,
      default: defaultFilterKeyName,
    },
  },
  data() {
    return {
      activeIndex: this.filterGroups.findIndex((g) => g.initActive),
    }
  },
  computed: {
    filterGroupKey(): string {
      return typeof this.filterConditionKeyName === 'string' && this.filterConditionKeyName.length > 0
        ? this.filterConditionKeyName
        : defaultFilterKeyName
    },
  },
  setup() {
    return {
      ComposableDataListServiceInstance: inject<ComposableDataListService>(
        'ComposableDataListServiceInstance',
      ),
    }
  },
}
</script>
