<template>
  <div class="fallback-model-page fallback-model-page--edit-or-create fallback-model-page--edit">
    <ModelEditPage
      :modelName="modelName"
      :record="record"
      :virtualModelName="virtualModelName"
      :columns="virtualModelColumns"
      ref="ModelPage"
    />
  </div>
</template>

<script lang="ts">
import ModelEditPage from '../../../ModelForm/ModelEditPage.vue'
import { checkModelFormHasChanged } from '../checkModelFormHasChanged'

export default {
  components: { ModelEditPage },
  data() {
    return {
      record: {},
    }
  },
  computed: {
    modelName() {
      return this.$route.params.fallback_model
    },
    model() {
      return $core.$models[this.modelName]
    },
    virtualModelName() {
      return this.$route.query.virtualModel
    },
    virtualModel() {
      return $core.$virtualModels[this.virtualModelName] || null
    },
    virtualModelColumns() {
      return this.virtualModel ? this.virtualModel.colNames : null
    },
  },
  async beforeRouteLeave(to, from, next) {
    checkModelFormHasChanged(this, next)
  },
}
</script>
