const defaultTTLSec = 86400 // 1 day

/**
 * LocalStorageに保存するアイテムのインターフェース
 */
interface LsCacheItem {
  /**
   * 有効期限のタイムスタンプ (ミリ秒)
   */
  expireAt: number
  /**
   * 保存する値
   */
  value: any
}

/**
 * # $core.$lsCache
 *
 * ## 概要
 * `$core.$lsCache` は、TTL (Time to Live) 付きでデータをブラウザの LocalStorage に保存・取得するためのサービスです。
 * LocalStorage は簡易的なデータ保存に便利ですが、有効期限を設定することができません。
 * `$core.$lsCache` を使用することで、有効期限付きのデータ保存が可能になり、一時的なキャッシュやユーザー設定の保存などに活用できます。
 *
 * ## ユースケース
 * - ユーザー設定の保存: ユーザーのテーマ設定や通知設定など、比較的長期的に保存する必要があるデータを有効期限付きで保存
 * - API レスポンスのキャッシュ: API から取得したデータをキャッシュすることで、API へのリクエスト回数を減らし、パフォーマンスを向上
 * - 一時的なデータの保存: ログイン状態やカート内の商品など、ブラウザセッションが有効な間だけ保存する必要があるデータを有効期限付きで保存
 *
 * ## メソッド
 * - `set(key: string, val: any, TTLInSeconds: number = defaultTTLSec): boolean`: 指定されたキーと値を、指定されたTTLでLocalStorageに保存します。
 *   - `key`: 保存するデータのキー
 *   - `val`: 保存する値
 *   - `TTLInSeconds`: データの有効期限 (秒), デフォルトは `defaultTTLSec` (1日)
 *   - 返り値: 保存に成功した場合は `true`, 失敗した場合は `false` を返します。
 * - `get(key: string): any`: 指定されたキーのデータをLocalStorageから取得します。有効期限が切れている場合は、`null` を返します。
 *   - `key`: 取得するデータのキー
 *   - 返り値: 保存されている値, 有効期限が切れている場合は `null`, 保存されていない場合は `''` (空文字)
 * - `clear(key: string): void`: 指定されたキーのデータをLocalStorageから削除します。
 *   - `key`: 削除するデータのキー
 * - `getWithFetchFunc(key: string, fetchFunction: Function, TTLInSeconds: number = defaultTTLSec): Promise<any>`: データを取得する際に、キャッシュが存在しない場合は指定された関数を実行してデータを取得し、キャッシュに保存します。
 *   - `key`: 保存するデータのキー
 *   - `fetchFunction`: キャッシュが存在しない場合に実行される関数。データ取得処理を実装します。
 *   - `TTLInSeconds`: データの有効期限 (秒), デフォルトは `defaultTTLSec` (1日)
 *   - 返り値: 取得したデータ, キャッシュに保存されている場合はキャッシュされたデータを返します。
 *
 * @coreDocPath $core/30.utils/306.lsCache
 */
export class LsCache {
  /**
   * TTL付きでLocalStorageに保存
   * @param key 保存するデータのキー
   * @param val 保存する値
   * @param TTLInSeconds データの有効期限 (秒), デフォルトは `defaultTTLSec` (1日)
   * @returns 保存に成功した場合は `true`, 失敗した場合は `false` を返します。
   *
   * @example
   * ```ts
   * // 24時間保持
   * $core.$lsCache.set('userSettings', { theme: 'dark', notifications: true }, 60 * 60 * 24)
   *
   * // 無期限
   * $core.$lsCache.set('apiKey', 'your_api_key', -1)
   * ```
   */
  set(key: string, val: any, TTLInSeconds: number | -1 = defaultTTLSec): boolean {
    const item: LsCacheItem = {
      expireAt: TTLInSeconds === -1 ? -1 : this._now + TTLInSeconds * 1000,
      value: val,
    }
    try {
      localStorage.setItem(key, JSON.stringify(item))
      return true
    } catch (e) {
      $core.$errorReporter.report(e, this)
      return false
    }
  }

  /**
   * 現在のタイムスタンプ (ミリ秒) を取得します
   * @private
   */
  private get _now(): number {
    return new Date().getTime()
  }

  /**
   * LocalStorageから取得, オブジェクトにparseしたものを返す
   * TTL(有効期限) が切れている場合は、空文字を返す
   * @param key 取得するデータのキー
   * @returns 保存されている値, 有効期限が切れている or 保存されていない場合は `''` (空文字)
   *
   * @example
   * ```ts
   * const userSettings = $core.$lsCache.get('userSettings')
   * if (userSettings) {
   *   // userSettingsオブジェクトが存在する (有効期限内)
   *   console.log(userSettings.theme)
   * } else {
   *   // userSettingsオブジェクトは存在しない, もしくは有効期限切れ
   * }
   * ```
   */
  get(key: string): any {
    try {
      const val = localStorage?.getItem(key) || ''
      if (['undefined', 'null', ''].indexOf(val) >= 0) {
        return ''
      }
      const cached: LsCacheItem = JSON.parse(val)
      // キャッシュが有効期限以内であれば
      if (cached.expireAt === -1 || cached.expireAt >= this._now) {
        return cached.value
      }
      // 有効期限切れのデータは削除する
      this.clear(key)
      return '' // expired
    } catch (e) {
      $core.$errorReporter.report(e, this)
      return '' // failed somehow
    }
  }

  /**
   * LocalStorageから削除
   * @param key 削除するデータのキー
   *
   * @example
   * ```ts
   * $core.$lsCache.clear('userSettings')
   * ```
   */
  clear(key: string): void {
    try {
      localStorage.removeItem(key)
    } catch (e) {
      console.error(e)
    }
  }

  /**
   * set と get をまとめて行うときに利用
   * @param key 保存するデータのキー
   * @param fetchFunction キャッシュが存在しない場合に実行される関数。データ取得処理を実装します。
   * @param TTLInSeconds データの有効期限 (秒), デフォルトは `defaultTTLSec` (1日)
   * @returns 取得したデータ, キャッシュに保存されている場合はキャッシュされたデータを返します。
   *
   * @example
   * ```ts
   * // APIからデータを取得する関数
   * const fetchUserData = async () => {
   *   const res = await $core.$axios.get('/users/me')
   *   return res.data
   * }
   *
   * // ユーザーデータを取得する
   * const userData = await $core.$lsCache.getWithFetchFunc('userData', fetchUserData, 60 * 60)
   * ```
   */
  async getWithFetchFunc(key: string, fetchFunction: Function, TTLInSeconds: number = defaultTTLSec): Promise<any> {
    const cached = this.get(key)
    if (cached) {
      return cached
    }
    const fetched = await fetchFunction()
    this.set(key, fetched, TTLInSeconds)
    return fetched
  }
}

/**
 * LsCache のインスタンス
 */
export const $lsCache = new LsCache()
