import dayjs, {Dayjs} from 'dayjs'
import ja from 'dayjs/locale/ja'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import relativeTime from 'dayjs/plugin/relativeTime'

dayjs.locale(ja)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)

// Default timezone to Asia/Tokyo
dayjs.tz.setDefault(process.env.DEFAULT_DAYJS_TIMEZONE || 'Asia/Tokyo')
export const $dayjs = dayjs

export {
  Dayjs
}
