import { FilterRuleService } from './FilterRuleService'
import { FilterItemService } from './FilterItemService'
import { FilterControlsService } from './FilterControlsService'
import { ref, Ref } from 'vue'
import { ColumnDef } from '../../../common/$models/ModelDef'

/**
 * FilterGroupService
 * @description
 * FilterGroupを管理するサービス(配下のFilterGroupまたはFilterRuleを管理するサービス)
 */

export class FilterGroupService {
  public filterObjects = []
  public filterLogic: 'and' | 'or' = 'and'
  public objectType = 'group'
  public isFirstObject: boolean = false
  public isSecondObject: boolean = false
  FilterControlsService: FilterControlsService
  parentFilterGroupService: FilterGroupService | null = null
  public hierarchyFromControlService: number = 1
  public isFocus: boolean = false

  constructor(
    filterControlsService: FilterControlsService,
    isFirstObject: boolean = false,
    isSecondObject: boolean = false,
    parentFilterGroupService: FilterGroupService | null = null,
    hierarchyFromControlService = 1,
    isFocus: boolean = false,
  ) {
    this.filterLogic = 'and'
    this.FilterControlsService = filterControlsService
    this.isFirstObject = isFirstObject
    this.isSecondObject = isSecondObject
    this.parentFilterGroupService = parentFilterGroupService
    this.hierarchyFromControlService = hierarchyFromControlService
    this.isFocus = isFocus
  }

  getFilterRuleWithColName(colName) {
    return this.filterObjects.find((filterObject) => {
      return filterObject.objectType === 'rule' && filterObject.colDef.name === colName
    })
  }

  appendFilterRuleWithColName(colNamePath: string[]) {
    const isFirst = this.filterObjects.length == 0
    const isSecond = this.filterObjects.length == 1
    const filterItemService = new FilterItemService({
      columnNamePathsFromRootColumnDef: colNamePath,
      filterItemType: 'custom', // 高度なフィルタ 内 であるということ
      filterControlsService: this.FilterControlsService,
      filterGroupService: this,
      isFirstObject: isFirst,
      isSecondObject: isSecond,
      isFocus: false
    })
    filterItemService.selectColumn(colNamePath)
    this.filterObjects.push(filterItemService)
    this.FilterControlsService.refreshFilter()
  }

  /**
   * フィルタ (FilterItemService) を新しく追加する場合の、カラム名のパスを返す
   * - ロジックとしては、どのカラムでも良い
   */
  get appendFilterInitColumnPath(): string[] {
    return [Object.keys(this.FilterControlsService.columns)[0]]
  }

  appendFilterRule() {
    this.appendFilterRuleWithColName(this.appendFilterInitColumnPath)
  }

  appendFilterGroup() {
    // this.FilterControlsServiceから３階層以上のFilterGroupServiceを作ることを禁止する
    if (this.hierarchyFromControlService >= 3) {
      return
    }
    const isFirst = this.filterObjects.length == 0
    const isSecond = this.filterObjects.length == 1
    const hierarchyFromControlService = this.hierarchyFromControlService + 1
    const filterGroupService = new FilterGroupService(
      this.FilterControlsService,
      isFirst,
      isSecond,
      this,
      hierarchyFromControlService,
    )
    this.filterObjects.push(filterGroupService)
    this.FilterControlsService.refreshFilter()
  }

  updateOperator(operator) {
    this.parentFilterGroupService.filterLogic = operator
    this.FilterControlsService.refreshFilter()
  }

  removeFilter() {
    this.parentFilterGroupService.filterObjects.splice(
      this.parentFilterGroupService.filterObjects.indexOf(this),
      1,
    )
    this.refreshOrder()
    this.FilterControlsService.refreshFilter()
  }

  refreshOrder() {
    this.parentFilterGroupService.filterObjects.forEach((filterObject, index) => {
      if (index === 0) {
        filterObject.isFirstObject = true
        filterObject.isSecondObject = false
      } else if (index === 1) {
        filterObject.isFirstObject = false
        filterObject.isSecondObject = true
      } else {
        filterObject.isFirstObject = false
        filterObject.isSecondObject = false
      }
    })
  }

  duplicateFilter() {
    const isFirst = this.parentFilterGroupService.filterObjects.length == 0
    const isSecond = this.parentFilterGroupService.filterObjects.length == 1
    const hierarchyFromControlService = this.hierarchyFromControlService
    const filterGroupService = new FilterGroupService(
      this.FilterControlsService,
      isFirst,
      isSecond,
      this.parentFilterGroupService,
      hierarchyFromControlService,
    )
    this.parentFilterGroupService.filterObjects.splice(
      this.parentFilterGroupService.filterObjects.indexOf(this) + 1,
      0,
      filterGroupService,
    )
    this.FilterControlsService.refreshFilter()
  }
}
