<template>
  <div>
    <model-index-directus
      v-bind="modelIndexBindValues"
    />
    <div class="text-center mt-3">
      <span v-if="isCreatable" class="btn btn-primary ml-2" v-single-click="openCreateModal">
        <ficon type="plus" />
        新規追加
      </span>
    </div>
  </div>
</template>

<style lang="scss"></style>

<script lang="ts">
import { ColumnDef, ColumnTypes } from '../../../common/$models/ModelDef'
import { PropType } from 'vue'
import { ModelFactory } from '../../../common/$models'

export default {
  name: 'relationshipOneToMany',
  props: {
    modelValue: { required: true, default: [] },
    modelName: { required: true, default: '' },
    virtualModelName: { required: true, default: '' },
    col: { required: true, type: Object as PropType<ColumnDef> },
    record: { required: true },
    commonAttrs: { required: false },
    recordRoot: {},
    hideCreateButton: { required: false, default: false },
  },
  data() {
    return {
      initialized: false,
      v: this.modelValue,
      isRefetching: false,
    }
  },
  computed: {
    isCreatable(): boolean {
      return (
        this.hideCreateButton !== true &&
        ($core.$embAuth.user?.isAdmin ||
          $core.$models[this.col.relationshipOneToMany.collectionName].creatable !== false)
      )
    },
    primaryKeyColName(): string {
      return this.model.primaryKeyColName
    },
    recordPrimaryKeyValue(): string {
      return this.record[this.primaryKeyColName]
    },
    model(): ModelFactory {
      return $core.$models[this.modelName]
    },
    idValue(): string {
      return this.record[this.primaryKeyColName]
    },
    orderableColumn() {
      return this.col.relationshipOneToMany.sortFieldName || null
    },
    defaultSort() {
      if (this.orderableColumn) return [this.orderableColumn]
      return []
    },
    /**
     * M2O リレーションの 中間テーブルにて、このモデルに対して、リレーションが定義されているカラム定義を返す
     */
    columnDefInJunctionModelRelationedToThisModel(): ColumnDef {
      return Object.values(
        $core.$models[this.col.relationshipOneToMany.collectionName].columns,
      ).find((colDef) => {
        return (
          colDef.type === ColumnTypes.RelationshipManyToOne &&
          colDef.relationshipManyToOne.collectionName === this.modelName &&
          this.col.relationshipOneToMany.fieldName === colDef.name
        )
      })
    },
    modelIndexBindValues() {
      /**
       * commonAttrs で 上書き可能
       */
      return {
        enableHeader: false,
        enableExcelExportButton: false,
        enableAddNewRecordButton: false,
        wrapperClass: '_card',
        filters: {
          [this.col.relationshipOneToMany.fieldName]: { _eq: this.recordPrimaryKeyValue },
        },
        defaultSort: this.defaultSort,
        ...this.$attrs,
        modelName: this.col.relationshipOneToMany.collectionName,
        virtualModelName: this.col.relationshipOneToMany.virtualModelName,
      }
    },
  },
  watch: {
    '$core.$uiState.userLatestStoreMethodActionTime': {
      handler() {
        this.reFetchAndEmitValue()
      },
      immediate: true,
    },
  },
  methods: {
    /**
     * 新規作成Modalを開く
     */
    openCreateModal() {
      $core.$modals.openCreateViewModal({
        modelName: this.col.relationshipOneToMany.collectionName,
        defaultValues: {
          [this.columnDefInJunctionModelRelationedToThisModel.name]: this.recordPrimaryKeyValue,
        },
        successCallback: (data) => null, // to avoid
      })
    },
    /**
     * Form 上の 値の更新用に、レコードを再fetchして、this.v および 更新の $emit をKickする
     * 背景: O2M の場合、中間テーブルのレコードを追加・削除・更新するが、その際に Form 上の 値を更新する必要があるため、 emit を特殊なタイミングで実施しなければならない。そのためのメソッド。
     * - このメソッドは、watcher によって、$core.$uiState.userLatestStoreMethodActionTime が更新されたタイミング (つまり、何らかのレコードの追加/更新/削除を発火したタイミング) で呼ばれる
     * - this.isRefetching が true の間は、何もしない (多重連続更新が起こった際の, 余計なリクエストを防ぐため)
     */
    async reFetchAndEmitValue() {
      if (this.isRefetching) {
        return
      }
      this.isRefetching = true
      // 若干の Delay を設ける
      await $core.$utils.sleep(400)
      // レコードをfetchして、this.v および this.modelValue を更新
      const currentRecordState = await this.findCurrentRecord()
      this.v = currentRecordState[this.col.name]
      console.log(
        '[relationshipOneToMany.vue] reFetchAndEmitValue() currentRecordState[this.col.name]:',
        this.v,
      )
      this.$emit('update:modelValue', this.v)
      this.isRefetching = false
    },
    async findCurrentRecord() {
      return this.model.findById(this.idValue, { virtualModelName: this.virtualModelName }, ['*'])
    },
  },
}
</script>
