/**
 * 認証必須 未認証の場合 Signin Page へ遷移
 * 認証済の場合コンテンツの表示
 */
export const authMixinRequireAuth = {
  data() {
    return {
      checkingAuthState: true,
    }
  },
  async created() {
    await $core.$embAuth.retrieveLoginStateWithWait()
    if (globalThis.$core.$embAuth.isLoggedIn) {
      this.checkingAuthState = false
      await this.init()
    } else {
      this.checkingAuthState = false
      if (
        this.$route.name !== 'signIn' &&
        $core.isSdkMode !== true &&
        this.$route?.meta?.layout !== 'logged-out'
      ) {
        this.$router.push({
          path: $core.$configVars.get('signInRedirectTo', '/signIn'),
          query: {
            redirectTo: this.$route.query.redirectTo || encodeURIComponent(this.$route.fullPath),
          },
        })
      }
    }
  },
  methods: {
    init() {
      // Override this if needed
      // auth確認後の初期化
    },
  },
}

/**
 * 認証済みの場合に Index page へ遷移
 * 未認証の場合コンテンツの表示
 */
export const authMixinRedirectIndexIfAuthed = {
  data() {
    return {
      checkingAuthState: true,
    }
  },
  async created() {
    await globalThis.$core.$embAuth.retrieveLoginStateWithWait()
    if (globalThis.$core.$embAuth.isLoggedIn && $core.isSdkMode !== true) {
      this.$router.push({ name: 'index' })
    } else {
      this.checkingAuthState = false
      this.init()
    }
  },
  methods: {
    init() {
      // Override this if needed
      // auth確認後の初期化
    },
  },
}
