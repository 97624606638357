<template>
  <div v-if="initialized">
    <ModelFormGroup
      v-for="(col, colName) in this.expressionsDef"
      v-show="col.visible !== false"
      :key="colName"
      :colName="colName"
      :value="row[colName]"
      :record="row"
      :recordRoot="recordRoot"
      :modelName="$parent.$parent.$parent.modelName"
      :passedColDefinition="col"
      :readOnlyMode="readOnlyMode"
      :virtualModelName="$parent.$parent.virtualModelName"
      :validation="true"
      :ModelFormService="ModelFormService"
      @update:value-and-error="
        (event) => {
          change(event, col, colName)
        }
      "
    />
  </div>
</template>
<script lang="ts">
import { expressionsDef, LogicalOperator } from './validateWithColDef'

export default {
  name: 'OneRowOfConditionalExpressionBuilder',
  emits: ['update:model-value'],
  props: {
    modelValue: { required: true },
    row: {},
    rowIndex: {},
    recordRoot: {},
    builderLabels: { required: false, default: null },
    propSelections: { required: false, default: null },
    readOnlyMode: { required: false, default: null },
    ModelFormService: {},
  },
  created() {
    expressionsDef.logicalOperator.selections = this._signSelection
    // ラベルの置き換え
    if (this.builderLabels?.propName) {
      expressionsDef.propName.label = this.builderLabels.propName
    }
    if (this.builderLabels?.relationalOperator) {
      expressionsDef.relationalOperator.label = this.builderLabels.relationalOperator
    }
    if (this.builderLabels?.threshold) {
      expressionsDef.threshold.label = this.builderLabels.threshold
    }
    if (this.builderLabels?.logicalOperator) {
      expressionsDef.logicalOperator.label = this.builderLabels.logicalOperator
    }
    if (this.builderLabels?.returnValue) {
      expressionsDef.returnValue.label = this.builderLabels.returnValue
    }
    // 選択肢の指定があればtype:SELECT選択式にする
    if (this.propSelections) {
      expressionsDef.propName.type = 'SELECT'
      expressionsDef.propName.dynamicSelections = true
      expressionsDef.propName.selections = this.propSelections
    }
    this.expressionsDef = expressionsDef
  },
  data() {
    return {
      initialized: false,
      visible: false,
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.initialized = true
    })
  },
  computed: {},
  methods: {
    _signSelection(
      record: any,
      currentValue: any,
      initialValue: any,
      recordRoot: any,
      callerVueInstance: any,
    ) {
      if (callerVueInstance.$parent.$parent.rowIndex === 0) {
        return Object.entries(LogicalOperator).map(([key, value]) => {
          return {
            label: key,
            value: value,
          }
        })
      } else {
        return Object.entries(LogicalOperator)
          .filter(([key, value]) => {
            return (
              value === this.$parent.v.blocks[0].logicalOperator ||
              value === LogicalOperator['ならば']
            )
          })
          .map(([key, value]) => {
            return {
              label: key,
              value: value,
            }
          })
      }
    },
    change(event, col, colName) {
      this.$emit('update:model-value', this.rowIndex, event, col, colName)
    },
  },
}
</script>
