<template>
  <div :key="`rendered-${$core.$uiState.appRenderedAt}`" class="core-app">
    <app-hookable-component resolve-hook-name="$CORE.component.coreAppMain.prepend"/>
    <LoadingMessage v-show="$core.$loading.state" />
    <component
      :is="layout"
      :noContentWrapper="noContentWrapper()"
      :initialSidebarDisplayStatus="initialSidebarDisplayStatus()"
    ></component>
    <app-hookable-component resolve-hook-name="$CORE.component.coreAppMain.append"/>
  </div>
</template>
<script lang="ts">
import DefaultLayout from '../Layouts/DefaultLayout.vue'
import EmptyLayout from '../Layouts/EmptyLayout.vue'
import HeaderlessLayout from '../Layouts/HeaderlessLayout.vue'
import LoggedOutLayout from '../Layouts/LoggedOutLayout.vue'
import LoadingMessage from '../LoadingMessage.vue'

export default {
  name: 'CoreAppMain',
  components: {
    LoadingMessage,
    DefaultLayout,
    EmptyLayout,
    HeaderlessLayout,
    LoggedOutLayout,
  },
  watch: {
    '$core.$uiState.wrapperClasses'(newValue) {
      document.body.className = newValue
    },
  },
  created() {
    window.document.body.className = $core.$uiState.wrapperClasses
    // $core.$rootIsMe = this
  },
  computed: {
    layout() {
      // router.jsのroutesにmeta.layoutの存在確認
      // セットするレイアウトを判断する
      return `${this.$route.meta.layout || 'default'}-layout`
    }
  },
  methods: {
    noContentWrapper() {
      return !!this.$route.meta.noContentWrapper
    },
    initialSidebarDisplayStatus() {
      return this.$route.meta.initialSidebarDisplayStatus === undefined
        ? true
        : this.$route.meta.initialSidebarDisplayStatus
    },
  },
}
</script>
