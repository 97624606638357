<template>
  <PageHeader v-if="initialized">
    <h6 class="bold mb-0">
      <Dict k="exportData.title" :f="fixedExportSettingName || 'データエクスポート実行'" />
    </h6>
    <router-link
      v-if="!fixedExportSettingName"
      class="small ml-auto btn btn-outline-secondary export-data-from-external-export-settings-button core-admin-role-only"
      :to="{
        name: 'fallback_model',
        params: { fallback_model: 'dataExportSettings' },
      }"
    >
      <Dict k="exportData.linkToExport" f="エクスポート設定を管理" />
    </router-link>
  </PageHeader>
  <div class="card export-data-from-external border-top-0">
    <div class="card-body">
      <div v-if="!initialized">
        <Loading />
      </div>
      <div v-else-if="!exportSettingNameSelectOptions?.length">
        <Dict k="exportData.noExportSetting" f="エクスポート設定が見つかりませんでした。" />
      </div>
      <div v-else>
        <div class="mb-3" v-if="!fixedExportSettingName">
          <Dict k="exportData.exportTargetSelect" f="エクスポートタイプを選択:" />
          <span class="d-inline-block pl-2">
            <b-form-select
              class="form-control-sm"
              :model-value="selectedExportSettingName"
              v-model="selectedExportSettingName"
              :options="['', ...exportSettingNameSelectOptions]"
            />
          </span>
        </div>
        <div class="mb-3" v-if="!fixedExportSettingName">
          <Dict k="exportData.exportFormatSelect" f="ファイル形式を選択:" />
          <span class="d-inline-block pl-2">
            <b-form-select
              class="form-control-sm"
              :model-value="exportFormatType"
              v-model="exportFormatType"
              :options="['xlsx', 'csv']"
            />
          </span>
        </div>
        <div v-if="selectedExportSettingName">
          <ModelDataFilterPanel
            class="d-inline-block"
            ref="dataFilterPanelComponent"
            :model-name="selectedDataExportSetting.targetModelName"
            :enable-sort="false"
            :enable-keyword-search="false"
            :enable-limit-control="false"
            :query="{ filter: initialFilterStateByExportSettingName }"
            :search-label="$core.$dict.get('exportData.seachLabel', 'エクスポート')"
            :filterButtonText="$core.$dict.get('exportData.filterButtonText', '絞り込み条件を追加')"
          />
          <div class="mt-1 w-100">
            <span v-single-click="executeExport" class="btn btn-outline-primary btn-sm">
              <Dict k="exportData.executeExport" f="エクスポート" />
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    exportSettingName: {
      required: false,
    },
  },
  data() {
    return {
      initialized: false,
      selectedExportSettingName: '',
      exportFormatType: 'xlsx',
    }
  },
  async mounted() {
    await $core.$dataExportService.fetchExportSettings()
    if (this.fixedExportSettingName) {
      this.selectedExportSettingName = this.fixedExportSettingName
    }
    this.initialized = true
  },
  watch: {
    fixedExportSettingName() {
      // re initialize
      this.initialized = false
      this.$nextTick(() => {
        this.selectedExportSettingName = this.fixedExportSettingName
        this.initialized = true
      })
    },
  },
  computed: {
    fixedExportSettingName() {
      return (
        this.exportSettingName ||
        this.$route.query.exportSettingName ||
        this.$route.params.exportSettingName
      )
    },
    selectedDataExportSetting() {
      return $core.$dataExportService.dataExportSettingsByName[this.selectedExportSettingName] || {}
    },
    selectedExportModel() {
      return $core.$models[this.selectedExportSettingName.targetModelName]
    },
    selectedFilter() {
      return this.$refs.dataFilterPanelComponent.$data.filter
    },
    initialFilterStateByExportSettingName() {
      const defaultValueFunc = () => ({}) // return empty object
      const filterResolveFunc = $core.$configVars.get(
        `exportData.initialFilterStateByExportSettingName.${this.selectedExportSettingName}`,
        defaultValueFunc,
      )
      return filterResolveFunc(this)
    },
    exportSettingNameSelectOptions() {
      return Object.keys($core.$dataExportService.dataExportSettingsByName).filter(name => {
        return $core.$dataExportService.dataExportSettingsByName[name].hideFrom !== true
      })
    },
  },
  methods: {
    async executeExport() {
      try {
        // $core.$loading.start('エクスポートファイルを作成しています...', 'overlay')
        $core.$loading.start(
          $core.$dict.get('exportData.createMessage', 'エクスポートファイルを作成しています...'),
          'overlay',
        )
        await $core.$dataExportService.exportBySettingName(
          this.selectedExportSettingName,
          this.selectedFilter || {},
          this.exportFormatType,
        )
      } catch (e) {
        $core.$errorReporter.r(e, this)
      } finally {
        $core.$loading.finish()
      }
    },
  },
}
</script>
