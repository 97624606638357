import { ModelDef } from '../../../common/$models/ModelDef'

export const userGroups: ModelDef = {
  tableName: 'userGroups',
  tableLabel: 'ユーザーグループ',
  tableComment: '',
  defaultSort: { key: 'createdAt', order: 'desc' },
  modelType: 'admin',
  columns: {
    // ユーザグループコード
    code: {
      label: 'グループコード',
      type: 'STRING',
      unique: true,
      inputAttrs: { wrapperClass: 'col-4' },
      validate: { notEmpty: true },
      defaultValue: () => $core.$utils.generateRandomString().toUpperCase(),
      editable: false,
      editableOnCreate: true,
      primaryKey: true,
      inputHelpText: '後から変更することはできません',
    },
    // ユーザグループ名
    name: {
      label: 'グループ名',
      type: 'STRING',
      validate: { notEmpty: true },
      unique: true,
      inputAttrs: { wrapperClass: 'col-4' },
    },
    // TODO: 静的 or 動的なユーザ設定
    // TODO: 動的な場合の条件設定
  },
  defaultValues: () => ({
    code: $core.$utils.generateRandomString().toUpperCase(),
  }),
}
