import {
  initCoreFrontApp,
  initCoreFrontAppArgs,
} from '../CORE/src/front/AppInitializer/initCoreFrontApp'
import models from '../models/fireModels'

const coreFrontInitArg: initCoreFrontAppArgs = {
  modelDefinitions: models,
}

/**
 * Initialize core
 */
initCoreFrontApp(coreFrontInitArg).then((startAppFunction) => {
  // globalThis.$core.$router.addRoutes(pageRoutes)
  startAppFunction() // start app
})
