<template>
  <DefaultLayout
    :enable-header="false"
    :enable-sidebar="true"
    :no-content-wrapper="noContentWrapper"
    :initialSidebarDisplayStatus="initialSidebarDisplayStatus"
  />
</template>
<script lang="ts">
import DefaultLayout from './DefaultLayout.vue'

export default {
  props: {
    noContentWrapper: { default: false },
    initialSidebarDisplayStatus: { default: true },
  },
  components: {
    DefaultLayout,
  },
}
</script>