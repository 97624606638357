import { App, defineAsyncComponent } from 'vue'
import Datepicker from './components/Datepicker.vue'
import '@vuepic/vue-datepicker/dist/main.css'
import BooleanCheckboxInput from './components/BooleanCheckboxInput.vue'
import CInputString from './components/CInputString.vue'
import CInputNumber from './components/CInputNumber.vue'
import DatetimePicker from './components/DatetimePicker.vue'
import TimePicker from './components/TimePicker.vue'
import TimeTextInput from './components/TimeTextInput.vue'
import YearMonthPicker from './components/YearMonthPicker.vue'
import SingleSelectInput from './components/SingleSelectInput.vue'
import RelationshipManyToOneNestedModelFormInput from './components/RelationshipManyToOneNestedModelFormInput.vue'

export const registerFormInputComponents = (Vue: App) => {
  Vue.component('CInputString', CInputString)
  Vue.component('CInputNumber', CInputNumber)
  Vue.component('Datepicker', Datepicker)
  Vue.component('BooleanCheckboxInput', BooleanCheckboxInput)
  Vue.component(
    'InputMultiselectMultipleValue',
    defineAsyncComponent(() => import('./components/InputMultiselectMultipleValue.vue')),
  )
  Vue.component('DatetimePicker', DatetimePicker)
  Vue.component('TimePicker', TimePicker)
  Vue.component('TimeTextInput', TimeTextInput)
  Vue.component('YearMonthPicker', YearMonthPicker)
  Vue.component(
    'RichTextEditor',
    $frameworkUtils.defineAsyncComponent(() => import('./components/RichTextEditor.vue')),
  )
  Vue.component('SingleSelectInput', SingleSelectInput)
  Vue.component('RelationshipManyToOneNestedModelFormInput', RelationshipManyToOneNestedModelFormInput)
}
