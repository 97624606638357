import { ColumnDefByColName, ModelDef } from '../../common/$models/ModelDef'
import { passwordValidator } from '../../common/ValidationService/validator'

export interface ModelDirectusUsers {
  id?: string
  first_name: string
  email?: string
  password?: string
  status?: string
  isAdmin: boolean
  coreRoles?: string[]
  role?: string | Record<string, any>
  metaData?: any
  organizations?: any[]
  thumbImg?: string

  [key: string]: any
}

export const userEditableColumns: ColumnDefByColName = {
  id: {
    type: 'UUID',
    editable: false,
    enableIf: (row) => !!row.id,
    visibleOnIndex: false,
  },
  first_name: {
    label: '名前',
    type: 'STRING',
    validate: { notEmpty: true },
    searchable: true,
    editable: true,
    editableOnCreate: true,
    // doNotSyncModel: true,
  },
  email: {
    label: 'メールアドレス',
    type: 'STRING',
    validate: {
      notEmpty: true,
      isEmail: true,
    },
    searchable: true,
    unique: true,
    editable: false,
    editableOnCreate: true,
    // doNotSyncModel: true,
  },
  // first_name: null,
  // last_name: null,
  /**
   * このフィールドはフォームのみ, フィールド自体は存在してしまうが、常にFALSEで保存されるようにする
   */
  shouldChangePassword: {
    // groupKey: 'passwordAccordion',
    label: 'パスワードを変更する',
    type: 'BOOLEAN',
    // 一度作成したときのみ、このフィールドが活かされるので...
    enableIf: (row) => !!row.id,
    visibleOnIndex: false,
  },
  password: {
    // groupKey: 'passwordAccordion',
    label: 'パスワード',
    type: 'STRING',
    enableIf: (row) => !row.id || !!row.shouldChangePassword,
    validate: {
      notEmpty: true,
      passwordValidator,
    },
    doNotSyncModel: true,
    visibleOnIndex: false,
  },
  // location: null,
  // title: null,
  // description: null,
  // tags: null,
  // avatar: null,
  // language: 'en-US',
  // theme: 'auto',
  // tfa_secret: null,
  status: {
    // groupKey: 'permissionAccordion',
    label: '有効状態',
    type: 'STRING',
    defaultValue: 'active',
    selections: () => ['active', 'suspended'],
    customLabel: (val) => {
      const labels = {
        active: '有効',
        suspended: '停止',
      }
      return `${labels[val]} (${val})`
    },
    doNotSyncModel: true,
    inputHelpText: '"停止" にすると、ログインできなくなります。',
  },
  isAdmin: {
    // groupKey: 'permissionAccordion',
    label: '管理者ロール',
    type: 'BOOLEAN',
    defaultValue: false,
    inputHelpText:
      'すべてのデータへのアクセス、更新を許可します。管理者権限の場合は、定義したロールを割り当てることはできません。',
  },
  coreRoles: {
    // groupKey: 'permissionAccordion',
    label: 'ロール(権限)',
    type: 'MULTISELECT',
    enableIf: (row) => !row.isAdmin,
    async selections() {
      const roles = await $core.$models.user_roles.find()
      return roles.map((r) => r.key)
    },
    inputHelpText: '画面制御に関わる権限を設定',
    // customLabel: (val, inpu) => {
    //   return val
    // }
  },
  role: {
    // groupKey: 'permissionAccordion',
    // group: {
    //   type: 'accordion',
    //   label: '権限設定'
    // },
    label: 'APIロール',
    type: 'RELATIONSHIP_MANY_TO_ONE',
    relationshipManyToOne: {
      collectionName: 'directus_roles',
      labelFormatter: (row) => row.name,
    },
    doNotSyncModel: true,
    // enableIf: (row) => !row.isAdmin,
    inputHelpText: 'データアクセスに関わる権限設定',
    visibleOnIndex: false,
  },
  /**
   * ユーザのメタデータを保存するフィールド, 各種設定情報の保存に利用する
   * 例: カラム並び替え情報, 前回入力値の保存等
   */
  metaData: {
    type: 'ARRAY_OF_OBJECT',
    comment: 'ユーザのメタデータを保存するフィールド',
    visible: false,
    editable: false,
    columns: {},
  },
  organizations: {
    label: '所属組織',
    type: 'RELATIONSHIP_ONE_TO_MANY',
    // TODO: UI要検討
    visible: false,
    inputAttrs: { wrapperClass: 'col-12' },
    relationshipOneToMany: {
      collectionName: 'junctionUsersAndOrgsAndPositions',
      fieldName: 'user',
    },
  },
  thumbImg: {
    label: 'プロフィール画像',
    type: 'FILE',
    inputComponent: 'inputImageUploader',
    visible: false,
  },
}

/**
 */
export const directus_users: ModelDef = {
  tableName: 'directus_users',
  tableLabel: 'ユーザ管理',
  tableComment: '',
  modelType: 'admin',
  timestamps: true,
  defaultSort: { key: 'id', order: 'desc' },
  columns: userEditableColumns,
  primaryKeyColType: 'UUID',
}
