import { ColumnDef, ColumnDefByColName } from './ModelDef'

/**
 * ネストされたカラム名を含む ColumnDef を返す
 * - RELATIONSHIP_MANY_TO_ONE である場合、対象の Model に 行って、その Model のカラムを取得する
 * - ARRAY_OF_OBJECT である場合は columns props を返す
 */
export const getColumnDefsWithNestedColumnNames = (colNames: string[], columnDefByColName: ColumnDefByColName): ColumnDef[] => {
  const columns: ColumnDef[] = []
  colNames.reduce((currentColDefs: ColumnDefByColName, colName) => {
    const colDef = currentColDefs[colName]
    if (!colDef) {
      console.warn(`[getColumnDefsWithNestedColumnNames] colDef is not found for colName: ${colName}`)
      return {}
    }
    columns.push(colDef)
    if (colDef?.type === 'RELATIONSHIP_MANY_TO_ONE') {
      const relationModelName = colDef.relationshipManyToOne?.collectionName
      return $core.$models[relationModelName]?.columns || {}
    }
    if (colDef?.type === 'RELATIONSHIP_ONE_TO_MANY') {
      const relationModelName = colDef.relationshipOneToMany.collectionName
      return $core.$models[relationModelName]?.columns || {}
    }
    if (colDef?.type === 'ARRAY_OF_OBJECT') {
      return colDef.columns || {}
    }

    return currentColDefs[colName]?.columns || {}
  }, columnDefByColName)
  return columns
}
