<template>
  <section>
    <app-hookable-component resolve-hook-name="$CORE.admin.resolveComponent.toppage">
<!--      <div>
        <h1 class="title">{{ $core.$uiState.appName }}</h1>
      </div>-->
    </app-hookable-component>
  </section>
</template>
<script>
export default {
  beforeCreate() {
    // URLのリプレイス
    if (!$core.$appDefinitionLoader.appKey) {
      // 管理アプリのときはアプリケーション定義一覧へ
      $core.$router.replace({ path: '/m/appDefinitions' })
    } else {
      // 初期表示Urlの指定があればリダイレクト
      if ($core.$appDefinitionLoader.appDefinition.startPage)
        $core.$router.replace({ path: $core.$appDefinitionLoader.appDefinition.startPage })
    }
  },
  mounted() {},
}
</script>
