<template>
  <div
    class="colorful-select"
    :class="{
      'colorful-select--multiple': isMultipleSelect,
      'colorful-select--single': !isMultipleSelect,
    }"
  >
    <multiselect
      v-bind="commonAttrs"
      :model-value="modelValue"
      @update:model-value="(__value) => change({ value: __value })"
      :allow-empty="true"
      :internal-search="false"
      :options="multiSelectOptions"
      :show-labels="true"
      :taggable="col.strictSelections !== true"
      :placeholder="commonAttrs.placeholder || '選択'"
      :select-label="commonAttrs.selectLabel || '選択'"
      :selected-label="commonAttrs.selectedLabel || '選択中'"
      tag-placeholder="選択肢以外の値を追加"
      :max="!!isMultipleSelect ? col.maxValueLength || 1000 : undefined"
      :multiple="!!isMultipleSelect"
      :customLabel="customLabelFunc"
      @open="multiSelectionOpened"
      @close="multiSelectionClosed"
      :hide-selected="false"
      @search-change="searchChange"
      @remove="(event) => (isMultipleSelect ? change() : change({ value: '' }))"
      @tag="addSelection"
      ref="multiselect"
    >
      <template v-slot:noOptions> ---</template>
      <template v-slot:noResult> ---</template>
      <template v-slot:maxElements> 最大 {{ col.maxValueLength }}項目 まで選択可能</template>
      <template v-slot:option="{ option }">
        <!-- ここでOptionのカスタムUIを定義 -->
        <span v-bind="optionStyle(option)">
          {{ customLabel(option) }}
        </span>
      </template>
      <template v-slot:tag="{ option }">
        <span class="multiselect__tag" v-bind="optionStyle(option)">
          {{ customLabel(option) }}
        </span>
      </template>
      <template v-slot:singleLabel="{ option }">
        <span v-bind="optionStyle(option, 'singleLabel')">
          {{ customLabel(option) }}
        </span>
      </template>
    </multiselect>
  </div>
</template>
<script lang="ts">
import { ColumnDef } from '../../common/$models/ModelDef'
import { fetchSelectionsWithColDef } from '../../front/ModelForm'

export default {
  name: 'ColorfulSelect',
  props: {
    record: {
      type: Object,
      default: null,
    },
    recordRoot: {
      type: Object,
      default: null,
    },
    modelValue: {
      type: [String, Number, Array],
      default: null,
    },
    col: {
      type: Object as () => ColumnDef,
      default: null,
    },
    modelName: {
      type: String,
      default: null,
    },
    selectionLabels: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      options: [],
      initialValue: null,
      addedSelections: [],
      multiSelectOptions: [],
      colInputSelection: [],
      isLoading: false,
      selectionLabelsTemp: {},
      isSearching: false,
    }
  },
  computed: {
    isMultipleSelect(): boolean {
      return this.col.type === 'MULTISELECT'
    },
    commonAttrs() {
      return this.$attrs.commonAttrs || {}
    },
    customLabelFunc() {
      if (this.col.customLabel) {
        return (value) => this.col.customLabel(value, this, this.recordRoot)
      }
      if (Object.keys(this.selectionLabelsTemp).length) {
        return (value) =>
          this.selectionLabelsTemp[value] !== undefined ? this.selectionLabelsTemp[value] : value
      }
      return undefined
    },
    colorLabelSelectionConditon() {
      return this.col.colorLabelSelectionConditon
    },
  },
  async created() {
    this.selectionLabelsTemp = this.selectionLabels
    this.initialValue = this.modelValue
    this.multiSelectOptions = await this._initColInputSelection()
  },
  methods: {
    multiSelectionOpened() {
      this.isSearching = true
    },
    multiSelectionClosed() {
      this.isSearching = false
    },
    change(event) {
      const value = event ? event.value || event : null
      if (this.isMultipleSelect) {
        const v = Array.isArray(value) ? value : value && value !== 0 ? [] : [value]
        this.$emit('update:modelValue', v)
      } else {
        this.$emit('update:modelValue', value)
      }
    },
    customLabel(value) {
      if (this.col.customLabel) {
        return this.col.customLabel(value, this, this.recordRoot)
      }
      if (Object.keys(this.selectionLabelsTemp).length) {
        return this.selectionLabelsTemp[value] !== undefined
          ? this.selectionLabelsTemp[value]
          : value
      }
      return value
    },
    addSelection(newSelection) {
      if (
        this.colDef.createSelectOptionsMasterOnAddNewSelection === true &&
        this.colDef.selectionsWithSelectOptionsMasterGroupName
      ) {
        // 追加時に、作成してしまう
        $core.$storeMethods.upsert({
          collectionName: 'selectOptionsMaster',
          data: {
            group: this.colDef.selectionsWithSelectOptionsMasterGroupName,
            value: newSelection,
            sort: 0,
          },
        })
      } else {
        // DBに登録しない場合は新規追加選択肢として保持する
        this.addedSelections.push(newSelection)
      }
      this.$nextTick(async () => {
        // isMultipleSelect の場合は、そのまま選択状態として追加
        if (this.isMultipleSelect) {
          this.change({
            value: Array.isArray(this.v) ? this.v.concat([newSelection]) : [newSelection],
          })
        } else {
          // 単一選択でValueをUpdate
          this.change({ value: newSelection })
        }
      })
    },
    async _initColInputSelection(autoSelectIfSelectionIsOnlyOne = false) {
      let list = await fetchSelectionsWithColDef({
        modelName: this.modelName,
        colDef: this.col,
        record: this.record,
        value: this.modelValue,
        initialValue: this.initialValue,
        recordRoot: this.recordRoot,
        callerVueInstance: this,
      })
      // list構造が {value, label}[] であれば、、、
      const isLabeledList =
        Object.keys(list[0] || {}).length === 2 && list[0].value !== undefined && list[0].label
      if (isLabeledList) {
        const valueList = []
        const labelMap = list.reduce((res, r) => {
          res[r.value] = r.label
          valueList.push(r.value)
          return res
        }, {})
        this.selectionLabelsTemp = labelMap
        list = valueList
      }
      if (this.addedSelections) {
        list = list.concat(this.addedSelections)
      }
      list = list.filter((v) => v !== undefined)
      this.colInputSelection = list
      this.multiSelectOptions = this.colInputSelection.map((__v) => (__v === null ? '' : __v))
      return list
    },
    async searchChange(query) {
      let list = this.colInputSelection

      if (this.col.dynamicSelections && query && query.length > 2) {
        this.isLoading = true
        list = await this._initColInputSelection()
        this.isLoading = false
      }

      if (query) {
        query = query.replaceAll('　', ' ').split(' ')
        for (const searchText of query) {
          list = list.filter((v) => {
            const label = this.col.customLabel
              ? this.col.customLabel(v, this, this.recordRoot)
              : this.selectionLabelsTemp[v] || ''
            const result = (label + v).toLowerCase().includes(searchText.toLowerCase())
            return result
          })
        }
      }
      this.multiSelectOptions = list
    },
    optionStyle(option, flag = null) {
      const optionValue = option.value || option
      const colorLabelSelectionConditon = this.colorLabelSelectionConditon
      if (colorLabelSelectionConditon && colorLabelSelectionConditon.length) {
        const find = colorLabelSelectionConditon.find((condition) => {
          const { valueCondition, useRegExp } = condition
          if (useRegExp) {
            const regExp = new RegExp(valueCondition)
            return regExp.test(optionValue)
          } else {
            return optionValue === valueCondition
          }
        })
        if (find) {
          return {
            style: {
              backgroundColor: find.color || 'transparent',
              color: find.textColor,
              border: `1px solid ${find.borderColor}`,
              padding: `4px 10px`,
              'border-radius': `4px`,
              'margin-bottom': 0,
              'margin-right': 0,
              ...(flag === 'singleLabel'
                ? {
                    display: 'flex',
                    width: 'fit-content',
                    padding: `2px 10px`,
                  }
                : {}),
            },
          }
        }
      } else {
        return {
          style: {
            'margin-bottom': `4px`,
            'margin-right': `4px`,
          },
        }
      }
    },
  },
}
</script>

<style lang="scss">
.colorful-select {
  .multiselect__tags-wrap {
    display: flex;
    gap: 4px;
    flex-wrap: wrap;
  }
}
</style>
