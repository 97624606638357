<template>
  <div v-if="initialized">
    <ModelForm
      v-bind="commonAttrs"
      :model-name="relationModelName"
      :virtual-model-name="relationVirtualModelName"
      :record="childRecord"
      :readOnlyMode="readOnlyMode"
      :disableSubmitAction="true"
      :hideFooter="true"
      @update="emitUpdate"
    />
  </div>
</template>
<script lang="ts">
import { ColumnDef } from '../../../../../common/$models/ModelDef'
import { PropType } from 'vue'
import { ModelFactory } from '../../../../../common/$models'

/**
 * # RelationshipManyToOneNestedModelFormInput
 *
 * ## 概要
 * - カラム `type` が `RELATIONSHIP_MANY_TO_ONE` の場合に、リレーション先のカラムも含めた編集フォームを表示するためのコンポーネント
 * - リレーション先の値を編集することが可能になる
 *
 */
export default {
  name: 'RelationshipManyToOneNestedModelFormInput',
  emits: ['update:value-and-error'],
  props: {
    modelValue: { required: true, type: [Object, String, null] },
    modelName: { required: true, type: String },
    virtualModelName: { required: false, type: String },
    col: { required: true, type: Object as PropType<ColumnDef> },
    record: { required: true },
    recordRoot: { required: true },
    validation: {},
    editable: {},
    forceRequired: {},
    forceEditable: {},
    readOnlyMode: {},
    ModelFormService: {},
    colInputSelection: {},
    selectionLabels: {},
    commonAttrs: {},
  },
  data() {
    return {
      childRecord: {},
      initialized: false,
      hasModelFormLoaded: false,
    }
  },
  computed: {
    relationshipManyToOne(): ColumnDef['relationshipManyToOne'] {
      return this.col.relationshipManyToOne
    },
    relationModelName(): string {
      return this.relationshipManyToOne.collectionName
    },
    relationModel(): ModelFactory {
      return $core.$models[this.relationModelName]
    },
    relationVirtualModelName(): string {
      return this.relationshipManyToOne.virtualModelName || this.commonAttrs.virtualModelName
    },
    hasModelValueGivenAsObject(): boolean {
      return typeof this.modelValue === 'object' && Object.keys(this.modelValue).length > 0
    },
    colName(): string {
      return this.col.name
    },
  },
  async mounted() {
    await this.fetchChildRecord()
    this.$nextTick(() => {
      this.initialized = true
      setTimeout(() => {
        this.hasModelFormLoaded = true
      }, 1000)
    })
  },
  methods: {
    async fetchChildRecord() {
      if (this.hasModelValueGivenAsObject) {
        this.childRecord = { ...this.modelValue }
        return
      }
      const idValue = typeof this.modelValue === 'object' ? this.modelValue[this.relationModel.primaryKeyColName] : this.modelValue
      if (!idValue) {
        this.childRecord = {}
        return
      }
      this.childRecord = await $core.$models[this.relationModelName].findById(idValue, {virtualModelName: this.relationVirtualModelName})
    },
    emitUpdate(ev: { data, validationErrors }) {
      if (!this.initialized || !this.hasModelFormLoaded) {
        return
      }
      this.$emit('update:value-and-error', {
        value: { ...(ev.data || {}) },
        error: Object.values(ev.validationErrors || {}).flat().join(''),
      })
    },
  },
}
</script>
