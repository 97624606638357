import { ColumnDefByColName, ModelDef } from '../../../common/$models/ModelDef'
import {
  modelNameSelectColumnDefWithClearVirtualModelNameColumnOnEditCallback,
  virtualModelNameSelectColumnDefWithUpdateModelNameColumnOnEditCallback,
} from '../../../common/$models/modelsSelectionsHelperFunctions'
import { colSelectionsByModelNameAsSelectOptions } from '../../../front/DataExportSettings/ModelDataExportSettings'

const composableComponentTypes = {
  none: {
    label: 'Normal',
  },
  // TODO: こういうのもあるよね多分
  // layout: {
  //   label: 'Layout',
  // },
  // layoutPartial: {
  //   label: 'Layout パーツ',
  // },
  modelColumn: {
    label: 'モデル カラム単位設定',
    subTypes: {
      columnDisplay: {
        label: 'カラム (表示)',
      },
      columnEdit: {
        label: 'カラム (編集)',
      },
    },
    useModelName: true,
    useModelColumnName: true,
  },
  modelRecord: {
    label: 'モデル レコード単位設定',
    subTypes: {
      dataListTableRow: {
        label: '一覧表示 (テーブル行)',
      },
      dataListItem: {
        label: '一覧表示 (自由形式)',
      },
      recordEdit: {
        label: 'レコード (編集)',
      },
      recordDisplay: {
        label: 'レコード (表示)',
      },
    },
    useModelName: true,
  },
}

const composableComponentTypeExplainComponent = {
  template: ``,
}

const core_composable_component_definitions_columns: ColumnDefByColName = {
  name: {
    label: '管理用名称',
    type: 'STRING',
    validate: { notEmpty: true },
  },
  key: {
    label: '一意キー',
    type: 'STRING',
    validate: { notEmpty: true },
    unique: true,
    defaultValue: () => {
      return $core.$router.currentRoute?.query?.key || ''
    },
  },
  type: {
    label: 'タイプ',
    type: 'STRING',
    validate: { notEmpty: true },
    selections: () => Object.keys(composableComponentTypes),
    customLabel: (value: string) => composableComponentTypes[value]?.label,
    editCallback({ row, newValue, oldValue }) {
      if (newValue !== oldValue) {
        row.typeSub = ''
      }
      return row
    },
    editable: true,
    editableOnCreate: true,
  },
  typeSub: {
    label: 'サブタイプ',
    type: 'STRING',
    dynamicSelections: true,
    selections: (row: any) => {
      if (composableComponentTypes[row.type]?.subTypes) {
        return Object.keys(composableComponentTypes[row.type].subTypes)
      }
      return []
    },
    customLabel: (value: string, vueIns, row: any) => {
      return composableComponentTypes[row?.type]?.subTypes?.[value]?.label || ''
    },
    enableIf: (row: any) => !!composableComponentTypes[row.type]?.subTypes,
    editable: true,
    editableOnCreate: true,
  },
  modelName: {
    ...modelNameSelectColumnDefWithClearVirtualModelNameColumnOnEditCallback('virtualModelName'),
    label: 'モデル定義',
    enableIf: (row: any) => !!composableComponentTypes[row.type]?.useModelName,
  },
  virtualModelName: {
    ...virtualModelNameSelectColumnDefWithUpdateModelNameColumnOnEditCallback('modelName'),
    label: 'Virtualモデル定義',
    enableIf: (row: any) => !!composableComponentTypes[row.type]?.useModelName,
  },
  targetColumn: {
    label: '対象カラム',
    type: 'STRING',
    enableIf: (row: any) => !!composableComponentTypes[row.type]?.useModelColumnName,
    strictSelections: false,
    dynamicSelections: true,
    selections(record) {
      return colSelectionsByModelNameAsSelectOptions(record.modelName)
    },
  },
  urlPath: {
    label: 'URLパス',
    type: 'STRING',
    unique: true,
    afterComponent: `<span class="small text-gray-500">設定すると、<code>/pb/\${入力したPath}</code> でアクセス可能になります。</span>`,
    width: { xs: 48, sm: 24 },
    defaultValue: () => {
      return $core.$router.currentRoute?.query?.urlPath || ''
    },
  },
  description: {
    label: '説明',
    type: 'TEXT',
    width: { xs: 48 },
  },
  // TODO: カテゴリ的なものを入れたい気がするが
  componentStructureData: {
    label: 'コンポーネントデータ',
    type: 'ARRAY_OF_OBJECT',
    editable: false,
    visible: false,
  },
  children: {
    label: '子コンポーネント',
    type: 'ARRAY_OF_OBJECT',
    editable: false,
    visible: false,
  },
  componentConfigColumns: {
    label: '指定可能な属性 (カラム定義)',
    type: 'ARRAY_OF_OBJECT',
    editable: false,
    visible: false,
  },
  metaData: {
    label: 'メタデータ',
    type: 'ARRAY_OF_OBJECT',
    editable: false,
    visible: false,
  },
}

/**
 * 新規作成の際に、強制的に入力させる対象のカラム名s
 */
//
// export const core_composable_component_definitions_columns_for_create_new: (keyof typeof core_composable_component_definitions_columns)[] =
//   [
//     'name',
//     'key',
//     'type',
//     'typeSub',
//     'modelName',
//     'virtualModelName',
//     'targetColumn',
//     'urlPath',
//     'description',
//   ]

export const core_composable_component_definitions: ModelDef = {
  tableName: 'core_composable_component_definitions',
  tableLabel: 'コンポーザブルコンポーネント定義',
  primaryKeyColType: 'UUID',
  indexListItemOnClick(id: string): any {
    $core.$router.push(`/composable-component-builder/${id}`)
  },
  columns: core_composable_component_definitions_columns,
  modelType: 'admin',
}
