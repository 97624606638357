<template>
  <div v-show="isActive">
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { registerComposableComponentSettings } from '../../../plugins/ComposableComponents'
import { ColumnDefByColName } from '../../../common/$models/ModelDef'

/**
 * タブグループアイテムの設定カラム
 */
export const composableComponentTabGroupItemConfigColumns: ColumnDefByColName = {
  title: {
    label: 'タブタイトル',
    type: 'STRING',
  },
}

const name = 'TabGroupItem'

registerComposableComponentSettings(name, {
  label: 'タブグループアイテム',
  category: 'Basic',
  hasDefaultSlot: true,
  configColumns: composableComponentTabGroupItemConfigColumns,
})

export default {
  name,
  props: {
    uid: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  inject: {
    DisplayToggleGroupContainerInstance: {
      default: null,
    },
  },
  computed: {
    isActive() {
      return this.DisplayToggleGroupContainerInstance.currentDisplayingUid === this.tabItemId
    },
    tabItemId() {
      return this.$attrs['data-cc-item-id']
    },
  },
  created() {
    this.DisplayToggleGroupContainerInstance.children.push({
      title: this.title,
      uid: this.tabItemId,
    })
  },
  beforeUnmount() {
    // Rendering された UID を削除する
    this.DisplayToggleGroupContainerInstance.children =
      this.DisplayToggleGroupContainerInstance.children.filter(
        (item) => item.uid !== this.tabItemId,
      )
  },
}
</script>
