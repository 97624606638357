/**
 * "文字列定義された関数" を "実行可能な関数" として返す
 * Convert "string defined" function expression into callable Javascript function
 * @param functionDefinitionString
 * TODO: This can be improved, need more research about
 */
import { $errorReporter } from '../../front/$libs/$errorReporter/$errorReporter'

export const stringJsExpressionIntoFunctionConverter = (
  functionDefinitionString: string,
  isAsync = false,
  argumentExpression = 'args',
): ((...any) => any) => {
  const asyncMark = isAsync ? 'async ' : ''
  const functionString = `{ return ${asyncMark} function (${argumentExpression}) { ${functionDefinitionString} } };`
  const func = new Function(functionString)
  return (...args) => {
    try {
      return func.call(null).call(null, ...args)
    } catch (e) {
      console.error(e)
      $errorReporter.r(e, this)
    }
  }
}
