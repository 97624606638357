import { $models, $modelsLoader } from '../$models'
import { $modelDefinitionsLoaderService } from './modelDefinitionsLoaderService'
import { modelDefinitions } from './modelDefinitions'
import { prepareModelExtensionsInstallBehavior } from './ModelExtensions/prepareModelExtensionsInstallBehavior'

/**
 * modelDefinitionsの挙動をInstall
 */
export const useModelDefinitionsFeature = () => {
  // Extensions の 挙動は 最初にセットアップしておく
  prepareModelExtensionsInstallBehavior()
  if (!$models.modelDefinitions) {
    $modelsLoader.loadModel(modelDefinitions)
  }
  // Add
  $core.$modelDefinitionsLoaderService = $modelDefinitionsLoaderService()
  /**
   * modelsDoc page
   */
  $core.$router.addRoutes([
    {
      path: '/modelsDoc',
      component: $frameworkUtils.defineAsyncComponent(() => import('./modelsDoc.vue')),
    },
  ])
  /**
   * Add link to modelsDoc
   */
  $core.$appHook.on('modelIndex.model:modelDefinitions.headerAppend', () => {
    return {
      template: `
<router-link class="btn btn-outline-secondary" to="/modelsDoc">設計書</router-link>
<a href="#" @click.prevent="() => $core.$modelsLoader.generateAllModelsTypeDefinitions()" class="btn btn-outline-secondary ml-1">Type定義を出力</a>
`,
    }
  })
}
