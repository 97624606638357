<template>
  <div class="d-flex flex-fill py-1" style="position: relative">
    <div class="filter-logic text-right mr-1" v-if="filterObject">
      <span v-if="filterObject.isFirstObject">条件:</span>
      <b-form-select
        v-else-if="filterObject.isSecondObject"
        class="flex-1 text-uppercase"
        :modelValue="operator"
        @update:modelValue="updateOperator"
        :options="options"
        size="sm"
      ></b-form-select>
      <span class="text-uppercase" v-else>{{ filterObject.parentFilterGroupService.filterLogic }}</span>
    </div>
    <div
      class="flex-fill"
      :class="{ group_back: !!filterObject && filterObject.objectType !== 'rule' }"
    >
      <div v-for="(filterObject, index) in calcedFilterObjects" :key="index">
        <component :is="filterObjectComponent(filterObject)" :filterObject="filterObject" />
      </div>
      <AppendFilterObject />
    </div>
    <a v-if="filterObject" class="ml-1" role="button" @click="toggleSetting">
      <Ficon class="ml-2" type="ellipsis-h" />
    </a>

    <FilterItemManagerForCustom
      @onDismiss="() => (isVisibleSetting = false)"
      :visible="isVisibleSetting"
    />
  </div>
</template>
<script lang="ts">
import AppendFilterObject from './AppendFilterObject.vue'
import { computed, inject } from 'vue'
import { FilterControlsService } from '../../../FilterControlsService'
import { FilterGroupService } from '../../../FilterGroupService'
import FilterItemManagerForCustom from '../FilterItemManagerForCustom.vue'
export default {
  components: {
    FilterItemManagerForCustom,
    AppendFilterObject,
  },
  props: {
    filterObject: {
      type: FilterGroupService,
      required: true,
      default: null,
    },
  },
  data() {
    return {
      operator: 'and',
      options: ['and', 'or'],
      isVisibleSetting: false,
    }
  },
  setup() {
    return {
      FilterControlsServiceInstance: inject<FilterControlsService>('FilterControlsServiceInstance'),
    }
  },
  provide() {
    return {
      filterObject: computed(() => this.filterObject),
    }
  },
  computed: {
    calcedFilterObjects() {
      if (this.filterObject) {
        return this.filterObject.filterObjects
      } else {
        return this.FilterControlsServiceInstance.filterGroupService.filterObjects
      }
    },
  },
  methods: {
    filterObjectComponent(filterObject) {
      if (filterObject.objectType === 'rule') {
        return 'ModernFilterRule'
      } else {
        return 'ModernFilterWrapper'
      }
    },
    toggleSetting() {
      this.isVisibleSetting = !this.isVisibleSetting
    },
    updateOperator(val) {
      this.operator = val
      this.filterObject.updateOperator(val)
    },
  },
}
</script>
