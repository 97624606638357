<template>
  <div class="clearfix">
    <div class="float-left mr-2">
      <b-input-group size="sm" class="float-left" :prepend="`一括操作:`">
        <b-form-select
          class="form-control"
          :model-value="selectedAction"
          v-model="selectedAction"
          :options="controlOptions"
        />
        <b-button
          :variant="targetIds.length && selectedAction ? 'outline-primary' : 'outline-secondary'"
          v-single-click="runBulkAction"
          >{{ targetIds.length }}件 を操作
        </b-button>
      </b-input-group>
    </div>
  </div>
</template>

<script lang="ts">
export default {
  props: {
    modelName: { required: true },
    virtualModelName: { required: true },
    targetIds: { required: true },
    updatable: { required: false, default: null },
    deletable: { required: false, default: null },
  },
  data() {
    return {
      selectedAction: '',
    }
  },
  // itemsをinjectする
  inject: ['items'],
  computed: {
    model() {
      return $core.$models[this.modelName]
    },
    virtualModel() {
      return $core.$models[this.virtualModelName]
    },
    controlOptions() {
      let options: { value: '' | { action: string }; text: string }[] = [
        { value: '', text: '-- 選択してください --' },
      ]
      if (
        this.updatable !== false &&
        this.model.updatable !== false &&
        (!this.virtualModel || this.virtualModel.updatable !== false)
      ) {
        options.push({ value: { action: 'bulkEdit' }, text: '一括編集' })
      }
      if (
        this.deletable !== false &&
        this.model.deletable !== false &&
        (!this.virtualModel || this.virtualModel.deletable !== false)
      ) {
        options.push({ value: { action: 'bulkDelete' }, text: '一括削除' })
      }
      if ($core.$embAuth.user.isAdmin) {
        options.push({ value: { action: 'exportAsJson' }, text: 'JSONエクスポート' })
      }
      if (this.model.bulkControlActions) {
        options = [
          ...options,
          ...Object.keys(this.model.bulkControlActions).map(key => ({
            value: { action: key },
            text: this.model.bulkControlActions[key].label || key,
          })),
        ]
      }
      if (this.virtualModel && this.virtualModel.bulkControlActions) {
        options = [
          ...options,
          ...Object.keys(this.virtualModel.bulkControlActions).map(key => ({
            value: { action: key },
            text: this.virtualModel.bulkControlActions[key].label || key,
          })),
        ]
      }
      return options
    },
    listItems() {
      return this.items
    },
    checkedData() {
      const pKeyName = this.model.primaryKeyColName || 'id'
      return this.listItems.filter(
        item =>
          this.targetIds.indexOf(item[pKeyName]) >= 0 ||
          this.targetIds.indexOf(`${item[pKeyName]}`) >= 0,
      )
    },
  },
  methods: {
    async runBulkAction() {
      const action = (this.selectedAction || {}).action
      if (!this.selectedAction || !this.targetIds.length || !action) {
        return // do nothing
      }
      const definedActions = Object.assign(
        {},
        this.model.bulkControlActions || {},
        this.virtualModel && this.virtualModel.bulkControlActions
          ? this.virtualModel.bulkControlActions
          : {},
      )
      $core.$loading.start('処理中です...', 'overlay')
      try {
        if (definedActions[action] && definedActions[action].function) {
          await definedActions[action].function({
            targetIds: this.targetIds,
            modelName: this.modelName,
            virtualModelName: this.virtualModelName,
          })
        } else if (action === 'exportAsJson') {
          $core.$utils.downloadJsValue(this.checkedData, this.virtualModelName || this.modelName)
        } else if (action === 'bulkDelete') {
          await $core.$storeMethods.bulkDelete({
            collectionName: this.modelName,
            docIds: this.targetIds,
          })
          this.$emit('clearIds', {})
        } else if (action === 'bulkEdit') {
          // Modalに渡す
          $core.$modals.openModal({
            component: $frameworkUtils.defineAsyncComponent(() =>
              import('./ModelPartialBulkEdit.vue'),
            ),
            componentProps: {
              passedIds: this.targetIds,
              modelName: this.modelName,
              virtualModelName: this.virtualModelName,
            },
          })
        }
      } catch (e) {
        $core.$toast.errorToast('処理に失敗しました。')
        throw e
      } finally {
        $core.$loading.finish()
      }
    },
    // 選択解除
    resetSelection() {
      this.$emit('clearIds')
    },
  },
}
</script>
