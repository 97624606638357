<template>
  <component style="min-height: 100px" :is="componentDef"></component>
</template>
<script>
export default {
  props: {
    tag: { default: 'div' },
    content: { default: '' },
  },
  computed: {
    componentDef() {
      const tag = this.tag || 'div'
      return {
        template: `<${tag}>${this.content || ''}</${tag}>`,
      }
    },
  },
}
</script>
