import { registerComposableComponentSettings } from '../'

registerComposableComponentSettings('Ficon', {
  label: 'アイコン',
  category: 'Basic',
  configColumns: {
    type: {
      type: 'STRING',
      defaultValue: 'cog',
      inputComponent: 'IconSelectInput',
    },
  },
})
