<template>
  <div
    class="composable-model-form-input"
    :class="wrapperClasses"
    v-if="ComposableModelInputServiceInstance && ComposableModelInputServiceInstance.isEnabled"
  >
    <div v-if="shouldDisplayLabel" class="small model-form-group--row-colname">
      <span class="badge border border-secondary text-secondary rounded-pill">
        {{ colName }}
      </span>
    </div>
    <label class="model-form-group--label" v-if="shouldDisplayLabel">
      <ModelColumnInput
        v-if="colDef.type === 'BOOLEAN'"
        :colDef="colDef"
        :colName="colName"
        :model-value="CCModelFormOneRecordServiceInstance.record[colName]"
        :record="CCModelFormOneRecordServiceInstance.record"
        :recordRoot="CCModelFormOneRecordServiceInstance.recordRoot"
        :isNewRecord="CCModelFormOneRecordServiceInstance.isNewRecord"
        :shouldDisabled="shouldDisabled"
        :customLabel="customLabel"
        :validationError="validationError"
        @update:modelValue="
          (value) =>
            CCModelFormOneRecordServiceInstance.updateValues({ [colName]: value }, vueInstance)
        "
        @validationError="
          (error) => CCModelFormOneRecordServiceInstance.updateErrors({ [colName]: error })
        "
      />
      <span
        v-if="colDef?.comment && colDef.type !== 'BOOLEAN'"
        v-b-tooltip.hover="
          colDef?.comment ? `<div class='text-left'>${colDef.comment}</div>` : undefined
        "
      >
        <span class="model-form-group--label--text">{{ colDef.label || colName }}</span>
        <span
          v-if="colDef.validate && colDef.validate.notEmpty"
          class="model-form-group--not-empty-mark"
        />
        <helpicon v-if="colDef.comment" />
      </span>
      <span v-else>
        <span class="model-form-group--label--text">{{ colDef.label || colName }}</span>
        <span
          v-if="colDef.validate && colDef.validate.notEmpty"
          class="model-form-group--not-empty-mark"
        />
      </span>
    </label>
    <div v-if="initialized">
      <ModelColumnInput
        v-if="colDef.type !== 'ARRAY_OF_OBJECT' && colDef.type !== 'BOOLEAN'"
        :colDef="colDef"
        :colName="colName"
        :model-value="CCModelFormOneRecordServiceInstance.record[colName]"
        :record="CCModelFormOneRecordServiceInstance.record"
        :recordRoot="CCModelFormOneRecordServiceInstance.recordRoot"
        :isNewRecord="CCModelFormOneRecordServiceInstance.isNewRecord"
        :shouldDisabled="shouldDisabled"
        :customLabel="customLabel"
        :validationError="validationError"
        @update:modelValue="
          (value) =>
            CCModelFormOneRecordServiceInstance.updateValues({ [colName]: value }, vueInstance)
        "
        @validationError="
          (error) => CCModelFormOneRecordServiceInstance.updateErrors({ [colName]: error })
        "
      />
      <div v-else>
        <ModelFormOneRecord
          v-for="(oneRecordService, index) in CCModelFormOneRecordServiceInstance
            .nestedOneRecordServicesByColumnNames[colName]"
          :key="index"
          :oneRecordService="oneRecordService"
        >
          <div class="d-flex flex-wrap" style="position: relative">
            <ArrayOfObjectControls :rowIndex="index" :colName="colName" />
            <ComposableModelInput
              v-for="(c, colName) in oneRecordService.columns"
              :col-name="colName"
              :key="index + '_' + colName"
            />
          </div>
        </ModelFormOneRecord>
        <div class="pt-2">
          <span
            v-if="
              !colDef.maxValueLength ||
              CCModelFormOneRecordServiceInstance.nestedOneRecordServicesByColumnNames[colName]
                .length < colDef.maxValueLength
            "
            class="btn btn-sm btn-outline-primary"
            @click="() => addRow(1)"
            >+ {{ colDef.label || colDef.name }} のデータを追加</span
          >
        </div>
      </div>
    </div>
    <div class="modelInput-validation-error-message text-danger small" v-if="errorMessage">
      {{ errorMessage }}
    </div>
  </div>
</template>
<script lang="ts">
import { registerComposableComponentSettings } from '../../../ComposableComponents'
import {
  ColumnDef,
  ColumnDefByColName,
  ComponentResolver,
  SelectOptionItem,
} from '../../../../common/$models/ModelDef'
import { codeInputCommonAttrs } from '../../../../common/$models'
import { inputTypes, colTypeInputTypeMap } from '../../../../front/ModelForm/ModelInput'
import { inject, shallowRef } from 'vue'
import { ComposableModelInputService } from '../ComposableModelInputService'
import { CCModelFormOneRecordService } from '../CCModelFormOneRecordService'
import { judgeModelInputComponentTypeByColDef } from '../judgeModelInputComponentTypeByColDef'
import { CCModelFormColumnService } from '../CCModelFormColumnService'
import ModelColumnInput from '../../../../plugins/ComposableModelForm/front/components/ModelColumnInput.vue'
import ArrayOfObjectControls from '../../../../plugins/ComposableModelForm/front/components/ArrayOfObjectControls.vue'
import ModelFormOneRecord from '../../../../plugins/ComposableModelForm/front/components/ModelFormOneRecord.vue'
import { getComposableDataListFromSettingColumnInstance } from '../../../ComposableDataListComponents/front/patrials/dataListComposableColumnNameSelectionSettingColumnDef'
import { columnsIntoSelectableOptions } from '../../../ModelServices/modelFactoryUtils'

const name = 'ComposableModelInput'
export const ComposableComponentModelInputConfigColumns: ColumnDefByColName = {
  colName: {
    type: 'STRING',
    label: 'カラム名',
    dynamicSelections: true,
    selections(
      record: any,
      currentValue: any,
      initialValue: any,
      recordRoot: any,
      callerVueInstance: any,
    ): Promise<any[]> | any[] {
      const parent = getComposableDataListFromSettingColumnInstance(
        callerVueInstance,
        'ComposableModelForm',
      )
      if (!parent) {
        return []
      }
      const virtualModelName = parent?.configuredProps?.virtualModelName
      const modelName = parent?.configuredProps?.modelName
      const columns =
        $core.$virtualModels[virtualModelName]?.columns || $core.$models[modelName]?.columns
      if (!columns) {
        return []
      }
      return columnsIntoSelectableOptions(columns)
    },
  },
  columnDefOverrideText: {
    type: 'TEXT',
    label: 'カラム定義オーバーライド',
    inputAttrs: {
      ...codeInputCommonAttrs,
      rows: 8,
    },
  },
  editable: {
    type: 'BOOLEAN',
    label: '編集可能',
  },
  hideLabel: {
    type: 'BOOLEAN',
    label: '項目名を非表示',
  },
}

registerComposableComponentSettings(name, {
  label: 'CCModelInput',
  hasDefaultSlot: true,
  category: 'フォーム',
  configColumns: ComposableComponentModelInputConfigColumns,
})

/**
 * # ComposableModelInput
 *
 * ## 役割
 * - `CCModelFormOneRecordServiceInstance` から渡された `colName` に対応する `record` の値を更新kickする
 * - `ComposableModelInputService` の初期化
 * - Column定義を整形し、 `ModelColumnInput` に props として渡す
 * - `ModelColumnInput` から 値の更新を受け取る (by `@update:model-value`)
 *
 *
 */
export default {
  name,
  components: { ArrayOfObjectControls, ModelColumnInput, ModelFormOneRecord },
  props: {
    colName: {
      type: String,
      required: true,
    },
    columnDefOverrideText: {
      type: String,
      default: () => '',
    },
    editable: {
      type: Boolean,
      default: () => true,
    },
    hideLabel: {
      type: Boolean,
      default: () => false,
    },
  },
  setup() {
    return {
      CCModelFormOneRecordServiceInstance: inject(
        'CCModelFormOneRecordServiceInstance',
      ) as CCModelFormOneRecordService,
    }
  },
  data() {
    return {
      // non-reactive
      inputTypes: shallowRef(inputTypes),
      colTypeInputTypeMap: shallowRef(colTypeInputTypeMap),

      // reactive
      v: '',
      validationError: '',
      inputAttrsOptions: {},
      colInputSelection: [],
      multiSelectOptions: [],
      isLoading: false,
      disabledLabel: '',
      ComposableModelInputServiceInstance: null as ComposableModelInputService | null,
      initialized: false,

      // editcallbackに渡す引数
      vueInstance: this,
      shouldDisabled: false,
      isEnabled: true,
    }
  },
  watch: {
    'CCModelFormOneRecordServiceInstance.record': {
      handler() {
        if (!this.CCModelFormOneRecordServiceInstance.checkAndUpdateNotFoundColName(this.colName))
          return
        this.calculateShouldDisabled()
        this.ComposableModelInputServiceInstance.calculateIsEnabledValue()
      },
      deep: true,
    },
    colDef: {
      handler() {
        if (!this.CCModelFormOneRecordServiceInstance.checkAndUpdateNotFoundColName(this.colName))
          return
        this.calculateShouldDisabled()
        this.ComposableModelInputServiceInstance.calculateIsEnabledValue()
      },
      deep: true,
    },
  },
  async created() {
    if (!this.CCModelFormOneRecordServiceInstance.checkAndUpdateNotFoundColName(this.colName))
      return
    this.calculateShouldDisabled()
    this.ComposableModelInputServiceInstance = new ComposableModelInputService({
      colName: this.colName,
      columnDefOverride: null,
      CCModelFormOneRecordService: this.CCModelFormOneRecordServiceInstance,
      CCModelFormService: this.CCModelFormOneRecordServiceInstance.CCModelFormService,
      colDef: this.colDef,
      vueInstance: this,
    })
    this.ComposableModelInputServiceInstance.calculateIsEnabledValue()
    this.$nextTick(() => {
      console.log(`this.modelInputComponent:`, this.modelInputComponent)
      this.initialized = true
    })
  },
  methods: {
    async calculateShouldDisabled() {
      this.shouldDisabled = await this.calculateShouldDisabledValue()
    },
    addRow(rows = 1, insertPosition = 0) {
      this.CCModelFormOneRecordServiceInstance.addRow(rows, this.colName)
    },
    async calculateShouldDisabledValue() {
      if (!this.colDef) {
        return true
      }
      if (this.colDef.visible === false) {
        return true
      }
      const keyColName = this.CCModelFormServiceInstance.model?.primaryKeyColName || 'id'
      const isNewRecord = (() => {
        try {
          return !this.record[keyColName] && !this.recordRoot[keyColName]
        } catch (e) {
          return false
        }
      })()
      let shouldDisabled =
        this.editable === false ||
        this.colDef.editable === false ||
        (!isNewRecord &&
          (this.CCModelFormServiceInstance.virtualModel || this.CCModelFormServiceInstance.model)
            ?.updatable === false)
      if (!shouldDisabled && typeof this.colDef.editable === 'function') {
        shouldDisabled =
          (await this.colDef.editable(this.CCModelFormOneRecordServiceInstance.record, this)) ===
          false
      }
      if (isNewRecord && this.colDef.editableOnCreate) {
        if (typeof this.colDef.editableOnCreate === 'function') {
          shouldDisabled =
            this.colDef.editableOnCreate(this.CCModelFormOneRecordServiceInstance.record, this) ===
            false
        } else if (this.colDef.editableOnCreate === true) {
          shouldDisabled = false
        }
      }
      return shouldDisabled
    },
  },
  computed: {
    shouldDisplayLabel() {
      if (this.colDef.hideLabel === true || this.hideLabel === true) {
        return false
      }
      return true
    },
    errorMessage() {
      return this.CCModelFormOneRecordServiceInstance.errorMessageByColName[this.colName]
    },
    currentValue() {
      return this.CCModelFormOneRecordServiceInstance.record[this.colName]
    },
    CCModelFormServiceInstance() {
      return this.CCModelFormOneRecordServiceInstance.CCModelFormService
    },
    columnDefOverride() {
      return this.columnDefOverrideText ? JSON.parse(this.columnDefOverrideText) : undefined
    },
    customLabel() {
      if (this.colDef.customLabel) {
        return (value) => {
          return this.colDef.customLabel(
            value,
            this,
            this.CCModelFormOneRecordServiceInstance.recordRoot,
          )
        }
      }
      if (
        this.ComposableModelInputServiceInstance?.selectionLabels &&
        Object.keys(this.ComposableModelInputServiceInstance?.selectionLabels)?.length
      ) {
        return (value) =>
          this.ComposableModelInputServiceInstance.selectionLabels[value] !== undefined
            ? this.ComposableModelInputServiceInstance.selectionLabels[value]
            : value
      }
      return undefined
    },
    colDef(): ColumnDef {
      // this.CCModelFormOneRecordServiceInstance.columnsにthis.colNameが存在するかないか判断
      if (!this.columnDefOverride) {
        return this.CCModelFormOneRecordServiceInstance.columns[this.colName]
      }
      return Object.assign(
        {},
        this.CCModelFormOneRecordServiceInstance.columns[this.colName],
        this.columnDefOverride,
      )
    },
    CCModelFormColumnServiceInstance(): CCModelFormColumnService {
      return this.CCModelFormServiceInstance.columnServices[this.colName]
    },
    isSelectInput() {
      const colInputType = this.CCModelFormServiceInstance.getColInputType(
        this.colName,
        this.columnDefOverride,
      )
      return [inputTypes.MULTISELECT, this.inputTypes.SELECT].indexOf(colInputType) >= 0
    },
    modelInputComponentTypeByColDef(): {
      component: ComponentResolver
      addProps?: Record<string, any>
    } {
      return judgeModelInputComponentTypeByColDef(this.colDef)
    },
    commonAttrs() {
      const _additionalClasses = this.CCModelFormServiceInstance.getAdditionalClasses(
        this.colName,
        this.columnDefOverride,
      )
      const colDef = this.CCModelFormServiceInstance.getColDef(this.colName, this.columnDefOverride)
      let cssClass = `model-input ${_additionalClasses} `
      const common = {
        placeholder: colDef.label,
        disabled: this.CCModelFormServiceInstance.readOnlyMode,
        maxlength: colDef.maxValueLength,
      }
      if (this.validationError) {
        cssClass += ' input-has-error '
      }
      if (colDef.inputAttrs?.class) {
        cssClass += ` ${this.colDef.inputAttrs.class} `
      }
      return Object.assign(
        common,
        this.modelInputComponentTypeByColDef.addProps,
        colDef.inputAttrs,
        { class: cssClass },
      )
    },
    // TODO: 別ファイル `judgeModelInputComponentTypeByColDef.ts` `judgeModelInputComponentTypeByColDef(colDef: ColumnDef): string` に切り出して、関数化
    modelInputComponent(): string {
      return this.modelInputComponentTypeByColDef.component
    },
    disabledClass() {
      return 'p-1 model-input-disable-value'
    },
    wrapperClasses() {
      return [
        `composable-model-form-input--type--${this.colDef.type}`,
        `composable-model-form-input--colName--${this.colName}`,
        this.colDef?.width
          ? Object.keys(this.colDef?.width)
              .map((key) => `col-${key}-48-${this.colDef.width[key]}`)
              .join(' ')
          : '',
      ]
    },
  },
  // inject: {
  //   CCModelFormServiceInstance: {
  //     default: null,
  //   },
  // },
}
</script>
