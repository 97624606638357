// @ts-ignore
import importDataFromExternal from './importDataFromExternal.vue'
import { $models, $modelsLoader } from '../../common/$models'
import { dataImportSettings } from './ModelDataImportSettings'

export const useDataImportSettingPage = () => {
  if (!$models.dataImportSettings) {
    $modelsLoader.loadModel(dataImportSettings)
  }
  $core.$router.addRoutes([
    {
      path: '/importDataFromExternal/:importType?', // importType is optional param
      component: importDataFromExternal,
    },
  ])
}
