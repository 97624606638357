import { singletonInstanceSummoner } from '../../common/singletonInstanceSummoner'
import { registerFunctionLoggedInExecuteOnce } from '../../common/utils'

/**
 * # $core.$userMetaData
 * - ユーザのメタデータを保存, loadするサービス。
 * - データ保存先: Table上, `directus_users.metaData` (JSON型のカラム) へkey-value形式で保存されます。
 *
 * ## get
 * ```ts
 * // 取得
 * const theVal = await $core.$userMetaData.get('someKey')
 * ```
 *
 * ## set
 * - `save` は `set` のaliasです。
 * ```ts
 * // 保存
 * await $core.$userMetaData.set('someKey', {some: 'vvv'})
 * // または、
 * // await $core.$userMetaData.save('someKey', {some: 'vvv'})
 * ```
 *
 * ## bulkSet
 * ```ts
 * // 一括保存
 * await $core.$userMetaData.bulkSet({
 *   someKey: {some: 'vvv'},
 *   estimatedAge: 28,
 *   estimatedGender: 'female',
 * })
 * ```
 *
 * @summaryOnly
 * @coreDocPath $core/30.utils/302.userMetaData
 */
export class UserMetaDataService {
  _metaData: any

  constructor() {
    this._metaData = {}
    // signIn時にデータロード
    registerFunctionLoggedInExecuteOnce({
      appHookFunctionRegisterKey: 'UserMetaDataService',
      onceLoadFunction: async userData => {
        this._metaData = userData?.metaData || {}
      }, // 初回ロード時に実行する関数を登録
      useAwaitOnLoad: true, // ロード時にawaitするかどうか ( => $core.$embAuth.user が初期化する前に実施するか、または、初期化後に実施で良い場合はfalse)
    })
  }

  static get instance(): UserMetaDataService {
    return singletonInstanceSummoner('UserMetaDataService', UserMetaDataService)
  }

  /**
   * ユーザーデータから metaData フィールドを Loadする
   * データの更新前に発火する
   */
  async _loadData() {
    this._metaData = (await $core.$d.users.me.read()).metaData || {}
  }

  /**
   * データ更新
   * @param keyName
   * @param data
   */
  async _updateData(keyName: string, data: any) {
    const beforeData = this._metaData[keyName]
    try {
      await this._loadData()
      this._metaData[keyName] = data
      await this.__saveCurrentState()
    } catch (e) {
      // Rollback
      this._metaData[keyName] = beforeData
      console.error(e)
      $core.$errorReporter.r(e, this)
    }
  }

  private async _bulkUpdateData(saveData: Record<string, any>) {
    const beforeData = { ...this._metaData }
    try {
      await this._loadData()
      this._metaData = { ...this._metaData, ...saveData }
      await this.__saveCurrentState()
    } catch (e) {
      // Rollback
      this._metaData = beforeData
      console.error(e)
      $core.$errorReporter.r(e, this)
    }
  }

  private async __saveCurrentState() {
    await $core.$d.users.me.update({ metaData: this._metaData })
  }

  // TODO: asyncではないほうが良いとは思うが...?
  get(keyName: string) {
    return this._metaData[keyName]
  }

  /**
   * 値を保存する
   * @param keyName
   * @param data
   */
  async set(keyName: string, data: any): Promise<void> {
    if (!keyName || typeof keyName !== 'string') {
      console.warn(`[${this.constructor.name}] keyName should be string, given keyName: ${keyName}`)
      return
    }
    return this._updateData(keyName, data)
  }

  /**
   * 複数の値を一括保存する
   * @param saveData, key: value のオブジェクト
   */
  async bulkSet(saveData: Record<string, any>): Promise<void> {
    if (!saveData || typeof saveData !== 'object' || Object.keys(saveData)?.length === 0) {
      console.warn(`[${this.constructor.name}] saveData should be object, given: ${saveData}`)
      return
    }
    return this._bulkUpdateData(saveData)
  }

  // alias
  save(keyName: string, data: any) {
    return this.set(keyName, data)
  }
}

export const $userMetaData = UserMetaDataService.instance
