import {
  $frontConfigurationsForComposableFunctionsManager,
  FrontConfigurationsForComposableFunction,
} from '../../ServersideAppHooks/front/$frontConfigurationsForComposableFunctionsManager'
import { SelectOptionItem } from '../../../common/$models/ModelDef'
import { recordCommentSenderTargets } from '../common/coreRecordCommentConstants'

export type RecordCommentAfterSaveNotificationCreationServiceOptions = {
  senderTargets: (keyof typeof recordCommentSenderTargets)[]
  useDefaultTemplate: boolean
  textTemplateKey?: string
  textTemplateKeyForMessageSender?: string
}

/**
 * serverSideAppHooks における選択可能な実行サービスとして登録する
 */
const recordCommentsServersideConfigs: FrontConfigurationsForComposableFunction = {
  key: 'RecordCommentAfterSaveNotificationCreationService',
  label: 'レコードコメント: 追加時の通知生成サービス',
  // レコードコメント のモデルであれば、選択可能
  selectable: (record) => {
    return (
      record.configType === 'modelHookBasedUIDefine' &&
      $core.$models[record.modelName]?.extensionType === 'recordComments'
    )
  },
  // サーバーサイド実行時の錆巣に渡される引数の定義
  argumentsConfigColumns: {
    senderTargets: {
      label: '通知送信先設定',
      type: 'MULTISELECT',
      selections(): Promise<SelectOptionItem[]> | SelectOptionItem[] {
        return Object.keys(recordCommentSenderTargets)
      },
      customLabel: (value: string) => recordCommentSenderTargets[value].label,
      width: {
        sm: 24,
      },
    },
    useDefaultTemplate: {
      label: '通知メッセージにデフォルトテンプレートを使用',
      type: 'BOOLEAN',
      defaultValue: true,
      width: {
        sm: 16,
      },
    },
    textTemplateKey: {
      label: 'アプリ上の通知メッセージテンプレート',
      type: 'REFERENCE',
      referenceOfStore: {
        storeName: 'textTemplates',
        key: 'key',
        label: 'name',
      },
      // relationshipManyToOne: {
      //   collectionName: 'textTemplates',
      //   labelFormatter: (textTemplate) =>
      //     `[${textTemplate.id}] ${textTemplate.name} (${textTemplate.key})`,
      // },
      enableIf: (args) => !args.useDefaultTemplate,
    },
    textTemplateKeyForMessageSender: {
      label: '外部ツール向けの通知メッセージテンプレート',
      type: 'REFERENCE',
      referenceOfStore: {
        storeName: 'textTemplates',
        key: 'key',
        label: 'name',
      },
      enableIf: (args) => !args.useDefaultTemplate,
    },
    // TODO: 通知メッセージのリンク生成関数
    // notiLinkGenerationFunction: {
    //   label: '通知メッセージのリンク生成関数',
    //   type: 'TEXT',
    //   inputAttrs: {
    //     ...codeInputCommonAttrs,
    //   },
    // },
  },
}

export const registerFrontRecordCommentsServersideComposableService = () => {
  $frontConfigurationsForComposableFunctionsManager.register(recordCommentsServersideConfigs)
}
