<template>
  <div class="card">
    <div class="card-header">
      <historyBackButton />
      <slot name="title">
        {{ virtualModel ? virtualModel.tableLabel : model.tableLabel }} 一括編集
      </slot>
      <slot name="titleAppend"></slot>
    </div>
    <div class="card-body">
      <h5>{{ passedIds.length }}件のデータ を一括編集</h5>
      <div class="clearfix">
        <div class="float-left mr-2">
          <b-input-group :prepend="`編集対象を選択:`">
            <b-form-select
              :model-value="selectedColumn"
              v-model="selectedColumn"
              :options="columnSelect"
            />
          </b-input-group>
        </div>
        <!-- selectedColumn: {{selectedColumn}} -->
      </div>
      <div v-if="selectedColumn && selectedColumn.type && initialized" class="row my-3">
        <ModelFormGroup
          :colName="selectedColumn.name"
          :value="value"
          :record="{}"
          :modelName="modelName"
          :virtualModelName="virtualModelName"
          :forceEditable="true"
          :forceEnable="true"
          :validation="true"
          @update:value-and-error="change"
        />
        <div class="col-12 text-center">
          <div v-if="!$core.isProduction" class="text-danger">
            value: {{ value }}<br />
            error: {{ error }}<br />
          </div>
          <p v-if="errorCount" class="text-danger">入力エラーを修正してください</p>
          <b-button
            :disabled="!!errorCount"
            :variant="`outline-${!!errorCount ? 'secondary' : 'success'}`"
            @click.prevent="save"
          >
            一括編集
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { validateIsValidValueAsColumnVal } from '../../../../common/ValidationService/validator'

export default {
  name: 'ModelPartialBulkEdit',
  components: {},
  props: {
    modelName: { required: true },
    virtualModelName: { required: false, default: null },
    onSubmitFunction: { required: false },
    // columns: { required: false, default: false },
    passedIds: { required: false, default: [] },
    successCallback: { required: false, default: null },
    actionLabel: { required: false, default: '一括更新' },
  },
  data() {
    this.model = $core.$models[this.modelName]
    this.id = this.passedId || this.$route.params.id
    this.virtualModel = $core.$virtualModels[this.virtualModelName] || null
    this.colNames = (this.virtualModel || {}).colNames || this.model.colNames
    this.columns = this.colNames.reduce((res, colName) => {
      const colDef = Object.assign(
        {},
        this.model.columns[colName],
        this.virtualModel ? this.virtualModel.columns[colName] || {} : {},
      )
      if (!colDef.unique && colDef.visible !== false && colDef.editable !== false) {
        res[colName] = colDef
      }
      return res
    }, {})
    this.columnSelect = Object.keys(this.columns)
      .filter(colName => {
        const colDef = this.columns[colName]
        if (colDef.bulkEditable === false) {
          return false
        }
        return true
      })
      .map(colName => ({
        text: this.columns[colName].label || this.columns[colName].name || colName,
        value: this.columns[colName],
      }))
    return {
      selectedColumn: {},
      initialized: false,
      value: null,
      error: null,
    }
  },
  computed: {
    errorCount() {
      return Object.values(this.error || {}).filter((v) => !!v).length
    },
  },
  async created() {
    this.initialized = false
    this.data = await $core.$models[this.modelName].find({
      filter: {
        id: {
          _in: this.passedIds,
        },
      },
      limit: -1,
    })
    this.initialized = true
  },
  watch: {
    selectedColumn(newValue, old) {
      this.initialized = false
      this.value = null
      this.error = null
      this.$nextTick(async () => {
        this.initialized = true
      })
    },
  },
  methods: {
    change({ value, error }) {
      console.log(`change:`, { value, error })
      this.value = value
      this.error = error
    },
    refreshData() {
      this.initialized = false
      this.$nextTick(async () => {
        if (this.successCallback && typeof this.successCallback === 'function') {
          this.successCallback()
        }
      })
    },
    async save() {
      if (this.errorCount) {
        $core.$toast.alertDialog('入力エラーを修正してください')
        return // do nothing
      }
      if (
        !this.selectedColumn ||
        !this.selectedColumn.name ||
        validateIsValidValueAsColumnVal(this.value) === false
      ) {
        return // do nothing
      }
      if (
        (await $core.$toast.confirmDialog(`一括編集します、よろしいですか？`, {
          okVariant: 'success',
        })) === false
      ) {
        return
      }
      const currentData = await $core.$models[this.modelName].find({
        filter: {
          id: {
            _in: this.passedIds,
          },
        },
        limit: -1,
      })
      const data = currentData.map(_data => {
        _data[this.selectedColumn.name] = this.value
        return _data
      })
      try {
        // TODO: こちらのアップデート方法について
        await $core.$storeMethods.bulkUpsert({
          collectionName: this.modelName,
          data,
        })
        $core.$toast.successToast(`${currentData.length}件の ${this.actionLabel}に成功しました。`)
      } catch (e) {
        $core.$toast.errorToast(`${this.actionLabel}に失敗しました。${JSON.stringify(e.message)}`)
        console.error(`[/front_nuxt/components/ModelForm.vue] Error: `, JSON.stringify(e))
        throw e
      }
    },
  },
}
</script>
