<template>
  <multiselect
    v-model="values"
    :options="selectionKeys"
    label-field="text"
    class="form-control d-inline-block"
    :multiple="!!enableMultiSelect"
    :customLabel="(val) => selectionObject[val]"
  />
</template>
<script lang="ts">
import { registerComposableComponentSettings } from '../../../../ComposableComponents'
import { inject } from 'vue'
import { ComposableDataListService } from '../../ComposableDataListService'
import { ColumnDefByColName, sortableFieldObject } from '../../../../../common/$models/ModelDef'
import { dataListComposableColumnNameSelectionSettingColumnDef } from '../../patrials/dataListComposableColumnNameSelectionSettingColumnDef'

const name = 'DataListSortSelection'

// ソートコントロールのcolumn定義
const dataListSortCCSettingConfigColumns: ColumnDefByColName = {
  enableMultiSelect: {
    label: '複数指定可能',
    type: 'BOOLEAN',
  },
  selectableColumns: {
    label: '選択可能なカラム',
    ...dataListComposableColumnNameSelectionSettingColumnDef,
    type: 'MULTISELECT',
  },
}

registerComposableComponentSettings(name, {
  label: 'ソート順',
  configColumns: dataListSortCCSettingConfigColumns,
})

export default {
  name,
  props: {
    selectableColumns: {
      type: Array,
      default: () => [],
    },
    enableMultiSelect: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    return {
      ComposableDataListServiceInstance: inject<ComposableDataListService>(
        'ComposableDataListServiceInstance',
      ),
    }
  },
  computed: {
    values: {
      get(): string[] | string {
        return this.enableMultiSelect
          ? this.ComposableDataListServiceInstance.query.sort || []
          : this.ComposableDataListServiceInstance.query.sort[0]
      },
      set(value: string[] | string) {
        if (this.enableMultiSelect) {
          this.ComposableDataListServiceInstance.query.sort = value
        } else {
          this.ComposableDataListServiceInstance.query.sort = [value]
        }
      },
    },
    selections(): sortableFieldObject[] {
      if (!this.selectableColumns?.length) {
        return this.ComposableDataListServiceInstance.query.sortableFields || []
      }
      return (this.ComposableDataListServiceInstance.query.sortableFields || []).filter((field) =>
        this.selectableColumns.includes(`${field.value}`.replace(/^-/, ``)),
      )
    },
    selectionObject(): Record<string, string> {
      return this.selections.reduce((acc, cur) => {
        acc[cur.value] = cur.text
        return acc
      }, {})
    },
    selectionKeys(): string[] {
      return Object.keys(this.selectionObject).filter((key) => {
        if (this.enableMultiSelect) {
          const transformedKey = key.startsWith('-') ? key.slice(1) : '-' + key
          return !this.values.includes(transformedKey)
        }
        return true
      })
    },
  },
}
</script>
