<template>
  <a
    href="#"
    class="btn btn-outline-secondary btn-sm p-1 mr-1"
    style="margin-top: -3px"
    v-single-click="() => close()"
  >
    <ficon type="angle-left" class="m-0" />
  </a>
</template>
<script>
export default {
  methods: {
    close() {
      this.$nextTick(() => {
        if ($core.$modals.openingModalCount) {
          const modalVue = $core.$utils.findParentVueComponentByComponentName(
            this,
            'BModal'
          )
          if (modalVue) {
            modalVue.hide()
            return
          }
          $core.$modals.closeTopModal()
          return
        }
        this.$router.go(-1)
      })
    },
  },
}
</script>
