<template>
  <div class="input-array-of-object p-2" v-if="initialized">
    <div
      v-for="(block, blockIndex) in this.v.expressions"
      :key="block.tempKey"
      style="position: relative"
      :data-block="blockIndex"
    >
      <div class="child-row-controls condition-child-row-controls">
        <div class="btn-group">
          <span class="btn text-success" :data-row="blockIndex" @click="addRow(1, blockIndex)">
            <ficon type="plus" :strokeWidth="4" class="mx-0" />
          </span>
          <span class="btn text-black" @click="moveUp(blockIndex)">
            <ficon type="arrow-up" :strokeWidth="3" class="mx-0" />
          </span>
          <span class="btn text-black" @click="moveDown(blockIndex)">
            <ficon type="arrow-down" :strokeWidth="3" class="mx-0" />
          </span>
          <span v-if="!isMinLength" class="btn text-danger" style="" @click="removeRow(blockIndex)">
            <ficon type="trash" :strokeWidth="4" class="mx-0" />
          </span>
        </div>
      </div>
      <OneBlockOfConditionalExpressionBuilder
        :commonAttrs="commonAttrs"
        :col="col"
        :block="block"
        :blockIndex="blockIndex"
        :record="record"
        :recordRoot="recordRoot"
        :builderLabels="builderLabels"
        :propSelections="propSelections"
        :ModelFormService="ModelFormService"
        @update:model-value="change"
      />
    </div>
    <span class="btn btn-outline-primary mb-3" @click="addRow(1)">+ 条件を追加</span>
    <ModelFormGroup
      v-for="(col, colName) in defaultValueDef"
      :key="colName"
      :colName="colName"
      :value="v.defaultValue"
      :record="record"
      :recordRoot="recordRoot"
      :modelName="$parent.modelName"
      :passedColDefinition="col"
      :readOnlyMode="readOnlyMode"
      :virtualModelName="$parent.virtualModelName"
      :validation="true"
      @update:value-and-error="changeDefaultValue"
    />
  </div>
</template>

<script lang="ts">
// dependent on "../modelInput"
import OneBlockOfConditionalExpressionBuilder from './OneBlockOfConditionalExpressionBuilder.vue'
import { defaultValueDef } from './validateWithColDef'

export default {
  components: { OneBlockOfConditionalExpressionBuilder },
  props: {
    modelValue: { required: true },
    col: { required: true },
    record: { required: true },
    commonAttrs: { required: false },
    recordRoot: {},
    readOnlyMode: { required: false, default: null },
    // OneRowOfの入力欄のラベル
    builderLabels: { required: false, default: null },
    // OneRowOfの入力欄のプロパティ選択肢
    propSelections: { required: false, default: null },
    ModelFormService: {},
  },
  data() {
    return {
      initialized: false,
      v: {
        expressions: [
          {
            blocks: [
              {
                propName: '',
                relationalOperator: '',
                threshold: '',
                logicalOperator: '',
                returnValue: '',
              },
            ],
          },
        ],
        defaultValue: '',
      },
      defaultValueValidationError: '',
      expressionsValidationError: '',
    }
  },
  created() {
    this.minLength = 0
    // ラベルの置き換え
    if (this.builderLabels?.defaultValue) {
      defaultValueDef.defaultValue.label = this.builderLabels.defaultValue
    }
    this.defaultValueDef = defaultValueDef
  },
  computed: {
    rowLength() {
      return !this.$parent.v ? 0 : this.$parent.v.length
    },
    isMinLength() {
      return this.minLength >= this.rowLength
    },
    enableControl() {
      return this.commonAttrs.enableControl !== false && this.readOnlyMode !== true
    },
  },
  mounted() {
    const v = this.modelValue || { expressions: [], defaultValue: '' }
    for (let value of v.expressions) {
      value.tempKey = Math.random()
    }
    this.v = v
    this.$nextTick(() => {
      const addRowNumbers = this.minLength - this.rowLength
      if (addRowNumbers > 0) {
        if (addRowNumbers) {
          this.addRow(addRowNumbers)
        }
      }
      this.initialized = true
    })
  },
  methods: {
    change(blockIndex, { value, error }) {
      //this.v.expressions[blockIndex] = value
      this.v.expressions[blockIndex] = value
      this.expressionsValidationError = error
      this._changeCallback()
    },
    changeDefaultValue({ value, error }) {
      this.v.defaultValue = value
      this.defaultValueValidationError = error
      this._changeCallback()
    },
    addRow(rows = 1, insertPosition = null) {
      if (rows <= 0) {
        return // do nothing
      }
      let newRows = []
      const currentVal = [...this.v.expressions]
      if (insertPosition === null) {
        insertPosition = currentVal.length
      }
      for (let i = 0; i < rows; i++) {
        newRows.push(Object.assign(this.newRowValues(), { tempKey: Math.random() }))
      }
      currentVal.splice(insertPosition, 0, ...newRows)
      this.v = { ...this.v, expressions: currentVal }
      this.$nextTick(() => {
        this._changeCallback()
      })
    },
    newRowValues() {
      return {
        blocks: [
          {
            propName: '',
            relationalOperator: '',
            threshold: '',
            logicalOperator: '',
            returnValue: '',
          },
        ],
      }
    },
    removeRow(rowIndex) {
      const currentVal = [...this.v.expressions]
      currentVal.splice(rowIndex, 1)
      this.v = { ...this.v, expressions: currentVal }
      this._changeCallback()
    },
    _changeCallback() {
      this.$emit('update:model-value', {
        value: this.v,
        error: this.defaultValueValidationError + this.expressionsValidationError,
      })
      this.$parent.runValidate()
    },

    moveUp(rowIndex) {
      if (rowIndex < 1) {
        return // do nothing
      }
      const arr = [...this.v.expressions]
      arr.splice(rowIndex - 1, 2, arr[rowIndex], arr[rowIndex - 1])
      this.v = { ...this.v, expressions: arr }
      this.scroll(rowIndex - 1, true)
      this.$nextTick(() => {
        this._changeCallback()
      })
    },
    moveDown(rowIndex) {
      if (rowIndex >= this.modelValue?.expressions?.length - 1) {
        return // do nothing
      }
      const arr = [...this.v.expressions]
      arr.splice(rowIndex, 2, arr[rowIndex + 1], arr[rowIndex])
      this.v = { ...this.v, expressions: arr }
      this.scroll(rowIndex + 1, false)
      this.$nextTick(() => {
        this._changeCallback()
      })
    },
    scroll(newRowIndex, isUp = true) {
      const scrollElement = this.getFrontModal()
      const element = document.querySelector(`[data-block="${newRowIndex}"]`) as HTMLElement
      scrollElement.scrollBy({
        top: isUp ? -element.clientHeight : element.clientHeight,
        left: 0,
        behavior: 'smooth',
      })
    },
    getFrontModal() {
      const modals = document.querySelectorAll('.modal.show')
      if (modals.length === 0) {
        return window
      }
      let max = null
      // @ts-ignore
      for (const modal of modals) {
        if (max === null) {
          max = modal
          continue
        }
        if (Number(max.parentElement.style.zIndex) < Number(modal.parentElement.style.zIndex)) {
          max = modal
        }
      }
      return max
    },
  },
}
</script>
