import { ToastHandlers } from './toastHandlers'

// TODO: typed
type CoreToastOptions = Record<string, any>

/**
 * # $core.$toast
 *
 * 成功/失敗/ワーニング等の通知メッセージ および、OK/キャンセルの確認ダイアログを表示するサービス。
 *
 * ## 利用シーン
 * - APIリクエスト後に成功/エラーメッセージを表示
 * - 確認ダイアログを表示, OK or キャンセル 押下の結果を返す
 *
 *
 * ```ts
 * try {
 *   await someAwaitFunction()
 *   ...
 *   // 通知メッセージを表示 (成功)
 *   $core.$toast.success('成功しました')
 * } catch (e) {
 *   // 通知メッセージを表示 (エラー)
 *   $core.$toast.error('エラーが発生しました')
 * }
 * ```
 *
 * ```ts
 * // 実行前にユーザに確認ダイアログを表示
 * async someMethod() {
 *   if(await $core.$toast.confirm('本当に実行しますか？') === false) {
 *     // キャンセルを選択した場合, 何もしない
 *     return
 *   }
 *   // 実行処理
 *   await otherFunction()
 *   ...
 * }
 * ```
 *
 * ### Toast 表示のサンプルコード
 * <CodeSampleWithDemo pac>
 * <template #title><code>$core.$toast.errorToast</code> example</template>
 * <template #desc>toast.errorToastを表示する</template>
 *
 * ```html
 * <template>
 *   <b-button variant="danger" @click="showErrorToast">Error Toast</b-button>
 * </template>
 * <script>
 * export default {
 *   methods: {
 *     showErrorToast() {
 *       $core.$toast.errorToast('カラム XXX の値が不正です。')
 *     }
 *   }
 * }
 * </script>
 * ```
 *
 * </CodeSampleWithDemo>
 *
 * ### 表示時のオプション `$core.$toast.success('成功しました', options)` について
 * - [Bootstrap Vue の Toast コンポーネントのオプション](https://bootstrap-vue.org/docs/components/toast#component-reference) を指定可能です。
 *
 * ```ts
 * export interface CoreToastOptions {
 *   // Commonly used props
 *   toaster?: string
 *   title?: string | VNode | Array<VNode>
 *   variant?: string // 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark'
 *   solid?: boolean
 *   noAutoHide?: boolean
 *   noHoverPause?: boolean
 *   autoHideDelay?: number
 *   noCloseButton?: boolean
 *   appendToast?: boolean
 *   isStatus?: boolean
 *   noFade?: boolean
 *   toastClass?: string | string[] | Array<any> | object | any
 *   headerClass?: string | string[] | Array<any> | object | any
 *   bodyClass?: string | string[] | Array<any> | object | any
 *   href?: string
 *   to?: string | object | any
 *   // Catch all
 *   [key: string]: any
 * }
 * ```
 * @coreDocPath $core/20.ui/202.toast.md
 */
export class Toast {
  /**
   * @hidden
   */
  config: typeof toastDefaultConfig

  constructor() {
    this.config = toastDefaultConfig
  }

  /**
   * エラーメッセージを表示
   * @param message 表示するメッセージ
   * @param option BvToast オプション
   *
   * @example
   * ```javascript
   * $core.$toast.errorToast('カラム XXX の値が不正です。')
   * ```
   */
  errorToast(message: string, option: Partial<CoreToastOptions> = {}) {
    // @ts-ignore
    return bvToast().toast(message, {
      ...this.config.error,
      ...option,
    })
  }

  // alias of errorToast()
  get error() {
    return this.errorToast
  }

  /**
   * 成功メッセージを表示
   * @param message 表示するメッセージ
   * @param option BvToast オプション
   */
  successToast(message: string, option: Partial<CoreToastOptions> = {}) {
    return bvToast().toast(message, {
      ...this.config.success,
      ...option,
    })
  }

  // alias of successToast()
  get success() {
    return this.successToast
  }

  warningToast(message: string, option: Partial<CoreToastOptions> = {}) {
    return bvToast().toast(message, {
      ...this.config.warn,
      ...option,
    })
  }

  // alias of warningToast()
  get warning() {
    return this.warningToast
  }

  infoToast(message: string, option: Partial<CoreToastOptions> = {}) {
    return bvToast().toast(message, {
      ...this.config.info,
      ...option,
    })
  }

  // alias of infoToast()
  get info() {
    return this.infoToast
  }

  /**
   * @hidden
   * @param message
   * @param option
   */
  invalidDataWarningToast(message: string, option: Partial<CoreToastOptions> = {}) {
    return bvToast().toast(message, {
      ...this.config.invalidData,
      ...option,
    })
  }

  /**
   * 確認メッセージ付きのOK/キャンセルダイアログを表示する。
   * ユーザーがOKを選択した場合は true, キャンセルを選択した場合は false を返す。 (Promise)
   *
   * @param message
   * @param option
   * @returns {Promise<boolean>}
   */
  confirmDialog(message: string, option: Partial<CoreToastOptions> = {}): Promise<boolean> {
    return new Promise(resolve => {
      $core.$modals.openModal({
        modalProps: {
          size: 'sm',
          hideHeader: true,
          centered: true,
          okOnly: false,
          cancelTitle: 'キャンセル',
          okTitle: 'OK',
          okVariant: 'success',
          cancelVariant: 'outline-secondary',
          buttonSize: 'sm',
          footerClass: 'p-1',
          class: 'modal-confirm-dialog',
          ...(option?.modalProps || {}),
          async onClose(onCloseEvent) {
            resolve(onCloseEvent?.trigger === 'ok')
          },
        },
        component: { template: `<h5 class="mb-0">${message}</h5>` },
      })
    })
  }

  alertDialog(message: string, option: Partial<CoreToastOptions> = {}): void {
    $core.$modals.openModal({
      modalProps: {
        size: 'sm',
        hideHeader: true,
        centered: true,
        okOnly: true,
        okVariant: 'outline-secondary',
        buttonSize: 'sm',
        footerClass: 'p-1',
      },
      component: { template: `<h5 class="mb-0">${message}</h5>` },
    })
  }
}

const bvToast = () => {
  // @ts-ignore
  return ToastHandlers
}

const toastDefaultConfig = {
  error: {
    title: 'エラー',
    variant: 'danger',
    'no-auto-hide': true,
    autoHideDelay: 1000000, // 1000000 sec
  },
  success: {
    title: '成功',
    variant: 'success',
    solid: true,
    autoHideDelay: 10000, // 10 sec
  },
  warn: {
    title: '情報',
    variant: 'warning',
    solid: true,
    autoHideDelay: 10000, // 10 sec
  },
  info: {
    title: '',
    variant: 'info',
    solid: true,
    autoHideDelay: 10000, // 10 sec
  },
  confirm: {
    title: null,
    size: 'sm',
    buttonSize: 'sm',
    okVariant: 'primary',
    okTitle: 'OK',
    cancelVariant: 'outline-secondary',
    cancelTitle: 'キャンセル',
    footerClass: 'p-2',
    centered: true,
  },
  invalidData: {
    title: '設定が正しくありません',
    variant: 'warning',
    class: 'position-fixed fixed-bottom m-0 rounded-0',
    'no-auto-hide': true,
    autoHideDelay: 1000000, // 1000000 sec
  },
}

export const $toast = Object.freeze(new Toast())
