export interface ToastOptions {
  // Commonly used props
  toaster?: string
  title?: string
  variant?: string
  solid?: boolean
  noAutoHide?: boolean
  noHoverPause?: boolean
  autoHideDelay?: number
  noCloseButton?: boolean
  appendToast?: boolean
  isStatus?: boolean
  noFade?: boolean
  toastClass?: string | string[] | Array<any> | object | any
  headerClass?: string | string[] | Array<any> | object | any
  bodyClass?: string | string[] | Array<any> | object | any
  href?: string
  to?: string | object | any

  // Catch all
  [key: string]: any
}

const bsToSpVariantMap = {
  primary: 'info',
  success: 'positive',
  warning: 'info',
  danger: 'negative',
}

const addToast = (message: string, options: ToastOptions) => {
  // add element if the wrapper element is not there
  let toastWrapper = document.getElementById('sp-toast-wrapper')
  if (!toastWrapper) {
    /**
     *  <sp-theme
     *     theme="spectrum"
     *     color="light"
     *     scale="small"
     *   >
     */
    toastWrapper = document.createElement('sp-theme')
    toastWrapper.setAttribute('theme', 'spectrum')
    toastWrapper.setAttribute('color', 'light')
    toastWrapper.setAttribute('scale', 'small')
    toastWrapper.id = 'sp-toast-wrapper'
    toastWrapper.style.position = 'fixed'
    toastWrapper.style.top = '0'
    toastWrapper.style.right = '0'
    toastWrapper.style.zIndex = '9999'
    toastWrapper.style.display = 'flex'
    toastWrapper.style.flexDirection = 'column'
    toastWrapper.style.alignItems = 'flex-end'
    window.document.body.appendChild(toastWrapper)
  }
  const toast = document.createElement('div')
  toast.innerHTML = `<sp-toast class="m-2" open variant="${
    bsToSpVariantMap[options.variant]
  }">${message}</sp-toast>`
  toastWrapper.appendChild(toast)
  if (options.autoHideDelay === 0 || options.noAutoHide) return
  setTimeout(() => {
    toastWrapper.removeChild(toast)
  }, options.autoHideDelay || 2000)
}

export const ToastHandlers = {
  toast: (message, anyother: any) => {
    return addToast(message, anyother)
  },
}
