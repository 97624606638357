import { ModelDef } from '../../common/$models/ModelDef'

// import { addModelDoc } from '../../plugins/HelpDoc/coreDocsHelperFunctions'
// addModelDoc('files', 'システム内でアップロードされるファイルを管理します。')

const model: ModelDef = {
  tableName: 'files',
  tableLabel: 'ファイル',
  tableComment: '',
  timestamps: true,
  defaultSort: { key: 'createdAt', order: 'desc' },
  columns: {
    name: {
      label: 'ファイル名',
      type: 'STRING',
      validate: { notEmpty: true },
      searchable: true,
    },
    filePath: {
      label: 'URL',
      type: 'FILEUPLOAD',
      validate: { notEmpty: true },
      editCallback({ row, isNewRecord, newValue, oldValue }) {
        if (newValue && newValue !== oldValue) {
          if (!row.name) {
            row.name = newValue
          }
        }
        return row
      },
    },
    category: {
      label: 'カテゴリ',
      type: 'STRING',
      selectionsWithSelectOptionsMasterGroupName: 'FileCategory',
      createSelectOptionsMasterOnAddNewSelection: true,
      facet: { showOnSearch: true },
    },
    tags: {
      label: 'タグ',
      type: 'MULTISELECT',
      selectionsWithSelectOptionsMasterGroupName: 'FileTags',
      createSelectOptionsMasterOnAddNewSelection: true,
      facet: { showOnSearch: true },
    },
  },
  modelType: 'admin',
}

export const files = model
