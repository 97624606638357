<template>
  <span
    class="d-lg-none align-self-center nav-menu-toggler-button"
    @click="() => ($core.$uiState.sidebarActive = !$core.$uiState.sidebarActive)"
  >
    <ficon :type="$core.$uiState.sidebarActive ? 'x' : 'bars'" />
  </span>
</template>
<script lang="ts">
export default {
  name: 'NavbarToggler',
}
</script>

<style lang="scss">
.nav-menu-toggler-button {
  cursor: pointer;
  font-size: 14px;
}
</style>
