<template>
  <div :class="[$style.loading_message, $style[$core.$loading.type]]">
    <b-spinner variant="success" type="grow" small class="mr-1"></b-spinner>
    {{ $core.$loading.message || 'ロード中...' }}
  </div>
</template>

<style module lang="scss">
.loading_message {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  position: fixed;
  left: 0;
  width: 100%;
  z-index: 999999;
  background: #020521;
  color: white;
  text-align: center;
  font-size: 13px;
  font-family: sans-serif;
  opacity: 0.7;

  &.overlay {
    height: 100vh;
    top: 0;
  }

  &.toast {
    bottom: 0;
  }
}
</style>
