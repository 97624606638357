import { $models, $modelsLoader } from '../../common/$models'
import { customComponentDefinitions } from './ModelCustomComponentDefinitions'

/**
 * 挙動をInstall
 */
export const useCustomComponentDefinitionsFeature = () => {
  if (!$models.customComponentDefinitions) {
    $modelsLoader.loadModel(customComponentDefinitions)
  }
}
