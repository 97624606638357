import './components/BButton.config'
import './components/Ficon.config'
import TextContent from './components/TextContent.vue'
import RichTextEditorContent from './components/RichTextEditorContent.vue'
import SimpleImage from './components/SimpleImage.vue'
import TabGroup from './components/TabGroup.vue'
import TabGroupItem from './components/TabGroupItem.vue'

export const initComposableComponents = () => {
  $core.VueClass.component('TextContent', TextContent)
  $core.VueClass.component('SimpleImage', SimpleImage)
  $core.VueClass.component('TabGroup', TabGroup)
  $core.VueClass.component('TabGroupItem', TabGroupItem)
  $core.VueClass.component('RichTextEditorContent', RichTextEditorContent)
}
