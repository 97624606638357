import { ColumnDefByColName, ModelDef } from '../$models/ModelDef'
import { ColumnTypes } from '../$models/ModelDef'
import { deepmerge, displayModelNameWithLabel } from '../utils'
import { addModelDoc } from '../../plugins/HelpDoc/coreDocsHelperFunctions'
import {
  modelDefinitions,
} from '../modelDefinitions/modelDefinitions'
import { DBDefinedModelDefinitionColumns } from '../modelDefinitions/DBDefinedModelDefinitionColumns'

const modelName = 'virtualModelDefinitions'
// Model 一覧に表示される Docs を追加
addModelDoc(
  modelName,
  'Virtualモデル定義では、ベースとなるモデル定義を指定して、カラムの表示順や項目表示名 及び挙動を変更した異なる見え方の一覧・詳細画面を定義することが可能です。<br/>同一のモデル (テーブル) にデータを格納しておきたいが、レコードの状態に応じて異なる区分けで表示・編集したい場合に便利です。<br/>(利用例: モデル定義 "受注情報" をベースとして、 複数のVirtualモデル定義 "見積", "出荷待ち", "請求待ち" を定義する など。)',
)

/**
 * ModelDef.columns(ColumnDef) のうち、clone する際に無視するカラム名
 */
const ignoreTargetsOfOverrideableColumnPropsForVirtualModelDef = ['key', 'label', 'type']

export const overrideableColumnKeysOfModelDefinitionForVirtualModelDefinition = (): string[] => {
  return Object.keys(modelDefinitions.columns.columns.columns).filter(
    (key) => !ignoreTargetsOfOverrideableColumnPropsForVirtualModelDef.includes(key),
  )
}
const cloneModelDefinitionColumnsDefinitionsForEdit = (): ColumnDefByColName => {
  const cloned = deepmerge({}, modelDefinitions.columns.columns.columns)
  ignoreTargetsOfOverrideableColumnPropsForVirtualModelDef.forEach((key) => {
    delete cloned[key]
  })
  return Object.keys(cloned).reduce((columns: ColumnDefByColName, key) => {
    const existingEnableIf = cloned[key].enableIf || (() => true)
    columns[key] = {
      ...cloned[key],
      // groupKey: undefined,
      enableIf: (row, rowRoot) => {
        return existingEnableIf(row, rowRoot) && row.overrideAttrs?.indexOf(key) >= 0
      },
    }
    return columns
  }, {})
}

/**
 * ModelDef 属性 のうち、 VirtualModel に clone する際に無視するカラム名
 */
// const cloneIgnoreColNamesForModelDefinition: (keyof DBDefinedModelDefinitionColumns | 'createdAt' | 'updatedAt')[] = ['id', 'tableName', 'tableLabel', 'tableComment', 'primaryKeyColType', 'doNotSyncModel', 'columns', 'createdAt', 'updatedAt', 'metaData']
const _cloneModelDefinitionKeys: (keyof DBDefinedModelDefinitionColumns)[] = [
  'creatable',
  'updatable',
  'deletable',
  'enableCheckDuplicateUpdateOnBeforeSave',
  'replicable',
  'timestamps',
  'defaultSort_key',
  'defaultSort_order',
  'bulkControllable',
  'formStyle_type',
  'formColumnGroupsAsArray',
  'otherModelAttributes',
  'enableKeywordSearch',
  'useBeforeSaveFunction',
  'beforeSaveFunctionDef',
  'enableSearchConditionSave',
  'keywordSearchTargetColumns',
]
export const overrideableModelDefinitionKeysForVirtualModelDefinition =
  (): (keyof DBDefinedModelDefinitionColumns)[] => {
    // @ts-ignore
    // return Object.keys(modelDefinitions.columns).filter(key => !cloneIgnoreColNamesForModelDefinition.includes(key))
    return _cloneModelDefinitionKeys
  }
const cloneModelDefinitionsForEdit = (): ColumnDefByColName => {
  const cloned = deepmerge({}, modelDefinitions.columns)
  // cloneIgnoreColNamesForModelDefinition.forEach(key => {
  //   delete cloned[key]
  // })
  return overrideableModelDefinitionKeysForVirtualModelDefinition().reduce(
    (columns: ColumnDefByColName, key) => {
      const existingEnableIf = cloned[key].enableIf || (() => true)
      columns[key] = {
        ...cloned[key],
        // groupKey: undefined,
        enableIf: (row, rowRoot) => {
          return existingEnableIf(row, rowRoot) && row.overrideAttrs?.indexOf(key) >= 0
        },
      }
      return columns
    },
    {},
  )
}

const virtualModelDefinitionsBaseColumnsColumns: ColumnDefByColName = {
  key: {
    type: ColumnTypes.String,
    dynamicSelections: true,
    selections(
      record: any,
      currentValue: any,
      initialValue: any,
      recordRoot: any,
    ): Promise<any[]> | any[] {
      const selectedColNames = recordRoot.columns?.map((c) => c.key) || []
      return $core.$models[recordRoot.baseModel].colNames.filter(
        (colName) => selectedColNames.indexOf(colName) === -1,
      )
    },
    validate: {
      notEmpty: true,
    },
    customLabel: (value, callerVueInstance, recordRoot) => {
      const label = $core.$models[recordRoot.baseModel]?.columns[value]?.label
      return label && value !== label ? `${value} (${label})` : value
    },
    comment: '実データのキーを定義します',
    label: 'キー',
    editCallback: ({ row, newValue, oldValue, callerVueInstance }) => {
      if (oldValue === newValue) return row
      // label を 元のモデルのカラム定義から取得
      const label =
        $core.$models[callerVueInstance?.recordRoot?.baseModel]?.columns[newValue]?.label
      if (label) {
        row.label = label
      }
      return row
    },
    disabledComponent: {
      template: `<code class="small">{{ displayLabel }}</code>`,
      computed: {
        displayLabel() {
          const key = this.$parent.value
          const label = $core.$models[this.$parent?.recordRoot?.baseModel]?.columns[key]?.label
          const type = $core.$models[this.$parent?.recordRoot?.baseModel]?.columns[key]?.type
          return (label && key !== label ? `${key} (${label})` : key) + ` [type:${type}]`
        }
      }
    },
    editable: (row, callerVueInstance, recordRoot) => {
      return !$core.$virtualModels[recordRoot.name]?.columns?.[row.key]
    },
  },
  label: {
    type: ColumnTypes.String,
    label: '表示名',
    validate: { notEmpty: true },
  },
  overrideAttrs: {
    type: ColumnTypes.MultiSelect,
    // inputComponent: 'MultiselectCheckboxesInput',
    selections: () => {
      const cols = overrideableColumnKeysOfModelDefinitionForVirtualModelDefinition()
      return cols
        .sort((a, b) => {
          // modelDefinitions.columns.columns.columns[colName]?.orderOnForm を利用して sort
          const aOrder = modelDefinitions.columns.columns.columns[a]?.orderOnForm || 0
          const bOrder = modelDefinitions.columns.columns.columns[b]?.orderOnForm || 0
          return aOrder - bOrder
        })
        .sort((a, b) => {
          // modelDefinitions.columns.columns.columns[colName]?.orderOnForm を利用して sort
          const aOrder = modelDefinitions.columns.columns.columns[a]?.orderOnForm || 0
          const bOrder = modelDefinitions.columns.columns.columns[b]?.orderOnForm || 0
          return aOrder - bOrder
        })
        .map((colName) => {
          const label = modelDefinitions.columns.columns.columns[colName]?.label
          return {
            value: colName,
            label: `${label} [${colName}]`,
          }
        })
    },
    label: '上書きするカラム定義',
    // customLabel: (v) => modelDefinitions.columns.columns.columns[v]?.label,
    displayAsDetail: true,
    width: { xs: 48 },
    inputHelpText: '上書きするカラム定義の属性を選択します。',
  },
}

const virtualModelDefinitionsBaseColumns: ColumnDefByColName = {
  id: {
    type: ColumnTypes.UUID,
    label: 'ID',
    visible: false,
    primaryKey: true,
  },
  baseModel: {
    label: 'ベースモデル定義',
    inputHelpText: '基底となるモデル名を選択',
    type: 'STRING',
    selections: () => Object.keys($core.$models),
    customLabel: (val, callerVueInstance, recordRoot) => {
      return displayModelNameWithLabel(val)
    },
    validate: { notEmpty: true },
    inputAttrs: { wrapperClass: 'col-4' },
    editable: false,
    editableOnCreate: true,
    listItemAttrs: { class: { 'no-ellipsis': true, } },
  },
  name: {
    label: 'Virtualモデル名',
    type: 'STRING',
    unique: true,
    validate: {
      notEmpty: true,
      isAlphaNumberHyphenAndUnderscore: (val) => {
        return /^[a-zA-Z0-9_-]*$/.test(val)
          ? ''
          : '英数字とハイフン・アンダースコアで設定してください'
      },
      shouldFirstLetterBeAlphabet: (val) => {
        // 頭文字は [a-zA-Z] である必要がある
        return /^[a-zA-Z]/.test(val) ? '' : '頭文字は英字で設定してください'
      },
    },
    listItemAttrs: { class: { 'no-ellipsis': true, } },
  },
  tableLabel: {
    label: '表示名',
    type: 'STRING',
    validate: { notEmpty: true },
    listItemAttrs: { class: { 'no-ellipsis': true, } },
  },
  dataFilters: {
    label: 'データフィルタ条件',
    type: ColumnTypes.JSON,
    inputHelpText: '本Virtualモデルで表示する対象となるデータのフィルタ条件を設定します。',
    inputComponent: {
      template: '<FilterResultDisplayContainer :emitValueAsObject="true" v-bind="attrsMerged"/>',
      computed: {
        attrsMerged() {
          return {
            ...this.$attrs,
            collectionName: this.$attrs.recordRoot.baseModel,
          }
        },
      },
    },
    visibleOnIndex: false,
    width: { xs: 48 },
    virtualColumnOf: 'metaData',
    groupKey: 'basicTab',
  },
  overrideAttrs: {
    type: ColumnTypes.MultiSelect,
    columnSchema: {
      data_type: 'text',
    },
    // inputComponent: 'MultiselectCheckboxesInput',
    selections: () => {
      const cols = overrideableModelDefinitionKeysForVirtualModelDefinition()
      console.log(`cloneModelDefinitionKeys: `, cols)
      return cols
        .sort((a, b) => {
          // modelDefinitions.columns.columns.columns[colName]?.orderOnForm を利用して sort
          const aOrder = modelDefinitions.columns[a]?.orderOnForm || 0
          const bOrder = modelDefinitions.columns[b]?.orderOnForm || 0
          return aOrder - bOrder
        })
        .map((colName) => {
          const label = modelDefinitions.columns[colName]?.label || colName
          return {
            value: colName,
            label: label + ` [${colName}]`,
          }
        })
    },
    label: '上書きするモデル定義の属性',
    // customLabel: (v) => modelDefinitions.columns.columns.columns[v]?.label,
    displayAsDetail: true,
    width: { xs: 48 },
    // inputHelpText: '上書きするモデル定義の属性を選択します。',
    inputAttrs: {
      class: 'small',
    },
  },
  metaData: {
    type: 'ARRAY_OF_OBJECT',
    visible: false,
  },
  columns: {
    groupKey: 'colAccordion',
    label: 'カラム定義',
    hideLabel: true,
    type: ColumnTypes.ArrayOfObject,
    inputAttrs: { wrapperClass: 'col-12' },
    visibleOnIndex: false,
    enableIf: (row) => row.baseModel && $core.$models[row.baseModel],
    columns: {
      ...virtualModelDefinitionsBaseColumnsColumns,
      ...cloneModelDefinitionColumnsDefinitionsForEdit(),
    },
  },
}

const virtualModelDefinitionsBaseColumnsKeys = Object.keys(virtualModelDefinitionsBaseColumns)
const virtualModelDefinitionsBaseColumnsColumnsKeys = Object.keys(
  virtualModelDefinitionsBaseColumnsColumns,
)

/**
 * VirtualModels の定義を入れておくための Model
 */
export const virtualModelDefinitions: ModelDef = {
  tableName: modelName,
  tableLabel: 'Virtualモデル定義',
  primaryKeyColType: 'UUID',
  defaultSort: { key: 'id', order: 'desc' },
  modelType: 'admin',
  formColumnGroups: {
    ...modelDefinitions.formColumnGroups,
  },
  columns: {
    ...virtualModelDefinitionsBaseColumns,
    ...(cloneModelDefinitionsForEdit()),
  },
  afterSave: async (saved: any): Promise<boolean> => {
    // modelDefinitionsLoaderServiceを使用して$modelsをリロード
    await $core.$virtualModelDefinitionsLoaderService.loadVirtualModelDefinitions([saved.baseModel])
    // For component re-rendering on next frame
    setTimeout(() => {
      $core.$uiState.latestModelDefinitionLoadedTime = new Date().getTime()
    }, 1)
    return true
  },
  afterDelete() {
    setTimeout(() => {
      $core.$uiState.latestModelDefinitionLoadedTime = new Date().getTime()
    }, 1)
  },
}

/**
 * virtualModelDefinitionsのデータを整形 on loaded
 * @param unformattedModelDefinition
 */
export const formatVirtualModelDefinitionDataWithOverrideAttrs = (unformattedModelDefinition) => {
  /**
   * overrideAttrs を 適用, overrideable な Model Props の場合に override 対象になっていなければ props を delete する
   */
  if (
    unformattedModelDefinition.overrideAttrs &&
    Array.isArray(unformattedModelDefinition.overrideAttrs)
  ) {
    const overrideAttrs = unformattedModelDefinition.overrideAttrs
    for (const key in unformattedModelDefinition) {
      if (
        overrideAttrs.indexOf(key) === -1 &&
        virtualModelDefinitionsBaseColumnsKeys.indexOf(key) === -1
      ) {
        delete unformattedModelDefinition[key]
      }
    }
  }
  // columns の中身も同様に
  if (unformattedModelDefinition.columns?.length > 0) {
    for (const column of unformattedModelDefinition.columns) {
      if (column.overrideAttrs && Array.isArray(column.overrideAttrs)) {
        const overrideAttrs = column.overrideAttrs
        for (const key in column) {
          if (
            overrideAttrs.indexOf(key) === -1 &&
            virtualModelDefinitionsBaseColumnsColumnsKeys.indexOf(key) === -1
          ) {
            delete column[key]
          }
        }
      }
    }
  }
  return unformattedModelDefinition
}
