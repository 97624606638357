<template>
  <div class="card-layout">
    <div :class="['d-flex', 'flex-wrap']" :style="{ gap: 0, margin: `0 -${cardGapPx / 2}px` }">
      <div
        v-for="(item, index) in items"
        :key="index"
        :class="[
          `col-xs-48-${cardWidthMobile || 48}`,
          `col-sm-48-${cardWidthTablet || 16}`,
          `col-md-48-${cardWidthDesktop || 12}`,
        ]"
        :style="{ padding: `0 ${cardGapPx / 2}px ${cardGapPx}px` }"
      >
        <DataListDisplayCardItem
          :item="item"
          v-bind="cardItemProps"
          :class="disableDefaultCardClick ? '' : `cursor-pointer hover-fade`"
          @click.prevent="(_event) => itemClick(item.id, _event, item)"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject, computed, h } from 'vue'
import { registerComposableComponentSettings } from '../../../../../ComposableComponents'
import { ComposableDataListService } from '../../../ComposableDataListService'
import {
  normalAndOutlinedVariantsKeys,
} from '../../../../../ComposableComponents/composableComponentHelpers/variants'
import {
  dataListComposableColumnNameSelectionSettingColumnDef
} from '../../../patrials/dataListComposableColumnNameSelectionSettingColumnDef'
import DataListDisplayCardItem from './DataListDisplayCardItem.vue'
import { DataListDisplayService } from '../DataListDisplayService'

const name = 'DataListDisplayCard'

const vueTemplateHelpTextHtml = `<div class="small">Vue テンプレートとして記載します。 item.columnName で レコードの値にアクセス可能です。 例: <code>{{ item.name }}</code></div>`

registerComposableComponentSettings(name, {
  description: 'カードレイアウトを表示するコンポーネント',
  configColumns: {
    cardWidthMobile: {
      type: 'NUMBER',
      label: 'カード幅 スマホ時',
      inputHelpText: '幅の割合を、48分の1 の単位で設定可能です。 (48を設定すると幅100%)',
      validate: { min: 1, max: 48 },
      defaultValue: null,
    },
    cardWidthTablet: {
      type: 'NUMBER',
      label: 'カード幅 タブレット時',
      validate: { min: 1, max: 48 },
      defaultValue: null,
    },
    cardWidthDesktop: {
      type: 'NUMBER',
      label: 'カード幅 PC時',
      validate: { min: 1, max: 48 },
      defaultValue: null,
    },
    cardGapPx: {
      type: 'NUMBER',
      label: 'カード間の余白',
      validate: { min: 0, max: 100 },
      defaultValue: 12,
      inputAttrs: {
        suffix: 'px',
      }
    },
    cardThumbnail: {
      ...dataListComposableColumnNameSelectionSettingColumnDef,
      label: 'サムネイル画像 カラム名',
    },
    cardBodyTitle: {
      ...dataListComposableColumnNameSelectionSettingColumnDef,
      label: 'タイトル カラム名',
    },
    useCardBodyHTML: {
      type: 'BOOLEAN',
      label: 'テキストで Vue template / HTMLを利用する',
      defaultValue: false,
    },
    cardBodyText: {
      ...dataListComposableColumnNameSelectionSettingColumnDef,
      enableIf: (row) => !row.useCardBodyHTML,
      label: 'テキスト カラム名',
    },
    cardBodyTextSize: {
      type: 'STRING',
      label: 'カードボディテキストサイズ',
      defaultValue: '90%',
      enableIf: (row) => !row.useCardBodyHTML,
    },
    cardBodyHTMLContent: {
      type: 'TEXT',
      inputComponent: 'CodeEditor',
      label: 'カードボディHTML/Vueテンプレートコンテンツ',
      enableIf: (row) => row.useCardBodyHTML,
      inputHelpText: vueTemplateHelpTextHtml,
    },
    cardTags: {
      label: 'カードタグ',
      type: 'ARRAY_OF_OBJECT',
      columns: {
        tagColName: {
          ...dataListComposableColumnNameSelectionSettingColumnDef,
          type: 'STRING',
          label: 'カードタグとして表示するカラム',
        },
        tagVariant: {
          type: 'STRING',
          label: 'カードタグのvariant',
          defaultValue: 'primary',
          selections: () => normalAndOutlinedVariantsKeys,
        },
      }
    },
    showCardFooter: {
      type: 'BOOLEAN',
      label: 'カードフッターを表示',
      defaultValue: false,
    },
    cardFooterContent: {
      type: 'TEXT',
      label: 'カードフッターHTML/Vueテンプレートコンテンツ',
      inputComponent: 'CodeEditor',
      enableIf: (row) => row.showCardFooter,
      inputHelpText: vueTemplateHelpTextHtml,
    },
    disableDefaultCardClick: {
      type: 'BOOLEAN',
      label: 'デフォルトのカードクリックを無効化',
    },
  },
  category: 'CardLayout',
})

export default defineComponent({
  name,
  components: { DataListDisplayCardItem },
  props: {
    items: {
      type: Array,
      required: true,
    },
    cardWidthMobile: {
      type: Number,
      default: null,
    },
    cardWidthTablet: {
      type: Number,
      default: null,
    },
    cardWidthDesktop: {
      type: Number,
      default: null,
    },
    cardGapPx: {
      type: Number,
      default: 12,
    },
    cardThumbnail: {
      type: String,
      default: '',
    },
    cardBodyTitle: {
      type: String,
      default: '',
    },
    cardBodyText: {
      type: String,
      default: '',
    },
    cardBodyTextSize: {
      type: String,
      default: '0.9em',
    },
    cardTags: {
      type: Array,
      default: () => [],
    },
    useCardBodyHTML: {
      type: Boolean,
      default: false,
    },
    cardBodyHTMLContent: {
      type: String,
      default: '',
    },
    showCardFooter: {
      type: Boolean,
      default: false,
    },
    cardFooterContent: {
      type: String,
      default: '',
    },
    disableDefaultCardClick: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    cardItemProps() {
      return {
        cardThumbnail: this.cardThumbnail,
        cardBodyTitle: this.cardBodyTitle,
        cardBodyText: this.cardBodyText,
        cardBodyTextSize: this.cardBodyTextSize,
        cardTags: this.cardTags,
        useCardBodyHTML: this.useCardBodyHTML,
        cardBodyHTMLContent: this.cardBodyHTMLContent,
        showCardFooter: this.showCardFooter,
        cardFooterContent: this.cardFooterContent,
      }
    },
  },
  setup() {
    return {
      items: inject<any[]>('items'),
      ComposableDataListServiceInstance: inject('ComposableDataListServiceInstance') as ComposableDataListService,
      DataListDisplayServiceInstance: inject('DataListDisplayServiceInstance') as DataListDisplayService,
    }
  },
  methods: {
    itemClick(itemId, event, record) {
      if (this.disableDefaultCardClick) {
        return
      }
      // TODO: parent check
      if (event.target.className.indexOf('ignoreItemClick') >= 0) {
        return
      }
      this.DataListDisplayServiceInstance.invokeItemClickFunction(itemId, event, record)
    },
  }
})
</script>

<style scoped>
.card-layout {
  width: 100%;
}
</style>
