import { $appHook } from '../$appHook'
import { $modelsLoader } from '../$models'
import { ColumnDef, ModelDef } from '../$models/ModelDef'
import { frontAppHooks } from '../../front/frontAppHooks'
import { executeStringDefinedFunction, tryParseAsObject, unFlattenObject } from '../../common/utils'
import { registerFunctionLoggedInExecuteOnce } from '../../front/LoggedInExecuteOnceServiceBase'
import { parseValidationConfigIntoColumnDefValidateProp } from './validationConfigs'

export class ModelDefinitionsLoaderService {
  constructor() {
    // 初期化された時点で、ログイン後に実行される関数を登録
    registerFunctionLoggedInExecuteOnce({
      appHookFunctionRegisterKey: 'ModelDefinitionsLoaderService',
      onceLoadFunction: userData => {
        if (!userData) {
          return
        }
        return this.loadModelDefinitions()
      }, // 初回ロード時に実行する関数を登録
      useAwaitOnLoad: true, // ロード時にawaitするかどうか ( => $core.$embAuth.user が初期化する前に実施するか、または、初期化後に実施で良い場合はfalse
      hookPriority: 1000,
    })
  }

  async loadModelDefinitions(modelNames = []) {
    try {
      // modelDefinitionsからmodel情報を取得
      const findQuery = {
        limit: -1,
        sort: ['tableName'],
        filter: modelNames.length === 0 ? {} : { tableName: { _in: modelNames } }
      }
      const modelDefinitions = await $core.$models.modelDefinitions.find(findQuery)
      const formattedModelDefinitions = []
      // ModelDefの型に整形
      for (const unformattedModelDefinition of modelDefinitions) {
        unformattedModelDefinition.isDefinedOnDb = true
        // $core.$modelsLoaderを利用してmodelをロード
        let formattedModelDefinition = formatImportableModel(unformattedModelDefinition)
        const hookName = frontAppHooks.modelDefinitions.beforeInitOnModelDefinitionLoaded(
          formattedModelDefinition.tableName,
        )
        if ($appHook.hasHook(hookName)) {
          formattedModelDefinition = await $appHook.emit(hookName, formattedModelDefinition)
        }
        formattedModelDefinitions.push(formattedModelDefinition)
      }
      await $modelsLoader.loadModels(formattedModelDefinitions)
      await $appHook.emit(frontAppHooks.modelDefinitions.loaded, { modelNames })
    } catch (e) {
      console.error(
        `[modelDefinitionsLoaderService.ts] Failed to load modelDefinition. There might not exists the table, if so you should run "yarn syncModels --models=modelDefinitions" e.message: ${e.message}`,
      )
    }
  }

  /**
   * DBDefinedModelDefinition データを ModelDefの型に整形
   */
  formatDBDefinedModelIntoImportableModelDef = formatImportableModel
}

const parseAsJavascriptObjectFieldNamesInEachColumn = ['inputAttrs']

const __removeFields = ['__removeFields']
const __removeModelUnnecessaryFields = model => {
  __removeFields.forEach(field => {
    delete model[field]
  })
  return model
}

/**
 * ModelDef.columns load時, および VirtualModel.columns 初期化時に deepMerge する ColumnDef の props
 * - 基本的には オブジェクトで記載される ColumnDef の props に対して deepMerge する
 * - 例: inputAttrs.class, relationshipManyToOne.* など
 */
const columnJsDefineDeepMergeTargetProps: (keyof ColumnDef)[] = ['inputAttrs', 'relationshipManyToOne', 'relationshipOneToMany', 'listItemAttrs']
export const columnDefOnLoadOtherColAttributesDeepMerge = (column, otherAttrs) => {
  return columnJsDefineDeepMergeTargetProps.reduce((r, prop) => {
    r[prop] = globalThis.$core.$utils.deepmerge(column[prop] || {}, otherAttrs[prop] || {})
    return r
  }, {
    ...column,
    ...otherAttrs,
  })
}

/**
 * 編集用のmodelを正しいmodelの形に修正
 */
export const formatImportableModel = (model: any): ModelDef => {
  // _で繋がれたkeyをObjectに
  model = unFlattenObject(model)
  model.tableName = model.tableName?.trim()

  // formColumnGroupsAsArray を formColumnGroups に変換
  if (model.formColumnGroupsAsArray?.length > 0) {
    model.formColumnGroups = model.formColumnGroupsAsArray.reduce((r, { key, ...group }) => {
      key = key?.trim()
      r[key] = group
      return r
    }, {})
  }
  model = __removeModelUnnecessaryFields(model)

  // map column
  const columns = model.columns.reduce((r, { key, ...column }) => {
    key = key?.trim()
    // column = __removeIgnoreColFields(column)

    // _で繋がれたkeyをObjectに
    column = unFlattenObject(column)
    // inputAttrsのparse
    for (let i = 0; parseAsJavascriptObjectFieldNamesInEachColumn.length > i; i++) {
      const fieldName = parseAsJavascriptObjectFieldNamesInEachColumn[i]
      if (column[fieldName] && typeof column[fieldName] === 'string') {
        try {
          column[fieldName] = tryParseAsObject(column[fieldName])
        } catch (e) {
          console.warn(
            `fail to parse "${fieldName}" in importableModel[${model.tableName} - ${key}], column[fieldName]:`,
            JSON.stringify(column[fieldName]),
          )
        }
      }
    }
    // Validation定義のparse
    if (column.validationConfigs && column.validationConfigs.length) {
      const parsedValidate = parseValidationConfigIntoColumnDefValidateProp(
        column.validationConfigs,
      )
      column.validate = { ...(column.validate || {}), ...parsedValidate }
    }
    if (column.otherColAttributes) {
      try {
        const otherAttrs = tryParseAsObject(column.otherColAttributes)
        column = columnDefOnLoadOtherColAttributesDeepMerge(column, otherAttrs)
      } catch (e) {
        const msg = `Failed to parse "otherColAttributes" in importableModel[${model.tableName} - ${key}], Error: ${e.message}`
        console.warn(msg)
        // 管理者の場合にのみwarn
        setTimeout(() => {
          if (globalThis.$core?.$embAuth?.user?.isAdmin) {
            globalThis.$core.$toast.warningToast(msg)
          }
        }, 1000)
      }
    }
    r[key] = column
    return r
  }, {})

  model.columns = columns
  model = modelDefConvertFunctionDefinitionPropsToFunction(model)
  if (model.otherModelAttributes) {
    try {
      const parsedOtherModelAttrs = tryParseAsObject(model.otherModelAttributes)
      // merge
      model = {
        ...model,
        ...parsedOtherModelAttrs,
      }
    } catch (e) {
      console.warn(`failed to parse "otherModelAttributes" in importableModel[${model.tableName}]`)
    }
  }
  return model
}

/**
 * Function定義をevalを利用して挙動へ変換
 * beforeSaveFunctionDef => async beforeSave(row, beforeRow)
 * @param model
 * @private
 */
export const modelDefConvertFunctionDefinitionPropsToFunction = model => {
  if (model.useBeforeSaveFunction && model.beforeSaveFunctionDef) {
    const beforeSaveFunc = executeStringDefinedFunction({
      functionArgValues: { row: '' },
      functionString: `${model.beforeSaveFunctionDef}; return row`,
      returnAsExecutableFunction: true,
    })
    model.beforeSave = async (row: any, index?: number, allSaving?: any[]) => beforeSaveFunc({ row, index, allSaving })
  }
  return model
}

export const $modelDefinitionsLoaderService = () => new ModelDefinitionsLoaderService()
