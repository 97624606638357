<template>
  <div
    class="position-relative composable-component-renderer-by-id"
    :class="{
      'hover-show-parent': !!recordId,
    }"
  >
    <AdminRoleOnly
      v-if="openBuilderEditHRef"
      class="position-absolute hover-show-target"
      style="top: 0; right: 0; z-index: 1000; font-size: 11px"
    >
      <a class="bg-white" target="_blank" :href="openBuilderEditHRef">
        <ficon type="edit" />
        Builderで編集</a
      >
    </AdminRoleOnly>
    <ComposableComponentRenderer
      :class="'composable-component-renderer-by-id'"
      v-if="initialized"
      :builderData="builderData"
    />
  </div>
</template>
<script lang="ts">
export default {
  name: 'ComposableComponentRendererById',
  props: {
    pageId: {
      type: String,
      required: false,
    },
    itemKey: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      initialized: false,
      builderData: null,
    }
  },
  async created() {
    if (!this.pageId && !this.itemKey) {
      console.error(
        `[ComposableComponentRendererById] :pageId, :itemKey のどちらかを指定してください。`,
      )
      return
    }
    const data = await this.fetchData()
    if (!data) {
      console.error(
        `[ComposableComponentRendererById] record not found, pageId: "${this.pageId}", itemKey: "${this.itemKey}"`,
      )
      return
    }
    this.builderData = data
    this.$nextTick(() => {
      this.initialized = true
    })
  },
  computed: {
    openBuilderEditHRef() {
      if (this.recordId) {
        return `/#/composable-component-builder/${this.recordId}`
      } else if (this.itemKey) {
        return `/#/m/core_composable_component_definitions/new?key=${this.itemKey}`
      }
      return null
    },
    recordId() {
      return this.builderData?.id
    },
  },
  methods: {
    async fetchData() {
      if (this.pageId) {
        return $core.$models.core_composable_component_definitions.findById(this.pageId)
      }
      if (this.itemKey) {
        return $core.$models.core_composable_component_definitions.findOne({
          filter: {
            key: {
              _eq: this.itemKey,
            },
          },
        })
      }
    },
  },
}
</script>
