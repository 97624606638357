<template>
  <div>
    <input
      ref="excel-upload-input"
      class="excel-upload-input"
      type="file"
      :accept="uploadExcelAccepts"
      @change="handleClick"
    />
    <div
      v-show="!loading"
      class="drop"
      @drop="handleDrop"
      @dragover="handleDragover"
      @dragenter="handleDragover"
    >
      Excel, CSVファイルをドロップ
      <small><br />もしくは<br /></small>
      <b-button style="margin-left: 16px" size="mini" type="primary" v-single-click="handleUpload">
        ファイルを選択
      </b-button>
    </div>
    <div v-show="loading" class="text-center py-4">
      <div v-if="letSelectSheetName && sheetNameSelection.length">
        読み込み対象シートを選択してください:
        <span class="d-inline-block">
          <b-form-select
            :model-value="selectedSheetName"
            v-model="selectedSheetName"
            class="form-control-sm"
            :options="sheetNameSelection"
          ></b-form-select>
        </span>
        <span
          class="btn ml-2"
          :class="!!selectedSheetName ? `btn-primary` : `btn-outline-secondary`"
          :disabled="!!selectedSheetName"
          v-single-click="() => selectSheetName()"
          >読み込み実行</span
        >
      </div>
      <Loading v-else>読み込み中...</Loading>
    </div>
  </div>
</template>

<script lang="ts">
export default {
  props: {
    // sheetNameDetectFunction: {default: false}, // eslint-disable-line
    letSelectSheetName: { default: false, type: Boolean }, // eslint-disable-line
    beforeUpload: Function, // eslint-disable-line
    onSuccess: Function, // eslint-disable-line
    uploadExcelAccepts: {
      default: '.xlsx,.xls,.csv',
      type: String
    },
  },
  data() {
    return {
      loading: false,
      excelData: {
        header: null,
        results: null,
      },
      sheetNameSelection: [],
      selectedSheetName: '',
      selectCallbackFunction: () => {},
    }
  },
  async created() {
    await $core.$exportAsExcel.initLib()
  },
  methods: {
    async generateData({ header, results }) {
      this.excelData.header = header
      this.excelData.results = results
      if (this.onSuccess) {
        return this.onSuccess(this.excelData)
      }
      return
    },
    handleDrop(e) {
      e.stopPropagation()
      e.preventDefault()
      if (this.loading) return
      const files = e.dataTransfer.files
      if (files.length !== 1) {
        $core.$toast.errorToast('1ファイルのみ選択してください')
        return
      }
      const rawFile = files[0] // only use files[0]

      if (!this.isExcel(rawFile)) {
        $core.$toast.errorToast('拡張子 .xlsx, .xls のファイルのみ利用可能です')
        return false
      }
      this.upload(rawFile)
      e.stopPropagation()
      e.preventDefault()
    },
    handleDragover(e) {
      e.stopPropagation()
      e.preventDefault()
      e.dataTransfer.dropEffect = 'copy'
    },
    handleUpload() {
      this.$refs['excel-upload-input'].click()
    },
    handleClick(e) {
      const files = e.target.files
      const rawFile = files[0] // only use files[0]
      if (!rawFile) return
      this.upload(rawFile)
    },
    upload(rawFile) {
      this.$refs['excel-upload-input'].value = null // fix can't select the same excel
      this.sheetNameSelection = []

      let isAllowUpload = true
      if (this.beforeUpload) {
        isAllowUpload = this.beforeUpload(rawFile)
      } else {
        const isOverSizedFile = rawFile.size / 1024 / 1024 > 50
        if (isOverSizedFile) {
          $core.$toast.errorToast('Excelファイルサイズの上限は50MBです。')
          isAllowUpload = false
        }
      }
      if (isAllowUpload) {
        this.readerData(rawFile)
      }
    },
    async readerData(rawFile: File) {
      this.loading = true
      // CSV の場合には 自動的に UTF-8 に変換して Emit する
      if (rawFile.name.match(/\.csv$/i)) {
        const _conv = await $core.$utils.autoDetectEncodingAndConvertToUtf8WithFile(rawFile)
        const arrayOfObjects = $core.$utils.csvParserLite(_conv.text)
        await this.generateData({
          header: Object.keys(arrayOfObjects[0] || {}),
          results: arrayOfObjects
        })
        this.$nextTick(() => {
          this.loading = false
          $core.$loading.finish()
        })
        return
      }
      return new Promise(async (resolve, reject) => {
        const reader = new FileReader()
        reader.onload = async e => {
          const data = e.target.result
          let workbook = null
          try {
            workbook = $core.$exportAsExcel.XLSX.read(data, {
              type: 'array',
              cellDates: true,
            })
          } catch (e) {
            console.error(e)
            globalThis.$core.$toast.errorToast(
              e.message === 'File is password-protected'
                ? 'アップロードするには、パスワード保護を解除して下さい。'
                : 'エラーが発生しました、ファイル内容を確認して下さい。',
            )
            this.loading = false
            return reject(e)
          }
          this.selectCallbackFunction = async targetSheetName => {
            $core.$loading.start('読み込み中...', 'overlay')
            const worksheet = workbook.Sheets[targetSheetName]
            const header = this.getHeaderRow(worksheet)
            const results = $core.$exportAsExcel.XLSX.utils.sheet_to_json(worksheet)
            await this.generateData({ header, results })
            this.$nextTick(() => {
              this.loading = false
              $core.$loading.finish()
            })
            resolve()
          }
          if (this.letSelectSheetName) {
            this.sheetNameSelection = workbook.SheetNames
          } else {
            const targetSheetName = this.sheetNameDetectFunction
              ? this.sheetNameDetectFunction(workbook.SheetNames)
              : workbook.SheetNames[0]
            this.selectCallbackFunction(targetSheetName)
          }
        }
        reader.readAsArrayBuffer(rawFile)
      })
    },
    getHeaderRow(sheet) {
      const headers = []
      const range = $core.$exportAsExcel.XLSX.utils.decode_range(sheet['!ref'])
      let C
      const R = range.s.r
      /* start in the first row */
      for (C = range.s.c; C <= range.e.c; ++C) {
        /* walk every column in the range */
        const cell = sheet[$core.$exportAsExcel.XLSX.utils.encode_cell({ c: C, r: R })]
        /* find the cell in the first row */
        let hdr = 'UNKNOWN ' + C // <-- replace with your desired default
        if (cell && cell.t) hdr = $core.$exportAsExcel.XLSX.utils.format_cell(cell)
        headers.push(hdr)
      }
      return headers
    },
    isExcel(file) {
      return /\.(xlsx|xls|csv)$/i.test(file.name)
    },
    selectSheetName() {
      if (!this.selectedSheetName) {
        console.log(`シート名が不正です。`)
      } else {
        $core.$loading.start('読み込み中...', 'overlay')
        this.$nextTick(() => {
          this.selectCallbackFunction(this.selectedSheetName)
        })
      }
    },
  },
}
</script>

<style scoped>
.excel-upload-input {
  display: none;
  z-index: -9999;
}

.drop {
  border: 2px dashed #bbb;
  width: 600px;
  max-width: 100%;
  height: 9em;
  line-height: 1.8em;
  margin: 0 auto;
  font-size: 20px;
  border-radius: 5px;
  padding: 28px;
  text-align: center;
  color: #bbb;
  position: relative;
}
</style>
