export class CoreBaseError extends Error {
  constructor(message: string) {
    super(message)
    this.name = 'CoreBaseError'
  }
}

export class RecordNotFoundError extends CoreBaseError {
  constructor(message: string) {
    super(message)
    this.name = 'RecordNotFoundError'
  }
}
