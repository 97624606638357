/** Load instance properties **/
import { $appHook } from './$appHook'
import * as $const from './constants'
import { $dayjs } from './$dayjs'
import * as $utils from './utils'
import { AxiosStatic } from 'axios'
import { $dict, DictionaryService } from './$dict/DictionaryService'
import { $configVars, ApplicationConfigVariablesService } from './$configVars/$configVars'
import {
  $frameworkUtils,
  FrameworkFrameworkApiRefForCoreFramework,
} from '../front/$frameworkUtils/$frameworkUtils'

/**
 * サーバサイド・Front共通で利用する $core. の原型 Class
 * 共通で利用できるサービスをここで初期化する
 */
export class CoreBaseClass {
  $axios: AxiosStatic
  $utils = $utils
  /**
   * @deprecated 後方互換
   * @hidden from docs
   */
  $const = $const
  $appHook = $appHook
  isProduction: boolean
  $dayjs: typeof $dayjs = $dayjs
  $dict: DictionaryService = $dict
  $configVars: ApplicationConfigVariablesService = $configVars
  /**
   * @deprecated 後方互換
   * @hidden from docs
   */
  $moment: typeof $dayjs = $dayjs
  $frameworkUtils: FrameworkFrameworkApiRefForCoreFramework = $frameworkUtils

  constructor() {
    this.isProduction =
      process.env.NODE_ENV === 'production' ||
      process.env.LOAD_STAGE_ENV === 'production' ||
      process.env.NODE_ENV === 'staging' ||
      process.env.LOAD_STAGE_ENV === 'staging'
  }

  /**
   * Serviceを注入する
   * window.$core.$serviceName で利用可能になる
   * TODO: これ全然使っていないが... どうしようか
   * @param serviceName
   * @param serviceInstance
   * @hidden from docs
   */
  injectService(serviceName: string, serviceInstance) {
    this[serviceName] = serviceInstance
  }
}

// const coreInstance = Core.instance
// // @ts-ignore
// if (!globalThis.$core) {
//   // @ts-ignore
//   globalThis.$core = coreInstance as Core
// }
//
// // To avoid error(s) from some libs
// if (!globalThis.global) {
//   // @ts-ignore
//   globalThis.global = globalThis
// }
// export const $core = coreInstance as Core
