<template>
  <table :class="tableClass">
    <thead>
      <tr>
        <th class="index-list-table--thead--check" v-if="bulkControllable">
          <input
            :checked="isAllDisplayedItemsChecked"
            class="ignoreItemClick"
            type="checkbox"
            @click.stop="event => toggleAllDisplayItemsChecked(event)"
          />
        </th>
        <th
          class="index-list-table--thead--list-item-action"
          v-if="virtualModel && virtualModel.listItemActionHtml"
        ></th>
        <th :class="`index-list-table--thead--col--${t.key}`" v-for="t in tableColumns">
          {{ t.label || t.key }}
        </th>
      </tr>
    </thead>
    <tbody v-if="!bulkControllable">
      <tr
        v-for="(item, index) in items"
        :key="index"
        style="cursor: pointer; font-size: 0.94em"
        v-single-click="event => itemClick(item[primaryKeyColName], event)"
        v-html="`<td>${tableColumns.map(t => formatLabel(t, item)).join('</td><td>')}</td>`"
      ></tr>
    </tbody>
    <tbody v-else>
      <tr
        v-for="(item, index) in items"
        :key="index"
        style="cursor: pointer; font-size: 0.94em"
        v-single-click="event => itemClick(item[primaryKeyColName], event)"
      >
        <th class="ignoreItemClick">
          <div
            v-b-tooltip.hover.right
            title="+[shift]キーで複数操作"
            :style="{
              display: items.some(item => bulkControlSelectedItems[item[primaryKeyColName]])
                ? 'block'
                : 'contents',
              cursor: 'pointer',
            }"
          >
            <input
              :checked="bulkControlSelectedItems[item[primaryKeyColName]]"
              class="ignoreItemClick"
              type="checkbox"
              @click.stop="
                event => clickCheckboxInput(item[primaryKeyColName] + '', [...items], index, event)
              "
            />
          </div>
        </th>
        <td
          v-if="virtualModel && virtualModel.listItemActionHtml"
          v-html="virtualModel.listItemActionHtml(item)"
          class="no-ellipsis"
        ></td>
        <td
          v-for="t in tableColumns"
          :key="t.key"
          :class="`index-list-table--tbody--col--${t.key}`"
          v-html="formatLabel(t, item)"
        ></td>
      </tr>
    </tbody>
  </table>
</template>

<script lang="ts">
import { inputTypes } from '../ModelForm/ModelInput/inputTypes'
import { escapeHtml } from '../../common/utils'

export default {
  props: {
    items: { required: true },
    dataTableClass: { required: false },
    model: { required: false },
    virtualModel: { required: false },
    clickCheckboxInput: { required: true },
    bulkControlSelectedItems: { required: true },
    tableColumns: { required: true },
    itemClick: { required: true },
    bulkControllable: { required: true },
    checkAllDisplayedItems: { required: false, default: false },
  },
  data() {
    return {
      isAllDisplayedItemsChecked: this.checkAllDisplayedItems,
    }
  },
  computed: {
    tableClass(): string {
      const classes = [
        this.dataTableClass ||
          'table b-table table-striped table-hover overflow-ellipsis sticky_thead',
      ]
      if (this.bulkControllable) {
        classes.push('table-bulk-controllable')
      }
      return classes.join(' ')
    },
    primaryKeyColName() {
      return this.model?.primaryKeyColName || 'id'
    },
  },
  methods: {
    isColumnRelationshipManyToOne(columnType: string): boolean {
      return columnType === inputTypes.RELATIONSHIP_MANY_TO_ONE
    },
    isColumnRelationshipOneToMany(columnType: string): boolean {
      return columnType === inputTypes.RELATIONSHIP_ONE_TO_MANY
    },
    toggleAllDisplayItemsChecked(event) {
      this.items.forEach((item, index) => {
        this.clickCheckboxInput(item[this.primaryKeyColName] + '', [...this.items], index, event)
      })
    },
    // TODO: We can make this more efficient func (loop part)
    formatLabel(columnDef, r): string {
      if (r[columnDef.key] === undefined || r[columnDef.key] === null) {
        return ''
      }
      /**
       * RELATIONSHIP_M2Oの場合
       */
      if (this.isColumnRelationshipManyToOne(columnDef.type)) {
        const labelFormatter = columnDef.relationshipManyToOne.labelFormatter
        if (typeof labelFormatter === 'function') {
          return labelFormatter(r[columnDef.key])
        }
        if (labelFormatter && typeof labelFormatter === 'string') {
          const func = new Function(
            `{ return function (row) { ${columnDef.relationshipManyToOne.labelFormatter} } };`,
          )
          return func.call(null).call(null, r[columnDef.key])
        }
        // @ts-ignore
        return Object.values(r[columnDef.key])[0]
      }

      /**
       * RELATIONSHIP_O2Mの場合
       */
      if (this.isColumnRelationshipOneToMany(columnDef.type)) {
        const labelFormatter = columnDef.relationshipOneToMany.labelFormatter
        if (typeof labelFormatter === 'function') {
          return labelFormatter(r[columnDef.key])
        }
        if (labelFormatter && typeof labelFormatter === 'string') {
          const func = new Function(
            `{ return function (rows) { ${columnDef.relationshipOneToMany.labelFormatter} } };`,
          )
          return func.call(null).call(null, r[columnDef.key])
        }
        return r[columnDef.key].length
      }

      // labelFormatter 定義がある場合
      if (columnDef.labelFormatter) {
        return columnDef.labelFormatter(r)
      }
      // escape HTML
      return escapeHtml(r[columnDef.key])
    },
  },
}
</script>