/**
 * Define all the validation methods, copied from sequelize.
 * https://github.com/sequelize/sequelize/blob/master/lib/utils/validator-extras.js
 */

// validator は結果的に不要になった
// import validator from 'validator'
import dayjs from 'dayjs'
import { tryParseAsObject } from '../utils'
import { $configVars } from '../$configVars/$configVars'

// isDate removed in 7.0.0
// https://github.com/chriso/validator.js/commit/095509fc707a4dc0e99f85131df1176ad6389fc9

/**
 * Default のパスワードチェック関数: 英字・数字を含む8文字以上, 30文字以下
 */
const defaultPassowrdValidator = (value: string) => {
  // 英字・数字を含む8文字以上, 30文字以下
  const ratz = /[a-z|A-Z]/
  const r0t9 = /[0-9]/
  const isValid = ratz.test(value) && r0t9.test(value) && value.length >= 8 && value.length <= 30
  return !!isValid
}
/**
 * パスワードが正しい形式かチェックする
 */
export const passwordValidator = (value: string): boolean => {
  return $configVars.get('passwordValidatorFunction', defaultPassowrdValidator)(value)
}

/**
 * 削除対象の値リスト
 */
const invalidDataValueList = [null, undefined]

/**
 * 保存する値として正しいかどうかチェックする
 * null, undefined は不可
 * @param value
 */
export const validateIsValidValueAsColumnVal = (value): boolean => {
  return invalidDataValueList.indexOf(value) === -1
}

/**
 * 文字列がjsの値として表現が正しいかチェックする
 * @param value
 */
export const validateIsValidJsObject = (value: string) => {
  try {
    tryParseAsObject(value)
  } catch (e) {
    console.error(e)
    return `文法エラー: ${e.message}`
  }
}

type ErrorMessageDef = string | ((args: any[]) => string)

const errorMessages: { [key: string]: ErrorMessageDef } = {
  contains: (args) => `${args[0]} を含める必要があります`,
  matches: (args) => `${args[0]} ではありません`,
  isEmail: 'メールアドレスの形式で入力してください',
  isURL: '正しいURLではありません',
  isBoolean: '真偽値で入力してください',
  isAlpha: '半角英字で入力してください',
  isAlphanumeric: '半角英数で入力してください',
  isNumeric: '数字で入力してください',
  isInt: '数値で入力してください',
  isFloat: '数値で入力してください (小数点可)',
  passwordValidator: '半角英数を含む8文字以上30文字以下でご入力ください。',
  isDecimal: '数値で入力してください',
  isHexColor: '16進数カラーコードで入力してください',
  isISRC: 'ISRCコードで入力してください',
  isJSON: 'JSON形式で入力してください',
  isEmpty: '入力必須です',
  notEmpty: '入力必須です',
  len: (args) => {
    if (args[0] === args[1]) {
      return `${args[0]}文字で入力してください`
    }
    return `${args[0]} 文字以上 ${args[1]} 文字以下で入力してください`
  },
  isUrl: '',
  regex: '入力内容が正しくありません',
  notRegex: '',
  min: (args) => `${args[0]} 以上で入力してください`,
  max: (args) => `${args[0]} 以下で入力してください`,
  // not: '',
  // notContains: '',
  // is: '',
  // isNull: '',
  isDate: '日付を入力してください',
  isValidJsObject: 'Javascript 構文エラーがあります',
  isKatakana: '全角カタカナで入力してください',
  isHiragana: '全角ひらがなで入力してください',
  isPhoneNumberWithoutHyphen: 'ハイフンなしの電話番号で入力してください',
  isPhoneNumberWithHyphen: 'ハイフンありの電話番号で入力してください',
  isSingleByte: '半角で入力してください',
}

const invokeValidator = (value, args, typeName) => {
  const arrayArgs = Array.isArray(args) ? args : args === undefined ? [] : [args]
  const res = validator[typeName](value + '', ...arrayArgs)
  if (!res) {
    const argStr = JSON.stringify(arrayArgs)
    if (!errorMessages[typeName]) {
      return `[${typeName}] - args: (${argStr}) `
    }
    const errorMessage = errorMessages[typeName]
    if (typeof errorMessage === 'string') {
      return errorMessage
    } else if (typeof errorMessage === 'function') {
      return errorMessage(arrayArgs)
    }
  }
}
export const validator = {
  extend(name, fn) {
    this[name] = fn

    return this
  },
  notEmpty(str) {
    return !str.match(/^[\s\t\r\n]*$/)
  },
  len(str, min, max) {
    const strLen = str.length
    return strLen >= min && strLen <= max
  },
  regex(str, pattern, regexpFlags) {
    try {
      return new RegExp(pattern, regexpFlags).test(str)
    } catch (e) {
      console.error('[validator.regex] 不正な正規表現です', { pattern, regexpFlags, e })
      // ignore error
      return true
    }
  },
  notRegex(str, pattern) {
    return !this.regex(str, pattern)
  },
  isInt(str) {
    return /^\d+$/.test(`${str}`)
  },
  isDecimal(str) {
    return str !== '' && !!str.match(/^(?:-?(?:[0-9]+))?(?:\.[0-9]*)?(?:[eE][+-]?(?:[0-9]+))?$/)
  },
  isAlpha(str) {
    return str !== '' && !!/^[a-z]+?$/i.test(str)
  },
  isAlphanumeric(str) {
    return str !== '' && !!/^[a-z0-9]+?$/i.test(str)
  },
  min(str, val) {
    if (val === '') {
      return true
    }
    const number = parseFloat(str)
    return isNaN(number) || number >= val
  },
  max(str, val) {
    if (val === '') {
      return true
    }
    const number = parseFloat(str)
    return isNaN(number) || number <= val
  },
  contains(str, elem) {
    return !!elem && str.includes(elem)
  },
  notContains(str, elem) {
    return !this.contains(str, elem)
  },
  is(str, pattern, modifiers) {
    return this.regex(str, pattern, modifiers)
  },
  isEmail(str) {
    return this.regex(str, /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)
  },
  // instance based validators
  isImmutable(value, validatorArgs, field, modelInstance) {
    return (
      modelInstance.isNewRecord ||
      modelInstance.dataValues[field] === modelInstance._previousDataValues[field]
    )
  },
  notNull(val) {
    return val !== null && val !== undefined
  },
  isDate(dateString) {
    const parsed = Date.parse(dateString)
    if (isNaN(parsed)) {
      return false
    }
    const date = new Date(parsed)
    return dayjs(date.toISOString()).isValid()
  },
  passwordValidator,
  invokeValidator,
  isValidJsObject(val) {
    try {
      tryParseAsObject(val)
      return true
    } catch (e) {
      console.log(e)
      return false
    }
  },
  isKatakana(str) {
    return !!str.match(/^[ァ-ヶー]+$/)
  },
  isHiragana(str) {
    return !!str.match(/^[ぁ-んー]+$/)
  },
  isPhoneNumberWithoutHyphen(str) {
    return !!str.match(/^[0-9]{10,11}$/)
  },
  isPhoneNumberWithHyphen(str) {
    return !!str.match(/^[0-9]{2,4}-[0-9]{2,4}-[0-9]{3,4}$/)
  },
  isSingleByte(str) {
    return !!str.match(/^[ -~｡-ﾟ]*$/)
  },
}
