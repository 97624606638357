<template>
  <div>
    <div v-if="initialized === false">Not found modelName: {{ modelName }}</div>
    <div v-else-if="initialized" :key="$route.fullPath">
      <component v-bind="modelIndexComponentProps" :is="indexComponent">
        <template #header>
          <router-link
            v-if="virtualModelCreatable && model.creatable !== false"
            class="btn btn-primary ml-2 component-model-index-page--add-new-button"
            :to="{ name: `fallback_model-new`, ...routerLinkOption }"
          >
            <ficon type="plus"></ficon>
            新規追加
          </router-link>
        </template>
      </component>
    </div>
  </div>
</template>

<script lang="ts">
import { ModelFactory } from '../../../common/$models'
import { convertURLQueryFilterForModelIndex } from '../../ModelIndex/$modeIndexSearchService'

export default {
  components: {
    // @ts-ignore
    modelIndexPage: $frameworkUtils.defineAsyncComponent(() =>
      import('../../ModelIndex/ModelIndexPage.vue'),
    ),
  },
  props: {
    passedVirtualModelName: {},
  },
  data() {
    return {
      initialized: null,
    }
  },
  computed: {
    modelName() {
      return this.$route.params.fallback_model
    },
    model(): ModelFactory {
      return $core.$models[this.modelName]
    },
    virtualModelName() {
      return this.$route.query.virtualModel || this.passedVirtualModelName
    },
    virtualModel() {
      return $core.$virtualModels[this.virtualModelName] || null
    },
    virtualModelColumns() {
      return this.virtualModel ? this.virtualModel.colNames : null
    },
    virtualModelCreatable() {
      return this.virtualModel?.creatable !== false
    },
    routerLinkOption() {
      return {
        params: { fallback_model: this.modelName },
        query: this.virtualModelName ? { virtualModel: this.virtualModelName } : {},
      }
    },
    /**
     *
     */
    indexComponent() {
      if (this.model.datasource === 'directus') {
        // @ts-ignore
        return $frameworkUtils.defineAsyncComponent(() =>
          import('../../ModelIndex/ModelIndexDirectus.vue'),
        )
      }
      return 'modelIndexPage'
    },
    searchConditionSaveKey() {
      return (this.virtualModel
      ? this.virtualModel.enableSearchConditionSave
      : this.model.enableSearchConditionSave)
        ? this.$route.fullPath
        : null
    },
    modelIndexComponentProps() {
      const bindProps = {
        modelName: this.modelName,
        virtualModelName: this.virtualModelName,
        headerTitle: this.headerTitle,
        fields: this.virtualModelColumns,
        bulkControllable: this.virtualModel
          ? this.virtualModel.bulkControllable
          : this.model.bulkControllable,
        searchConditionSaveKey: this.searchConditionSaveKey,
        urlQueryFilterChangeable: !!this.$route.query.urlQueryFilterChangeable,
        queryFilters: this.queryFilters,
        initialPage: Number(this.$route.query.page) || 1,
      }
      return bindProps
    },
    headerTitle() {
      return (
        this.$route.query?.headerTitle ||
        `${(this.virtualModel || this.model)?.tableLabel || this.modelName}`
      )
    },
    queryFilters() {
      return convertURLQueryFilterForModelIndex(
        this.$route.query.filters || this.$route.query.filter,
      )
    },
  },
  created() {
    this.$nextTick(async () => {
      this.initialized = !!this.model
    })
  },
  methods: {
    filterFunction(row) {
      if (!this.virtualModel) {
        return true
      }
      for (let [key, value] of Object.entries(this.virtualModel.dataFilters)) {
        if (row[key] != value) {
          return false
        }
      }
      return true
    },
  },
}
</script>
