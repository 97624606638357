import ComposableDataList from './components/ComposableDataList.vue'
import ComposableDataListSearchWrapper from './components/ComposableDataListSearchWrapper.vue'
import ComposableDataListColumnSearchInput from './components/ComposableDataListColumnSearchInput.vue'
import DataListKeywordSearch from './components/DataListQueryControls/DataListKeywordSearch.vue'
import DataListSortSelection from './components/DataListQueryControls/DataListSortSelection.vue'
import DataListPagination from './components/DataListQueryControls/DataListPagination.vue'
import DataListLimitControl from './components/DataListQueryControls/DataListLimitControl.vue'
import DataListQueryFilterGroupToggle from './components/DataListQueryControls/DataListQueryFilterGroupToggle.vue'

import DataListDisplayContainer from './components/DataListDisplay/DataListDisplayContainer.vue'
import DataListQueryFilter from './components/DataListQueryFilter.vue'
import DataListKeywordFilter from './components/DataListKeywordFilter.vue'

import DataListDisplayTable from './components/DataListDisplay/Table/DataListDisplayTable.vue'
import DataListDisplayTableBody from './components/DataListDisplay/Table/DataListDisplayTableBody.vue'
import DataListDisplayTableBodyRow from './components/DataListDisplay/Table/DataListDisplayTableBodyRow.vue'
import DataListDisplayTableBodyCellItem from './components/DataListDisplay/Table/DataListDisplayTableBodyCellItem.vue'
import DataListDisplayTableHeader from './components/DataListDisplay/Table/DataListDisplayTableHeader.vue'
import DataListDisplayTableHeaderColumnItem from './components/DataListDisplay/Table/DataListDisplayTableHeaderColumnItem.vue'
import DataListDisplayCheckboxBulk from '../../ModelRecordBulkControlService/front/components/DataListDisplayCheckboxBulk.vue'
import DataListBulkControlAction from '../../ModelRecordBulkControlService/front/components/DataListBulkControlAction.vue'
import ModelDataFilterSavedSearchConditions from '../../../front/ModelIndex/ModelDataFilterSavedSearchConditions.vue'
import DataListDisplayColumnItem from './components/DataListDisplay/DataListDisplayColumnItem.vue'
import DataListDisplayDragSortableList from './components/DataListDisplay/DragSortableList/DataListDisplayDragSortableList.vue'

import DataListDisplayCard from './components/DataListDisplay/Card/DataListDisplayCard.vue'

export const initComposableDataListComponents = () => {
  $core.VueClass.component('ComposableDataList', ComposableDataList)
  $core.VueClass.component(
    'ComposableDataListColumnSearchInput',
    ComposableDataListColumnSearchInput,
  )
  $core.VueClass.component('ComposableDataListSearchWrapper', ComposableDataListSearchWrapper)

  $core.VueClass.component('DataListDisplayContainer', DataListDisplayContainer)
  $core.VueClass.component('DataListPagination', DataListPagination)
  $core.VueClass.component('DataListLimitControl', DataListLimitControl)
  $core.VueClass.component('DataListQueryFilter', DataListQueryFilter)
  $core.VueClass.component('DataListQueryFilterGroupToggle', DataListQueryFilterGroupToggle)
  $core.VueClass.component('DataListKeywordSearch', DataListKeywordSearch)
  $core.VueClass.component('DataListKeywordFilter', DataListKeywordFilter)
  $core.VueClass.component('DataListSortSelection', DataListSortSelection)
  $core.VueClass.component(
    'ModelDataFilterSavedSearchConditions',
    ModelDataFilterSavedSearchConditions,
  )

  // Table components
  $core.VueClass.component('DataListDisplayTable', DataListDisplayTable)
  $core.VueClass.component('DataListDisplayTableBody', DataListDisplayTableBody)
  $core.VueClass.component('DataListDisplayTableBodyRow', DataListDisplayTableBodyRow)
  $core.VueClass.component('DataListDisplayTableBodyCellItem', DataListDisplayTableBodyCellItem)
  $core.VueClass.component('DataListDisplayColumnItem', DataListDisplayColumnItem)
  $core.VueClass.component('DataListDisplayTableHeader', DataListDisplayTableHeader)
  $core.VueClass.component(
    'DataListDisplayTableHeaderColumnItem',
    DataListDisplayTableHeaderColumnItem,
  )
  $core.VueClass.component('DataListDisplayCheckboxBulk', DataListDisplayCheckboxBulk)
  $core.VueClass.component('DataListBulkControlAction', DataListBulkControlAction)
  $core.VueClass.component('DataListDisplayDragSortableList', DataListDisplayDragSortableList)

  // Card layout
  $core.VueClass.component('DataListDisplayCard', DataListDisplayCard)
}
