<template>
  <div class="full-sized-page-layout">
    <div ref="headerNav" v-if="$slots.header">
      <slot name="header" />
    </div>
    <slot />
  </div>
</template>

<style>
.full-sized-page-layout {
  min-height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1050;
}
</style>
