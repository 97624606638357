<template>
  <component
    class="composable-component-rich-text-editor-content"
    style="margin-bottom: -0.8em"
    :is="t__tag || 'div'"
    v-html="t__htmlContent"
  />
</template>
<style>
.composable-component-rich-text-editor-content p {
  margin-bottom: 0;
}
</style>
<script lang="ts">
import { registerComposableComponentSettings } from '../../../plugins/ComposableComponents'
import { ColumnDefByColName } from '../../../common/$models/ModelDef'

/**
 * テキストコンテンツの設定カラム
 */
export const composableComponentTextContentConfigColumns: ColumnDefByColName = {
  t__htmlContent: {
    label: 'コンテンツ',
    type: 'RICHTEXT',
    inputAttrs: {
      rows: 8,
    },
  },
  t__tag: {
    label: '要素のタグ',
    type: 'STRING',
  },
}
const name = 'RichTextEditorContent'
registerComposableComponentSettings(name, {
  label: 'HTML (WYSIWYG エディタ)',
  category: 'Basic',
  hasDefaultSlot: true,
  configColumns: composableComponentTextContentConfigColumns,
  defaultProps: {
    t__htmlContent: 'サンプルテキスト',
  },
})

/**
 * シンプルなテキストコンテンツ, TextNode と同じようなイメージで、各種 ComposableComponent が継承して使用することも可能
 */
export default {
  name: name,
  props: {
    t__content: {},
    t__isHtml: {},
    t__htmlContent: {},
    t__tag: {},
  },
}
</script>
