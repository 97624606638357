<template>
  <ModelEditPage
    :modelName="modelName"
    :virtualModelName="virtualModelName"
    :columns="columns"
    :passedId="passedId"
    :disableHistoryBackButton="disableHistoryBackButton"
    :disableHeader="disableHeader"
    :enableOpenEditButton="enableOpenEditButton"
    :readOnlyMode="true"
  />
</template>

<script lang="ts">
export default {
  name: 'FireModelViewPage',
  props: {
    modelName: { required: true },
    virtualModelName: { required: false, default: null },
    columns: { required: false, default: false },
    passedId: { required: false, default: null },
    disableHistoryBackButton: { required: false, default: false },
    disableHeader: { required: false, default: false },
    enableOpenEditButton: { required: false, default: true },
  },
}
</script>
