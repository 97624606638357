import { validator } from '../../../common/ValidationService/validator'
// @ts-ignore
import { ColumnDefByColName } from '../../../common/$models/ModelDef'

export const defaultValueDef: ColumnDefByColName = {
  defaultValue: {
    label: 'デフォルト値',
    type: 'STRING',
    validate: { notEmpty: true },
  },
}

// 等号不等号
export const RelationalOperators = {
  '=': '===',
  '≠': '!==',
  '＞': '>',
  '≧': '>=',
  '＜': '<',
  '≦': '<=',
}

export const LogicalOperator = {
  ならば: 'ならば',
  and: 'and',
  or: 'or',
}

export const expressionsDef: ColumnDefByColName = {
  propName: {
    type: 'STRING',
    label: 'プロパティ名',
    validate: {
      notEmpty: true,
    },
    inputAttrs: {
      wrapperClass: 'col-2',
    },
  },
  relationalOperator: {
    type: 'SELECT',
    label: '条件',
    validate: {
      notEmpty: true,
    },
    strictSelections: true,
    selections(
      record: any,
      currentValue: any,
      initialValue: any,
      recordRoot: any,
      callerVueInstance: any,
    ) {
      return Object.entries(RelationalOperators).map(([key, value]) => {
        return {
          label: key,
          value: value,
        }
      })
    },
    inputAttrs: {
      wrapperClass: 'col-2',
    },
  },
  threshold: {
    type: 'STRING',
    label: '閾値',
    validate: {
      notEmpty: true,
    },
    inputAttrs: {
      wrapperClass: 'col-2',
    },
  },
  logicalOperator: {
    type: 'SELECT',
    label: '接続',
    validate: {
      notEmpty: true,
    },
    strictSelections: true,
    dynamicSelections: true,
    selections(
      record: any,
      currentValue: any,
      initialValue: any,
      recordRoot: any,
      callerVueInstance: any,
    ) {
      if (callerVueInstance.$parent.$parent.rowIndex === 0) {
        return Object.entries(LogicalOperator).map(([key, value]) => {
          return {
            label: key,
            value: value,
          }
        })
      } else {
        return Object.entries(LogicalOperator)
          .filter(([key, value]) => {
            return value !== recordRoot.propsMap[0].logicalOperator
          })
          .map(([key, value]) => {
            return {
              label: key,
              value: value,
            }
          })
      }
    },
    inputAttrs: {
      wrapperClass: 'col-2',
    },
  },
  returnValue: {
    type: 'STRING',
    label: '戻り値',
    enableIf: (row) => row.logicalOperator === LogicalOperator['ならば'],
    inputAttrs: {
      wrapperClass: 'col-2',
    },
    validate: {
      checkValue(value, col, modelName, record) {
        if (record.logicalOperator === 'ならば' && !value) {
          return '入力してください'
        }
      },
    },
  },
}

/**
 * Column 定義でvalidationを実施
 */
export const validateWithColDef = async ({
  value,
  modelName,
  record,
  colDef,
  initialValue,
  recordRoot,
  $modelInputVm = null,
}): Promise<string> => {
  const model = $core.$models[modelName]
  const isChanged = initialValue !== value
  const isNewRecord = recordRoot && !recordRoot?.[model?.primaryKeyColName]
  if (!colDef.validate) {
    return '' // Do nothing
  }
  // const validate = colDef.validate
  if (typeof value === 'undefined' || value === null) {
    value = ''
  }
  // Cast value as string to pass new Validator.js string requirement
  // value = String(value)
  // Array of error messages
  const validate = colDef.validate

  const errors = (
    await Promise.all(
      Object.keys(validate).map(async (key) => {
        if (validate[key] === '') {
          return // do nothing
        }
        try {
          if (typeof validator[key] === 'function') {
            if (!validate.notEmpty && value === '') {
              return ''
            }
            return validator.invokeValidator(value, validate[key], key)
          } else if (typeof validate[key] === 'function') {
            return await validate[key](value, colDef, modelName, record, recordRoot)
          }
        } catch (e) {
          console.error(
            `[validateWithColDef] error on validation execution`,
            {
              value,
              colDef,
              modelName,
              record,
              recordRoot,
            },
            e,
          )
          return null
        }
      }),
    )
  ).filter((v) => !!v)

  // check unique
  if (colDef.unique && value && model) {
    let condition = { [colDef.name]: { _eq: value } }
    if (colDef.unique.conditions) {
      if (typeof colDef.unique.conditions === 'function') {
        condition = Object.assign({}, condition, colDef.unique.conditions(record, recordRoot))
      } else {
        condition = Object.assign({}, condition, colDef.unique.conditions)
      }
    }
    const found = (await model?.find({ filter: condition })) || []
    // 新規レコードであれば、ゼロであるべき
    // 既存レコードであれば、編集してたら、ゼロであるべき
    // 既存レコードであれば、編集して無かったら、1でもいい。
    const isNotUnique = found.length > (!isNewRecord && !isChanged ? 1 : 0)
    if (isNotUnique) {
      errors.push(`${value} はすでに登録されています。`)
    }
  }
  return errors.length ? errors[0] : '' // .join(' / ')
}

export const validateConditionalExpressionBuilder = async ({
  value,
  modelName,
  record,
  colDef,
  initialValue,
  recordRoot,
}): Promise<string> => {
  let errors = []
  const err = await validateWithColDef({
    value: value ? value.defaultValue : '',
    modelName,
    record,
    colDef: defaultValueDef.defaultValue,
    initialValue: initialValue ? initialValue.defaultValue : '',
    recordRoot,
  })
  if (err) {
    errors.push(err)
  }

  if (!value?.expressions) {
    return errors.length ? errors[0] : '' // .join(' / ')
  }
  for (const exp of value.expressions) {
    if (!exp.blocks) {
      continue
    }
    for (const block of exp.blocks) {
      errors = errors.concat(
        Object.keys(block)
          .filter((key) => {
            expressionsDef[key]
          })
          .map(async (key) => {
            return await validateWithColDef({
              value: block[key],
              modelName,
              record,
              colDef: expressionsDef[key],
              initialValue,
              recordRoot,
            })
          }),
      )
    }
  }
  return errors.length ? errors[0] : '' // .join(' / ')
}
