// @ts-nocheck
import VueMultiselect from 'vue-multiselect'

VueMultiselect.props.selectLabel.default = '選択'
VueMultiselect.props.selectGroupLabel.default = '選択'
VueMultiselect.props.selectedLabel.default = '選択済'
VueMultiselect.props.deselectLabel.default = '選択解除'
VueMultiselect.props.deselectGroupLabel.default = '選択解除'
VueMultiselect.props.placeholder = { default: '---' }
VueMultiselect.props.tagPlaceholder = {
  default: 'エンターを押して追加できます',
}

export const Multiselect = VueMultiselect
