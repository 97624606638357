<template>
  <div>
    <!--    <b-form-select-->
    <!--      :model-value="modelValue"-->
    <!--      @update:model-value="(__value) => change(__value)"-->
    <!--      :options="colInputSelection"-->
    <!--      :inline="true"-->
    <!--      v-bind="commonAttrs"-->
    <!--    />-->
    <multiselect
      v-bind="commonAttrs"
      :allow-empty="false"
      :show-labels="false"
      select-label="選択"
      placeholder="選択"
      :multiple="false"
      :model-value="modelValue"
      :internal-search="false"
      :options="multiSelectOptions"
      @open="searchChange"
      :custom-label="customLabel"
      @select="change"
      @search-change="searchChange"
    >
      <template v-slot:noOptions> --- </template>
      <template v-slot:noResult> --- </template>
    </multiselect>
  </div>
</template>
<script lang="ts">
import { inject, PropType } from 'vue'
import { CCModelFormOneRecordService } from '../../../front/CCModelFormOneRecordService'
import { SelectionService } from '../../../front/SelectionService'

export default {
  props: {
    record: {
      type: Object as PropType<Record<string, unknown>>,
      required: true,
    },
    modelValue: {
      required: true,
    },
    col: {
      required: true,
    },
    commonAttrs: {
      type: Object as PropType<Record<string, unknown>>,
      required: false,
      default: () => ({}),
    },
    customLabel: {
      type: Function,
      required: false,
    },
  },
  data() {
    return {
      initialValue: {},
      colInputSelection: [],
      multiSelectOptions: [],
      service: null,
    }
  },
  watch: {
    record: {
      handler(value) {
        if (this.service) {
          this.service.record = value
          this.calcOptions()
        }
      },
      deep: true,
    },
  },
  setup() {
    return {
      CCModelFormOneRecordServiceInstance: inject(
        'CCModelFormOneRecordServiceInstance',
      ) as CCModelFormOneRecordService,
    }
  },
  async created() {
    this.initialValue = this.modelValue
    this.service = new SelectionService({
      modelName: this.CCModelFormOneRecordServiceInstance.CCModelFormService.modelName,
      colDef: this.col,
      record: this.record,
      v: this.modelValue,
      initialValue: this.initialValue,
      recordRoot: this.CCModelFormOneRecordServiceInstance.initialRecordState,
      vueInstance: this,
      isNewRecord: this.CCModelFormOneRecordServiceInstance.isNewRecord,
    })

    this.calcOptions()
  },
  methods: {
    async calcOptions() {
      const options = await this.service._initColInputSelection()
      this.$nextTick(async () => {
        if (this.service.v) {
          this.change(this.service.v) // kick validation & $emit event for parent component
        }
      })
      this.colInputSelection = options
      this.multiSelectOptions = this.colInputSelection
    },
    change(value) {
      this.$emit('update:modelValue', value)
    },
    searchChange(query) {
      let list = this.multiSelectOptions

      if (query) {
        query = query.replaceAll('　', ' ').split(' ')
        for (const searchText of query) {
          const tarText = searchText.toLowerCase()
          list = list.filter((v) => `${v.label}`.toLowerCase().includes(tarText))
        }
      }
      this.multiSelectOptions = list
    },
  },
}
</script>
