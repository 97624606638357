import { ModelDef, ColumnTypes, ColumnDefByColName } from '../../../common/$models/ModelDef'
import { codeInputCommonAttrs } from '../../../common/$models'
import { modelNameSelectColumnDef } from '../../../common/$models/modelsSelectionsHelperFunctions'
import { $frontConfigurationsForComposableFunctionsManager } from '../front/$frontConfigurationsForComposableFunctionsManager'

const hookTypes = {
  beforeSave: { label: 'beforeSave (データ保存前)', useFields: ['modelName', 'dataFilter'] },
  afterSave: { label: 'afterSave (データ保存後)', useFields: ['modelName', 'dataFilter'] },
  beforeDelete: { label: 'beforeDelete (データ削除前)', useFields: ['modelName', 'dataFilter'] },
  afterDelete: { label: 'afterDelete (データ削除後)', useFields: ['modelName', 'dataFilter'] },
}

const serverSideAppHooksConfigTypes = {
  modelHookBasedUIDefine: {
    label: 'データの保存・削除時のHook実行',
    useFields: [
      'modelName',
      'hookType',
      'dataFilterOnlyCreateRecords',
      'dataFilterUseFunction',
      'dataFilter',
      'dataFilterFunction',
      'dataArrayExecutionComposableFunctions',
    ],
  },
  // recordCommentAfterSaveNotification: {
  //   label: 'レコードコメント追加時の通知を生成',
  // },
  // schedulingBasedUIDefine: {
  //   label: '定時Hook実行',
  // },
  rawFunction: {
    label: 'Hook, 関数を直接指定',
    useFields: ['appHookName', 'function', 'hookType'],
  },
}

export const serverSideAppHooks: ModelDef = {
  tableName: 'serverSideAppHooks',
  tableLabel: 'サーバサイドカスタムHooks',
  primaryKeyColType: 'UUID',
  defaultSort: { key: 'createdAt', order: 'desc' },
  modelType: 'admin',
  beforeSave(saving: any, index?: number, allSaving?: any[]): Promise<any> | object {
    if (saving.configType === 'modelHookBasedUIDefine') {
      saving.appHookName = `${saving.modelName}.${saving.hookType}`
    }
    return saving
  },
  columns: {
    title: {
      label: '管理用名称',
      type: ColumnTypes.String,
      validate: { notEmpty: true },
      width: {
        xs: 48,
      },
      listItemAttrs: { class: { 'no-ellipsis': true, 'whitespace-wrap': true }, style: {minWidth: '320px', maxWidthSS: '320px'} },
    },
    description: {
      label: '説明',
      type: ColumnTypes.Text,
      width: { xs: 48 },
      listItemAttrs: { class: { 'no-ellipsis': true, 'whitespace-wrap': true }, style: {minWidth: '320px', maxWidthSS: '320px'} },
    },
    configType: {
      label: '設定タイプ',
      type: ColumnTypes.String,
      selections: () => {
        return Object.keys(serverSideAppHooksConfigTypes)
      },
      customLabel: (value) => serverSideAppHooksConfigTypes[value]?.label,
      defaultValue: 'modelHookBasedUIDefine',
    },
    functionName: {
      label: '$appHook 登録関数名',
      type: ColumnTypes.String,
      validate: { notEmpty: true },
      listItemAttrs: { class: { 'no-ellipsis': true, 'whitespace-wrap': true }, style: {minWidth: '320px', maxWidth: '320px'} },
    },
    appHookName: {
      label: '$appHook Hookタイプ',
      type: ColumnTypes.String,
      validate: { notEmpty: true },
      enableIf: (row) => row.configType === 'rawFunction',
    },
    function: {
      type: ColumnTypes.Text,
      validate: { notEmpty: true },
      enableIf: (row) => row.configType === 'rawFunction',
      inputAttrs: {
        ...codeInputCommonAttrs,
      },
      inputHelpText: `args から 各種サービスを取得して利用可能です。 const productItemService = args.itemServiceGenerator('products', {adminMode: true}) { req, res, next, services, exceptions, env, database, getSchema, data, docIds, savedKeys, thisItemService, itemServiceGenerator, coreServices }`,
    },
    modelName: {
      type: ColumnTypes.String,
      validate: { notEmpty: true },
      ...modelNameSelectColumnDef,
      label: '対象モデル',
      enableIf: (data) =>
        hookTypes[data.hookType]?.useFields?.includes('modelName') &&
        data.configType === 'modelHookBasedUIDefine',
      defaultValue: '',
    },
    hookType: {
      type: ColumnTypes.String,
      label: '実行タイミング (Hook種別)',
      validate: { notEmpty: true },
      selections: () => Object.keys(hookTypes),
      customLabel: (value) => hookTypes[value]?.label,
      defaultValue: 'afterSave',
      enableIf: (row) => row.configType === 'modelHookBasedUIDefine',
    },
    dataFilterOnlyCreateRecords: {
      beforeComponent: '<h5 style="font-weight: bold">データフィルタ条件</h5>',
      type: ColumnTypes.Boolean,
      label: '新規作成レコードのみ対象',
      defaultValue: false,
      enableIf: (data) =>
        hookTypes[data.hookType]?.useFields?.includes('dataFilter') &&
        data.configType === 'modelHookBasedUIDefine' &&
        data.modelName,
    },
    dataFilterUseFunction: {
      type: ColumnTypes.Boolean,
      label: 'フィルタ条件を関数で設定する',
      defaultValue: false,
      enableIf: (data) =>
        hookTypes[data.hookType]?.useFields?.includes('dataFilter') &&
        data.configType === 'modelHookBasedUIDefine' &&
        data.modelName,
    },
    dataFilter: {
      type: ColumnTypes.Text,
      label: 'レコードの状態でフィルタ',
      enableIf: (data) =>
        !data.dataFilterUseFunction &&
        hookTypes[data.hookType]?.useFields?.includes('dataFilter') &&
        data.configType === 'modelHookBasedUIDefine' &&
        data.modelName,
      inputComponent: {
        template: `<ModelDataFilterQueryInput :enableDefineAsFunction="false" v-bind="$attrs" :filterableCols="filterableCols" modelNamePropName="modelName"/>`,
      },
      width: {
        xs: 48,
        sm: 24,
      },
    },
    dataFilterFunction: {
      type: ColumnTypes.Text,
      inputAttrs: {
        ...codeInputCommonAttrs,
      },
      enableIf: (data) =>
        data.dataFilterUseFunction && hookTypes[data.hookType]?.useFields?.includes('dataFilter'),
      afterComponent:
        '<div class="small my-1 _text-muted">async 関数として実行されます。 引数 <code>data</code>, <code>oldDataArray</code> (どちらも、レコードの配列) が利用可能です。 <code>data</code> を フィルタリングし、後続関数で利用する配列を返却してください。返却しない場合 <code>data</code> がそのまま利用されます。 処理を停止したい場合には、 falsy な値を返却するか、例外 (Error) をthrowしてください。</div>',
      defaultValue: `// 処理を停止したい場合: (例: データの一括保存が100件以上の場合、処理を停止する)
// if(data.length > 100) {
//   return false
//   // もしくは
//   // throw new Error('error message')
// }
data = data.filter(d => {
  // フィルタリングロジックを記載
  // if(d.status === 'done') {
  //   return false
  // }
  // 例: 新規追加レコードのみ対象とする場合 (id がない場合)
  // return !d.id
  return true
})
return data
`,
    },
    executionComposableServices: {
      label: '実行サービス (複数選択可能)',
      type: ColumnTypes.ArrayOfObject,
      enableIf: (row) => row.configType !== 'rawFunction',
      minValueLength: 0,
      columns: {
        serviceKey: {
          label: 'データ処理サービス',
          type: ColumnTypes.String,
          validate: { notEmpty: true },
          dynamicSelections: true,
          selections: async (
            record: any,
            currentValue: any,
            initialValue: any,
            recordRoot: any,
            callerVueInstance: any,
          ) => {
            return $frontConfigurationsForComposableFunctionsManager
              .getSelectableConfigs(record, recordRoot)
              ?.map((c) => c.key)
          },
          customLabel: (value) =>
            $frontConfigurationsForComposableFunctionsManager.configs[value]?.label + ` (${value})`,
          width: {
            xs: 48,
            sm: 48,
          },
        },
        /**
         * $serversideAppHooksFrontConfigurationManager.configs[record.serviceKey].optionalColumns に指定されたカラムを設定できる
         */
        options: {
          label: 'オプション',
          enableIf: (row) =>
            !!row.serviceKey &&
            $frontConfigurationsForComposableFunctionsManager.configs[row.serviceKey]
              .argumentsConfigColumns,
          type: ColumnTypes.ArrayOfObject,
          inputComponent: {
            template: `
                <div :key="selectedServiceKey" class="card p-3 pb-0">
                  <ModelForm @update="update" :record="optionsData" :columns="columns" :hide-footer="true" :onSubmitFunction="() => false"/>
                </div>`,
            data() {
              return {
                optionsData: this.$attrs.record.options || {},
              }
            },
            computed: {
              selectedServiceKey() {
                return this.$attrs.record.serviceKey
              },
              columns(): ColumnDefByColName {
                return this.selectedServiceKey
                  ? $frontConfigurationsForComposableFunctionsManager.configs[
                      this.selectedServiceKey
                    ].argumentsConfigColumns
                  : {}
              },
            },
            methods: {
              update({ data, validationErrors }) {
                this.$emit('update:model-value', data)
              },
            },
          },
          width: {
            xs: 48,
            sm: 48,
          },
        },

        // types: {
        //   type: ColumnTypes.MultiSelect,
        //   label: '処理区分',
        //   validate: { notEmpty: true },
        //   selections: () => {
        //     return [
        //       'リプライ先ユーザ・組織',
        //       '関連するユーザ(過去リプライ,コメントに含まれるユーザ)',
        //     ]
        //   },
        //   width: {
        //     xs: 48,
        //     sm: 24,
        //   },
        // },
        // textTemplate: {
        //   type: ColumnTypes.RelationshipManyToOne,
        //   relationshipManyToOne: {
        //     collectionName: 'textTemplates',
        //   },
        //   label: 'テキストテンプレート',
        //   // selections() {
        //   //   return ['sss']
        //   // },
        // },
      },
      width: {
        xs: 48,
      },
    },
  },
}

export type ModelServerSideAppHooks = {
  title?: string
  configType?: keyof typeof serverSideAppHooksConfigTypes
  functionName?: string
  appHookName?: string
  function?: string
  modelName?: string
  hookType?: string
  dataFilterOnlyCreateRecords?: boolean
  dataFilterUseFunction?: boolean
  dataFilter?: string
  dataFilterFunction?: string
  executionComposableServices?: {
    serviceKey: string
    options?: { [key: string]: any }
  }[]
}
