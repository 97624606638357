import { ColumnDefByColName } from '../../common/$models/ModelDef'
import { ComposableComponentSubCategoryName } from './composableComponentCategories'
import { AsyncComponentLoader, Component } from 'vue'
import { codeInputCommonAttrs } from '../../common/$models'
import { ComposableComponentInstanceItem } from './ComposableComponentInstanceItem'

export type ComposableComponentSettingsChildrenGenerateFunction = (
  settings: ComposableComponentSettings,
) => Promise<ComposableComponentInstanceItem[]>

/**
 * コンポーザブル なコンポーネントの設定
 */
export type ComposableComponentSettings = {
  /**
   * フォームで設定可能な項目
   */
  configColumns?: ColumnDefByColName
  /**
   * コンポーネントの設定項目, Default値
   */
  defaultProps?: Record<string, any>
  /**
   * フォームで設定可能な項目, configColumns と同じだが、下記に設定されたものは style タブ配下に表示される
   */
  styleColumns?: ColumnDefByColName
  /**
   * default slot があるかどうか, 下記をtrueに設定すると、composable component を children として配下に含めることが可能
   */
  hasDefaultSlot?: boolean
  /**
   * named slot があるかどうか, 下記をtrueに設定すると、composable component を children として配下に含めることが可能
   * TODO: named slot への対応... これ、アイテムを移動させたときに色々ややこしいのでどうしたもんかという感じ
   */
  // namedSlots?: string[]

  name?: string | any
  label?: string | any
  labelIcon?: string | any
  category?: 'container' | 'dataView' | 'dataInput' | 'DataList' | string
  subCategory?: ComposableComponentSubCategoryName
  listHtml?: string | any
  componentDef?: string | Component | AsyncComponentLoader // Vue component definition
  enableOnBuilder?: boolean
  shortDesc?: string
  description?: string
  thumbImage?: string
  images?: { img: string }[]
  /**
   * 子要素の定義, ネスト構造化が可能。
   * ビルダーに追加できるパーツとして、グルーピングされたパーツを定義することができる。
   */
  children?: ComposableComponentInstanceItem[] | ComposableComponentSettingsChildrenGenerateFunction
}

export const composableComponentStyleColumnsDefault: ColumnDefByColName = {
  // 過剰なので廃止した
  // defineStyleAsFunction: {
  //   type: 'BOOLEAN',
  //   defaultValue: false,
  // },
  styles: {
    type: 'TEXT',
    defaultValue: '',
    // enableIf: ({ defineStyleAsFunction }) => !defineStyleAsFunction,
  },
  // styleDefineFunction: {
  //   type: 'TEXT',
  //   defaultValue: '',
  //   inputAttrs: {
  //     ...codeInputCommonAttrs,
  //   },
  //   enableIf: ({ defineStyleAsFunction }) => !!defineStyleAsFunction,
  // },
  // defineClassAsFunction: {
  //   type: 'BOOLEAN',
  //   defaultValue: false,
  // },
  classes: {
    type: 'TEXT',
    defaultValue: '',
    // enableIf: ({ defineClassAsFunction }) => !defineClassAsFunction,
  },
  // classDefineFunction: {
  //   type: 'TEXT',
  //   defaultValue: '',
  //   inputAttrs: {
  //     ...codeInputCommonAttrs,
  //   },
  //   enableIf: ({ defineClassAsFunction }) => !!defineClassAsFunction,
  // },
  // Flex の 場合, item 毎に設定可能なpropsを設定できるように
}
