import { $appHook } from '../common/$appHook'
import { embAuthHooks, EmbUserData } from './auth/$embAuth/$embAuth'
import { frontAppHooks } from './frontAppHooks'

type OnceLoadFunction = (args: EmbUserData) => Promise<any>
export type LoggedInExecuteOnceServiceBaseConstructorArg = {
  onceLoadFunction: OnceLoadFunction
  initStateFunction?: () => void
  appHookFunctionRegisterKey: string
  signOutBeforeFunction?: Function
  useAwaitOnLoad?: boolean
  hookPriority?: number
}

/**
 * ログインした際に、1度だけ実行される関数を登録できる
 * アプリケーション定義等の、初期セットアップに必要な実行内容を登録していく。
 */
export class LoggedInExecuteOnceServiceBase {
  initPromise: Promise<true>
  executionPromise: Promise<any>
  _markInitialized: () => void
  onceLoadFunction: OnceLoadFunction
  initStateFunction: Function
  signOutBeforeFunction?: Function | null
  appHookFunctionRegisterKey: string
  useAwaitOnLoad: boolean
  hookPriority: number | undefined

  constructor({
    onceLoadFunction,
    initStateFunction,
    appHookFunctionRegisterKey,
    signOutBeforeFunction,
    useAwaitOnLoad,
    hookPriority,
  }: LoggedInExecuteOnceServiceBaseConstructorArg) {
    this.onceLoadFunction = onceLoadFunction
    this.initStateFunction = initStateFunction
    this.signOutBeforeFunction = signOutBeforeFunction
    this.appHookFunctionRegisterKey = appHookFunctionRegisterKey
    this.hookPriority = hookPriority
    // TODO: Fix strange code
    this.useAwaitOnLoad = useAwaitOnLoad === undefined ? !!useAwaitOnLoad : false
    this.__initState()
    this.registerAppHooks()
  }

  __initState() {
    this.executionPromise = null
    if (this.initStateFunction) {
      this.initStateFunction()
    }
    this.initPromise = new Promise(resolve => {
      this._markInitialized = () => {
        resolve(true)
      }
    })
  }

  async load(args) {
    // ログインしたら onceLoadFunction を実行
    if (!this.executionPromise) {
      this.executionPromise = this.onceLoadFunction(args)
    }
    await this.executionPromise
    this._markInitialized()
  }

  registerAppHooks() {
    $appHook.on(
      embAuthHooks.getUserData.AFTER,
      async args => {
        this.__initState()
        if (this.useAwaitOnLoad) {
          await this.load(args)
        } else {
          setTimeout(() => {
            this.load(args)
          }, 10)
        }
        return args
      },
      `LoggedInExecuteOnce__${this.appHookFunctionRegisterKey}__OnSignedIn`,
      this.hookPriority,
    )
    // ログアウトしたら初期化
    $appHook.on(
      embAuthHooks.signOut.BEFORE,
      async args => {
        if (this.signOutBeforeFunction) {
          await this.signOutBeforeFunction(args)
        }
        this.__initState()
        return args
      },
      `LoggedInExecuteOnce__${this.appHookFunctionRegisterKey}__OnSignedOut`,
      this.hookPriority,
    )
    // Loaderの制御制御に必要
    $appHook.on(
      frontAppHooks.beforeInit,
      async args => {
        await this.initPromise
        return args
      },
      `LoggedInExecuteOnce__${this.appHookFunctionRegisterKey}__WaitAppDisplayUntilLoadAppDefinition`,
      this.hookPriority,
    )
  }

  async reload(userData = null) {
    await this.load(userData || $core.$embAuth?.user)
  }
}

// 関数実行で登録も可能
export const registerFunctionLoggedInExecuteOnce = (
  args: LoggedInExecuteOnceServiceBaseConstructorArg,
): LoggedInExecuteOnceServiceBase => {
  return new LoggedInExecuteOnceServiceBase(args)
}
