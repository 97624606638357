<template>
  <div
    class="modelInput clearfix"
    :class="valueHasNotChanged ? 'modelInputValueHasNotChanged' : 'modelInputValueHasChanged'"
  >
    <b-skeleton v-if="!initialized" height="24px" class="mb-2" />
    <div
      v-else-if="colInputType === inputTypes.ARRAY_OF_OBJECT && colDef.inputHelpText"
      class="small text-muted"
    >
      <div v-html="colDef.inputHelpText" />
    </div>
    <arrayOfObject
      v-if="
        colInputType === inputTypes.ARRAY_OF_OBJECT && colDef.visible !== false && colDef.columns
      "
      :col="colDef"
      :commonAttrs="commonAttrs"
      :record="record"
      :recordRoot="recordRoot"
      :model-value="v"
      :readOnlyMode="readOnlyMode"
      :ModelFormService="ModelFormService"
      :modelName="modelName"
      :virtualModelName="virtualModelName"
      :disableEdit="shouldDisabled"
      @update:value-and-error="
        ({ value, error }) => {
          if (Array.isArray(value)) {
            change({ value, error })
          }
        }
      "
    />
    <conditionalExpressionBuilder
      v-else-if="colInputType === inputTypes.CONDITIONAL_EXPRESSION && colDef.visible !== false"
      :col="colDef"
      :commonAttrs="commonAttrs"
      :record="record"
      :recordRoot="recordRoot"
      :model-value="v"
      :builder-labels="
        colDef.conditionalExpression ? colDef.conditionalExpression.builderLabels : null
      "
      :prop-selections="
        colDef.conditionalExpression ? colDef.conditionalExpression.propSelections : null
      "
      :ModelFormService="ModelFormService"
      @update:model-value="updateValueAndError"
    />
    <component
      v-else-if="shouldDisabled && colDef.disabledComponent"
      v-bind="commonAttrs"
      :is="colDef.disabledComponent"
      :disabled="true"
      :record="record"
      :model-value="v"
      :col="colDef"
      :class="disabledClass"
      :ModelFormService="ModelFormService"
      :colInputSelection="colInputSelection"
      @update:model-value="updateValueAndError"
      @update:value-and-error="updateValueAndError"
    />
    <sortable-record-list
      v-else-if="colInputType === inputTypes.RELATIONSHIP_ONE_TO_MANY && colDef.visible !== false && colDef.inputComponent === 'SortableRecordList'"
      :commonAttrs="commonAttrs"
      v-bind="commonAttrs"
      :record="record"
      :recordRoot="recordRoot"
      :model-value="v"
      :col="colDef"
      :modelName="modelName"
      :inputType="inputType"
      :inputAttrs="inputAttrs"
      :validation="validation"
      :editable="editable"
      :forceRequired="forceRequired"
      :virtualModelName="virtualModelName"
      :forceEditable="forceEditable"
      :readOnlyMode="readOnlyMode || shouldDisabled"
      :ModelFormService="ModelFormService"
      :colInputSelection="colInputSelection"
      :selectionLabels="selectionLabels"
      @update:model-value="change"
      @update:value-and-error="updateValueAndError"
    />
    <relationship-one-to-many
      v-else-if="colInputType === inputTypes.RELATIONSHIP_ONE_TO_MANY && !isNewRecord && !colDef.inputComponent"
      v-bind="commonAttrs"
      :model-value="v"
      :model-name="modelName"
      :virtual-model-name="virtualModelName"
      :col="colDef"
      :record="record"
      :recordRoot="recordRoot"
      :hideCreateButton="commonAttrs.hideCreateButton !== undefined ? commonAttrs.hideCreateButton : (readOnlyMode || shouldDisabled)"
      @update:modelValue="change"
    >
    </relationship-one-to-many>
    <input-file-selector
      v-else-if="colInputType === inputTypes.FILE && shouldDisabled"
      v-bind="commonAttrs"
      :disabled="true"
      :model-value="v"
      :col="colDef"
    />
    <input-image-uploader
      v-else-if="colInputType === inputTypes.FILEUPLOAD && shouldDisabled"
      v-bind="commonAttrs"
      :model-value="v"
      :disable-edit="true"
    />
    <JsonViewer
      v-else-if="colInputType === inputTypes.JSON && shouldDisabled"
      :max-height="'320px'"
      v-bind="commonAttrs"
      :jsonData="v"
    />
    <BooleanCheckboxInput
      v-else-if="colInputType === inputTypes.BOOLEAN && !colDef.inputComponent"
      v-bind="commonAttrs"
      :model-value="v"
      :unchecked-value="false"
      :disabled="shouldDisabled"
      @update:model-value="change"
      @keydown="modifyKeyDown"
    >
      <span ref="booleanCheckLabel" class="model-form-group--label--text d-inline">{{
          colDef.label || colDef.name || colName
        }}</span>
    </BooleanCheckboxInput>
    <div v-else-if="shouldDisabled" :class="disabledClass">
      <span v-if="commonAttrs.prefix" class="model-input--disabled--prefix">
        {{ commonAttrs.prefix }}
      </span>
      <span>
        <span v-if="shouldDisplayDisabledValueAsHtml" v-html="disabledLabel"></span>
        <span v-else class="d-block" style="white-space: pre-wrap">{{ disabledLabel }}</span>
      </span>
      <!-- <span v-if="!v && v !== 0 && v !== false" style="color: #8d9498; font-size: 0.9em; font-style: italic">{{ commonAttrs.placeholder || '--' }}</span> -->
      <span v-if="commonAttrs.suffix" class="model-input--disabled--suffix">
        {{ commonAttrs.suffix }}
      </span>
    </div>
    <component
      v-else-if="colDef.inputComponent"
      :commonAttrs="commonAttrs"
      v-bind="commonAttrs"
      :record="record"
      :recordRoot="recordRoot"
      :model-value="v"
      :col="colDef"
      :is="colDef.inputComponent"
      :modelName="modelName"
      :inputType="inputType"
      :inputAttrs="inputAttrs"
      :validation="validation"
      :editable="editable"
      :forceRequired="forceRequired"
      :virtualModelName="virtualModelName"
      :forceEditable="forceEditable"
      :readOnlyMode="readOnlyMode"
      :ModelFormService="ModelFormService"
      :colInputSelection="colInputSelection"
      :selectionLabels="selectionLabels"
      @update:model-value="change"
      @update:value-and-error="updateValueAndError"
    />
    <rich-text-editor
      v-else-if="colInputType === inputTypes.RICHTEXT"
      v-bind="commonAttrs"
      :colDef="colDef"
      :model-value="v"
      @update:modelValue="change"
    />
    <input-file-selector
      v-else-if="colInputType === inputTypes.FILE"
      v-bind="commonAttrs"
      :model-value="v"
      @update:model-value="change"
      :col="colDef"
    />
    <input-image-uploader
      v-else-if="colInputType === inputTypes.FILEUPLOAD"
      v-bind="commonAttrs"
      :model-value="v"
      @update:model-value="change"
    />
    <referenceMultiSelect
      v-else-if="
        colInputType === inputTypes.REFERENCE ||
        colInputType === inputTypes.RELATIONSHIP_MANY_TO_ONE
      "
      :col="colDef"
      :commonAttrs="commonAttrs"
      :record="record"
      :model-value="v"
      @update:modelValue="change"
    >
    </referenceMultiSelect>
    <RadioSelectInput
      v-else-if="isSelectInput && commonAttrs.radio"
      v-bind="commonAttrs"
      :colInputSelection="colInputSelection"
      :record="record"
      :col="colDef"
      :inline="true"
      :model-value="v"
      @update:model-value="(__value) => change({ value: __value })"
    />
    <b-form-select
      v-else-if="isSelectInput && commonAttrs.select"
      v-bind="commonAttrs"
      :model-value="v"
      :key="`${v}-form-select`"
      @update:model-value="
        (__value) => {
          if (v !== __value) {
            change({ value: __value })
          }
        }
      "
      :options="colInputSelection"
      :inline="true"
    />
    <multiselect
      v-else-if="isSelectInput"
      v-bind="commonAttrs"
      :model-value="v"
      @update:model-value="(__value) => change({ value: __value })"
      :allow-empty="true"
      :loading="isLoading"
      :internal-search="false"
      :options="multiSelectOptions"
      :show-labels="true"
      :taggable="colDef.strictSelections !== true"
      :placeholder="commonAttrs.placeholder || '選択'"
      :select-label="commonAttrs.selectLabel || '選択'"
      :selected-label="commonAttrs.selectedLabel || '選択中'"
      tag-placeholder="選択肢以外の値を追加"
      :max="!!isMultipleSelect ? colDef.maxValueLength || 1000 : undefined"
      :multiple="!!isMultipleSelect"
      :customLabel="customLabelFunc"
      :hide-selected="false"
      @keydown="modifyKeyDown"
      @open="multiSelectionOpened"
      @close="multiSelectionClosed"
      @search-change="searchChange"
      @select="() => keyup()"
      @tag="addSelection"
      ref="multiselect"
      :class="[multiselectOptionsPosition, multiselectOptionsSize]"
    >
      <template v-slot:noOptions> ---</template>
      <template v-slot:noResult> ---</template>
      <template v-slot:maxElements> 最大 {{ colDef.maxValueLength }}項目 まで選択可能</template>
    </multiselect>
    <b-input-group
      v-else-if="
        ([inputTypes.STRING].indexOf(colInputType) >= 0 || isNumberType) &&
        (commonAttrs.prefix || commonAttrs.suffix)
      "
      class="align-items-center gap-1"
    >
      <b-input-group-prepend v-if="commonAttrs.prefix" class="p-1"
      >{{ commonAttrs.prefix }}
      </b-input-group-prepend>
      <NumberInput
        v-if="isNumberType"
        v-bind="commonAttrs"
        :commonAttrs="commonAttrs"
        :model-value="v"
        :key="`${v}-form-input`"
        :col="colDef"
        @update:model-value="
        (__value) => {
          if (v !== __value) {
            change({ value: __value })
          }
        }
      "
      />
      <b-form-input
        v-else
        v-bind="commonAttrs"
        :type="
          commonAttrs.type || (isNumberType ? 'number' : 'text')
        "
        class="form-control"
        :model-value="v"
        :key="`${v}-form-input`"
        @update:model-value="
          (__value) => {
            if (v !== __value) {
              change({ value: __value })
            }
          }
        "
      />
      <b-input-group-append v-if="commonAttrs.suffix" class="p-1"
      >{{ commonAttrs.suffix }}
      </b-input-group-append>
    </b-input-group>
    <b-form-input
      v-else-if="colInputType === inputTypes.STRING"
      v-bind="commonAttrs"
      type="text"
      class="form-control"
      :model-value="v"
      :key="`${v}-form-input`"
      @update:model-value="
        (__value) => {
          if (v !== __value) {
            change({ value: __value })
          }
        }
      "
    />
    <NumberInput
      v-else-if="isNumberType"
      v-bind="commonAttrs"
      :commonAttrs="commonAttrs"
      :model-value="v"
      :key="`${v}-form-input`"
      :col="colDef"
      @update:model-value="
        (__value) => {
          if (v !== __value) {
            change({ value: __value })
          }
        }
      "
    />
    <code-editor
      v-else-if="colInputType === inputTypes.TEXT && commonAttrs.codeEditor === true"
      v-bind="commonAttrs"
      :model-value="v"
      @update:model-value="(__value) => change({ value: __value })"
    />
    <textarea
      v-else-if="colInputType === inputTypes.TEXT"
      v-bind="commonAttrs"
      :value="v"
      class="form-control"
      @change="(_ev) => change({ value: _ev.target.value })"
    />
    <code-editor
      v-else-if="colInputType === inputTypes.JSON"
      v-bind="commonAttrs"
      :model-value="v"
      @update:model-value="(__value) => change({ value: __value })"
    />
    <datepicker
      v-else-if="colInputType === inputTypes.DATEPICKER"
      v-bind="commonAttrs"
      :inputClass="commonAttrs.class"
      :model-value="v"
      @update:modelValue="change"
    />
    <datetime-picker
      v-else-if="colInputType === inputTypes.DATETIMEPICKER"
      v-bind="commonAttrs"
      :model-value="v"
      @update:modelValue="change"
    />
    <TimeTextInput
      v-else-if="colInputType === inputTypes.TIME"
      v-bind="commonAttrs"
      :model-value="v"
      @update:model-value="change"
    />
    <!--  -->
    <!--  -->
    <!--  -->
    <openDataWithModal
      class="modelInput-openDataWithModal"
      v-if="
        value &&
        [inputTypes.RELATIONSHIP_MANY_TO_ONE, inputTypes.REFERENCE].indexOf(colInputType) >= 0 &&
        isEnableOpenDataModal
      "
      :id="value && value.id ? value.id : value"
      :autoDetectVirtualModelName="true"
      :modelName="referenceModelName"
      :virtualModelName="referenceVirtualModelName"
      :referenceField="referenceField"
    />

    <div
      v-if="colDef.selectionsWithSelectOptionsMasterGroupName"
      class="small selectOptionMasterEditLink"
    >
      <a
        href="#"
        target="_blank"
        @click.prevent="
          () =>
            $core.$modals.openListViewModal({
              modelName: 'selectOptionsMaster',
              filters: { group: colDef.selectionsWithSelectOptionsMasterGroupName },
            })
        "
      >
        "{{ colDef.selectionsWithSelectOptionsMasterGroupName }}" リストを編集
      </a>
    </div>
    <div
      v-if="colInputType !== inputTypes.ARRAY_OF_OBJECT && colDef.inputHelpText && !shouldDisabled"
      class="small text-muted modelInput-inputHelpText"
    >
      <div v-html="colDef.inputHelpText" />
    </div>
    <ModelInputValidationMessage
      :ModelFormService="ModelFormService"
      :col="col"
      :colName="colName"
      :validationError="validationError"
      :modelInputInstance="modelInputInstance"
    />
  </div>
</template>

<script lang="ts">
import { colTypeInputTypeMap, inputTypes } from './inputTypes'
import {
  validator,
  validateIsValidValueAsColumnVal,
} from '../../../common/ValidationService/validator'
import { validateConditionalExpressionBuilder, validateWithColDef } from './validateWithColDef'
import { fetchSelectionsWithColDef } from '../index'
import RelationshipOneToMany from './relationshipOneToMany.vue'
import ConditionalExpressionBuilder from './ConditionalExpressionBuilder.vue'
import RadioSelectInput from './RadioSelectInput.vue'
import InputImageUploader from '../../FileManager/InputImageUploader.vue'
import InputFileSelector from '../../FileManager/InputFileSelector.vue'
import ModelInputValidationMessage from './ModelInputValidationMessage.vue'
import NumberInput from './NumberInput.vue'
import { isProxy } from 'vue'
import { columnTypesNumber } from '../../../common/modelDefinitions/modelDefinitions'

export default {
  name: 'ModelInput',
  components: {
    InputFileSelector,
    InputImageUploader,
    RadioSelectInput,
    ConditionalExpressionBuilder,
    RelationshipOneToMany,
    ModelInputValidationMessage,
    NumberInput,
    openDataWithModal: $frameworkUtils.defineAsyncComponent(
      () => import('../../openDataWithModal.vue'),
    ),
  },
  props: {
    modelName: { required: false, default: '', type: String },
    value: { required: true },
    col: { required: true },
    colName: { required: true, type: String },
    record: { required: false, default: false },
    recordRoot: {},
    inputType: { required: false, default: false },
    inputAttrs: { required: false, default: () => Object },
    validation: { default: true },
    editable: { default: null },
    forceRequired: { default: false },
    virtualModelName: { required: false, type: String },
    forceEditable: { required: false, default: false },
    readOnlyMode: { required: false, default: null },
    ModelFormService: {},
  },
  data() {
    /** Set non-reactive properties (to reduce overhead of reactivity) **/
    this.inputTypes = inputTypes
    this.colTypeInputTypeMap = colTypeInputTypeMap
    this.model = $core.$models[this.modelName]
    const keyColName = this.model?.primaryKeyColName || 'id'
    this.isNewRecord = (() => {
      if (this.ModelFormService?.forceTreatAsNewRecord) {
        return true
      }
      try {
        // TODO: これ、ロジックおかしいな。
        return !this.record[keyColName] && !this.recordRoot[keyColName]
      } catch (e) {
        return false
      }
    })()
    this.virtualModel = this.ModelFormService?.virtualModel
    this.colDef = this.col
    try {
      this._colTypeName = this.colDef.type.replace(/\(.+/, '')
    } catch (e) {
      throw new Error(
        `[Invalid column definition on modelInput] this.col: ${JSON.stringify(this.col)} ${
          e.message
        }`,
      )
    }
    if (
      (typeof this.colDef.selections === 'function' ||
        this.colDef.selectionsWithSelectOptionsMasterGroupName ||
        this.colDef.selectionsWithColNameFacet) &&
      !this.inputType
    ) {
      this.colInputType = inputTypes.MULTISELECT
    } else {
      this.colInputType = this.colTypeInputTypeMap[this.inputType || this._colTypeName]
    }
    this.isMultipleSelect = this.colDef.type === 'MULTISELECT'
    this._additionalClasses =
      this.colDef.type === 'NUMBER' && this.colInputType !== inputTypes.MULTISELECT
        ? 'text-right'
        : ''
    return {
      v: '',
      // initialValue: '',
      validationError: {},
      // isNewRecord: false,
      addedSelections: [],
      inputAttrsOptions: {},
      colInputSelection: [],
      multiSelectOptions: [],
      isLoading: false,
      disabledLabel: '',
      selectionLabels: {}, // Label
      multiselectOptionsPosition: 'left',
      initialized: false,
      shouldDisabled: false,
      tempRecord: {},
      hasValueEmittedOnInitOnce: false,
      valueHasNotChanged: true,
    }
  },
  computed: {
    // ModelFormService() {
    //   return $core.$utils.findParentVueComponentByComponentName(this, 'ModelForm')?.ModelFormService
    // },
    // commonAttrs: computedであるべき
    commonAttrs() {
      let cssClass = `model-input ${this._additionalClasses} `
      const common = {
        placeholder: this.colDef.label,
        disabled: this.shouldDisabled,
        maxlength: this.colDef.maxValueLength,
      }
      if (this.validationError && this.validationError[this.colName]) {
        cssClass += ' input-has-error '
      }
      if (this.inputAttrs?.class) {
        cssClass += ` ${this.inputAttrs.class} `
      }
      if (this.colDef.inputAttrs?.class) {
        cssClass += ` ${this.colDef.inputAttrs.class} `
      }
      return Object.assign(common, this.colDef.inputAttrs, this.inputAttrs, { class: cssClass })
    },
    modelInputInstance() {
      return this
    },
    disabledClass() {
      return (
        (this.isNumberType ? 'text-right' : '') +
        ' p-1 model-input-disable-value'
      )
    },
    isSelectInput() {
      return [inputTypes.MULTISELECT, this.inputTypes.SELECT].indexOf(this.colInputType) >= 0
    },
    customLabelFunc() {
      if (this.colDef.customLabel) {
        return (value) => this.colDef.customLabel(value, this, this.recordRoot)
      }
      if (Object.keys(this.selectionLabels).length) {
        return (value) =>
          this.selectionLabels[value] !== undefined ? this.selectionLabels[value] : value
      }
      return undefined
    },
    validator() {
      return validator
    },
    isReference(): boolean {
      return this.colInputType === inputTypes.REFERENCE
    },
    isRelationshipManyToOne(): boolean {
      return this.colInputType === inputTypes.RELATIONSHIP_MANY_TO_ONE
    },
    location() {
      return window.location
    },
    referenceModelName() {
      return (
        this.colDef.relationshipManyToOne?.collectionName ||
        this.colDef.referenceOfStore?.storeName ||
        this.modelName
      )
    },
    referenceVirtualModelName() {
      return (
        this.colDef.relationshipManyToOne?.virtualModelName ||
        this.colDef.referenceOfStore?.virtualModelName ||
        null
      )
    },
    referenceField() {
      if (this.colInputType === inputTypes.RELATIONSHIP_MANY_TO_ONE) {
        return this.colDef.relationshipManyToOne?.key || false
      }
      if (this.colInputType === inputTypes.REFERENCE) {
        return this.colDef.referenceOfStore?.key || false
      }
      return false
    },
    referenceDef() {
      return this.isReference ? this.col.referenceOfStore : this.col.relationshipManyToOne
    },
    isUsingLabelFormatterFunction(): boolean {
      return !!this.col.labelFormatter || !!this.referenceDef?.labelFormatter
    },
    shouldDisplayDisabledValueAsHtml(): boolean {
      return this.isUsingLabelFormatterFunction
    },
    isEnableOpenDataModal(): boolean {
      return !this.col.hideOpenDataLink
    },
    /**
     * Validation message を変換するための関数を返却する getter
     * 例: 選択肢Inputで、 "入力必須です" エラーメッセージを "選択してください" に変更したい場合 (at `/front/main.ts`)
     * $core.$configVars.set('$core.overrides.validateWithColDefFunc', function ({ validateWithColDef }) {
     *   return async ({ value, modelName, record, colDef, initialValue, recordRoot, $modelInputVm }) => {
     *     const errorMessage = await validateWithColDef({ value, modelName, record, colDef, initialValue, recordRoot, $modelInputVm })
     *     if (errorMessage === '入力必須です' && $modelInputVm.isSelectInput) {
     *       return '選択してください'
     *     }
     *     return errorMessage
     *   }
     * })
     */
    validateWithColDefFunc() {
      const override = $core.$configVars.configs['$core.overrides.validateWithColDefFunc']
      if (override) {
        return override({ validateWithColDef })
      }
      return validateWithColDef
    },
    multiselectOptionsSize() {
      const multiSelectOptionsLength = this.multiSelectOptions.map((option) => option?.length || 1)
      const maxLength = Math.max(...multiSelectOptionsLength)
      return maxLength > 20 ? 'max' : ''
    },
    isNumberType(): boolean {
      return columnTypesNumber.includes(this.colDef.type)
    },
  },
  watch: {
    async value(newValue, oldValue) {
      if (newValue === this.v) {
        return
      }
      this.$nextTick(async () => {
        // disabledの場合... kickする
        if (this.shouldDisabled && newValue !== this.v) {
          // this.disabledLabel = newValue
          this.disabledLabel = await this.generateDisabledLabel()
        }
        this.v = newValue
        this.$nextTick(async () => {
          this.change(undefined, true) // emit initialized this.v into the parent component
        })
      })
      // if (newValue === oldValue || (newValue === null && oldValue === '')) {
      //   return // Do nothing
      // }
    },
    async shouldDisabled(newValue, oldValue) {
      if (newValue === true && oldValue === false) {
        this.disabledLabel = await this.generateDisabledLabel()
      }
      if (newValue === false && oldValue === true) {
        this._initColInputSelection()
      }
    },
    record: {
      async handler(newValue, oldValue) {
        await this.calculateShouldDisabled()
        this.changeMultiSelectPosition()
        if (
          this.colDef.dynamicSelections === true &&
          JSON.stringify(this.tempRecord) !== JSON.stringify(newValue)
        ) {
          await this._initColInputSelection()
          this.tempRecord = Object.assign({}, newValue)
        }
      },
      deep: true,
    },
    readOnlyMode: {
      handler() {
        this.calculateShouldDisabled()
      },
      deep: true,
    },
    colDef: {
      handler() {
        this.calculateShouldDisabled()
      },
      deep: true,
    },
  },
  async created() {
    if (this.isNewRecord && this.record !== false && this.value === undefined) {
      const def =
        this.colDef.defaultValue !== undefined ? this.colDef.defaultValue : this.colDef.default
      this.v = typeof def === 'function' ? await def() : def
    } else {
      this.v = this.value
    }
    this.initialValue = this.v
    await this.calculateShouldDisabled()
    this.tempRecord = Object.assign({}, this.record)
    this.initialized = true

    this.$nextTick(async () => {
      if (!this.shouldDisabled) {
        await this._initColInputSelection()
      }
      this.change() // emit initialized this.v into the parent component
      if (this.shouldDisabled) {
        this.disabledLabel = await this.generateDisabledLabel()
      }
      this.changeMultiSelectPosition()
    })
  },
  methods: {
    changeMultiSelectPosition() {
      const el = this.$refs.multiselect?.$el

      if (el) {
        const offsetRight =
          document.body.offsetWidth -
          (el.getBoundingClientRect().x + el.getBoundingClientRect().width)

        if (offsetRight < document.body.offsetWidth / 3) {
          this.multiselectOptionsPosition = 'right'
        } else {
          this.multiselectOptionsPosition = 'left'
        }
      }
    },
    async calculateShouldDisabled() {
      this.shouldDisabled = await this.calculateShouldDisabledValue()
    },
    async calculateShouldDisabledValue() {
      if (this.readOnlyMode === true) {
        return true
      }
      if (this.colDef.visible === false) {
        return true
      }

      if ((this.$config && this.$config.forceEditMode === true) || this.forceEditable === true) {
        return false
      }
      let shouldDisabled =
        this.editable === false ||
        this.colDef.editable === false ||
        (!this.isNewRecord && (this.virtualModel || this.model)?.updatable === false)
      if (!shouldDisabled && typeof this.colDef.editable === 'function') {
        shouldDisabled = this.colDef.editable(this.record, this, this.recordRoot) === false
      }
      if (this.isNewRecord && this.colDef.editableOnCreate) {
        if (typeof this.colDef.editableOnCreate === 'function') {
          shouldDisabled =
            this.colDef.editableOnCreate(this.record, this, this.recordRoot) === false
        } else if (this.colDef.editableOnCreate === true) {
          shouldDisabled = false
        }
      }
      return shouldDisabled
    },
    multiSelectionOpened(event) {
      // ドロップダウンFocusのフラグをTrueにする
      if (this.ModelFormService) {
        this.ModelFormService.selectionFocus = true
      }
      this.$nextTick(() => {
        this.colDef.dynamicSelections === true ||
        this.colDef.createSelectOptionsMasterOnAddNewSelection === true
          ? this._initColInputSelection()
          : null
      })
    },
    multiSelectionClosed() {
      this.$nextTick(() => {
        // ドロップダウンFocusのフラグをFalseにする
        if (this.ModelFormService) {
          this.ModelFormService.selectionFocus = false
        }
      })
    },
    async validateColumn(value, col, modelName, record) {
      if (
        this.forceRequired === true &&
        (typeof value === 'undefined' || value === null || value === '')
      ) {
        return '入力必須です'
      }
      if (this.validation === false) {
        return '' // Do nothing
      }
      return this.validateWithColDefFunc({
        value,
        record,
        modelName,
        initialValue: this.initialValue,
        colDef: this.colDef,
        recordRoot: this.recordRoot,
        $modelInputVm: this,
      })
    },
    async runValidate() {
      // Execute validation
      // check col defs
      const validationMessage = await this.validateColumn(
        this.v,
        this.colDef,
        this.modelName,
        this.record,
      )
      this.validationError = this.shouldDisabled
        ? {}
        : validationMessage == ''
          ? {}
          : { [this.colName]: [validationMessage] }
      // console.log(`[modelInput] this.validationError: `, this.validationError)
    },
    keyup(event) {
      this.$nextTick(async () => {
        if (event?.value) {
          this.v = event.value
        }
        // Make a new timeout set to go off in 800ms
        setTimeout(() => {
          // this.runValidate()
          this.emitObject('keyup')
        }, 50)
      })
    },
    change(event) {
      this.$nextTick(async () => {
        let eventValue =
          event?.target?.value !== undefined
            ? event.target.value
            : event?.value !== undefined
              ? event.value
              : event

        // ここでカラムタイプがStringの場合、eventValueが""の場合にはnullに変換する
        if (this.colDef.type === 'STRING' && this.colDef.setNullIfEmpty === true && eventValue === '') {
          eventValue = null
        }

        const disableReactivity = event?.disableReactivity !== undefined ? event?.disableReactivity : true
        const hasValueChanged = eventValue !== undefined && this.v !== eventValue
        if (hasValueChanged) {
          if (this.valueHasNotChanged) {
            this.valueHasNotChanged = false
          }
          const isFalsyValue = [null, undefined, '', false].includes(eventValue)
          let val = this.colInputType === this.inputTypes.BOOLEAN ? !!eventValue : eventValue
          // Reactivity を 解除する
          if (this.colInputType !== this.inputTypes.FILE && this.colInputType !== this.inputTypes.FileUpload && isProxy(val) && val && disableReactivity === true) {
            val = JSON.parse(JSON.stringify(val))
          }
          if (this.isMultipleSelect) {
            this.v = Array.isArray(val) ? val : val && val !== 0 ? [] : [val]
          } else if(this.isNumberType) {
            this.v = isFalsyValue ? null : Number(val)
          } else {
            this.v = val
          }
        }
        await this.runValidate()
        if (this.hasValueEmittedOnInitOnce === false) {
          this.hasValueEmittedOnInitOnce = true
        }
        this.emitObject()
      })
    },
    updateValueAndError({ value, error }) {
      // TODO: run validation here
      this.$nextTick(async () => {
        this.$emit('update:value-and-error', { value, error })
      })
    },
    emitObject(eventName = 'update:value-and-error') {
      this.$nextTick(async () => {
        this.$emit(eventName, {
          value: validateIsValidValueAsColumnVal(this.v) === false ? null : this.v,
          error: this.validationError,
          modelInputVm: this,
        })
      })
    },
    // https://vue-multiselect.js.org/#sub-tagging
    addSelection(newSelection) {
      if (
        this.colDef.createSelectOptionsMasterOnAddNewSelection === true &&
        this.colDef.selectionsWithSelectOptionsMasterGroupName
      ) {
        // 追加時に、作成してしまう
        $core.$storeMethods.upsert({
          collectionName: 'selectOptionsMaster',
          data: {
            group: this.colDef.selectionsWithSelectOptionsMasterGroupName,
            value: newSelection,
            sort: 0,
          },
        })
      } else {
        // DBに登録しない場合は新規追加選択肢として保持する
        this.addedSelections.push(newSelection)
      }
      this.$nextTick(async () => {
        // isMultipleSelect の場合は、そのまま選択状態として追加
        if (this.isMultipleSelect) {
          this.change({
            value: Array.isArray(this.v) ? this.v.concat([newSelection]) : [newSelection],
          })
        } else {
          // 単一選択でValueをUpdate
          this.change({ value: newSelection })
        }
      })
    },
    async _initColInputSelection(autoSelectIfSelectionIsOnlyOne = false) {
      if (this.isSelectInput === false) {
        return // do nothing
      }
      let list = await fetchSelectionsWithColDef({
        modelName: this.modelName,
        colDef: this.colDef,
        record: this.record,
        value: this.v,
        initialValue: this.initialValue,
        recordRoot: this.recordRoot,
        callerVueInstance: this,
      })
      // list構造が {value, label}[] であれば、、、
      const isLabeledList =
        Object.keys(list[0] || {}).length === 2 && list[0].value !== undefined && list[0].label
      if (isLabeledList) {
        const valueList = []
        const labelMap = list.reduce((res, r) => {
          res[r.value] = r.label
          valueList.push(r.value)
          return res
        }, {})
        this.selectionLabels = labelMap
        list = valueList
      }
      if (this.addedSelections) {
        list = list.concat(this.addedSelections)
      }
      // 選択肢が1つだけの場合、自動選択する if autoSelectIfSelectionIsOnlyOne === true
      if (
        autoSelectIfSelectionIsOnlyOne &&
        this.isNewRecord &&
        list.length === 1 &&
        !this.v &&
        list[0]
      ) {
        this.$nextTick(async () => {
          this.v = list[0]
          this.change() // kick validation & $emit event for parent component
        })
      }
      list = list.filter((v) => v !== undefined)
      this.colInputSelection = list
      this.multiSelectOptions = this.colInputSelection.map((__v) => (__v === null ? '' : __v))
      this.change()
      return list
    },
    modifyKeyDown(event) {
      // コレをやると... select があかんことになってしまうので廃止
      // if (event.keyCode === 13) {
      //   const elements = document.querySelectorAll('.modelInput input')
      //   const index = [].findIndex.call(elements, (elem) => elem === event.target)
      //   if (elements[index + 1]) {
      //     // @ts-ignore
      //     elements[index + 1].focus()
      //   }
      // }
    },
    async searchChange(query) {
      let list = this.colInputSelection

      if (this.colDef.dynamicSelections && query && query.length > 2) {
        this.isLoading = true
        list = await this._initColInputSelection()
        this.isLoading = false
      }

      if (query) {
        query = query.replaceAll('　', ' ').split(' ')
        for (const searchText of query) {
          list = list.filter((v) => {
            const label = this.colDef.customLabel
              ? this.colDef.customLabel(v, this, this.recordRoot)
              : this.selectionLabels[v] || ''
            const result = (label + v).toLowerCase().includes(searchText.toLowerCase())
            return result
          })
        }
      }
      this.multiSelectOptions = list
    },
    deleted() {
      this.v = this.colInputType === inputTypes.ARRAY_OF_OBJECT ? [] : ''
    },
    async generateDisabledLabel() {
      this.v = this.value
      if (!(this.isReference || this.isRelationshipManyToOne)) {
        // Format datetime
        if (this.col.type === 'DATETIME' && this.v && !this.col.labelFormatter) {
          // TODO: Make the best way to handle... or should we show the +09:00 timezone info here?
          return this.v.indexOf('Z') >= 0
            ? this.v
            : $core.$dayjs(this.v).format('YYYY-MM-DD HH:mm:ss') || this.v
        }
        if (typeof this.col.labelFormatter === 'function') {
          return this.col.labelFormatter(this.record)
        }
        if (typeof this.col.customLabel === 'function') {
          return this.col.customLabel(this.v, this, this.recordRoot)
        }
        if([null, undefined].indexOf(this.v) >= 0) {
          return ''
        }

        if (this.col.type === 'TEXT' && this.v) {
          return this.v
        }
        // カンマ区切りにする
        if(this.col.inputAttrs?.isFormatWithComma && this.isNumberType) {
          return this.v?.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') || ''
        }
        return this.v
      }
      if (this.v === undefined) {
        return
      }
      const refDef = this.referenceDef
      // M2O の場合、必ず fetch する 挙動とする
      const shouldFetch = this.isRelationshipManyToOne || (this.v && (typeof this.v === 'string' || typeof this.v === 'number'))
      const row = shouldFetch ? await this.fetchRefRecord() : this.v
      if (!row) {
        return refDef.emptyLabel || ''
      }
      if (refDef.labelFormatter) {
        if (typeof refDef.labelFormatter === 'function') {
          return refDef.labelFormatter(row)
        } else if (typeof refDef.labelFormatter === 'string') {
          const func = new Function(`{ return function (row) { ${refDef.labelFormatter} } };`)
          return func.call(null).call(null, row)
        }
      } else if (this.isReference) {
        return row ? (row[refDef.label] ? row[refDef.label] : row[refDef.key]) : ''
      } else {
        return row.id || this.v
      }
    },
    async fetchRefRecord() {
      if (this.isRelationshipManyToOne) {
        const idValue = this.v?.id || this.v

        return $core.$models[this.referenceModelName].findById(idValue, {
          virtualModelName: this.referenceVirtualModelName,
        }, this.referenceDef?.findQueryFields || ['*'])
      }
      if (this.isReference) {
        return await $core.$models[this.referenceModelName].findOne({
          filter: { [this.referenceDef.key]: { _eq: this.v } },
        })
      }
    }
  },
  // Error report しつつ、エラーを握りつぶす
  errorCaptured(err, vm, info) {
    console.error(`[ModelInput] errorCaptured: `, err, vm, info)
    $core.$errorReporter.r(err, this)
    return false
  },
}
</script>

<style>
.selectOptionMasterEditLink {
  display: none;
}
</style>
