<template>
  <div class="row">
    <div :class="wrapperClass" v-for="i in formGorupCounts" :key="i">
      <b-skeleton width="180px" height="23px" class="mb-2" />
      <b-skeleton width="100%" height="35px" class="mb-3" />
    </div>
  </div>
</template>

<script lang="ts">
export default {
  props: {
    columns: {},
    colClass: {},
  },
  computed: {
    formGorupCounts() {
      return this.columns ? Object.keys(this.columns).length : 8
    },
    wrapperClass() {
      return this.colClass || 'col-12 col-md-4'
    },
  },
}
</script>
