import { ModelDef } from '../../../common/$models/ModelDef'
import { codeInputCommonAttrs } from '../../../common/$models'
import {
  ModelCoreAnotherDataSourceDialect,
  sourceTypeSubSelectionsBySourceTypes,
} from './model_core_another_data_source_dialects'

const selectQueryExample =
  'SELECT productDetails.*, products.* FROM productDetails LEFT JOIN products AS p ON p.id = productDetails.productId'

export type ModelCoreAnotherDataSourceConnectionDefinition = {
  enabled: boolean
  name: string
  useExternalDialect: boolean
  dialect?: ModelCoreAnotherDataSourceDialect | string
  useRawDbQuery: boolean
  selectQuery: string
  tableName: string
  primaryKeys: string[]
  fields: string[]
  beforeFindQueryTransformFunction: string
  afterFindFunction: string
}

export const core_another_data_source_connection_definitions: ModelDef = {
  tableName: 'core_another_data_source_connection_definitions',
  tableLabel: 'データソース定義',
  tableComment:
    'SQLを利用した複雑なクエリによるデータモデルの定義や、外部データソースの接続情報を定義可能。接続先は別テーブル core_another_data_source_dialects に保持されており、Relationしている。',
  primaryKeyColType: 'UUID',
  modelType: 'admin',
  columns: {
    name: {
      beforeComponent:
        '<div class="alert alert-info">SQLを利用した複雑なクエリや、外部SQL系DB, および API接続などを利用したモデル定義のデータソースの定義が可能です。</div>',
      type: 'STRING',
      label: 'データソース名',
      validate: { notEmpty: true },
      width: { md: 24 },
      unique: true,
      afterComponent: {
        template: `<div class="small mt-1">モデル定義のデータソース名称 (属性 <code>anotherDataSourceName: '{{ record.name }}'</code>) および API Endpoint <code class="d-block">/core/anotherDatasourceFind?dataSourceName={{ record.name }}</code> で利用されます。</div>`,
        computed: {
          record() {
            return this.$parent.record
          },
        },
      },
      listItemAttrs: { class: { 'no-ellipsis': true, } },
    },
    desc: {
      type: 'TEXT',
      label: '説明',
      width: { md: 24 },
      inputAttrs: {
        rows: 5,
      },
      listItemAttrs: { class: { 'no-ellipsis': true, 'whitespace-wrap': true }, style: {minWidth: '400px', maxWidth: '100%'} },
    },
    enabled: {
      type: 'BOOLEAN',
      label: '有効状態',
      width: {
        md: 6,
      },
      defaultValue: true,
    },
    useExternalDialect: {
      type: 'BOOLEAN',
      label: '外部ソース接続先を利用',
      defaultValue: false,
      inputHelpText: '外部ソース接続先を利用しない場合は、メインDBに対してクエリを実行します。',
      width: {
        md: 18,
      },
    },
    dialect: {
      enableIf: row => row?.useExternalDialect,
      type: 'RELATIONSHIP_MANY_TO_ONE',
      label: '外部ソース接続先',
      relationshipManyToOne: {
        collectionName: 'core_another_data_source_dialects',
        labelFormatter: record => {
          return `"${record?.name}" - ${sourceTypeSubSelectionsBySourceTypes[record?.connectionType]
            ?.label || record?.connectionType}:${sourceTypeSubSelectionsBySourceTypes[
            record?.connectionType
          ]?.subSourceTypes?.[record?.subSourceType]?.label || ''}`
        },
      },
      width: { md: 24 },
      validate: { notEmpty: true },
    },
    useRawDbQuery: {
      type: 'BOOLEAN',
      label: 'データソースに SQL表現を使用する',
      inputHelpText:
        'SQL表現を使用して、JOINやサブクエリを利用した複雑なデータソースを定義可能です。',
      defaultValue: false,
      width: { md: 48 },
    },
    tableName: {
      type: 'STRING',
      label: 'テーブル名',
      enableIf: row => !row.useRawDbQuery,
      width: { md: 16 },
      validate: { notEmpty: true },
    },
    primaryKeys: {
      type: 'MULTISELECT',
      label: '主キー',
      enableIf: row => !row.useRawDbQuery,
      width: { md: 16 },
      inputHelpText: '複数入力でマルチプライマリキーに対応可能です。',
      validate: { notEmpty: true },
    },
    fields: {
      type: 'MULTISELECT',
      label: 'SELECT対象 フィールド',
      enableIf: row => !row.useRawDbQuery,
      width: { md: 16 },
      inputHelpText: '空の場合は全フィールド選択 (`SELECT *`) を実行します',
    },
    selectQuery: {
      type: 'TEXT',
      label: 'SELECT Query',
      enableIf: row => row.useRawDbQuery,
      inputAttrs: {
        ...codeInputCommonAttrs,
      },
      width: {
        md: 48,
      },
      afterComponent: `<div class="small" style="line-height: 1.6em">SELECT SQL文を入力します。例: <code class="bg-light p-1">${selectQueryExample}</code><br/>※ Model定義のデータソースとして利用する場合の制約: プライマリキーが1つのフィールドになるように設定し、このデータソースを利用するモデル定義でも、プライマリキーカラムを同名で正しく設定してください。 (複合主キーの例: <code class="bg-light p-1">SELECT CONCAT(code, sub_code) AS id, ...</code>) </div>`,
      validate: { notEmpty: true },
    },
    beforeFindQueryTransformFunction: {
      type: 'TEXT',
      inputAttrs: {
        ...codeInputCommonAttrs,
      },
      width: {
        md: 48,
      },
    },
    afterFindFunction: {
      type: 'TEXT',
      inputAttrs: {
        ...codeInputCommonAttrs,
      },
      width: {
        md: 48,
      },
    },
  },
}
