import { $virtualModelsLoader } from '../../common/$virtualModels'
import { VModelChartDefinition } from './builder/ChartBuilderManager'

export const useChartDefinitionsFeature = () => {
  $virtualModelsLoader.loadModels([VModelChartDefinition])

  // 新規作成のボタン上書き
  $core.$appHook.on(
    'modelIndex.model:customComponentDefinitions.virtualModel:chartDefinitions.headerAppend',
    () => {
      return {
        template: `
        <a
          class="btn btn-primary ml-2 component-model-index-page--add-new-button"
          href="#/chart-builder"
        >
          <ficon type="plus"/> 新規追加
        </a>
      `,
      }
    },
  )
}
