<template>
  <th class="data-list-display-table-header-column-item" :class="`item--colname-${colName}`">
    <span
      :class="{
        'item--sortable': isSortable,
        'item--sorting': !!currentColSort,
        'item--sorting-asc': currentColSort && currentColSort.indexOf('-') !== 0,
        'item--sorting-desc': currentColSort && currentColSort.indexOf('-') === 0,
      }"
      @click.prevent="clickCol"
    >
      {{ colLabel || colName }}
      <ficon v-if="isSortable" class="item--sort-icon" type="signal" />
    </span>
  </th>
</template>
<script lang="ts">
import { registerComposableComponentSettings } from '../../../../../ComposableComponents'
import { inject } from 'vue'
import { DataListDisplayService } from '../DataListDisplayService'
import { dataListComposableColumnNameSelectionSettingColumnDef } from '../../../patrials/dataListComposableColumnNameSelectionSettingColumnDef'
import { ComposableDataListService } from '../../../ComposableDataListService'

const name = 'DataListDisplayTableHeaderColumnItem'
registerComposableComponentSettings(name, {
  hasDefaultSlot: true,
  configColumns: {
    colName: dataListComposableColumnNameSelectionSettingColumnDef,
  },
})

export default {
  name,
  props: {
    colName: {
      type: String,
      required: true,
    },
    disableSort: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    // TODO: Sortable column かどうかは、Model定義にも依存する (doNotSyncModel, virtualFieldOf など...)
    column() {
      return this.colName?.length ? this.DataListDisplayServiceInstance.columns[this.colName] : null
    },
    colLabel() {
      return this.column?.label
    },
    currentSort() {
      return this.ComposableDataListServiceInstance.query.sort
    },
    currentColSort() {
      return this.currentSort.find((s) => s === this.colName || s === `-${this.colName}`) || ''
    },
    isSortable() {
      if (this.disableSort) {
        return false
      }
      return !!this.column?.isSortable
    },
  },
  setup() {
    return {
      DataListDisplayServiceInstance: inject(
        'DataListDisplayServiceInstance',
      ) as DataListDisplayService,
      ComposableDataListServiceInstance: inject(
        'ComposableDataListServiceInstance',
      ) as ComposableDataListService,
    }
  },
  methods: {
    clickCol() {
      if (!this.isSortable) {
        return
      }
      // 昇順 => 降順 => リセット
      if (!this.currentColSort) {
        this.ComposableDataListServiceInstance.query.sort = [this.colName]
      } else if (this.currentColSort.indexOf('-') === 0) {
        // ソート リセット
        this.ComposableDataListServiceInstance.query.sort = []
      } else {
        this.ComposableDataListServiceInstance.query.sort = [`-${this.colName}`]
      }
    },
  },
}
</script>
<style lang="scss">
.data-list-display-table-header-column-item {
  .item--sortable {
    cursor: pointer;
    border-bottom: 1px solid transparent;
    &:hover {
      color: var(--bs-link-color);
      opacity: 0.8;
    }
    .item--sort-icon {
      transform: rotate(90deg) scaleX(-1);
      opacity: 0.2;
    }
    &.item--sorting {
      font-weight: bold;
      color: var(--bs-link-color);
      // border-bottom: 1px solid var(--bs-link-color);
      .item--sort-icon {
        opacity: 0.5;
      }
    }
    &.item--sorting-asc {
      .item--sort-icon {
        transform: rotate(90deg);
      }
    }
    &.item--sorting-desc {
      .item--sort-icon {
        transform: rotate(90deg) scaleX(-1);
      }
    }
  }
}
</style>
