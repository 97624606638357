export const showErrorPage = (message: string = '') => {
  setTimeout(() => {
    if ($core.$router.currentRoute.name !== 'error') {
      $core.$router.push({
        name: 'error',
        query: { message },
      })
    }
  }, 10)
}
