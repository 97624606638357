import { ModelDef } from '../../common/$models/ModelDef'
import { codeInputCommonAttrs, FindFilter } from '../../common/$models'
import { columnPermissionSelections } from './$userRoleManager'
import { findParentVueComponentByComponentName } from '../../common/utils'
// import { registerAppHookBehaviorWithFrontSideAppHookRecord } from '../../common/FrontSideAppHooks/FrontSideAppHooksRegistrator'
// import { processQuery } from '../../plugins/ComposableDataListComponents/front/FilterControlsService'
// import { $appHook } from '../../common/$appHook'

// export const registerRecordStateBasedEditAndDeletePermission = (
//   userRoleRecord: ModelUser_roles,
// ) => {
//   // permissions
//   const permissions = userRoleRecord.permissions
//   const roleKey = userRoleRecord.key
//   if (!permissions) {
//     // permissionが設定されていない場合には、何もしない
//     return
//   }
//   // 実際に、個別的なpermissionは、個別的なモデルに構成されている
//   permissions.map((permission) => {
//     // editableConditionをチェック
//     const editableCondition = permission.editableCondition
//     const deletableCondition = permission.deletableCondition
//     const modelName = permission.modelName
//     $appHook.on(`$CORE.admin.resolveComponent.model.${modelName}.pages.edit`, async (args) => {
//       if (!editableCondition && !deletableCondition) {
//         return args // skip
//       }
//       const editableConditionFilterQuery = editableCondition
//         ? (JSON.parse(editableCondition) as FindFilter)
//         : null
//       const deletableConditionFilterQuery = deletableCondition
//         ? (JSON.parse(deletableCondition) as FindFilter)
//         : null
//       return [
//         {
//           template: `
//             <div>
//             <component :is="editComponent" v-if="init" v-bind="$attrs" :readOnlyMode="readOnly" :disableSubmitAction="readOnly" :disableDelete="!deletable"/>
//             <div v-else class="text-center py-4">
//               <loading/>
//             </div>
//             </div>`,
//           data() {
//             return {
//               init: false,
//               // @ts-ignore
//               readOnly: this.$attrs.readOnlyMode as boolean,
//               deletable: true,
//             }
//           },
//           async mounted() {
//             try {
//               if (!$core.$embAuth.user?.isAdmin) {
//                 // core_roleがない場合には、編集不可
//                 if (!$core.$embAuth.user?.coreRoles) {
//                   this.readOnly = true
//                   return
//                 }
//                 // このユーザのcore_roleに、この権限がない場合にチェック
//                 if ($core.$embAuth.user?.coreRoles.includes(roleKey)) {
//                   // @ts-ignore
//                   this.readOnly = editableConditionFilterQuery
//                     ? (await processQuery(editableConditionFilterQuery, this.$attrs.record)) ===
//                       false
//                     : false
//                   this.deletable = deletableConditionFilterQuery
//                     ? (await processQuery(deletableConditionFilterQuery, this.$attrs.record)) ===
//                       true
//                     : true
//                 }
//               }
//             } catch (e) {
//               console.error(`Error on registerRecordStateBasedEditPermission:`, e)
//             } finally {
//               // @ts-ignore
//               this.init = true
//             }
//           },
//           computed: {
//             editComponent() {
//               return 'ModelForm'
//             },
//           },
//         },
//       ]
//     })
//   })
// }

export const user_roles: ModelDef = {
  tableName: 'user_roles',
  tableLabel: 'ユーザロール',
  tableComment: '',
  primaryKeyColType: 'UUID',
  defaultSort: { key: 'createdAt', order: 'desc' },
  modelType: 'admin',
  columns: {
    key: {
      label: '権限キー',
      comment: '一度登録すると、変更ができません。',
      type: 'STRING',
      validate: {
        notEmpty: true,
      },
      editable: false,
      editableOnCreate: true,
      groupedEdit: true,
      unique: true,
    },
    name: {
      label: '権限名称',
      type: 'STRING',
      validate: {
        notEmpty: true,
      },
      groupedEdit: true,
    },
    permissions: {
      label: '権限設定',
      type: 'ARRAY_OF_OBJECT',
      inputAttrs: { wrapperClass: 'col-12' },
      visibleOnIndex: false,
      columns: {
        modelName: {
          label: 'モデル名',
          type: 'STRING',
          dynamicSelections: true,
          selections(
            record: any,
            currentValue: any,
            initialValue: any,
            recordRoot: any,
          ): Promise<any[]> | any[] {
            const selectedModelNames = recordRoot.permissions?.map((d) => d.modelName) || []
            return Object.keys($core.$models).filter(
              (modelName) => selectedModelNames.indexOf(modelName) === -1,
            )
          },
          customLabel: (value) =>
            $core.$models[value] && $core.$models[value].tableLabel
              ? `${$core.$models[value].tableLabel} (${value})`
              : value,
          validate: {
            notEmpty: true,
          },
          inputAttrs: { wrapperClass: 'col-6' },
        },
        creatable: {
          label: '作成可',
          enableIf: (row) => !!row.modelName,
          type: 'BOOLEAN',
          defaultValue: true,
          inputAttrs: { wrapperClass: 'col-6 col-sm-1' },
        },
        updatable: {
          label: '更新可',
          enableIf: (row) => !!row.modelName,
          type: 'BOOLEAN',
          defaultValue: true,
          inputAttrs: { wrapperClass: 'col-6 col-sm-1' },
          // selections: () => [true, false, 'function'],
        },
        deletable: {
          label: '削除可',
          enableIf: (row) => !!row.modelName,
          type: 'BOOLEAN',
          defaultValue: true,
          inputAttrs: { wrapperClass: 'col-6 col-sm-1' },
        },
        additionalModelDefinition: {
          label: '追加モデル定義属性',
          type: 'TEXT',
          // enableIf: (row) => !!row.modelName,
          inputAttrs: {
            ...codeInputCommonAttrs,
            placeholder: `{\n  defaultSort: { key: 'createdAt', order: 'desc' },\n}`,
          },
          inputHelpText: 'JavascriptのObject定義を追加します',
        },
        // editableCondition: {
        //   label: 'レコード別編集可/不可 判定関数',
        //   type: 'TEXT',
        //   enableIf: (row) => !!row.modelName,
        //   inputComponent: 'RecordEditableConditionForUserRole',
        //   inputAttrs: {
        //     wrapperClass: 'col-12',
        //   },
        // },
        // deletableCondition: {
        //   label: 'レコード別削除可/不可 判定関数',
        //   type: 'TEXT',
        //   enableIf: (row) => !!row.modelName,
        //   inputComponent: 'RecordDeletableConditionForUserRole',
        //   inputAttrs: {
        //     wrapperClass: 'col-12',
        //   },
        // },
        columnDefinitions: {
          label: '特定カラムの権限制御',
          type: 'ARRAY_OF_OBJECT',
          minValueLength: 0,
          enableIf: (row) => !!row.modelName,
          inputAttrs: { wrapperClass: 'col-12' },
          columns: {
            // ここに、カラム毎のフィールドを定義する
            // 0. 定義対象のカラムを選択させる
            colName: {
              label: '対象カラム名',
              type: 'STRING',
              dynamicSelections: true,
              selections(
                record: any,
                currentValue: any,
                initialValue: any,
                recordRoot: any,
                callerVueInstance: any,
              ): Promise<any[]> | any[] {
                const foundVueComponent = findParentVueComponentByComponentName(
                  callerVueInstance,
                  'oneRowOfArrayOfObjectInput',
                  2,
                )
                const modelName = foundVueComponent?.row?.modelName
                // 既に選択したカラムを選択肢に出したくないのでFilterOut
                const alreadySelectedOtherColNames =
                  foundVueComponent?.row.columnDefinitions.reduce((res, colDef) => {
                    if (colDef.colName && colDef.colName !== currentValue) {
                      res.push(colDef.colName)
                    }
                    return res
                  }, [])
                return !modelName
                  ? []
                  : $core.$models[modelName].colNames.filter(
                      (colName) => alreadySelectedOtherColNames.indexOf(colName) === -1,
                    )
              },
              customLabel: (value, callerVueInstance) => {
                const modelName = findParentVueComponentByComponentName(
                  callerVueInstance,
                  'oneRowOfArrayOfObjectInput',
                  2,
                )?.row?.modelName
                const label = $core.$models[modelName]?.columns[value]?.label
                return label ? `${label} (${value})` : value
              },
              validate: {
                notEmpty: true,
              },
            },
            // 1. 3択で制御: "編集不可" "非表示" "データの状態で変動(関数で定義)"
            permission: {
              label: '権限',
              type: 'SELECT',
              selections() {
                return Object.keys(columnPermissionSelections)
              },
              validate: {
                notEmpty: true,
              },
              customLabel: (value) => {
                return columnPermissionSelections[value]
              },
            },
            // 2. "データの状態で変動(関数で定義)" の場合に、js関数を入力できるテキストエリアを表示する (入力必須にする)
            editableFunction: {
              label: '編集可/不可 判定関数',
              inputHelpText:
                'editable: (row, currentVueInstance) => { \nの文脈で実行されます。 row.someColumnName でデータの状態を参照可能です。明示的に false を返却した場合にのみ、編集不可状態になります。',
              type: 'TEXT',
              // 表示させたい状態を制御する (
              enableIf: (childRow, row) => childRow.permission === 'function',
              editable: (row, currentVueInstance) => {
                console.log({ row, currentVueInstance })
                return true
              },
              inputAttrs: {
                ...codeInputCommonAttrs,
              },
            },
          },
        },
      },
    },
  },
  afterSave: async (saved: ModelUser_roles): Promise<boolean> => {
    // registerRecordStateBasedEditAndDeletePermission(saved)
    return true
  },
}

export interface ModelUser_roles {
  key: string
  name: string
  permissions: {
    modelName: string
    creatable?: boolean
    updatable?: boolean
    deletable?: boolean
    editableCondition?: string
    deletableCondition?: string
    columnDefinitions?: {
      colName: string
      permission: any
      editableFunction?: string
    }[]
  }[]
  id: string
}
