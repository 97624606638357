<template>
  <div
    v-if="memoryRefreshIntervalId && !hideThisComponent"
    class="p-2 core-memory-usage-display-panel"
    style="position: fixed; right: 0; margin-right: 0; bottom: 0; background-color: rgba(0, 0, 30, 0.85); z-index: 999999999999; border-top-left-radius: 6px"
  >
    <code style="text-shadow: rgba(0, 0, 0, 0.1) 1px 1px 0; font-weight: normal; font-size: 14px">
      T: {{ Math.floor(memoryInfo.totalJSHeapSize / 1000000) }}MB, U:
      {{ Math.floor(memoryInfo.usedJSHeapSize / 1000000) }}MB, M:
      {{ Math.floor(memoryInfo.jsHeapSizeLimit / 100000000) / 10 }}GB
    </code>
    <ficon type="x" class="text-white small" @click.prevent="() => hideThisComponent = true"/>
  </div>
</template>
<script>
export default {
  data() {
    return {
      memoryInfo: {
        totalJSHeapSize: 0,
        usedJSHeapSize: 0,
        jsHeapSizeLimit: 0,
      },
      memoryRefreshIntervalId: null,
      hideThisComponent: false,
    }
  },
  mounted() {
    setTimeout(() => {
      if (
        (!$core.isProduction || this.$route.query.showMemoryUsage || $core.$configVars.get('showMemoryUsage', false)) && window.performance?.memory?.totalJSHeapSize
      ) {
        this.memoryRefreshIntervalId = setInterval(this.refreshMemoryInfo, 500)
      }
    }, 2000)
  },
  methods: {
    refreshMemoryInfo() {
      this.memoryInfo = window.performance.memory
    },
  },
}
</script>
