<template>
  <div class="child-row-controls">
    <div class="btn-group">
      <span class="btn text-success" @click="() => addRow(1, rowIndex)">
        <ficon type="plus" :strokeWidth="4" class="mx-0" />
      </span>
      <span class="btn text-black" @click="() => moveUp(rowIndex)">
        <ficon type="arrow-up" :strokeWidth="3" class="mx-0" />
      </span>
      <span class="btn text-black" @click="() => moveDown(rowIndex)">
        <ficon type="arrow-down" :strokeWidth="3" class="mx-0" />
      </span>
      <span class="btn text-danger" @click="() => removeRow(rowIndex)">
        <ficon type="trash" :strokeWidth="4" class="mx-0" />
      </span>
    </div>
  </div>
</template>
<script lang="ts">
import { inject } from 'vue'
import { CCModelFormOneRecordService } from '../CCModelFormOneRecordService'

export default {
  props: {
    rowIndex: {
      type: Number,
      required: true,
    },
    colName: {
      type: String,
      required: true,
    },
  },
  setup() {
    return {
      CCModelFormOneRecordServiceInstance: inject(
        'CCModelFormOneRecordServiceInstance',
      ) as CCModelFormOneRecordService,
    }
  },
  methods: {
    addRow(rows = 1, insertPosition = 0) {
      this.CCModelFormOneRecordServiceInstance.addRow(rows, this.colName, insertPosition + 1)
    },
    moveUp(rowIndex) {
      this.CCModelFormOneRecordServiceInstance.moveUpSubRow(rowIndex, this.colName)
    },
    moveDown(rowIndex) {
      this.CCModelFormOneRecordServiceInstance.moveDownSubRow(rowIndex, this.colName)
    },
    removeRow(rowIndex) {
      this.CCModelFormOneRecordServiceInstance.removeSubRow(rowIndex, this.colName)
    },
  },
}
</script>
