import {
  validateConditionalExpressionBuilder,
  validateWithColDef,
} from '../../../front/ModelForm/ModelInput/validateWithColDef'
import { ComponentPublicInstance, ref, watchEffect, reactive } from 'vue'
import { CCModelFormService } from './CCModelFormService'
import { ColumnDefByColName } from '../../../common/$models/ModelDef'

/**
 * # CCModelFormOneRecordService - レコード1件の編集状態を管理するサービス, CCModelFormService の子サービスである
 *
 * ## 役割・責任範囲
 * - データの編集中の状態を、このサービスインスタンス の `.record` で保持する
 * - editCallback を走らせ、record の 状態を更新する
 * - validation を走らせる, エラーメッセージを保持する
 * - ネストした状態がある場合 (ColumnDef.type `ARRAY_OF_OBJECT` がある場合) は、そのカラムに対応する、CCModelFormOneRecordService の "配列" を保持する
 *
 * ## 責任範囲外 (このClassで実施しないこと)
 * - 保存・削除のSubmit実行
 */
export class CCModelFormOneRecordService {
  record: any = ref({})
  recordRoot: Record<string, any>
  initialRecordState: Record<string, any>
  changedColumns: Set<string> = new Set()
  CCModelFormService: CCModelFormService
  errorMessageByColName: { [colName: string]: string } = {}
  errorMessageForEditCallbackByColName: { [colName: string]: any } = {}
  notFoundColNames: string[] = []
  /**
   * ColumnDef.type が `ARRAY_OF_OBJECT` の場合、そのカラムに対応する、CCModelFormOneRecordService の "配列" を保持する
   */
  nestedOneRecordServicesByColumnNames: { [colName: string]: CCModelFormOneRecordService[] } =
    reactive({})
  columnNamePathsFromRootColumnDef: string[] = [] // このレコードの、親レコードからのカラム定義のPaths
  /**
   *
   */
  parentOneRecordService: CCModelFormOneRecordService = null
  constructor(
    record: Record<string, any>,
    recordRoot: Record<string, any>,
    parentService: CCModelFormService,
    parentOneRecordService?: CCModelFormOneRecordService,
    columnNamePathsFromRootColumnDef?: string[], // このレコードの、親レコードからのパス
  ) {
    this.CCModelFormService = parentService
    this.parentOneRecordService = parentOneRecordService
    this.columnNamePathsFromRootColumnDef = columnNamePathsFromRootColumnDef || []
    this.record.value = record
    this.initialRecordState = Object.freeze({ ...record })
    this.recordRoot = recordRoot
    this._initNestedOneRecordServices()
    this._initWatcher() // call the function to set up the watcher
  }

  checkAndUpdateNotFoundColName(colName) {
    if (this.columns[colName]) {
      this.notFoundColNames = this.notFoundColNames.filter((name) => name !== colName)
      return true
    }
    if (this.notFoundColNames.indexOf(colName) === -1) {
      this.notFoundColNames.push(colName)
    }
    return false
  }

  _initWatcher() {
    watchEffect(() => {
      for (const key in this.record.value) {
        if (this.record.value[key] !== this.initialRecordState[key]) {
          this.changedColumns.add(key)
        }
      }
    })
  }

  /**
   * カラム定義 by columnName を返す
   * - ネストしている場合に対応
   */
  get columns(): ColumnDefByColName {
    if (this.columnNamePathsFromRootColumnDef.length === 0) {
      // ネストしていないなら、そのまま返す
      return this.CCModelFormService.filteredColumns
    }
    // ネストしている場合は、ネストしているところまで掘って、そのカラム定義 by name のオブジェクトを返す
    return this.columnNamePathsFromRootColumnDef.reduce((acc, colName) => {
      return acc[colName].columns
    }, this.CCModelFormService.filteredColumns)
  }

  _initNestedOneRecordServices() {
    Object.keys(this.columns).forEach((colName) => {
      // ARRAY_OF_OBJECT の場合のみ、そのカラムに対応する、CCModelFormOneRecordService の "配列" を生成する
      if (
        this.columns[colName].type === 'ARRAY_OF_OBJECT' &&
        this.columns[colName].visible !== false
      ) {
        this.nestedOneRecordServicesByColumnNames[colName] =
          this.record.value[colName]?.map((recordChild) => {
            // 例: ['mainMenus', 'children']
            const columnNamePathsFromRootColumnDef = [
              ...this.columnNamePathsFromRootColumnDef,
              colName,
            ]
            return new CCModelFormOneRecordService(
              recordChild,
              this.recordRoot,
              this.CCModelFormService,
              this,
              columnNamePathsFromRootColumnDef,
            )
          }) || []
      }
    })
  }

  async deleteData() {
    if (this.record.id) {
      if (this.CCModelFormService.modelName) {
        const res = await $core.$storeMethods.delete({
          modelName: this.CCModelFormService.modelName,
          id: this.record.id,
        })

        if (res) {
          this.CCModelFormService.removeOneRecordService(this)
        }
      } else {
        this.CCModelFormService.removeOneRecordService(this)
      }
    } else {
      $core.$toast.error('新規レコードです。')
    }
  }

  addRow(rows: number, columnName: string, insertPosition: number) {
    if (rows <= 0) {
      return
    }
    let newRecordService: any
    let nestedOneRecordServicesByColumnNames: any
    const columnNamePathsFromRootColumnDef = [...this.columnNamePathsFromRootColumnDef, columnName]

    if (insertPosition !== undefined) {
      newRecordService = new CCModelFormOneRecordService(
        {},
        this.recordRoot,
        this.CCModelFormService,
        this.parentOneRecordService,
        this.columnNamePathsFromRootColumnDef,
      )
      nestedOneRecordServicesByColumnNames =
        this.parentOneRecordService.nestedOneRecordServicesByColumnNames[columnName]
    } else {
      newRecordService = new CCModelFormOneRecordService(
        {},
        this.recordRoot,
        this.CCModelFormService,
        this,
        columnNamePathsFromRootColumnDef,
      )
      insertPosition = this.nestedOneRecordServicesByColumnNames[columnName]?.length || 0
      nestedOneRecordServicesByColumnNames = this.nestedOneRecordServicesByColumnNames[columnName]
    }

    nestedOneRecordServicesByColumnNames.splice(insertPosition, 0, newRecordService)
    this.nestedOneRecordServicesByColumnNames = {
      ...this.nestedOneRecordServicesByColumnNames,
      [columnName]: nestedOneRecordServicesByColumnNames,
    }
  }

  moveUpSubRow(rowIndex: number, columnName: string) {
    if (rowIndex < 1) {
      return // do nothing
    }
    const nestedOneRecordServices = [
      ...this.parentOneRecordService.nestedOneRecordServicesByColumnNames[columnName],
    ]
    // nestedOneRecordServicesのrowIndex番のサービスを、rowIndex-1番目に移動する
    const removed = nestedOneRecordServices.splice(rowIndex, 1)
    nestedOneRecordServices.splice(rowIndex - 1, 0, removed[0])
    this.parentOneRecordService.nestedOneRecordServicesByColumnNames = {
      ...this.parentOneRecordService.nestedOneRecordServicesByColumnNames,
      [columnName]: nestedOneRecordServices,
    }
  }

  moveDownSubRow(rowIndex: number, columnName: string) {
    const nestedOneRecordServices = [
      ...this.parentOneRecordService.nestedOneRecordServicesByColumnNames[columnName],
    ]
    if (rowIndex >= nestedOneRecordServices.length - 1) {
      return // do nothing
    }
    // nestedOneRecordServicesのrowIndex番のサービスを、rowIndex+1番目に移動する
    const removed = nestedOneRecordServices.splice(rowIndex, 1)
    nestedOneRecordServices.splice(rowIndex + 1, 0, removed[0])
    this.parentOneRecordService.nestedOneRecordServicesByColumnNames = {
      ...this.parentOneRecordService.nestedOneRecordServicesByColumnNames,
      [columnName]: nestedOneRecordServices,
    }
  }

  removeSubRow(rowIndex: number, columnName: string) {
    const nestedOneRecordServices = [
      ...this.parentOneRecordService.nestedOneRecordServicesByColumnNames[columnName],
    ]
    // nestedOneRecordServicesのrowIndex番のサービスを削除する
    nestedOneRecordServices.splice(rowIndex, 1)
    this.parentOneRecordService.nestedOneRecordServicesByColumnNames = {
      ...this.parentOneRecordService.nestedOneRecordServicesByColumnNames,
      [columnName]: nestedOneRecordServices,
    }
  }

  get isNewRecord(): boolean {
    return !this.recordRoot[this.CCModelFormService.model.primaryKeyColName]
  }

  // TODO
  async runEditCallback(values: { [colName: string]: any }, vueInstance: ComponentPublicInstance) {
    // ModelFormServiceのmodel(型定義がModelFactory)からeditCallbackを実行する
    const columnNames = Object.keys(values)
    await Promise.all(
      columnNames.map(async (colName) => {
        try {
          await this.CCModelFormService.model.editCallback({
            row: this.record,
            key: colName,
            newValue: values[colName],
            oldValue: this.record[colName],
            isNewRecord: false,
            callerVueInstance: vueInstance,
          })
          await this.runValidation(colName)
        } catch (error) {
          console.error(`Error occurred during editCallback for column ${colName}: `, error)
          // Store the error for later processing
          this.errorMessageForEditCallbackByColName[colName] = error
        }
      }),
    )
    // After the callback has been run, update the initial record state with the updated record
    this.initialRecordState = { ...this.record.value }
  }
  async validateWithColDefFunc(params) {
    const override = $core.$configVars.configs['$core.overrides.validateWithColDefFunc']
    if (override) {
      const overrideFunc = override({ validateWithColDef })
      return await overrideFunc(params)
    }
    return await validateWithColDef(params)
  }

  // TODO:１つのカラムに対してValidationを実行する
  async runValidationColumn(colName: string) {
    if (!this.columns[colName]) {
      return null
    }
    if (this.columns[colName].type === 'CONDITIONAL_EXPRESSION') {
      return await validateConditionalExpressionBuilder({
        value: this.record[colName],
        modelName: this.CCModelFormService.modelName,
        record: this.record,
        initialValue: this.initialRecordState[colName],
        colDef: this.columns[colName],
        recordRoot: this.recordRoot,
      })
    } else {
      return await this.validateWithColDefFunc({
        value: this.record[colName],
        record: this.record,
        modelName: this.CCModelFormService.modelName,
        initialValue: this.initialRecordState[colName],
        colDef: this.columns[colName],
        recordRoot: this.recordRoot,
        $modelInputVm: this,
      })
    }
  }

  /**
   * TODO
   * - 変更されたカラムに対してvalidateを実行する
   * - editCallbackとかで変更されたカラムに対してvalidateを実行する
   */
  async runValidation(colName) {
    const errorMessage = await this.runValidationColumn(colName)
    if (errorMessage) {
      this.errorMessageByColName[colName] = errorMessage
    } else {
      delete this.errorMessageByColName[colName]
    }
    // 変更が発生したカラムに対してvalidateを実行
    for (const colNameForChangedRow of this.changedColumns) {
      const errorMessage = await this.runValidationColumn(colNameForChangedRow)
      if (errorMessage) {
        this.errorMessageByColName[colNameForChangedRow] = errorMessage
      } else {
        delete this.errorMessageByColName[colNameForChangedRow]
      }
    }

    console.log(this.errorMessageByColName, 'this.errorMessageByColName')
  }
  async updateValues(values: { [colName: string]: any }, vueInstance: any) {
    this.record = { ...this.record, ...values }
    await this.runEditCallback(values, vueInstance)
  }
  updateErrors(errors: { [colName: string]: any }) {
    console.log({ errors })
  }
}
