import {
  ColumnDef,
  EditCallback,
  EditCallbackForColumn,
  ModelDef,
  findAllByArgument,
  ModelDatasourceType,
  ModelFunctionOverrides,
  ColumnDefByColName,
  ErrorMessagesObject,
  ModelValidationConfigItem,
  ErrorMessagesResult, ColumnType,
} from './ModelDef'
import { $appHook } from '../$appHook'
import { $directusSdk } from '../$directusSdk'
import { ID } from '@directus/sdk'
import { ManyItems } from '@directus/sdk'
import { FindFilter, FindQuery } from '../../types'
import { anotherDataSourceFrontModelDefFactory } from './anotherDataSourceFrontModelDefFactory'
import { createNewRecordWithColumnDefs } from './createNewRecordWithColumnDefs'
import { initColumnDef } from './initColumnDef'
import {
  validateConditionalExpressionBuilder,
  validateWithColDef,
} from '../../front/ModelForm/ModelInput/validateWithColDef'
import { matchesFilter } from '../../plugins/ComposableDataListComponents/front/FilterControlsService'

const defaultDataSource = 'directus'

export const modelFactoryAppHooks = {
  init: {
    before: '$CORE.ModelFactory.init.before',
    after: '$CORE.ModelFactory.init.after',
  },
  byModelName(modelName: string) {
    return {
      init: {
        before: `$CORE.ModelFactory.${modelName}.init.before`,
        after: `$CORE.ModelFactory.${modelName}.init.after`,
      },
    }
  },
}

export const columnDefinitionTemplates: ColumnDefByColName = {
  createdAt: {
    name: 'createdAt',
    label: '作成日時',
    type: 'DATETIME',
    editable: false,
    inputAttrs: { disabled: true, placeholder: '--' },
    labelFormatter: (row) =>
      row.createdAt ? $core.$dayjs(row.createdAt).format('YYYY-MM-DD HH:mm:ss') : '',
    enableIf: () => false,
    orderOnForm: 10099,
  },
  updatedAt: {
    name: 'updatedAt',
    label: '更新日時',
    type: 'DATETIME',
    editable: false,
    inputAttrs: { disabled: true, placeholder: '--' },
    labelFormatter: (row) =>
      row.updatedAt ? $core.$dayjs(row.updatedAt).format('YYYY-MM-DD HH:mm:ss') : '',
    enableIf: () => false,
    orderOnForm: 10099,
  },
}

export type ErrorMessagesCallbackArgs = {
  value: any
  record: any
  modelName: string
  initialValue: any
  colDef: any
  recordRoot: any
  modelInputVm: any
}
export interface ErrorMessagesCallback {
  ({
    value,
    record,
    modelName,
    initialValue,
    colDef,
    recordRoot,
    modelInputVm,
  }: ErrorMessagesCallbackArgs): Promise<ErrorMessagesObject>
}

export interface ValidateColumn {
  ({
    value,
    col,
    modelName,
    record,
    recordRoot,
  }: {
    value: any
    col: ColumnDef
    modelName: string
    record: any
    recordRoot: any
  }): Promise<string | undefined>
}

// 検索クエリが大きすぎるURLになっているかどうか判別するサイズの定義
const MAX_FIND_URL_QUERY_BYTE_SIZE = Number(process.env.MAX_FIND_URL_QUERY_BYTE_SIZE) || 4000

const defaultSortDefToStringArray = (
  defaultSort: ModelDef['defaultSort'],
  fallback: string[] = [],
): string[] => {
  if (!defaultSort) {
    return fallback
  }
  if (typeof defaultSort === 'string') {
    return defaultSort.split(',')
  }
  if (Array.isArray(defaultSort)) {
    return defaultSort
  }
  if (defaultSort.key) {
    if (Array.isArray(defaultSort.key)) {
      return defaultSort.key
    }
    return `${defaultSort.order === 'desc' ? '-' : ''}${defaultSort.key}`.split(',')
  }
  return fallback
}

const validateWithColDefFunc = ({
  value,
  record,
  modelName,
  initialValue,
  colDef,
  recordRoot,
  $modelInputVm,
}) => {
  if (!colDef) return ''
  const override = $core.$configVars.configs['$core.overrides.validateWithColDefFunc']
  if (override) {
    return override({
      value,
      record,
      modelName,
      initialValue,
      colDef,
      recordRoot,
      $modelInputVm,
    })
  }
  return validateWithColDef({
    value,
    modelName,
    record,
    colDef,
    initialValue,
    recordRoot,
    $modelInputVm,
  })
}

const validateColumnFunc = async ({
  value,
  col,
  modelName,
  record,
  modelInputVm,
}): Promise<ErrorMessagesObject> => {
  if (!modelInputVm) return {}
  if (
    modelInputVm.forceRequired === true &&
    (typeof value === 'undefined' || value === null || value === '')
  ) {
    return {
      [col.name || col.key]: ['入力必須です'],
    }
  }
  if (modelInputVm.validation === false) {
    return {}
  }
  if (col.type === 'CONDITIONAL_EXPRESSION') {
    const eMessage = await validateConditionalExpressionBuilder({
      value,
      record,
      modelName,
      initialValue: modelInputVm.initialValue,
      colDef: modelInputVm.colDef,
      recordRoot: modelInputVm.recordRoot,
    })
    return {
      [col.name || col.key]: [eMessage],
    }
  }
  // @ts-ignore
  const eMessage = await validateWithColDefFunc({
    value,
    record,
    modelName,
    initialValue: modelInputVm.initialValue,
    colDef: modelInputVm.colDef,
    recordRoot: modelInputVm.recordRoot,
    $modelInputVm: modelInputVm,
  })
  if (eMessage) {
    return {
      [col.name || col.key]: [eMessage],
    }
  }
}

const getValidateItemMessage = async ({
  validateItem,
  value,
  record,
  modelName,
  initialValue,
  colDef,
  recordRoot,
  modelInputVm,
}: {
  validateItem: ModelValidationConfigItem
  value: any
  record: any
  modelName: string
  initialValue: any
  colDef: any
  recordRoot: any
  modelInputVm: any
}): Promise<ErrorMessagesResult[]> => {
  // 1 validationItem.isDefinedAsFunction　が true のときは、その関数を実行する
  if (validateItem.isDefinedAsFunction) {
    if(typeof validateItem.isInvalidCheckFunction === 'string') {
      const asyncFunc = async function (data) {
        // @ts-ignore
        const func = new Function('data', validateItem.isInvalidCheckFunction)
        return await func(data)
      }
      const res = await asyncFunc(record)
      if (res) {
        return validateItem.errorMessageByColumnName
      }
      return []
    } else if(typeof validateItem.isInvalidCheckFunction === 'function') {
      const res = await validateItem.isInvalidCheckFunction(record)
      if (res) {
        return validateItem.errorMessageByColumnName
      }
    }
    return []
  }
  if (validateItem.isInvalidCheckConditionObject) {
    // 2 validationItem.isDefinedAsFunction　が false のときは、matchesFilter　から　isInvalidCheckConditionObject　が　Trueの場合　エラーメッセージを返す
    const res = matchesFilter(validateItem.isInvalidCheckConditionObject, record)
    if (res) {
      return validateItem.errorMessageByColumnName
    }
    return []
  }
  return []
}

const defaultNotSortableColumnTypes: ColumnType[] = ['JSON', 'CONDITIONAL_EXPRESSION', 'RELATIONSHIP_ONE_TO_MANY']

/**
 * ColumnDef を インスタンス化して ColumnDefService として返す
 * - インスタンス化されたModel定義 (ModelFactory) の columns には、ColumnDefService が格納される
 * - ColumDef に加えて 算出系の getter methods (logics) などを増強
 */
export class ColumnDefService extends ColumnDef {
  private caches: Record<string, any> = {}
  private model: ModelFactory
  constructor(colDef: ColumnDef, model: ModelFactory) {
    super()
    Object.keys(colDef).forEach((key) => {
      this[key] = colDef[key]
    })
    this.model = model
  }

  /**
   * 一覧にて Sortable かどうか
   */
  get isSortable(): boolean {
    return this.returnCacheIfExist('isSortable', calcIsSortable)
  }

  /**
   * getter methods 用, 一度計算した値をキャッシュするためのメソッド
   * - calcFunc は 外部定義できるように 引数に this を渡す
   * @param keyName
   * @param calcFunc
   * @private
   */
  private returnCacheIfExist(
    keyName: string,
    calcFunc: (thisSv: ColumnDefService) => any,
  ) {
    if (this.caches[keyName] !== undefined) {
      return this.caches[keyName]
    }
    this.caches[keyName] = calcFunc(this)
    return this.caches[keyName]
  }
}

const calcIsSortable = (col: ColumnDefService) => {
  if (col.virtualColumnOf || col.doNotSyncModel) {
    return false
  }
  if (defaultNotSortableColumnTypes.includes(col.type)) {
    return false
  }
  return true
}

/**
 * # $models[modelName]
 *
 * ## このモデルの説明!
 *
 * @coreDocPath $core/10.handleData/101.models/ModelFactory
 */
export class ModelFactory<ModelData = { [fieldName: string]: any }> extends ModelDef {
  datasource: ModelDatasourceType
  modelDef: ModelDef
  /**
   * プライマリキーのカラム定義を保持します
   */
  primaryKeyCol?: ColumnDef
  /**
   * プライマリキーのカラム名称
   */
  primaryKeyColName: string
  colLabels: Record<string, any>
  comment?: string
  timestamps?: boolean
  editCallbacksByColname: { [colName: string]: EditCallbackForColumn }
  editCallback: EditCallback
  errorMessagesCallback: ErrorMessagesCallback
  validateColumn: ValidateColumn
  facetColNames: string[]
  hasVirtualColumn: boolean
  defaultSort: string[]
  /**
   * Overrides, find系の挙動を書き換えをする
   */
  overrides?: ModelFunctionOverrides
  // overrides
  onSubmitFunction?: (data: any) => Promise<any> | any
  // overrides
  onDeleteFunction?: (data: any) => Promise<any> | any

  doNotSyncModel?: true
  enableKeywordSearch?: boolean
  defaultValues?: () => Promise<Record<string, any>>
  defaultFieldsParamExpression: string[] = ['*.*']
  /**
   * Model定義をFileで定義しているか、DBに保管しているかどうか。
   * `CORE/src/common/modelDefinitions/modelDefinitionsLoaderService.ts` で true 設定されている
   */
  isDefinedOnDb: boolean = false

  columns: {
    [colName: string]: ColumnDefService
  }

  constructor(modelDef: ModelDef) {
    super()
    $appHook.emit(ModelFactory.hooks.init.before, { modelDefinition: modelDef })
    $appHook.emit(ModelFactory.hooks.byModelName(modelDef.tableName).init.before, {
      modelDefinition: modelDef,
    })
    // anotherDataSource が指定されている場合は、そのデータソースを使う
    if (modelDef.anotherDataSourceName) {
      modelDef = anotherDataSourceFrontModelDefFactory(
        {
          modelDef,
          dataSourceName: modelDef.anotherDataSourceName,
        },
        false,
      )
    }
    this.modelDef = modelDef
    /**
     * ModelDef の 全key をそのまま引き継ぐ
     */
    const inheritKeys = Object.keys(modelDef)
    for (const key of inheritKeys) {
      this[key] = modelDef[key]
    }
    this.colLabels = {}
    this.datasource = modelDef.datasource || defaultDataSource
    this.editCallbacksByColname = {}
    this.timestamps = modelDef.timestamps !== false // default true
    this.hasVirtualColumn = false // default false
    this.facetColNames = []
    // TODO: 以下ごちゃってる箇所を切り出してキレイにしたい...

    // TODO: コレ不要?? 要調査 & 検討
    if (this.timestamps && !modelDef.columns.createdAt) {
      modelDef.columns.createdAt = columnDefinitionTemplates.createdAt
    }
    if (this.timestamps && !modelDef.columns.updatedAt) {
      modelDef.columns.updatedAt = columnDefinitionTemplates.updatedAt
    }

    Object.keys(modelDef.columns).map((colName: string) => {
      modelDef.columns[colName] = new ColumnDefService(initColumnDef(colName, modelDef.columns[colName], this), this)
    })
    /**
     * プライマリキーを columns として 設定する if not set
     */
    this.primaryKeyColName = this.primaryKeyCol?.name || 'id'
    if (!modelDef.columns[this.primaryKeyColName]) {
      modelDef.columns[this.primaryKeyColName] = {
        name: this.primaryKeyColName,
        type: this.primaryKeyColType || this.primaryKeyCol?.type || 'NUMBER',
        visible: false,
        primaryKey: true,
      }
    }
    if (!this.primaryKeyCol && modelDef.columns[this.primaryKeyColName]) {
      this.primaryKeyCol = modelDef.columns[this.primaryKeyColName]
    }

    /**
     * editCallback 挙動を設定
     */
    this.editCallback = async ({
      row,
      key,
      newValue,
      oldValue,
      isNewRecord,
      callerVueInstance,
    }) => {
      if (newValue === oldValue && !isNewRecord) {
        return row
      }
      // Model定義にあるとき
      if (modelDef.editCallback) {
        row = await modelDef.editCallback({
          row,
          key,
          newValue,
          oldValue,
          isNewRecord,
          callerVueInstance,
        })
      }
      // Auto calc 関連
      if (this.editCallbacksByColname[key]) {
        row = await this.editCallbacksByColname[key]({
          row,
          newValue,
          oldValue,
          isNewRecord,
          callerVueInstance,
        })
      }
      return row
    }

    // validatesの挙動を設定
    this.errorMessagesCallback = async ({
      value,
      record,
      modelName,
      initialValue,
      colDef,
      recordRoot,
      modelInputVm,
    }) => {
      const col = colDef
      const res = {}
      // 2. モデルフォーム全体的なvalidateを実行
      const modelValidates = this.modelDef.validates || []
      for (const validateItem of modelValidates) {
        const modelRes = await getValidateItemMessage({
          validateItem,
          value,
          record,
          modelName,
          initialValue,
          colDef,
          recordRoot,
          modelInputVm,
        })
        if (modelRes) {
          modelRes.forEach((v, k) => {
            // res[v.colName] = v.errorMessages.map((e) => e.message))
            const errorMessages = v.errorMessages.map((e) => e.message)
            res[v.colName] = res[v.colName] ? res[v.colName].concat(errorMessages) : errorMessages
          })
          // res = Object.assign({}, res, modelRes)
        }
      }
      return res
    }

    // calc defaultSort
    this.defaultSort = defaultSortDefToStringArray(modelDef.defaultSort, [
      this.columns.createdAt ? `-createdAt` : `-${this.primaryKeyColName}`,
    ])

    $appHook.emit(ModelFactory.hooks.init.after, {
      modelDefinition: modelDef,
      instance: this,
    })
    $appHook.emit(ModelFactory.hooks.byModelName(modelDef.tableName).init.after, {
      modelDefinition: modelDef,
      instance: this,
    })
  }

  get colNames() {
    return Object.keys(this.columns)
  }

  /**
   * 新規レコード作成時のデータを生成するためのメソッド
   */
  async createNew(): Promise<Record<string, any>> {
    // TODO use default value object
    const rewRec = await createNewRecordWithColumnDefs(this.columns)
    return Object.assign(
      {},
      rewRec,
      this.modelDef.defaultValues ? await this.modelDef.defaultValues() : {},
    )
  }

  static get hooks() {
    return modelFactoryAppHooks
  }

  /**
   * @param id
   * overrideable
   */
  async findById(id: ID, findOption: { virtualModelName?: string } = {}, fields: string[] = null) {
    if (this.overrides?.findById) {
      return this.overrides.findById(id)
    }
    const data = (
      await this._find({
        filter: { [this.primaryKeyColName]: { _eq: id } },
        fields: fields || this.defaultFieldsParamExpression,
      })
    )?.data?.[0]
    return this._formatDataAfterFetched([data], findOption?.virtualModelName)[0]
  }

  /**
   * Directus SDK の このテーブルを操作するAPIへの参照
   */
  get directusItemRef() {
    return $directusSdk.items(this.tableName)
  }

  /**
   * virtualColumn のみ取得
   */
  get virtualColumns() {
    return Object.values(this.columns).filter((c) => !!c.virtualColumnOf)
  }

  /**
   * virtualColumn 定義に従ってdataを "展開" する
   * @param data
   */
  expandDataWithVColumns(data) {
    return !this.hasVirtualColumn
      ? data
      : this.virtualColumns.reduce((res, vCol) => {
          if (data[vCol.virtualColumnOf]?.[vCol.name] !== undefined) {
            res[vCol.name] = data[vCol.virtualColumnOf]?.[vCol.name]
          }
          return res
        }, data)
  }

  /**
   * virtualColumn 定義に従ってdataを "収縮" する, 主にデータ保存前に利用する
   * @param data
   */
  compressDataWithVColumns(data) {
    if (!this.hasVirtualColumn) {
      return data
    }
    return this.virtualColumns.reduce((res, vCol) => {
      if (data[vCol.name] === undefined) {
        return res
      }
      if (
        !res[vCol.virtualColumnOf] ||
        typeof res[vCol.virtualColumnOf] !== 'object' ||
        Array.isArray(res[vCol.virtualColumnOf])
      ) {
        res[vCol.virtualColumnOf] = {}
      }
      res[vCol.virtualColumnOf][vCol.name] = data[vCol.name]
      return res
    }, data)
  }

  /**
   * 検索取得 main
   * @param query
   */
  async find<ModelDataType = any>(query: FindQuery<ModelDataType> | null = null): Promise<any[]> {
    // @ts-ignore TODO: なぜErrorになるか...
    const data = ((await this._find(query)).data as ModelDataType[]) || []
    return this._formatDataAfterFetched(data, query?.virtualModelName)
  }

  /**
   * findの主体
   * @param query
   * @private
   */
  public async _find(
    query,
  ): Promise<{ data?: any[]; meta?: { filter_count?: number; total_count?: number } }> {
    if (this.overrides?.find) {
      return this.overrides.find(query)
    }
    return this.defaultFind(query)
  }

  /**
   * デフォルトの検索処理
   * @param query
   */
  public async defaultFind(
    query,
  ): Promise<{ data?: any[]; meta?: { filter_count?: number; total_count?: number } }> {
    // query が大きすぎるときは、別のEndpoint POSTメソッドを使う
    if (this._shouldFetchItemsFromSearchItemsEndpoint(query)) {
      // @ts-ignore
      return $core.$d.transport.post(`/core/searchItems/${this.tableName}`, { query })
    }
    return this.directusItemRef.readByQuery(this._fixQuery(query))
  }

  private _fixQuery(query) {
    if (!query) {
      return {}
    }
    if (typeof query.sort === 'string' && query.sort) {
      query.sort = query.sort.split(',')
    }
    return query
  }

  _shouldFetchItemsFromSearchItemsEndpoint(query): boolean {
    if (query?.limit === -1) {
      return true
    }
    // query param が URL として長過ぎるかどうか for CloudFront or other CDN or managed services
    return query?.filter && JSON.stringify(query).length > MAX_FIND_URL_QUERY_BYTE_SIZE
  }

  /**
   * データ取得後の整形
   * @param data
   * @param virtualModelName
   */
  _formatDataAfterFetched(data: any[], virtualModelName: string = null) {
    return data.map((d) => {
      if (!d) {
        return d
      }
      if (virtualModelName && $core.$virtualModels[virtualModelName]?.hasVirtualColumn) {
        d = $core.$virtualModels[virtualModelName].expandDataWithVColumns(d)
      }
      if (this.hasVirtualColumn) {
        d = this.expandDataWithVColumns(d)
      }
      // record.id compat
      if (this.primaryKeyColName !== 'id') {
        Object.defineProperty(d, 'id', {
          enumerable: false,
          configurable: false,
          value: d[this.primaryKeyColName],
          writable: false,
        })
      }
      // TODO: 下記に移行したほうがよい...？ そんなコト無いかも...？
      // Object.defineProperty(d, '__pKey', {
      //   enumerable: false,
      //   configurable: false,
      //   value: d[this.primaryKeyColName],
      //   writable: false
      // })
      return d
    })
  }

  /**
   * 1件取得
   * @param query
   */
  async findOne<ModelDataType = Partial<ModelData>>(
    query: FindQuery<ModelDataType> = {},
  ): Promise<any> {
    query.limit = 1
    const res = await this.find(query)
    return res[0]
  }

  /**
   * TODO: 削除したい 検索
   * @deprecated
   */
  get findAll(): <ModelDataType = ModelData>(
    query?: FindQuery<ModelDataType>,
  ) => Promise<ModelDataType[]> {
    return this.find
  }

  /**
   * 単純なequal合致の組み合わせによる検索を提供
   * ```ts
   * $core.$models[modelName].findAllBy({userId: 'xxx-xx-xx-xxx'})
   * ```
   * @deprecated
   */
  findAllBy<ModelDataType = ModelData>(
    query: { [fieldName: string]: any },
    noLimit = false,
    addtionalParams = null,
  ): Promise<ModelDataType[]> {
    // query: FindQuery<ModelDataType>
    query = backwardCompatFindFilterStringIntoFindFilter(query)
    // @ts-ignore
    let param: FindQuery<ModelDataType> = { filter: query }
    if (noLimit === true) {
      param.limit = -1
    }
    if (addtionalParams) {
      // @ts-ignore
      param = Object.assign({}, param, addtionalParams)
    }
    return this.find(param)
  }

  /**
   * 検索取得 with total count
   * @param query
   */
  async findWithCount<ModelDataType = ModelData>(
    query: FindQuery<any>,
  ): Promise<ManyItems<ModelDataType>> {
    if (!query.meta) {
      // @ts-ignore
      query.meta = ['filter_count']
    }
    const { data, meta } = await this._find(query)
    return {
      data: this._formatDataAfterFetched(data, query?.virtualModelName),
      meta,
    }
  }

  /**
   * Find by ids
   * @param ids
   * @param query
   */
  async findByIds<ModelDataType = ModelData>(
    ids: ID[],
    query: FindQuery<ModelDataType> = {},
  ): Promise<ModelDataType[]> {
    return this.find<ModelDataType>({
      ...query,
      // @ts-ignore
      filter: {
        [this.primaryKeyColName]: {
          _in: ids,
        },
      },
    })
  }

  /**
   * TODO: 🍊 削除するか?
   * @param facets
   * @param filters
   */
  async fetchFacets(
    facets: string[] = ['*'],
    filters: findAllByArgument = '',
  ): Promise<{ [colName: string]: { [valueName: string]: number } }> {
    return {}
  }

  /**
   * ある1つのカラム名で、facetを取得する
   */
  async fetchFacetNamesByColName(colName: string): Promise<string[]> {
    try {
      return (
        await this.find({
          groupBy: [colName],
          limit: -1,
        })
      ).map((r) => r[colName])
    } catch (e) {
      return []
    }
  }

  async countBy(filters: FindFilter<any> | string): Promise<number> {
    filters = backwardCompatFindFilterStringIntoFindFilter(filters)
    const res = await this.findWithCount({
      filter: filters,
      limit: 1,
    })
    return res?.meta?.filter_count || 0
  }

  /**
   * 検索フィルタ絞り込みに利用できるカラムを返す
   */
  get filterableColumns(): ColumnDef[] {
    return Object.values(this.columns).filter(
      (col: ColumnDef) =>
        col.visible !== false && col.searchBehavior !== false && !col.virtualColumnOf,
    ) as ColumnDef[]
  }
}

/**
 * V2 => V3 互換のためのString => Object:FindFilter する
 * Algoliaの検索を文字列でぶっこんでいたため、そういう事になっている
 * @param filters
 */
export const backwardCompatFindFilterStringIntoFindFilter = <T = any>(
  filters: string | FindFilter<T>,
): FindFilter<T> => {
  if (typeof filters !== 'string') {
    return filters
  }
  console.warn(`[Chageme:backwardCompatFindFilterStringIntoFindFilter] filters:`, filters)
  const ANDSplited = filters.split(' AND ')
  const filterObject: FindFilter<T> = ANDSplited.reduce((res, keyVal: string) => {
    const [key, val] = keyVal.split(':')
    // is object, {_in: ['some', 'other']} など、クエリですでに定義されているならそのまま返却
    res[key] = typeof val === 'object' && val !== null ? val : { _eq: val }
    return res
  }, {})
  console.warn(`[Chageme:backwardCompatFindFilterStringIntoFindFilter] filterObject:`, filterObject)
  return filterObject
}
