import { ColumnDef, CustomValidationFunction, IValidates } from '../$models/ModelDef'
import { codeInputCommonAttrs } from '../$models/constants'
import { validator } from '../ValidationService/validator'
import { executeStringDefinedFunction } from '../utils'

/**
 * Validation types
 */
export type ValidationConfigType = {
  label: string
  // Validator の 固定引数, true 等
  fixedArg?: any
  // 正規表現での定義
  regExp?: string
  // Validator の 引数指定定義, 最大値など
  argInputs?: {
    desc: string
    argInputValidationFunc?: CustomValidationFunction
  }[]
  codeInput?: true
}

type ValidatorKeys = 'notEmpty' | 'customFunc' | 'regex' | 'isInt' | 'isDecimal' | 'min' | 'max' | 'len' | 'isSingleByte' | 'isAlpha' | 'isAlphanumeric' | 'isEmail' | 'isKatakana' | 'isHiragana' | 'isPhoneNumberWithoutHyphen' | 'isPhoneNumberWithHyphen' | 'contains' | 'notContains'

const validationConfigTypeNumberOnly: CustomValidationFunction = (value, arg) => {
  if (value === '' || isNaN(Number(value))) {
    return '数字で入力してください'
  }
  return
}

const regexpFlags = ['g', 'i', 'm', 's', 'u', 'y']

/**
 * UIから設定可能なValidationTypes の定義
 */
export const validationConfigTypes: {
  [K in ValidatorKeys]?: ValidationConfigType
} = {
  notEmpty: { label: '入力必須', fixedArg: true },
  customFunc: { label: 'カスタム関数定義', codeInput: true },
  regex: {
    label: '正規表現',
    argInputs: [
      {
        desc: '正規表現を入力 例:<code>^d{3}-d{4}-d{4}$</code>',
        argInputValidationFunc: (value) => {
          if (!value) {
            return '入力必須です'
          }
          try {
            new RegExp(value)
          } catch (e) {
            return '正規表現の形式が不正です'
          }
        },
      },
      {
        desc: `正規表現フラグを指定 (${regexpFlags.join(', ')})`,
        argInputValidationFunc: (value) => {
          // regexpFlags に含まれない文字が入力されていたらエラー
          if (value.split('').some((flag) => !regexpFlags.includes(flag))) {
            return `${regexpFlags.join(', ')} のみ指定可能です`
          }
        },
      },
    ],
  },
  isInt: { label: '数値', fixedArg: true },
  isDecimal: { label: '数値(小数点許可)', fixedArg: true },
  min: {
    label: '最小数値',
    argInputs: [
      {
        desc: '許可する最小値を数値で入力',
        argInputValidationFunc: validationConfigTypeNumberOnly,
      },
    ],
  },
  max: {
    label: '最大数値',
    argInputs: [
      {
        desc: '許可する最大値を数値で入力',
        argInputValidationFunc: validationConfigTypeNumberOnly,
      },
    ],
  },
  len: {
    label: '文字数制限',
    argInputs: [
      { desc: '最小文字数', argInputValidationFunc: validationConfigTypeNumberOnly },
      {
        desc: '最大文字数',
        argInputValidationFunc: validationConfigTypeNumberOnly,
      },
    ],
  },
  isSingleByte: { label: '半角文字列のみ許可', fixedArg: true },
  isAlpha: { label: '英字のみ許可', fixedArg: true },
  isAlphanumeric: { label: '英数字のみ許可', fixedArg: true },
  isEmail: { label: 'メールアドレス', fixedArg: true },
  isKatakana: { label: 'カタカナのみ許可', fixedArg: true },
  isHiragana: { label: 'ひらがなのみ許可', fixedArg: true },
  isPhoneNumberWithoutHyphen: { label: '電話番号(ハイフンなし)', regExp: '^[0-9]{10,11}$' },
  isPhoneNumberWithHyphen: {
    label: '電話番号(ハイフンあり)',
    regExp: '^[0-9]{2,4}-[0-9]{2,4}-[0-9]{3,4}$',
  },
  // isPhoneNumberGlobalHeadPlus: { label: '電話番号(国際)', regExp: '^\\+[0-9]{1,3}-[0-9]{2,4}-[0-9]{2,4}-[0-9]{3,4}$' },
  contains: { label: '特定文字列の入力強制', argInputs: [{ desc: '含める文字列を指定します' }] },
  notContains: {
    label: '特定文字列の入力拒否',
    argInputs: [{ desc: '含めない文字列を指定します' }],
  },
}

/**
 *
 */
const validatorArgColDefGenerator = (index: number): ColumnDef => {
  return {
    label: `引数${index + 1}`,
    type: 'STRING',
    enableIf: (childRow, row) =>
      !!validationConfigTypes[childRow.validationType]?.argInputs?.[index],
    validate: {
      customValidate: (
        value,
        col: ColumnDef,
        modelName: string,
        childRow: any,
        recordRoot: any,
      ) => {
        if (
          validationConfigTypes[childRow.validationType]?.argInputs?.[index]?.argInputValidationFunc
        ) {
          return validationConfigTypes[childRow.validationType]?.argInputs?.[
            index
          ]?.argInputValidationFunc(value, col, modelName, childRow, recordRoot)
        }
      },
    },
    afterComponent: {
      template: `<div class="mt-1 small" style="color: var(--bs-gray-600);" v-html="desc" :key="desc"/>`,
      computed: {
        parentComponent() {
          return this.$parent
          // return $core.$utils.findParentVueComponentByComponentName(this, 'ModelFormGroup')
        },
        childRow() {
          return this.parentComponent?.record
        },
        recordRoot() {
          return this.parentComponent?.recordRoot
        },
        desc() {
          if (!this.childRow?.validationType) {
            return ''
          }
          return validationConfigTypes[this.childRow?.validationType]?.argInputs?.[index]?.desc
        },
      },
    },
  }
}

export type ValidationConfigValueType = {
  validationType: ValidatorKeys
  validatorArg1?: string
  validatorArg2?: string
  customErrorMessage?: string
  customCodeExpression?: string
}

/**
 * バリデーション定義の設定 Column
 */
export const validationConfigs: ColumnDef = {
  label: ' 入力値チェック設定',
  type: 'ARRAY_OF_OBJECT',
  groupKey: 'colValidations',
  displayAsDetail: true,
  hideLabel: true,
  minValueLength: 0,
  width: {
    xs: 48,
  },
  columns: {
    validationType: {
      label: 'チェック種別',
      type: 'STRING',
      validate: { notEmpty: true },
      selections: () => Object.keys(validationConfigTypes),
      customLabel: (value: string) => validationConfigTypes[value]?.label || value,
    },
    validatorArg1: validatorArgColDefGenerator(0),
    validatorArg2: validatorArgColDefGenerator(1),
    // TODO: 実装...
    customErrorMessage: {
      // beforeComponent: ' ', // splitter
      label: 'カスタムエラーメッセージ',
      type: 'STRING',
      width: {
        sm: 36,
      },
      inputHelpText: '未入力の場合、デフォルトのエラーメッセージが表示されます',
      enableIf: (childRow, row) =>
        childRow.validationType && !validationConfigTypes[childRow.validationType]?.codeInput,
    },
    // TODO: 実装...
    // enableCondition: {},
    customCodeExpression: {
      label: 'カスタムコード',
      type: 'TEXT',
      inputAttrs: {
        ...codeInputCommonAttrs,
      },
      afterComponent:
        '<HelpDoc doc-key="model:modelDefinitions.columns.columns.customCodeExpression" class="mt-2">バリデーションエラーである場合に、エラーメッセージを返却してください。 例: <code>return value === "" ? "入力必須です" : false</code><br/>引数 (<code>value</code>: 入力されたフィールドの値, <code>col</code>: カラム定義, <code>modelName</code>: モデル名, <code>record</code>: レコード(ARRAY_OF_OBJECT でネストしている場合は子レコード), <code>recordRoot</code>: レコード</code>) が利用できます。 また <code>await</code> および <code>$core</code> オブジェクトも利用可能です。</HelpDoc>',
      enableIf: (childRow, row) => !!validationConfigTypes[childRow.validationType]?.codeInput,
    },
  },
}

type validationConfig = {
  validationType
  validatorArg1
  validatorArg2
  customErrorMessage
  customCodeExpression
}

/**
 * validationConfigs のデータを ColumnDef.validate に変換する
 * ModelDefinition load 時に利用する
 * @param validationConfigs
 */
export const parseValidationConfigIntoColumnDefValidateProp = (
  validationConfigs: validationConfig[],
): IValidates => {
  if (!validationConfigs || validationConfigs.length === 0) {
    return {}
  }
  return validationConfigs.reduce((res, config, index) => {
    const {
      validationType,
      validatorArg1,
      validatorArg2,
      customCodeExpression,
      customErrorMessage,
    } = config
    const validationConfigType = validationConfigTypes[validationType]
    if (!validationConfigType) {
      return res
    }
    const validatorArgs = validationConfigType.fixedArg
      ? validationConfigType.fixedArg
      : [validatorArg1, validatorArg2]
    if (validationConfigType.codeInput) {
      // 1. 関数定義でのバリデーション
      const executableFuncFromString = executeStringDefinedFunction({
        functionString: customCodeExpression,
        returnAsExecutableFunction: true,
        functionArgValues: {},
        functionArgExpression: 'value, col, modelName, record, recordRoot',
        errorThrow: false,
      })
      res[`${validationType}___${index}`] = async (value, col, modelName, record, recordRoot) => {
        return executableFuncFromString(value, col, modelName, record, recordRoot)
      }
    } else if (customErrorMessage && validator[validationType]) {
      // 2. カスタムエラーメッセージがある場合は...
      res[`${validationType}___${index}`] = async (value) => {
        const errorMessage = await validator.invokeValidator(value, validatorArgs, validationType)
        if (errorMessage) {
          return customErrorMessage
        }
      }
    } else {
      // 3. カスタムエラーメッセージがない場合
      res[validationType] = validatorArgs
    }
    return res
  }, {})
}
