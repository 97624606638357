<template>
  <draggable
    :tag="tagName"
    :list="items"
    :group="componentUid"
    :component-data="{ name: 'fade' }"
    @start="onDragStart"
    @end="onDragEnd"
    item-key="id"
    class="data-list-display-table-body"
    ghost-class="drag-ghost"
    data-group-id="groupId"
    :disabled="!dragSortable"
  >
    <template #item="{ element, index }">
      <DataListDisplayTableBodyRow
        :index="index"
        :key="element.id"
        :record="element"
        :item="element"
      >
        <slot />
      </DataListDisplayTableBodyRow>
    </template>
  </draggable>
</template>

<style lang="scss">
.drag-ghost {
  background-color: red !important;
}
</style>

<script lang="ts">
import { inject, PropType } from 'vue'
import { registerComposableComponentSettings } from '../../../../../ComposableComponents'
import { ComposableDataListService } from '../../../ComposableDataListService'

type VueDraggableDragEndCustomEvent = {
  type: 'end' // 固定
  clone: HTMLElement
  from: HTMLElement
  isTrusted: boolean // false
  item: HTMLElement
  newDraggableIndex: number // 2
  newIndex: number // 2
  oldDraggableIndex: number // 0
  oldIndex: number // 0
  originalEvent: DragEvent
  pullMode: undefined // ??
  to: HTMLElement
  bubbles: boolean // true
  cancelBubble: boolean // false
  cancelable: boolean // true
  composed: boolean // false
  currentTarget: null
  defaultPrevented: boolean // false
  detail: null
  eventPhase: number // 0
  returnValue: boolean // true
  srcElement: HTMLElement // tbody.data-list-display-table-body.composable-component-item-renderer
  target: HTMLElement // tbody.data-list-display-table-body.composable-component-item-renderer
  timeStamp: number // 6498
}

const name = 'DataListDisplayDragSortableList'

registerComposableComponentSettings(name, {
  hasDefaultSlot: true,
  configColumns: {
    tagName: {
      label: 'Wrapper 要素タグ名',
      type: 'STRING',
      inputHelpText: 'div, tbody など の HTML タグ名を指定します。',
      defaultValue: 'tbody',
    },
    sortColNameForAutoSave: {
      label: '並び順 自動保存先カラム名',
      type: 'STRING',
      inputHelpText: '設定すると、並び替え後に、並び順を自動保存します。指定したカラム名に対して、降順で 並び順の値 (数値) を再割り当てします。',
    },
    autoSaveSuccessMessage: {
      label: '並び順 自動保存成功メッセージ',
      type: 'STRING',
      inputHelpText: '並び順の自動保存に成功した際に表示するメッセージを指定します。空欄にすると メッセージは表示されません。',
      defaultValue: '並び順を変更しました',
      enableIf: (row) => !!row.sortColNameForAutoSave,
    },
    sortCallbackFunctionString: {
      label: '並び替え後 コールバック関数',
      type: 'TEXT',
      inputComponent: 'CodeEditor',
      inputHelpText: `引数 items, movedItem, oldIndex, newIndex, ComposableDataListServiceInstance が利用可能です。items は 並び替え実施済みのレコードの配列です。`
    },
  }
})


/**
 * 並び順を保存する (quiet)
 *
 * ## 処理の流れ
 * - 1. 並び順 値を set (DESC)
 * - 2. 保存実行 (id, sort key のみ) without before / after save callback
 *
 * ## sort key の値の割当ロジック
 * - まず list 内で 最大値を取得
 * - 最大値 が list の長さ を 超えているなら、そのまま利用する。 そうでないなら、 list の長さを利用する。
 * - sort key の値を割り当てる
 *
 */
const autoSaveSortOrderAfterSortActionEnd = async ({
  items,
  sortColNameForAutoSave,
  modelName,
  virtualModelName,
}: {
    items: any[],
    sortColNameForAutoSave: string,
    modelName: string,
    virtualModelName: string | null,
  }
) => {
  if (!sortColNameForAutoSave || !modelName) {
    return
  }
  await $core.$storeMethods.bulkUpsert({
    modelName,
    virtualModelName,
    data: applyResortAndExtractIdAndPropsForSave(items, sortColNameForAutoSave),
    disableBeforeSaveFunction: true,
    disableAfterSaveFunction: true,
    quiet: true,
    disableUserLatestStoreMethodActionTime: true,
  })
}
const applyResortAndExtractIdAndPropsForSave = (items: any[], sortColNameForAutoSave: string) => {
  const maxSortKey = Math.max(items.length, ...(items.map(item => item[sortColNameForAutoSave] || 0)))
  return items.map((item, index) => {
    item[sortColNameForAutoSave] = maxSortKey - index
    return {
      id: item.id,
      [sortColNameForAutoSave]: item[sortColNameForAutoSave],
    }
  })
}

type DragEndCallbackArgs = {
  items: any[],
  movedItem: any,
  oldIndex: number,
  newIndex: number,
  ComposableDataListServiceInstance: ComposableDataListService,
  event: VueDraggableDragEndCustomEvent,
}

type SortCallbackFunctionType = (args: DragEndCallbackArgs) => Promise<void>

/**
 * 一覧表示にて draggable sort を提供するコンポーネント
 */
export default {
  name: name,
  components: {
    draggable: $core.$frameworkUtils.defineAsyncComponent(() => import('vuedraggable')),
  },
  props: {
    tagName: {
      type: String,
      default: 'tbody',
    },
    sortColNameForAutoSave: {
      type: String,
      default: '',
    },
    autoSaveSuccessMessage: {
      type: String,
      default: '',
    },
    sortCallbackFunctionString: {
      type: String,
      default: '',
    },
    sortCallbackFunction: {
      type: Function as PropType<SortCallbackFunctionType>,
      default: null,
    },
  },
  emits: {
    dragEnd: (dragEndArgs: DragEndCallbackArgs) => true,
  },
  setup() {
    return {
      items: inject<any[]>('items'),
      ComposableDataListServiceInstance: inject<ComposableDataListService>(
        'ComposableDataListServiceInstance',
      ),
    }
  },
  computed: {
    componentUid() {
      return this._.uid
    },
    dragSortable() {
      //
      return true
    },
    sortCallbackFunctionStringExecutable() {
      if (this.sortCallbackFunction) {
        return this.sortCallbackFunction
      }
      if (!this.sortCallbackFunctionString) {
        return null
      }
      return $core.$utils.executeStringDefinedFunction({
        functionString: this.sortCallbackFunctionString,
        functionArgValues: {
          items: '',
          ComposableDataListServiceInstance: '',
          movedItem: '',
          oldIndex: 0,
          newIndex: 0,
        },
        errorThrow: true,
        returnAsExecutableFunction: true,
      })
    },
  },
  methods: {
    onDragStart(event, a, b) {
      console.log('onDragStart', { event, a, b })
      // debugger
    },
    async onDragEnd(event: VueDraggableDragEndCustomEvent, a, b) {
      console.log('onDragEnd', { event, a, b })
      // 並び順が変更されていない場合は 何もしない
      if (event.oldIndex === event.newIndex) {
        return
      }
      // quiet に 並び順を保存する
      if(this.sortColNameForAutoSave) {
        try {
          // ここでは this.items はすでに 並び替え実施済み である
          await autoSaveSortOrderAfterSortActionEnd({
            items: this.items,
            sortColNameForAutoSave: this.sortColNameForAutoSave,
            modelName: this.ComposableDataListServiceInstance.modelName,
            virtualModelName: this.ComposableDataListServiceInstance.virtualModelName,
          })
          if (this.autoSaveSuccessMessage) {
            $core.$toast.success(this.autoSaveSuccessMessage, {
              autoHideDelay: 3000
            })
          }
        } catch (e) {
          console.error('autoSaveSortOrderAfterSortActionEnd', e)
          $core.$toast.error(`並び順の自動保存に失敗しました: ${e.message}`)
        }
      }
      const dragEndArgs: DragEndCallbackArgs = {
        items: this.items,
        movedItem: this.items[event.newIndex],
        oldIndex: event.oldIndex,
        newIndex: event.newIndex,
        ComposableDataListServiceInstance: this.ComposableDataListServiceInstance,
        event,
      }
      if(this.sortCallbackFunctionStringExecutable) {
        try {
          await this.sortCallbackFunctionStringExecutable(dragEndArgs)
        } catch (e) {
          console.error('sortCallbackFunctionStringExecutable', e)
          $core.$toast.error(`並び替え後の関数の実行に失敗しました: ${e.message}`)
        }
      }
      this.$emit('dragEnd', dragEndArgs)
    },
  }
}
</script>
