<template>
  <div class="model-data-filter-saved-search-conditions">
    <span class="small">保存した検索条件</span>
    <span
      v-for="(ss, index) in savedSearchConditions"
      :key="index"
      class="badge badge-pill bg-primary mx-1"
      style="cursor: pointer"
      :class="appliedSearchConditionName === ss.name ? 'bg-primary' : 'bg-secondary'"
    >
      <span @click.prevent="applySavedSearchCondition(ss.name)">{{ ss.name }} </span>
      <span class="text-danger" @click.prevent="deleteSearchConditionByName(ss.name)">
        <ficon type="x"></ficon>
      </span>
    </span>
    <span v-if="isSaveableSearchCond">
      <a href="#" ref="addSaveCondButton" class="small mx-1" @click.prevent="() => false"
        >+ 条件を保存</a
      >
      <b-popover
        :target="() => $refs.addSaveCondButton"
        ref="addSaveCondPop"
        :triggers="['click']"
        hide-footer
        body-class="pb-0"
        placement="bottom"
        custom-class="_lg"
        centered
        title=""
      >
        <div style="min-width: 200px">
          <div class="input-group input-group-sm flex-nowrap">
            <input
              type="text"
              class="form-control"
              v-model="newlyAddingSearchConditionName"
              placeholder="条件名を入力"
            />
            <span class="btn btn-outline-primary" @click.prevent="saveCurrentSearchCondition()"
              >保存</span
            >
          </div>
        </div>
      </b-popover>
    </span>
  </div>
</template>
<script lang="ts">
import { inject } from 'vue'
import { registerComposableComponentSettings } from '../../plugins/ComposableComponents'
import { ComposableDataListService } from '../../plugins/ComposableDataListComponents/front/ComposableDataListService'

const name = 'ModelDataFilterSavedSearchConditions'
registerComposableComponentSettings(name, {
  label: name,
  hasDefaultSlot: true,
  configColumns: {},
  defaultProps: {},
})

const lsCacheTTL = -1
const defaultQueryState = {
  selectedFilterCols: [],
  filter: {},
  sort: '',
  limit: 100,
  search: '',
}
const defaultQueryStateStringified = JSON.stringify(defaultQueryState)

export default {
  name,
  data() {
    return {
      savedSearchConditions: [],
      appliedSearchConditionName: '',
      newlyAddingSearchConditionName: '',
    }
  },
  setup() {
    return {
      ComposableDataListServiceInstance: inject<ComposableDataListService>(
        'ComposableDataListServiceInstance',
      ),
    }
  },
  mounted() {
    this.loadSavedSearchConditionsFromLocalStorage()
    // デフォルトの検索条件がある場合は適用する
    const defaultSearchCond = this.savedSearchConditions.find((ss) => ss.isDefault)
    if (defaultSearchCond) {
      this.applySavedSearchCondition(defaultSearchCond.name)
    }
  },
  computed: {
    // searchConditionSaveKey() {
    //   return this.$parent.searchConditionSaveKey
    // },
    localStorageSaveKey() {
      return `modelIndexSearchConds--${this.searchConditionSaveKey}`
    },
    currentQuery() {
      return this.ComposableDataListServiceInstance.FilterControlsServiceInstance.filterQuery
    },
    isSaveableSearchCond() {
      return JSON.stringify(this.currentQuery) !== defaultQueryStateStringified
    },
    searchConditionSaveKey() {
      return this.ComposableDataListServiceInstance.modelName
    },
  },
  methods: {
    loadSavedSearchConditionsFromLocalStorage() {
      console.log(`loadSavedSearchConditionsFromLocalStorage() { called.`)
      this.savedSearchConditions = $core.$lsCache.get(this.localStorageSaveKey) || []
    },
    async saveCurrentSearchCondition() {
      // Filterのクエリが空Objectの場合には保存しない
      if (
        Object.keys(
          this.ComposableDataListServiceInstance.FilterControlsServiceInstance.filterQuery,
        ).length === 0
      ) {
        $core.$toast.errorToast('検索条件が設定されていないです')
        return
      }
      const name = this.newlyAddingSearchConditionName
      if (!name) {
        return
      }
      const isDefault = window.confirm('この検索条件をデフォルトに設定しますか？')
      const saveingQueryData = {
        name,
        isDefault,
        query: this.currentQuery,
      }
      let savedSearchConditions = this.savedSearchConditions.filter((ss) => ss.name !== name)
      if (isDefault) {
        savedSearchConditions = savedSearchConditions.map((s) => {
          s.isDefault = false
          return s
        })
      }
      savedSearchConditions.push(saveingQueryData)
      $core.$lsCache.set(this.localStorageSaveKey, savedSearchConditions, lsCacheTTL)
      $core.$toast.successToast('検索条件を保存しました。')
      this.newlyAddingSearchConditionName = ''
      this.$refs.addSaveCondPop.$emit('close')
      this.$nextTick(() => {
        this.loadSavedSearchConditionsFromLocalStorage()
        this.appliedSearchConditionName = name
      })
    },
    async deleteSearchConditionByName(name) {
      if ((await $core.$toast.confirmDialog(`検索条件 "${name}" を削除しますか？`)) === false) {
        return
      }
      $core.$lsCache.set(
        this.localStorageSaveKey,
        this.savedSearchConditions.filter((ss) => ss.name !== name),
        lsCacheTTL,
      )
      this.$nextTick(() => {
        this.loadSavedSearchConditionsFromLocalStorage()
      })
    },
    applySavedSearchCondition(searchConditionName) {
      const savedQuery = this.savedSearchConditions.find(
        (ss) => ss.name === searchConditionName,
      )?.query
      if (!savedQuery) {
        return
      }
      // Reactiveを解除
      const _found = JSON.parse(JSON.stringify(savedQuery))
      this.ComposableDataListServiceInstance.FilterControlsServiceInstance.setFilterFromQueryFromObj(
        _found?._and || [],
      )
      this.appliedSearchConditionName = searchConditionName
    },
  },
}
</script>
