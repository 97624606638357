import { $models, $modelsLoader } from '../$models'
import { $virtualModelDefinitionsLoaderService } from './virtualModelDefinitionsLoaderService'
import { virtualModelDefinitions } from './virtualModelDefinitions'

/**
 * VirtualModelDefinitionsの挙動をInstall
 */
export const useVirtualModelDefinitionsFeature = () => {
  if (!$models.virtualModelDefinitions) {
    $modelsLoader.loadModel(virtualModelDefinitions)
  }
  // Add
  $core.$virtualModelDefinitionsLoaderService = $virtualModelDefinitionsLoaderService()
}
