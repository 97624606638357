import { RouterOptions, Router, createWebHashHistory, createRouter } from 'vue-router'
import { $frameworkUtils } from '../$frameworkUtils/$frameworkUtils'
import { $core } from '../$core'

const defaultRouterOption: RouterOptions = {
  routes: [],
  history: createWebHashHistory(),
}

const r = createRouter(defaultRouterOption)
// TODO: FIX IT...
// @ts-ignore
r.addRoutes = function (routes) {
  routes.map((route) => {
    this.addRoute(route)
  })
}

export const $router: Router = $frameworkUtils.makeItReactive(r as any)
