<template>
  <a href="#" class="display-raw-data-link" v-single-click="openModal"
    >データ全体を表示
    <ficon type="code" />
  </a>
</template>
<script lang="ts">
export default {
  props: {
    data: { required: true },
  },
  methods: {
    openModal() {
      const data = this.data
      $core.$modals.openModal({
        modalProps: {
          title: 'データ全体を表示',
        },
        component: {
          template: `<JsonViewer :jsonData="d" />`,
          computed: {
            d() {
              return data
            },
          },
        },
      })
    },
  },
}
</script>
