/**
 * 一定時間内でのアクションの2重発火を避けるために利用する関数。
 * ユーザーが誤ってボタンを2回クリックした場合や、API呼び出しを多重に発生させないようにするために特に有用
 *
 * ## 利用例
 * ```vue
 * // 2重発火の回避
 * <span class="btn" @click.prevent="event => $core.$utils.fireOnce(itemClick)(item.id, event, item)"> 送信 <span>
 * ```
 *
 * @param {Function} func - 2重発火を防ぐ対象の関数。
 * @param {number} [preventMs=500] - 2重発火を防ぐためのミリ秒単位の間隔。デフォルトは500ミリ秒。
 *
 * @returns {Function} - 新しい関数。この関数は元の関数と同じ引数を受け取りますが、preventMsミリ秒の間隔を開けてのみ実行されます。
 */
export function fireOnce(func: Function, preventMs: number = 1000): Function {
  let isClicked = false;

  return function (...args: any[]) {
    if (!isClicked) {
      isClicked = true;
      func.apply(this, args);

      setTimeout(() => {
        isClicked = false;
      }, preventMs);
    }
  }
}