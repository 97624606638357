/**
 * ES6 の Symbol を利用して厳密なSingleton export を実現するためのHelper Function
 * 何回召喚しても厳密に同じInstanceが帰ってくるよ、という関数
 */
export const singletonInstanceSummoner = <T>(
  baseName: string,
  CallerClass: { new (...args: any[]): T },
  constructorArgs: any[] = [],
): T => {
  const key = Symbol.for(`PF_${baseName}`)
  // @ts-ignore
  if (!globalThis[key]) {
    // @ts-ignore
    globalThis[key] = new CallerClass(...constructorArgs)
  }
  // @ts-ignore
  return globalThis[key]
}
