import { ColumnDef } from '../$models/ModelDef'

export const columnDisplayValueFormatter = (columnDef: ColumnDef, r: Record<string, any>): string => {
  const colName = columnDef.name
  try {
    if (r[colName] === undefined || r[colName] === null) {
      // listLabelFormatter 定義がある場合
      if (columnDef.listLabelFormatter) {
        return columnDef.listLabelFormatter(r)
      }

      // labelFormatter 定義がある場合
      if (columnDef.labelFormatter) {
        return columnDef.labelFormatter(r)
      }

      // customLabel func がある場合
      if (columnDef.customLabel) {
        return columnDef.customLabel(r[colName])
      }
      return ''
    }

    // listLabelFormatter 定義がある場合
    if (columnDef.listLabelFormatter) {
      return columnDef.listLabelFormatter(r)
    }
    /**
     * RELATIONSHIP_M2Oの場合
     */
    if (columnDef.type === 'RELATIONSHIP_MANY_TO_ONE') {
      const labelFormatter = columnDef.relationshipManyToOne.labelFormatter
      if (typeof labelFormatter === 'function') {
        return labelFormatter(r[colName])
      }
      if (labelFormatter && typeof labelFormatter === 'string') {
        console.log(`[${colName}] if (labelFormatter && typeof labelFormatter === 'string') {`)
        const func = new Function(
          `{ return function (row) { ${columnDef.relationshipManyToOne.labelFormatter} } };`,
        )
        return func.call(null).call(null, r[colName])
      }
      // @ts-ignore
      return Object.values(r[colName])[0]
    }

    /**
     * RELATIONSHIP_O2Mの場合
     */
    if (columnDef.type === 'RELATIONSHIP_ONE_TO_MANY') {
      const labelFormatter = columnDef.relationshipOneToMany.labelFormatter
      if (typeof labelFormatter === 'function') {
        return labelFormatter(r[colName])
      }
      if (labelFormatter && typeof labelFormatter === 'string') {
        const func = new Function(
          `{ return function (rows) { ${columnDef.relationshipOneToMany.labelFormatter} } };`,
        )
        return func.call(null).call(null, r[colName])
      }
      return r[colName].length
    }

    // labelFormatter 定義がある場合
    if (columnDef.labelFormatter) {
      return columnDef.labelFormatter(r)
    }

    // customLabel func がある場合
    if (columnDef.customLabel) {
      return columnDef.customLabel(r[colName])
    }

    // TODO: DATETIME, DATE, TIMEの場合, fomart して返却
    // if (r[colName] && ['DATETIME', 'DATE', 'TIME'].indexOf(columnDef.type) >= 0) {
    //   return $core.$dayjs(r[colName]).format(columnDef.format)
    // }

    return $core.$utils.escapeHtml(r[colName])
  } catch (e) {
    console.error(e)
    return `${r[colName]}<span class="text-danger core-admin-role-only">Error: ${e.message}, ${JSON.stringify(r)}</span>`
  }
}