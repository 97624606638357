<template>
  <div v-if="componentsById" class="composable-component-renderer">
    <template v-for="(item, index) in dataWithParsedByIdFrozen.children" :key="item.id">
      <ComposableComponentItemRenderer
        v-if="item.isDeleting !== true"
        :composableComponentItem="item"
        :childPath="`${index}`"
        :pagePath="'pageRoot'"
      />
    </template>
  </div>
</template>
<script lang="ts">
import {
  ComposableComponentData,
  ComposableComponentDataWithByComponentIdRefs,
  convertComposableComponentDataToComposableComponentDataWithByComponentIdRefs,
} from '../../../ComposableComponentTypes'
import { PropType } from 'vue'

export default {
  name: 'ComposableComponentRenderer',
  props: {
    builderData: {
      type: Object as PropType<ComposableComponentData>,
      required: false,
    },
    builderDataWithParsedById: {
      type: Object as PropType<ComposableComponentDataWithByComponentIdRefs>,
      required: false,
    },
    lastModifiedAt: {
      type: String || Number,
      required: false,
    },
    makeStructureReactive: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    dataWithParsedById() {
      if (this.builderDataWithParsedById) {
        return this.builderDataWithParsedById
      }
      return convertComposableComponentDataToComposableComponentDataWithByComponentIdRefs(
        this.builderData,
      )
    },
    dataWithParsedByIdFrozen() {
      return this.makeStructureReactive
        ? this.dataWithParsedById
        : Object.freeze(this.dataWithParsedById)
    },
    componentsById() {
      return this.dataWithParsedByIdFrozen?.componentById || null
    },
    children() {
      return this.dataWithParsedByIdFrozen?.children || null
    },
  },
  provide() {
    return {
      componentsById: this.componentsById,
    }
  },
}
</script>
