<template>
  <component
    :is="itemTagComputed"
    @click="
      () => {
        applyFilter()
        $emit('click')
      }
    "
    :class="[itemClass, isActive ? itemClassActive : '']"
  >
    <component
      :is="itemChildTag || 'span'"
      :class="[itemChildClass, isActive ? itemChildClassActive : '']"
    >
      <span v-html="filterGroupItem.label" />
      <span v-if="filterGroupItem.showCount && !isNaN(count)" class="ml-1" :class="countClass">{{
        count
      }}</span>
    </component>
  </component>
</template>
<script lang="ts">
// DataListQueryFilterGroupToggleItem
import { inject, PropType } from 'vue'
import { DataListQueryFilterGroupToggleFilterGroupItem } from './DataListQueryFilterGroupToggle.vue'
import { ComposableDataListService } from '../../ComposableDataListService'

export default {
  props: {
    isActive: {
      type: Boolean,
      required: true,
    },
    filterGroupKey: {
      type: String,
      required: true,
    },
    itemTag: {},
    itemClass: {},
    itemClassActive: {},
    itemChildTag: {},
    itemChildClass: {},
    itemChildClassActive: {},
    filterGroupItem: {
      type: Object as PropType<DataListQueryFilterGroupToggleFilterGroupItem>,
      required: true,
    },
  },
  data() {
    return {
      filter: null,
      count: null,
    }
  },
  async mounted() {
    this.filter = await this.filterFormStringDefinedFunc()
    this.setCount()
    if (this.isActive) {
      // filter を適用してしまう
      this.$nextTick(() => {
        setTimeout(() => {
          this.applyFilter()
        }, 1)
      })
    }
  },
  watch: {
    isActive() {
      if (this.isActive) {
        // filter を適用してしまう
        this.$nextTick(() => {
          this.applyFilter()
        })
      }
    },
    // ユーザーが最後にデータを変更した時にカウントを更新する
    '$core.$uiState.userLatestStoreMethodActionTime'() {
      if ($core.$uiState.userLatestStoreMethodActionModelName === this.ComposableDataListServiceInstance.modelName) {
        this.setCount()
      }
    }
  },
  computed: {
    itemTagComputed() {
      return this.itemTag || 'div'
    },
    countClass() {
      if (this.count === 0 && this.filterGroupItem.countClassWhenZero) {
        return this.filterGroupItem.countClassWhenZero
      }
      if (this.filterGroupItem.countClass) {
        return this.filterGroupItem.countClass
      }
      if (this.count === 0) {
        return 'badge rounded-pill bg-light text-dark'
      }
      return 'badge rounded-pill bg-danger'
    },
  },
  setup() {
    return {
      ComposableDataListServiceInstance: inject<ComposableDataListService>(
        'ComposableDataListServiceInstance',
      ),
    }
  },
  methods: {
    async filterFormStringDefinedFunc() {
      try {
        const filter = await $core.$utils.executeStringDefinedFunction({
          functionString: this.filterGroupItem.__func__filter,
          functionArgValues: { thisInstance: this },
        })
        return filter || {}
      } catch (e) {
        console.error(e)
        return {}
      }
    },
    async setCount() {
      if (this.filter) {
        this.count = await this.ComposableDataListServiceInstance.model.countBy(
          this.ComposableDataListServiceInstance.query.mergeFilter(this.filter),
        )
      }
    },
    applyFilter() {
      if (this.filter) {
        this.ComposableDataListServiceInstance.query.applyFilter(this.filterGroupKey, this.filter)
      }
    },
  },
}
</script>
