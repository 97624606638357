<template>
  <span>{{ $core.$dict.get(k, f) }}</span>
</template>
<script>
export default {
  props: {
    k: {},
    f: {},
  },
}
</script>
