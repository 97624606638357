/**
 * 検索をサポートするサービス, クエリの構築を支援
 */
import { ModelFactory } from '../../common/$models'

export const convertURLQueryFilterForModelIndex = (queryFilters: string) => {
  if (!queryFilters) {
    return null
  }
  if (typeof queryFilters === 'string') {
    try {
      const parsed = JSON.parse(decodeURIComponent(queryFilters))
      return Object.keys(parsed).reduce((res, key) => {
        if (typeof parsed[key] === 'string' || typeof parsed[key] === 'number') {
          res[key] = { _eq: parsed[key] }
        } else {
          res[key] = parsed[key]
        }
        return res
      }, {})
    } catch (e) {
      console.error('[convertURLQueryFilterForModelIndex] Failed to parse url queryFilters:', e)
      return null
    }
  }
}

class ModeIndexSearchService {
  constructor() {
    // do nothing!
  }

  /**
   * 検索状態を初期化, パラメータなどを初期化する
   * @param $model
   */
  init($model: ModelFactory) {}
}
