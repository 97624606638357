<template>
  <div>
    <slot></slot>
  </div>
</template>
<script lang="ts">
import { registerComposableComponentSettings } from '../../../ComposableComponents'
import { FilterControlsService } from '../FilterControlsService'
import { computed, inject, provide } from 'vue'
import { ComposableDataListService } from '../ComposableDataListService'

registerComposableComponentSettings('ComposableDataListSearchWrapper', {
  label: 'ComposableDataListSearchWrapper',
  hasDefaultSlot: true,
  configColumns: {},
  defaultProps: {},
})

export default {
  name: 'ComposableDataListSearchWrapper',
  props: {},
  data() {
    return {
      FilterControlsService: null as FilterControlsService,
    }
  },
  setup() {
    return {
      ComposableDataListServiceInstance: inject<ComposableDataListService>(
        'ComposableDataListServiceInstance',
      ),
    }
  },
  created() {
    this.ComposableDataListServiceInstance.FilterControlsServiceInstance.appendModernFilter()
    this.FilterControlsService =
      this.ComposableDataListServiceInstance.FilterControlsServiceInstance
    // FilterControlsServiceをproviderする
    provide(
      'FilterControlsServiceInstance',
      computed(() => this.FilterControlsService),
    )
  },
}
</script>
