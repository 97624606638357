import { singletonInstanceSummoner } from '../../common/singletonInstanceSummoner'
import { DataExporter } from './DataExporter'

/**
 * ./ModelDataExportSettings に基づいてデータをエクスポートする Service
 */
export class DataExportService {
  public dataExportSettingsByName: { [settingName: string]: any }
  public selectedDataExportSettingName: string
  public dataExportSetting: any
  public filter: any
  public exportFormatType: 'xlsx' | 'csv'
  // .find({search}) パラメータ
  public searchKeyword: string

  constructor() {
    this.dataExportSettingsByName = {}
    this.selectedDataExportSettingName = ''
  }

  get selectedDataExportSetting() {
    return this.dataExportSettingsByName[this.selectedDataExportSettingName]
  }

  /**
   * 選択可能なエクスポート設定を取得しておく for selection
   */
  async fetchExportSettings() {
    this.dataExportSettingsByName = (
      await $core.$models.dataExportSettings.find({ limit: -1 })
    ).reduce((res, d) => {
      res[d.name] = d
      return res
    }, {})
  }

  static get instance(): DataExportService {
    return singletonInstanceSummoner('DataExportService', DataExportService)
  }

  async exportBySettingName(
    settingName: string,
    filter: any = {},
    exportFormatType: 'xlsx' | 'csv',
    searchKeyword: string = '',
  ) {
    this.selectedDataExportSettingName = settingName
    this.filter = filter || {}
    this.searchKeyword = searchKeyword || ''
    this.exportFormatType = exportFormatType
    await this._execExport()
  }

  async _execExport() {
    // 設定を取得
    const dataExportSetting: any = await $core.$models.dataExportSettings.findOne({
      filter: { name: { _eq: this.selectedDataExportSettingName } },
    })
    if (!dataExportSetting) {
      $core.$toast.errorToast(
        `エクスポート設定 "${this.selectedDataExportSettingName}" が見つかりませんでした。`,
      )
      return
    }
    await new DataExporter(
      dataExportSetting,
      this.filter,
      true,
      this.searchKeyword,
      this.exportFormatType,
    ).run()
  }
}

export const $dataExportService = DataExportService.instance
