import PageModelIndex from './index.vue'
import PageModelEdit from './edit/_id.vue'
import PageModelView from './_id.vue'
import PageModelAddNew from './new.vue'
import { RouteRecordRaw } from 'vue-router'

export const fallbackModelRoutes: RouteRecordRaw[] = [
  {
    path: '/m/:fallback_model',
    name: 'fallback_model',
    component: PageModelIndex,
  },
  {
    path: '/m/:fallback_model/edit/:id',
    name: 'fallback_model-edit-id',
    component: PageModelEdit,
  },
  {
    path: '/m/:fallback_model/view/:id',
    name: 'fallback_model-view-id',
    component: PageModelView,
  },
  {
    path: '/m/:fallback_model/new',
    name: 'fallback_model-new',
    component: PageModelAddNew,
  },
  // 順番は大事
  {
    path: '/m/:fallback_model/:id',
    name: 'fallback_model-id',
    component: PageModelView,
  },
]

export { PageModelIndex, PageModelEdit, PageModelView, PageModelAddNew }
