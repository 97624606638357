import { ModelNameSpecifiedClassBase } from '../../ModelServices/ModelNameSpecifiedClassBase'
import { DataListQuery } from './DataListQuery'
import { UnwrapNestedRefs, watch, reactive, shallowReactive, ref, Ref, ShallowReactive } from 'vue'
import { ColumnDefByColName } from '../../../common/$models/ModelDef'
import { FilterControlsService } from './FilterControlsService'
import { CanceledError, CancellablePromise } from '../../../common/utils'

type QueryChangeCallbackFunction = (ins: ComposableDataListService) => void

/**
 * Composable Components にてData list view を司るサービス
 * items, initialized, isLoading, page, limit, sort, search, meta, filter, filter_count, bulkControlSelectedItems, lastCheckedCheckboxItemIndex などのpropsを管理する
 *
 * constructor 時に設定可能な props with default
 *
 * modelName: { required: true },
 * virtualModelName: { required: false },
 * filters: { required: false, default: null, type: [Object, String] },
 * itemClickEvent: { required: false, default: null, type: [Function, String] },
 * searchConditionSaveKey: { required: false, default: null },
 * resolveHookNamePrefix: { required: false, default: null },
 * enableSort: { type: Boolean, default: true },
 * enableKeywordSearch: { type: Boolean, default: true },
 * enableLimitControl: { type: Boolean, default: true },
 * limitLabel: { default: '表示件数' },
 * defaultLimit: { default: 100 },
 * filterableColNames: { default: null, type: Array },
 * enableHeader: { type: Boolean, default: true },
 * enableControls: { type: Boolean, default: true },
 * wrapperClass: { default: 'card' },
 * enablePagination: { type: Boolean, default: true },
 * listColNames: { default: null, type: Array },
 * enableExcelExportButton: {
 * enableAddNewRecordButton: { type: Boolean, default: false },
 * enableFilter: { type: Boolean, default: true },
 * enableFixedFilterStateDisplay: {
 * queryFilters: { default: null, type: Object },
 * urlQueryFilterChangeable: { type: Boolean, default: false },
 * headerTitle: { default: null },
 * initialPage: { default: 1 },
 */
export class ComposableDataListService extends ModelNameSpecifiedClassBase {
  public initialized = false
  private _isLoading = reactive({ value: false })
  public bulkControlSelectedItems: Record<string, any>[] = []
  public lastCheckedCheckboxItemIndex = -1
  public disableFindDataOnLoad: boolean = false
  public disableFindDataOnQueryChange: boolean = false
  public disableRefreshDataOnDataEdited: boolean = false
  // Reactive!
  public query: UnwrapNestedRefs<DataListQuery>
  // public dataList: UnwrapNestedRefs<DataListDataItems>
  public dataList: ShallowReactive<DataListDataItems>
  public queryChangeCallbackFunctions: { [key: string]: QueryChangeCallbackFunction } = {}
  public lastQueryChangedAt: Ref = ref(0)
  public lastDataFetchedAt: Ref = ref(0)
  public FilterControlsServiceInstance: FilterControlsService
  private _queryServiceStringified: string = ''
  private _findCancellablePromise: CancellablePromise<any> | null = null

  constructor() {
    super()
    // reactive: query 変更を検知する with callbacks
    this.query = reactive<DataListQuery>(new DataListQuery(this))
    this.FilterControlsServiceInstance = new FilterControlsService({
      ComposableDataListServiceInstance: this,
      ModelFormServiceInstance: null,
    })
    // shallowReactive: 取得したItemをreactiveにしないでおく
    this.dataList = shallowReactive(new DataListDataItems(this))
    this.initQueryChangedCallback()
    this.initDataEditedChangedCallback()
  }

  // 正しい状態かどうか
  get isValidState(): boolean {
    return !!this.model && !!this.columns
  }

  initQueryChangedCallback() {
    this.queryChangeCallbackFunctions['findDataOnQueryChange'] = (ins) => {
      if (!this.disableFindDataOnQueryChange) {
        setTimeout(() => {
          this.find()
        }, 1)
      }
    }

    // this.query の状態変更を監視 (deep)
    setTimeout(() => {
      watch(
        this.query,
        (value, oldValue, onCleanup) => {
          // ここで、query が変化したかどうかを判断, 変化していなければ、find() を実行しない
          const _stringified = JSON.stringify(value.findQueryParam)
          if (this._queryServiceStringified && _stringified === this._queryServiceStringified) {
            return // Query が変化していないので skip
          }
          this._queryServiceStringified = _stringified
          this.lastQueryChangedAt.value = new Date().getTime()
          this.invokeQueryChangeCallbackFunctions()
        },
        { deep: true },
      )
    }, 10)
  }

  initDataEditedChangedCallback() {
    // TODO: Watch の仕方: 本来、 $core.$uiState.userLatestStoreMethodActionTime だけをwatchしておきたいが...
    watch(
      $core.$uiState,
      (value, oldValue, onCleanup) => {
        setTimeout(() => {
          if (!this.disableRefreshDataOnDataEdited && value.userLatestStoreMethodActionTime) {
            this.find()
          }
          // Frameずらし
        }, 200)
      },
      { deep: true },
    )
  }

  get filteredColumns(): ColumnDefByColName {
    return this.virtualModel?.columnsMergedWithBaseModel || this.model.columns
  }

  get items() {
    return this.dataList.list
  }

  invokeQueryChangeCallbackFunctions() {
    Object.values(this.queryChangeCallbackFunctions).forEach((f) => f(this))
  }

  /**
   * データ fetch して良い状態かどうか？
   */
  get isFindable(): boolean {
    return !!this.isValidState
  }

  get isLoading() {
    return this._isLoading.value
  }

  set isLoading(value) {
    this._isLoading.value = value
  }

  /**
   * 指定された条件で検索を実行する
   */
  async find() {
    if (!this.isFindable) {
      return
    }
    // すでに実行中の場合、キャンセルする
    if(this._findCancellablePromise) {
      this._findCancellablePromise.cancel()
    }
    this.isLoading = true
    try {
      this._queryServiceStringified = JSON.stringify(this.query.findQueryParam)
      this._findCancellablePromise = new CancellablePromise(async (resolve, reject, _cancellablePromiseInstance) => {
        const res = await this.model.findWithCount(this.query.findQueryParam)
        resolve(res)
      })
      const res = await this._findCancellablePromise
      // ページングを掛けている状態で、データが0件になってしまう場合、ページを1にリセットする
      if (this.query.page !== 1 && !res.data?.length && res.meta.filter_count) {
        this.query.page = 1
      } else {
        this.dataList.list = res.data
        this.dataList.filter_count = res.meta.filter_count
      }
      this.updateLastDataFetchedAt()
    } catch (e) {
      if (e instanceof CanceledError) {
        // console.log(`[ComposableDataListService] Promise was canceled, with query: ${this._queryServiceStringified}`)
        return
      }
      console.error(e)
    } finally {
      this.isLoading = false
    }
  }
  cancelFind() {
    if (this._findCancellablePromise) {
      this._findCancellablePromise.cancel()
    }
  }

  updateLastDataFetchedAt() {
    // check if this.lastDataFetchedAt is Vue's ref instance
    if (this.lastDataFetchedAt.value) {
      this.lastDataFetchedAt.value = new Date().getTime()
    } else {
      this.lastDataFetchedAt = ref(new Date().getTime())
    }
  }

  exportAsExcel() {
    return this.dataList.exportAsExcel()
  }
}

/**
 * ComposableDataListService に関連
 * DataList の 検索条件を管理するクラス
 */
export class DataListDataItems {
  _list: Record<string, any>[] = []
  listLastUpdated: number = 0
  /**
   * 現在の検索条件内での total count
   */
  public filter_count: number = 0

  constructor(public readonly $dls: ComposableDataListService) {
  }

  get itemLength(): number {
    return this.list?.length || 0
  }

  get pageCount(): number {
    return Math.ceil((this.filter_count || 0) / this.$dls.query.limit)
  }

  get list(): Record<string, any>[] {
    return this._list
  }

  set list(v: Record<string, any>[]) {
    this._list = v
    this.listLastUpdated = Date.now()
  }

  exportAsExcel() {
    $core.$exportAsExcel.fromArrayOfObject({
      data: this.list,
      filename: `${this.$dls.modelName}_export_${$core.$dayjs().format('YYYYMMDDHHmmss')}`,
      modelName: this.$dls.modelName,
      sheetName: this.$dls.modelName,
    })
  }
}
