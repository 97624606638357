<template>
  <div
    v-if="isOnline === false"
    class="text-danger"
    style="
      position: fixed;
      bottom: 0;
      width: 100%;
      text-align: center;
      background-color: rgba(11, 13, 20, 0.85);
      font-weight: bold;
      padding: 0.5em 1em;
      z-index: 2000;
    "
  >
    インターネット接続がオフラインです。データの取得・更新はできません。
  </div>
</template>
<script lang="ts">
let eventListenerForOnlineDetectInitialized = false
const addEvents = ['online', 'offline', 'load']

export default {
  data() {
    return {
      isOnline: true,
    }
  },
  mounted() {
    this.initEventListenerForOnlineDetect()
  },
  methods: {
    updateOnlineStatus() {
      this.isOnline = window.navigator.onLine || false
    },
    initEventListenerForOnlineDetect() {
      if (eventListenerForOnlineDetectInitialized) {
        return
      }
      addEvents.map(event => window.addEventListener(event, this.updateOnlineStatus))
      eventListenerForOnlineDetectInitialized = true
      this.updateOnlineStatus()
    },
  },
}
</script>
