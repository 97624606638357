<template>
  <input
    type="text"
    class="form-control"
    v-bind:value="displayValue"
    @change="(_$event) => updateValue(_$event.target.value)"
    v-bind="commonAttrs"
  />
</template>

<script lang="ts">
import { PropType } from 'vue'

/**
 * 時刻 (HH:mm) を数値で入力するためのテキストボックス
 */
export default {
  name: 'TimeTextInput',
  props: {
    modelValue: { default: '' },
    commonAttrs: {
      type: Object as PropType<Record<string, unknown>>,
      required: false,
      default: () => ({}),
    },
  },
  computed: {
    displayValue() {
      return $core.$utils.toHourMinutesString(
        `${this.modelValue || ''}`,
        this.$attrs.maxTime,
        this.$attrs.minTime,
      )
    },
  },
  mounted() {},
  methods: {
    updateValue(value) {
      if (value == this.displayValue) {
        return // do nothing
      }
      this.initialized = false
      this.$nextTick(() => {
        this.$emit(
          'update:modelValue',
          $core.$utils.toHourMinutesString(value, this.$attrs.maxTime, this.$attrs.minTime),
        )
        this.initialized = true
      })
    },
  },
}
</script>
