<template>
  <input
    class="form-control w-auto"
    :value="ComposableDataListServiceInstance.query.limit"
    v-if="limit === 'numberInput'"
    type="number"
    @change="handleChangeLimit"
  />
  <select
    class="form-control w-auto"
    @change="handleChangeLimit"
    :value="ComposableDataListServiceInstance.query.limit"
    v-else
  >
    <option v-for="(option, index) in limitOptions" :key="index" :value="option.value">
      {{ option.label }}
    </option>
  </select>
</template>
<script lang="ts">
import { registerComposableComponentSettings } from '../../../../ComposableComponents'
import { inject } from 'vue'
import { ComposableDataListService } from '../../ComposableDataListService'

const name = 'DataListLimitControl'
const limitStyles = {
  default: {
    label: '選択式 (select)',
  },
  numberInput: {
    label: '件数入力 (input)',
  },
}
const limitOptions = [
  { value: '10', label: '10件' },
  { value: '20', label: '20件' },
  { value: '30', label: '30件' },
  { value: '50', label: '50件' },
  { value: '100', label: '100件' },
  { value: '200', label: '200件' },
  { value: '300', label: '300件' },
  { value: '500', label: '500件' },
  { value: '1000', label: '1000件' },
  { value: '-1', label: '無制限' },
]
registerComposableComponentSettings(name, {
  label: 'データリストコントロール',
  category: 'DataList',
  configColumns: {
    limit: {
      type: 'STRING',
      strictSelections: true, // 追加禁止
      selections: () => Object.keys(limitStyles),
      customLabel: (val) => limitStyles[val]?.label || '',
    },
  },
})

export default {
  props: {
    limit: {
      type: String,
      default: '',
    },
  },
  name,
  setup() {
    return {
      limitOptions: limitOptions,
      ComposableDataListServiceInstance: inject<ComposableDataListService>(
        'ComposableDataListServiceInstance',
      ),
    }
  },
  computed: {},
  methods: {
    handleChangeLimit(e: any) {
      this.ComposableDataListServiceInstance.query.limit = e.target.value
    },
  },
}
</script>
