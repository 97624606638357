import { ModelDef } from '../../../common/$models/ModelDef'
import { codeInputCommonAttrs } from '../../../common/$models'
import { addModelDoc } from '../../HelpDoc/coreDocsHelperFunctions'

addModelDoc(
  'core_user_group_definitions',
  `フィルタ条件・関数定義によって、ユーザグループを定義可能です。本定義を利用して <router-link to="/m/core_front_model_permission_config">条件付き編集権限設定</router-link> などを作成します。`,
)
/**
 * ユーザーグループ定義
 * - 各種, 権限やユーザ条件を指定する際に使用する
 */
export const core_user_group_definitions: ModelDef = {
  tableName: 'core_user_group_definitions',
  tableLabel: 'ユーザーグループ定義',
  primaryKeyColType: 'UUID',
  modelType: 'admin',
  columns: {
    name: {
      label: 'ユーザーグループ名',
      type: 'STRING',
      unique: true,
      validate: {notEmpty: true},
    },
    description: {
      label: '説明',
      type: 'TEXT',
      width: { xs: 48 },
    },
    user_filter_condition: {
      label: 'ユーザー絞り込み条件',
      type: 'JSON',
      inputComponent: {
        template: '<FilterResultDisplayContainer :emitValueAsObject="true" collectionName="directus_users"/>',
      },
      width: { xs: 48 },
    },
    use_user_filter_function: {
      label: 'ユーザー絞り込み関数を使用する',
      type: 'BOOLEAN',
    },
    user_filter_function: {
      label: 'ユーザー絞り込み関数',
      enableIf: (row) => row.use_user_filter_function,
      type: 'TEXT',
      width: { xs: 48 },
      defaultValue: '',
      inputAttrs: {
        ...codeInputCommonAttrs,
      },
      afterComponent: `変数 <code>users</code> (ユーザデータの配列) を利用して、追加の絞り込み処理を記述します。 必ず ユーザデータの配列を return してください。 await が 利用可能です。`,
    }
  }
}