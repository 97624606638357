<template>
  <div
    style="overflow-wrap: break-word"
    class="app-hookable-devInfo-tooltip"
  >
    <a class="app-hookable-devInfo-" href="#" @click.prevent="() => $refs.detailModal.show()">
      <span class="_small"><ficon type="code"/> <span class="small">$appHook:</span></span>
      <strong>'{{ resolveHookName }}'</strong>
    </a>
    <b-modal
      size="lg"
      ref="detailModal"
      ok-only
      ok-title="閉じる"
      :title='`AppHookableComponent - hookName: "${resolveHookName}"`'
    >
      <div>
        <p>
          <code>$appHook</code> を利用して UIパーツ を差し替えることが可能です。<br/>
          hookName: <code class="bold">{{ resolveHookName }}</code>
        </p>
        <b-tabs>
          <b-tab :title='`カスタムコードでの設定例`'>
            <p class="mt-2">
              <a target="_blank" href="/#/m/frontSideAppHooks">{{$core.$models.frontSideAppHooks.tableLabel}} <ficon type="external-link-alt"/></a> を利用して、Vueコンポーネント + Javascript で UI部品を追加する例です。
              <span class="d-block mt-2">
                <a class="btn btn-outline-primary btn-sm" href="#" @click.prevent="() => openFrontSideAppHooksCreateModal()"><ficon type="plus"/> サンプルコードを利用して新規追加</a>
              </span>
            </p>
            <CodePreview
              :value="sampleCode"
            />
          </b-tab>
          <b-tab title="属性値">
            <p class="mt-2">コンポーネント内で <code>this.$attrs.propName</code> もしくは <code>props: {propName: {}}</code> として設定すると受け取れる属性値</p>
            <CodePreview
              :value="JSON.stringify(givenAttrs, null, 2)"
            />
          </b-tab>
        </b-tabs>
      </div>
    </b-modal>
  </div>
</template>
<script lang="ts">

import { frontAppHooks } from '../../front/frontAppHooks'

/**
 * ./AppHookableComponent.vue 内で サンプルコードを表示するためのComponent
 * @param hookName
 */
const getAppHookSampleCode = (hookName: string): string => {
  return `// Vue コンポーネント定義を作成
const addingVueComponent = {
  // Vue template part
  template: \`<div class="card">
<div class="card-body">
  <ficon type="exclamation-circle"/>
    Sample Component! (hookName: ${hookName})
  <a href="#" class="small" @click.prevent="() => openAttrsDisplayModal()">$attrs を確認</a>
  <b-modal
  ref="attrsDisplayModal"
  size="lg"
  title="$attrs を確認"
    ><pre>givenAttrs: {{givenAttrs}}</pre></b-modal>
  </div>
</div>\`,
  // data props
  data() {
    return {}
  },
  // computed props
  computed: {
    givenAttrs() {
      return this.$attrs
    },
  },
  // methods
  methods: {
    openAttrsDisplayModal() {
      this.$refs.attrsDisplayModal.show()
    },
  },
}
/**
* パターン1: コンポーネントを "追加する" 場合
* - $appHook.on の引数 args は 他に 同じHook名で登録された コンポーネントが入る可能性があるため、配列にして返す
*/
if(!args) {
  args = []
}
if(!Array.isArray(args)) {
  args = [args]
}
return args.concat(addingVueComponent)

/**
* パターン2: コンポーネントを "置き換える" 場合
* - 作成したVueコンポーネント定義のみを配列として返却することで、他のコンポーネントを排除して置き換えることが可能です。
*/
// return [addingVueComponent]
`
}

/**
 * ./AppHookableComponent.vue 内で サンプルコードを表示するためのComponent
 */
export default {
  computed: {
    resolveHookName() {
      return this.$parent.resolveHookName
    },
    givenAttrs() {
      return this.$parent.$attrs
    },
    sampleCode() {
      return getAppHookSampleCode(this.resolveHookName)
    },
  },
  methods: {
    openFrontSideAppHooksCreateModal() {
      $core.$modals.openCreateViewModal({
        modelName: $core.$models.frontSideAppHooks.tableName,
        defaultValues: {
          appHookName: this.resolveHookName,
          functionName: `Change here to the unique name (${$core.$dayjs().format('YYYY-MM-DD HH:mm')})`, // 他のHook登録と重複しないように設定, 重複した場合は、上書きの挙動になる
          function: this.sampleCode,
        },
      })
    }
  }
}
</script>
