import { makeItReactive } from './$frameworkUtils/$frameworkUtils'

const defaultMessage = 'ロード中...'

/**
 * # $core.$loading
 *
 * 画面上のLoading表示をコントロールします。
 * - 長時間のサーバサイド処理を行う場合に、ユーザーに処理中であることを伝えるために使用します。
 * - 全画面表示を指定 (ユーザのUI操作をブロック) 可能です。 (`$core.$loading.start('サンプルメッセージ...', 'overlay')`)
 *
 * ```ts
 * // ロード表示
 * $core.$loading.start('ロード中です...')
 * // $core.$loading.start('ロード中です...', 'overlay') // 全画面表示にしたい場合
 * try {
 *   await somePromiseFunction()
 *   ...
 * } catch (e) {
 *
 * } try {
 *   // loding を非表示に
 *   $core.$loading.finish()
 * }
 * ```
 * ```ts
 * // 全画面で表示 (UI操作をブロック)
 * $core.$loading.start('XXXのロードをしています...', 'overlay')
 * ```
 * @coreDocPath $core/20.ui/205.loading
 */
export class Loading {
  state: boolean
  message: string
  type: string

  constructor() {
    this.state = false
    this.message = defaultMessage
    this.type = 'overlay'
  }

  start(message = defaultMessage, type: LoadingTypes = '') {
    this.state = true
    this.message = message
    this.type = type
  }

  finish() {
    this.state = false
    this.type = 'overlay'
  }

  // Alias of finish()
  get end() {
    return this.finish
  }
  // Alias of finish()
  get stop() {
    return this.finish
  }
}

type LoadingTypes = 'overlay' | ''

export const $loading = makeItReactive<Loading>(new Loading())
