<template>
  <b-input-group :prepend="prependText">
    <b-form-select
      :model-value="value"
      :options="selections"
      label-field="label"
      @update:model-value="changed"
      class="form-control-sm"
    />
  </b-input-group>
</template>
<script lang="ts">
import { PropType } from 'vue'
import { ModelFactory } from '../../common/$models'
import { ColumnDef } from '../../common/$models/ModelDef'

const generateAscDescSelections = (colName, label) => {
  return [
    {
      text: `"${label || colName}" 昇順`,
      value: `${colName}`,
    },
    {
      text: `"${label || colName}" 降順`,
      value: `-${colName}`,
    },
  ]
}

export default {
  name: 'ModelSearchSetSort',
  props: {
    model: {
      type: Object as PropType<ModelFactory>,
    },
    virtualModel: {
      type: Object as PropType<ModelFactory>,
    },
    filterableColumns: {
      type: Array as PropType<ColumnDef[]>,
    },
    currentValue: {
      type: String,
    },
    showAllColumns: {
      type: Boolean,
      default: false,
    },
    prependText: {
      type: String,
      default: '並び順',
    },
  },
  data() {
    return {
      value: this.currentValue || '',
      initialCurrentValue: this.currentValue,
    }
  },
  computed: {
    columns() {
      return this.virtualModel?.columns || this.model?.columns || {}
    },
    selections(): { value: string; label: string }[] {
      // TODO: 若干Dirty...
      const modelDefinedSortable = this.virtualModel?.sortableFields || this.model?.sortableFields
      if (modelDefinedSortable && modelDefinedSortable.length) {
        if (typeof modelDefinedSortable[0] === 'string') {
          return modelDefinedSortable.reduce((res, colName) => {
            return res.concat(
              generateAscDescSelections(colName, this.columns[colName]?.label || colName),
            )
          }, [])
        }
        return modelDefinedSortable
      }
      // Default behavior
      const selections: ColumnDef[] = this.filterableColumns || this.model.filterableColumns
      const sortSelections = selections.reduce((res, r) => {
        if (
          this.showAllColumns ||
          ['DATEONLY', 'DATETIME', 'NUMBER'].indexOf(r.type) >= 0 ||
          ['createdAt', 'updatedAt'].indexOf(r.name) >= 0 ||
          r.name === this.initialCurrentValue
        ) {
          return res.concat(generateAscDescSelections(r.name, r.label || r.name))
        }
        return res
      }, [])

      // 現在指定されている並び順
      const currentSorts = (typeof this.currentValue?.split === 'function'
        ? this.currentValue?.split(',')
        : []
      ).concat(
        typeof this.initialCurrentValue?.split === 'function'
          ? this.initialCurrentValue?.split(',')
          : [],
      )
      // model.defaultSort もしくは、this.currentValue が指定されているのに現在のselections にない場合
      const defaultSortColumnsString =
        this.model.defaultSort === 'string'
          ? this.model.defaultSort
          : this.model.defaultSort?.key || ''
      const defaultSortColumns = defaultSortColumnsString.split(',')
      // 現在の指定 および defaultSort の指定をマージ
      const defaultAndCurrentSortKeyColNames = currentSorts
        .concat(defaultSortColumns)
        .map(c => c.replace(/^-/, ''))
        .filter((v, i, a) => v && a.indexOf(v) === i && a.indexOf(',') === -1)
      if (defaultAndCurrentSortKeyColNames.length) {
        // sortSelections に含まれていないなら、先頭に追加する
        defaultAndCurrentSortKeyColNames.forEach(colName => {
          if (!sortSelections.find(s => s.value === colName)) {
            const colLabel = this.columns[colName]?.label || colName
            sortSelections.unshift(...generateAscDescSelections(colName, colLabel))
          }
        })
      }
      return sortSelections
    },
  },
  methods: {
    changed(selected) {
      this.$emit('update:model-value', selected)
    },
  },
}
</script>
