<template>
  <div>
    <multiselect
      v-bind="commonAttrs"
      :allow-empty="true"
      :show-labels="false"
      select-label="選択"
      placeholder="選択"
      :model-value="valueAlt"
      track-by="key"
      label="label"
      :internal-search="false"
      :options="multiSelectOptions"
      @open="() => searchChange()"
      @update:model-value="change"
      @search-change="searchChange"
    >
      <template v-slot:noOptions> ---</template>
      <template v-slot:noResult> ---</template>
    </multiselect>
    <slot/>
    <div v-if="shouldEnableAddNewLink" class="small">
      <a href="#" @click.prevent="() => openAddNewModal()">+ 新規追加</a>
    </div>
  </div>
</template>

<script lang="ts">
// type: 'REFERENCE' 用のInputForm.
import { ColumnDef, ColumnTypes } from '../../../common/$models/ModelDef'
import { ModelFactory } from '../../../common/$models'

/**
 * propsを定義
 * 動作としては、getter/setter pattern で、変換してv-modelに返す
 */

export default {
  props: {
    modelValue: { required: true },
    col: { required: true },
    record: { required: true },
    commonAttrs: { required: false },
    enableAddNewLink: { required: false, default: false },
  },
  data() {
    return {
      colInputSelection: [],
      multiSelectOptions: [],
      dataList: [],
      valueAlt: null,
      initialValue: '',
      initialized: false,
      // 最後に fetch したクエリを保持: 不必要な fetch の連続を回避するため
      lastFetchedQuery: null,
      findQueryBaseFilter: null,
    }
  },
  computed: {
    isReference(): boolean {
      return this.col.type === ColumnTypes.Reference
    },
    isRelationshipManyToOne(): boolean {
      return this.col.type === ColumnTypes.RelationshipManyToOne
    },
    ref(): ColumnDef['referenceOfStore'] | ColumnDef['relationshipManyToOne'] {
      return this.isReference ? this.col.referenceOfStore : this.col.relationshipManyToOne
    },
    referenceModelName(): string {
      return this.isReference ? this.ref.storeName : this.ref.collectionName
    },
    referenceVirtualModelName(): string {
      return this.ref?.virtualModelName || null
    },
    referenceModel(): ModelFactory {
      return $core.$models[this.referenceModelName]
    },
    referenceVirtualModel(): ModelFactory {
      return this.referenceVirtualModelName ? $core.$virtualModels[this.referenceVirtualModelName] : null
    },
    emptyLabel(): string {
      return this.ref?.emptyLabel
    },
    emptyValue(): string {
      return this.isReference ? '' : ''
    },
    shouldEnableAddNewLink(): boolean {
      // ユーザ権限による判別: Model定義上, creatable でない場合は、新規追加リンクを表示しない
      if (this.referenceModel?.creatable === false) {
        return false
      }
      return (this.enableAddNewLink || this.commonAttrs?.enableAddNewLink || this.ref?.enableAddNewLink) && this.isRelationshipManyToOne
    },
    shouldDynamicallyLoad(): boolean {
      return typeof this.ref.filterByAttrs === 'function'
    },
    idValue() {
      return this.modelValue?.id || this.modelValue
    },
    findQueryFilter() {
      if (this.findQueryBaseFilter) {
        if (this.idValue) {
          return {
            _or: [
              this.findQueryBaseFilter,
              { id: { _eq: this.idValue } },
            ],
          }
        }
        return this.findQueryBaseFilter
      }
      return {} // 全件fetchになる
    },
    findQueryFields() {
      return this.ref.findQueryFields || ['*.*']
    },
    findQuerySort() {
      return this.ref.findQuerySort || this.referenceVirtualModel?.defaultSort || this.referenceModel?.defaultSort || [`-${this.referenceModel.primaryKeyColName}`]
    },
    findParams() {
      return {
        limit: -1,
        filter: this.findQueryFilter,
        sort: this.findQuerySort,
        fields: this.findQueryFields,
      }
    },
  },
  watch: {
    modelValue(newValue) {
      // 更新なかったら何もしない
      if (newValue === this.valueAlt?.key) return
      const event = this.colInputSelection.find(r => r.key === newValue)
      this.change(event || { key: this.emptyValue, value: this.emptyValue })
    },
  },
  created() {
    this.initialValue = this.modelValue?.id || this.modelValue
    this.initSelections().then(() => {
      this.initialized = true
    })
  },
  methods: {
    async filteredColInputSelection() {
      if (this.ref.filterSelectionsByRowState) {
        return this.ref.filterSelectionsByRowState({
          selections: this.colInputSelection,
          row: this.record,
          dataList: this.dataList,
        })
      } else {
        return this.colInputSelection
      }
    },
    async searchChange(query = null) {
      // ここで 再 fetch する (必要であれば)
      await this.reInitSelectionsIfNeeded()
      let list = await this.filteredColInputSelection()
      if (query) {
        query = query.replaceAll('　', ' ').split(' ')
        for (const searchText of query) {
          const tarText = searchText.toLowerCase()
          list = list.filter(v => `${v.label}`.toLowerCase().includes(tarText))
        }
      }
      this.multiSelectOptions = list
    },
    change(event) {
      this.$nextTick(async () => {
        this.$emit('update:modelValue', { value: event?.key || this.emptyValue })
        this.valueAlt = event
      })
    },
    async setFindQueryBaseFilter() {
      const filterByAttrs = typeof this.ref.filterByAttrs === 'function'
        ? (await this.ref.filterByAttrs(this.record, this.$parent?.recordRoot, this))
        : this.ref.filterByAttrs || null
      const andConds = [
        (filterByAttrs && Object.keys(filterByAttrs).length > 0) ? filterByAttrs : null,
        this.referenceVirtualModel?.dataFilters || this.referenceModel?.dataFilters || null,
      ].filter(Boolean)
      this.findQueryBaseFilter = andConds.length > 0 ? { _and: andConds } : null
    },
    /**
     * クエリに変更がある場合にのみ 再度Fetchする
     */
    async reInitSelectionsIfNeeded() {
      if (!this.shouldDynamicallyLoad) {
        return // 何もしない
      }
      // クエリ内容に変更があるかどうかの評価
      await this.setFindQueryBaseFilter()
      const hasQueryChanged = JSON.stringify(this.findParams) !== this.lastFetchedQuery
      if (hasQueryChanged) {
        this.colInputSelection = []
        this.multiSelectOptions = []
        this.dataList = []
        await this.initSelections(true)
      }
    },
    /**
     * 初期化時に、選択肢を構成する
     * this.ref.filterByAttrs: 定義していれば絞り込み条件となる
     * @returns {Promise<void>}
     */
    async initSelections(skipSetFindQueryBaseFilter = false) {
      if (!skipSetFindQueryBaseFilter) {
        await this.setFindQueryBaseFilter()
      }
      const dataList = await this.referenceModel.find(this.findParams)
      this.lastFetchedQuery = JSON.stringify(this.findParams)
      this.dataList = dataList

      let valueAlt = null
      const undefinedList = []
      if (this.initialValue) {
        await this.generateLabelForInitialValue()
      }
      let list = (dataList || []).reduce((res, r) => {
        const key = this.isReference ? this.ref.key : 'id'
        if (!r[key]) {
          undefinedList.push(r)
          return res
        }
        const re = {
          key: r[key],
          label: this._genLabel(r),
        }
        res.push(re)
        if (re.key == this.initialValue) {
          valueAlt = re
          this.valueAlt = re
        }
        return res
      }, [])
      if (this.col.additionalSelectOptions) {
        list = [
          ...(this.col.additionalSelectOptions.prepend || []).map(v => ({
            key: v,
            label: v,
          })),
          ...list,
          ...(this.col.additionalSelectOptions.append || []).map(v => ({
            key: v,
            label: v,
          })),
        ]
      }
      const emptyValue = '' // this.isReference ? '' : null
      const emptyOptionItem = { key: emptyValue, label: this.emptyLabel || '=== 未選択 ===' }
      if (this.ref.allowEmptySelection) {
        list = [emptyOptionItem, ...list]
        if (this.initialValue === emptyValue || [undefined, null].includes(this.initialValue)) {
          valueAlt = emptyOptionItem
        }
      }
      if (
        this.col.strictSelections === false &&
        list.filter(l => l.key === this.initialValue).length === 0
      ) {
        list.push({ key: this.initialValue, label: this.initialValue })
        valueAlt = { key: this.initialValue, label: this.initialValue }
      }
      this.colInputSelection = list
      this.multiSelectOptions = this.colInputSelection
      if (valueAlt && valueAlt.label) {
        this.$nextTick(() => {
          this.valueAlt = valueAlt
          this.$emit('update:modelValue', { value: this.valueAlt.key })
        })
      }
    },
    _genLabel(r) {
      if (!r) {
        return ''
      }
      if (typeof this.ref.labelFormatter === 'function') {
        return this.ref.labelFormatter(r)
      } else if (this.ref.labelFormatter && typeof this.ref.labelFormatter === 'string') {
        const func = new Function(`{ return function (row) { ${this.ref.labelFormatter} } };`)
        return func.call(null).call(null, r)
      } else if (this.isReference) {
        return r ? (r[this.ref.label] ? r[this.ref.label] : r[this.ref.key]) : ''
      } else {
        return r.id
      }
    },
    async generateLabelForInitialValue() {
      const key = this.isReference ? this.ref.key : 'id'
      const row = this.isReference
        ? await this.referenceModel.findOne({
          filter: { [this.ref.key]: { _eq: this.initialValue } },
        })
        : this.initialValue
      this.valueAlt = {
        key: this.initialValue[key],
        label: this._genLabel(row),
      }
    },
    async openAddNewModal() {
      $core.$modals.openCreateViewModal({
        modelName: this.referenceModelName,
        virtualModelName: this.referenceVirtualModelName,
        defaultValues: {
          [this.ref.fieldName]: this.record[this.ref.fieldName],
        },
        successCallback: async (data) => {
          await this.initSelections()
          this.$nextTick(() => {
            this.valueAlt = {
              key: data[this.referenceModel.primaryKeyColName],
              label: this._genLabel(data),
            }
            this.$emit('update:modelValue', { value: this.valueAlt.key })
          })
        },
      })
    },
  },
}
</script>
