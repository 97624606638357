import { $directusSdk } from '../$directusSdk'
import {
  BulkDeleteParam,
  BulkUpsertParam,
  DeleteDocParam,
  UpdateDocParam,
  StoreMethodModelNameOption,
} from './storeMethodTypes'
import { TransportResponse } from '@directus/sdk'
import { singletonInstanceSummoner } from '../singletonInstanceSummoner'
import { cleanupDataForSave } from './cleanupDataForSave'
import { $appHook } from '../$appHook'
import { $models } from '../$models'
import { $uiState } from '../$uiState'
import { TransformDataBeforeSave } from './TransformDataBeforeSave'

const _logMessage = (message, level: 'success' | 'error' = 'error') => {
  $core.$toast[level === 'success' ? 'successToast' : 'errorToast'](message)
  console.log(message)
}

const _logMessageWithCloudResponse = (res: TransportResponse<any, any>) => {
  _logMessage(
    res.errors ? JSON.stringify(res.errors) : res.data?.message,
    res.errors ? 'error' : 'success',
  )
}

export type StoreMethodMethodName = 'bulkUpsert' | 'bulkDelete'

export type StoreMethodAPIPostedData =
  | (BulkUpsertParam & { methodName?: 'bulkUpsert' })
  | (BulkDeleteParam & { methodName?: 'bulkDelete' })

const _storeMethodsApiRequester = async (
  methodName: StoreMethodMethodName,
  allPostData: StoreMethodAPIPostedData,
) => {
  allPostData.methodName = methodName
  allPostData.collectionName = allPostData.collectionName || allPostData.modelName
  return $directusSdk.transport.post('/storeMethods', allPostData)
}

/**
 * Model定義の beforeSave 関数, appHook `${modelName}.beforeSave` を実行する
 * @param modelName モデル名
 * @param data 保存するデータ
 * @param virtualModelName Virtualモデル名
 * @returns Promise<any[]>
 */
export const executeBeforeSaveByModelName = async (
  modelName: string,
  data: any[],
  virtualModelName: string = null,
): Promise<any[]> => {
  const res = []
  const virtualModel = $core.$virtualModels[virtualModelName]
  const model = $models[modelName] || $models[virtualModel?.baseModel]
  for (let i = 0; i < data.length; i++) {
    let d = data[i]
    if (typeof virtualModel?.beforeSave === 'function') {
      d = await virtualModel.beforeSave(d, i, data)
    }
    if (typeof model?.beforeSave === 'function') {
      d = await model.beforeSave(d, i, data)
    }
    d = cleanupDataForSave(d, model)
    d = new TransformDataBeforeSave(d, model).run()
    // beforeSaveHook 実施
    const beforeSaveHookName = `${modelName}.beforeSave`
    const hasBeforeSaveCallback = $appHook.hasHook(beforeSaveHookName)
    if (hasBeforeSaveCallback) {
      const hookedRes = await $appHook.emit(beforeSaveHookName, {
        data: d,
        index: i,
        allSaving: data,
      })
      if (hookedRes.data) {
        d = hookedRes.data
      }
    }
    res.push(d)
  }
  return res
}

/**
 * Model定義の afterSave 関数, appHook `${modelName}.afterSave` を実行する
 * @param modelName モデル名
 * @param data 保存したデータ
 * @param virtualModelName Virtualモデル名
 */
export const executeAfterSaveByModelName = async (modelName: string, data: any[], virtualModelName: string) => {
  const virtualModel = $core.$virtualModels[virtualModelName]
  const model = $models[modelName] || $models[virtualModel?.baseModel]
  for (let i = 0; i < data.length; i++) {
    const d = data[i]
    if (virtualModel?.afterSave) {
      virtualModel.afterSave(d, i, data)
    }
    if (model?.afterSave) {
      model.afterSave(d, i, data)
    }
    const afterSaveHookName = `${modelName}.afterSave`
    const hasAfterSaveCallback = $appHook.hasHook(afterSaveHookName)
    if (hasAfterSaveCallback) {
      $appHook.emit(afterSaveHookName, { data: d, index: i, allSaved: data })
    }
  }
}

/**
 * Model定義の beforeDelete 関数, appHook `${modelName}.beforeDelete` を実行する
 * @param modelName モデル名
 * @param deleteIds 削除するデータのIDの配列
 * @returns Promise<(string | number)[]>
 */
export const executeBeforeDeleteByModelName = async (modelName, deleteIds: (string | number)[]): Promise<(string | number)[]> => {
  for (let i = 0; i < deleteIds.length; i++) {
    const id = deleteIds[i]
    if ($models[modelName]?.beforeDelete) {
      await $models[modelName]?.beforeDelete(id, i, deleteIds)
    }
    const beforeDeleteHookName = `${modelName}.beforeDelete`
    const hasBeforeDeleteCallback = $appHook.hasHook(beforeDeleteHookName)
    if (hasBeforeDeleteCallback) {
      await $appHook.emit(beforeDeleteHookName, {
        deletingId: id,
        index: i,
        allDeletingIds: deleteIds,
      })
    }
  }
  return deleteIds
}

/**
 * Model定義の afterDelete 関数, appHook `${modelName}.afterDelete` を実行する
 * @param modelName モデル名
 * @param deleteIds 削除したデータのIDの配列
 */
export const executeAfterDeleteByModelName = async (modelName, deleteIds: (string | number)[]) => {
  for (let i = 0; i < deleteIds.length; i++) {
    const id = deleteIds[i]
    if ($models[modelName]?.afterDelete) {
      await $models[modelName]?.afterDelete(id, i, deleteIds)
    }
    const afterDeleteHookName = `${modelName}.afterDelete`
    const hasAfterDeleteCallback = $appHook.hasHook(afterDeleteHookName)
    if (hasAfterDeleteCallback) {
      await $appHook.emit(afterDeleteHookName, {
        deletedId: id,
        index: i,
        allDeletedIds: deleteIds,
      })
    }
  }
}

/**
 * # $core.$storeMethods
 * - フロントエンドからデータの保存・削除を実行するためのサービス
 * - 保存・削除処理の前後に、Model定義で定義された関数や、AppHookで登録された関数が実行される
 * - 処理結果に応じてToastメッセージを表示する
 *
 * ## メソッド
 * - `upsert(args: UpdateDocParam)`: 1件のデータを更新する
 * - `bulkUpsert(args: BulkUpsertParam)`: 複数件のデータを更新する
 * - `delete(args: DeleteDocParam)`: 1件のデータを削除する
 * - `bulkDelete(args: BulkDeleteParam)`: 複数件のデータを削除する
 *
 * ## 使用例
 * ### データの保存
 * ```ts
 * // products モデルのレコードを保存
 * await $core.$storeMethods.upsert({
 *   // 保存対象のモデル名
 *   modelName: 'products',
 *   // 保存するデータ
 *   data: {
 *     name: 'Sample Product',
 *     price: 100
 *   }
 * })
 *
 * // products モデルの複数レコードを保存
 * await $core.$storeMethods.bulkUpsert({
 *   // 保存対象のモデル名
 *   modelName: 'products',
 *   // 保存するデータの配列
 *   data: [
 *     { name: 'Sample Product 1', price: 100 },
 *     { name: 'Sample Product 2', price: 200 },
 *   ]
 * })
 * ```
 * ### データの削除
 * ```ts
 * // products モデルのレコードを削除
 * await $core.$storeMethods.delete({
 *   // 削除対象のモデル名
 *   modelName: 'products',
 *   // 削除するデータのID
 *   id: 1
 * })
 *
 * // products モデルの複数レコードを削除
 * await $core.$storeMethods.bulkDelete({
 *   // 削除対象のモデル名
 *   modelName: 'products',
 *   // 削除するデータのIDの配列
 *   docIds: [1, 2, 3]
 * })
 * ```
 * ### 保存処理の前後に処理を追加
 * ```ts
 * // Model定義にbeforeSave関数を定義する
 * const products: ModelDef = {
 *   tableName: 'products',
 *   tableLabel: '商品',
 *   // ...
 *   async beforeSave(saving, index, allSaving) {
 *     // 保存前にupdatedAtを更新する
 *     saving.updatedAt = new Date()
 *     return saving
 *   }
 * }
 *
 * // AppHookでbeforeSave関数を登録する
 * $core.$appHook.on('products.beforeSave', async (data) => {
 *   // 保存前にupdatedAtを更新する
 *   data.updatedAt = new Date()
 *   return data
 * })
 * ```
 */
class StoreMethods {
  /**
   * 1件のデータを更新する
   * @param arg 更新するデータ
   */
  async upsert(arg: UpdateDocParam) {
    return this.bulkUpsert({
      ...arg,
      data: [arg.data],
    })
  }

  /**
   * @deprecated Use .upsert()
   * @param arg
   */
  get updateDoc() {
    return this.upsert
  }

  /**
   * model名を取得する
   * @param args モデル名を取得するための引数
   * @returns モデル名
   */
  extractModelName(args: StoreMethodModelNameOption): string {
    return (
      args.collectionName ||
      args.modelName ||
      $core?.$virtualModels[args.virtualModelName]?.baseModel
    )
  }

  /**
   * 複数件のデータを更新する
   * @param args 更新するデータ
   */
  async bulkUpsert(args: BulkUpsertParam) {
    // 更新するデータがない場合は何もしない
    if (args.data.length === 0) {
      return
    }

    const modelName: string = this.extractModelName(args)

    /**
     * 保存処理実行前に Validation, データ transform, 保存処理のキャンセルなどを実施する
     * - appHook による 戻り値 が falsy であれば 保存処理を中止する
     * @event ${modelName}.storeMethodBulkUpsertBefore
     * @example
     * ```ts
     * $core.$appHook.on('products.storeMethodBulkUpsertBefore', async (args) => {
     *   // 保存前に何らかの処理を行う
     *   args.data.forEach((d) => {
     *     d.updatedAt = new Date()
     *   })
     *   return args
     * })
     * ```
     */
    if ($appHook.hasHook(`${modelName}.storeMethodBulkUpsertBefore`)) {
      args = await $appHook.emit(`${modelName}.storeMethodBulkUpsertBefore`, args)
      // appHookの戻り値がfalsyの場合は何もしない
      if (!args) {
        return
      }
    }

    // Model定義で上書き関数が定義されている場合は実行する
    if ($models[modelName]?.overrides?.bulkUpsert) {
      return $models[modelName].overrides.bulkUpsert(args)
    }

    // 排他制御（複数レコードを一括更新する場合はいずれかのレコードで先に更新されていたら排他チェックがかかる）
    if (
      $models[modelName].enableCheckDuplicateUpdateOnBeforeSave &&
      $models[modelName].enableCheckDuplicateUpdateOnBeforeSave === true
    ) {
      // primaryKey が存在する場合のみ対象とする（新規作成は対象外）
      const primaryKeyColName = $core.$models[modelName].primaryKeyColName
      const concurrencyData = args.data.filter((d) => !!d[primaryKeyColName]).map((d) => {
        return {
          [primaryKeyColName]: d[primaryKeyColName],
          updatedAt: d.updatedAt,
        }
      })
      if (concurrencyData.length > 0) {
        let concurrencyFlag = false
        const concurrencyDataIds = concurrencyData.map((d) => d[primaryKeyColName])
        const currentRecords = await $core.$models[modelName].findByIds(concurrencyDataIds)
        const currentRecordsById = currentRecords.reduce((acc, cur) => {
          acc[cur[primaryKeyColName]] = cur
          return acc
        }, {})
        concurrencyData.map(async (d) => {
          const current = currentRecordsById[d[primaryKeyColName]]
          if (current?.updatedAt && current.updatedAt !== d.updatedAt) {
            // 他の方によって既に更新されている場合
            concurrencyFlag = true
          }
        })
        if (concurrencyFlag) {
          throw new Error('他のユーザーがデータを更新しているため保存できません。画面を更新してもう一度データを編集してください。')
        }
      }
    }

    // beforeSave関数を実行する
    if (args.disableBeforeSaveFunction !== true) {
      args.data = await executeBeforeSaveByModelName(modelName, args.data, args.virtualModelName)
    }
    // virtualColumnがある場合はデータを圧縮する
    const convertFunc = (data) => {
      if ($models[modelName]?.hasVirtualColumn && $models[modelName].compressDataWithVColumns) {
        data = $models[modelName].compressDataWithVColumns(data)
      }
      if (
        $core?.$virtualModels[args.virtualModelName]?.hasVirtualColumn &&
        $core.$virtualModels[args.virtualModelName].compressDataWithVColumns
      ) {
        data = $core.$virtualModels[args.virtualModelName].compressDataWithVColumns(data)
      }
      return data
    }
    args.data = args.data.map((d) => convertFunc(d))
    // APIリクエストを実行する
    const res = await _storeMethodsApiRequester('bulkUpsert', args)
    // quietオプションがfalse、またはエラーが発生した場合はメッセージを表示する
    if (args.quiet !== true || res.errors) {
      _logMessageWithCloudResponse({
        ...res,
        data: {
          message: '保存しました',
        },
      })
    }
    // afterSave関数を実行する
    if (args.disableAfterSaveFunction !== true) {
      await executeAfterSaveByModelName(modelName, res.data, args.virtualModelName)
    }
    // ユーザーの最新の保存アクションの時間を更新する
    if (args.disableUserLatestStoreMethodActionTime !== true) {
      $uiState.userLatestStoreMethodActionModelName = modelName
      $uiState.userLatestStoreMethodActionTime = new Date().getTime()
    }
    return res
  }

  /**
   * @deprecated Use .upsert()
   * @param arg 削除するデータ
   */
  get deleteDoc() {
    return this.delete
  }

  /**
   * 1件のデータを削除する
   * @param args 削除するデータ
   */
  async delete(args: DeleteDocParam) {
    return this.bulkDelete({
      ...args,
      docIds: [args.id || args.docId],
    })
  }

  /**
   * 複数件のデータを削除する
   * @param args 削除するデータ
   * @returns Promise<(string | number)[] | false>
   * - 削除に成功した場合は、削除したデータのIDの配列を返す
   * - 削除に失敗した場合は、`false`を返す
   */
  async bulkDelete({
    collectionName,
    modelName,
    virtualModelName,
    docIds,
    beforeDelete = null,
    afterDelete = null,
    skipConfirm = false,
    quiet = false,
    disableUserLatestStoreMethodActionTime = false,
    disableBeforeDeleteFunction = false,
    disableAfterDeleteFunction = false,
  }: BulkDeleteParam) {
    modelName = this.extractModelName({ collectionName, modelName, virtualModelName })
    const $model = $models[modelName]

    // Model定義で上書き関数が定義されている場合は実行する
    if ($model?.overrides?.bulkDelete) {
      return $model.overrides.bulkDelete({
        collectionName,
        modelName,
        virtualModelName,
        docIds,
        beforeDelete,
        afterDelete,
        skipConfirm,
        quiet,
      })
    }

    // 削除するデータがない場合、または確認ダイアログでキャンセルされた場合は何もしない
    if (
      docIds.length === 0 ||
      (!skipConfirm &&
        quiet !== true &&
        !window.confirm(
          `${$model?.tableLabel || modelName} ${docIds.length} 件を削除します。よろしいですか？`,
        ))
    ) {
      return false
    }

    // beforeDelete関数を実行する
    if (disableBeforeDeleteFunction !== true) {
      docIds = await executeBeforeDeleteByModelName(modelName, docIds)
    }

    // APIリクエストを実行する
    const res = await _storeMethodsApiRequester('bulkDelete', {
      modelName,
      docIds,
      beforeDelete,
      afterDelete,
      skipConfirm,
    })

    // quietオプションがfalseの場合はメッセージを表示する
    if (quiet !== true) {
      _logMessageWithCloudResponse({
        ...res,
        data: {
          message: '削除しました',
        },
      })
    }

    // afterDelete関数を実行する
    if (disableAfterDeleteFunction !== true) {
      await executeAfterDeleteByModelName(modelName, docIds)
    }

    // ユーザーの最新の削除アクションの時間を更新する
    if (disableUserLatestStoreMethodActionTime !== true) {
      $uiState.userLatestStoreMethodActionModelName = modelName
      $uiState.userLatestStoreMethodActionTime = new Date().getTime()
    }
    return docIds
  }

  /**
   * StoreMethodsクラスのインスタンスを取得する
   * @returns StoreMethods
   * @private
   */
  static get instance(): StoreMethods {
    return singletonInstanceSummoner('StoreMethods', StoreMethods)
  }
}

/**
 * StoreMethodsクラスのインスタンス
 */
export const storeMethods = StoreMethods.instance
