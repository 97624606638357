<template>
  <component :is="tag" v-b-tooltip.lefttop="'管理者のみ表示'" class="core-admin-role-only">
    <slot />
  </component>
</template>
<script lang="ts">
export default {
  props: {
    tag: {
      type: String,
      default: 'span',
    },
  },
}
</script>
