import { fetchSelectionsWithColDef } from '../../../front/ModelForm'
import { ColumnDef } from '../../../common/$models/ModelDef'

export class SelectionService {
  modelName: string
  colDef: ColumnDef
  record: any
  v: any
  initialValue: any
  recordRoot: any
  vueInstance: any
  selectionLabels: any
  addedSelections: any[]
  isNewRecord: boolean

  constructor({
    modelName,
    colDef,
    record,
    v,
    initialValue,
    recordRoot,
    vueInstance,
    isNewRecord,
  }: any) {
    this.modelName = modelName
    this.colDef = colDef
    this.record = record
    this.v = v
    this.initialValue = initialValue
    this.recordRoot = recordRoot
    this.vueInstance = vueInstance
    this.isNewRecord = isNewRecord
  }

  async _initColInputSelection(autoSelectIfSelectionIsOnlyOne = false) {
    let list = await fetchSelectionsWithColDef({
      modelName: this.modelName,
      colDef: this.colDef,
      record: this.record,
      value: this.v,
      initialValue: this.initialValue,
      recordRoot: this.recordRoot,
      callerVueInstance: this.vueInstance,
    })
    // list構造が {value, label}[] であれば、、、
    const isLabeledList =
      Object.keys(list[0] || {}).length === 2 && list[0].value !== undefined && list[0].label
    if (isLabeledList) {
      const valueList = []
      const labelMap = list.reduce((res, r) => {
        res[r.value] = r.label
        valueList.push(r.value)
        return res
      }, {})
      this.selectionLabels = labelMap
      list = valueList
    }
    if (this.addedSelections) {
      list = list.concat(this.addedSelections)
    }
    // 選択肢が1つだけの場合、自動選択する if autoSelectIfSelectionIsOnlyOne === true
    if (
      autoSelectIfSelectionIsOnlyOne &&
      this.isNewRecord &&
      list.length === 1 &&
      !this.v &&
      list[0]
    ) {
      this.v = list[0]
    }
    list = list.filter((v) => v !== undefined)
    return list
  }
}
