<template>
  <div
    class="devonly p-2 mb-2"
    style="background-color: rgba(255, 0, 0, 0.06)"
    v-if="ComposableDataListServiceInstance.modelName"
    :key="ComposableDataListServiceInstance.modelName || 'none'"
  >
    <h6 class="text-danger">※ 廃止予定！</h6>
    <ModelDataFilterPanel
      :query="ComposableDataListServiceInstance.query"
      :model-name="ComposableDataListServiceInstance.modelName"
      @search="(qu) => (ComposableDataListServiceInstance.query = qu)"
    />
  </div>
</template>
<script lang="ts">
import { inject } from 'vue'
import { ComposableDataListService } from '../ComposableDataListService'

export default {
  setup() {
    return {
      ComposableDataListServiceInstance: inject<ComposableDataListService>(
        'ComposableDataListServiceInstance',
      ),
    }
  },
}
</script>
