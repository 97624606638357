<template>
  <a
    href="#"
    class="display-record-revisions-list-link"
    v-single-click="() => $core.$modals.openListViewModal({
        modelName: 'directus_revisions',
        filters: {
          collection: modelName,
          item: record.id,
        },
        otherComponentProps: {
          resolveHookNamePrefix: 'oneRecordRevisionsDiffList.',
        },
      })
    "
    >変更履歴を表示
    <ficon type="history" />
  </a>
</template>
<script lang="ts">
export default {
  props: {
    modelName: { required: true },
    record: { required: true },
  },
}
</script>
