<template>
  <div>
    <p class="small">ログインパスワードの再設定を行います。</p>
    <input
      v-model="email"
      class="form-control mx-auto"
      placeholder="you@yourcompany.com"
      style="max-width: 280px;"
    />
    <div class="text-center mt-2">
      <b-button variant="primary" v-single-click="requestPasswordReset"
        >パスワード再設定用メールを送信
      </b-button>
    </div>
  </div>
</template>
<script lang="ts">
export default {
  props: { emailValue: {} },
  data() {
    return {
      email: '',
    }
  },
  created() {
    if (this.emailValue) {
      this.email = this.emailValue
    }
  },
  methods: {
    async requestPasswordReset() {
      if (!this.email) {
        return
      }
      $core.$loading.start('Requesting password reset...')
      try {
        await $core.$d.transport.post('/users/requestPasswordReset', { email: this.email })
        $core.$toast.successToast(
          'パスワードリセットのリクエストを受け付けました。ご登録済である場合には、パスワードリセット用のリンクをお送りいたしましたので、メールボックスをご確認下さい。',
        )
        this.$emit('closeModal')
      } catch (e) {
        // TODO: このエラーメッセージが出ること自体が少し問題だが、、、わかりやすさでもある。
        $core.$toast.errorToast(
          `パスワードリセットを受け付けられませんでした。メールアドレスがユーザ登録されていることをご確認ください。`,
        )
        $core.$errorReporter.report(e, this)
      } finally {
        $core.$loading.finish()
      }
    },
  },
}
</script>
