<template>
  <b-form-input
    v-bind="commonAttrs"
    :model-value="modelValue !== undefined ? modelValue : 0"
    type="number"
    :number="true"
    :lazy="true"
    :lazy-formatter="true"
    @update:modelValue="(val) => $emit('update:modelValue', val)"
  />
</template>
<script lang="ts">
import { PropType } from 'vue'

export default {
  props: {
    modelValue: {
      type: Number,
      required: true,
    },
    commonAttrs: {
      type: Object as PropType<Record<string, unknown>>,
      required: false,
    },
  },
}
</script>
