import { App } from 'vue'
import FilterItemBoolean from './components/FilterControls/FilterItemComponents/FilterItemBoolean.vue'
import FilterItemDate from './components/FilterControls/FilterItemComponents/FilterItemDate.vue'
import FilterItemNumber from './components/FilterControls/FilterItemComponents/FilterItemNumber.vue'
import FilterItemString from './components/FilterControls/FilterItemComponents/FilterItemString.vue'
import FilterItemSelect from './components/FilterControls/FilterItemComponents/FilterItemSelect.vue'
import FilterItemBooleanForCustom from './components/FilterControls/FilterItemComponents/FilterItemBooleanForCustom.vue'
import FilterItemDateForCustom from './components/FilterControls/FilterItemComponents/FilterItemDateForCustom.vue'
import FilterItemNumberForCustom from './components/FilterControls/FilterItemComponents/FilterItemNumberForCustom.vue'
import FilterItemStringForCustom from './components/FilterControls/FilterItemComponents/FilterItemStringForCustom.vue'
import FilterItemSelectForCustom from './components/FilterControls/FilterItemComponents/FilterItemSelectForCustom.vue'

import FilterResultDisplayContainer from './components/FilterResultDisplayContainer.vue'
import ModernFilterRule from './components/FilterControls/ModernFilterComponents/ModernFilterRule.vue'
import ModernFilterWrapper from './components/FilterControls/ModernFilterComponents/ModernFilterWrapper.vue'

export const registerFilterItemComponents = (Vue: App) => {
  Vue.component('FilterItemBoolean', FilterItemBoolean)
  Vue.component('FilterItemDate', FilterItemDate)
  Vue.component('FilterItemNumber', FilterItemNumber)
  Vue.component('FilterItemString', FilterItemString)
  Vue.component('FilterItemSelect', FilterItemSelect)
  Vue.component('FilterItemBooleanForCustom', FilterItemBooleanForCustom)
  Vue.component('FilterItemDateForCustom', FilterItemDateForCustom)
  Vue.component('FilterItemNumberForCustom', FilterItemNumberForCustom)
  Vue.component('FilterItemStringForCustom', FilterItemStringForCustom)
  Vue.component('FilterItemSelectForCustom', FilterItemSelectForCustom)
  Vue.component('ModernFilterRule', ModernFilterRule)
  Vue.component('ModernFilterWrapper', ModernFilterWrapper)
  Vue.component('FilterResultDisplayContainer', FilterResultDisplayContainer)
}
