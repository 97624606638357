import {
  ColumnDef,
  ColumnDefByColName, ColumnTypes,
  sortableFieldObject,
} from '../../../common/$models/ModelDef'
import { VirtualModelFactory } from '../../../common/$virtualModels'
import { FindFilter, FindQuery, ModelFactory } from '../../../common/$models'
import { ComposableDataListService } from './ComposableDataListService'
import { extractFilterablesFromColumns } from '../../ModelServices/modelFactoryUtils'

const generateAscDescSelections = (colName: string, label: string): sortableFieldObject[] => {
  return [
    {
      text: `"${label || colName}" 昇順`,
      value: `${colName}`,
    },
    {
      text: `"${label || colName}" 降順`,
      value: `-${colName}`,
    },
  ]
}

const extractSortableFieldsFromColumns = (
  columns: ColumnDefByColName,
): sortableFieldObject[] | null => {
  if (!columns) {
    return null
  }
  return Object.keys(columns).reduce((res, colName) => {
    if (columns[colName].visible !== false) {
      return [...res, ...generateAscDescSelections(colName, columns[colName]?.label || colName)]
    }
    return res
  }, [])
}

const getSortableFieldsByModel = ({
  virtualModel,
  model,
}: {
  virtualModel?: VirtualModelFactory
  model: ModelFactory
}): sortableFieldObject[] | null => {
  const modelDefinedSortable = virtualModel?.sortableFields || model?.sortableFields
  // 1. modelDefinedSortable があるなら
  if (modelDefinedSortable?.length) {
    return modelDefinedSortable.reduce((res, colNameOrSortDef) => {
      if (typeof colNameOrSortDef === 'string') {
        return [
          ...res,
          ...generateAscDescSelections(
            colNameOrSortDef,
            virtualModel?.columns[colNameOrSortDef]?.label ||
              model.columns[colNameOrSortDef]?.label ||
              colNameOrSortDef,
          ),
        ]
      }
      return [...res, colNameOrSortDef]
    }, [])
  }
  // 2. 無いなら、Columns から生成
  const byColumns = extractSortableFieldsFromColumns(
    virtualModel?.columnsMergedWithBaseModel || model.columns,
  )
  if (byColumns) {
    return byColumns
  }

  return null
}

const defaultLimit = 100


/**
 * ComposableDataListService に関連
 * DataList の 検索条件を管理するクラス
 *
 * ## クエリ管理の考え方
 * - データ一覧の絞り込み条件は、複数の (それぞれ別の役割を持つ) コンポーネント や スクリプト から (外側から) 適用される
 * - そのため、各コンポーネントやスクリプトは、自身で 検索条件におけるkeyを指定して、他と重複しない形で、検索条件を追加・更新・削除を実施する
 * - 各所から追加された検索条件は、 AND 条件で結合され、最終的な検索条件として適用される
 * - 検索実行がなされるのは 親Class である ComposableDataListService.find() が呼ばれた時である。
 *   - ComposableDataListService 側で 本 Class `DataListQuery` の instance を deep watch しているため、通常は applyFilter() が呼ばれた時に、自動的に 連鎖的に find() が呼ばれる
 *
 * ### 検索条件の追加の例
 * ```ts
 * // ComposableDataList を inject しているコンポーネント内で、以下のように記述する
 *
 * export default defineComponent({
 *   mounted() {
 *     // 例1: 初期化時に、固定的な検索条件を追加する
 *     this.ComposableDataListInstance.query.applyFilter('someFixedFilterCondition', { name: { _eq: 'some' } })
 *   },
 *   methods: {
 *     // 例2: 検索ボックスで入力されたキーワードを、検索条件に追加する
 *     onSearchKeywordChanged(keyword: string) {
 *       this.ComposableDataListInstance.query.applyFilter('keywordFilterCondition', { name: { _contain: keyword } })
 *     }
 *   }
 * })
 * ```
 *
 */
export class DataListQuery {
  public sort: string[] = []
  public search: string = ''
  public meta: Record<string, any> = {}
  /**
   * 検索条件の保持を実施する main property
   * @private
   */
  private _filtersByKey: Record<string, FindFilter> = {}
  public page = 1
  private _limit = defaultLimit
  public fields: string[] = null
  private readonly $dls: ComposableDataListService

  constructor($dls: ComposableDataListService) {
    // Set as non-enumerable prop
    Object.defineProperty(this, '$dls', {
      enumerable: false,
      get(): any {
        return $dls
      },
    })
  }

  /**
   * 検索条件を (部分的に) 追加したり、上書きしたりする
   */
  public applyFilter(filterName: string, filter: FindFilter | {}, deepMerge = false): void {
    if (!filterName || typeof filterName !== 'string') {
      console.error(`[DataListQuery.applyFilter] "filterName" must be string, given filterName:`, filterName)
      return
    }
    this._filtersByKey[filterName] = deepMerge ? $core.$utils.deepmerge(this._filtersByKey[filterName] || {}, filter) : filter
  }

  public get filtersByKey() {
    return this._filtersByKey // 参照可能
  }

  public get limit(): number {
    return this._limit || defaultLimit
  }
  public set limit(limit: number) {
    this._limit = limit
  }

  public get sortableFields(): sortableFieldObject[] {
    return getSortableFieldsByModel({
      virtualModel: this.$dls.virtualModel,
      model: this.$dls.model,
    })
  }

  public get filterableColumns(): ColumnDefByColName {
    return extractFilterablesFromColumns(this.$dls.columns)
  }

  public get filterableColumnsAsArray(): ColumnDef[] {
    return Object.values(this.filterableColumns)
  }

  public get fixedFilterOfModel(): Record<string, any> {
    return this.$dls.getModelProp('dataFilters') || {}
  }

  /**
   * filter, fixedFilter をマージしたものを返す
   * TODO: 名称変更したいところ...
   */
  mergeFilter(filter: FindFilter = {}) {
    return {
      // 空Object でも 何ら問題はない
      _and: [
        filter || null,
        this.fixedFilterOfModel || null,
        ...Object.values(this._filtersByKey)
      ].filter((v) => v),
    }
  }
  get filter(): FindFilter {
    return this.mergeFilter()
  }

  set filter(filter: FindFilter) {
    console.warn(`[DataListQuery.filter] filter への直接の値のセットは許可されていません。代わりに、 DataListQuery.applyFilter(filterName: string, filter: FindFilter | {}, deepMerge = false) を利用してください。`)
  }

  get findQueryParam(): FindQuery {
    const { search, sort, page, limit } = this
    return {
      search,
      filter: this.mergeFilter(),
      sort,
      page,
      limit,
      fields: this.fields || this.$dls.getModelProp('defaultFieldsParamExpression') || ['*.*'],
      virtualModelName: this.$dls.virtualModelName || null,
    }
  }
}
