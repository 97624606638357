import { ColumnDef, ColumnTypes } from '../$models/ModelDef'
import {
  isKeywordSearchableCol,
  keywordSearchOperatorByColumnType,
} from '../../plugins/ComposableDataListComponents/front/DataListKeywordFilterService'
import { getRelationColumnLabelAndKey } from './getRelationColumnLabelAndKey'

/**
 * 検索対象の Fields を指定するための Selections を返す
 *
 * - currentValue が 渡されている場合には currentValue に含まれる選択肢を除外する
 */
export const getAllNestedColumnsWithRelationsForSelections = (
  columnsArray: ColumnDef[],
  currentValue: string[] = []
): { value: string; label: string }[] => {
  const columns = columnsArray
  if (Array.isArray(currentValue) === false) {
    // init to avoid error
    currentValue = []
  }
  let selections: { value: string; label: string }[] = []
  // 選択肢の一番上に、全カラムを指定する選択肢を追加（*）
  selections.push({
    value: '*',
    label: '* (全カラム)',
  })
  // もし「全カラム」が選択されている場合には、それ以外の選択肢は追加しない
  for (const column of columns) {
    // @ts-ignore
    const colName = column.key || column.name
    if (
      column.type !== ColumnTypes.RelationshipManyToOne &&
      !isKeywordSearchableCol(column)
    ) {
      continue
    }
    if (column.type === ColumnTypes.RelationshipManyToOne) {
      // M2Oリレーションしているカラム名に対しての選択肢を追加
      selections = selections.concat(getSelectionsForM2ORelation(column).map((item) => ({
        value: item.key,
        label: item.label,
      })))
    } else if (keywordSearchOperatorByColumnType[column.type]) {
      selections.push({
        value: colName,
        label: column.label,
      })
    }
  }
  // 1. currentValueに'*'がある場合には「全てのカラム」以外の選択肢を削除
  // if (currentValue.includes('*')) {
  //   selections = selections.filter((selection) => selection.value === '*')
  // }
  // 2. currentValueに'[columnKey].*'がある場合には、そのカラムの全ての選択肢を削除
  // const columnKeys = columns.map((column) => column.key || column.name)
  const columnKeysWithAsterisk = currentValue?.filter((item) => item.includes('*'))?.map((item) => {
      // 最後の.*を削除
      return item.split('.').slice(0, -1).join('.')
    })
  if (columnKeysWithAsterisk.length > 0) {
    selections = selections.filter((selection) => {
      let flag = true
      columnKeysWithAsterisk.forEach((columnKey) => {
        if (selection.value === columnKey + '.*') {
          return
        }
        if (selection.value.startsWith(columnKey + '.') === false) {
          return
        }
        // ドットの数を数えて、それが一致している場合には、そのカラムの全ての選択肢を削除
        const dotCount = selection.value?.split('.')?.length
        if(dotCount === (columnKey.split('.').length + 1)) {
          flag = false
        }
      })
      return flag
    })
  }
  // 3. currentValueに'[columnKey]'がある場合には、そのカラムの全ての選択肢を削除
  const columnKeysWithoutAsterisk = currentValue
    ?.filter((item) => {
      return !item.includes('*')
    })
    ?.map((item) => {
      // 最後のアイテムを切ってそこに.*を追加
      return item.split('.').slice(0, -1).join('.') + '.*'
    })

  if (columnKeysWithoutAsterisk.length > 0) {
    selections = selections.filter((selection) => {
      return !columnKeysWithoutAsterisk.includes(selection.value)
    })

    // 4. 全てのカラムの選択肢も解除する
    selections = selections.filter((selection) => {
      return selection.value !== '*'
    })
  }

  return selections || []
}

/**
 * 検索対象の Fields を指定するための Selections を返す with modelName
 */
export const getAllNestedColumnsWithRelationsForSelectionsByModelName = ({
  modelName,
  virtualModelName = null,
  currentValue
}: {
  modelName: string
  virtualModelName?: string
  currentValue: string[]
}): { value: string; label: string }[] => {
  const columns = virtualModelName
    ? $core.$virtualModels[virtualModelName].columnsMergedWithBaseModel
    : $core.$models[modelName].columns
  return getAllNestedColumnsWithRelationsForSelections(Object.values(columns), currentValue)
}

const getSelectionsForM2ORelation = (column: any): {
  label: string
  key: string
}[] => {
  if (column.type === ColumnTypes.RelationshipManyToOne) {
    const colName = column.key || column.name
    return getRelationColumnLabelAndKey(column, colName, column.label || colName)
  } else {
    return []
  }
}

