import { ModelDef } from '../../../common/$models/ModelDef'
import { ComposableComponentData } from '../../ComposableComponentBuilder/ComposableComponentTypes'
import { addModelDoc } from '../../HelpDoc/coreDocsHelperFunctions'

const modelName = 'appDocumentPages'
addModelDoc(
  modelName,
  '利用ユーザ向けのドキュメントページを作成することが可能です。 利用マニュアルなどを作成する場合などに最適です。<br/>各ドキュメントページを "シリーズ" で区分けし、親子関係を設定することで、階層構造のメニューを表現可能です。',
)

const defaultValueOfBuilderContent = () => ({
  children: [
    {
      component: 'FlexComponentsContainer',
      id: $core.$utils.generateRandomString(),
      children: [
        {
          id: $core.$utils.generateRandomString(),
          label: 'HTML (WYSIWYG エディタ)',
          component: 'RichTextEditorContent',
          configuredProps: {
            t__htmlContent: '<p>サンプルテキスト サンプルテキスト</p>',
          },
        },
        {
          id: $core.$utils.generateRandomString(),
          label: 'HTML (WYSIWYG エディタ)',
          component: 'RichTextEditorContent',
          configuredProps: {
            t__htmlContent: '<p>サンプルテキスト サンプルテキスト</p>',
          },
        },
      ],
      styleProps: { styles: null, classes: null },
      advancedSettings: { useBeforeInitFunction: null },
      configuredProps: {
        display: 'flex',
        'column-gap': '10',
        'row-gap': '10',
        'flex-direction': 'column',
        'flex-wrap': 'wrap',
        'justify-content': null,
        'align-items': null,
        'align-content': null,
      },
    },
  ],
})

export type ModelAppDocumentPages = {
  id: string
  series: string
  parentId: string
  sort: number
  pageTitle: string
  menuTitle: string
  contentEditType: 'markdown' | 'builder'
  pageContents: string | null
  pageContentsBuilder: ComposableComponentData | null
  useVueComponentInMarkdown: boolean
}

export const appDocumentPages: ModelDef = {
  tableName: modelName,
  tableLabel: 'ドキュメント',
  primaryKeyColType: 'UUID',
  modelType: 'admin',
  defaultSort: { key: 'series,-sort', order: 'asc' },
  columns: {
    // シリーズ
    series: {
      type: 'SELECT',
      label: 'シリーズ',
      validate: { notEmpty: true },
      async selections() {
        const seriesGroup = []
        const appDocumentPagesSeriesGroup = await $core.$models.appDocumentPages.find({
          groupBy: ['series'],
          sort: ['series'],
        })
        appDocumentPagesSeriesGroup.map((series) => seriesGroup.push(series.series))
        return seriesGroup
      },
      editCallback: ({ row, newValue, oldValue, isNewRecord, callerVueInstance }) => {
        if (newValue !== oldValue) {
          row.parentId = ''
        }
        return row
      },
      afterComponent: {
        template: `<div class="small text-muted">アプリケーション定義のメニューで <code>/doc/{{ series }}</code> と設定することで、アクセス可能です。<a :href="hrefLink" target="_blank"><ficon type="external-link-alt"/></a></div>`,
        computed: {
          series() {
            return this.$parent?.record?.series
          },
          hrefLink() {
            return `${location.pathname}#/doc/${this.series}`
          },
        },
      },
    },
    parentId: {
      type: 'RELATIONSHIP_MANY_TO_ONE',
      label: '親ページ',
      enableIf: (row) => row.series,
      defaultValue: null,
      relationshipManyToOne: {
        collectionName: 'appDocumentPages',
        labelFormatter: (row) => row.pageTitle,
        filterByAttrs: (row) => {
          return { series: { _eq: row.series } }
        },
      },
      dynamicSelections: true,
    },
    sort: {
      type: 'NUMBER',
      label: '表示順(降順)',
      width: {
        md: 8,
      },
    },
    // ページタイトル
    pageTitle: {
      type: 'STRING',
      label: 'ページタイトル',
      validate: { notEmpty: true },
      width: {
        md: 24,
      },
    },
    menuTitle: {
      type: 'STRING',
      label: 'メニュー表示用タイトル',
      width: {
        md: 24,
      },
    },
    // コンテンツ
    contentEditType: {
      beforeComponent: '<div></div>',
      label: 'コンテンツ編集タイプ',
      type: 'STRING',
      selections: () => [
        { value: 'builder', label: 'ページビルダー' },
        { value: 'markdown', label: 'マークダウン' },
      ],
      defaultValue: 'builder',
    },
    useVueComponentInMarkdown: {
      type: 'BOOLEAN',
      label: 'マークダウン内で Vue コンポーネントを利用可能にする',
      defaultValue: false,
      enableIf: (row) => row.contentEditType === 'markdown',
      inputHelpText: 'チェックを入れると、マークダウンコンテンツ内で Vue コンポーネントを利用することが可能です。 (Vue の template としてパースします)',
      width: {
        xs: 48,
        md: 24,
      }
    },
    pageContents: {
      type: 'TEXT',
      label: 'ページコンテンツ',
      hideLabel: true,
      validate: { notEmpty: true },
      inputComponent: 'CodeEditor',
      inputAttrs: {
        language: 'markdown',
      },
      // inputAttrs: {
      //   style: 'height: 150px;',
      // },
      width: {
        xs: 48,
      },
      enableIf: (row) => row.contentEditType === 'markdown',
      inputHelpText: 'マークダウン形式が利用可能です。',
    },
    pageContentsBuilder: {
      type: 'ARRAY_OF_OBJECT',
      label: 'ページコンテンツ (ビルダー)',
      hideLabel: true,
      inputComponent: 'ComposableComponentBuilderModelInput',
      enableIf: (row) => row.contentEditType === 'builder',
      editCallback: ({ row, newValue, oldValue }) => {
        if (newValue?.renderedText !== oldValue?.renderedText) {
          // Builder にて 設定されたコンテンツ内, テキスト情報を row.pageContents に格納する for 検索
          row.pageContents = newValue?.renderedText || ''
        }
        return row
      },
      visibleOnIndex: false,
      width: {
        xs: 48,
      },
      defaultValue: () => defaultValueOfBuilderContent(),
    },
    metaData: {
      type: 'JSON',
      visible: false
    }
  },
  sortableFields: ['series', 'sort', 'pageTitle'],
  beforeSave(row) {
    if (row.parentId === '') {
      row.parentId = null
    }
    return row
  },
}
