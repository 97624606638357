import { registerComposableComponentSettings } from '../registerComposableComponentSettings'
import { normalAndOutlinedVariantsKeys } from '../composableComponentHelpers/variants'
import { ComposableComponentSettings } from '../ComposableComponentSettings'

export const BButtonComposableComponentSetting: ComposableComponentSettings = {
  label: 'ボタン',
  category: 'Basic',
  configColumns: {
    variant: {
      label: 'スタイル',
      type: 'STRING',
      selections: () => normalAndOutlinedVariantsKeys,
    },
  },
  defaultProps: {
    variant: 'primary',
  },
  children: [
    {
      label: 'テキスト',
      component: 'TextContent',
      configuredProps: {
        t__content: 'ボタンテキスト',
      }
    },
    {
      label: 'アイコン',
      component: 'Ficon',
      configuredProps: {
        type: "external-link-alt",
      }
    },
  ],
  hasDefaultSlot: true,
}

registerComposableComponentSettings('BButton', BButtonComposableComponentSetting)
