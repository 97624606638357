import { codeInputCommonAttrs } from '../../common/$models'
import { ColumnDef, ColumnDefByColName, ModelDef } from '../../common/$models/ModelDef'
import { addModelDoc } from '../HelpDoc/coreDocsHelperFunctions'

const modelName = 'appDefinitions'

// Model 一覧に表示される Docs を追加
addModelDoc(
  modelName,
  'アプリケーション定義では、ユーザが利用するアプリケーション (例: "経費申請アプリ" など) の作成・メニュー構成を設定します。ユーザ権限毎に利用可否を設定することも可能です。',
)

/**
 * tableName validator
 */
const validateTableName = (value, col, modelName, record) => {}

export const generateAppUrlWithKey = (key: string) => {
  return `${globalThis.location?.protocol}//${globalThis.location?.host}/a/${key}`
}

/**
 * URLからappKeyを抽出
 * '/a/appName/' => 'appName'
 */
export const fetchAppKeyFromUrl = (): string => {
  return (globalThis.location?.pathname || '').split('/')[2]
}

const menuTypes = {
  model: 'モデル定義',
  vmodel: 'Virtualモデル定義',
  heading: 'メニュー区切り小見出し',
  nested: 'サブメニュー',
  free: '自由リンク',
  component: 'Vueコンポーネントとして設定',
}

const menuTypeKeys = Object.keys(menuTypes)

const menuBasicColumns = (hasNested = true) => {
  const enabledTypes = hasNested ? menuTypeKeys : menuTypeKeys.filter((key) => key !== 'nested')
  const columns: ColumnDefByColName = {
    linkType: {
      label: 'リンクタイプ',
      type: 'STRING',
      selections() {
        return enabledTypes
      },
      customLabel(val) {
        return menuTypes[val]
      },
      inputAttrs: { wrapperClass: 'col-12 col-md-2' },
      defaultValue: 'model',
    },
    label: {
      label: 'ラベル',
      type: 'STRING',
      enableIf: (row) => ['free', 'nested', 'heading'].indexOf(row.linkType) >= 0,
      validate: { notEmpty: true },
      inputAttrs: { wrapperClass: 'col-12 col-md-2' },
    },
    icon: {
      label: 'アイコン',
      inputAttrs: { wrapperClass: 'col-12 col-md-1' },
      type: 'STRING',
      enableIf: (row) =>
        ['free', 'nested', 'heading', 'model', 'vmodel'].indexOf(row.linkType) >= 0,
      inputComponent: 'IconSelectInput',
    },
    linkTargetModel: {
      label: 'リンク先モデル',
      type: 'STRING',
      enableIf: (row) => row.linkType === 'model',
      selections() {
        return Object.keys($core.$models)
      },
      customLabel(val) {
        const label = $core.$models[val]?.tableLabel
        return `${val} ${label ? ` (${label})` : label}`
      },
      inputAttrs: {
        wrapperClass: 'col-12 col-md-6',
      },
      validate: {
        notEmpty: true,
      },
    },
    linkTargetVModel: {
      label: 'リンク先Virtualモデル',
      type: 'STRING',
      enableIf: (row) => row.linkType === 'vmodel',
      selections() {
        return Object.keys($core.$virtualModels)
      },
      customLabel(val) {
        const label = $core.$virtualModels[val]?.tableLabel
        return `${val} ${label ? ` (${label})` : label}`
      },
      inputAttrs: {
        wrapperClass: 'col-12 col-md-6',
      },
      validate: {
        notEmpty: true,
      },
    },
    linkTarget: {
      label: 'リンク先',
      type: 'STRING',
      enableIf: (row) => row.linkType === 'free',
      dynamicSelections: true,
      validate: {
        notEmpty: true,
      },
      editCallback: ({ row, newValue }) => {
        if (newValue) {
          // リンク冒頭の「/#」は削除
          if (newValue.startsWith('/#')) {
            newValue = newValue.replace(/^\/#/, '')
            row.linkTarget = newValue
          }
          // リンク冒頭の「/」がなければ追加
          if (newValue[0] !== '/') {
            row.linkTarget = `/${newValue}`
          }
        }
        return row
      },
      width: {
        md: 24,
      },
    },
    component: {
      label: 'コンポーネント定義',
      type: 'TEXT',
      inputAttrs: {
        ...codeInputCommonAttrs,
      },
      enableIf: (row) => row.linkType === 'component',
    },
    cssClass: {
      label: '追加CSS Class',
      type: 'STRING',
      displayAsDetail: true,
    },
    userRoleRestrictions: {
      label: 'ユーザ権限による表示制限',
      type: 'MULTISELECT',
      inputAttrs: {
        wrapperClass: 'col-12',
        placeholder: '選択',
      },
      async selections(): Promise<any[]> {
        const roles = await $core.$models.user_roles.find({
          limit: -1,
        })
        return roles.map((r) => r.key)
      },
      inputHelpText: '指定した場合、該当のユーザ権限に所属するユーザにのみ表示されます。',
      // displayAsDetail: true,
    },
    amountBadgeVisible: {
      label: '件数表示を有効化',
      type: 'BOOLEAN',
      displayAsDetail: true,
      inputAttrs: {
        wrapperClass: 'w-auto',
      },
      inputHelpText:
        'メニュー内に、特定の検索条件を指定してデータの件数を表示可能です。対応が必要な件数 (例えば、対象モデルのデータの内 "承認待ち" 状態の件数) を表示する際に利用します。',
    },
    amountBadgeTargetModel: {
      label: '件数カウント対象のモデル',
      type: 'STRING',
      enableIf: (row) => row.amountBadgeVisible === true,
      selections() {
        return Object.keys($core.$models)
      },
      customLabel(val) {
        const label = $core.$models[val]?.tableLabel
        return `${val} ${label ? ` (${label})` : label}`
      },
      inputAttrs: {
        wrapperClass: 'col-12 col-md-6',
      },
      validate: {
        notEmpty: true,
      },
      displayAsDetail: true,
    },
    amountBadgeFilterCondition: {
      label: '件数フィルタリング条件',
      type: 'TEXT',
      enableIf: (row) => row.amountBadgeVisible === true && row.amountBadgeTargetModel,
      inputAttrs: {
        wrapperClass: 'col-12',
      },
      inputComponent: {
        template: '<FilterResultDisplayContainer v-if="collectionName" :emitValueAsObject="true" v-bind="attrsMerged"/>',
        computed: {
          collectionName(): string {
            const modelName = this.$attrs.record?.amountBadgeTargetModel
            return modelName || null
          },
          attrsMerged() {
            return {
              ...this.$attrs,
              collectionName: this.collectionName
            }
          }
        }
      },
      displayAsDetail: true,
    },
    amountBadgeStyle: {
      label: 'カウントの表示色',
      type: 'STRING',
      enableIf: (row) => row.amountBadgeVisible === true && row.amountBadgeTargetModel,
      selections() {
        return ['primary', 'secondary', 'success', 'danger', 'warning', 'info', 'light', 'dark']
      },
      displayAsDetail: true,
      inputAttrs: {
        wrapperClass: 'w-auto',
      },
      defaultValue: 'danger',
    },
    amountBadgeStyleWhenZero: {
      label: '0件の際の表示色',
      type: 'STRING',
      enableIf: (row) => row.amountBadgeVisible === true && row.amountBadgeTargetModel,
      selections() {
        return ['primary', 'secondary', 'success', 'danger', 'warning', 'info', 'light', 'dark']
      },
      displayAsDetail: true,
      inputAttrs: {
        wrapperClass: 'w-auto',
      },
      defaultValue: 'secondary',
    },
    useActiveLinkRegex: {
      beforeComponent: '<div></div>',
      label: 'アクティブリンク判定に正規表現を利用',
      type: 'BOOLEAN',
      displayAsDetail: true,
    },
    activeLinkRegex: {
      enableIf: (row) => row.useActiveLinkRegex === true,
      label: 'アクティブリンク判定用正規表現',
      type: 'STRING',
      inputHelpText: '正規表現を指定します。例: "^/m/expense/.*" と指定した場合、/m/expense/ で始まるURLがアクティブと判定されます。',
      displayAsDetail: true,
    },
    activeLinkRegexFlags: {
      enableIf: (row) => row.useActiveLinkRegex === true,
      label: 'アクティブリンク判定用正規表現フラグ',
      type: 'STRING',
      inputHelpText: '正規表現フラグを指定します。例: "i" と指定した場合、大文字小文字を区別しないようになります。',
      displayAsDetail: true,
      validate: {
        isRightRegexFlag: (val) => {
          if(!val) {
            return
          }
          const validJsRegexFlags = ['g', 'i', 'm', 's', 'u', 'y']
          const invalidFlags = val.split('').filter((flag) => validJsRegexFlags.indexOf(flag) < 0)
          if (invalidFlags.length) {
            return `正規表現フラグは ${validJsRegexFlags.join(', ')} のいずれかを指定してください。`
          }
          return
        }
      },
    }
  }
  if (hasNested) {
    columns.children = {
      label: 'サブメニュー',
      type: 'ARRAY_OF_OBJECT',
      enableIf: (row) => row.linkType === 'nested',
      inputAttrs: {
        wrapperClass: 'col-12',
      },
      columns: menuBasicColumns(false),
    }
  }
  return columns
}

const mainMenus: ColumnDef = {
  groupKey: 'menu',
  label: 'メインメニュー',
  visibleOnIndex: false,
  hideLabel: true,
  type: 'ARRAY_OF_OBJECT',
  inputAttrs: {
    wrapperClass: 'col-12',
  },
  columns: menuBasicColumns(),
}

export const appDefinitions: ModelDef = {
  tableName: modelName,
  primaryKeyColType: 'UUID',
  tableLabel: 'アプリケーション定義',
  tableComment: '',
  timestamps: true,
  defaultSort: { key: 'key', order: 'asc' },
  modelType: 'admin',
  formColumnGroups: {
    disp: {
      type: 'tab',
      label: '表示設定',
      groupKey: 'tab1',
      icon: 'eye',
      order: 30,
    },
    menu: {
      type: 'tab',
      label: 'メニュー設定',
      groupKey: 'tab1',
      icon: 'list',
      order: 20,
    },
    privilege: {
      type: 'tab',
      label: '権限設定',
      groupKey: 'tab1',
      icon: 'user-check',
      order: 30,
    },
    advanced: {
      type: 'tab',
      label: '高度な設定',
      groupKey: 'tab1',
      icon: 'cog',
      order: 30,
    },
  },
  columns: {
    key: {
      label: 'アプリケーションKey',
      inputHelpText: `アプリケーションのURLの一部を決定します。 例: "myapp" と設定した場合: ${generateAppUrlWithKey(
        'myapp',
      )} がアプリケーションのURLになります。`,
      type: 'STRING',
      unique: true,
      validate: {
        notEmpty: true,
        shouldAlphabet: (val) => {
          if (/^[a-z|\-|0-9]+$/.test(val) === false) {
            return '英字(小文字)およびハイフンで入力ください'
          }
          return false
        },
      },
    },
    name: {
      label: 'アプリケーション名',
      type: 'STRING',
      validate: { notEmpty: true },
    },
    logo: {
      groupKey: 'disp',
      orderOnForm: 20,
      label: 'アプリケーションアイコン',
      type: 'FILE',
      visibleOnIndex: false,
    },
    startPage: {
      groupKey: 'disp',
      label: 'アプリケーション初期表示URL',
      type: 'STRING',
      orderOnForm: 10,
      inputAttrs: {
        wrapperClass: 'col-12 col-md-6',
      },
      inputHelpText: `アプリケーション選択時の初期表示URLを指定します。 例: "/m/startPage" と設定した場合: ${
        generateAppUrlWithKey('myapp') + '/#/m/startPage'
      } が初期表示URLになります。`,
    },
    themeColor: {
      groupKey: 'disp',
      orderOnForm: 20,
      label: 'テーマカラー',
      type: 'STRING',
      inputAttrs: {
        type: 'color',
        style: 'width: 80px; height: 36px;',
      },
      visible: false,
    },
    appMenuColor: {
      orderOnForm: 20,
      groupKey: 'disp',
      label: 'メニューの背景色',
      type: 'STRING',
      inputAttrs: {
        type: 'color',
        style: 'width: 80px; height: 36px;',
      },
      visibleOnIndex: false,
      inputComponent: 'ColorPicker',
    },
    // layoutType: {
    //   orderOnForm: 80,
    //   type: 'STRING',
    //   label: 'レイアウトタイプ',
    //   groupKey: 'disp',
    //   selections: () => Object.keys(appLayoutTypes).map((key) => ({ value: key, label: appLayoutTypes[key].label })),
    //   inputComponent: 'RadioSelectInput',
    // },
    roleRestriction: {
      groupKey: 'privilege',
      label: 'ユーザ権限によるアクセス制限',
      type: 'MULTISELECT',
      inputHelpText: '指定した場合、該当のユーザ権限に所属するユーザのみがアクセス可能になります。',
      inputAttrs: {
        wrapperClass: 'col-12 col-md-6',
        placeholder: '選択',
      },
      async selections(): Promise<string[]> {
        const roles = await $core.$models.user_roles.find({
          limit: -1,
        })
        return roles.map((r) => r.key)
      },
    },
    /**
     * メニューの定義を実施
     * Modelのメニュー
     */
    mainMenus,
    /**
     * アプリケーション起動時に実行する関数 with AppHook
     * - 物理カラムを持たない仮想カラム
     */
    functionsOnAppLoaded: {
      doNotSyncModel: true,
      visibleOnIndex: false,
      type: 'STRING',
      groupKey: 'advanced',
      label: 'アプリケーション起動時に実行する関数',
      afterComponent: `<div class="small text-muted mt-2">モデル定義 <code>frontSideAppHooks</code> を活用して、アプリケーション起動時の処理を追加可能です。</div>`,
      width: {xs:48},
      inputComponent: {
        template: `<ModelIndexDirectus :enable-header="false" model-name="frontSideAppHooks" :filters="filterCondition"/><div class="pt-2 text-center"><span class="btn btn-outline-primary" @click.prevent="() => openAddNewModal()"><ficon type="plus"/> 新規追加</span></div>`,
        computed: {
          appKey() {
            return this.$parent.record?.key
          },
          appHookName() {
            return $core.$appDefinitionLoader.getAppDefinitionLoadedWithAppKey(this.appKey)
          },
          filterCondition() {
            return {
              appHookName: {
                _eq: this.appHookName
              }
            }
          }
        },
        methods: {
          openAddNewModal() {
            $core.$modals.openCreateViewModal({
              modelName: 'frontSideAppHooks',
              defaultValues: {
                appHookName: this.appHookName,
              }
            })
          }
        }
      }
    },
  },
  afterSave: async (saved: any): Promise<boolean> => {
    setTimeout(async () => {
      if (saved.key === $core.$appDefinitionLoader.appDefinition?.name) {
        await $core.$appDefinitionLoader.reload()
      }
      $core.$uiState.latestModelDefinitionLoadedTime = new Date().getTime()
    }, 1)
    return true
  },
  afterDelete() {
    setTimeout(() => {
      $core.$uiState.latestModelDefinitionLoadedTime = new Date().getTime()
    }, 1)
  },
}
