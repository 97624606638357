import { FilterItemService } from './FilterItemService'
import { FilterGroupService } from './FilterGroupService'
import { ComposableDataListService } from './ComposableDataListService'
import { ColumnDef, ColumnDefByColName, ColumnTypes } from '../../../common/$models/ModelDef'
import { ModelFormService } from '../../../front/ModelForm/ModelFormService'
import { FindFilter, ModelFactory } from '../../../common/$models'
import { createFilterItemFromFindFilterQueryObject } from './createFilterItemFromFindFilterQueryObject'
import { FieldFilter } from '@directus/sdk'
import {
  DBDefinedModelDefinitionColumnsColumns
} from '../../../common/modelDefinitions/DBDefinedModelDefinitionColumns'

/**
 * 高度なフィルタリング機能を提供するサービス
 * filters, filterItemService
 *
 * constructor 時に設定可能な props with default
 * modelName: { required: true },
 * virtualModelName: { required: false },
 * filters: { required: false, default: [], type: Array },
 * ComposableDataListService: { required: true, default: null },
 */
const evaluateRule = (colName: string, operator: string, value: any, recordValue: any): boolean => {
  return evaluateRuleOperations[operator](value, recordValue)
}

// evaluateRule 関数を key value pair に変換したオブジェクト
export const evaluateRuleOperations = {
  _eq: (value: any, recordValue: any) => {
    // recordValueがBooleanの場合には、文字列に変換して比較する
    if (typeof recordValue === 'boolean' && typeof value === 'string') {
      return recordValue.toString() === value
    }
    return recordValue === value
  },
  _neq: (value: any, recordValue: any) => {
    // recordValueがBooleanの場合には、文字列に変換して比較する
    if (typeof recordValue === 'boolean') {
      return recordValue.toString() !== value
    }
    return recordValue !== value
  },
  _contains: (value: any, recordValue: any) => (recordValue ? recordValue.includes(value) : false),
  _ncontains: (value: any, recordValue: any) =>
    recordValue ? !recordValue.includes(value) : false,
  _icontains: (value: any, recordValue: any) =>
    recordValue.toLowerCase().includes(value.toLowerCase()),
  _nicontains: (value: any, recordValue: any) =>
    !recordValue.toLowerCase().includes(value.toLowerCase()),
  _starts_with: (value: any, recordValue: any) => recordValue.startsWith(value),
  _nstarts_with: (value: any, recordValue: any) => !recordValue.startsWith(value),
  _istarts_with: (value: any, recordValue: any) =>
    recordValue.toLowerCase().startsWith(value.toLowerCase()),
  _nistarts_with: (value: any, recordValue: any) =>
    !recordValue.toLowerCase().startsWith(value.toLowerCase()),
  _ends_with: (value: any, recordValue: any) => recordValue.endsWith(value),
  _nends_with: (value: any, recordValue: any) => !recordValue.endsWith(value),
  _iends_with: (value: any, recordValue: any) =>
    recordValue.toLowerCase().endsWith(value.toLowerCase()),
  _niends_with: (value: any, recordValue: any) =>
    !recordValue.toLowerCase().endsWith(value.toLowerCase()),
  _gt: (value: any, recordValue: any) => recordValue > value,
  _gte: (value: any, recordValue: any) => recordValue >= value,
  _lt: (value: any, recordValue: any) => recordValue < value,
  _lte: (value: any, recordValue: any) => recordValue <= value,
  _between: (value: any, recordValue: any) => {
    const [min, max] = value.split(',').map(Number)
    return recordValue >= min && recordValue <= max
  },
  _nbetween: (value: any, recordValue: any) => {
    const [nmin, nmax] = value.split(',').map(Number)
    return recordValue < nmin || recordValue > nmax
  },
  _empty: (value: any, recordValue: any) => recordValue === '',
  _nempty: (value: any, recordValue: any) => recordValue !== '',
  _null: (value: any, recordValue: any) => recordValue === null,
  _nnull: (value: any, recordValue: any) => recordValue !== null,
  _in: (value: any, recordValue: any) =>
    Array.isArray(value) ? value.includes(recordValue) : value.split(',').includes(recordValue),
  _nin: (value: any, recordValue: any) =>
    Array.isArray(value) ? !value.includes(recordValue) : !value.split(',').includes(recordValue),
}
export type OperatorKey = keyof typeof evaluateRuleOperations

// 検索対象にしないカラムタイプ
export const filterDisabledColumnType: string[] = [
  ColumnTypes.ArrayOfObject,
  ColumnTypes.JSON,
  // ColumnTypes.RelationshipOneToMany,
]

export const processQuery = (queryData: FindFilter, record: Record<string, any>): boolean => {
  for (const key in queryData) {
    const value = queryData[key]

    if (key === '_and') {
      for (const andCondition of value) {
        if (!processQuery(andCondition, record)) {
          return false
        }
      }
    } else if (key === '_or') {
      let anyTrue = false
      for (const orCondition of value) {
        if (processQuery(orCondition, record)) {
          anyTrue = true
          break
        }
      }
      if (!anyTrue) return false
    } else {
      const operatorKeyOrNestedFieldName = Object.keys(value)[0]
      const operatorValue = Object.values(value)[0]
      // operatorKey が "_" で始まるなら、フィルタリング条件として処理する
      if (operatorKeyOrNestedFieldName.startsWith('_')) {
        if (!evaluateRule(key, operatorKeyOrNestedFieldName, operatorValue, record[key])) {
          return false
        }
      } else {
        // operatorKey が "_" で始まらないなら、ネストされたカラムとして評価する
        if (!processQuery(value, record[key])) {
          return false
        }
      }
    }
  }
  return true
}

/**
 * レコードの配列 に対して フィルタリング条件に合致したデータを返す
 * @param filterQuery
 * @param records
 */
export const matchesFilter = <T = any>(filterQuery: FindFilter, records: T[]): T[] => {
  return Array.isArray(records) ? records.filter((record) => processQuery(filterQuery, record)) : []
}

export type FilterColumnSelection = {
  value: string
  label: string
  children?: FilterColumnSelection[]
}


/**
 * フィルタリング条件の 子選択肢を生成する by column
 * @param column
 * @param nestedCount
 * @param modelNameHistories
 * @param maxNestedCount
 */
export const getChildrenOptions = (
  column: ColumnDef & DBDefinedModelDefinitionColumnsColumns | any,
  nestedCount = 0,
  modelNameHistories = [],
  maxNestedCount = 3,
): FilterColumnSelection[] => {
  const shouldHaveChildren = [ColumnTypes.RelationshipManyToOne, ColumnTypes.RelationshipOneToMany].indexOf(column.type) >= 0
  if (!shouldHaveChildren) {
    return []
  }
  let collectionName = column.type === ColumnTypes.RelationshipManyToOne ? (column.relationshipManyToOne?.collectionName || column.relationshipManyToOne_collectionName) : (column.type === ColumnTypes.RelationshipOneToMany ? column.relationshipOneToMany?.collectionName || column.relationshipOneToMany_collectionName : null)
  if (!collectionName) {
    return []
  }
  const columns = $core.$models[collectionName]?.columns
  if (!columns) {
    console.warn(`[FilterControlsService] modelName "${collectionName}" not found.`)
    return []
  }
  // 無限ループを防ぐために、modelNameHistoriesにcollectionNameが含まれている場合には、空配列を返す
  if (modelNameHistories.includes(collectionName) && nestedCount > maxNestedCount) {
    return []
  }
  return Object.keys(columns).map((key) => {
    const column = columns[key]
    return {
      value: column.name,
      label: column.label || column.name,
      children: shouldHaveChildren ? getChildrenOptions(column, nestedCount + 1, modelNameHistories.concat(collectionName), maxNestedCount)
          : [],
    }
  })
}

type NumberOrString = number | string
type BetweenLikeValueRes =
  | {
      _between: [NumberOrString, NumberOrString]
    }
  | {
      _gte: NumberOrString
    }
  | {
      _lte: NumberOrString
    }

/**
 * between like の場合に
 * - NUMBERなら: values を isNaN で 評価して NaN なら null に変換
 * - DATEなら: values を string として評価
 */
const convertBetweenLikeObjectToFilterValue = (
  values: any[],
  columnDef: ColumnDef,
): BetweenLikeValueRes | null => {
  if (!values[0] && !values[1]) {
    return null
  }
  // @ts-ignore
  if ([ColumnTypes.Datetime, ColumnTypes.DateOnly].indexOf(columnDef.type) >= 0) {
    // Date like なら...
    if (values[0] && values[1]) {
      return {
        _between: [values[0], values[1]],
      }
    } else if (values[0]) {
      return {
        _gte: values[0],
      }
    }
    return {
      _lte: values[1],
    }
  }
  // Number like の場合に、 values を isNaN で 評価して NaN なら null に変換
  const gtIsValid = !isNaN(values[0])
  const ltIsValid = !isNaN(values[1])
  if (!gtIsValid && !ltIsValid) {
    return null
  }
  if (gtIsValid && ltIsValid) {
    return {
      _between: [values[0], values[1]],
    }
  }
  if (gtIsValid) {
    return {
      _gte: values[0],
    }
  }
  return {
    _lte: values[1],
  }
}

// 検索の際にemptyじゃなくてnullで検索しないといけないカラムタイプを定義する
export const filterEmptyToNullColumnType: string[] = [
  ColumnTypes.Number,
  ColumnTypes.Boolean,
  ColumnTypes.DateOnly,
  ColumnTypes.Datetime,
  ColumnTypes.Float,
  ColumnTypes.Decimal,
  ColumnTypes.Double,
]

export const convertFilterItemServiceToQueryObject = (
  filterItemService: FilterItemService<any>,
): FieldFilter<any> => {
  const filter = filterItemService.value
  if (filter && filter.colName) {
    const result: any = {}
    if (filter.operator === 'between' && Array.isArray(filter.value)) {
      const betweenLikeValueRes = convertBetweenLikeObjectToFilterValue(
        filter.value,
        filterItemService.colDef,
      )
      if (betweenLikeValueRes) {
        Object.assign(result, betweenLikeValueRes)
      }
    } else if (filter.operator === 'relative') {
      if (Array.isArray(filter.value)) {
        result['_between'] = filter.value.join(',')
      } else {
        result['_eq'] = filter.value
      }
    } else if (filter.operator === 'isnull') {
      if (filterEmptyToNullColumnType.includes(filterItemService.colDef.type)) {
        result[`_null`] = true
      } else {
        result[`_or`] = {
          type: 'isnull',
          expressions: [{ _null: true }, { _empty: true }],
        }
      }
    } else if(filter.operator === 'isnotnull') {
      if (filterEmptyToNullColumnType.includes(filterItemService.colDef.type)) {
        result[`_nnull`] = true
      } else {
        result[`_and`] = {
          type: 'isnotnull',
          expressions: [{ _nnull: true }, { _nempty: true }],
        }
      }
    } else {
      if (filter.value === null || filter.value === undefined || filter.value === '') {
        return null
      } else {
        result[`_${filter.operator}`] = filter.value
      }
    }
    /**
     *
     */
    if (
      filterItemService.columnNamePathsFromRootColumnDef &&
      filterItemService.columnNamePathsFromRootColumnDef.length > 0
    ) {
      const fullPath = [...filterItemService.columnNamePathsFromRootColumnDef]

      // Check for duplicate
      if (fullPath[fullPath.length - 1] === filter.colName) {
        fullPath.pop()
      }
      let _tmp = null
      if (Object.keys(result)?.[0] === '_or' && result['_or'].type === 'isnull') {
        const expressionArray = result['_or'].expressions.map((v) => {
          return {
            [filter.colName]: v,
          }
        })
        _tmp = {
          _or: expressionArray,
        }
      } else if (Object.keys(result)?.[0] === '_and' && result['_and'].type === 'isnotnull') {
        const expressionArray = result['_and'].expressions.map((v) => {
          return {
            [filter.colName]: v,
          }
        })
        _tmp = {
          _and: expressionArray,
        }
      } else {
        _tmp = Object.keys(result)?.[0] === '_or' ? result : { [filter.colName]: result }
      }
      return buildNestedStructure(fullPath, Object.keys(result).length === 0 ? null : _tmp)
    } else {
      // resultが空の場合にはnullを返す
      if (Object.keys(result).length === 0) {
        return null
      } else {
        // @ts-ignore
        if (Object.keys(result)?.[0] === '_or' && result['_or'].type === 'isnull') {
          const expressionArray = result['_or'].expressions.map((v) => {
            return {
              [filter.colName]: v,
            }
          })
          return {
            _or: expressionArray,
          }
        }
        if (Object.keys(result)?.[0] === '_or') {
          return result
        }
        return { [filter.colName]: result }
      }
    }
  } else {
    return null
  }
}

export const processFilterObject = (filterItemService) => {
  if (filterItemService.objectType === 'rule') {
    return convertFilterItemServiceToQueryObject(filterItemService)
  } else if (filterItemService.objectType === 'group') {
    const operator = filterItemService.filterLogic // Use filterLogic as operator
    const filterObjects = filterItemService.filterObjects
    // processFilterObjectがnullの場合には除外する
    const res = filterObjects.map(processFilterObject).filter((v) => !!v)
    if (res.length > 0) {
      return {
        [`_${operator}`]: res,
      }
    }
  }
}

const buildNestedStructure = (pathArray, filterValue) => {
  if (pathArray.length === 0) {
    return filterValue
  }
  const [head, ...rest] = pathArray
  return { [head]: buildNestedStructure(rest, filterValue) }
}

const defaultFilterConditionKeyName = 'FilterControlsService'

/**
 * 高度なフィルタリング機能を提供するサービス
 * - hasMany FilterItemService[]
 * - hasOne FilterGroupService
 */
export class FilterControlsService {
  public initialized = false
  public filterItemServices: { [colName: string]: FilterItemService<any> } = {}
  // vue3を利用してreactiveにする
  public filterGroupService: FilterGroupService | null = null
  public ComposableDataListServiceInstance: ComposableDataListService
  public ModelFormServiceInstance: ModelFormService
  public vueInstance: any
  public _modelName: string
  public relationModelName: string
  /**
   * クエリの管理に利用する, 複数の FilterControlsService を利用する場合には, 一意な値を指定する必要がある, デフォルトは `FilterControlsService`
   */
  public readonly filterConditionKeyName: string
  /**
   * Component $emit 実施時に, stringify するかどうか の設定
   */
  public readonly emitValueAsObject: boolean
  public focusingColumnPaths: string[] = [''] // dot connected path
  public validationColumns: ColumnDefByColName = null

  constructor({
    ComposableDataListServiceInstance: ComposableDataListService = null,
    ModelFormServiceInstance: ModelFormService = null,
    vueInstance = null,
    modelName = null,
    emitValueAsObject = false,
    filterConditionKeyName = defaultFilterConditionKeyName,
  }) {
    if (ComposableDataListService && ModelFormService) {
      throw new Error(
        `[FilterControlsService] ComposableDataListService と ModelFormService は 片方のみ指定可能です`,
      )
    }
    if (ComposableDataListService) {
      this.ComposableDataListServiceInstance = ComposableDataListService
      this.ModelFormServiceInstance = null
    } else if (ModelFormService) {
      this.ComposableDataListServiceInstance = null
      this.ModelFormServiceInstance = ModelFormService
    }
    this.filterConditionKeyName = filterConditionKeyName
    this._modelName = modelName
    this.vueInstance = vueInstance
    this.emitValueAsObject = !!emitValueAsObject
    if (this.ComposableDataListServiceInstance) {
      // 初期化時に クエリを設定しておく => コレを実施しないと、空クエリセット時にも 再度検索が走ってしまうため
      this.ComposableDataListServiceInstance.query?.applyFilter(
        this.filterConditionKeyName,
        this.filterQuery,
      )
    }
  }

  setValiadtionColumns(columns) {
    this.validationColumns = columns
  }

  get modelName(): string {
    if (this.relationModelName) {
      return this.relationModelName
    }
    if (this.ComposableDataListServiceInstance?.modelName) {
      return this.ComposableDataListServiceInstance?.modelName
    } else if (this.ModelFormServiceInstance?.modelName) {
      if (this.ModelFormServiceInstance?.modelName == 'modelDefinitions') {
        return this.ModelFormServiceInstance.record.tableName
      } else {
        return this.ModelFormServiceInstance?.modelName
      }
    } else {
      return this._modelName
    }
  }

  get model(): ModelFactory {
    // @ts-ignore
    return $core.$models[this.modelName]
  }

  appendFilterItem(colNamePath: string[]) {
    this.focusingColumnPaths = [colNamePath.join('.')]
    // 既存のfilterItemServiceからisFocusをfalseにする
    Object.keys(this.filterItemServices).forEach((key) => {
      this.filterItemServices[key].isFocus = false
    })
    if (this.filterGroupService) {
      this.filterGroupService.isFocus = false
    }
    // 新しいFilterItemを追加する
    this.filterItemServices[colNamePath.join('.')] = new FilterItemService({
      columnNamePathsFromRootColumnDef: colNamePath,
      filterItemType: 'normal',
      filterControlsService: this,
      filterGroupService: null,
      isFirstObject: false,
      isSecondObject: false,
      isFocus: true,
    })
  }

  removeModernFilter() {
    this.filterGroupService = null
    this.refreshFilter()
  }

  get columnsForAppDefinition() {
    return $core.$models[this.modelName]?.columns || {}
  }

  setFilterFromQuery(query: string | FindFilter) {
    const queryObject = typeof query === 'string' ? JSON.parse(query) : query
    this.setFilterFromQueryFromObj(queryObject?._and || [])
  }

  setFilterFromQueryFromObj(queryObjectAndConditionArray: FindFilter[]) {
    // 空の場合には初期化しない
    if (!queryObjectAndConditionArray?.length) {
      this.initialized = true
      return
    }

    // Pass true since the root is always the top level
    const restored = {
      filterItemServices: [],
      filterGroupService: null,
    }
    // Loop queryObjectAndConditionArray
    for (const queryObjectAndCondition of queryObjectAndConditionArray) {
      const restoredFilters = createFilterItemFromFindFilterQueryObject(
        queryObjectAndCondition,
        true,
        null,
        0,
        this,
      )
      restored.filterItemServices.push(...restoredFilters.filterItemServices)
      if (restoredFilters.filterGroupService) {
        restored.filterGroupService = restoredFilters.filterGroupService
      }
    }
    this.filterItemServices = restored.filterItemServices.reduce((acc, filterItemService) => {
      if (acc[filterItemService.columnNamePathDotConnected]) {
        // TODO: dup?
        console.warn(
          `Restore した filterItemService に path の重複が発生しています (path: ${filterItemService.columnNamePathDotConnected})`,
        )
      }
      acc[filterItemService.columnNamePathDotConnected] = filterItemService
      return acc
    }, {})
    this.filterGroupService = restored.filterGroupService
    this.initialized = true
    this.refreshFilter()
  }

  removeFilter(colName: string) {
    delete this.filterItemServices[colName]
  }

  focusFilterItem(colName: string) {
    Object.keys(this.filterItemServices).forEach((key) => {
      this.filterItemServices[key].isFocus = false
    })
    if (this.filterGroupService) {
      this.filterGroupService.isFocus = false
    }
    try {
      this.filterItemServices[colName].isFocus = true
    } catch (e) {
      console.error(e)
    }
  }

  focusModernFilter() {
    Object.keys(this.filterItemServices).forEach((key) => {
      this.filterItemServices[key].isFocus = false
    })
    if (this.filterGroupService) {
      this.filterGroupService.isFocus = true
    }
  }

  private get _baseColumns(): ColumnDefByColName {
    if (this.ComposableDataListServiceInstance) {
      return this.ComposableDataListServiceInstance.filteredColumns
    }
    if (this.modelName) {
      return this.columnsForAppDefinition
    }
    if (this.ModelFormServiceInstance) {
      if (this.ModelFormServiceInstance.modelName == 'modelDefinitions') {
        return this.ModelFormServiceInstance.columnsForRecord
      }
      return this.ModelFormServiceInstance?.model?.columns
    }
    return {}
  }

  get columns(): ColumnDefByColName {
    if (this.validationColumns) {
      return this.validationColumns
    }

    const columns = this._baseColumns
    // @ts-ignore
    if (!!this.ModelFormServiceInstance && this.ModelFormServiceInstance.record.baseModel) {
      // vitualModelの場合
      // @ts-ignore
      const baseColumns = $core.$models[this.ModelFormServiceInstance.record.baseModel].columns
      // columnsのtypeをbaseColumnsのtypeから補足する
      Object.keys(columns).forEach((key) => {
        const column = columns[key]
        column.type = baseColumns[key].type
      })
    }
    const cols = Object.keys(columns).reduce((acc: any, key: string) => {
      const column = columns[key]
      if (
        column.visible !== false &&
        column.excludeFromSearch !== true &&
        filterDisabledColumnType.includes(column.type) === false &&
        !column.doNotSyncModel &&
        !column.virtualColumnOf
      ) {
        acc[key] = column
      }
      return acc
    }, {})
    // ID or primary key に 類するものは 明示的に 検索対象から除外されていない限り 検索対象として 選択可能にする
    if (
      this.model &&
      !cols[this.model.primaryKeyColName] &&
      this.model.primaryKeyCol?.excludeFromSearch !== true
    ) {
      cols[this.model.primaryKeyColName] = this.model.primaryKeyCol
    }
    return cols
  }

  // getColumnDefFromNamePath(namePath: string[]): ColumnDef | null {
  //   let columns = this.columns
  //   let columnDef = null
  //   for (const name of namePath) {
  //     columnDef = columns[name]
  //     if (!columnDef) {
  //       return null
  //     }
  //     if (columnDef.type === ColumnTypes.RelationshipManyToOne) {
  //       if (columnDef.relationshipManyToOne) {
  //         columns = $core.$models[columnDef.relationshipManyToOne.collectionName].columns
  //       } else {
  //         columns = $core.$models[columnDef.relationshipManyToOne_collectionName].columns
  //       }
  //     } else {
  //       break
  //     }
  //   }
  //   return columnDef
  // }

  refreshFilter() {
    if (this.ComposableDataListServiceInstance) {
      this.ComposableDataListServiceInstance.query.applyFilter(
        this.filterConditionKeyName,
        this.filterQuery,
      )
    }
    if ((this.ModelFormServiceInstance || this.modelName) && this.vueInstance) {
      this.vueInstance.$emit(
        'update:modelValue',
        this.emitValueAsObject ? this.filterQuery : JSON.stringify(this.filterQuery),
      )
    }
  }

  appendModernFilter() {
    if (this.filterGroupService) {
      this.filterGroupService.isFocus = true
      return
    }
    Object.keys(this.filterItemServices).forEach((key) => {
      this.filterItemServices[key].isFocus = false
    })
    this.filterGroupService = new FilterGroupService(this, true, false, null, 1, true)
  }

  /**
   *
   */
  get filterQuery(): FindFilter {
    const query: FindFilter = {
      _and: [],
    }

    for (const key of Object.keys(this.filterItemServices)) {
      const filterItemService = this.filterItemServices[key]
      const processed = processFilterObject(filterItemService)
      if (processed) {
        query._and.push(processed)
      }
    }

    if (this.filterGroupService) {
      const processed = processFilterObject(this.filterGroupService)
      if (processed) {
        query._and.push(processed)
      }
    }

    // queryが空の場合には{}を返す
    if (query._and.length === 0) {
      return {}
    } else {
      return query
    }
  }
}
