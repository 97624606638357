<template>
  <BButton :variant="variant" @click="save">
    <slot>{{ label }}</slot>
  </BButton>
</template>
<script lang="ts">
import { ColumnDefByColName } from '../../../../common/$models/ModelDef'
import { registerComposableComponentSettings } from '../../../ComposableComponents'
import { inject } from 'vue'
import { CCModelFormService } from '../CCModelFormService'
import { normalAndOutlinedVariantsKeys } from '../../../ComposableComponents/composableComponentHelpers/variants'
import { codeInputCommonAttrs } from '../../../../common/$models'

export const configColumns: ColumnDefByColName = {
  label: {
    type: 'STRING',
    label: 'ラベル',
  },
  variant: {
    label: 'スタイル',
    type: 'STRING',
    selections: () => normalAndOutlinedVariantsKeys,
  },
  isConfirmMessage: {
    label: '保存前確認メッセージを利用しない',
    type: 'BOOLEAN',
  },
  confirmMessageWithHtml: {
    label: '確認メッセージ',
    type: 'TEXT',
    inputAttrs: {
      rows: 3,
      ...codeInputCommonAttrs,
    },
  },
}
const name = 'CCModelFormSaveButton'
registerComposableComponentSettings(name, {
  label: name,
  hasDefaultSlot: true,
  category: 'フォーム',
  configColumns: configColumns,
  defaultProps: {
    label: '保存する',
    variant: 'primary',
    isConfirmMessage: true,
    confirmMessageWithHtml: '',
  },
})

export default {
  name,
  props: {
    label: {
      type: String,
      required: true,
    },
    variant: {
      type: String,
      required: true,
    },
    confirmMessageWithHtml: {
      type: String,
    },
    isConfirmMessage: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    return {
      CCModelFormServiceInstance: inject('CCModelFormServiceInstance') as CCModelFormService,
    }
  },
  methods: {
    save() {
      this.CCModelFormServiceInstance.saveData(this.confirmMessageWithHtml, this.isConfirmMessage)
    },
  },
}
</script>
