<template>
  <div class="open-d-admin-with-iframe" :style="wrapperStyle" v-if="shouldOpenDAdmin">
    <div
      v-if="showCloseButton"
      @click="() => (shouldOpenDAdmin = false)"
      class="btn btn-secondary"
      style="position: fixed; right: 0; top: 0;"
    >
      CLOSE
    </div>
    <iframe
      style="width: 100%;min-height: 100vh; background-color: white"
      :src="iframeSrc"
      frameborder="0"
    ></iframe>
  </div>
</template>
<script>
export default {
  props: {
    pagePath: { required: true, type: String },
    showCloseButton: { default: false, type: Boolean },
    fullOverlay: { default: false, type: Boolean },
  },
  data() {
    return {
      shouldOpenDAdmin: true,
    }
  },
  computed: {
    iframeSrc() {
      return `${$core.$d.url}${$core.$d.url.endsWith('/') ? '' : '/'}${
        this.pagePath
      }?body-attr=open-from-core`
    },
    wrapperStyle() {
      if (this.fullOverlay) {
        return {
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'white',
        }
      }
      return {}
    },
  },
}
</script>
