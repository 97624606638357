export const commonColumns = Object.freeze(['createdAt', 'updatedAt', '作成日時', '更新日時'])

export const inputTypes = Object.freeze({
  STRING: 'string',
  TEXT: 'textarea',
  RICHTEXT: 'richtext',
  DATEPICKER: 'datepicker',
  DATETIMEPICKER: 'datetimepicker',
  NUMBER: 'number',
  CHECKBOX: 'checkbox',
  BOOLEAN: 'checkbox',
  SELECT: 'select',
  MULTISELECT: 'multiselect',
  REFERENCE: 'reference',
  ARRAY_OF_OBJECT: 'arrayOfObject',
  FILE: 'file',
  TIME: 'timepicker',
  JSON: 'JSON',
})

export const colTypeInputTypeMap = Object.freeze({
  STRING: inputTypes.STRING,
  TEXT: inputTypes.TEXT,
  RICHTEXT: inputTypes.RICHTEXT,
  CITEXT: inputTypes.STRING,
  SELECT: inputTypes.SELECT,
  INTEGER: inputTypes.NUMBER,
  NUMBER: inputTypes.NUMBER,
  BIGINT: inputTypes.NUMBER,
  JSON: inputTypes.JSON,
  FLOAT: inputTypes.NUMBER,
  REAL: inputTypes.STRING,
  DOUBLE: inputTypes.NUMBER,
  DECIMAL: inputTypes.NUMBER,
  DATE: inputTypes.DATETIMEPICKER,
  DATEONLY: inputTypes.DATEPICKER,
  BOOLEAN: inputTypes.CHECKBOX,
  ENUM: inputTypes.SELECT,
  ARRAY: inputTypes.STRING,
  JSONB: inputTypes.STRING,
  BLOB: inputTypes.STRING,
  UUID: inputTypes.STRING,
  CIDR: inputTypes.STRING,
  INET: inputTypes.STRING,
  MACADDR: inputTypes.STRING,
  RANGE: inputTypes.STRING,
  GEOMETRY: inputTypes.STRING,
  REFERENCE: inputTypes.REFERENCE,
  ARRAY_OF_OBJECT: inputTypes.ARRAY_OF_OBJECT,
  FILE: inputTypes.FILE,
  MULTISELECT: inputTypes.MULTISELECT,
  TIME: inputTypes.TIME,
})

/* Date, year & months */
const todayObject = new Date()

function calcMonthFromToday(offset) {
  const dateObject = new Date(todayObject.getFullYear(), todayObject.getMonth() + offset, 1)
  return `${dateObject.getFullYear()}${('0' + (dateObject.getMonth() + 1)).slice(-2)}` // YYYYMM
}

const dateObj = {
  get today() {
    return `${todayObject.getFullYear()}-${('0' + (todayObject.getMonth() + 1)).slice(-2)}-${(
      '0' + todayObject.getDate()
    ).slice(-2)}`
  },
}
export const today = dateObj.today
export const thisMonthYYYYMM = dateObj.today.replace(/(\d{4})-(\d{2}).+/, '$1$2')
export const thisMonthFirstDate = dateObj.today.replace(/(\d{4})-(\d{2}).+/, '$1-$2-01')

export const nextMonthYYYYMM = calcMonthFromToday(1)
export const nextNextMonthYYYYMM = calcMonthFromToday(2)

export const prevMonthYYYYMM = calcMonthFromToday(-1)
export const prevPrevMonthYYYYMM = calcMonthFromToday(-2)

export const CORE_LOGO = `

=================================
=== HELLO FROM CORE Framework ===
=================================

`
