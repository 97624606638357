import { ColumnDefByColName } from '../../types'
import { ModelFactory } from '../../common/$models'
import { loadXlsxLib } from './loadXlsxLib'
// import * as XLSXType from '../../../libs/SheetjsPro/edit/types'

let XLSX: any = null
let Flat: any = null
const initLib = async () => {
  if (!XLSX) {
    XLSX = await loadXlsxLib()
  }
  if (!Flat) {
    Flat = (await import(/* webpackChunkName: 'flat' */ 'flat')).flatten
  }
}

const ignoreFromExportColumnTypes = [
  'ARRAY_OF_OBJECT', // flatternされるので無視
]
const skipExportColumnNames = ['objectID'] // , 'createdAt', 'updatedAt', '作成日時', '更新日時'

// nestedCol.0.name のようなpathのlabelを取得する
export const getColumnLabelByDottedNestedPath = (
  dottedColName: string,
  colDefs: ColumnDefByColName,
  res = '',
) => {
  const paths = dottedColName.split('.')
  for (let i = 0; paths.length > i; i++) {
    const colName = paths[i]
    const col = i === 0 ? colDefs[colName] : colDefs[paths[0]]?.columns?.[colName] || null
    const label = col?.label || colName
    if (label) {
      res += (res ? '.' : '') + label
    }
  }
  return res
}

const getFallbackExportFilename = () => `エクスポート_${$core.$dayjs().format('YYYY-MM-DD_HHmmss')}`

/**
 * # $core.$exportAsExcel
 * - `ARRAY_OF_OBJECT` と `Table` からExcelデーターを出力するオブジェクトリテラル
 */
export const $exportAsExcel = {
  /**
   * @hidden
   */
  get XLSX() {
    return XLSX
  },
  /**
   * @hidden
   */
  async initLib() {
    await initLib()
  },
  /**
   * `ARRAY_OF_OBJECT`から`Excel`ファイルを生成するための非同期メソッドです。
   *
   * 様々なオプションを提供してカスタマイズ可能です。
   * @param data Excelファイルにエクスポートしたいデータを含むオブジェクトの配列
   * @param sheetName シート名
   * @param filename ファイル名
   * @param headerRow ヘッダー行
   * @param modelName
   * @param flatternNestedData
   * @param returnWorkSheetObject
   * @param exportFormatType
   */
  fromArrayOfObject: async ({
    data,
    sheetName = 'Sheet1',
    filename = 'exported',
    headerRow = null,
    modelName = null,
    flatternNestedData = true,
    returnWorkSheetObject = false,
    exportFormatType = 'xlsx',
  }: {
    data: Record<string, any>[]
    sheetName?: string
    filename?: string
    headerRow?: string[] | null
    modelName?: string | null
    flatternNestedData?: boolean
    returnWorkSheetObject?: boolean
    exportFormatType?: 'xlsx' | 'csv'
  }) => {
    await initLib()
    const headerRowsAsObj = (headerRow || []).reduce((res, r) => {
      res[r] = true
      return res
    }, {})
    try {
      if (flatternNestedData) {
        data = data.map(d => {
          const flatted = Flat(d)
          Object.keys(flatted).map(key => {
            if (skipExportColumnNames.indexOf(key) >= 0) {
              delete flatted[key]
            }
            headerRowsAsObj[key] = true
          })
          return flatted
        })
      }
      headerRow = Object.keys(headerRowsAsObj)
      if (modelName && globalThis.$core.$models[modelName]) {
        // @ts-ignore
        const model = globalThis.$core.$models[modelName] as ModelFactory
        // headerRow 並び替え: Alphabet順で整形した上で、colNames順にする
        headerRow = headerRow
          .filter(
            colName =>
              skipExportColumnNames.indexOf(colName) === -1 &&
              ignoreFromExportColumnTypes.indexOf(model.columns[colName]?.type) === -1,
          )
          .sort((a, b) => a.localeCompare(b)) // Alphabet順

        if (model) {
          // TODO: Too dirty...
          headerRow = headerRow.sort((a, b) => {
            if (a === 'id') {
              return -1
            }
            if (b === 'id') {
              return 1
            }
            const indA = model.colNames.indexOf(a)
            const indB = model.colNames.indexOf(b)
            if (indA === -1 && indB === -1) {
              return 0
            }
            if (indA === -1 && indB !== -1) {
              return 1
            }
            if (indA !== -1 && indB === -1) {
              return -1
            }
            return indA - indB > 0 ? 1 : -1
          })
        }
        // 1行目にカラム定義のlabel を利用する
        const labelRow = headerRow.reduce((res, colName) => {
          if (model.colLabels[colName]) {
            res[colName] = model.colLabels[colName]
          } else if (colName.indexOf('.') >= 0) {
            res[colName] = getColumnLabelByDottedNestedPath(colName, model.columns)
          }
          return res
        }, {})
        data = [labelRow, ...data]
      }
      const ws = XLSX.utils.json_to_sheet(data, {
        header: headerRow,
        skipHeader: false,
      })
      // ワークシートオブジェクトのみ返却パターン
      if (returnWorkSheetObject) {
        return ws
      }
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, sheetName.slice(0, 31))
      XLSX.writeFile(wb, `${filename || getFallbackExportFilename()}.${exportFormatType}`)
    } catch (e) {
      console.error(e)
      if(e.message.indexOf('Text length must not exceed') >= 0) {
        $core.$toast.errorToast('エクスポートするデータが大きすぎます。1セルに入力できる 最大文字数 (32,767文字) を超えています。')
      } else {
        $core.$toast.errorToast(`エクスポートに失敗しました。 (Error: ${e.message})`)
      }
      debugger
      throw e
    } finally {

    }
  },
  async fromTableElement({ id = null, filename, element = null, cellStyles = false }) {
    await initLib()
    if (!element) {
      element = window.document.getElementById(id)
    }
    if (!element) {
      globalThis.$core.$toast.errorToast(`Table要素が見つかりませんでした。 id: ${id}`)
      return
    }
    // TODO: cellStyles, 各tablecellに対しては、computed style (props) を参照すればより良くなるはずだ
    XLSX.writeFile(
      XLSX.utils.table_to_book(element, {
        display: true,
        rawDates: true,
      }),
      `${filename || id || getFallbackExportFilename()}.xlsx`,
      { cellStyles },
    )
  },
}
export type ExportAsExcel = typeof $exportAsExcel
