<template>
  <div class="filter-item-append" style="position: relative">
    <b-button
      size="sm"
      variant="outline-secondary"
      class="_rounded-pill"
      @click.prevent="() => toggleModal()"
    >
      <Ficon type="filter" style="font-size: 1.3em; margin: 0 -1px" />
      <span v-if="addFilterButtonText" class="pl-1">{{ addFilterButtonText }}</span>
    </b-button>
    <div
      class="dropdown-menu position-absolute p-2 border-0 shadow-lg"
      style="max-width: 520px; top: 32px"
      :style="toggle ? 'display: block;' : 'display: none;'"
    >
      <input
        type="text"
        class="form-control form-control-sm"
        :value="columnSearchStr"
        ref="columnSearchStrInput"
        @input="changeColumnSearchStr"
        placeholder="項目名で検索..."
      />
      <div
        class="pt-1 small"
        style="border-bottom: 1px solid #ccc; max-height: 400px; overflow: visible"
      >
        <el-cascader-panel
          v-if="Object.keys(filteredColumns).length > 0"
          v-model="value"
          :options="options"
          @change="handleChange"
          style="background: white"
        />
        <div class="pb-1" v-else>該当なし</div>
      </div>
      <div
        @click="appendModernFilter"
        class="d-flex align-items-center mt-1 hover-opacity"
        style="height: 26px; font-size: 0.9em"
        role="button"
      >
        <Ficon type="sort-amount-up" class="mx-1" />
        <strong
          >高度なフィルター{{
            !FilterControlsServiceInstance.filterGroupService ? 'を追加' : ''
          }}</strong
        >
        <span
          v-if="
            !!FilterControlsServiceInstance.filterGroupService &&
            Object.keys(FilterControlsServiceInstance.filterGroupService.filterObjects).length
          "
          class="badge border-rounded bg-secondary ml-2"
          >{{
            Object.keys(FilterControlsServiceInstance.filterGroupService.filterObjects).length
          }}件</span
        >
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { inject } from 'vue'
import {
  FilterControlsService,
  FilterColumnSelection,
  getChildrenOptions,
} from '../../FilterControlsService'
import { ColumnTypes } from '../../../../../common/$models/ModelDef'
import { ElCascaderPanel } from 'element-plus'
import { getColLabels } from '../../FilterItemService'
export default {
  name: 'FilterItemAppend',
  components: {
    ElCascaderPanel,
  },
  props: {
    addFilterButtonText: {
      type: String,
      default: 'フィルターを追加',
    },
  },
  data() {
    return {
      toggle: false,
      columnSearchStr: '',
      value: [],
    }
  },
  setup() {
    return {
      FilterControlsServiceInstance: inject<FilterControlsService>('FilterControlsServiceInstance'),
    }
  },
  computed: {
    options(): FilterColumnSelection[] {
      if (!this.filteredColumns) return []
      return Object.keys(this.filteredColumns).map((key) => {
        const column = this.filteredColumns[key]
        const shouldHaveChildren = this.hasChildrenColTypes.indexOf(column.type) >= 0
        const options = {
          value: column.name || column.key || key,
          label: column.label || key,
          children: shouldHaveChildren ? getChildrenOptions(column) : [],
        }
        return options
      })
    },
    hasChildrenColTypes() {
      return [
        ColumnTypes.RelationshipManyToOne,
        ColumnTypes.RelationshipOneToMany,
        ColumnTypes.RelationshipManyToAny,
      ]
    },
    columns() {
      return this.FilterControlsServiceInstance.columns
    },
    filteredColumns() {
      if (!this.columns) return {}
      if (this.columnSearchStr === '') return this.columns
      return Object.keys(this.columns).reduce((acc: any, key: string) => {
        const column = this.columns[key]
        if (column.label?.includes(this.columnSearchStr) || key.includes(this.columnSearchStr)) {
          acc[key] = column
        }
        return acc
      }, {})
    },
  },
  methods: {
    /**
     * カラムが選択された場合に kick される。
     * - Nest した 選択である場合があるため、ColumnName の 配列で渡される。
     */
    handleChange(selectedColumnPath: string[] | string) {
      if (typeof selectedColumnPath === 'string') {
        selectedColumnPath = [selectedColumnPath]
      }
      this.appendFilterItem(selectedColumnPath)
      this.value = []
    },
    toggleModal() {
      this.toggle = !this.toggle
      if (this.toggle) {
        // カラム選択を開いたら検索文字列input へ 自動フォーカス
        this.$nextTick(() => {
          this.$refs.columnSearchStrInput.focus()
        })
      } else {
        // カラム選択を閉じたら検索文字列をクリア
        this.columnSearchStr = ''
      }
    },
    closeAppendModal() {
      this.toggle = false
    },
    appendFilterItem(colNamePath: string[]) {
      this.FilterControlsServiceInstance.appendFilterItem(colNamePath)
      this.toggle = false
    },
    appendModernFilter() {
      this.FilterControlsServiceInstance.appendModernFilter()
      this.closeAppendModal()
    },
    changeColumnSearchStr(event) {
      this.columnSearchStr = event.target.value
    },
  },
}
</script>
