import { RecordNotFoundError } from './'
import { CoreBaseError } from './'
import { NoNeedReportError } from './NoNeedReportError'

/**
 *
 */
export const $frontErrors = {
  CoreBaseError,
  RecordNotFoundError,
  NoNeedReportError,
}
