import { AppMenuItem } from './appMenuDefinitionParser'
import {
  absoluteHrefBase,
  generateAppLink,
} from '../../plugins/AppDefinitions/front/AppDefinitionLoaderService'

/**
 * 他のアプリがある場合, or isAdmin の場合に、リンクを生成
 */
export const generateSidebarLinksToOtherApps = (
  { skipAdminAppLink } = { skipAdminAppLink: false },
): AppMenuItem[] => {
  // isAdminでなく、現在のアプリのみであれば表示する必要はないので
  const otherAppDefs = Object.values($core.$appDefinitionLoader.appKeysOfCurrentUserHasPrivs || {})
  if (otherAppDefs.length <= 1 && $core.$embAuth.user?.isAdmin !== true) {
    return []
  }
  const appLinkMenus: AppMenuItem[] = [
    {
      type: 'heading',
      label: $core.$dict.get('appMenuItem.heading', 'アプリケーション一覧'),
    },
  ]
  otherAppDefs.map(appDef => {
    if (appDef.key === $core.$appDefinitionLoader.appDefinition?.key) {
      return // 現在のアプリはSkip
    }
    appLinkMenus.push({
      type: 'component',
      componentDefinition: generateLinkComponentDef(
        generateAppLink(appDef.key),
        appDef.name,
        'nav-link',
        'external-link-alt',
      ),
    })
  })
  if ($core.$embAuth.user?.isAdmin && skipAdminAppLink !== true) {
    appLinkMenus.push({
      type: 'component',
      componentDefinition: generateLinkComponentDef(
        `${absoluteHrefBase}/`,
        '管理アプリ',
        'nav-link',
        'cog',
      ),
    })
  }
  return appLinkMenus
}

export const generateLinkComponentDef = (linkTarget, label, cssClass = '', iconClass = '') => {
  return {
    template: `<a class='${cssClass}' href="${linkTarget}">${
      iconClass ? `<ficon class="mr-2 d-inline-block" type="${iconClass}"></ficon>` : ''
    }${label}</a>`,
  }
}
