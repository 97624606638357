<template>
  <b-form-input
    v-bind="commonAttrs"
    :modelValue="modelValue"
    :lazy="true"
    :lazy-formatter="true"
    @update:modelValue="changeValue"
  />
</template>
<script lang="ts">
import { PropType } from 'vue'

export default {
  name: 'TextInput',
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    commonAttrs: {
      type: Object as PropType<Record<string, unknown>>,
      required: false,
      default: () => ({}),
    },
  },
  methods: {
    changeValue(val) {
      this.$emit('update:modelValue', val)
    },
  },
}
</script>
