/**
 * Javascriptコードを入力するcolumnの共通props
 */
export const codeInputCommonAttrs = {
  wrapperClass: 'col-12',
  class: '',
  rows: 8,
  style: 'font-family: monospace, monospace; letter-spacing: .05em;',
  codeEditor: true,
}
