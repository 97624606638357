/**
 *
 */
export const variants = {
  primary: {
    name: 'primary',
  },
  secondary: {
    name: 'secondary',
  },
  success: {
    name: 'success',
  },
  danger: {
    name: 'danger',
  },
  warning: {
    name: 'warning',
  },
  info: {
    name: 'info',
  },
}
export const outlinedVariants = Object.keys(variants).reduce((acc, key) => {
  acc[`outline-${key}`] = {
    name: `outline-${key}`,
  }
  return acc
}, {})
export const normalAndOutlinedVariants = {
  ...variants,
  ...outlinedVariants,
}

export const normalAndOutlinedVariantsKeys = Object.keys(normalAndOutlinedVariants)
