/**
 * Error capture を有効化 with appHook
 */
export const addCoreGlobalHooksOnError = () => {
  if (process.env.NODE_ENV === 'development') {
    return
  }
  // Error handling(s), appHook で挙動変更可能
  $core.VueClass.config.errorHandler = async function(err, vm, info) {
    console.error(`[errorHandlers - VueApp.config.errorHandler] error on ${vm?.$options?.__file}`, {
      err,
      vm,
      info,
    })
    try {
      $core.$appHook.emit('$core.front.error', err)
      $core.$appHook.emit('$core.front.error.vue', { err, vm, info })
    } catch (e) {
      $core.$errorReporter.r(e, this)
    }
  }
  window.addEventListener('unhandledrejection', function(err) {
    console.error(`[errorHandlers - window.addEventListener('unhandledrejection')]`, err)
    try {
      $core.$appHook.emit('$core.front.error', err)
    } catch (e) {
      console.error(e)
      $core.$errorReporter.r(e, this)
    }
  })
  window.addEventListener('error', function(err) {
    console.error(`[errorHandlers - window.addEventListener('error')]`, err)
    try {
      $core.$appHook.emit('$core.front.error', err)
    } catch (e) {
      console.error(e)
      $core.$errorReporter.r(e, this)
    }
  })
}
