const lsCacheKey = '$core.i18n.locale'

type LangConfig = {
  value: string
  label: string
  /**
   * ブラウザ言語表現としての値, 複数指定可能
   * 例: ['en-US', 'en-GB', 'en']
   */
  langKeys: string[]
}

/**
 * # $core.$i18n
 *
 * 言語切替を実施するサービス。
 * - 本サービス `$i18n` は言語切替・選択肢の設定のみを実行します。言語切替に伴うテキストの設定は `$core.$dict` (DictionaryService) 及び各種設定オブジェクト ($models 等) のマージにて実現されます。
 * - 初期状態では、ブラウザの言語設定を参照し、言語設定が存在しない場合は、`$i18n.defaultLang` が選択されます。一度選択した言語設定は、ブラウザの LocalStorage に記憶されます。
 *
 * ## 設定例
 * - プロジェクト側の `front/main.ts` にて
 * ```ts
 * // 言語切替を有効化 (サイドバー内に、言語切替ボタンが表示されます。デフォルトは無効)
 * $core.$configVars.set('i18n.enabled', true)
 *
 * // 言語切替対象を設定
 * $core.$i18n.setSelectableLangs([
 *   {
 *     // 言語切替ボタンに表示されるラベル
 *     label: '日本語',
 *     // 言語切替のkeyとなる値
 *     value: 'ja',
 *     // ブラウザの言語判定(window.navigator.language) に対応する値
 *     langKeys: ['ja', 'ja-JP'],
 *   },
 *   {
 *     label: 'English',
 *     value: 'en',
 *     langKeys: ['en', 'en-US', 'en-GB', 'en-AU', 'en-CA', 'en-NZ', 'en-ZA'],
 *   },
 *   {
 *     label: '中文',
 *     value: 'zh',
 *     langKeys: ['zh', 'zh-CN', 'zh-HK', 'zh-MO', 'zh-SG', 'zh-TW'],
 *   }
 * ])
 *
 * // デフォルトフォールバック表示言語を 英語 にしたい場合
 * // $core.$i18n.defaultLang = 'en'
 *
 * // 言語切替が走ったら、browser reload する設定を付与 (model定義等オブジェクトに設定しているテキストを再ロードする等含めreload)
 * $core.$appHook.on('i18n:localeChanged', async ({lang}) => {
 *   return new Promise((resolve) => {
 *     console.log(`i18n:localeChanged: ${lang}`)
 *     window.location.reload()
 *     // resolve しない
 *   })
 * })
 *
 * // 言語毎の設定内容を付与
 * // 英語の設定
 * const loadEnDictionary = () => {
 *   $core.$dict.configure({
 *     // 書き換え可能なテキストのキーは、 CORE/src/common/$dict/coreDictTemplate.ts に記載されています。
 *     "exportData.title": "Export Data",
 *     "exportData.linkToExport": "Manage Export Settings",
 *     "ChangeUserEmail.currentMail": "Current Email",
 *     "ChangeUserEmail.editMail": "Change Email",
 *     "ChangeUserEmail.currentPassword": "Current password",
 *   })
 *   // Model定義などの翻訳を付与
 *   $core.$utils.registerFunctionLoggedInExecuteOnce({
 *     appHookFunctionRegisterKey: 'i18n:localeChanged.en',
 *     onceLoadFunction: async () => {
 *       $core.$models = $core.$utils.deepmerge($core.$models, {
 *         // モデル毎に、翻訳したい項目を設定
 *         someModelName: {
 *           tableLabel: 'Select options master',
 *           // カラムも設定
 *           columns: {
 *             group: {
 *               label: 'Group',
 *               inputHelpText: 'Select one or input new group',
 *             },
 *           }
 *         },
 *         anotherModelName: {
 *           // ...
 *         }
 *       })
 *       // $core.$virtualModels = $core.$utils.deepmerge($core.$virtualModels, {...})
 *     }
 *   })
 * }
 *
 * const loadZhDictionary = () => {
 *   // ... 中国語用の言語設定を記述
 * }
 *
 * if($core.$i18n.locale === 'en') {
 *   loadEnDictionary()
 * } else if($core.$i18n.locale === 'zh') {
 *   // 中国語でのタイトル
 *   loadZhDictionary()
 * }
 * ```
 *
 * @coreDocPath $core/40.customize/404.i18n
 */
export class I18nService {
  locale = ''
  defaultLocale: string = 'ja_JP'
  allowedLangs: LangConfig[] = []

  constructor() {
    // Browser or Server
    const isBrowser = typeof window !== 'undefined'
    if (isBrowser) {
      setTimeout(() => {
        this.init()
      }, 10)
    }
  }

  /**
   * $core.$lsCache を利用して、現在の言語設定を取得, fallback は 'ja_JP'
   */
  init() {
    const locale = $core.$lsCache.get(lsCacheKey)
    if (locale) {
      this.locale = locale
    }
  }

  /**
   * 言語設定を変更後、 `$appHook.emit('i18n:localeChanged', { lang }` で hook を発火します
   * @param lang
   */
  setLang(lang: string) {
    $core.$lsCache.set(lsCacheKey, lang)
    this.locale = lang
    $core.$appHook.emit('i18n:localeChanged', { lang })
  }

  /**
   * 言語選択可能なリストをセットするメソッド
   * 通常, フロントアプリケーション初期化時(`front/main.ts`) に1度だけ実行します
   * @param langs
   */
  setSelectableLangs(langs: LangConfig[]) {
    this.allowedLangs = langs
    // 言語が指定されていない場合
    if (!this.locale) {
      this.setLangWithDetectBrowserLang()
    }
  }

  /**
   * ブラウザの言語設定を元に、言語を設定するメソッド
   * @private
   */
  private setLangWithDetectBrowserLang() {
    const browserLang = window.navigator.language
    // browserLang で this.allowedLangs を検索, 引き当たらなかった場合は、デフォルトをsetする
    const detectedLang =
      this.allowedLangs.find(lang => lang.langKeys.indexOf(browserLang) > -1)?.value ||
      this.defaultLocale
    this.setLang(detectedLang)
  }
}

export const $i18n = new I18nService()
