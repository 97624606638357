<template>
  <app-hookable-component resolve-hook-name="$CORE.pages.signIn">
    <div class="d-flex justify-content-center h-100">
      <div class="col-12 col-sm-10 col-md-5" style="margin-top: 28vh">
        <div v-if="!resetToken">
          <!--SignIn Component-->
          <div class="card" v-if="!showRequestPasswordResetComponent">
            <h5 class="card-header">ログイン</h5>
            <div class="card-body">
              <slot name="cardBodyBeforeContent"></slot>
              <div>
                <div class="my-3 row">
                  <div class="text-right col-sm-4">
                    <label>メールアドレス:</label>
                  </div>
                  <div class="col-sm-8">
                    <input v-model="email" class="form-control" placeholder="you@yourcompany.com" />
                  </div>
                </div>
                <div class="my-3 row">
                  <div class="text-right col-sm-4">
                    <label>パスワード:</label>
                  </div>
                  <div class="col-sm-8">
                    <input v-model="password" class="form-control" type="password" placeholder="" />
                  </div>
                </div>
                <div class="text-center mt-3">
                  <b-button
                    variant="primary"
                    v-single-click="() => $core.$embAuth.signIn({ email, password })"
                    >ログイン
                  </b-button>
                </div>
              </div>
              <slot name="cardBodyAfterContent"></slot>
            </div>
          </div>
          <!-- TODO: control enabling with env var -->
          <div v-if="$core.$configVars.get('passwordResetEnabled', false)" class="text-right mt-3">
            <a href="#" class="small" v-single-click="() => $refs.passwordResetRequestModal.show()"
              >パスワードを忘れた場合</a
            >
          </div>
          <b-modal
            ok-only
            ok-title="もどる"
            ok-variant="outline-secondary"
            centered
            title="パスワードをリセット"
            ref="passwordResetRequestModal"
          >
            <PasswordResetRequest
              @closeModal="$refs.passwordResetRequestModal.hide()"
              :emailValue="email"
            />
          </b-modal>
        </div>

        <!--Reset Password Component-->
        <div v-else class="card">
          <h5 class="card-header">パスワード再設定</h5>
          <div class="card-body">
            <div class="row">
              <div class="text-right col-sm-4">
                <label for="pwd">新しいパスワード</label>
              </div>
              <div class="col-sm-8">
                <input
                  class="form-control"
                  id="pwd"
                  name="password"
                  type="password"
                  v-model="newPassword"
                />
                <p class="small">半角英数を含む8文字以上30文字以下でご入力ください。</p>
              </div>
              <div class="text-right col-sm-4">
                <label for="pwd2">新しいパスワード(再入力)</label>
              </div>
              <div class="col-sm-8">
                <input
                  class="form-control"
                  id="pwd2"
                  name="password"
                  type="password"
                  v-model="newPasswordRepeated"
                />
              </div>
              <div class="text-center mt-3 mb-2">
                <b-button variant="primary" v-single-click="() => tryToReset()"
                  >パスワードリセットを実行
                </b-button>
              </div>
            </div>
            <div class="text-center">
              <router-link :to="{ name: 'signIn' }">ログインへ</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </app-hookable-component>
</template>

<script lang="ts">
import { passwordValidator } from '../../common/ValidationService/validator'
import PasswordResetRequest from '../Users/PasswordResetRequest.vue'

type State = {
  email: string
  password: string
  showRequestPasswordResetComponent: boolean
  newPassword: string
  newPasswordRepeated: string
}
export default {
  components: { PasswordResetRequest },
  layout: 'auth',
  name: 'SignInPage',
  data(): State {
    return {
      email: '',
      password: '',
      showRequestPasswordResetComponent: $core.$configVars.get(
        'showRequestPasswordResetComponent',
        false,
      ),
      newPassword: '',
      newPasswordRepeated: '',
    }
  },
  computed: {
    resetToken() {
      return this.$route.query.token
    },
  },
  mounted() {
    // ログインしていたら、ホームへリダイレクト
    if ($core.$embAuth.isLoggedIn) {
      this.$router.push('/')
    }
  },
  methods: {
    async tryToReset() {
      if (this.newPassword !== this.newPasswordRepeated) {
        $core.$toast.errorToast(`再入力パスワードが合致しません。`)
        return
      }
      if (!passwordValidator(this.newPassword)) {
        $core.$toast.errorToast(`半角英数を含む8文字以上30文字以下でご入力ください。`)
        return
      }
      $core.$loading.start('')
      try {
        await $core.$d.transport.post('/users/verifyTokenAndChangePassword', {
          newPassword: this.newPassword,
          resetToken: this.resetToken,
        })
        $core.$toast.successToast('パスワードを設定しました。')
        $core.$loading.finish()
        $core.$router.push({ name: 'signIn' })
      } catch (e) {
        $core.$errorReporter.r(e, this)
        $core.$toast.errorToast('パスワード再設定に失敗しました。')
      }
    },
  },
}
</script>

<style scoped>
span:hover {
  cursor: pointer;
}
</style>
