import { reactive, defineAsyncComponent, markRaw } from 'vue'
import { CoreFrontendFrameworks } from '../../types'
import { singletonInstanceSummoner } from '../../common/singletonInstanceSummoner'

export const makeItReactive = <T>(obj): T => {
  return reactive(obj)
}

/**
 * Vue, React, etc に依存しない、Framework Util functions をまとめたもの
 */
export class FrameworkFrameworkApiRefForCoreFramework {
  fw: CoreFrontendFrameworks

  constructor(fw: CoreFrontendFrameworks = 'vue') {
    this.fw = fw
  }

  /**
   * Reactivity を付与する
   */
  makeItReactive = makeItReactive

  markRaw(maybeObject) {
    if (typeof maybeObject !== 'object') {
      return maybeObject
    }
    return markRaw(maybeObject)
  }
  get makeItNonReactive() {
    return this.markRaw
  }

  /**
   * defineAsyncComponent
   */
  get defineAsyncComponent(): (Function) => any {
    return defineAsyncComponent
  }

  /**
   * Reactivity keep しつつ、新しいpropertyを付与する
   */
  set(obj, key, val) {
    obj[key] = val
    return obj
  }

  static get instance() {
    return singletonInstanceSummoner('FrameworkUtils', FrameworkFrameworkApiRefForCoreFramework, [
      (process.env.CORE_FW_FRONTEND_FRAMEWORK as CoreFrontendFrameworks) || 'vue',
    ])
  }
}

globalThis.$frontendFrameworkApiRefForCoreFramework =
  FrameworkFrameworkApiRefForCoreFramework.instance
globalThis.$frameworkUtils = globalThis.$frontendFrameworkApiRefForCoreFramework

export const $frameworkUtils = globalThis.$frontendFrameworkApiRefForCoreFramework
