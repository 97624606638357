import { ColumnTypes } from '../$models/ModelDef'
// getRelationColumnLabelAndKey の 再帰的回数の上限
const MAX_RECURSIVE_COUNT_FOR_getRelationColumnLabelAndKey = 5

export const getRelationColumnLabelAndKey = (
  colData: Record<string, any>, // (ColumnDef | DBDefinedModelDefinitionColumnsColumns),
  colKey: string,
  colLabel: string,
  recursiveCount = 0,
): {
  label: string
  key: string
}[] => {
  const collectionName =
    colData.relationshipManyToOne_collectionName || colData.relationshipManyToOne?.collectionName
  if (colData.type !== ColumnTypes.RelationshipManyToOne || !collectionName) {
    return []
  }
  const res = []
  // リレーション先配下の全てのカラムを展開する
  res.push({
    key: `${colKey}.*`,
    label: `${colLabel} > * (全てのカラム)`,
  })
  // カラムタイプがRelationManyToOneの場合には再帰的に処理する
  const columns = $core.$models[collectionName].columns
  for (const [key, value] of Object.entries(columns)) {
    if (value.type === ColumnTypes.RelationshipManyToOne && recursiveCount < MAX_RECURSIVE_COUNT_FOR_getRelationColumnLabelAndKey) {
      const tempRes = getRelationColumnLabelAndKey(
        value,
        `${colKey}.${key}`,
        `${colLabel} > ${value.label || key}`,
        recursiveCount + 1,
      )
      res.push(...tempRes)
    } else {
      // keyは `.`で連結する
      //labelは `>`で連結する
      res.push({
        label: `${colLabel} > ${value.label || key}`,
        key: `${colKey}.${key}`,
      })
    }
  }
  return res
}
