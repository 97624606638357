<template>
  <span
    v-if="logoImage"
    :style="`background-image: url(${$core.$imgix.minify(logoImage)})`"
    style="
                    width: 30px;
                    height: 30px;
                    margin-right: 0.5em;
                    display: inline-block;
                    background-size: contain;
                  "
  >
  </span>
  {{ appName }}
</template>
<script lang="ts">
export default {
  name: 'AppLogoAndName',
  computed: {
    logoImage() {
      return this.$core.$appDefinitionLoader.appDefinition?.logo || ''
    },
    appName() {
      return this.$core.$appDefinitionLoader.appDefinition?.name || 'CORE Framework'
    },
  }
}
</script>
