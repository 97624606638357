import { $models, $modelsLoader } from '../../common/$models'
import { directus_users } from '../Users/directus_users'
import { user_roles } from './user_roles'
import { $userRoleManager } from './$userRoleManager'

/**
 * UserRolesの挙動をInstall
 */
export const useUserRolesFeature = () => {
  if (!$models.directus_users) {
    $modelsLoader.loadModel(directus_users)
  }
  if (!$models.user_roles) {
    $modelsLoader.loadModel(user_roles)
  }
  // Add
  $core.$userRoleManager = $userRoleManager()
}
