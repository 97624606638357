import { RouteRecordRaw } from 'vue-router'
import SignIn from './signIn.vue'
import ErrorPage from './error.vue'
import Dashboard from './index.vue'
import { fallbackModelRoutes } from './_fallback_model'
import { dAdminPageRoutes } from '../../common/DirectusAdminPageIntegrator/front/dAdminPageRoutes'

/**
 * COREで定義されているデフォルトのページルート
 *
 * @coreDocPath $core/20.ui/206.router/defaultPageRoutes
 */
export const defaultPageRoutes: RouteRecordRaw[] = [
  {
    path: '/signIn',
    component: SignIn,
    name: 'signIn',
    meta: { layout: 'logged-out' },
  },
  {
    path: '/error',
    component: ErrorPage,
    name: 'error',
    meta: { layout: 'empty' },
  },
  {
    // This is duplicated but both needed to work property
    path: '/',
    name: 'default',
    component: Dashboard,
  },
  {
    // This is duplicated but both needed to work property
    path: '/',
    name: 'index',
    component: Dashboard,
  },
  {
    path: '/kanban',
    name: 'kanban',
    component: () => import('../Kanban/Kanban.vue'),
  },
  {
    path: '/composable-component-builder/:pageId?',
    props: true,
    name: 'composable-component-builder',
    component: () =>
      import(
        '../../plugins/ComposableComponentBuilder/front/components/ComposableComponentBuilder.vue'
      ),
    meta: {
      // layout: 'empty'
    },
  },
  {
    path: '/chart-builder',
    name: 'chart-builder',
    component: () => import('../Chart/builder/ChartBuilder.vue'),
    meta: {
      layout: 'empty',
    },
  },
  {
    path: '/db-direct-query-executor',
    name: 'db-direct-query-executor',
    component: () => import('../../plugins/DBDirectQueryExecutor/DBDirectQueryExecutor.vue'),
  },
  {
    path: '/app-template',
    name: 'app-template',
    component: () => import('../../plugins/AppTemplate/front/gallery.vue'),
  },
  {
    path: '/app-template-detail',
    name: 'app-template-detail',
    component: () => import('../../plugins/AppTemplate/front/template_detail.vue'),
  },
  // API Roles & Permissions
  {
    path: '/api-roles-and-permissions',
    name: 'api-roles-and-permissions',
    component: () => import('../../plugins/APIRolesAndPermissions/APIRolesAndPermissions.vue'),
  },
  {
    path: '/api-roles-and-permissions/detail',
    name: 'api-roles-detail',
    component: () => import('../../plugins/APIRolesAndPermissions/screens/RoleDetail.vue'),
  },
  {
    // 組織編集
    path: '/editOrg',
    name: 'editOrg',
    component: () => import('../../plugins/UserOrgGroupsManager/front/editOrgsAndUsers.vue'),
  },
  {
    path: '/p/:hookName',
    name: 'appHookPage',
    component: () => import('../AppHookPage/AppHookPage.vue'),
  },
  {
    path: '/doc/:series/:displayPageTitle?',
    name: 'AppDocPage',
    props: true,
    component: () => import('../../plugins/AppDocuments/front/components/AppDocPage.vue'),
  },
  {
    path: '/bulk_files',
    name: 'bulk_files',
    component: () => import('../../front/FileBulk/FileBulkAppend.vue'),
  },
  ...dAdminPageRoutes,
  ...fallbackModelRoutes,
]
