export const ModelIndexDirectusProps = {
  modelName: { required: true },
  virtualModelName: { required: false },
  filters: { required: false, default: null, type: [Object, String] },
  itemClickEvent: { required: false, default: null, type: [Function, String] },
  searchConditionSaveKey: { required: false, default: null },
  resolveHookNamePrefix: { required: false, default: '' },
  enableSort: { type: Boolean, default: true },
  defaultSort: { default: null },
  enableKeywordSearch: { type: Boolean, default: true },
  enableLimitControl: { type: Boolean, default: true },
  limitLabel: { default: '表示件数' },
  defaultLimit: { default: 100 },
  filterableColNames: { default: null, type: Array },
  enableHeader: { type: Boolean, default: true },
  enableControls: { type: Boolean, default: true },
  wrapperClass: { default: '_card' },
  /**
   * ページネーションリンクを有効化
   * default: true
   */
  enablePagination: { type: Boolean, default: true },
  listColNames: { default: null, type: Array },
  enableExcelExportButton: {
    type: Boolean,
    default: true,
  },
  enableAddNewRecordButton: { type: Boolean, default: null },
  enableFilter: { type: Boolean, default: true },
  enableFixedFilterStateDisplay: {
    type: Boolean,
    default: true,
  },
  queryFilters: { default: null, type: Object },
  urlQueryFilterChangeable: { type: Boolean, default: false },
  headerTitle: { default: null },
  initialPage: { default: 1 },
}
