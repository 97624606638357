<template>
  <!-- ここで、Disabled, non-editable な状態を作る必要はない -->
  <component
    class="modelInput"
    :model-value="calcValue"
    :is="modelInputComponent"
    :options="selections"
    :colName="colName"
    :disabled="shouldDisabled"
    :record="record"
    :recordRoot="recordRoot"
    :col="colDef"
    :customLabel="customLabel"
    :commonAttrs="commonAttrs"
    @update:modelValue="(value) => $emit('update:modelValue', value)"
    @validationError="(error) => $emit('validationError', error)"
  />
</template>
<script lang="ts">
import { ColumnDef, ComponentResolver } from '../../../../common/$models/ModelDef'
import { inputTypes } from '../../../../front/ModelForm/ModelInput'
import { PropType } from 'vue'
import { judgeModelInputComponentTypeByColDef } from '../judgeModelInputComponentTypeByColDef'

const name = 'ModelColumnInput'

/**
 * # ModelColumnInput
 *
 * ## 役割
 * - カラム定義に応じて、適切な入力コンポーネントを表示する
 * - v-model 形式で値の受け渡し仲介を実施
 * - 選択肢の生成関数をコンポーネントに提供する
 *
 */
export default {
  name,
  props: {
    colDef: {
      type: Object as PropType<ColumnDef>,
      required: true,
    },
    selections: {
      type: Array,
      required: false,
    },
    customLabel: {
      type: Function,
      required: false,
    },
    colName: {
      type: String,
      required: true,
    },
    modelValue: {
      required: true,
    },
    record: {
      type: Object,
      required: false,
      default: null,
    },
    recordRoot: {
      type: Object,
      required: false,
      default: null,
    },
    isNewRecord: {
      type: Boolean,
      required: false,
      default: false,
    },
    shouldDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    inputComponent: {
      type: Object as PropType<ComponentResolver>,
      required: false,
      default: null,
    },
    validationError: {
      type: String,
      required: false,
      default: '',
    },
    // 渡されるかもしれないし、渡されないかもしれない
    // と思ったが... inject で利用できればいいから、いいか。
    // ComposableModelInputServiceInstance: {
    //   type: Object as PropType<ComposableModelInputService> | null,
    //   required: false,
    //   default: null,
    // },
  },
  mounted() {
    console.log(this.customLabel, 'this.customLabel', this.colName)
  },
  computed: {
    calcValue() {
      if (this.modelValue !== undefined && this.modelValue !== null) return this.modelValue
      return this.record[this.colName]
    },
    isSelectInput() {
      return [inputTypes.MULTISELECT, this.inputTypes.SELECT].indexOf(this.colDef.type) >= 0
    },
    modelInputComponentTypeByColDef(): {
      component: ComponentResolver
      addProps?: Record<string, any>
    } {
      return judgeModelInputComponentTypeByColDef(this.colDef)
    },
    commonAttrs() {
      const cssClass = ['model-input', 'model-column-input']
      if (this.validationError) {
        cssClass.push('input-has-error')
      }
      if (this.colDef.inputAttrs?.class) {
        cssClass.push(this.colDef.inputAttrs.class)
      }
      if (this.shouldDisabled) {
        cssClass.push('model-input-disable-value')
        cssClass.push('model-column-input--disabled')
      }
      return Object.assign(
        this.modelInputComponentTypeByColDef.addProps || {},
        this.colDef.inputAttrs,
        { class: cssClass },
      )
    },
    modelInputComponent(): string {
      return this.inputComponent || this.modelInputComponentTypeByColDef.component
    },
  },
}
</script>
