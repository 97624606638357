// @ts-nocheck
import { singletonInstanceSummoner } from '../../common/singletonInstanceSummoner'

/**
 * # $core.$inlineCssController
 * CSS Inline style を適用するためのサービス
 *
 * ## Example
 * ```ts
 * const styleId = 'my-style'
 * $core.$styleController.applyStyle(`
 *  .my-class {
 *    color: red;
 *  }
 *  `, styleId)
 * ```
 *
 */
export class InlineCssController {
  static get instance(): InlineCssController {
    return singletonInstanceSummoner('InlineCssController', InlineCssController)
  }

  /**
   * CSS の Inline Style を適用する。
   * 既存のスタイルに追加する場合は append を true に設定する。
   * デフォルトは false で、既存のスタイルは上書きされる。
   *
   * @param cssContent 適用する CSS の内容を文字列で指定する
   * @param id スタイルを識別するための一意な ID を指定する。同じ ID で applyStyle を複数回呼び出すと、以前のスタイルは上書きされる。
   * @param append 既存のスタイルに追加する場合は true を指定する。デフォルトは false で、既存のスタイルは上書きされる。
   */
  applyStyle(cssContent, id, append = false) {
    if(!cssContent || !id) {
      console.warn('[InlineCssController] cssContent or id is not defined')
      return
    }
    const existingTag = window.document.getElementById(id)
    const styleElem = existingTag || window.document.createElement('style', { id, type: 'text/css' })
    if (!existingTag) {
      styleElem.setAttribute('id', id)
      styleElem.appendChild(window.document.createTextNode(cssContent))
      window.document.head.appendChild(styleElem)
    } else {
      styleElem.innerHTML = window.document.createTextNode(cssContent)
    }
  }

  /**
   * 指定された ID のスタイルを削除する。
   *
   * @param id 削除するスタイルの ID
   */
  removeStyle(id: string) {
    const styleElem = window.document.getElementById(id)
    if (styleElem) {
      styleElem.remove()
    }
  }
}

export const $inlineCssController = new InlineCssController()
