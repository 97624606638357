import { FindFilter } from '../../../common/$models'
import { FilterGroupService } from './FilterGroupService'
import { FilterItemService } from './FilterItemService'
import { FilterControlsService } from './FilterControlsService'

type createFilterItemFromFindFilterQueryObjectResult = {
  filterItemServices: FilterItemService<any>[]
  filterGroupService: FilterGroupService
}

/**
 * "未入力" 状態の場合において _null と _empty が同時に存在するかどうかを判定する
 * - 下記のようなオブジェクトになっている場合に true を返す
 * {
 *   "_or": [
 *     {
 *       ${colName}: {
 *         "_null": true
 *       }
 *     },
 *     {
 *       ${colName}: {
 *         "_empty": true
 *       }
 *     }
 *   ]
 * }
 */
const isNullEmptyOperator = (queryData: FindFilter, isEmptyOrNotEmpty: 'isnull' | 'isnotnull'): boolean => {
  // if (filterEmptyToNullColumnType.includes(colDefType)) {
  const queryDataKeys = Object.keys(queryData || {})
  if (queryDataKeys.length === 1 && queryDataKeys[0].startsWith('_') === false) {
    // TODO: Ambiguous logic...
    const colName = queryDataKeys[0]
    if(isEmptyOrNotEmpty === 'isnull') {
      return queryData[colName]?.['_null'] === true
    } else {
      return queryData[colName]?.['_nnull'] === true
    }
  }
  const orAndOperator = isEmptyOrNotEmpty === 'isnull' ? '_or' : '_and'
  const colName = Object.keys(queryData[orAndOperator]?.[0] || {})[0]
  if (queryData?.[orAndOperator]?.length !== 2) {
    return false
  }
  const keys = isEmptyOrNotEmpty === 'isnull' ? ['_null', '_empty'] : ['_nnull', '_nempty']
  if (!colName || !queryData[orAndOperator][0][colName]?.[keys[0]] || !queryData[orAndOperator][1][colName]?.[keys[1]]) {
    return false
  }
  return JSON.stringify(queryData[orAndOperator]) === JSON.stringify([
    { [colName]: { [keys[0]]: true } },
    { [colName]: { [keys[1]]: true } },
  ])
}

const createFilterGroup = (
  queryData: FindFilter[],
  FilterControlsService: FilterControlsService,
  isTopLevel: boolean,
  filterObjectsCount: number,
  parentGroup: FilterGroupService = null,
  filterLogic: 'and' | 'or',
): FilterGroupService => {
  const group = new FilterGroupService(
    FilterControlsService,
    isTopLevel || filterObjectsCount === 0,
    !isTopLevel && filterObjectsCount === 1,
    parentGroup,
    1,
  )
  group.filterLogic = filterLogic

  const nestedResults: (FilterItemService<any> | FilterGroupService)[] = []
  queryData.forEach((groupValue) => {
    const items = createFilterItemFromFindFilterQueryObject(
      groupValue,
      false,
      group,
      filterObjectsCount,
      FilterControlsService,
    )
    nestedResults.push(...items.filterItemServices, items.filterGroupService)
  })
  group.filterObjects = nestedResults.filter((_i) => !!_i)
  return group
}
const createFilterItem = (
  queryItem: FlatternedQueryItem,
  filterObjectsCount: number,
  parentGroup: FilterGroupService,
  FilterControlsService: FilterControlsService,
): FilterItemService<any> => {
  const filterItemType = parentGroup ? 'custom' : 'normal'
  const item = new FilterItemService({
    columnNamePathsFromRootColumnDef: queryItem.colNamePath,
    filterItemType,
    filterGroupService: parentGroup,
    filterControlsService: FilterControlsService,
    isFirstObject: filterObjectsCount === 0,
    isSecondObject: filterObjectsCount === 1,
    isFocus: false,
  })
  item.value = item.value || {
    colName: '',
    operator: '',
    value: '',
  }
  item.value.colName = queryItem.colNamePath[queryItem.colNamePath.length - 1]

  const { operator, value } = queryItem
  switch (operator) {
    case '_between':
      item.value.operator = 'between'
      item.value.value = Array.isArray(value) ? value : value?.split(',') || []
      break
    case '_eq':
      item.value.operator = 'eq'
      item.value.value = value
      break
    case '_empty':
      item.value.operator = 'isnull'
      break
    case '_nempty':
      item.value.operator = 'isnotnull'
      break
    default:
      item.value.operator = operator.substring(1)
      item.value.value = value
      break
  }

  return item
}
/**
 * FindFilter オブジェクトから FilterItemService の配列を生成する
 * - 利用ケースとしては、保存されている Filter を 高度なフィルター上で復元する場合
 *
 * ## Logic
 * - _and, _or は FilterGroupService として生成する
 * - '_' で始まらないプロパティは ネストした FilterItemService として生成する
 * - _eq, _neq, _gt, _gte, _lt, _lte, _like, _nlike, _empty, _nempty は FilterItemService として生成する
 * - _between は FilterItemService として生成するが、value は配列にする
 */
export const createFilterItemFromFindFilterQueryObject = (
  queryData: FindFilter,
  isTopLevel = false,
  parentGroup: FilterGroupService = null,
  filterObjectsCount = 0,
  FilterControlsService: FilterControlsService = null,
): {
  filterItemServices: FilterItemService<any>[]
  filterGroupService: FilterGroupService
} => {
  if (!FilterControlsService) {
    throw new Error(
      `[createFilterItemFromFindFilterQueryObject] FilterControlsService is required.`,
    )
  }
  const results: createFilterItemFromFindFilterQueryObjectResult = {
    filterItemServices: [],
    filterGroupService: null,
  }

  for (const key in queryData) {
    /**
     * _empty, _nempty の場合は、特殊な処理を行う
     */
    if (isNullEmptyOperator(queryData, 'isnull')) {
      const item = createFilterItem(
        {
          colNamePath: Object.keys(queryData['_or']?.[0] || queryData),
          operator: '_empty',
          value: true,
        },
        filterObjectsCount,
        parentGroup,
        FilterControlsService,
      )
      results.filterItemServices.push(item)
    } else if (isNullEmptyOperator(queryData, 'isnotnull')) {
      const item = createFilterItem(
        {
          colNamePath: Object.keys(queryData['_and']?.[0] || queryData),
          operator: '_nempty',
          value: true,
        },
        filterObjectsCount,
        parentGroup,
        FilterControlsService,
      )
      results.filterItemServices.push(item)
    } else if (key === '_and' || key === '_or') {
      const filterLogic = key === '_and' ? 'and' : 'or'
      results.filterGroupService = createFilterGroup(
        queryData[key],
          FilterControlsService,
        isTopLevel,
        filterObjectsCount,
        parentGroup,
        filterLogic,
        )
    } else {
      const [queryItem] = flattenFindFilterQueryObject({ [key]: queryData[key] })
      if (!queryItem || !queryItem.colNamePath || !queryItem.operator) {
        console.warn(
          `[createFilterItemFromFindFilterQueryObject] key "${key}" を復元できませんでした: `,
          JSON.stringify(queryData[key]),
        )
        continue
      }
      const item = createFilterItem(
        queryItem,
        filterObjectsCount,
        parentGroup,
        FilterControlsService,
      )
      results.filterItemServices.push(item)
    }

    filterObjectsCount++
  }
  return results
}

type FlatternedQueryItem = {
  colNamePath: string[]
  operator: string
  value: any
}

/**
 * ネストしている Filter 条件でも Flattern して key-value のオブジェクトにする function
 * ネストしているカラムかどうかは、key が '_' で始まっているかどうかで判断する
 *
 * 例:
 * { office: {name: { _eq: 'sss' }} } => [ { colNamePath: ['office','name'], operator: '_eq', value: 'sss' } ]
 * { customer: {office: {name: { _eq: 'sss' }}} } => [{ colNamePath: ['customer','office','name'], operator: '_eq', value: 'sss' }]
 */
export const flattenFindFilterQueryObject = (
  queryData: FindFilter,
  parentKeys: string[] = [],
): FlatternedQueryItem[] => {
  const result: FlatternedQueryItem[] = []

  for (const [key, value] of Object.entries(queryData)) {
    const newKeys = [...parentKeys, key]

    if (key.startsWith('_')) {
      // '_'で始まるキーはネストしていないと見なす
      result.push({
        colNamePath: parentKeys,
        operator: key,
        value,
      })
    } else if (typeof value === 'object' && value !== null) {
      // オブジェクトは再帰的に処理
      const nestedResult = flattenFindFilterQueryObject(value, newKeys)
      result.push(...nestedResult)
    } else {
      // その他の値は無視 (不正なクエリの構造になっているということでもある)
      console.warn(`[flattenFindFilterQueryObject] Invalid query structure: `, {
        parentKeys,
        value,
        queryData,
      })
    }
  }

  return result
}
