/**
 * appDefinition をLoadして、Applicationの状態を設定する
 * appDefinition の key
 */
import { fetchAppKeyFromUrl } from '../appDefinitions'
import { singletonInstanceSummoner } from '../../../common/singletonInstanceSummoner'
import { showErrorPage } from '../../../front/ErrorHandlings/showErrorPage'
import { AppDefinitionMainMenu } from '../../../front/AppMenu/appMenuDefinitionParser'
import { $inlineCssController } from '../../../front/InlineCssController/$inlineCssController'
import {
  LoggedInExecuteOnceServiceBase,
  registerFunctionLoggedInExecuteOnce,
} from '../../../front/LoggedInExecuteOnceServiceBase'
import { frontAppHooks } from '../../../front/frontAppHooks'

export class AppDefinitionLoaderService {
  appKey: string
  appDefinition: ModelAppDefinitions | null
  allAppDefinitionsByKey: {
    [appDefKey: string]: ModelAppDefinitions
  }
  loggedInExecuteOnceServiceBaseInstance: LoggedInExecuteOnceServiceBase

  constructor() {
    // ログイン後に1度だけ発火する関数を登録
    this.loggedInExecuteOnceServiceBaseInstance = registerFunctionLoggedInExecuteOnce({
      appHookFunctionRegisterKey: 'AppDefinitionLoaderService',
      onceLoadFunction: userData => {
        if (!userData) {
          return
        }
        return this._loadAppDefinition(userData)
      }, // 初回ロード時に実行する関数を登録
      initStateFunction: () => this.__initState(), // ログアウトしたときにリセットする関数を登録
      useAwaitOnLoad: true, // ロード時にawaitするかどうか ( => $core.$embAuth.user が初期化する前に実施するか、または、初期化後に実施で良い場合はfalse)
    })
  }

  __initState() {
    this.appKey = ''
    // @ts-ignore
    this.appDefinition = null
  }

  // Call from $core
  loadAppDefinitionOnSignedIn(appKey = null) {
    this.appKey = appKey || fetchAppKeyFromUrl()
  }

  async _loadAppDefinition(userData) {
    try {
      this.appDefinition = await this.__fetchAppDefinition()
    } catch (e) {
      $core.$errorReporter.r(e, this)
    }
    // UserRoles権限による判別, 権限がなければエラーページを表示
    await this._checkUserRoleRestrictions()
    if (this.appDefinition?.appMenuColor) {
      const styles = [`.core-app-definition-menu-bg-color { background: ${this.appDefinition?.appMenuColor}; }`]
      const tooLightColor = $core.$utils.getBrightnessOfColor(this.appDefinition.appMenuColor) > 155
      if (tooLightColor) {
        styles.push(`
        .core-app-menu-bg-colored-text { color: var(--bs-gray-700); }
        .sidebar > .nav .nav-item .nav-link, .sidebar > div > .nav .nav-item .nav-link, .sidebar > span > .nav .nav-item .nav-link,
        .sidebar > .nav .nav-item.nav-category,
        .sidebar > .nav .nav-item .nav-item-heading,
        .sidebar > div > .nav .nav-item.nav-category,
        .sidebar > div > .nav .nav-item .nav-item-heading,
        .sidebar > span > .nav .nav-item.nav-category,
        .sidebar > span > .nav .nav-item .nav-item-heading
        { color: var(--bs-gray-700); }
        .core-app-menu-bg-colored-border { color: var(--bs-gray-700); }
        .sidebar > .nav .nav-item.nav-category,
        .sidebar > .nav .nav-item .nav-item-heading,
        .sidebar > div > .nav .nav-item.nav-category,
        .sidebar > div > .nav .nav-item .nav-item-heading,
        .sidebar > span > .nav .nav-item.nav-category,
        .sidebar > span > .nav .nav-item .nav-item-heading { border-color: rgba(0,0,0,0.1); }
        `)
      }
      $inlineCssController.applyStyle(styles.join('\n'), 'core-app-def-color-variant')
    }
    // Add css class
    $core.$uiState.bodyClasses.push(`core-app--${this.appKey}`)
    await $core.$appHook.emit(this.hookNames.appDefinitionLoaded)
    await $core.$appHook.emit(this.hookNames.appDefinitionLoadedWithAppKey)
  }

  get hookNames() {
    return {
      appDefinitionLoaded: '$appDefinitionLoader.appDefinitionLoaded',
      appDefinitionLoadedWithAppKey: this.getAppDefinitionLoadedWithAppKey(this.appKey),
    }
  }

  public getAppDefinitionLoadedWithAppKey(appKey: string): string {
    return `$appDefinitionLoader.appDefinitionLoaded--appKey:${appKey}`
  }

  async __fetchAppDefinition(): Promise<ModelAppDefinitions | null> {
    if (!$core.$models.appDefinitions) {
      return null
    }
    const appDefs = await $core.$models.appDefinitions.find()
    this.allAppDefinitionsByKey = appDefs.reduce((res, appDef) => {
      res[appDef.key] = appDef
      return res
    }, {})
    if (!this.appKey) {
      this.appKey = fetchAppKeyFromUrl()
      if (!this.appKey) {
        console.log(`this.appKey is empty so no appDefinition has been loaded`)
        return null
      }
    }
    // TODO: throw error?
    // console.log(`[AppDefinitionLoaderService] appDefinition "${this.appKey}" has loaded.`)
    return this.allAppDefinitionsByKey[this.appKey]
  }

  static get instance(): AppDefinitionLoaderService {
    return singletonInstanceSummoner('AppDefinitionLoaderService', AppDefinitionLoaderService)
  }

  get isAdminMode(): boolean {
    return !this.appKey
  }

  /**
   * 権限チェックを実施
   */
  async _checkUserRoleRestrictions() {
    if (!$core.$embAuth.user) {
      // SDKモードの場合は強制リダイレクトしない
      if (
        $core.$router?.currentRoute?.name &&
        $core.$router.currentRoute.name !== 'signIn' &&
        $core.isSdkMode !== true
      ) {
        $core.$router.push({
          name: '/signIn',
          query: { redirectTo: encodeURIComponent($core.$router.currentRoute.fullPath) },
        })
      }
      return
    }
    // $core.$embAuth.user?.isAdmin なら 通す
    if ($core.$embAuth.user?.isAdmin) {
      return // OK
    }
    // admin mode なら
    await this.redirectToRightAppOrErrorPageWhenNoPrivilege()
    if (this.__doesUserHasRightRole === false) {
      await this.redirectToRightAppOrErrorPageWhenNoPrivilege()
    }
  }

  // appDefinitions.roleRestriction が定義されていたら、それを含むかどうかチェック
  get __doesUserHasRightRole(): boolean {
    const userCoreRoleNames = $core.$embAuth.user?.coreRoles || []
    return !!this.appDefinition?.roleRestriction?.find(
      roleName => userCoreRoleNames.indexOf(roleName) >= 0,
    )
  }

  get currentUserCoreRoles() {
    return $core.$embAuth.user?.coreRoles || []
  }

  /**
   * 権限を持っているアプリがあれば、表示
   * 1つもなければ、エラーページを表示
   */
  async redirectToRightAppOrErrorPageWhenNoPrivilege() {
    // admin mode なら何もしない
    if ($core.$embAuth.user?.isAdmin) {
      return
    }
    // 権限を持っているアプリがあれば、そのリンクを表示したいので...
    const appKeysOfCurrentUserHasPrivs = this.appKeysOfCurrentUserHasPrivs
    // 現在, 権限を持つ正しいAppを表示しているかどうか
    const isCurrentAppHasPriv = !!appKeysOfCurrentUserHasPrivs[this.appKey]
    if (isCurrentAppHasPriv) {
      return // 問題無しとして通す
    }
    const apps = Object.values(appKeysOfCurrentUserHasPrivs || {})
    if (!apps.length) {
      showErrorPage('いずれのアプリケーションにもログインする権限がありません。')
    } else {
      // 権限を持つAppにリダイレクト
      window.location.href = await $core.$appHook.emit(
        frontAppHooks.appDefinitionLoaderService.redirectToRightAppOrErrorPageWhenNoPrivilege,
        generateAppLink(apps[0].key),
      )
      await $core.$utils.sleep(20000)
      return
    }
  }

  /**
   * 権限を持つappDefinitionsを返却
   */
  get appKeysOfCurrentUserHasPrivs(): {
    [appKey: string]: ModelAppDefinitions
  } {
    if ($core.$embAuth.user?.isAdmin) {
      return this.allAppDefinitionsByKey
    }
    return Object.values(this.allAppDefinitionsByKey || {}).reduce((res, appDef) => {
      const hasPriv = appDef.roleRestriction?.length
        ? !!appDef.roleRestriction.find(
            roleName => this.currentUserCoreRoles.indexOf(roleName) >= 0,
          )
        : true
      if (hasPriv) {
        res[appDef.key] = appDef
      }
      return res
    }, {})
  }

  /**
   * 権限を持つもののうち、現在表示しているものではないアプリを返却
   */
  get otherAppsHasPrivs(): ModelAppDefinitions[] {
    return Object.values(this.allAppDefinitionsByKey || {}).filter(appDef => {
      return appDef.key !== this.appKey
    })
  }

  reload() {
    this.loggedInExecuteOnceServiceBaseInstance.reload()
  }
}

export const absoluteHrefBase = `${globalThis.location?.protocol}//${globalThis.location?.host}`
export const generateAppLink = (appKey: string) => `${absoluteHrefBase}/a/${appKey}/`

export const $appDefinitionLoader = AppDefinitionLoaderService.instance

export interface ModelAppDefinitions {
  key: string
  name: string
  logo: string
  themeColor: string
  appMenuColor: string
  roleRestriction: string[]
  mainMenus?: AppDefinitionMainMenu[]
  id: string
  startPage?: string
  layoutType?: string
}
