import { registerComposableComponentSettings } from '../../../../ComposableComponents'
import { ComposableDataListService } from '../../ComposableDataListService'
import { ColumnDefByColName } from '../../../../../common/$models/ModelDef'
import { dataListComposableColumnNameSelectionSettingColumnDef } from '../../patrials/dataListComposableColumnNameSelectionSettingColumnDef'
import { extractVisibleOnIndexFromColumns } from '../../../../ModelServices/modelFactoryUtils'
import { IDLike } from '../../../../../types'
import { makeItReactive } from '../../../../../front/$frameworkUtils/$frameworkUtils'

export type DataListDisplayColumn = {
  label: string
  name: string
}

/**
 * # DataListDisplayService
 *
 * ## 役割
 * - DataListDisplayコンポーネント用, 表示するカラムやその他機能設定を保持する
 * - ComposableDataListService に依存
 *
 * ## Props
 *
 */
export class DataListDisplayService {
  public static readonly componentName = 'DataListDisplayContainer'
  // 列に利用するカラムを選択するかどうか
  public isSpecifyColumns: boolean = false
  // 表示するカラム
  public specifiedColumns: DataListDisplayColumn[] = []
  // 一括操作を有効にするか
  public disableBulkActions: boolean = false
  // TODO: 一括操作の選択肢
  public bulkActions: any[] = []
  private _checkedItemId: { [key: string]: boolean } = makeItReactive({})
  public bulkChecked: boolean = false
  public itemClickFunction: (itemId: IDLike, event, record) => Promise<void> = null
  public lastChecked: string = null

  constructor(public readonly ComposableDataListServiceInstance: ComposableDataListService) {}

  /**
   * 利用するColumnsを返す, 基本的には Table表示 `DataListDisplayTable` で利用される
   */
  get columns(): ColumnDefByColName {
    if (this.isSpecifyColumns !== true || !this.specifiedColumns?.length) {
      return this.visibleColumns
    }
    return this.specifiedColumns.reduce((acc, cur) => {
      if (!cur?.name) {
        return acc
      }
      acc[cur.name] = this.ComposableDataListServiceInstance.columns[cur.name]
      if (cur.label) {
        acc[cur.name].label = cur.label
      }
      return acc
    }, {})
  }

  get visibleColumns(): ColumnDefByColName {
    return extractVisibleOnIndexFromColumns(this.ComposableDataListServiceInstance.columns)
  }

  get checkedItemId(): { [key: string]: boolean } {
    return this._checkedItemId
  }
  checkItemId(itemId: IDLike, checked: boolean) {
    if (checked) {
      this._checkedItemId[itemId] = !!checked
    } else {
      delete this._checkedItemId[itemId]
    }
  }
  bulkCheckItemIds(checked: boolean) {
    this.bulkChecked = !!checked
    if (!checked) {
      this._checkedItemId = {}
      return
    }
    this._checkedItemId = this.ComposableDataListServiceInstance.items.reduce((acc, cur) => {
      acc[cur.id] = !!checked
      return acc
    }, {})
  }

  /**
   * Item click 時の処理を実行する
   * @param itemId
   * @param event
   * @param record
   */
  async invokeItemClickFunction(itemId: IDLike, event, record) {
    if (this.itemClickFunction) {
      this.itemClickFunction(itemId, event, record)
      return
    }
    const modelDefinedItemClick = this.ComposableDataListServiceInstance.getModelProp('indexListItemOnClick')
    if (modelDefinedItemClick) {
      await modelDefinedItemClick(itemId, event, record)
      return
    }
    // default
    const modalArgs = {
      id: itemId,
      modelName: this.ComposableDataListServiceInstance.modelName,
      virtualModelName: this.ComposableDataListServiceInstance.virtualModelName,
    }
    if (this.ComposableDataListServiceInstance.updatable) {
      $core.$modals.openEditViewModal(modalArgs)
    } else {
      $core.$modals.openModelViewModal(modalArgs)
    }
  }

  get shouldBulkControlEnabled(): boolean {
    if (this.ComposableDataListServiceInstance.getPermission('bulkControllable') === false) {
      return false
    }
    return this.bulkControlOptions?.length > 0 && this.disableBulkActions !== true
  }

  get bulkControlOptions(): { value: { action: string }; text: string }[] {
    const options: { value: { action: string }; text: string }[] = []
    if (this.ComposableDataListServiceInstance.updatable !== false) {
      options.push({ value: { action: 'bulkEdit' }, text: '一括編集' })
    }
    if (this.ComposableDataListServiceInstance.deletable !== false) {
      options.push({ value: { action: 'bulkDelete' }, text: '一括削除' })
    }
    if ($core.$embAuth.user.isAdmin) {
      options.push({ value: { action: 'exportAsJson' }, text: 'JSONエクスポート' })
    }
    const bulkControlActions =
      this.ComposableDataListServiceInstance.virtualModel?.bulkControlActions ||
      this.ComposableDataListServiceInstance.model.bulkControlActions
    if (bulkControlActions && Object.keys(bulkControlActions)?.length) {
      Object.keys(bulkControlActions).map((key) => {
        options.push({
          value: { action: key },
          text: bulkControlActions[key].label || key,
        })
      })
    }
    return options
  }
}

const configColumnsOfDataListDisplayContainer: ColumnDefByColName = {
  isSpecifyColumns: {
    label: '表示するカラムを指定する',
    type: 'BOOLEAN',
    inputHelpText:
      'チェックを入れない場合は、モデル定義のカラムが表示対象になります。 ※ 表示形式がTableの場合のみ有効',
    defaultValue: false,
  },
  specifiedColumns: {
    label: '表示するカラム',
    type: 'ARRAY_OF_OBJECT',
    enableIf: (row) => !!row.isSpecifyColumns,
    columns: {
      name: {
        label: 'カラム',
        ...dataListComposableColumnNameSelectionSettingColumnDef,
        type: 'STRING',
      },
      label: {
        label: '表示名',
        type: 'STRING',
      },
    },
  },
  disableBulkActions: {
    label: '一括操作を無効にする',
    type: 'BOOLEAN',
    defaultValue: false,
  },
}

// Builder 用の定義
registerComposableComponentSettings(DataListDisplayService.componentName, {
  hasDefaultSlot: true,
  label: DataListDisplayService.componentName,
  configColumns: configColumnsOfDataListDisplayContainer,
  category: 'DataListDisplay',
})
