<template>
  <Datepicker
    v-bind="commonAttrs"
    :model-value="modelValue"
    @update:model-value="change"
    :format="format"
    :displayFormat="displayFormat"
    :enableTimePicker="true"
    :datepickerProps="datepickerProps"
    @time-picker-open="onTimePickerOpen"
    @time-picker-close="onTimePickerClose"
    :commonAttrs="commonAttrs"
  />
</template>

<script lang="ts">
import { VueDatePickerProps } from '@vuepic/vue-datepicker'
import { PropType } from 'vue'

export default {
  name: 'DatetimePicker',
  props: {
    format: { default: 'YYYY-MM-DDTHH:mm:ss' },
    displayFormat: { default: 'yyyy-MM-dd HH:mm' },
    modelValue: { default: '' },
    datepickerProps: { default: () => ({} as PropType<VueDatePickerProps>) },
    commonAttrs: {
      type: Object as PropType<Record<string, unknown>>,
      required: false,
      default: () => ({}),
    },
    // 2つのパタン、Rangeの場合は配列、それ以外は文字列
    defaultTime: {
      type: [String, Array] as PropType<string | string[]>,
    },
  },
  data() {
    return {
      duringTimeSelecting: false,
    }
  },
  methods: {
    change(value) {
      // Date object で来ている場合には string format して emit する
      if (value instanceof Date) {
        // duringTimeSelecting が true の場合には、そのまま
        if (!this.duringTimeSelecting && this.defaultTime) {
          value = $core.$dayjs(value).format('YYYY-MM-DD') + 'T' + this.defaultTime
        } else if (this.duringTimeSelecting) {
          value = $core.$dayjs(value).format(this.format)
        }
      }

      if (Array.isArray(value)) {
        if (!this.duringTimeSelecting && this.defaultTime) {
          value = value.map((v, index) => {
            let datetime = v
            // defaultTimeのTimeに切り替える
            datetime = $core.$dayjs(v).format('YYYY-MM-DD') + 'T' + this.defaultTime[index]
            return datetime
          })
        } else if (this.duringTimeSelecting) {
          value = value.map((v) => $core.$dayjs(v).format(this.format))
        }
      }

      if (value === this.modelValue) {
        return // 何もしない
      }
      this.$emit('update:modelValue', value || '')
    },
    onTimePickerOpen() {
      this.duringTimeSelecting = true
      if (Array.isArray(this.modelValue)) {
        const startDate = this.modelValue[0] ? new Date(this.modelValue[0]) : new Date()
        const endDate = this.modelValue[1] ? new Date(this.modelValue[1]) : new Date()
        this.change([startDate, endDate])
      } else {
        if (!this.modelValue) {
          this.change(new Date())
        }
      }
    },
    onTimePickerClose() {
      this.duringTimeSelecting = false
    },
  },
}
</script>
