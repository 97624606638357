import { ModelDef } from '../../../common/$models/ModelDef'

export const appTemplateInstallHistory: ModelDef = {
  tableName: 'appTemplateInstallHistory',
  tableLabel: 'アプリテンプレートインストール履歴',
  tableComment: 'アプリテンプレートのインストール履歴',
  primaryKeyColType: 'NUMBER',
  timestamps: true,
  creatable: false,
  updatable: false,
  deletable: false,
  bulkEditable: false,
  modelType: 'admin',
  columns: {
    appTemplateId: {
      label: 'アプリテンプレートID',
      name: 'appTemplateId',
      type: 'NUMBER',
      visible: true,
      visibleOnIndex: true,
    },
    appTemplateName: {
      type: 'STRING',
      name: 'appTemplateName',
      visible: true,
      visibleOnIndex: true,
      numeric_precision: 10,
      numeric_scale: 5,
      label: 'アプリテンプレート名',
    },
    isActive: {
      type: 'BOOLEAN',
      name: 'isActive',
      visible: true,
      visibleOnIndex: true,
      numeric_precision: 10,
      numeric_scale: 5,
      label: 'アクティブ',
      defaultValue: true,
    },
    version: {
      type: 'STRING',
      name: 'version',
    },
  },
}
