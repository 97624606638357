import { PropType } from 'vue'
import { FilePathGenerateFunction } from './FileManager'

export const uploadPathPrefixProps = {
  filePathPrefix: {
    type: String,
  },
  filePathGenerateFunction: {
    type: Function as PropType<FilePathGenerateFunction>,
  },
}
