import { $models, $modelsLoader } from '../../common/$models'
import { directus_revisions } from './directus_revisions'

export const useRecordRevisionsFeature = () => {
  if (!$models.directus_revisions) {
    $modelsLoader.loadModel(directus_revisions)
  }
  $core.VueClass.component(
    'RecordRevisionsDiffList',
    $frameworkUtils.defineAsyncComponent(() => import('./RecordRevisionsDiffList.vue')),
  )
  setAppHook()
}
const setAppHook = () => {
  // Change list view only if filters.item is set
  $core.$appHook.on(
    'oneRecordRevisionsDiffList.modelIndex.model:directus_revisions.indexList',
    components => {
      return components.concat('RecordRevisionsDiffList')
    },
  )
  // この状態を復元 ボタン for form
  $core.$appHook.on(
    '$CORE.admin.resolveComponent.model.directus_revisions.edit.modelForm.beforeActionButton',
    () =>
      $frameworkUtils.defineAsyncComponent(() => import('./RestoreRevisionsButtonOnModelForm.vue')),
    'c',
  )
}
