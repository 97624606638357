<template>
  <div class="flex-fill" style="position: relative">
    <div class="d-flex align-items-center">
      <b-form-select
        style="width: 120px"
        class="mr-1"
        :modelValue="operator"
        @update:modelValue="updateOperator"
        :options="options"
        size="sm"
      ></b-form-select>
      <b-form-radio-group
        v-if="operator !== 'isnull' && operator !== 'isnotnull'"
        :modelValue="changedValue"
        :options="radioOptions"
        @update:modelValue="change"
      ></b-form-radio-group>
      <a v-if="disableSetting" class="ml-auto" role="button" @click="toggleSetting">
        <Ficon class="ml-2" type="ellipsis-h" />
      </a>
    </div>
    <FilterItemManagerForCustom
      @onDismiss="() => (isVisibleSetting = false)"
      :visible="isVisibleSetting"
    />
  </div>
</template>
<script lang="ts">
import FilterItemManagerForCustom from '../FilterItemManagerForCustom.vue'
import { inject } from 'vue'
import { FILTER_OPERATOR_FOR_BOOLEAN } from '../../../FilterRuleService'

export default {
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    disableSetting: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    FilterItemManagerForCustom,
  },
  setup() {
    return {
      filterObject: inject('filterObject'),
    }
  },
  data() {
    return {
      operator: null,
      isVisibleSetting: false,
      radioOptions: [
        { text: 'TRUE', value: 1 },
        { text: 'FALSE', value: 0 },
      ],
    }
  },
  computed: {
    label() {
      if (!this.filterObject) return ''
      return this.filterObject.colDef.label || this.filterObject.colDef.name
    },
    changedValue() {
      return this.modelValue ? (this.modelValue === 'true' ? 1 : 0) : null
    },
    options() {
      // { value: xxxx'', text: 'xxxxx' }のような形で返す
      return Object.keys(FILTER_OPERATOR_FOR_BOOLEAN).map((operator) => {
        return {
          value: operator,
          text: FILTER_OPERATOR_FOR_BOOLEAN[operator],
        }
      })
    },
  },
  created() {
    this.operator = this.options.length > 0 ? this.options[0].value : null
  },
  methods: {
    toggleSetting() {
      this.isVisibleSetting = !this.isVisibleSetting
    },
    change(value) {
      this.$emit('update:modelValue', {
        value: value === 1 ? 'true' : 'false',
        operator: this.operator,
      })
    },
    updateOperator(value) {
      this.operator = value
      this.$emit('update:operator', this.operator)
    },
  },
}
</script>
