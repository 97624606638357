<template>
  <div class="model-form-one-record">
    <code v-if="oneRecordService.notFoundColNames.length > 0"
    >カラム定義が見つかりませんでした: {{ notFoundColumns }}</code
    >
    <slot></slot>
  </div>
</template>
<script lang="ts">
import { CCModelFormOneRecordService } from '../CCModelFormOneRecordService'
import { computed, PropType } from 'vue'
const name = 'ModelFormOneRecord'
export default {
  name,
  props: {
    oneRecordService: {
      type: Object as PropType<CCModelFormOneRecordService>,
      required: true,
    },
  },
  provide() {
    return {
      CCModelFormOneRecordServiceInstance: computed(() => this.oneRecordService),
    }
  },
  computed: {
    notFoundColumns() {
      return this.oneRecordService.notFoundColNames.join(', ')
    },
  },
  created() {},
}
</script>
