<template>
  <div class="b-skeleton-wrapper">
    <div
      class="b-skeleton b-skeleton-animate-wave"
      :style="{
        height: height,
        width: width,
      }"
    ></div>
  </div>
</template>
<script lang="ts">
export default {
  props: {
    height: {
      type: String,
      default: '1rem',
    },
    width: {
      type: String,
      default: '100%',
    },
  },
}
</script>
