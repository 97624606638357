<template>
  <div v-if="colName" class="modelInput-validation-error-message text-danger small">
    <div
      v-for="(message, index) in messages"
      :data-error-message="message"
    >{{ message }}</div>
  </div>
</template>
<script lang="ts">
export default {
  props: {
    ModelFormService: {},
    col: { required: true },
    colName: { required: true, type: String },
    validationError: {},
    modelInputInstance: {},
  },
  computed: {
    messages() {
      const validationMessages = this.ModelFormService?.validationErrorMessages || {}
      const currentColumnMessagesInModelFormService = validationMessages?.[this.colName] || []
      const modelInputErrorMessages = this.validationError?.[this.colName] || []
      // unique
      return currentColumnMessagesInModelFormService.concat(modelInputErrorMessages).filter(
        (value, index, self) => self.indexOf(value) === index,
      )
    },
  },
}
</script>
