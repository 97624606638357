/**
 * バイナリデータのエンコーディングを自動検出してUTF-8に変換する
 * @param input
 */
export const autoDetectEncodingAndConvertToUtf8 = (input: string | ArrayBuffer | Uint8Array): AutoDetectEncodingAndConvertToUtf8Result => {
  let uint8Array
  if (input instanceof ArrayBuffer) {
    uint8Array = new Uint8Array(input)
  } else if (input instanceof Uint8Array) {
    uint8Array = input
  } else if (typeof input === 'string') {
    uint8Array = new TextEncoder().encode(input)
  } else {
    throw new Error('Input must be ArrayBuffer or string')
  }
  // const uint8Array = new Uint8Array(arrayBuffer)
  const detectedEncoding = detectEncoding(uint8Array)
  return {
    text: convertToUtf8(uint8Array, detectedEncoding),
    encoding: detectedEncoding,
  }
}

/**
 * バイナリデータをUTF-8に変換する with 指定された from エンコーディング
 * @param input
 * @param encoding
 */
export const convertToUtf8 = (input: string | ArrayBuffer | Uint8Array, encoding: string): string => {
  let uint8Array
  if (input instanceof ArrayBuffer) {
    uint8Array = new Uint8Array(input)
  } else if (input instanceof Uint8Array) {
    uint8Array = input
  } else if (typeof input === 'string') {
    uint8Array = new TextEncoder().encode(input)
  } else {
    throw new Error('Input must be ArrayBuffer or string')
  }
  const decoder = new TextDecoder(encoding)
  return decoder.decode(uint8Array)
}

/**
 * ファイルのエンコーディングを自動検出してUTF-8に変換する
 * @param file
 */
export const autoDetectEncodingAndConvertToUtf8WithFile = async (file: Blob): Promise<AutoDetectEncodingAndConvertToUtf8Result> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = (event) => {
      const arrayBuffer = event.target.result
      const result = autoDetectEncodingAndConvertToUtf8(arrayBuffer)
      resolve(result)
    }
    reader.onerror = reject
    reader.readAsArrayBuffer(file)
  })
}


// UTF-8の検出
function isUTF8(data) {
  let i = 0
  while (i < data.length) {
    if (data[i] <= 0x7F) {
      i++
    } else if (data[i] >= 0xC2 && data[i] <= 0xDF && i + 1 < data.length) {
      if (data[i + 1] >= 0x80 && data[i + 1] <= 0xBF) {
        i += 2
      } else {
        return false
      }
    } else if (data[i] >= 0xE0 && data[i] <= 0xEF && i + 2 < data.length) {
      if (data[i + 1] >= 0x80 && data[i + 1] <= 0xBF &&
        data[i + 2] >= 0x80 && data[i + 2] <= 0xBF) {
        i += 3
      } else {
        return false
      }
    } else if (data[i] >= 0xF0 && data[i] <= 0xF4 && i + 3 < data.length) {
      if (data[i + 1] >= 0x80 && data[i + 1] <= 0xBF &&
        data[i + 2] >= 0x80 && data[i + 2] <= 0xBF &&
        data[i + 3] >= 0x80 && data[i + 3] <= 0xBF) {
        i += 4
      } else {
        return false
      }
    } else {
      return false
    }
  }
  return true
}

// SJISの検出
function isSJIS(data) {
  for (let i = 0; i < data.length - 1; i++) {
    const c1 = data[i], c2 = data[i + 1]
    if ((c1 >= 0x81 && c1 <= 0x9F) || (c1 >= 0xE0 && c1 <= 0xFC)) {
      if ((c2 >= 0x40 && c2 <= 0x7E) || (c2 >= 0x80 && c2 <= 0xFC)) {
        return true
      }
    }
  }
  return false
}

// EUC-JPの検出
function isEUCJP(data) {
  for (let i = 0; i < data.length - 1; i++) {
    const c1 = data[i], c2 = data[i + 1]
    if (c1 >= 0xA1 && c1 <= 0xFE && c2 >= 0xA1 && c2 <= 0xFE) {
      return true
    }
  }
  return false
}

// ISO-2022-JPの検出
function isISO2022JP(data) {
  for (let i = 0; i < data.length - 2; i++) {
    if (data[i] === 0x1B && data[i + 1] === 0x24 && data[i + 2] === 0x42) {
      return true
    }
  }
  return false
}


// エンコーディング検出関数
export const detectEncoding = (data: ArrayBuffer): string => {
  // UTF-8の検出
  if (isUTF8(data)) return 'utf-8'

  // SJISの検出
  if (isSJIS(data)) return 'shift-jis'

  // EUC-JPの検出
  if (isEUCJP(data)) return 'euc-jp'

  // ISO-2022-JPの検出
  if (isISO2022JP(data)) return 'iso-2022-jp'

  // デフォルトはUTF-8
  return 'utf-8'
}

type AutoDetectEncodingAndConvertToUtf8Result = {
  text: string
  encoding: string
}

