<template>
  <component
    :is="wrapperTagCalc()"
    :class="itemWrapperBindPropsFixedClasses()"
    v-bind="colDef.listItemAttrs || {}"
  >
    <component
      :is="itemDisplayComponent()"
      :colName="colNameCalc()"
      :column="column()"
      :DataListDisplayServiceInstance="DataListDisplayServiceInstance"
      :item="itemCalc()"
      v-bind="colDisplayItemBindByInputComponentType()"
    />
  </component>
</template>
<script lang="ts">
import { registerComposableComponentSettings } from '../../../../ComposableComponents'
import { inject } from 'vue'
import { DataListDisplayService } from './DataListDisplayService'
import { dataListComposableColumnNameSelectionSettingColumnDef } from '../../patrials/dataListComposableColumnNameSelectionSettingColumnDef'
import { ColumnDef } from '../../../../../common/$models/ModelDef'
import { colItemWrapperBindByInputComponentTypes } from './colItemWrapperBindByInputComponentTypes'

const name = 'DataListDisplayColumnItem'
registerComposableComponentSettings(name, {
  hasDefaultSlot: true,
  configColumns: {
    colName: dataListComposableColumnNameSelectionSettingColumnDef,
  },
})


/**
 * # DataListDisplayColumnItem
 * - 一覧表示時の 1 カラム分のデータを表示するコンポーネント
 *
 */
export default {
  name,
  props: {
    colName: {
      type: String,
      required: true,
    },
    wrapperTag: {
      type: String,
      default: 'span',
    },
    displayIndex: {
      type: Number,
      default: null,
    },
  },
  computed: {
    colDef() {
      return this.column()
    },
  },
  methods: {
    wrapperTagCalc() {
      return this.wrapperTag || 'span'
    },
    colNameCalc() {
      return this.colName || ''
    },
    itemCalc() {
      return this.item || {}
    },
    column(): ColumnDef {
      return this.colName?.length ? this.DataListDisplayServiceInstance.columns[this.colName] : null
    },
    value() {
      return this.item[this.colName]
    },
    formattedDisplayValue() {
      return $core.$utils.columnDisplayValueFormatter(this.colDef, this.item)
    },
    /**
     * inputComponent に応じた Style を返す
     */
    colDisplayItemBindByInputComponentType(): Record<string, any> | null {
      if (colItemWrapperBindByInputComponentTypes[this.colDef.inputComponent]) {
        return colItemWrapperBindByInputComponentTypes[this.colDef.inputComponent]({
          colName: this.colName,
          colDef: this.colDef,
          record: this.item,
          value: this.value(),
        })
      }
      return null
    },
    itemWrapperBindPropsFixedClasses(): string[] {
      return [
        `data-list-display-column-item--model-${this.DataListDisplayServiceInstance.ComposableDataListServiceInstance?.modelName}--col-${this.colName}`
      ]
    },
    itemDisplayComponent() {
      if (this.colDef.listItemComponent) {
        return this.colDef.listItemComponent
      }
      return {
        template: `<span v-bind="colDisplayItemBindByInputComponentType()">${this.formattedDisplayValue()}</span>`,
        methods: {
          colDisplayItemBindByInputComponentType() {
            return this.$parent.colDisplayItemBindByInputComponentType
          },
        },
      }
    },
  },
  setup() {
    return {
      DataListDisplayServiceInstance: inject<DataListDisplayService>(
        'DataListDisplayServiceInstance',
      ),
      item: inject<Record<string, any>>('record'),
    }
  },
}
</script>
