<template>
  <tr
    v-single-click="(event) => itemClick(item.id, event, item)"
    style="cursor: pointer"
    v-bind="rowBindProps()"
    v-once
  >
    <slot>
      <td
        v-if="DataListDisplayServiceInstance.shouldBulkControlEnabled"
        class="ignoreItemClick"
        :class="`data-list-display-table-cell--model-${ComposableDataListServiceInstance?.modelName}--col-_bulk_control`"
      >
        <DataListDisplayItemCheckbox class="ignoreItemClick" :item="item" :index="index" />
      </td>
      <!-- Vue rendering Performance の 問題で、下記のような v-if / v-else にして Rendering するのが良いようである... -->
      <td
        v-for="(column, colName, i) in DataListDisplayServiceInstance.columns"
        :key="colName"
        :class="itemWrapperBindPropsFixedClasses(colName)"
        v-bind="column.listItemAttrs || {}"
        :data-col-name="colName"
        :data-value="item[colName]"
        v-once
      >
        <span
          v-html="$core.$utils.columnDisplayValueFormatter(column, item)"
          v-bind="colDisplayItemBindByInputComponentType(column, colName)"
          v-if="!column.listItemComponent"
        />
        <component
          v-else
          :is="column.listItemComponent"
          :item="item"
          :colName="colName"
          :colDef="column"
          :displayIndex="i"
          :DataListDisplayServiceInstance="DataListDisplayServiceInstance"
        />
      </td>
    </slot>
  </tr>
</template>
<script lang="ts">
import { computed, inject } from 'vue'
import { DataListDisplayService } from '../DataListDisplayService'
import { registerComposableComponentSettings } from '../../../../../ComposableComponents'
import { ComposableDataListService } from '../../../ComposableDataListService'
import DataListDisplayItemCheckbox from '../../../../../ModelRecordBulkControlService/front/components/DataListDisplayItemCheckbox.vue'
import { ColumnDef } from '../../../../../../common/$models/ModelDef'
import { colItemWrapperBindByInputComponentTypes } from '../colItemWrapperBindByInputComponentTypes'

const name = 'DataListDisplayTableBodyRow'
registerComposableComponentSettings(name, {
  hasDefaultSlot: true,
})

export default {
  name,
  components: { DataListDisplayItemCheckbox },
  provide() {
    return {
      record: computed(() => this.item),
    }
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  setup() {
    return {
      DataListDisplayServiceInstance: inject(
        'DataListDisplayServiceInstance',
      ) as DataListDisplayService,
      ComposableDataListServiceInstance: inject(
        'ComposableDataListServiceInstance',
      ) as ComposableDataListService,
    }
  },
  methods: {
    itemClick(itemId, event, record) {
      // TODO: parent check
      if (event.target.className.indexOf('ignoreItemClick') >= 0) {
        return
      }
      this.DataListDisplayServiceInstance.invokeItemClickFunction(itemId, event, record)
    },
    rowBindProps() {
      return this.ComposableDataListServiceInstance.getModelProp('listRecordItemAttrsFunction')?.(
        this.item,
        this.index,
        this.ComposableDataListServiceInstance,
      ) || {}
    },
    itemWrapperBindPropsFixedClasses(colName: string) {
      return [
        `data-list-display-column-item--model-${this.DataListDisplayServiceInstance.ComposableDataListServiceInstance?.modelName}--col-${colName}`
      ]
    },
    colDisplayItemBindByInputComponentType(colDef: ColumnDef, colName: string): Record<string, any> {
      if (colItemWrapperBindByInputComponentTypes[colDef.inputComponent]) {
        return colItemWrapperBindByInputComponentTypes[colDef.inputComponent]({
          colName: colName,
          colDef: colDef,
          record: this.item,
          value: this.item[colName],
        })
      }
      return {}
    },
  },
}
</script>
