import { ColumnDef } from './ModelDef'
import { codeInputCommonAttrs } from './constants'
import { validateIsValidJsObject } from '../ValidationService/validator'
import { displayModelNameWithLabel } from '../utils'

const dataSourceTypes = {
  model: 'モデル定義',
  virtualModel: 'Virtualモデル定義',
  dataExportSetting: 'データエクスポート設定',
}

const dataSourceTypeKeys = Object.keys(dataSourceTypes)

export const findQueryColumnTemplate: ColumnDef = {
  type: 'TEXT',
  label: 'データ取得クエリ',
  comment:
    'データ取得時のフィルタクエリ ModelFactory.find(query) のオプション。\nfields, sort, filter, limit, offset 等のプロパティを設定',
  inputAttrs: {
    ...codeInputCommonAttrs,
    placeholder: `{ sort: 'date', filter: { colName: {_eq: 'some'}, limit: -1 } }`,
  },
  enableIf: row => ['model', 'virtualModel'].indexOf(row.dataSourceType) >= 0,
  validate: {
    validateIsValidJsObject,
  },
}

export const dataTransformFunctionColumnTemplate: ColumnDef = {
  type: 'TEXT',
  label: 'データ変換関数',
  comment:
    'データ取得後の 変換関数を定義します。\nasync ({data: any[]}) => {...; return data} の文脈で実行されますので、data を書き換えてください。',
  inputAttrs: {
    ...codeInputCommonAttrs,
    placeholder: `data = data.map(d => { d.some = 'thing'; return d })`,
  },
  visibleOnIndex: false,
}

export const dataSourceTypeColumnTemplate: ColumnDef = {
  label: 'データソース種別',
  type: 'STRING',
  validate: { notEmpty: true },
  selections: () => dataSourceTypeKeys,
  defaultValue: 'model',
  editCallback({ row, newValue, oldValue }) {
    if (newValue !== oldValue) {
      if (row.dataSourceTargetName) {
        row.dataSourceTargetName = ''
      }
    }
    return row
  },
  customLabel: (val, callerVueInstance, recordRoot) => {
    return dataSourceTypes[val] || ''
  },
  labelFormatter: record => {
    return dataSourceTypes[record.dataSourceType] || ''
  },
}

export const dataSourceTargetNameColumnTemplate: ColumnDef = {
  label: 'データソース',
  type: 'STRING',
  editable: row => !!row.dataSourceType,
  dynamicSelections: true,
  validate: { notEmpty: true },
  async selections(record: any) {
    if (record.dataSourceType === 'model') {
      return $core.$modelsLoader.modelNames
    }
    if (record.dataSourceType === 'virtualModel') {
      return Object.keys($core.$virtualModels)
    }
    if (record.dataSourceType === 'dataExportSetting') {
      return $core.$models.dataExportSettings.find({ fields: ['id', 'name'] })
    }
  },
  customLabel: (val, callerVueInstance, recordRoot) => {
    if (recordRoot.dataSourceType === 'model') {
      return displayModelNameWithLabel(val)
    }
    if (recordRoot.dataSourceType === 'virtualModel') {
      return displayModelNameWithLabel(val, true, true)
    }
    return val
  },
  labelFormatter: record => {
    if (record.dataSourceType === 'model') {
      return displayModelNameWithLabel(record.dataSourceTargetName)
    }
    return record.dataSourceTargetName
  },
}

export const generateColNamesSelectionsFunction = (
  record: any,
  currentValue: any,
  initialValue: any,
  recordRoot: any,
) => {
  if (!recordRoot.dataSourceTargetName) {
    return []
  }
  if (recordRoot.dataSourceType === 'model') {
    return $core.$models[recordRoot.dataSourceTargetName].colNames
  }
  if (recordRoot.dataSourceType === 'virtualModel') {
    return $core.$virtualModels[recordRoot.dataSourceTargetName].colNames
  }
  return []
}

const labelDisplayFunc = (value, label) => {
  return label ? `${label} (${value})` : value
}

const getLabelByDataSourceTypeAndTargetName = (dataSourceType, dataSourceTargetName, colName) => {
  if (dataSourceType === 'model') {
    return $core.$models[dataSourceTargetName].columns[colName].label
  }
  if (dataSourceType === 'virtualModel') {
    return $core.$virtualModels[dataSourceTargetName].columns[colName].label
  }
  return ''
}

// TODO: 統合...
export const dataSourceTypeColNameSelectionColumnTemplate: ColumnDef = {
  label: 'カラム',
  dynamicSelections: true,
  selections(record: any, currentValue: any, initialValue: any, recordRoot: any) {
    return generateColNamesSelectionsFunction(record, currentValue, initialValue, recordRoot)
  },
  type: 'STRING',
  customLabel: (value, callerVueInstance, recordRoot) => {
    return labelDisplayFunc(
      value,
      getLabelByDataSourceTypeAndTargetName(
        recordRoot.dataSourceType,
        recordRoot.dataSourceTargetName,
        value,
      ),
    )
  },
}

export const generateDataSourceTypeColNameSelectionColumnWithColumnFilterFunction = (
  filterFunc,
): ColumnDef => {
  return {
    ...dataSourceTypeColNameSelectionColumnTemplate,
    selections(
      record: any,
      currentValue: any,
      initialValue: any,
      recordRoot: any,
      callerVueInstance,
    ) {
      return generateColNamesSelectionsFunction(
        record,
        currentValue,
        initialValue,
        recordRoot,
      ).filter(filterFunc(record, currentValue, initialValue, recordRoot, callerVueInstance))
    },
  }
}

export const builderTypeModelColumnsTemplates: {
  query: ColumnDef
  dataTransformFunction: ColumnDef
  dataSourceType: ColumnDef
  dataSourceTargetName: ColumnDef
} = {
  query: findQueryColumnTemplate,
  dataTransformFunction: dataTransformFunctionColumnTemplate,
  dataSourceType: dataSourceTypeColumnTemplate,
  dataSourceTargetName: dataSourceTargetNameColumnTemplate,
}
