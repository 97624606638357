const convertFullWidthToHalf = (value: string): string => {
  return value.replace(/[０-９]/g, function (s) {
    return String.fromCharCode(s.charCodeAt(0) - 65248)
  })
}

const getHoursAndMinutes = (
  numeric: string,
  maxTime: string,
  minTime: string,
): { hours: number; minutes: number } => {
  let hours: number = 0,
    minutes: number = 0

  switch (numeric.length) {
    case 1:
    case 2:
      hours = parseInt(numeric)
      break
    case 3:
      hours = parseInt(numeric.substring(0, 1))
      minutes = parseInt(numeric.substring(1, 3))
      break
    default:
      hours = parseInt(numeric.substring(0, 2))
      minutes = parseInt(numeric.substring(2, 4))
      break
  }

  // maxTimeを考慮
  const [maxHours, maxMinutes] = maxTime.split(':').map((num) => parseInt(num))
  if (hours > maxHours || (hours === maxHours && minutes > maxMinutes)) {
    hours = maxHours
    minutes = maxMinutes
  }

  // minTimeを考慮
  const [minHours, minMinutes] = minTime.split(':').map((num) => parseInt(num))
  if (hours < minHours || (hours === minHours && minutes < minMinutes)) {
    hours = minHours
    minutes = minMinutes
  }

  return { hours, minutes }
}

export const toHourMinutesString = (
  value: string,
  maxTime: string = '24:00',
  minTime: string = '00:00',
): string => {
  if (!value) {
    return ''
  }

  const numeric = convertFullWidthToHalf(value).replace(/[^\d]/g, '')
  const { hours, minutes } = getHoursAndMinutes(numeric, maxTime, minTime)

  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`
}
