<template>
  <div class="d-flex align-items-center" style="height: 100vh">
    <div class="text-center container">
      <div v-if="messageOnHtml" v-html="messageOnHtml"></div>
      <div v-else class="alert alert-danger bg-white border-0 pt-0">
        <ficon type="info" class="text-danger" style="font-size: 2em; opacity: 0.5"></ficon>
        <h6 class="mt-3 fw-bold">
          {{ message || 'エラーが発生しました' }}
        </h6>
        <div class="small">
          ログイン中のアカウント: {{ email }}
          <div class="my-2">
            <a href="#" v-single-click="() => $core.$embAuth.signOut()">> ログアウト</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  layout: 'auth',
  name: 'ErrorPage',
  mounted() {
    if ($core.isSdkMode) {
      return // do nothing
    }
    // ログインしていないときはログイン画面へ遷移
    if (!this.email) {
      $core.$router.push($core.$configVars.get('signInRedirectTo', '/signIn'))
    }
  },
  computed: {
    message() {
      return this.$route.query.message || this.messageHtml
    },
    messageOnHtml() {
      return this.$route.params.messageHtml || this.messageHtml
    },
    email() {
      return $core.$embAuth?.user?.email
    },
  },
}
</script>
