import { ColumnDef, ModelDef } from '../../../common/$models/ModelDef'

export const junctionUsersAndGroups: ModelDef = {
  tableName: 'junctionUsersAndGroups',
  tableLabel: 'ユーザー所属グループ',
  tableComment: '',
  primaryKeyColType: 'UUID',
  defaultSort: { key: 'createdAt', order: 'desc' },
  dataFilters: {},
  modelType: 'admin',
  columns: {
    // TODO: UI的にはグループに対してまとめてがユーザ登録できたほうが良い
    // ユーザ
    user: {
      label: 'ユーザー',
      type: 'RELATIONSHIP_MANY_TO_ONE',
      relationshipManyToOne: {
        collectionName: 'directus_users',
        labelFormatter: record => {
          return `${record.last_name || ''} ${record.first_name || ''}`
        },
      },
    },
    // グループ
    // TODO:これだとID保持することになる？コードの概念いらない？
    userGroup: {
      label: 'グループ',
      type: 'RELATIONSHIP_MANY_TO_ONE',
      relationshipManyToOne: {
        collectionName: 'userGroups',
        labelFormatter: record => {
          return `${record.name}(${record.code})`
        },
      },
    },
    autoCalced: {
      label: '自動計算による追加',
      type: 'BOOLEAN',
      enableIf: row => row.autoCalced,
      editable: false,
    },
  },
}
