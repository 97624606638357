import { ColumnDef, ModelDef } from '../../../common/$models/ModelDef'
import { codeInputCommonAttrs } from '../../../common/$models'

const sqlRequiredColumns = ['db_host', 'db_port', 'db_database', 'db_user', 'db_password']

const databaseSubSourceTypes = {
  mysql: {
    label: 'MySQL',
    requiredColumns: sqlRequiredColumns,
  },
  postgres: {
    label: 'PostgreSQL',
    requiredColumns: sqlRequiredColumns,
  },
  mssql: {
    label: 'Microsoft SQL Server',
    requiredColumns: sqlRequiredColumns,
  },
  // sqlite: {
  //   label: 'SQLite',
  //   requiredColumns: ['filename'],
  // },
  oracle: {
    label: 'Oracle',
    requiredColumns: sqlRequiredColumns,
  },
}

export const sourceTypeSubSelectionsBySourceTypes = {
  database: {
    label: 'データベース (SQL系)',
    subSourceTypes: databaseSubSourceTypes,
  },
  externalHttpApi: {
    label: '外部 HTTP API',
  },
}

const connectionConfigColName = 'connectionConfig'
const getConnectionConfigCommonColAttrs = (colName, required = false): Partial<ColumnDef> => {
  return {
    virtualColumnOf: connectionConfigColName,
    enableIf: row => {
      return (
        sourceTypeSubSelectionsBySourceTypes[row?.connectionType]?.subSourceTypes?.[
          row?.subSourceType
        ]?.requiredColumns?.indexOf(colName) >= 0
      )
    },
    validate: required ? { notEmpty: true } : {},
  }
}

export type ModelCoreAnotherDataSourceDialect = {
  name: string
  desc: string
  connectionType: keyof typeof sourceTypeSubSelectionsBySourceTypes
  subSourceType: keyof typeof databaseSubSourceTypes
  connectionConfig:
    | {
        db_host: string
        db_port: string
        db_user: string
        db_password: string
        db_database: string
      }
    | any
  additionalParams: string
}

/**
 * データソースの接続情報を保持 DB, API 等のデータソースを定義する
 */
export const core_another_data_source_dialects: ModelDef = {
  tableName: 'core_another_data_source_dialects',
  tableLabel: '外部ソース接続先',
  primaryKeyColType: 'UUID',
  modelType: 'admin',
  columns: {
    displayName: {
      label: '管理用名称',
      type: 'STRING',
      unique: true,
      validate: { notEmpty: true },
    },
    name: {
      type: 'STRING',
      label: '接続識別子',
      unique: true,
      validate: { notEmpty: true },
      inputHelpText: 'この接続先を識別するための一意の名称を指定します。',
    },
    desc: {
      type: 'TEXT',
      label: '説明',
      width: {
        md: 24,
      },
    },
    connectionType: {
      label: '接続先タイプ',
      type: 'STRING',
      validate: { notEmpty: true },
      selections: () => Object.keys(sourceTypeSubSelectionsBySourceTypes),
      customLabel: value => sourceTypeSubSelectionsBySourceTypes[value]?.label || value,
    },
    subSourceType: {
      label: 'データソースタイプ',
      type: 'STRING',
      validate: { notEmpty: true },
      dynamicSelections: true,
      enableIf: row => !!sourceTypeSubSelectionsBySourceTypes[row?.connectionType]?.subSourceTypes,
      selections: row =>
        Object.keys(sourceTypeSubSelectionsBySourceTypes[row?.connectionType].subSourceTypes || {}),
      customLabel: (value, callerVueInstance: any, recordRoot: any) =>
        sourceTypeSubSelectionsBySourceTypes[recordRoot?.connectionType]?.subSourceTypes?.[value]
          ?.label || '',
    },
    [connectionConfigColName]: {
      type: 'ARRAY_OF_OBJECT',
      visible: false,
    },
    db_host: {
      beforeComponent: '<h4>DB 接続情報</h4>',
      label: 'ホスト',
      type: 'STRING',
      ...getConnectionConfigCommonColAttrs('db_host', true),
    },
    db_port: {
      label: 'ポート番号',
      inputAttrs: { placeholder: 'default' },
      type: 'NUMBER',
      ...getConnectionConfigCommonColAttrs('db_port', true),
    },
    db_user: {
      label: 'ユーザ名',
      type: 'STRING',
      ...getConnectionConfigCommonColAttrs('db_user', true),
    },
    db_password: {
      label: 'パスワード',
      type: 'STRING',
      ...getConnectionConfigCommonColAttrs('db_password', true),
    },
    db_database: {
      label: 'データベース名',
      type: 'STRING',
      ...getConnectionConfigCommonColAttrs('db_database', true),
    },
    additionalParams: {
      label: 'その他の接続パラメーター設定',
      type: 'TEXT',
      inputAttrs: {
        ...codeInputCommonAttrs,
      },
      afterComponent: {
        template: `<div class="small mt-1"><span v-if="connectionType === 'database'">Knex.jsのDB接続初期化時パラメータを Javascript オブジェクト形式で指定します。 <a href="https://knexjs.org/guide/#configuration-options" target="_blank">https://knexjs.org/guide/#configuration-options</a></span></div>`,
        computed: {
          record() {
            return this.$parent.record
          },
          connectionType() {
            return this.record?.connectionType
          },
        },
      },
    },
  },
}
