import { ColumnDef } from '../../../common/$models/ModelDef'
import { CCModelFormOneRecordService } from './CCModelFormOneRecordService'
import { CCModelFormService } from '../../../plugins/ComposableModelForm/front/CCModelFormService'
import { fetchSelectionsWithColDef } from '../../../front/ModelForm'
import { ComponentPublicInstance } from 'vue'
import { matchesFilter } from '../../ComposableDataListComponents/front/FilterControlsService'

/**
 * ComposableModelInputService
 *
 * ## 役割
 * - `<ComposableModelInput/>` にて初期化される
 * - 配下に配置される input に対して、値の受け渡しを実施する
 * - columnDefOverride が渡されていたら、その内容で columnDef を上書きした状態で伝達する
 */
export class ComposableModelInputService {
  readonly colName: string
  readonly columnDefOverride: Partial<ColumnDef> | null
  CCModelFormOneRecordService: CCModelFormOneRecordService
  CCModelFormService: CCModelFormService
  vueInstance: ComponentPublicInstance
  selectionLabels: {}
  addedSelections: []
  colDef: ColumnDef | null = null
  isEnabled: boolean = true
  _columnDef: ColumnDef | null = null
  constructor({
    colName,
    columnDefOverride,
    CCModelFormOneRecordService,
    CCModelFormService,
    colDef,
    vueInstance,
  }) {
    this.colName = colName
    this.colDef = colDef
    try {
      if (columnDefOverride && Object.keys(columnDefOverride).length) {
        this.columnDefOverride = columnDefOverride
      }
    } catch (e) {
      console.error(`columnDefOverride が不正です。`, e)
    }
    this.CCModelFormOneRecordService = CCModelFormOneRecordService
    this.CCModelFormService = CCModelFormService
    this.vueInstance = vueInstance
  }

  /**
   * カラムタイプからSelectionsを取得する
   */
  async fetchSelections(colName: string, colDef: ColumnDef) {
    let list = await fetchSelectionsWithColDef({
      modelName: this.CCModelFormService.modelName,
      colDef: colDef,
      record: this.CCModelFormOneRecordService.record,
      value: this.CCModelFormOneRecordService.record[colName],
      initialValue: this.CCModelFormOneRecordService.initialRecordState[colName],
      recordRoot: this.CCModelFormOneRecordService.initialRecordState,
      callerVueInstance: this.vueInstance,
    })
    // list構造が {value, label}[] であれば、、、
    const isLabeledList =
      Object.keys(list[0] || {}).length === 2 && list[0].value !== undefined && list[0].label
    if (isLabeledList) {
      const valueList = []
      const labelMap = list.reduce((res, r) => {
        res[r.value] = r.label
        valueList.push(r.value)
        return res
      }, {})
      this.selectionLabels = labelMap
      list = valueList
    }
    if (this.addedSelections) {
      list = list.concat(this.addedSelections)
    }
    list = list.filter((v) => v !== undefined)
    return list
  }

  async calculateIsEnabledValue() {
    if (this.colDef.enableIf) {
      if (typeof this.colDef.enableIf === 'function') {
        this.isEnabled = await this.colDef.enableIf(
          this.CCModelFormOneRecordService.record,
          this.CCModelFormOneRecordService.recordRoot,
        )
      } else {
        this.isEnabled =
          Object.keys(this.colDef.enableIf).filter(
            (keyName) =>
              !(this.CCModelFormOneRecordService.record[keyName] === this.colDef.enableIf[keyName]),
          ).length === 0
      }
      return
    } else {
      if (this.colDef.enableIfByFilterQuery) {
        this.isEnabled = (matchesFilter(
          this.colDef.enableIfByFilterQuery,
          [this.CCModelFormOneRecordService.record],
        )).length > 0
        return
      } else {
        this.isEnabled = true
        return
      }
    }
  }
}
