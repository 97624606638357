import { ComposableComponentInstanceItem } from '../../ComposableComponents/ComposableComponentInstanceItem'

type ComposableDataListDefaultComponentStructureProps = {
  // ModelIndexDirectus.props
  modelName
  virtualModelName
  // filters => ComposableDataList
  itemClickEvent
  searchConditionSaveKey // TODO!
  resolveHookNamePrefix
  enableSort: boolean
  enableKeywordSearch: boolean
  enableLimitControl: boolean
  // defaultLimit // => ComposableDataList
  filterableColNames // TODO!
  enableHeader: boolean
  enableControls: boolean
  enablePagination: boolean
  enableExcelExportButton: boolean
  enableFilter: boolean
  // queryFilters // => ComposableDataList
  // urlQueryFilterChangeable // => ComposableDataList
  headerTitle: string
  // enableFixedFilterStateDisplay: boolean // => 廃止!
  // limitLabel // => 廃止!
  // listColNames // => 廃止!
  // initialPage // => 廃止!

  // ModelIndexDirectus.computed
  shouldEnableAddNewRecordButton
  // [key in keyof typeof ModelIndexDirectusProps]: any
}

/**
 * 一覧表示のコンポーネントStructure を返す関数
 * - Builder および、一覧表示のデフォルト構造を生成する際に使用する
 */
export const generateComposableDataListDefaultComponentStructure = async (
  args: Partial<ComposableDataListDefaultComponentStructureProps> & any,
  calledFromModelIndexVueComponent: boolean = false,
  { itemClickFunction = null, disableBulkActions = false } = {},
): Promise<ComposableComponentInstanceItem[]> => {
  const resolveHookNameForModelIndexPrefix = `${args.resolveHookNamePrefix || ''}modelIndex.model:${
    args.modelName
  }${args.virtualModelName ? `.virtualModel:${args.virtualModelName}` : ''}`
  return [
    {
      label: 'Wrapper',
      component: 'FlexComponentsContainer',
      isDeleting: false,
      configuredProps: {
        display: 'flex',
        'row-gap': '0',
        'flex-wrap': 'wrap',
        'column-gap': '10',
        'flex-direction': 'column',
      },
      advancedSettings: {},
      children: [
        {
          _invisible: args.enableHeader === false,
          label: 'ページヘッダー',
          component: 'PageHeader',
          children: [
            {
              label: 'ページヘッダー 左',
              children: [
                {
                  label: '{{ Dynamic }} タイトル',
                  component: 'TextContent',
                  styleProps: {
                    classes: 'mb-0 bold',
                  },
                  configuredProps: {
                    t__tag: 'h6',
                    t__isHtml: true,
                    t__content: '',
                    t__htmlContent:
                      args.headerTitle ||
                      "{{ $core.$utils.findNearestParentVueComponentByName(this, 'ComposableDataList')?.ComposableDataListServiceInstance?.displayModelName }} 一覧",
                  },
                },
              ],
              component: 'FlexComponentsContainer',
              styleProps: {},
              configuredProps: {
                display: 'flex',
                'row-gap': '10',
                'flex-wrap': 'wrap',
                'column-gap': '10',
                'align-items': 'center',
                'flex-direction': 'row',
              },
              advancedSettings: {},
            },
            {
              label: 'ページヘッダー 右',
              children: [
                {
                  component: 'AppHookableComponent',
                  configuredProps: {
                    resolveHookName: `${resolveHookNameForModelIndexPrefix}.excelExportButton`,
                  },
                  children: args.enableExcelExportButton === false ? [] : [excelExportButtonComponent],
                },
                // @ts-ignore
                {
                  component: 'AppHookableComponent',
                  configuredProps: {
                    resolveHookName: `${resolveHookNameForModelIndexPrefix}.headerCreateNewButton`,
                  },
                  children:
                    args.shouldEnableAddNewRecordButton === false ? [] : [createNewButtonComponent],
                },
                // @ts-ignore
                calledFromModelIndexVueComponent
                  ? {
                      /**
                       * <app-hookable-component
                       *           :resolveHookName="
                       *             `${resolveHookNamePrefix}modelIndex.model:${modelName}${
                       *               virtualModelName ? `.virtualModel:${virtualModelName}` : ''
                       *             }.headerAppend`
                       *           "
                       *         />
                       */
                      component: 'AppHookableComponent',
                      configuredProps: {
                        resolveHookName: `${resolveHookNameForModelIndexPrefix}.headerAppend`,
                      },
                    }
                  : {},
              ],
              component: 'FlexComponentsContainer',
              isDeleting: false,
              styleProps: {},
              configuredProps: {
                display: 'flex',
                'row-gap': '10',
                'flex-wrap': 'wrap',
                'column-gap': '10',
                'align-items': 'center',
                'flex-direction': 'row',
                'justify-content': 'space-between',
              },
              advancedSettings: {},
            },
          ],
          advancedSettings: {},
        },
        {
          _invisible: true,
          label: 'フィルタータブ コンテナー',
          children: [
            {
              label: 'フィルタータブ',
              children: [],
              component: 'DataListQueryFilterGroupToggle',
              styleProps: {
                classes: 'w-100 bg-white px-3 small',
              },
              configuredProps: {
                itemTag: 'span',
                itemClass: 'nav-item',
                filterGroups: [
                  {
                    label: '開始前',
                    tempKey: 0.893905308547406,
                    showCount: true,
                    initActive: false,
                    __func__filter: "return {\n  title: {\n    _contains: 'サンプル',\n  }\n}",
                  },
                  {
                    label: '確認待ち',
                    tempKey: 0.8885171529108438,
                    showCount: true,
                    initActive: true,
                  },
                  {
                    label: '進行中',
                    tempKey: 0.9163849480771034,
                    showCount: true,
                  },
                  {
                    label: '完了',
                    tempKey: 0.6204314407042417,
                  },
                ],
                itemChildTag: 'span',
                wrapperClass: 'nav nav-tabs',
                groupingStyle: 'tabs',
                itemChildClass: 'nav-link',
                itemClassActive: '',
                itemChildClassActive: 'nav-link active',
              },
              advancedSettings: {},
            },
          ],
          component: 'FlexComponentsContainer',
          styleProps: {},
          configuredProps: {
            display: 'flex',
            'row-gap': '10',
            'flex-wrap': 'wrap',
            'column-gap': '10',
            'flex-direction': 'row',
          },
          advancedSettings: {},
        },
        {
          label: 'Main',
          component: 'FlexComponentsContainer',
          isDeleting: false,
          styleProps: {
            styles: 'max-width: 100%;',
            classes: 'px-3 py-1',
          },
          configuredProps: {
            display: 'flex',
            'column-gap': '5',
            'row-gap': '5',
            'flex-direction': 'row',
          },
          children: [
            {
              component: 'AppHookableComponent',
              configuredProps: {
                resolveHookName: `${resolveHookNameForModelIndexPrefix}.indexListBefore`,
              },
              styleProps: {
                classes: 'w-100',
              },
              children: [
                {
                  label: '一覧前 HelpDoc',
                  component: 'RemoteCOREHelpDoc',
                  configuredProps: {},
                },
              ],
            },
            {
              _invisible: args.enableControls === false,
              label: '検索 コンテナー',
              component: 'FlexComponentsContainer',
              styleProps: {
                classes: 'w-100',
                styles: null,
              },
              configuredProps: {
                display: 'flex',
                'column-gap': '10',
                'row-gap': '10',
                'flex-direction': 'row',
                'flex-wrap': 'wrap',
                'justify-content': 'space-between',
                'align-items': 'center',
                'align-content': null,
              },
              advancedSettings: {
                useBeforeInitFunction: null,
              },
              children: [
                {
                  label: '検索フィルター',
                  component: 'FlexComponentsContainer',
                  configuredProps: {
                    'column-gap': '6',
                  },
                  children: [
                    ...((args.enableKeywordSearch === false ||
                    args?.model?.enableKeywordSearch === false)
                      ? []
                      : [
                          {
                            component: 'FlexComponentsContainer',
                            configuredProps: {
                              'row-gap': 0,
                              'column-gap': 0,
                            },
                            styleProps: {
                              classes: 'input-group input-group-sm w-auto',
                            },
                            children: [
                              {
                                children: [
                                  {
                                    label: 'アイコン',
                                    children: [],
                                    component: 'Ficon',
                                    isDeleting: false,
                                    configuredProps: {
                                      type: 'search',
                                    },
                                    advancedSettings: {},
                                  },
                                ],
                                component: 'SimpleContainer',
                                styleProps: {
                                  classes: 'input-group-text',
                                },
                              },
                              {
                                label: 'キーワード検索',
                                component: 'DataListKeywordFilter',
                              },
                            ],
                          },
                        ]),
                    {
                      _invisible: args.enableFilter === false,
                      label: '絞り込み検索 (カラム名選択式)',
                      component: 'FilterResultDisplayContainer',
                      configuredProps: {
                        addFilterButtonText: '',
                      },
                    },
                  ],
                },
                {
                  label: '表示条件',
                  component: 'FlexComponentsContainer',
                  configuredProps: {
                    'align-items': 'center',
                  },
                  children: [
                    {
                      component: 'FlexComponentsContainer',
                      configuredProps: {
                        'row-gap': 0,
                        'column-gap': 0,
                      },
                      styleProps: {
                        classes: 'input-group input-group-sm w-auto',
                      },
                      children:
                        args.enableLimitControl === false
                          ? []
                          : [
                              {
                                label: '表示件数',
                                component: 'DataListLimitControl',
                                styleProps: {
                                  classes: '_d-inline-block _w-auto',
                                  styles: '',
                                },
                              },
                            ],
                    },
                    {
                      _invisible: true,
                      component: 'FlexComponentsContainer',
                      configuredProps: {
                        'row-gap': 0,
                        'column-gap': 0,
                      },
                      styleProps: {
                        classes: 'input-group input-group-sm w-auto',
                      },
                      children: [
                        // {
                        //   component: 'TextContent',
                        //   configuredProps: {
                        //     t__tag: 'span',
                        //     t__isHtml: false,
                        //     t__content: '表示件数',
                        //   },
                        //   styleProps: {
                        //     classes: 'input-group-text',
                        //   },
                        // },
                        args.enableSort === false
                          ? null
                          : {
                              label: '表示件数',
                              component: 'DataListSortSelection',
                              configuredProps: {
                                enableMultiSelect: false,
                              },
                              styleProps: {
                                classes: '_d-inline-block _w-auto',
                                styles: '',
                              },
                            },
                      ],
                    },
                  ],
                },
              ],
            },
            args?.model?.enableSearchConditionSave === true
              ? {
                  label: 'ModelDataFilterSavedSearchConditions',
                  component: 'ModelDataFilterSavedSearchConditions',
                  configuredProps: {},
                  children: [],
                  styleProps: {
                    styles: '',
                    classes: '',
                  },
                  advancedSettings: {},
                }
              : null,
            {
              label: '一覧 Wrapper',
              component: 'DataListDisplayContainer',
              configuredProps: {
                itemClickFunction,
                disableBulkActions,
              },
              children: [
                {
                  label: '一括操作',
                  component: 'DataListBulkControlAction',
                },
                {
                  _invisible: args.enablePagination === false,
                  label: 'ページ切り替え',
                  children: [],
                  component: 'DataListPagination',
                  styleProps: {
                    styles: null,
                    classes: null,
                  },
                  configuredProps: {
                    displayType: null,
                  },
                  advancedSettings: {
                    useBeforeInitFunction: null,
                  },
                },
                calledFromModelIndexVueComponent
                  ? {
                      component: 'AppHookableComponent',
                      configuredProps: {
                        resolveHookName: `${resolveHookNameForModelIndexPrefix}.indexList`,
                      },
                      children: [listTableComponent],
                    }
                  : listTableComponent,
              ],
            },
          ],
        },
      ],
    },
  ]
}

const listTableComponent = {
  label: '一覧テーブル',
  children: [],
  component: 'DataListDisplayTable',
  styleProps: {},
  configuredProps: {
    tableStyle: 'default',
  },
}

const openCreateNewModalFunc = `const dataList = $core.$utils.findNearestParentVueComponentByName(thisInstance, 'ComposableDataList')
const modelName = dataList.modelName
const virtualModelName = dataList.virtualModelName
const shouldHide = $core.$models[modelName]?.creatable === false || $core.$virtualModels[virtualModelName]?.creatable === false
return {
  class: shouldHide ? ['d-none'] : [],
  onClick: () => {
    if ($core.$router.currentRoute.name === 'fallback_model' && $core.$modals.openingModalCount === 0) {
      return $core.$router.push(\`/m/\${modelName}/new\${virtualModelName ? '?virtualModel=' + virtualModelName : ''}\`)
    }
    return $core.$modals.openCreateViewModal({
      modelName,
      virtualModelName: dataList.virtualModelName,
    })
  }
}`

const createNewButtonComponent = {
  label: '新規追加ボタン',
  component: 'BButton',
  isDeleting: false,
  styleProps: {
    // classes: 'btn-sm',
  },
  configuredProps: {
    variant: 'primary',
  },
  advancedSettings: {
    useBeforeInitFunction: true,
    __func__beforeInitFunction: openCreateNewModalFunc,
  },
  children: [
    {
      label: 'アイコン',
      children: [],
      component: 'Ficon',
      isDeleting: false,
      styleProps: {
        classes: 'mr-1',
      },
      configuredProps: {
        type: 'plus',
      },
      advancedSettings: {},
    },
    {
      label: '新規追加',
      children: [],
      component: 'TextContent',
      styleProps: {
        classes: 'mb-0 _bold',
      },
      configuredProps: {
        t__tag: 'span',
        t__isHtml: false,
        t__content: '新規追加',
      },
    },
  ],
}

const excelExportButtonComponent = {
  label: 'Excelエクスポート',
  children: [
    {
      label: 'アイコン',
      children: [],
      component: 'Ficon',
      isDeleting: false,
      styleProps: {
        classes: 'mr-1',
      },
      configuredProps: {
        type: 'cloud-download-alt',
      },
      advancedSettings: {},
    },
    {
      label: 'コンテンツ',
      children: [],
      component: 'TextContent',
      styleProps: {},
      configuredProps: {
        t__content: 'Excelエクスポート',
      },
      advancedSettings: {},
    },
  ],
  component: 'BButton',
  styleProps: {},
  configuredProps: {
    variant: 'outline-secondary',
  },
  advancedSettings: {
    useBeforeInitFunction: true,
    __func__beforeInitFunction:
      "const dataList = $core.$utils.findNearestParentVueComponentByName(thisInstance, 'ComposableDataList')\nreturn {\n  onClick: () => { dataList.ComposableDataListServiceInstance.exportAsExcel() }\n}",
  },
}
