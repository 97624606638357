<template>
  <app-hookable-component
    :id="$route.params.id"
    :passedId="$route.params.id"
    :modelName="$route.params.fallback_model"
    :virtualModelName="$route.query.virtualModel"
    :virtualModel="$virtualModels ? $virtualModels[$route.query.virtualModel] : null"
    :resolveHookName="
      `$CORE.admin.resolveComponent.model.${$route.query.virtualModel ||
        $route.params.fallback_model}.pages.view`
    "
    :defaultComponentResolver="
      () => $frameworkUtils.defineAsyncComponent(() => import('../../ModelView/fireModelViewPage.vue'))
    "
  />
</template>
