<template>
  <component :is="t__tag || 'div'">
    <slot />
  </component>
</template>

<script lang="ts">
import { registerComposableComponentSettings } from '../../../../../plugins/ComposableComponents'
import { ColumnDefByColName } from '../../../../../common/$models/ModelDef'

export const composableComponentTextContentConfigColumns: ColumnDefByColName = {
  t__tag: {
    label: '要素のタグ',
    type: 'STRING',
  },
}
const name = 'SimpleContainer'
registerComposableComponentSettings(name, {
  label: 'コンテナ',
  category: 'container',
  hasDefaultSlot: true,
  configColumns: composableComponentTextContentConfigColumns,
  defaultProps: {
    t__tag: 'div',
  },
})

/**
 * シンプルなテキストコンテンツ, TextNode と同じようなイメージで、各種 ComposableComponent が継承して使用することも可能
 */
export default {
  name: name,
  props: {
    t__tag: {
      default: 'div',
    },
  },
}
</script>
