<template>
  <div style="max-width: 100%; width: 100%">
    <slot />
  </div>
</template>
<script lang="ts">
import { computed, inject, PropType } from 'vue'
import { ComposableDataListService } from '../../ComposableDataListService'
import { DataListDisplayService } from './DataListDisplayService'

/**
 * DataListDisplayContainer, データ一覧を表示する際のWrapperとなるコンポーネント
 *
 * ## 役割
 * - データのリスト および 一覧表示の際の設定値を保持 & provide する
 *
 * ## 配下のコンポーネント
 * - 一覧表示Table, List
 * - 一括操作Actions (ボタン or selection)
 */
export default {
  name: DataListDisplayService.componentName,
  provide() {
    return {
      items: computed(() => this.dataList || this.ComposableDataListServiceInstance.items),
      DataListDisplayServiceInstance: this.DataListDisplayServiceInstance,
    }
  },
  props: {
    /**
     * Optional, データリストを直接渡す場合に使用する
     */
    dataList: {
      type: Array,
      default: null,
    },
    isSpecifyColumns: {
      type: Boolean,
      default: false,
    },
    specifiedColumns: {
      type: Array,
      default: () => [],
    },
    disableBulkActions: {
      type: Boolean,
      default: false,
    },
    itemClickFunction: {
      type: Function as PropType<DataListDisplayService['invokeItemClickFunction']>,
      default: null,
    },
  },
  setup() {
    return {
      ComposableDataListServiceInstance: inject<ComposableDataListService>(
        'ComposableDataListServiceInstance',
      ),
    }
  },
  data() {
    return {
      DataListDisplayServiceInstance: new DataListDisplayService(
        this.ComposableDataListServiceInstance,
      ),
    }
  },
  created() {
    this.DataListDisplayServiceInstance.isSpecifyColumns = this.isSpecifyColumns
    this.DataListDisplayServiceInstance.specifiedColumns = this.specifiedColumns
    this.DataListDisplayServiceInstance.disableBulkActions = this.disableBulkActions
    this.DataListDisplayServiceInstance.itemClickFunction = this.itemClickFunction
  },
  computed: {
    items() {
      return this.ComposableDataListServiceInstance.items
    },
    listLastUpdated() {
      return this.ComposableDataListServiceInstance?.dataList?.listLastUpdated || null
    },
  },
}
</script>
