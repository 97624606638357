import { ModelDef } from '../../types'
// @ts-ignore
import { md5 } from '../md5'

const model: ModelDef = {
  tableName: 'notifications',
  tableLabel: 'システムアラート',
  bulkControllable: true,
  tableComment: '',
  defaultSort: { key: 'createdAt', order: 'desc' },
  columns: {
    message: {
      label: 'システムメッセージ',
      type: 'TEXT',
      editable: false,
      comment: 'システムから発行される、対応が必要な内容を記載します。',
      validate: {
        notNull: true,
        notEmpty: true,
      },
      inputAttrs: {
        wrapperClass: 'col-12',
      },
    },
    category: {
      label: 'カテゴリ',
      editable: false,
      facet: { showOnSearch: true },
      type: 'STRING', // TODO: Do we need some models?
    },
    unique_key: {
      label: '一意ユニークキー',
      editable: false,
      unique: true,
      comment:
        'メッセージをユニークにしたい場合に設定するキー。設定しないことも可能 (Allow NULL) ユースケースとしては例えば、"製品品番ABC-123を追加してください" というNotiを追加したいが、重複して追加したくない場合にこのカラムに値を設定する。 `product_code-undefined-ABC-123` などとする。',
      // visible: false,
      type: 'STRING',
    },
    operation_status: {
      label: '対応状況',
      type: 'SELECT',
      strictSelections: true,
      selections: () => ['要対応', '対応不要', '対応済み'],
      default: '要対応',
      facet: { showOnSearch: true },
    },
    note: {
      label: 'メモ',
      type: 'TEXT',
      inputAttrs: { rows: 4, wrapperClass: 'col-12' },
    },
    ignoreUntilDate: {
      label: '指定日までは無視する',
      type: 'DATEONLY',
      editCallback: ({ row, newValue, oldValue }) => {
        if (newValue !== oldValue) {
          row.ignoreUntilDate_int = newValue ? Number(newValue.replace(/-/g, '')) : ''
        }
        return row
      },
    },
    ignoreUntilDate_int: {
      type: 'NUMBER',
      facet: true,
      visible: false,
    },
    hasIgnoreUntilDate: {
      type: 'BOOLEAN',
      facet: true,
      visible: false,
    },
    対象月YYYYMM: {
      type: 'NUMBER',
      facet: true,
      visible: false,
    },
  },
  async beforeSave(row) {
    if (row.unique_key) {
      row.id = md5(row.unique_key)
    }
    row.hasIgnoreUntilDate = !!row.ignoreUntilDate
    if (row.hasIgnoreUntilDate === false) {
      row.ignoreUntilDate_int === 0
    }
    return row
  },
  modelType: 'admin',
}

export const notifications = model
