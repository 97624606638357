import { ModelDef } from '../../common/$models/ModelDef'

const modelName = 'userPasswordResetSecrets'

export const userPasswordResetSecrets: ModelDef = {
  tableName: modelName,
  tableLabel: modelName,
  defaultSort: { key: 'id', order: 'desc' },
  modelType: 'admin',
  timestamps: true,
  columns: {
    userId: {
      type: 'STRING',
    },
    resetToken: {
      type: 'STRING',
    },
    requestedAt: {
      type: 'BIGINTEGER',
    },
  },
}
