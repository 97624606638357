<template>
  <ModelBulkControl
    v-if="checkedCount"
    :target-ids="checkedItemIds"
    :virtual-model-name="
      DataListDisplayServiceInstance.ComposableDataListServiceInstance.virtualModelName
    "
    :model-name="DataListDisplayServiceInstance.ComposableDataListServiceInstance.modelName"
    @clearIds="() => DataListDisplayServiceInstance.bulkCheckItemIds(false)"
    :updatable="DataListDisplayServiceInstance.ComposableDataListServiceInstance.updatable"
    :deletable="DataListDisplayServiceInstance.ComposableDataListServiceInstance.deletable"
  />
</template>
<script lang="ts">
import { DataListDisplayService } from '../../../ComposableDataListComponents/front/components/DataListDisplay/DataListDisplayService'
import { inject } from 'vue'
import { registerComposableComponentSettings } from '../../../ComposableComponents'

const name = 'DataListBulkControlAction'
registerComposableComponentSettings(name, {
  hasDefaultSlot: false,
})

export default {
  setup() {
    return {
      DataListDisplayServiceInstance: inject<DataListDisplayService>(
        'DataListDisplayServiceInstance',
      ),
    }
  },
  computed: {
    checkedItemId() {
      return this.DataListDisplayServiceInstance.checkedItemId
    },
    checkedItemIds() {
      return Object.keys(this.DataListDisplayServiceInstance.checkedItemId)
    },
    checkedCount() {
      return this.checkedItemIds?.length || 0
    },
  },
}
</script>
