import { ModelDef } from '../../../common/$models/ModelDef'
import { generateCoreRecordCommentsModelAndColumns } from '../models/generateCoreRecordCommentsModelAndColumns'
import {
  getExtensionCommentsModelNameByBaseModelName, ModelExtensionRecordCommentsConfig,
  recordCommentManyToOneRelationshipColName,
  recordCommentOneToManyColName,
} from './coreRecordCommentConstants'

/**
 * Model定義の commentable が true ならば、そのModelごとに comment用Model `${modelName}__extension__comments` を追加生成する
 * Front, ServerSide 両方で利用される
 * - Front: Model定義初期化時
 * - ServerSide: syncModels 実行時
 * @param models
 */
export const generateCommentExtensionModelBasedOnModelDefCommentable = (
  models: ModelDef[],
): ModelDef[] => {
  return models.reduce((res, model) => {
    if(model.extensions && !Array.isArray(model.extensions)) {
      console.warn(`[generateCommentExtensionModelBasedOnModelDefCommentable] Model ${model.tableName} の extensions が配列でないため、処理をスキップします。model.extensions:`, model.extensions)
      return res
    }
    const recordCommentExtensionConfigs = model.extensions?.filter(
      (ex) => ex.extensionType === 'recordComments',
    ) as ModelExtensionRecordCommentsConfig[]
    if (recordCommentExtensionConfigs?.length) {
      for (const config of recordCommentExtensionConfigs) {
        // model.extensions[]. が true ならば、そのModelごとに
        if (config?.enabled === true) {
          // comment用Model `${modelName}__extension__comments` or 設定した名称 で追加する
          const commentExtensionModelName =
            config.extensionModelName ||
            getExtensionCommentsModelNameByBaseModelName(model.tableName)
          if (!models.find((m) => m.tableName === commentExtensionModelName)) {
            res.push(generateCoreRecordCommentsModelAndColumns(model) as ModelDef)
          }
          // 元Model定義 から コメントをリレーションで取得できるように O2M カラムを付与する
          const _recordCommentOneToManyColName =
            config.recordCommentOneToManyColName || recordCommentOneToManyColName
          if (
            config.disableAddOneToManyRelationship !== true &&
            !model.columns[_recordCommentOneToManyColName]
          ) {
            model.columns[_recordCommentOneToManyColName] = {
              label: 'レコードコメント',
              visible: false,
              type: 'RELATIONSHIP_ONE_TO_MANY',
              relationshipOneToMany: {
                collectionName: commentExtensionModelName,
                fieldName: recordCommentManyToOneRelationshipColName,
              },
            }
          }
        }
      }
    }

    res.push(model)
    return res
  }, [])
}
