// @ts-nocheck
import { ModelFactory } from '../../common/$models'
import { VirtualModel } from '../../common/$virtualModels/VirtualModel'
import { stringJsExpressionIntoFunctionConverter } from '../../common/StringToFunctionConverter/StringToFunctionConverter'
import { generateSidebarLinksToOtherApps } from './generateSidebarLinksToOtherApps'

export type AppDefinitionMainMenu = {
  linkType: 'model' | 'vmodel' | 'heading' | 'free' | 'component'
  label?: string
  linkTarget?: string
  linkTargetModel?: string
  linkTargetVModel?: string
  component?: string
  userRoleRestrictions?: string[]
  icon?: string
  children?: AppDefinitionMainMenu[]
  cssClass?: string
  amountBadgeVisible?: boolean
  amountBadgeTargetModel?: string
  amountBadgeFilterCondition?: string
  amountBadgeStyle?: string
  amountBadgeStyleWhenZero?: string
}
export type AppMenuItem = {
  cssClass?: string
  icon?: string
  amountBadgeVisible?: boolean
  amountBadgeTargetModel?: string
  amountBadgeFilterCondition?: string
  amountBadgeStyle?: string
  amountBadgeStyleWhenZero?: string
  useActiveLinkRegex?: boolean
  activeLinkRegex?: string
  activeLinkRegexFlags?: string
} & (
  | {
      type: 'link'
      label: string
      to: string
    }
  | {
      type: 'heading'
      label: string
    }
  | {
      type: 'component'
      componentDefinition: any
    }
  | {
      type: 'nested'
      label: string
      children: AppMenuItem[]
    }
)

export const genLinkWithModelNames = (modelNames: string[]): AppMenuItem[] => {
  return modelNames.map((modelName) => {
    try {
      return genLinkWithModelDef($core.$models[modelName])
    } catch (e) {
      console.error(modelName, e)
    }
  })
}

export const genLinkWithModelDef = (
  modelDef: ModelFactory | VirtualModel,
  {
    icon,
    cssClass,
    amountBadgeVisible,
    amountBadgeTargetModel,
    amountBadgeFilterCondition,
    amountBadgeStyle,
  } = { icon: '', cssClass: '' },
): AppMenuItem | null => {
  try {
    return {
      type: 'link',
      label: modelDef.tableLabel || modelDef.tableName,
      // @ts-ignore
      to: modelDef.baseModel
        ? // @ts-ignore
          `/m/${modelDef.baseModel}?virtualModel=${modelDef.name}`
        : `/m/${modelDef.tableName}`,
      icon: icon,
      cssClass: cssClass,
      amountBadgeVisible: amountBadgeVisible,
      amountBadgeTargetModel: amountBadgeTargetModel,
      amountBadgeFilterCondition: amountBadgeFilterCondition,
      amountBadgeStyle: amountBadgeStyle,
    }
  } catch (e) {
    console.warn(e)
    return null
  }
}

const linkGenFunc = {
  model: (menuDef: AppDefinitionMainMenu) =>
    genLinkWithModelDef($core.$models[menuDef.linkTargetModel], menuDef || {}),
  vmodel: (menuDef: AppDefinitionMainMenu) =>
    genLinkWithModelDef($core.$virtualModels[menuDef.linkTargetVModel], menuDef || {}),
  heading: (menuDef: AppDefinitionMainMenu) => ({
    type: 'heading',
    label: menuDef.label,
    icon: menuDef.icon,
  }),
  free: (menuDef: AppDefinitionMainMenu) => ({
    ...menuDef,
    type: 'link',
    to: menuDef.linkTarget,
  }),
  component: (menuDef: AppDefinitionMainMenu) => {
    const componentDefinition = stringJsExpressionIntoFunctionConverter(menuDef.component)()
    return {
      type: 'component',
      componentDefinition,
    }
  },
  nested: (menuDef: AppDefinitionMainMenu) => {
    return {
      type: 'nested',
      label: menuDef.label,
      icon: menuDef.icon,
      children: parseMainMenuDefinitionIntoAppMenus(menuDef.children), //.map((c) => this[c.linkType]),
    }
  },
}

export const getSidebarLinksBasedOnAppDefinition = (includeOtherAppLinks: boolean = true): AppMenuItem[] => {
  const menuLinks = parseMainMenuDefinitionIntoAppMenus(
    $core.$appDefinitionLoader.appDefinition.mainMenus,
  )
  // 他のアプリがあるならそのリンクを表示
  if(!includeOtherAppLinks) {
    return menuLinks
  }
  const linksToOtherApps = generateSidebarLinksToOtherApps()
  return menuLinks.concat(...linksToOtherApps)
}

/**
 * Parse AppDefinitionMainMenu[] into AppMenuItem[]
 * @param menus
 */
const parseMainMenuDefinitionIntoAppMenus = (menus: AppDefinitionMainMenu[]): AppMenuItem[] => {
  // 権限によるFiltering
  const roleFilteredMenus = filterOutMenuWithUserRoleRestrictions(menus)
  // マニュアル設定を返却
  return roleFilteredMenus.map((menuDef) => {
    return linkGenFunc[menuDef.linkType](menuDef)
  }) as AppMenuItem[]
}

const filterOutMenuWithUserRoleRestrictions = (
  menus: AppDefinitionMainMenu[],
): AppDefinitionMainMenu[] => {
  return menus.filter((menu) => {
    if (
      !menu.userRoleRestrictions ||
      menu.userRoleRestrictions.length === 0 ||
      $core.$embAuth.user.isAdmin === true
    ) {
      return true
    }
    // TODO: strange...
    const userRoles =
      typeof menu.userRoleRestrictions === 'string'
        ? [menu.userRoleRestrictions]
        : menu.userRoleRestrictions
    // ログインしているユーザが、そのroleに所属しているかどうか？をチェックする
    return (
      userRoles.filter((restrictionRoleKey) => {
        return (
          $core.$embAuth.user.coreRoles &&
          $core.$embAuth.user.coreRoles?.indexOf(restrictionRoleKey) >= 0
        )
      }).length > 0
    )
  })
}

export const getAmountBadgeCount = async (modelName, filterCondition) => {
  try {
    const parseFilter = (typeof filterCondition === 'string' && filterCondition ? JSON.parse(filterCondition) : filterCondition) || {}
    return await $core.$models[modelName].countBy(parseFilter)
  } catch (e) {
    console.error(e)
    return null
  }
}
