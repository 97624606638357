<template>
  <ul v-if="initialized" class="nav">
    <AppMenuItemList
      v-for="(menuItem, index) in displayingNavLinks"
      :key="`main-${index}`"
      :menuItem="menuItem"
    />
  </ul>
</template>
<script lang="ts">
import { getSidebarLinksBasedOnAppDefinition } from './appMenuDefinitionParser'
import AppMenuItemList from './AppMenuItemList.vue'

export default {
  props: {
    showOtherAppLinks: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      navLinks: [],
      initialized: false,
      menuState: 'main',
    }
  },
  components: {
    AppMenuItemList,
  },
  computed: {
    displayingNavLinks() {
      return this.navLinks
    },
  },
  created() {
    this.reload()
  },
  methods: {
    loadNavLinksFromModelDefinitions() {
      this.navLinks = getSidebarLinksBasedOnAppDefinition(this.showOtherAppLinks)
    },
    reload() {
      this.initialized = false
      this.loadNavLinksFromModelDefinitions()
      this.$nextTick(() => {
        this.initialized = true
      })
    },
  },
  watch: {
    // 更新したらメニューをReload
    '$core.$uiState.latestModelDefinitionLoadedTime'(newVal, oldVal) {
      this.$nextTick(() => {
        this.reload()
      })
    },
  },
}
</script>
