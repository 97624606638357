<template>
  <div>
    <router-view></router-view>
    <MemoryUsageDisplayPanel />
    <OfflineDetectMessage />
    <ModalContainer />
    <ControlDisplayOfAppHookDetail v-if="!$core.isProduction" class="core-admin-role-only" />
  </div>
</template>
<script lang="ts">

export default {
  name: 'LoggedOutLayout',
}
</script>

