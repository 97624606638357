<template>
  <div v-if="initialized" style="max-width: 100%">
    <slot />
  </div>
</template>
<script lang="ts">
import { registerComposableComponentSettings } from '../../../ComposableComponents'
import { ColumnDef, ColumnDefByColName } from '../../../../common/$models/ModelDef'
import { CCModelFormService } from '../CCModelFormService'
import {
  modelNameSelectColumnDefWithClearVirtualModelNameColumnOnEditCallback,
  virtualModelNameSelectColumnDefWithUpdateModelNameColumnOnEditCallback,
} from '../../../../common/$models/modelsSelectionsHelperFunctions'
import { codeInputCommonAttrs } from '../../../../common/$models'
import { PropType } from 'vue'

/**
 * CModelFormの設定カラム
 */
export const composableComponentCModelFormConfigColumns: ColumnDefByColName = {
  modelName: {
    ...modelNameSelectColumnDefWithClearVirtualModelNameColumnOnEditCallback('virtualModelName'),
  },
  virtualModelName: {
    ...virtualModelNameSelectColumnDefWithUpdateModelNameColumnOnEditCallback('modelName'),
  },
  columnsAsArray: {
    label: 'カラム定義',
    type: 'ARRAY_OF_OBJECT',
    columns: {
      name: {
        type: 'STRING',
        label: 'プロパティ名',
      },
      label: {
        type: 'STRING',
        label: 'ラベル',
      },
      otherAttrsFunction: {
        type: 'TEXT',
        label: 'その他の属性',
        inputAttrs: {
          ...codeInputCommonAttrs,
          rows: 8,
        },
      },
    },
  },
  enableMultiRecordEdit: {
    label: '複数レコード編集',
    type: 'BOOLEAN',
  },
  initialRecords: {
    label: '初期レコード',
    type: 'ARRAY_OF_OBJECT',
  },
  recordId: {
    label: '編集フォーム用レコードID',
    type: 'STRING',
  },
  recordFindCondition: {
    label: '編集フォーム用レコード検索条件',
    type: 'TEXT',
    inputAttrs: {
      rows: 8,
    },
  },
  onSubmitFunction: {
    label: 'onSubmit関数',
    type: 'TEXT',
    inputAttrs: {
      rows: 8,
    },
  },
  onDeleteFunction: {
    label: 'onDelete関数',
    type: 'TEXT',
    inputAttrs: {
      rows: 8,
    },
  },
  readOnlyMode: {
    label: '読み取り専用モード',
    type: 'BOOLEAN',
  },
  filterCondition: {
    label: 'フィルター条件',
    type: 'TEXT',
    inputAttrs: {
      rows: 8,
      ...codeInputCommonAttrs,
    },
  },
}
const name = 'ComposableModelForm'
registerComposableComponentSettings(name, {
  label: 'CCModelForm',
  hasDefaultSlot: true,
  category: 'フォーム',
  configColumns: composableComponentCModelFormConfigColumns,
  defaultProps: {
    modelName: '',
    virtualModelName: '',
    initialRecords: [],
    onSubmitFunction: '',
    onDeleteFunction: '',
    readOnlyMode: false,
    filterCondition: '',
    recordId: '',
    recordFindCondition: '',
  },
})

export default {
  name,
  props: {
    modelName: {
      type: String,
      default: '',
    },
    virtualModelName: {
      type: String,
      default: '',
    },
    initialRecords: {
      type: Array,
      default: () => [],
    },
    onSubmitFunction: {
      type: String,
      default: '',
    },
    onDeleteFunction: {
      type: String,
      default: '',
    },
    readOnlyMode: {
      type: Boolean,
      default: false,
    },
    filterCondition: {
      type: Object,
      default: null,
    },
    // カラム定義の配列を渡す
    columnsAsArray: {
      type: Array as PropType<(ColumnDef & { name: string })[]>,
      default: () => [],
    },
    recordId: {
      type: String,
      default: '',
    },
    recordFindCondition: {
      type: Object,
      default: null,
    },
    // // js, vue 個別で指定する場合に利用する => 廃止。
    // columns: {
    //   type: Object as PropType<ColumnDefByColName>,
    //   default: null,
    // },
  },
  provide() {
    return {
      CCModelFormServiceInstance: this.CCModelFormServiceInstance,
    }
  },
  data() {
    return {
      CCModelFormServiceInstance: new CCModelFormService(),
      initialized: false,
    }
  },
  computed: {
    useColumns(): ColumnDefByColName | null {
      if (!this.columnsAsArray?.length) {
        return null
      }
      // 例外処理する
      if (this.columnsAsArray.length == 1 && !this.columnsAsArray[0].name) {
        return null
      }
      return this.columnsAsArray.reduce((acc, cur) => {
        acc[cur.name] = cur
        return acc
      }, {} as ColumnDefByColName)
    },
  },
  created() {
    if (this.modelName) {
      this.CCModelFormServiceInstance.modelName = this.modelName
    }
    if (this.virtualModelName) {
      this.CCModelFormServiceInstance.virtualModelName = this.virtualModelName
    }
    // modelName or virtualModelName かつ useColumns も falsy である場合には、初期化しない
    if (!this.CCModelFormServiceInstance.model && !this.useColumns) {
      console.error(
        `[${name}] modelName or virtualModelName or useColumns を正しく設定してください。`,
      )
      return
    }
    this.CCModelFormServiceInstance.initialRecords = this.initialRecords
    this.CCModelFormServiceInstance.onSubmitFunction = this.onSubmitFunction
    this.CCModelFormServiceInstance.onDeleteFunction = this.onDeleteFunction
    this.CCModelFormServiceInstance.readOnlyMode = this.readOnlyMode
    if (this.filterCondition) {
      if (typeof this.filterCondition === 'string') {
        try {
          this.CCModelFormServiceInstance.filterCondition = $core.$utils.tryParseAsObject(
            this.filterCondition,
          )
        } catch (error) {
          console.log(error)
        }
      } else {
        this.CCModelFormServiceInstance.filterCondition = this.filterCondition
      }
    }
    if (this.useColumns) {
      this.CCModelFormServiceInstance.useColumns = this.useColumns
    }
    // urlのqueryからIDを取得する
    const recordId = this.$route.query.id
    if (recordId) {
      this.CCModelFormServiceInstance.recordId = recordId as string
    } else if (!!this.recordId && this.recordId !== '') {
      this.CCModelFormServiceInstance.recordId = this.recordId
    }
    if (this.recordFindCondition) {
      if (typeof this.recordFindCondition === 'string') {
        try {
          this.CCModelFormServiceInstance.recordFindCondition = $core.$utils.tryParseAsObject(
            this.recordFindCondition,
          )
        } catch (error) {
          console.log(error)
        }
      } else {
        this.CCModelFormServiceInstance.recordFindCondition = this.recordFindCondition
      }
    }
    this.$nextTick(async () => {
      await this.CCModelFormServiceInstance.init()
      this.initialized = true
    })
  },
}
</script>
