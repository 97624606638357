<template>
  <tbody>
    <DataListDisplayTableBodyRow
      v-for="(item, index) in items"
      :index="index"
      :key="item.id"
      :record="item"
      :item="item"
    >
      <slot />
    </DataListDisplayTableBodyRow>
  </tbody>
</template>
<script lang="ts">
import { inject } from 'vue'
import { registerComposableComponentSettings } from '../../../../../ComposableComponents'

const name = 'DataListDisplayTableBody'
registerComposableComponentSettings(name, {
  hasDefaultSlot: true,
})

export default {
  name: name,
  setup() {
    return {
      items: inject<any[]>('items'),
    }
  },
}
</script>
