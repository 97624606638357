<template>
  <div class="boolean-radio-input d-flex gap-2">
    <label
      class="d-flex align-items-center gap-1 pr-1 cursor-pointer"
      v-for="(item, index) in options"
      :key="index"
    >
      <input
        class="form-check-input m-0"
        type="radio"
        :id="col.name + '_true'"
        :name="col.name + '' + _.uid"
        :value="true"
        :checked="modelValue === item.value"
        @change="() => change(item.value)"
      />
      {{ item.text }}
    </label>
  </div>
</template>
<script lang="ts">
/**
 *
 */
export default {
  props: {
    record: {},
    modelValue: {},
    col: {},
    trueLabel: { type: String },
    falseLabel: { type: String },
  },
  computed: {
    options() {
      if (this.trueLabel && this.falseLabel) {
        return [
          { value: true, text: this.trueLabel },
          { value: false, text: this.falseLabel },
        ]
      }
      const sel = typeof this.col.selections === 'function' ? this.col.selections() : null
      if (Array.isArray(sel)) {
        return sel
      }
      return [
        { value: true, text: 'はい' },
        { value: false, text: 'いいえ' },
      ]
    },
  },
  methods: {
    change(value) {
      this.$emit('update:model-value', value)
    },
  },
}
</script>
